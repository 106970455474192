/* global H */
import { useCallback } from 'react';
import { getFillColor, getStrokeColor } from '~/utils/map/drawing/utils';

export const useHereMapPolygons = () => {
    const getPolygon = useCallback((config) => {
        const { perimeterCoordinates, color, strokeColor, strokeWeight } =
            config;

        const linestring = new H.geo.LineString();
        perimeterCoordinates.forEach((point) => {
            linestring.pushPoint(point);
        });

        const fillColor = getFillColor(color);
        const stroke = getStrokeColor(strokeColor);
        const polygon = new H.map.Polygon(linestring, {
            style: {
                lineWidth: strokeWeight,
                fillColor,
                strokeColor: stroke
            }
        });
        return polygon;
    }, []);

    return {
        getPolygon
    };
};
