import React, { useEffect } from 'react';

import { IconButton } from '~/ui';
import { Task } from '~/data-classes';

import { TaskTimeContent } from '../../LiveDispatchAssignDriverPopup/TaskTimeContent';
import { usePopupTaskCard } from './usePopuTaskCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsUnassignedTasksTabActive,
    setIsUnassignedTasksTabActive
} from '~/reducers/mapDrawerSettingsSlice';
import { selectSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';

import './PopupTaskCard.scss';

const rootClassName = 'live-dispatch-popup-task-card';

interface PopupTaskCardProps {
    task: Task;
}

export const PopupTaskCard = ({ task }: PopupTaskCardProps) => {
    const { name, locationAddressText, serviceTimeText, timeWindowText } =
        usePopupTaskCard(task);

    const dispatch = useDispatch();

    const isUnassignedTasksTabActive = useSelector(
        selectIsUnassignedTasksTabActive
    );
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);

    const isSelectedEquipmentIdsEmpty = !selectedEquipmentIds.length;

    useEffect(() => {
        if (isUnassignedTasksTabActive && isSelectedEquipmentIdsEmpty) {
            dispatch(setIsUnassignedTasksTabActive(false));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isUnassignedTasksTabActive, isSelectedEquipmentIdsEmpty]);

    return (
        <div className={rootClassName} data-testid={rootClassName}>
            <IconButton
                className={`${rootClassName}__marker`}
                text="D"
                disabled
            />
            <div className={`${rootClassName}__summary`}>
                <span
                    className={`${rootClassName}__text`}
                    data-testid={`${rootClassName}__text`}
                >
                    {name}
                </span>
                <span className={`${rootClassName}__text`}>
                    {locationAddressText}
                </span>
                <div
                    className={`${rootClassName}__service-time-wrapper`}
                    data-testid={`${rootClassName}__service-time-wrapper`}
                >
                    <TaskTimeContent
                        icon="hourglass"
                        text={serviceTimeText.join(' ').trim()}
                        data-testid={`${rootClassName}__service-time`}
                    />
                    <TaskTimeContent
                        icon="clock"
                        text={timeWindowText}
                        data-testid={`${rootClassName}__time-window`}
                    />
                </div>
            </div>
        </div>
    );
};
