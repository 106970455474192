import { ApiDepot, ApiEquipmentAttributes } from '~/api/types';
import { ColumnFiltersState } from '@tanstack/react-table';
import { EquipmentState } from '~/reducers/equipmentSlice';
import constants from '~/utils/constants';

type HiddenRouteState = Record<string, boolean>;

const {
    mapOptionSettings: { DEFAULT_CENTER }
} = constants;

export const checkIfRouteIsSelected = (
    clientRouteId: string,
    selectedMapRoutesState: string[],
    selectedDrawerRouteIdState: string
) => {
    const isSelectedDrawerRoute = clientRouteId === selectedDrawerRouteIdState;
    if (selectedDrawerRouteIdState && !isSelectedDrawerRoute) {
        return false;
    }
    return selectedMapRoutesState.includes(clientRouteId);
};

export const checkShouldFilterOutRoute = (
    clientRouteId: string,
    hiddenRoutesState: HiddenRouteState,
    selectedMapRoutesState: string[],
    hasIsolatedRoutesState: boolean,
    selectedDrawerRouteIdState: string
) => {
    const isSelectedDrawerRoute = clientRouteId === selectedDrawerRouteIdState;
    if (selectedDrawerRouteIdState && !isSelectedDrawerRoute) {
        return true;
    }
    if (selectedMapRoutesState.length && hasIsolatedRoutesState) {
        const isSelected = selectedMapRoutesState.includes(clientRouteId);
        if (!isSelected) {
            return true;
        }
    }
    return Boolean(hiddenRoutesState[clientRouteId]);
};

export const getClientHQDepot = (depotsList: ApiDepot[]) => {
    if (!depotsList.length) return DEFAULT_CENTER;

    const hqDepot = depotsList.find(({ hq }) => hq === true);

    if (!hqDepot?.address?.location) return DEFAULT_CENTER;

    return hqDepot.address.location;
};

const getFilteredEquipmentColumn = ({
    equipment,
    columnFilters
}: {
    equipment: EquipmentState;
    columnFilters: ColumnFiltersState;
}) => {
    const lowercaseFilters = columnFilters.flatMap(({ value }) =>
        (value as string[]).map((val) => val.toLowerCase())
    );
    const setOfFilters = new Set(lowercaseFilters);

    return Object.fromEntries(
        Object.entries(equipment).filter(([, value]) => {
            return columnFilters.every((cF) => {
                const { id } = cF;
                const equipmentValue =
                    value[id as keyof ApiEquipmentAttributes];

                const targetValues = Array.isArray(equipmentValue)
                    ? (equipmentValue as string[])
                    : [equipmentValue as string];

                return targetValues.some((targetValue) =>
                    setOfFilters.has(targetValue.toLowerCase())
                );
            });
        })
    );
};

function getFilteredGlobal({
    equipment,
    globalFilter
}: {
    equipment: EquipmentState;
    globalFilter: string;
}) {
    const filteredObjects: EquipmentState = {};

    Object.keys(equipment).forEach((key) => {
        const obj = equipment[key];
        const objString = JSON.stringify(obj).toLowerCase();
        if (new RegExp(globalFilter, 'i').test(objString)) {
            filteredObjects[key] = obj;
        }
    });
    return filteredObjects;
}

export const getFilteredEquipments = ({
    equipment,
    globalFilter,
    columnFilters
}: {
    equipment: EquipmentState;
    globalFilter: string;
    columnFilters: ColumnFiltersState;
}): EquipmentState => {
    if (!globalFilter && !columnFilters.length) return equipment;

    let filteredEquipment: EquipmentState = equipment;

    if (columnFilters.length) {
        filteredEquipment = getFilteredEquipmentColumn({
            equipment,
            columnFilters
        });
    }

    if (globalFilter) {
        filteredEquipment = getFilteredGlobal({
            equipment: filteredEquipment,
            globalFilter
        });
    }

    return filteredEquipment;
};
