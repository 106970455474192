import { isNil } from 'lodash';
import { Duration } from 'luxon';
import i18n from '~/i18n';

import { AddOrUpdateTaskData } from '~/api/TasksApi';
import { ApiCompartment, TaskPriority, TaskTypes } from '~/api/types';
import dateUtils from '~/utils/date-utils';
import { hasDefinedValue, isRecord } from '~/utils/object-utils';

export const className = 'addTaskModal';

export interface AddressInputFields {
    customerName?: string;
    customerId?: string;
    addressStreet?: string;
    addressApt?: string;
    addressCity?: string;
    addressState?: string;
    addressZipCode?: string;
    longitude?: string;
    latitude?: string;
    currentTaskType?: TaskTypes;
    euid?: string;
}
export interface AddressTabFields {
    date?: Date;
    taskType?: TaskTypes;
    externalTaskType?: string;
    deliveryLatitude?: string;
    deliveryLongitude?: string;
    deliveryAddressStreet?: string;
    deliveryAddressApt?: string;
    deliveryAddressCity?: string;
    deliveryAddressState?: string;
    deliveryAddressZipCode?: string;
    deliveryCustomerName?: string;
    deliveryCustomerId?: string;
    deliveryEuid?: string;
    pickupLatitude?: string;
    pickupLongitude?: string;
    pickupAddressStreet?: string;
    pickupAddressApt?: string;
    pickupAddressCity?: string;
    pickupAddressState?: string;
    pickupAddressZipCode?: string;
    pickupCustomerName?: string;
    pickupCustomerId?: string;
    pickupEuid?: string;
}

export interface TimeWindow {
    /* Time in 24-hr format */
    start?: string;
    /* Time in 24-hr format */
    end?: string;
}

export interface Invoice {
    frontendOnlyId?: string;
    amount?: string;
    referenceNumber?: string;
}

export enum VehicleType {
    SIDE_LOADER = 'Side Loader',
    END_LOADER = 'End Loader'
}

export type TaskCompartment = {
    componentId: string;
    selectedCompartmentId: string;
    capacity: string;
    label: string;
    unit: string;
    isFirst: boolean;
};

export interface TaskInformationTabFields {
    priority?: TaskPriority;
    deliveryTimeWindows?: Readonly<TimeWindow>[];
    pickupTimeWindows?: Readonly<TimeWindow>[];
    invoices?: Readonly<Invoice>[];
    paymentRequired?: boolean;
    deliveryServiceTimeMins?: string;
    pickupServiceTimeMins?: string;
    sizeByCompartment?: Partial<TaskCompartment>[];
    routeId?: string;
    vehicleType?: VehicleType | null;
    size?: string;
    weight?: string;
    equipmentId?: string;
}

export interface InventoryItem {
    name?: string;
    trackingID?: string;
    quantity?: string;
    weight?: string;
}

export interface InventoryItemsTabFields {
    inventoryItems?: Readonly<InventoryItem>[];
}

export interface Note {
    title?: string;
    content?: string;
}

export interface NotesTabFields {
    notes?: Note[];
}

export type ClientTask = AddressTabFields &
    TaskInformationTabFields &
    InventoryItemsTabFields &
    NotesTabFields;

export const tasksFieldToPreserve = {
    externalTaskType: 'externalTaskType',
    date: 'date',
    taskType: 'taskType'
};

export const getCurrentTime = (type: 'start' | 'end') => {
    const now = new Date();
    if (type === 'end') {
        now.setHours(now.getHours() + 2);
    }
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const emptyClientTask: Required<ClientTask> = {
    deliveryLatitude: '',
    deliveryLongitude: '',
    deliveryAddressStreet: '',
    deliveryAddressApt: '',
    deliveryAddressCity: '',
    deliveryAddressState: '',
    deliveryAddressZipCode: '',
    deliveryCustomerName: '',
    deliveryCustomerId: '',
    deliveryEuid: '',
    pickupLatitude: '',
    pickupLongitude: '',
    pickupAddressStreet: '',
    pickupAddressApt: '',
    pickupAddressCity: '',
    pickupAddressState: '',
    pickupAddressZipCode: '',
    pickupCustomerName: '',
    pickupCustomerId: '',
    pickupEuid: '',
    date: new Date(),
    equipmentId: '',
    priority: TaskPriority.LOW,
    routeId: '',
    deliveryServiceTimeMins: '',
    pickupServiceTimeMins: '',
    sizeByCompartment: [],
    taskType: TaskTypes.DELIVERY,
    deliveryTimeWindows: [
        {
            start: getCurrentTime('start'),
            end: getCurrentTime('end')
        }
    ],
    pickupTimeWindows: [
        {
            start: getCurrentTime('start'),
            end: getCurrentTime('end')
        }
    ],
    vehicleType: null,
    size: '',
    weight: '',

    /**
     * Initialize the table with 6 empty rows
     * Empty inventory items are filtered out when the api request to add the task is made
     */
    inventoryItems: [{}, {}, {}, {}, {}, {}],
    notes: [{}],
    externalTaskType: '',
    invoices: [],
    paymentRequired: false
};

const parseOptionalFloat = (value: string): number | undefined => {
    return value?.length ? parseFloat(value) : undefined;
};

const getPayloadServiceTime = (serviceTimeMins?: string) => {
    const serviceTimeMinsNumber = serviceTimeMins?.length
        ? parseFloat(serviceTimeMins)
        : undefined;

    const serviceTimeDurationISO =
        !isNil(serviceTimeMinsNumber) && !Number.isNaN(serviceTimeMinsNumber)
            ? Duration.fromObject({ minutes: serviceTimeMinsNumber }).toISO()
            : undefined;

    return serviceTimeDurationISO;
};

const getPayloadTimeWindow = (
    date: Date,
    timeWindows?: TimeWindow[],
    zone?: string
) => {
    const isoTimeWindows = timeWindows
        ?.filter(hasDefinedValue)
        .map(({ start, end }) => {
            return {
                start: start
                    ? dateUtils.convert24HourDateTimeToISO(date, start, {
                          isConvertToUTC: true,
                          dateTimeJSOptions: { zone }
                      })
                    : undefined,
                end: end
                    ? dateUtils.convert24HourDateTimeToISO(date, end, {
                          isConvertToUTC: true,
                          dateTimeJSOptions: { zone }
                      })
                    : undefined
            };
        });

    return isoTimeWindows;
};

const getPayloadLocation = (
    longitude: string | number,
    latitude: string | number
) => {
    const lng = longitude?.toString() || '';
    const lat = latitude?.toString() || '';

    const location =
        lng && lat
            ? {
                  lng,
                  lat
              }
            : undefined;

    return location;
};

const getPayloadSizeByCompartment = (
    sizeByCompartment: Partial<TaskCompartment>[]
) => {
    const filteredSizeByCompartment = sizeByCompartment.filter(
        (compartment) => !!compartment.selectedCompartmentId
    );

    const apiSizeByCompartment = filteredSizeByCompartment.map(
        (compartment) => {
            return {
                size: parseInt(compartment.capacity ?? '0', 10),
                compartmentType: compartment.selectedCompartmentId
            };
        }
    );

    const summedCompartmentValues = apiSizeByCompartment.reduce(
        (acc: ApiCompartment[], curr) => {
            const compartmentType = curr.compartmentType as string;

            const objInAcc = acc.find(
                (o) => o.compartmentType === compartmentType
            ) as ApiCompartment;
            if (objInAcc?.size) {
                objInAcc.size += curr.size ?? 0;
            } else {
                acc.push({
                    size: curr.size,
                    compartmentType
                });
            }

            return acc;
        },
        []
    );

    return summedCompartmentValues;
};

const getPayloadInventory = (inventoryItems: InventoryItem[]) => {
    const apiInventoryItems = inventoryItems
        .filter(hasDefinedValue)
        .map(({ name, trackingID, quantity, weight }) => ({
            item_name: name,
            item_id: trackingID,
            expected_quantity: quantity?.length
                ? parseFloat(quantity)
                : undefined,
            unit_weight: weight?.length ? parseFloat(weight) : undefined
        }));

    return apiInventoryItems;
};

const getPayloadTaskLocation = ({
    addressStreet,
    addressApt,
    addressCity,
    addressState,
    addressZipCode,
    location,
    customerName
}: {
    addressStreet: string;
    addressApt: string;
    addressCity: string;
    addressState: string;
    addressZipCode: string;
    location?: {
        lng: string;
        lat: string;
    };
    customerName: string;
}) => {
    const taskLocation = {
        addressLine1: addressStreet?.length ? addressStreet : undefined,
        addressLine2: addressApt?.length ? addressApt : undefined,
        city: addressCity?.length ? addressCity : undefined,
        state: addressState?.length ? addressState : undefined,
        zipcode: addressZipCode?.length ? addressZipCode : undefined,
        location,
        name: customerName?.length ? customerName : undefined
    };

    return taskLocation;
};

const getPayloadNotes = (notes: Note[]) => {
    const apiNotes = notes
        .filter(hasDefinedValue)
        .map(({ content, title }) => ({
            scope: 'other',
            title,
            text: content
        }));

    return apiNotes;
};

const getPayloadSizeWeight = ({
    taskSize,
    taskWeight
}: {
    taskSize: string;
    taskWeight: string;
}) => {
    return {
        size: parseOptionalFloat(taskSize),
        weight: parseOptionalFloat(taskWeight)
    };
};

const getPayloadInvoices = ({
    key,
    invoices,
    paymentRequired
}: {
    key: string;
    invoices: Invoice[];
    paymentRequired: boolean;
}) => {
    const mappedInvoices = invoices.map((invoice) => ({
        invoiceNumber: invoice.referenceNumber,
        amountDue: Number(invoice.amount)
    }));

    const taskInvoices = {
        ...(paymentRequired &&
            Boolean(invoices.length) && {
                [key]: mappedInvoices
            })
    };

    return taskInvoices;
};

const getPayloadCommon = (clientTask: Required<ClientTask>) => {
    const {
        sizeByCompartment,
        deliveryCustomerName: customerName,
        date,
        externalTaskType,
        equipmentId,
        routeId,
        vehicleType
    } = clientTask;

    const summedCompartmentValues =
        getPayloadSizeByCompartment(sizeByCompartment);

    const commonPayload = {
        name: customerName.length ? customerName : undefined,
        externalTaskType: externalTaskType?.length
            ? externalTaskType
            : undefined,
        equipmentId: equipmentId?.length ? equipmentId : undefined,
        sizeByCompartment: summedCompartmentValues,
        routeDate: dateUtils.convertToISODateOnly(date) ?? undefined,
        euid: routeId.length ? routeId : undefined,
        vehicleTypes: !isNil(vehicleType) ? [vehicleType] : undefined
    };

    return commonPayload;
};

const getPayloadTaskTypeCommon = (
    clientTask: Required<ClientTask>,
    zone?: string
) => {
    const {
        date,
        priority,
        inventoryItems,
        size: taskSize,
        weight: taskWeight,
        paymentRequired,
        invoices = [],
        notes,
        deliveryAddressApt,
        deliveryAddressCity,
        deliveryAddressState,
        deliveryAddressStreet,
        deliveryAddressZipCode,
        deliveryCustomerId,
        deliveryCustomerName,
        deliveryLatitude,
        deliveryLongitude,
        deliveryEuid,
        deliveryServiceTimeMins,
        deliveryTimeWindows,
        pickupAddressApt,
        pickupAddressCity,
        pickupAddressState,
        pickupAddressStreet,
        pickupAddressZipCode,
        pickupCustomerId,
        pickupCustomerName,
        pickupLatitude,
        pickupLongitude,
        pickupEuid,
        pickupServiceTimeMins,
        pickupTimeWindows
    } = clientTask;

    const deliveryServiceTimeDurationISO = getPayloadServiceTime(
        deliveryServiceTimeMins
    );
    const pickupServiceTimeDurationISO = getPayloadServiceTime(
        pickupServiceTimeMins
    );
    const deliveryISOTimeWindows = getPayloadTimeWindow(
        date,
        deliveryTimeWindows,
        zone
    );
    const pickupISOTimeWindows = getPayloadTimeWindow(
        date,
        pickupTimeWindows,
        zone
    );
    const deliveryLocation = getPayloadLocation(
        deliveryLongitude,
        deliveryLatitude
    );
    const pickupLocation = getPayloadLocation(pickupLongitude, pickupLatitude);
    const apiInventoryItems = getPayloadInventory(inventoryItems);
    const deliveryTaskLocation = getPayloadTaskLocation({
        addressStreet: deliveryAddressStreet,
        addressApt: deliveryAddressApt,
        addressCity: deliveryAddressCity,
        addressState: deliveryAddressState,
        addressZipCode: deliveryAddressZipCode,
        location: deliveryLocation,
        customerName: deliveryCustomerName
    });
    const pickupTaskLocation = getPayloadTaskLocation({
        addressStreet: pickupAddressStreet,
        addressApt: pickupAddressApt,
        addressCity: pickupAddressCity,
        addressState: pickupAddressState,
        addressZipCode: pickupAddressZipCode,
        location: pickupLocation,
        customerName: pickupCustomerName
    });
    const apiNotes = getPayloadNotes(notes);
    const deliveryAndPickupTaskProps = getPayloadSizeWeight({
        taskSize,
        taskWeight
    });

    return {
        deliveryTaskLocation,
        pickupTaskLocation,
        priority,
        deliveryServiceTimeDurationISO,
        pickupServiceTimeDurationISO,
        deliveryISOTimeWindows,
        pickupISOTimeWindows,
        apiInventoryItems,
        apiNotes,
        deliveryAndPickupTaskProps,
        invoices,
        paymentRequired,
        deliveryCustomerId,
        pickupCustomerId,
        deliveryEuid,
        pickupEuid
    };
};

const getPayloadTaskTypeDelivery = (
    clientTask: Required<ClientTask>,
    zone?: string
) => {
    const {
        deliveryTaskLocation,
        priority,
        deliveryServiceTimeDurationISO,
        deliveryISOTimeWindows,
        apiInventoryItems,
        apiNotes,
        deliveryAndPickupTaskProps,
        invoices,
        paymentRequired,
        deliveryEuid
    } = getPayloadTaskTypeCommon(clientTask, zone);

    const deliverySubTask = {
        deliveryLocation: deliveryTaskLocation,
        props: {
            priority,
            deliveryServiceTime: deliveryServiceTimeDurationISO,
            deliveryWindow: deliveryISOTimeWindows,
            deliveryInventory: apiInventoryItems,
            notes: apiNotes,
            ...deliveryAndPickupTaskProps,
            ...getPayloadInvoices({
                key: 'deliveryInvoices',
                invoices,
                paymentRequired
            })
        },
        deliveryEuid
    };

    return deliverySubTask;
};

const getPayloadTaskTypePickup = (
    clientTask: Required<ClientTask>,
    zone?: string
) => {
    const {
        pickupTaskLocation,
        priority,
        pickupServiceTimeDurationISO,
        pickupISOTimeWindows,
        apiInventoryItems,
        apiNotes,
        deliveryAndPickupTaskProps,
        invoices,
        paymentRequired,
        pickupEuid
    } = getPayloadTaskTypeCommon(clientTask, zone);

    const pickupSubTask = {
        pickupLocation: pickupTaskLocation,
        props: {
            priority,
            pickupServiceTime: pickupServiceTimeDurationISO,
            pickupWindow: pickupISOTimeWindows,
            pickupInventory: apiInventoryItems,
            notes: apiNotes,
            ...deliveryAndPickupTaskProps,
            ...getPayloadInvoices({
                key: 'pickupInvoices',
                invoices,
                paymentRequired
            })
        },
        pickupEuid
    };

    return pickupSubTask;
};

const getPayloadTaskTypeSpecific = (
    clientTask: Required<ClientTask>,
    zone?: string
) => {
    const { taskType } = clientTask;
    const isTaskTypeDelivery = taskType === TaskTypes.DELIVERY;

    if (taskType === TaskTypes.TWOPART) {
        const deliverySpecific = getPayloadTaskTypeDelivery(clientTask, zone);
        const { props: deliveryProps } = deliverySpecific;

        const pickupSpecific = getPayloadTaskTypePickup(clientTask, zone);
        const { props: pickupProps } = pickupSpecific;

        return {
            ...getPayloadTaskTypeDelivery(clientTask, zone),
            ...getPayloadTaskTypePickup(clientTask, zone),
            props: {
                ...deliveryProps,
                ...pickupProps
            }
        };
    }

    return isTaskTypeDelivery
        ? getPayloadTaskTypeDelivery(clientTask, zone)
        : getPayloadTaskTypePickup(clientTask, zone);
};

export function clientTaskToApiTask(
    clientTask: Required<ClientTask>,
    zone?: string
): AddOrUpdateTaskData {
    const commonPayload = getPayloadCommon(clientTask);
    const taskTypeSpecificSubTask = getPayloadTaskTypeSpecific(
        clientTask,
        zone
    );

    const apiTask = {
        ...commonPayload,
        ...taskTypeSpecificSubTask
    };

    return apiTask;
}

export const taskPriorityTranslationSliderLabels = [
    i18n.t('addTask:fields.priority.fields.low.label'),
    i18n.t('addTask:fields.priority.fields.medium.label'),
    i18n.t('addTask:fields.priority.fields.high.label')
];

export const prioritySliderValueLookup: { [key: number]: number } = {
    3: TaskPriority.HIGH,
    2: TaskPriority.MEDIUM,
    1: TaskPriority.LOW
};

export type VehicleTypeToTranslationKeyMap = {
    [key in VehicleType]: string;
};

export const vehicleTypeToTranslationKeyMap: VehicleTypeToTranslationKeyMap = {
    [VehicleType.END_LOADER]: 'fields.vehicleType.fields.endLoader.label',
    [VehicleType.SIDE_LOADER]: 'fields.vehicleType.fields.sideLoader.label'
};

export type AddressTabRequiredFieldMap =
    RequiredFieldMapTypeGen<AddressTabFields>;

export const addressTabRequiredFieldMap: AddressTabRequiredFieldMap = {
    date: true,
    taskType: true,
    externalTaskType: false,
    deliveryLatitude: false,
    deliveryLongitude: false,
    deliveryAddressStreet: false,
    deliveryAddressApt: false,
    deliveryAddressCity: false,
    deliveryAddressState: true,
    deliveryAddressZipCode: true,
    deliveryCustomerName: false,
    deliveryCustomerId: false,
    deliveryEuid: false,
    pickupLatitude: false,
    pickupLongitude: false,
    pickupAddressStreet: false,
    pickupAddressApt: false,
    pickupAddressCity: false,
    pickupAddressState: true,
    pickupAddressZipCode: true,
    pickupCustomerName: false,
    pickupCustomerId: false,
    pickupEuid: false
} as const;

export const getAddressTabRequiredFieldMap: (
    taskType?: string
) => AddressTabRequiredFieldMap = (taskType) => {
    const isTwoPart = taskType === TaskTypes.TWOPART;
    const isDelivery = taskType === TaskTypes.DELIVERY;
    const isPickup = taskType === TaskTypes.PICKUP;

    const deliveryAddressState = isTwoPart || isDelivery;
    const deliveryAddressZipCode = isTwoPart || isDelivery;
    const pickupAddressState = isTwoPart || isPickup;
    const pickupAddressZipCode = isTwoPart || isPickup;

    return {
        ...addressTabRequiredFieldMap,
        deliveryAddressState,
        deliveryAddressZipCode,
        pickupAddressState,
        pickupAddressZipCode
    };
};

export type TaskInformationTabRequiredFieldMap =
    RequiredFieldMapTypeGen<TaskInformationTabFields>;

export const taskInformationTabRequiredFieldMap: TaskInformationTabRequiredFieldMap =
    {
        priority: true,
        routeId: false,
        equipmentId: false,
        deliveryServiceTimeMins: false,
        pickupServiceTimeMins: false,
        deliveryTimeWindows: {
            minLength: 0,
            requiredMap: {
                end: false,
                start: false
            }
        },
        pickupTimeWindows: {
            minLength: 0,
            requiredMap: {
                end: false,
                start: false
            }
        },
        sizeByCompartment: {
            minLength: 0,
            requiredMap: {
                componentId: false,
                selectedCompartmentId: false,
                capacity: false,
                label: false,
                unit: false,
                isFirst: false
            }
        },
        vehicleType: false,
        size: false,
        weight: false,
        invoices: {
            minLength: 0,
            requiredMap: {
                frontendOnlyId: true,
                referenceNumber: true,
                amount: true
            }
        },
        paymentRequired: false
    } as const;

export const getTaskInformationTabRequiredFieldMap: (
    taskType?: string
) => TaskInformationTabRequiredFieldMap = (taskType) => {
    const isTwoPart = taskType === TaskTypes.TWOPART;
    const isDelivery = taskType === TaskTypes.DELIVERY;
    const isPickup = taskType === TaskTypes.PICKUP;

    const deliveryServiceTimeMins = isTwoPart || isDelivery;
    const pickupServiceTimeMins = isTwoPart || isPickup;

    const deliveryTimeWindows = {
        minLength: isTwoPart || isDelivery ? 1 : 0,
        requiredMap: {
            end: isTwoPart || isDelivery,
            start: isTwoPart || isDelivery
        }
    };
    const pickupTimeWindows = {
        minLength: isTwoPart || isPickup ? 1 : 0,
        requiredMap: {
            end: isTwoPart || isPickup,
            start: isTwoPart || isPickup
        }
    };

    return {
        ...taskInformationTabRequiredFieldMap,
        deliveryServiceTimeMins,
        pickupServiceTimeMins,
        deliveryTimeWindows,
        pickupTimeWindows
    };
};

export type InventoryItemsTabRequiredFieldMap =
    RequiredFieldMapTypeGen<InventoryItemsTabFields>;

export const inventoryItemsTabRequiredFieldMap: InventoryItemsTabRequiredFieldMap =
    {
        inventoryItems: {
            minLength: 0,
            requiredMap: {
                name: true,
                quantity: true,
                trackingID: true,
                weight: false
            }
        }
    } as const;

export type InventoryItemRequiredFieldMap =
    RequiredFieldMapTypeGen<InventoryItem>;

export type NotesTabRequiredFieldMap = RequiredFieldMapTypeGen<NotesTabFields>;

export const notesTabRequiredFieldMap: NotesTabRequiredFieldMap = {
    notes: {
        minLength: 0,
        requiredMap: {
            title: false,
            content: false
        }
    }
} as const;

export type ClientTaskRequiredFieldMap = RequiredFieldMapTypeGen<ClientTask>;

export const clientTaskRequiredFieldMap: (
    taskType?: string
) => ClientTaskRequiredFieldMap = (taskType) =>
    ({
        ...getAddressTabRequiredFieldMap(taskType),
        ...getTaskInformationTabRequiredFieldMap(taskType),
        ...inventoryItemsTabRequiredFieldMap,
        ...notesTabRequiredFieldMap
    } as const);

export const dataIsMissingRequiredFields = <
    Data extends Record<string, unknown>
>(
    data: Data,
    requiredFieldMap: Partial<RequiredFieldMapTypeGen<Data>>
): boolean => {
    const requiredFieldMapEntries = Object.entries(requiredFieldMap) as [
        keyof typeof requiredFieldMap,
        typeof requiredFieldMap[keyof typeof requiredFieldMap]
    ][];

    return requiredFieldMapEntries.some(([field, requiredMapValue]) => {
        const fieldValue = data[field];

        if (typeof requiredMapValue === 'boolean') {
            if (!requiredMapValue) {
                return false;
            }

            if (!fieldValue) {
                return true;
            }

            return false;
        }

        if (isRecord(fieldValue) && isRecord(requiredMapValue)) {
            return dataIsMissingRequiredFields(
                fieldValue,
                requiredMapValue as RequiredFieldMapTypeGen<typeof fieldValue>
            );
        }

        if (Array.isArray(fieldValue)) {
            /**
             * Filter fieldValue rows to only rows that have some truthy values
             */
            const filteredFieldValue = fieldValue.filter((fieldValueRow) => {
                return Object.values(fieldValueRow).filter(
                    (fieldValueRowValue) => !!fieldValueRowValue
                ).length;
            });

            const typedRequiredMapValue =
                requiredMapValue as ArrayEntryRequiredMapData<
                    typeof fieldValue[number]
                >;

            const { minLength, requiredMap: subRequiredMap } =
                typedRequiredMapValue;

            if (filteredFieldValue.length < minLength) {
                return true;
            }

            return filteredFieldValue.some((fieldValueRow) => {
                return dataIsMissingRequiredFields(
                    fieldValueRow,
                    subRequiredMap
                );
            });
        }

        return typeof fieldValue === 'string' ? !fieldValue.length : false;
    });
};

export type ClientTaskErrorState = ErrorStateTypeGen<ClientTask>;

export type AddressTabErrorState = ErrorStateTypeGen<AddressTabFields>;

export type TaskInformationTabErrorState =
    ErrorStateTypeGen<TaskInformationTabFields>;

export type InventoryItemsTabErrorState =
    ErrorStateTypeGen<InventoryItemsTabFields>;

export type NotesTabErrorState = ErrorStateTypeGen<NotesTabFields>;

export type TabErrorState =
    | AddressTabErrorState
    | TaskInformationTabErrorState
    | InventoryItemsTabErrorState
    | NotesTabErrorState;

export type FieldToTabErrorStateMap = {
    [key in keyof AddressTabFields]-?: AddressTabErrorState;
} &
    {
        [key in keyof TaskInformationTabFields]-?: TaskInformationTabErrorState;
    } &
    {
        [key in keyof InventoryItemsTabErrorState]-?: InventoryItemsTabErrorState;
    } &
    {
        [key in keyof NotesTabErrorState]-?: NotesTabErrorState;
    };

interface FieldToTabErrorStateMapGeneratorArgs {
    addressTabErrorState: AddressTabErrorState;
    taskInformationTabErrorState: TaskInformationTabErrorState;
    inventoryItemsTabErrorState: InventoryItemsTabErrorState;
    notesTabErrorState: NotesTabErrorState;
}

/**
 * Returns a mapping from a client task field, to the tab error state that contains that field
 */
export function generateFieldToTabErrorStateMap({
    addressTabErrorState,
    taskInformationTabErrorState,
    inventoryItemsTabErrorState,
    notesTabErrorState
}: FieldToTabErrorStateMapGeneratorArgs): FieldToTabErrorStateMap {
    return {
        date: addressTabErrorState,
        equipmentId: taskInformationTabErrorState,
        inventoryItems: inventoryItemsTabErrorState,
        priority: taskInformationTabErrorState,
        routeId: taskInformationTabErrorState,
        deliveryServiceTimeMins: taskInformationTabErrorState,
        pickupServiceTimeMins: taskInformationTabErrorState,
        sizeByCompartment: taskInformationTabErrorState,
        taskType: addressTabErrorState,
        deliveryTimeWindows: taskInformationTabErrorState,
        pickupTimeWindows: taskInformationTabErrorState,
        vehicleType: taskInformationTabErrorState,
        size: taskInformationTabErrorState,
        weight: taskInformationTabErrorState,
        notes: notesTabErrorState,
        externalTaskType: addressTabErrorState,
        invoices: taskInformationTabErrorState,
        paymentRequired: taskInformationTabErrorState,
        deliveryLatitude: addressTabErrorState,
        deliveryLongitude: addressTabErrorState,
        deliveryAddressStreet: addressTabErrorState,
        deliveryAddressApt: addressTabErrorState,
        deliveryAddressCity: addressTabErrorState,
        deliveryAddressState: addressTabErrorState,
        deliveryAddressZipCode: addressTabErrorState,
        deliveryCustomerName: addressTabErrorState,
        deliveryCustomerId: addressTabErrorState,
        deliveryEuid: addressTabErrorState,
        pickupLatitude: addressTabErrorState,
        pickupLongitude: addressTabErrorState,
        pickupAddressStreet: addressTabErrorState,
        pickupAddressApt: addressTabErrorState,
        pickupAddressCity: addressTabErrorState,
        pickupAddressState: addressTabErrorState,
        pickupAddressZipCode: addressTabErrorState,
        pickupCustomerName: addressTabErrorState,
        pickupCustomerId: addressTabErrorState,
        pickupEuid: addressTabErrorState
    };
}

interface GenerateErrorStatesReturn {
    addressTabErrorState: AddressTabErrorState;
    taskInformationTabErrorState: TaskInformationTabErrorState;
    inventoryItemsTabErrorState: InventoryItemsTabErrorState;
    notesTabErrorState: NotesTabErrorState;
}

/**
 * Given an error state representing the whole client task, returns error state slices for each tab
 * of the Add Task Modal
 */
export function generateErrorStates(
    errorState: ClientTaskErrorState
): GenerateErrorStatesReturn {
    const addressTabErrorState: AddressTabErrorState = {};
    const taskInformationTabErrorState: TaskInformationTabErrorState = {};
    const inventoryItemsTabErrorState: InventoryItemsTabErrorState = {};
    const notesTabErrorState: NotesTabErrorState = {};
    const fieldToTabErrorStateMap = generateFieldToTabErrorStateMap({
        addressTabErrorState,
        taskInformationTabErrorState,
        inventoryItemsTabErrorState,
        notesTabErrorState
    });

    (
        Object.entries(errorState) as [keyof ClientTaskErrorState, boolean][]
    ).forEach(
        ([errorStateKey, errorStateValue]: [
            keyof ClientTaskErrorState,
            boolean
        ]) => {
            const tabErrorState = fieldToTabErrorStateMap[errorStateKey];

            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            (tabErrorState as any)[errorStateKey] = errorStateValue;
        }
    );

    return {
        addressTabErrorState,
        taskInformationTabErrorState,
        inventoryItemsTabErrorState,
        notesTabErrorState
    };
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function errorStateHasError(errorState: Record<string, any>): boolean {
    return Object.values(errorState).some((errorStateValue) => {
        if (Array.isArray(errorStateValue)) {
            return errorStateValue.some((errorStateValueEntry) =>
                errorStateHasError(errorStateValueEntry)
            );
        }
        if (typeof errorStateValue === 'object') {
            return errorStateHasError(errorStateValue);
        }
        return errorStateValue;
    });
}

export enum AddTaskModalTab {
    ADDRESS,
    TASK_INFORMATION,
    INVENTORY_ITEMS,
    NOTES
}

type FieldToTabMap = {
    [key in keyof ClientTask]-?: AddTaskModalTab;
};

/**
 * Maps from each ClientTask field to the tab containing that field
 *
 * Used for generating error state maps for each tab
 */
export const fieldToTabMap: FieldToTabMap = {
    date: AddTaskModalTab.ADDRESS,
    equipmentId: AddTaskModalTab.TASK_INFORMATION,
    inventoryItems: AddTaskModalTab.INVENTORY_ITEMS,
    priority: AddTaskModalTab.TASK_INFORMATION,
    routeId: AddTaskModalTab.TASK_INFORMATION,
    sizeByCompartment: AddTaskModalTab.TASK_INFORMATION,
    deliveryServiceTimeMins: AddTaskModalTab.TASK_INFORMATION,
    pickupServiceTimeMins: AddTaskModalTab.TASK_INFORMATION,
    taskType: AddTaskModalTab.ADDRESS,
    deliveryTimeWindows: AddTaskModalTab.TASK_INFORMATION,
    pickupTimeWindows: AddTaskModalTab.TASK_INFORMATION,
    vehicleType: AddTaskModalTab.TASK_INFORMATION,
    notes: AddTaskModalTab.NOTES,
    size: AddTaskModalTab.TASK_INFORMATION,
    weight: AddTaskModalTab.TASK_INFORMATION,
    externalTaskType: AddTaskModalTab.ADDRESS,
    invoices: AddTaskModalTab.TASK_INFORMATION,
    paymentRequired: AddTaskModalTab.TASK_INFORMATION,
    deliveryLatitude: AddTaskModalTab.ADDRESS,
    deliveryLongitude: AddTaskModalTab.ADDRESS,
    deliveryAddressStreet: AddTaskModalTab.ADDRESS,
    deliveryAddressApt: AddTaskModalTab.ADDRESS,
    deliveryAddressCity: AddTaskModalTab.ADDRESS,
    deliveryAddressState: AddTaskModalTab.ADDRESS,
    deliveryAddressZipCode: AddTaskModalTab.ADDRESS,
    deliveryCustomerName: AddTaskModalTab.ADDRESS,
    deliveryCustomerId: AddTaskModalTab.ADDRESS,
    deliveryEuid: AddTaskModalTab.ADDRESS,
    pickupLatitude: AddTaskModalTab.ADDRESS,
    pickupLongitude: AddTaskModalTab.ADDRESS,
    pickupAddressStreet: AddTaskModalTab.ADDRESS,
    pickupAddressApt: AddTaskModalTab.ADDRESS,
    pickupAddressCity: AddTaskModalTab.ADDRESS,
    pickupAddressState: AddTaskModalTab.ADDRESS,
    pickupAddressZipCode: AddTaskModalTab.ADDRESS,
    pickupCustomerName: AddTaskModalTab.ADDRESS,
    pickupCustomerId: AddTaskModalTab.ADDRESS,
    pickupEuid: AddTaskModalTab.ADDRESS
} as const;

export function getTimeWindowErrorState(
    timeWindow: TimeWindow,
    requiredMap: RequiredFieldMapTypeGen<TimeWindow>
): Required<ErrorStateTypeGen<TimeWindow>> {
    return {
        start: requiredMap.start ? !timeWindow.start?.length : false,
        end: requiredMap.end ? !timeWindow.end?.length : false
    };
}

export function getSizeByCompartmentErrorState(): Required<
    ErrorStateTypeGen<Partial<TaskCompartment>>
> {
    return {
        componentId: false,
        selectedCompartmentId: false,
        capacity: false,
        label: false,
        unit: false,
        isFirst: false
    };
}

export function getInvoiceErrorState(
    invoice: Invoice,
    requiredMap: RequiredFieldMapTypeGen<Invoice>
): Required<ErrorStateTypeGen<Invoice>> {
    return {
        frontendOnlyId: requiredMap.frontendOnlyId
            ? !invoice.frontendOnlyId?.length
            : false,
        referenceNumber: requiredMap.referenceNumber
            ? !invoice.referenceNumber?.length
            : false,
        amount: requiredMap.amount ? !invoice.amount?.length : false
    };
}

export function isInvoicesFieldWithError(clientTask: ClientTask) {
    const { paymentRequired, invoices } = clientTask;

    if (!paymentRequired) return false;

    return (invoices || []).some(({ amount, referenceNumber }) => {
        return !amount || !referenceNumber;
    });
}

export function getInventoryItemErrorState(
    inventoryItem: InventoryItem,
    requiredMap: RequiredFieldMapTypeGen<InventoryItem>
): Required<ErrorStateTypeGen<InventoryItem>> {
    return {
        name: requiredMap.name ? !inventoryItem.name?.length : false,
        quantity: requiredMap.quantity
            ? !inventoryItem.quantity?.length
            : false,
        trackingID: requiredMap.trackingID
            ? !inventoryItem.trackingID?.length
            : false,
        weight: requiredMap.weight ? !inventoryItem.weight?.length : false
    };
}

export function getNotesErrorState(
    note: Note,
    requiredMap: RequiredFieldMapTypeGen<Note>
): Required<ErrorStateTypeGen<Note>> {
    return {
        title: requiredMap.title ? !note.title?.length : false,
        content: requiredMap.content ? !note.content?.length : false
    };
}

export function getAddressErrorState({
    place,
    taskType
}: {
    place: AddressTabFields;
    taskType: string;
}): ClientTaskErrorState {
    const addressErrorState: ClientTaskErrorState = {};

    type AddressField = MatchTypeKeys<
        Omit<AddressTabFields, 'taskType'>,
        string
    >;

    for (const field in place) {
        const addressField = field as AddressField;
        addressErrorState[addressField] = clientTaskRequiredFieldMap(taskType)[
            addressField
        ]
            ? !place[addressField]?.length
            : false;
    }
    return addressErrorState;
}
