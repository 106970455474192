import { Equipment } from '~/data-classes';
import dateUtils from '~/utils/date-utils';

export const useEquipmentCard = (equipmentData: Equipment) => {
    const { id, status, updatedAt, attached } = equipmentData;

    const formattedDate = dateUtils.formatEquipmentUpdatedAtDate(
        new Date(updatedAt ?? 0)
    );

    return {
        id,
        status,
        timestamp: formattedDate,
        attached
    };
};
