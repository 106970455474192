import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useMapUtils } from '~/hooks';
import { getStopIds } from '~/ui/components/StopMarker/utils';
import {
    addSelectedMapStop,
    removeSelectedMapStop
} from '~/reducers/selectedMapStopsSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { resetDriverBreaksModal } from '~/reducers/driverBreaksModalSlice';
import { useIsPlanStopSelected } from '~/ui/components/StopMarker/useIsPlanStopSelected';
import { idUtils } from '~/utils/id-utils';

interface PlanStopClickHandlerProps {
    clientRouteTaskId: string;
    isSelected?: boolean;
    isTwoPart?: boolean;
}
export const usePlanStopClickHandler = () => {
    const dispatch = useDispatch();
    const { isPlanStopSelected } = useIsPlanStopSelected();
    const { isPlanRouteMode } = useMapUtils();

    const planStopClickHandler = useCallback(
        ({
            clientRouteTaskId,
            isSelected = isPlanStopSelected(clientRouteTaskId)
        }: PlanStopClickHandlerProps) => {
            if (!isPlanRouteMode) return;

            let stopsIds = [];

            const isUnassignedTaskStopId =
                idUtils.isUnassignedTaskStopId(clientRouteTaskId);
            if (isUnassignedTaskStopId) {
                stopsIds = [clientRouteTaskId];
            } else {
                stopsIds = getStopIds(clientRouteTaskId);
            }

            dispatch(resetSelectedTaskRowId());
            dispatch(resetDriverBreaksModal());

            stopsIds.forEach((stopId) => {
                if (isSelected) {
                    dispatch(removeSelectedMapStop(stopId));
                    return;
                }

                dispatch(addSelectedMapStop(stopId));
            });
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [isPlanRouteMode, isPlanStopSelected]
    );

    return {
        planStopClickHandler
    };
};
