import { useCallback, useEffect } from 'react';
import constants from '~/utils/constants';
import { setLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';
import { selectIsOpenAnyUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import {
    selectAreRoutesLoading,
    selectAreStopsLoading
} from '~/reducers/dataLoadingSlice';
import { useIsolatedRoutes } from '~/hooks';
import {
    selectShouldFitPlanMapToBounds,
    setShouldFitPlanMapToBounds
} from '~/reducers/mapSettingsSlice';

export const useFitMapToBounds = () => {
    const dispatch = useDispatch();

    const setShouldFitToBounds = useCallback(
        (value: boolean) => {
            return dispatch(setShouldFitPlanMapToBounds(value));
        },
        [dispatch]
    );

    const shouldFitToBounds = useSelector(selectShouldFitPlanMapToBounds);

    return {
        shouldFitToBounds,
        setShouldFitToBounds
    };
};

export const useMapBoundingEffects = () => {
    const dispatch = useDispatch();

    const { setShouldFitToBounds } = useFitMapToBounds();
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenAnyUnassignedTasksDrawer
    );
    const areRoutesLoading = useSelector(selectAreRoutesLoading);
    const areStopsLoading = useSelector(selectAreStopsLoading);
    const { hasIsolatedRoutes } = useIsolatedRoutes();

    // reset zoom when selected map routes change
    useEffect(() => {
        dispatch(setLastPlanMapZoom(constants.mapOptionSettings.DEFAULT_ZOOM));
    }, [dispatch, selectedMapRoutes, isOpenUnassignedTasksDrawer]);

    // fit bounds effect when routes and stops are done loading
    //
    // @note:
    // + `handleLoadingData()` from `usePlanMapPropsContext() is responsible for
    //   setting `areRoutesLoading` and `areStopsLoading`
    // + a useEffect triggers `handleLoadingData()` when markers and/or mapRouteMode changes
    useEffect(() => {
        const isDataLoading = areRoutesLoading || areStopsLoading;
        if (isDataLoading) {
            return;
        }
        setShouldFitToBounds(true);
    }, [setShouldFitToBounds, areRoutesLoading, areStopsLoading]);

    // fit bounds when not isolating routes
    //
    // @note:
    // + updates when displaying routes or unassigned task markers
    useEffect(() => {
        if (hasIsolatedRoutes) {
            return;
        }
        setShouldFitToBounds(true);
    }, [setShouldFitToBounds, hasIsolatedRoutes]);
};
