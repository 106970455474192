import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ApiLiveDriver } from '~/api/types';
import { LiveDriver, InstantRoutesReportDriver } from '~/data-classes';
import { selectLiveDrivers } from '~/reducers/liveDriversSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';
import constants from '~/utils/constants';
import { XLSXUtil } from '~/data-classes/utils';
import { useGetDriverList, useSelectedClients } from '~/hooks';
import { useReportsContext } from '~/components/ReportsPage/ReportsContextProvider';
import { getInventoryData } from './utils';

type HookInstantRoutesReport = {
    downloadInstantRoutesReport: (date: string) => Promise<void>;
};

export const useInstantRoutesReport = (): HookInstantRoutesReport => {
    const { t } = useTranslation('reports');
    const liveDrivers = useSelector(selectLiveDrivers) as Record<
        string,
        ApiLiveDriver
    >;
    const mainClient = useSelector(selectMainClient);
    const mainClientName = mainClient?.name || constants.defaultMainClientName;
    const { selectedClientId } = useReportsContext();
    const { allClients, userHasMultiAccess } = useSelectedClients();
    const { selectedClientDriverList } = useGetDriverList();

    const clientNameForReport = userHasMultiAccess
        ? allClients[selectedClientId]?.name
        : mainClientName;

    const allLiveDrivers = useMemo(() => {
        return Object.values(liveDrivers)
            .filter((driver) => {
                const { clientId: driverClientId } = driver;
                return driverClientId === selectedClientId;
            })
            .reduce((allDrivers, driver) => {
                const { id } = driver;
                allDrivers[id] = new LiveDriver(driver);
                return allDrivers;
            }, {} as Record<string, LiveDriver>);
    }, [liveDrivers, selectedClientId]);

    const allDrivers = useMemo(() => {
        if (!selectedClientDriverList?.length) return [];

        return selectedClientDriverList
            .filter((driver) => {
                const { client } = driver;
                return client === selectedClientId;
            })
            .map((driver) => {
                const currentLiveDriver = allLiveDrivers[driver.id as string];

                const instantDriver = new InstantRoutesReportDriver(driver);
                if (currentLiveDriver) {
                    instantDriver.setLiveDriver(currentLiveDriver);
                }

                return instantDriver;
            });
    }, [selectedClientDriverList, allLiveDrivers, selectedClientId]);

    const getSummarySheetHeader = () => {
        return {
            routeId: t('liveDriver.columns.routeId'),
            driverName: t('liveDriver.columns.driverName'),
            assignmentsCompleted: t('liveDriver.columns.assignmentsCompleted'),
            assignmentsRemaining: t('liveDriver.columns.assignmentsRemaining'),
            mostRecentStatus: t('liveDriver.columns.mostRecentStatus'),
            percentageComplete: t('liveDriver.columns.percentageComplete')
        };
    };

    const getDetailSheetHeader = () => {
        return {
            routeId: t('liveDriver.columns.routeId'),
            driverName: t('liveDriver.columns.driverName'),
            assignmentOrder: t('liveDriver.columns.assignmentOrder'),
            assignmentName: t('liveDriver.columns.assignmentName'),
            delayStatus: t('liveDriver.columns.delayStatus'),
            estimatedServiceTime: t('liveDriver.columns.estimatedServiceTime'),
            serviceTime: t('liveDriver.columns.serviceTime'),
            timeWindowStart: t('liveDriver.columns.timeWindowStart'),
            timeWindowEnd: t('liveDriver.columns.timeWindowEnd'),
            arrivalTime: t('liveDriver.columns.arrivalTime'),
            city: t('liveDriver.columns.city'),
            type: t('liveDriver.columns.type'),
            stopLatitude: t('liveDriver.columns.stopLatitude'),
            stopLongitude: t('liveDriver.columns.stopLongitude'),
            units: t('liveDriver.columns.units'),
            weight: t('liveDriver.columns.weight'),
            labels: t('liveDriver.columns.labels'),
            priority: t('liveDriver.columns.priority')
        };
    };

    const getInventorySheetHeader = () => {
        return {
            routeId: t('liveDriver.columns.routeId'),
            driverName: t('liveDriver.columns.driverName'),
            assignmentOrder: t('liveDriver.columns.assignmentOrder'),
            assignmentName: t('liveDriver.columns.assignmentName'),
            itemId: t('liveDriver.columns.itemId'),
            itemName: t('liveDriver.columns.itemName'),
            expectedQuantity: t('liveDriver.columns.expectedQuantity'),
            actualQuantity: t('liveDriver.columns.actualQuantity'),
            scannedAt: t('liveDriver.columns.scannedAt'),
            status: t('liveDriver.columns.status'),
            reasonCode: t('liveDriver.columns.reasonCode'),
            barcode: t('liveDriver.columns.barcode'),
            signatureUrl: t('liveDriver.columns.signatureUrl'),
            photoUrl: t('liveDriver.columns.photoUrl')
        };
    };

    const getSummarySheet = () => {
        const summaryHeader = getSummarySheetHeader();
        const summaryData = allDrivers.map((driver) => {
            const { routeId, driverName, summary } = driver;

            return {
                routeId,
                driverName,
                ...summary
            };
        });

        return [{ ...summaryHeader }, ...summaryData];
    };

    const getDetailSheet = () => {
        const detailHeader = getDetailSheetHeader();
        const detailData = allDrivers
            .map((driver) => {
                const { routeId, driverName, details } = driver;
                const [driverRow, ...detailRows] = details;

                return [
                    {
                        routeId,
                        driverName,
                        ...driverRow
                    },
                    ...detailRows
                ];
            })
            .reduce((compiled, driverDetails) => {
                return [...compiled, ...driverDetails];
            }, []);

        return [{ ...detailHeader }, ...detailData];
    };

    const getInventorySheet = async (routeDate: string) => {
        const inventoryHeader = getInventorySheetHeader();
        const inventoryData = await getInventoryData(routeDate, allDrivers);

        return [{ ...inventoryHeader }, ...inventoryData];
    };

    const downloadInstantRoutesReport = async (routeDate: string) => {
        const clientName = clientNameForReport
            .toUpperCase()
            .replaceAll(' ', '_');
        const currentDate = DateTime.now();
        const downloadFilename = t('liveDriver.filename', {
            clientName,
            date: currentDate.toISODate()
        });
        const wb = new XLSXUtil();
        const sheetOptions = {
            skipHeader: true
        };
        const inventorySheet = await getInventorySheet(routeDate);

        wb.appendSheet(
            getSummarySheet(),
            t('liveDriver.sheets.summary'),
            sheetOptions
        );

        wb.appendSheet(
            getDetailSheet(),
            t('liveDriver.sheets.detail'),
            sheetOptions
        );

        wb.appendSheet(
            inventorySheet,
            t('liveDriver.sheets.inventory'),
            sheetOptions
        );

        wb.save(downloadFilename);
    };

    return { downloadInstantRoutesReport };
};
