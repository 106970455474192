import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import Supercluster from 'supercluster';

import { mapDrawingUtils, markerMaker } from '~/utils/map';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { ApiTask, Coordinates } from '~/api/types';
import { usePlanMapEventsContext } from '~/components/MapPage/PlanMap/PlanMapEventsContext';
import { RoutePolygons } from '~/components/MapPage/PlanMap/types';
import { useSelector } from 'react-redux';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { useHereMaps, useMapUtils } from '~/hooks';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import makeStopMarkers, { makeRouteLines } from '~/utils/map-modes/stops-mode';
import { selectAllSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import {
    selectIsClusteringStops,
    selectedIsClusteringUnassignedTasks
} from '~/reducers/mapSettingsSlice';
import makeStopAndClusterMarkers from '~/utils/map-modes/stops-clusters-mode';
import makeUnassignedClusterMarkers from '~/utils/map-modes/unassigned-tasks-cluster-mode';
import { selectUnassignedPlanTasks } from '~/reducers/tasksSlice';
import { selectHiddenRoutes } from '~/reducers/hiddenRoutesSlice';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import constants from '~/utils/constants';
import { selectLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';
import { useRoutePlanStopEffects } from '~/components/MapPage/PlanMap/useRoutePlanStopEffects';
import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';
import { selectLastPlanMapBounds } from '~/reducers/lastPlanMapBoundsSlice';
import { makeUnassignedStopMarkerEffects } from '../makeUnassignedStopMarkerEffects';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useMapEngineContext } from '~/components/MapEngineProvider';

type UsePlannedModeMapMarkersReturnProps = {
    allPolygonsRef: MutableRefObject<RoutePolygons>;
    routeLevelCoordinatesRef: MutableRefObject<Coordinates[]>;
    stopLevelCoordinatesRef: MutableRefObject<Coordinates[]>;
    superClusters: Supercluster.AnyProps[];
    unassignedSuperClusters: Supercluster.AnyProps[];
};

interface UsePlannedModeMapMarkersReturnValue {
    routeMarkers: JSX.Element[];
    routeLines: JSX.Element[];
    routeStopMarkers: JSX.Element[];
    routeCoordinates: Coordinates[];
}

const usePlannedModeMapMarkers = ({
    allPolygonsRef,
    routeLevelCoordinatesRef,
    stopLevelCoordinatesRef,
    superClusters,
    unassignedSuperClusters
}: UsePlannedModeMapMarkersReturnProps): UsePlannedModeMapMarkersReturnValue => {
    const [routeMarkers, setRouteMarkers] = useState<JSX.Element[]>([]);
    const [routeStopMarkers, setRouteStopMarkers] = useState<JSX.Element[]>([]);
    const [routeLines, setRouteLines] = useState<JSX.Element[]>([]);
    const [routeCoordinates] = useState<Coordinates[]>([]);
    const { isHerePlanMapActive } = useHereMaps();

    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    const {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        visibleOnMapRouteData,
        routesLevelData,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mapsAPI
    } = usePlanMapPropsContext();
    const { emittedEventHandler } = usePlanMapEventsContext();
    const { mapInstance } = useMapEngineContext();
    const {
        isRoutesMode,
        isPlanRouteMode,
        mapMarkerMode,
        isStopsClustersMode,
        mapRouteMode
    } = useMapUtils();

    const isClusteringUnassignedTasks = useSelector(
        selectedIsClusteringUnassignedTasks(
            mapRouteMode as ConfigurableMapRouteMode
        )
    );
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);
    const serialSelectedLiveDriver = useSelector(
        selectLiveDriverById(selectedDrawerRouteId)
    );
    const selectedDate = useSelector(selectDateOnly);
    const allSelectedDrawerCardData = useSelector(
        selectAllSelectedDrawerCardData
    );
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const unassignedPlanTasks = useSelector(selectUnassignedPlanTasks);
    const hiddenRoutes = useSelector(selectHiddenRoutes);
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const lastPlanMapZoom = useSelector(selectLastPlanMapZoom);
    const lastPlanMapBounds = useSelector(selectLastPlanMapBounds);

    const {
        getParentClientRouteId,
        clientRouteIds: clientRouteIdsForSelectedMapRoutes
    } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });

    const { visibleOnMapPlanStops } = useRoutePlanStopEffects({
        getParentClientRouteId,
        clientRouteIdsForSelectedMapRoutes
    });

    function filterHiddenUnassignedPlanTasks() {
        return unassignedPlanTasks.filter((task) => {
            const { client: clientId } = task as ApiTask;
            const clientRouteId = `${clientId}_${constants.entityStates.UNPLANNED}`;
            return !hiddenRoutes[clientRouteId];
        });
    }

    useEffect(() => {
        if (isRoutesMode) {
            setRouteMarkers([]);
        } else {
            setRouteLines([]);
            setRouteStopMarkers([]);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedDate]);

    // route markers
    useEffect(() => {
        if (!isPlanRouteMode) {
            return;
        }
        const routeMarkerEffects = [];
        routeLevelCoordinatesRef.current = [];
        if (mapsAPI) {
            mapDrawingUtils.removeAllMapObjects(allPolygonsRef.current);
            allPolygonsRef.current = {};
        }
        for (const routeLevelData of visibleOnMapRouteData) {
            const {
                markerCoordinates,
                perimeterCoordinates,
                colorCSS,
                clientRouteId
            } = routeLevelData;
            const routeMarker = markerMaker.makeRouteMarker(
                routeLevelData,
                emittedEventHandler
            );
            routeMarkerEffects.push(routeMarker);
            routeLevelCoordinatesRef.current.push(markerCoordinates);

            if (mapsAPI) {
                const routePolygon = mapDrawingUtils.makePolygon(
                    mapsAPI,
                    perimeterCoordinates,
                    { color: colorCSS.backgroundColor }
                );
                allPolygonsRef.current[clientRouteId] = routePolygon;
            }
        }
        setRouteMarkers(routeMarkerEffects);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        visibleOnMapRouteData,
        mapsAPI,
        isPlanRouteMode,
        serialSelectedLiveDriver
    ]);

    // route lines
    useEffect(() => {
        if (!isPlanRouteMode || !mapInstance) {
            return;
        }

        const allSelectedRouteIds = allSelectedDrawerCardData.map(
            ({ data: selectedDrawerCardData }) => {
                const {
                    route: { routeId }
                } = selectedDrawerCardData as { route: { routeId: string } };

                return routeId;
            }
        );
        const routePlans = !allSelectedRouteIds.length
            ? routesLevelData
            : routesLevelData.filter(({ routeId }) =>
                  allSelectedRouteIds.includes(routeId)
              );

        const newPlannedRouteLines = makeRouteLines({
            mapInstance,
            routePlans,
            allPlannedStops: visibleOnMapPlanStops.flat()
        });

        setRouteLines(isClusteringStops ? [] : newPlannedRouteLines);
    }, [
        allSelectedDrawerCardData,
        isClusteringStops,
        mapInstance,
        isPlanRouteMode,
        visibleOnMapPlanStops,
        routesLevelData
    ]);

    useEffect(() => {
        if (!isStopsClustersMode) {
            return;
        }
        stopLevelCoordinatesRef.current = [];

        for (const planStopRoute of visibleOnMapPlanStops) {
            for (const planStop of planStopRoute) {
                if (planStop.isDepot) {
                    continue;
                }
                const { markerCoordinates } = planStop;
                stopLevelCoordinatesRef.current.push(markerCoordinates);
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [visibleOnMapPlanStops, isStopsClustersMode]);

    const getClusters = useCallback(
        (superCluster) => {
            if (isHerePlanMapActive) {
                return superCluster.points;
            }
            return superCluster.getClusters(lastPlanMapBounds, lastPlanMapZoom);
        },
        [isHerePlanMapActive, lastPlanMapBounds, lastPlanMapZoom]
    );

    // route stops or clusters
    useEffect(() => {
        if (!lastPlanMapBounds || !isPlanRouteMode) {
            return;
        }
        const stopClusterMarkerEffects = [];
        const coordinatesOffset = {};

        if (isOpenUnassignedTasksDrawer) {
            const filteredPlanTasks = filterHiddenUnassignedPlanTasks();
            if (!filteredPlanTasks.length) {
                setRouteStopMarkers([]);
                return;
            }

            const stopMarkerEffects = makeUnassignedStopMarkerEffects({
                isClusteringStops: isClusteringUnassignedTasks,
                unassignedSuperClusters,
                getClusters,
                makeUnassignedClusterMarkers,
                emittedEventHandler,
                planTasks: unassignedPlanTasks
            });

            setRouteStopMarkers(stopMarkerEffects);
            return;
        }

        for (let index = 0; index < superClusters.length; index++) {
            const superCluster = superClusters[index];
            let markerComponents = [];
            const geoJSONFeatures = getClusters(superCluster);

            if (isClusteringStops) {
                markerComponents = makeStopAndClusterMarkers(
                    superCluster,
                    geoJSONFeatures,
                    index,
                    emittedEventHandler,
                    coordinatesOffset
                );
            } else {
                markerComponents = makeStopMarkers(
                    superCluster,
                    geoJSONFeatures,
                    emittedEventHandler,
                    coordinatesOffset
                );
            }
            stopClusterMarkerEffects.push(...markerComponents);
        }
        setRouteStopMarkers(stopClusterMarkerEffects);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        emittedEventHandler,
        hiddenRoutes,
        superClusters,
        isPlanRouteMode,
        lastPlanMapBounds,
        lastPlanMapZoom,
        mapMarkerMode,
        isClusteringStops,
        unassignedPlanTasks,
        getClusters,
        unassignedSuperClusters,
        isClusteringUnassignedTasks
    ]);

    return {
        routeMarkers,
        routeLines,
        routeStopMarkers,
        routeCoordinates
    };
};

export default usePlannedModeMapMarkers;
