import { Duration } from 'luxon';

const oneHourInMinutes = 60;
/**
 * Converts seconds to hours and minutes
 *
 * @param {Number} seconds
 * @returns {Object} An object with hours (number) and minutes (number)
 */
function mapSecondsToHoursAndMinutes(seconds: number) {
    const duration = Duration.fromObject({ seconds });
    const durationAsHours = duration.as('hours');
    const hoursAsString = durationAsHours.toString();
    const indexOfDecimal = hoursAsString.indexOf('.');
    const isWithoutDecimal = indexOfDecimal === -1;

    if (isWithoutDecimal) {
        return {
            hours: durationAsHours,
            minutes: 0
        };
    }

    const hours = Number(hoursAsString.substring(0, indexOfDecimal));
    const durationAsMinutes = Number(duration.as('minutes').toFixed(0));
    const minutes = durationAsMinutes - hours * oneHourInMinutes;

    return {
        hours,
        minutes
    };
}

/**
 * Convert seconds to number of minutes
 *
 * @param { Number } seconds - The number of seconds to calculate the duration from
 * @returns { Object } An object representing the duration in minutes
 */
function convertSecondsToMinutes(seconds: number) {
    return Duration.fromMillis(seconds * 1000)
        .shiftTo('minutes')
        .toObject();
}

/**
 * Converts an ISO string representing a duration to milliseconds
 * @param {string} durationString - The ISO string representing the duration
 * @returns {number} The duration in milliseconds
 */
function convertDurationToMilliseconds(durationString: string) {
    return Duration.fromISO(durationString).as('milliseconds');
}

/**
 * Converts an ISO string representing a duration to hours
 * @param {string} durationString - The ISO string representing the duration
 * @returns {number} The duration in hours
 */
function convertDurationToHours(durationString: string) {
    return Duration.fromISO(durationString).as('hours');
}

export default {
    mapSecondsToHoursAndMinutes,
    convertSecondsToMinutes,
    convertDurationToMilliseconds,
    convertDurationToHours
};
