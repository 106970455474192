import React from 'react';
import { useTranslation } from 'react-i18next';

import { Task } from '~/data-classes';

import { ApiTask, TimeWindow } from '~/api/types';
import { IconButton, theme } from '~/ui';

import localizationUtils from '~/utils/localization-utils';
import dateUtils from '~/utils/date-utils';

import { TaskTimeContent } from '../TaskTimeContent';

import './TaskCard.scss';

interface TaskCardProps {
    task: ApiTask;
}

export const TaskCard: React.FC<TaskCardProps> = ({ task: apiTask }) => {
    const { t } = useTranslation(['liveDispatchTable']);

    const task = new Task(apiTask);

    const {
        name,
        pickupTask,
        deliveryTask,
        pickupLocationAddress,
        deliveryLocationAddress
    } = task;

    const locationAddress =
        pickupLocationAddress || deliveryLocationAddress || '';
    const locationAddressText =
        localizationUtils.formatAddress(locationAddress);

    const {
        props: { pickupServiceTime, pickupWindow }
    } = pickupTask || { props: {} };

    const {
        props: { deliveryServiceTime, deliveryWindow }
    } = deliveryTask || { props: {} };

    const serviceTime = pickupServiceTime || deliveryServiceTime || '';
    const { hours, minutes, seconds } =
        dateUtils.convertISOStringToDurationObjectUnits(serviceTime);
    const serviceTimeHours = hours
        ? t('dispatchPopup.taskCard.serviceTimeHours', { hours })
        : '';
    const serviceTimeMinutes = minutes
        ? t('dispatchPopup.taskCard.serviceTimeMinutes', { minutes })
        : '';
    const serviceTimeSeconds = seconds
        ? t('dispatchPopup.taskCard.serviceTimeSeconds', { seconds })
        : '';

    const serviceTimeText = [
        serviceTimeHours,
        serviceTimeMinutes,
        serviceTimeSeconds
    ];

    const [firstTimeWindow] = pickupWindow || deliveryWindow || [];

    const timeWindowToString = (timeWindow: TimeWindow) => {
        const { startTime, endTime } =
            dateUtils.convertTimeWindowToStringFormat(timeWindow);
        return t('timeWindow.range', { startTime, endTime });
    };

    const timeWindowText = timeWindowToString(firstTimeWindow);

    return (
        <div
            className="live-dispatch-task-card"
            data-testid="live-dispatch-task-card"
        >
            <IconButton
                className="live-dispatch-task-card__marker"
                text="?"
                textColor={theme.colors.comet}
                textStyle={{
                    ...theme.text['14-medium']
                }}
                disabled
            />
            <div className="live-dispatch-task-card__summary">
                <span
                    className="live-dispatch-task-card__text"
                    data-testid="live-dispatch-task-card__text"
                >
                    {name}
                </span>
                <span className="live-dispatch-task-card__text">
                    {locationAddressText}
                </span>
                <div
                    className="live-dispatch-task-card__service-time-wrapper"
                    data-testid="live-dispatch-task-card__service-time-wrapper"
                >
                    <TaskTimeContent
                        icon="hourglass"
                        text={serviceTimeText.join(' ').trim()}
                        data-testid="live-dispatch-task-card__service-time"
                    />
                    <TaskTimeContent
                        icon="clock"
                        text={timeWindowText}
                        data-testid="live-dispatch-task-card__time-window"
                    />
                </div>
            </div>
        </div>
    );
};
