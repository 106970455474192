import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
    addSelectedMapStop,
    removeSelectedMapStop
} from '~/reducers/selectedMapStopsSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { HandleSelectUnassignedTaskProps } from './types';

export const useUnassignedTaskClickHandler = () => {
    const dispatch = useDispatch();

    const handleSelectUnassignedTask = useCallback(
        ({ stopData, isTaskSelected }: HandleSelectUnassignedTaskProps) => {
            const markerIdsToSelect = [];
            if (stopData.isPickup) {
                markerIdsToSelect.push(
                    stopData.pickupStopData.clientRouteTaskId
                );
            }
            if (stopData.isDelivery) {
                markerIdsToSelect.push(
                    stopData.deliveryStopData.clientRouteTaskId
                );
            }

            dispatch(resetSelectedTaskRowId());

            markerIdsToSelect.forEach((clientRouteTaskId) => {
                if (isTaskSelected) {
                    dispatch(removeSelectedMapStop(clientRouteTaskId));
                } else {
                    dispatch(addSelectedMapStop(clientRouteTaskId));
                }
            });
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        []
    );

    return { handleSelectUnassignedTask };
};
