import { ReactElement, useMemo } from 'react';

import { useMapUtils } from '~/hooks';
import { usePlannedMarkersConfig } from '~/components/HereMaps/useMarkersConfig/usePlannedMarkersConfig';
import { useDispatchedMarkersConfig } from '~/components/HereMaps/useMarkersConfig/useDispatchedMarkersConfig';
import { useCompletedMarkersConfig } from '~/components/HereMaps/useMarkersConfig/useCompletedMarkersConfig';

interface UseMarkersConfig {
    markers: ReactElement[];
}

export const useMarkersConfig = ({ markers }: UseMarkersConfig) => {
    const { hoveredRouteMarker: plannedHovered, markersConfig: plannedConfig } =
        usePlannedMarkersConfig({ markers });
    const {
        hoveredRouteMarker: dispatchedHovered,
        markersConfig: dispatchedConfig
    } = useDispatchedMarkersConfig({ markers });

    const {
        hoveredRouteMarker: completedHovered,
        markersConfig: completedConfig
    } = useCompletedMarkersConfig({ markers });

    const { isPlanRouteMode, isDispatchedRouteMode, isCompletedRouteMode } =
        useMapUtils();

    const markersConfig = useMemo(() => {
        if (isPlanRouteMode) {
            return plannedConfig;
        }
        if (isDispatchedRouteMode) {
            return dispatchedConfig;
        }
        if (isCompletedRouteMode) {
            return completedConfig;
        }
        return [];
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        markers,
        plannedConfig,
        dispatchedConfig,
        completedConfig,
        isPlanRouteMode,
        isCompletedRouteMode,
        isDispatchedRouteMode
    ]);

    const hoveredRouteMarker = useMemo(() => {
        if (isPlanRouteMode) {
            return plannedHovered;
        }
        if (isDispatchedRouteMode) {
            return dispatchedHovered;
        }
        if (isCompletedRouteMode) {
            return completedHovered;
        }
        return null;
    }, [
        dispatchedHovered,
        plannedHovered,
        completedHovered,
        isDispatchedRouteMode,
        isCompletedRouteMode,
        isPlanRouteMode
    ]);

    return {
        markersConfig,
        hoveredRouteMarker
    };
};
