import { FilterFn } from '@tanstack/react-table';
import { Equipment } from '~/data-classes';

/**
 * column filter function for the `type & status` column of the equipment table
 */
export const columnFilterStringValues: FilterFn<Equipment> = (
    row,
    columnId,
    columnFilterValue
) => {
    const rowCellValue: string = row.getValue(columnId);
    return columnFilterValue.includes(rowCellValue);
};

export const columnFilterState: FilterFn<Equipment> = (
    row,
    columnId,
    columnFilterValue
) => {
    const rowCellValue: string[] = row.getValue(columnId);
    return rowCellValue.some((value) => columnFilterValue.includes(value));
};

/**
 * column filter function for the `UpdateAt` column of the Live Dispatch table
 */
export const columnFilterUpdateAt: FilterFn<Equipment> = (
    row,
    columnId,
    columnFilterValue
) => {
    const rowCellValue: string = row.getValue(columnId);
    const datePart: string = rowCellValue.split('T')[0];
    return columnFilterValue.includes(datePart);
};
