import {
    Header,
    HeaderGroup,
    Row,
    Cell,
    OnChangeFn
} from '@tanstack/react-table';
import { GetEquipmentParams } from '~/api/EquipmentApi';
import { ExtendedPaginationParams, TaskTypeRecord } from '~/api/types';
import { LiveDispatchTaskTableRowData, Equipment } from '~/data-classes';
import { UIElement, UIElementWithChildren } from '~/ui';

/**
 * Available table modes for the Live Dispatch Drawer
 */
export enum LiveDispatchDrawerTableMode {
    TASK_TABLE = 'taskTable',
    EQUIPMENT_TABLE = 'equipmentTable'
}

/**
 * Column IDs for the unassigned tasks table
 */
export enum LiveDispatchTableColumnId {
    TASK_TYPE = 'rowTaskType',
    EXT_TASK_TYPE = 'rowExtTaskType',
    NAME = 'rowName',
    LABELS = 'rowLabels',
    PICKUP_START = 'rowPickupStartTime',
    PICKUP_END = 'rowPickupEndTime',
    DELIVERY_START = 'rowDeliveryStartTime',
    DELIVERY_END = 'rowDeliveryEndTime',
    CUSTOMER_NAME = 'rowCustomerName',
    CUSTOMER_ADDRESS = 'rowCustomerAddress',
    CUSTOMER_CITY = 'rowCustomerCity',
    PICKUP_CUSTOMER_NAME = 'rowPickupCustomerName',
    PICKUP_CUSTOMER_ADDRESS = 'rowPickupCustomerAddress',
    PICKUP_CUSTOMER_CITY = 'rowPickupCustomerCity',
    DELIVERY_CUSTOMER_NAME = 'rowDeliveryCustomerName',
    DELIVERY_CUSTOMER_ADDRESS = 'rowDeliveryCustomerAddress',
    DELIVERY_CUSTOMER_CITY = 'rowDeliveryCustomerCity',
    EXT_LINKS = 'rowExtLinks',
    NOTES = 'rowNotes',
    EQUIPMENT_ID = 'rowEquipmentId',
    PRIORITY = 'rowTaskPriority',
    PAYOR_OF_FREIGHT = 'rowEquipmentPayerOfFreight',
    RESERVATION = 'rowEquipmentReservation',
    EQUIPMENT_TYPE = 'rowEquipmentType',
    DROP_STAY = 'rowEquipmentDropStay'
}

/**
 * Mapping for unassigned tasks column IDs and sortable property keys
 */
export const columnIdAndSortablePropertyKeysMapping: Record<string, string> = {
    [LiveDispatchTableColumnId.NAME]: 'name',
    [LiveDispatchTableColumnId.EXT_TASK_TYPE]: 'externalTaskType',
    [LiveDispatchTableColumnId.DELIVERY_CUSTOMER_NAME]: 'deliveryLocation.name',
    [LiveDispatchTableColumnId.DELIVERY_CUSTOMER_ADDRESS]:
        'deliveryLocation.addressLine1',
    [LiveDispatchTableColumnId.DELIVERY_CUSTOMER_CITY]: 'deliveryLocation.city',
    [LiveDispatchTableColumnId.PICKUP_CUSTOMER_NAME]: 'pickupLocation.name',
    [LiveDispatchTableColumnId.PICKUP_CUSTOMER_ADDRESS]:
        'pickupLocation.addressLine1',
    [LiveDispatchTableColumnId.PICKUP_CUSTOMER_CITY]: 'pickupLocation.city',
    [LiveDispatchTableColumnId.PRIORITY]: 'props.priority',
    [LiveDispatchTableColumnId.PICKUP_START]: 'props.pickupWindow.start',
    [LiveDispatchTableColumnId.PICKUP_END]: 'props.pickupWindow.end',
    [LiveDispatchTableColumnId.DELIVERY_START]: 'props.deliveryWindow.start',
    [LiveDispatchTableColumnId.DELIVERY_END]: 'props.deliveryWindow.end',
    [LiveDispatchTableColumnId.EQUIPMENT_ID]: 'equipmentId',
    [LiveDispatchTableColumnId.PAYOR_OF_FREIGHT]: 'payerOfFreight',
    [LiveDispatchTableColumnId.RESERVATION]: 'reservation',
    [LiveDispatchTableColumnId.EQUIPMENT_TYPE]: 'carKind'
};

/**
 * Column IDs for the equipment table
 */
export enum EquipmentTableColumnId {
    ID = 'id',
    NAME = 'name',
    TYPE = 'type',
    COORDINATES = 'coordinates',
    STATUS = 'status',
    STATE = 'state',
    ATTACHED = 'attached',
    UPDATED_AT = 'updatedAt',
    CUSTOMERID = 'customerId',
    CUSTOMERADDRESSLINE1 = 'customerAddressLine1',
    CUSTOMERCITY = 'customerCity',
    CUSTOMERSTATE = 'customerState',
    CUSTOMERPOSTALCODE = 'customerPostalCode',
    CUSTOMERNAME = 'customerName',
    DISPATCHZONE = 'dispatchZone',
    AREA = 'area',
    CHASSISID = 'chassisId'
}

/**
 * Mapping for equipment column IDs and column filter keys
 */
export const equipmentColumnIdToColumnFilterKeyMap: Record<string, string> = {
    [EquipmentTableColumnId.TYPE]: 'type',
    [EquipmentTableColumnId.STATUS]: 'status',
    [EquipmentTableColumnId.STATE]: 'state',
    [EquipmentTableColumnId.UPDATED_AT]: 'timestamp'
};

/*
 * column Id and sortable key mapping for equipment table
 */
export const equipmentTableColumnIdAndSortableKeyMapping: Record<
    string,
    string
> = {
    [EquipmentTableColumnId.ID]: 'ID',
    [EquipmentTableColumnId.NAME]: 'NAME',
    [EquipmentTableColumnId.TYPE]: 'TYPE',
    [EquipmentTableColumnId.STATUS]: 'STATUS',
    [EquipmentTableColumnId.CUSTOMERID]: 'CUSTOMER_ID',
    [EquipmentTableColumnId.CUSTOMERADDRESSLINE1]: 'CUSTOMER_ADDRESS_LINE1',
    [EquipmentTableColumnId.CUSTOMERCITY]: 'CUSTOMER_CITY',
    [EquipmentTableColumnId.CUSTOMERSTATE]: 'CUSTOMER_STATE',
    [EquipmentTableColumnId.CUSTOMERPOSTALCODE]: 'CUSTOMER_POSTAL_CODE',
    [EquipmentTableColumnId.CUSTOMERNAME]: 'CUSTOMER_NAME',
    [EquipmentTableColumnId.AREA]: 'AREA'
};

/**
 * Generic interface for table header row components
 *
 * Use of this interface requires that the table be generated using React-Table v8
 *
 * @see {@link https://tanstack.com/table/v8 | React-Table v8}
 */
export interface TableHeaderRowProps<TData> {
    /**
     * the table header group
     *
     * @see {@link https://tanstack.com/table/v8/docs/api/core/header-group | React-Table v8, HeaderGroup API}
     */
    headerGroup: HeaderGroup<TData>;

    /**
     * whether the table has selected rows
     */
    hasSelectedRows?: boolean;
}

/**
 * Generic interface for table header cell components
 *
 * Use of this interface requires that the table be generated using React-Table v8
 *
 * @see {@link https://tanstack.com/table/v8 | React-Table v8}
 */
export interface TableHeaderProps<TData, TValue = unknown>
    extends UIElementWithChildren {
    /**
     * the table header
     *
     * @see {@link https://tanstack.com/table/v8/docs/api/core/header | React-Table v8, Header API}
     */
    header?: Header<TData, TValue>;
}

/**
 * Generic interface for table data row components
 *
 * Use of this interface requires that the table be generated using React-Table v8
 *
 * @see {@link https://tanstack.com/table/v8 | React-Table v8}
 */
export interface TableRowProps<TData> {
    /**
     * The table row data
     *
     * This object contains all the data to render the table cells.
     * Table cell values are keyed to the identified `accessorKey` prop
     * in `columnHeaders` from `useReactTable` hook.
     *
     * @see {@link https://tanstack.com/table/v8/docs/api/core/row | React-Table v8, Row API}
     *
     */
    row: Row<TData>;
}

/**
 * Generic interface for table cell components
 *
 * Use of this interface requires that the table be generated using React-Table v8
 *
 * @see {@link https://tanstack.com/table/v8 | React-Table v8}
 */
export interface TableCellProps<TData, TValue> {
    /**
     * The table cell data
     *
     * This object contains all the data to render the table cells.
     * Table cell values are keyed to the identified `accessorKey` prop
     * in `columnHeaders` from `useLiveDispatchTableData` hook.
     *
     * @see {@link https://tanstack.com/table/v8/docs/api/core/row | React-Table v8, Row API}
     *
     */
    cell: Cell<TData, TValue>;
}

/**
 * Generic interface for table data cell components
 */
export interface TableDataProps<TData> extends UIElement {
    /**
     * The data to use to populate the live dispatch table cell
     *
     * This is meant to be used for table cells that need to output data
     * common to any task type
     */
    data?: TData | null;

    /**
     * The tooltip for the table cell data
     *
     * Declaring this property will override the use of `TextOverflowTooltip`
     */
    tooltip?: string;

    /**
     * whether to truncate the table cell data
     *
     * This applies a class that would allow the `TextOverflowTooltip` to function.
     * You will need to set either a CSS `width` or `max-width` for the truncation
     * to fully work with the `TextOverflowTooltip` component
     */
    truncate?: boolean;

    /**
     * The matched search string
     *
     * When table searching is enabled and the user enters a search string,
     * that value is reflected here. this may be used to `highlight`
     * matching searched text in the table cells
     */
    searchText?: string;
}

/**
 * All possible cell keys for the unassigned tasks table
 */
type UnassignedTasksTableCellKeys = keyof LiveDispatchTaskTableRowData;

/**
 * All possible cell value types for the unassigned tasks table
 */
type UnassignedTasksTableCellValues =
    LiveDispatchTaskTableRowData[UnassignedTasksTableCellKeys];

/**
 * Properties for unassigned tasks table cell components
 */
export type UnassignedTasksTableDataProps = TableCellProps<
    LiveDispatchTaskTableRowData,
    UnassignedTasksTableCellValues
>;

/**
 * All possible cell keys for the equipment table
 */
type EquipmentTableCellKeys = keyof Equipment;

/**
 * All possible cell value types for the equipment table
 */
type EquipmentTableCellValues = Equipment[EquipmentTableCellKeys];

/**
 * Properties for equipment table cell components
 */
export type EquipmentTableDataProps = TableCellProps<
    Equipment,
    EquipmentTableCellValues
>;

export interface HandleChangeProps {
    /**
     * value for verifying the status of the checkbox
     */
    isChecked: boolean;
    /**
     * value of the checkbox field
     */
    value?: string | number | unknown;
    /**
     * identifies which values are selected in the rows
     */
    selectedValues?: Array<string | number | unknown>;
}

export interface LiveDispatchTableDataTaskTypeRecordProps<TData>
    extends UIElement {
    /**
     * The task record data to use to populate the live dispatch table cell
     *
     * This is meant to be used for table cells that need to output data
     * for `pickup` and/or `delivery` tasks
     */
    data?: TaskTypeRecord<TData> | null;

    /**
     * The matched search string
     *
     * When table searching is enabled and the user enters a search string,
     * that value is reflected here. this may be used to `highlight`
     * matching searched text in the table cells
     */
    searchText?: string;
}

export interface LiveDispatchTableDataProps<TData> extends UIElement {
    /**
     * The data to use to populate the live dispatch table cell
     *
     * This is meant to be used for table cells that need to output data
     * common to any task type
     */
    data?: TData | null;

    /**
     * The tooltip for the table cell data
     *
     * Declaring this property will override the use of `TextOverflowTooltip`
     */
    tooltip?: string;

    /**
     * whether to truncate the table cell data
     *
     * This applies a class that would allow the `TextOverflowTooltip` to function.
     * You will need to set either a CSS `width` or `max-width` for the truncation
     * to fully work with the `TextOverflowTooltip` component
     */
    truncate?: boolean;

    /**
     * The matched search string
     *
     * When table searching is enabled and the user enters a search string,
     * that value is reflected here. this may be used to `highlight`
     * matching searched text in the table cells
     */
    searchText?: string;
}

export interface LiveDispatchTableSettingsContextValues {
    /**
     * The search text to globally filter the live dispatch table by
     */
    globalFilter: string;

    /**
     * Sets the search text
     */
    setGlobalFilter: (value: string) => void;
}

export interface LiveDispatchTablePaginationContextValues<TData> {
    /**
     * Pagination option for Live Dispatch drawer
     */
    apiPaginationOptions: TData;

    /**
     * Sets Pagination option for Live Dispatch drawer
     */
    setApiPaginationOptions: OnChangeFn<TData>;

    /**
     * The current page index for the live dispatch table's pagination
     */
    pageIndex: number;

    /**
     * Sets the current page index
     */
    setPageIndex: OnChangeFn<number>;

    /**
     * The current page index for the live dispatch table's pagination
     */
    pageSize: number;

    /**
     * Sets the page size
     */
    setPageSize: OnChangeFn<number>;
}

export interface LiveDispatchTableContextValues
    extends LiveDispatchTableSettingsContextValues,
        LiveDispatchTablePaginationContextValues<ExtendedPaginationParams> {
    /**
     * Live Dispatch Drawer Selected Table value
     */
    tableMode: LiveDispatchDrawerTableMode;

    /**
     * Allows to update Live Dispatch Drawer Selected Table value
     */
    setTableMode: OnChangeFn<LiveDispatchDrawerTableMode>;

    /**
     * Total count of Live Dispatch drawer entries
     */
    totalLiveDispatchCount: number;

    /**
     * Set Total count of Live Dispatch drawer entries
     */
    setTotalLiveDispatchCount: OnChangeFn<number>;
}

export interface EquipmentTableContextValues
    extends LiveDispatchTablePaginationContextValues<GetEquipmentParams> {
    /**
     * Total count of Equipment drawer entries
     */
    totalEquipmentCount: number;

    /**
     * Set Total count of Equipment drawer entries
     */
    setTotalEquipmentCount: OnChangeFn<number>;

    /**
     * The page total
     */
    pageTotal: number;

    /**
     * Sets the page total
     */
    setPageTotal: OnChangeFn<number>;

    /**
     * The current page index for the equipment table's pagination
     */
    evalualatedKeys: string[];

    /**
     * Sets the page size
     */
    setEvalualatedKeys: (value: string[]) => void;
}

export interface LiveDispatchTableContextProviderProps {
    /**
     * the context provider's children
     */
    children: React.ReactNode;
}

export interface EquipmentTableContextProviderProps {
    /**
     * the context provider's children
     */
    children: React.ReactNode;
}
