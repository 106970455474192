import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApiLiveDriver } from '~/api/types';
import { LiveDriver } from '~/data-classes';
import {
    useGetDriverList,
    useLiveDispatchTasks,
    useTaskManualDispatch,
    useWebInterval
} from '~/hooks';
import { setIsSaveAndAssignTaskMode } from '~/reducers/addTaskSlice';
import {
    selectLiveDispatchSelectedDriver,
    setSelectedDriverData
} from '~/reducers/liveDispatchSelectedDriverSlice';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import {
    selectIsOpenDispatchManagementPopup,
    setIsOpenDispatchManagementPopup,
    setIsOpenLiveDispatchDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import {
    resetSelectedDrawerCardId,
    selectSelectedDrawerCardId
} from '~/reducers/selectedDrawerCardIdSlice';
import { resetSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import {
    resetSelectedTaskIds,
    selectSelectedTaskIds
} from '~/reducers/selectedTaskIdsSlice';
import { getAllTasks } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import { colorUtils } from '~/utils/color-utils';
import constants from '~/utils/constants';

const MAX_PAGE_SIZE = 200;

export const useLiveDispatchAssignTasks = () => {
    const dispatch = useDispatch();
    const { refetch } = useWebInterval();
    const selectedDate = useSelector(selectDate);

    const { t } = useTranslation(['taskManagement', 'common']);

    const isPopupDisplayed = useSelector(selectIsOpenDispatchManagementPopup);
    const selectedDriver = useSelector(selectLiveDispatchSelectedDriver);
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const driverData = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    ) as ApiLiveDriver;
    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const { driversList } = useGetDriverList();

    const { isFiltered, unassignedTasks } = useLiveDispatchTasks({
        selectedTaskIds,
        apiPaginationOptions: {
            limit: MAX_PAGE_SIZE,
            page: 0
        }
    });

    const { manualDispatchTask } = useTaskManualDispatch();

    const { driverId: selectedDriverId, driverData: selectedDriverData } =
        selectedDriver;

    const finalDriverData = driverData || selectedDriverData;

    useEffect(() => {
        const [filteredDriver] = (driversList || []).filter(
            ({ driver }) => driver === selectedDriverId
        );

        if (filteredDriver) {
            dispatch(setSelectedDriverData(filteredDriver));
        }
    }, [dispatch, selectedDriverId, driversList]);

    const handleCancelButtonClick = useCallback(() => {
        dispatch(setIsOpenDispatchManagementPopup(false));
        dispatch(setIsSaveAndAssignTaskMode(false));
        dispatch(setIsOpenLiveDispatchDrawer(true));
        dispatch(resetSelectedDrawerCardId());
        dispatch(setSelectedDriverData(false));
    }, [dispatch]);

    const handleDispatchButtonClick = useCallback(async () => {
        if (!finalDriverData) return;

        dispatch(setIsOpenDispatchManagementPopup(false));
        dispatch(setIsSaveAndAssignTaskMode(false));

        const { id: driverId } = finalDriverData;

        const tasksToDispatch = unassignedTasks.map((unassignedTask) => {
            return {
                id: unassignedTask.id
            };
        });

        await manualDispatchTask(
            {
                driverId,
                tasksToDispatch,
                position: constants.assignmentPosition
            },
            {
                onSuccess: () => {
                    dispatch(resetSelectedTaskIds(null));
                    dispatch(resetSelectedEquipmentIds());
                    dispatch(
                        addToast({
                            message: t('assignTask.dispatchConfirmation', {
                                count: selectedTaskIds.length
                            }),
                            variant: 'info'
                        })
                    );
                    dispatch(
                        getAllTasks({
                            routeDate: selectedDate
                        })
                    );
                },
                onError: () => {
                    dispatch(
                        addToast({
                            message: t('error:assignTasksError'),
                            variant: 'error'
                        })
                    );
                }
            }
        );

        dispatch(resetSelectedDrawerCardId());
        dispatch(setSelectedDriverData(false));
        refetch();
    }, [
        dispatch,
        finalDriverData,
        manualDispatchTask,
        refetch,
        selectedDate,
        selectedTaskIds,
        t,
        unassignedTasks
    ]);

    const handleDriverCancelButtonClick = useCallback(() => {
        dispatch(resetSelectedDrawerCardId());
        dispatch(setSelectedDriverData(false));
    }, [dispatch]);

    return {
        isPopupDisplayed,
        selectedDrawerCardId,
        driverData: finalDriverData,
        driverId: selectedDriver.driverId,
        colorCSS: finalDriverData
            ? colorUtils.getWebColorsForId(
                  new LiveDriver(finalDriverData).clientDriverId
              )
            : undefined,
        selectedTaskIds,
        isFiltered,
        unassignedTasks,
        handleCancelButtonClick,
        handleDispatchButtonClick,
        handleDriverCancelButtonClick
    };
};
