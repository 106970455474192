import constants from '~/utils/constants';
import { LiveDispatchTaskTableRowData } from '~/data-classes';

export interface ExtTypeFilterList {
    name: string;
    numberOfEntries: number;
}

type ExtTypeFilterMap = Record<string, ExtTypeFilterList>;

interface GetExtTaskTypeFilterListProps {
    unassignedTasks: LiveDispatchTaskTableRowData[];
    isPredefinedExternalTaskTypeEnabled: boolean;
}

export const getPredefinedExternalTaskTypes = (): ExtTypeFilterMap => {
    const { externalTaskTypePredefinedData, other, none } = constants;

    const mergedExternalTaskTypeArray = Object.values(
        externalTaskTypePredefinedData
    ).reduce((acc, currentValue) => [...acc, ...currentValue], []);

    const uniqueExternalTaskTypeArray = [
        ...new Set(mergedExternalTaskTypeArray)
    ];

    const predefinedTypes = [
        ...uniqueExternalTaskTypeArray,
        other,
        none
    ] as string[];
    const predefinedTypesEntries = predefinedTypes.map<
        [string, ExtTypeFilterList]
    >((data) => {
        return [
            data,
            {
                name: data,
                numberOfEntries: 0
            }
        ];
    });

    const predefinedTypesEntriesMap = Object.fromEntries(
        predefinedTypesEntries
    );
    return predefinedTypesEntriesMap;
};

const getExistingType = (item: LiveDispatchTaskTableRowData) => {
    const { externalTaskType } = item;
    return externalTaskType || constants.none;
};

export const getTypeCounts = (
    unassignedTasks: LiveDispatchTaskTableRowData[]
) => {
    const predefinedExternalTaskTypes = {
        [constants.none]: {
            name: constants.none,
            numberOfEntries: 0
        }
    };
    const typeCounts = unassignedTasks.reduce(
        (acc: ExtTypeFilterMap, item) => {
            const externalTaskTypeKey = getExistingType(item);

            if (!acc[externalTaskTypeKey]) {
                acc[externalTaskTypeKey] = {
                    name: externalTaskTypeKey,
                    numberOfEntries: 0
                };
            }

            acc[externalTaskTypeKey].numberOfEntries++;

            return acc;
        },
        { ...predefinedExternalTaskTypes }
    );

    return typeCounts;
};

export const getPredefinedTypeCounts = (
    unassignedTasks: LiveDispatchTaskTableRowData[]
): ExtTypeFilterMap => {
    const predefinedExternalTaskTypes = getPredefinedExternalTaskTypes();
    const typeCounts = unassignedTasks.reduce(
        (acc: ExtTypeFilterMap, item) => {
            const externalTaskTypeKey = getExistingType(item);
            const externalTaskTypeRecord =
                acc[externalTaskTypeKey] || acc[constants.other];

            externalTaskTypeRecord.numberOfEntries++;

            return acc;
        },
        { ...predefinedExternalTaskTypes }
    );

    return typeCounts;
};

const sortByTypeName = (a: ExtTypeFilterList, b: ExtTypeFilterList) => {
    if (a.name < b.name) {
        return -1;
    }
    return 1;
};

export const getExtTaskTypeFilterList = ({
    unassignedTasks,
    isPredefinedExternalTaskTypeEnabled
}: GetExtTaskTypeFilterListProps) => {
    const filterList = isPredefinedExternalTaskTypeEnabled
        ? getPredefinedTypeCounts(unassignedTasks)
        : getTypeCounts(unassignedTasks);

    const {
        [constants.none]: noneCounts,
        [constants.other]: otherCounts,
        ...extTaskTypeRecords
    } = filterList;

    const extTaskTypeCounts =
        Object.values(extTaskTypeRecords).sort(sortByTypeName);

    // when `other` counts are available, make sure it is after detected external task types
    if (otherCounts?.numberOfEntries) extTaskTypeCounts.push(otherCounts);

    // when `none` counts are available, make sure it is at the end of the array
    if (noneCounts.numberOfEntries) extTaskTypeCounts.push(noneCounts);

    return extTaskTypeCounts;
};
