import { useSelector } from 'react-redux';
import { validatePermissions } from '~/utils/validatePermissions';
import constants from '~/utils/constants';
import { UserPermissionFeatures, UserPermissionRoles } from '~/api/types';
import { selectUserGroupsForCurrentUser } from '~/reducers/userGroupsForCurrentUserSlice';

interface UseIsWithEditPermissionsProps {
    /**
     * Whether editing assigned tasks is allowed
     */
    allowEditingAssignedTasks: boolean;

    /**
     * The task status
     */
    taskStatus?: number;
}

export const useIsWithEditPermissions = ({
    allowEditingAssignedTasks,
    taskStatus
}: UseIsWithEditPermissionsProps) => {
    const associatedUserGroups = useSelector(selectUserGroupsForCurrentUser);
    const isTaskCompletedOrCancelled =
        taskStatus === constants.taskStatus.COMPLETED ||
        taskStatus === constants.taskStatus.CANCELLED;

    const isViewOnly =
        !allowEditingAssignedTasks &&
        (taskStatus === constants.taskStatus.DISPATCHED ||
            taskStatus === constants.taskStatus.IN_PROGRESS);

    const isWithEditPermissions =
        isTaskCompletedOrCancelled || isViewOnly
            ? false
            : validatePermissions(
                  associatedUserGroups,
                  constants.userPermissionFeatures
                      .TASK_MANAGEMENT as UserPermissionFeatures,
                  constants.userPermissionRoles.EDITOR as UserPermissionRoles
              );

    return { isWithEditPermissions };
};
