import React, { useRef, useState, useMemo } from 'react';
import { PlanMapDisplayContext } from './PlanMapDisplayContext';
import useCompletedModeMapMarkers from '~/components/MapPage/PlanMap/useCompletedModeMapMarkers';
import useDispatchedModeMapMarkers from '~/components/MapPage/PlanMap/useDispatchedModeMapMarkers';
import usePlannedModeMapMarkers from '~/components/MapPage/PlanMap/usePlannedModeMapMarkers';
import useDepots from '~/components/MapPage/PlanMap/useDepots';
import useSuperClusters from '~/components/MapPage/PlanMap/useSuperClusters';
import { useIsolatedRoutes, useMapUtils } from '~/hooks';
import constants from '~/utils/constants';
import { Coordinates } from '~/api/types';
import { useSelector } from 'react-redux';
import { selectShowEquipmentMarker } from '~/reducers/mapSettingsSlice';
import { selectIsOpenAnyUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { useUnassignedSuperClusters } from '../useUnassignedSuperClusters';
import { useEquipmentClusters } from '../useEquipmentClusters';
import { useLiveStopsSuperClusters } from '~/components/MapPage/PlanMap/useLiveStopsSuperClusters';

type Props = {
    children: React.ReactChild;
};

export const PlanMapDisplayContextProvider = ({
    children
}: Props): JSX.Element => {
    const { mapRouteModes } = constants;

    const routeLevelCoordinatesRef = useRef([]);
    const stopLevelCoordinatesRef = useRef([]);
    const driverCoordinatesRef = useRef([]);
    const completedRoutesCoordinatesRef = useRef([]);
    const allPolygonsRef = useRef({});

    const [markerPopup, setMarkerPopup] = useState<JSX.Element | null>(null);

    const { hasIsolatedRoutes } = useIsolatedRoutes();

    const isOpenAnyUnassignedTasksDrawer = useSelector(
        selectIsOpenAnyUnassignedTasksDrawer
    );
    const { mapRouteMode, mapMarkerMode, isRoutesMode } = useMapUtils();
    const { superClusters } = useSuperClusters();
    const { superClusters: unassignedSuperClusters } =
        useUnassignedSuperClusters();
    const { superClusters: equipmentSuperClusters } = useEquipmentClusters();
    const { depots, depotMarkers } = useDepots();
    const { superClusters: liveStopsSuperClusters } =
        useLiveStopsSuperClusters();
    const {
        routeMarkers: plannedRouteMarkers,
        routeLines: plannedRouteLines,
        routeStopMarkers: plannedRouteStopMarkers
    } = usePlannedModeMapMarkers({
        allPolygonsRef,
        routeLevelCoordinatesRef,
        stopLevelCoordinatesRef,
        superClusters,
        unassignedSuperClusters
    });

    const {
        depotStopMarkers: completedDepotStopMarkers,
        routeMarkers: completedRouteMarkers,
        routeStopMarkers: completedRouteStopMarkers,
        routeLines: completedRouteLines
    } = useCompletedModeMapMarkers({
        stopLevelCoordinatesRef,
        completedRoutesCoordinatesRef
    });

    const {
        routeMarkers: dispatchedRouteMarkers,
        routeStopMarkers: dispatchedRouteStopMarkers,
        depotStopMarkers: dispatchedDepotStopMarkers,
        routeLines: dispatchedRouteLines,
        selectedRouteLines: dispatchedSelectedRouteLines,
        equipmentMarkers: dispatchedEquipmentMarkers
    } = useDispatchedModeMapMarkers({
        stopLevelCoordinatesRef,
        driverCoordinatesRef,
        unassignedSuperClusters,
        equipmentSuperClusters,
        liveStopsSuperClusters
    });

    const isShowEquipmentMarkers = useSelector(
        selectShowEquipmentMarker(constants.mapRouteModes.DISPATCHED)
    );

    const { markers, routeLines, mapSourceCoordinates, equipmentMarkers } =
        useMemo(() => {
            let markersEffect: JSX.Element[] = [];
            let routeLinesEffect: JSX.Element[] = [];
            let mapSourceCoordinatesEffect: Coordinates[] = [];
            let equipmentMarkersEffect: JSX.Element[] = [];

            switch (mapRouteMode) {
                case mapRouteModes.DISPATCHED:
                    if (hasIsolatedRoutes || isOpenAnyUnassignedTasksDrawer) {
                        markersEffect = dispatchedRouteStopMarkers;
                        mapSourceCoordinatesEffect =
                            stopLevelCoordinatesRef.current;
                        routeLinesEffect = dispatchedSelectedRouteLines;
                    } else {
                        markersEffect = dispatchedRouteMarkers;
                        routeLinesEffect = dispatchedRouteLines;
                        mapSourceCoordinatesEffect =
                            driverCoordinatesRef.current;
                    }
                    if (isShowEquipmentMarkers) {
                        equipmentMarkersEffect = dispatchedEquipmentMarkers;
                    }
                    break;
                case mapRouteModes.COMPLETED:
                    if (hasIsolatedRoutes) {
                        markersEffect = completedRouteStopMarkers;
                        routeLinesEffect = completedRouteLines;
                        mapSourceCoordinatesEffect =
                            stopLevelCoordinatesRef.current;
                    } else {
                        markersEffect = completedRouteMarkers;
                        routeLinesEffect = [];
                        mapSourceCoordinatesEffect =
                            completedRoutesCoordinatesRef.current;
                    }
                    break;
                case mapRouteModes.PLAN:
                    if (isRoutesMode) {
                        markersEffect = plannedRouteMarkers;
                        mapSourceCoordinatesEffect =
                            routeLevelCoordinatesRef.current;
                    } else {
                        markersEffect = plannedRouteStopMarkers;
                        routeLinesEffect = plannedRouteLines;
                        mapSourceCoordinatesEffect =
                            stopLevelCoordinatesRef.current;
                    }
                    break;
                case mapRouteModes.EMPTY:
                    break;
                default:
                    console.warn('Unknown Map mode');
            }
            return {
                markers: markersEffect,
                routeLines: routeLinesEffect,
                mapSourceCoordinates: mapSourceCoordinatesEffect,
                equipmentMarkers: equipmentMarkersEffect
            };
            /* eslint-disable-next-line react-hooks/exhaustive-deps */
        }, [
            mapMarkerMode,
            mapRouteMode,
            isRoutesMode,
            dispatchedRouteStopMarkers,
            dispatchedSelectedRouteLines,
            dispatchedRouteMarkers,
            dispatchedRouteLines,
            completedRouteMarkers,
            completedRouteStopMarkers,
            completedRouteLines,
            plannedRouteMarkers,
            plannedRouteStopMarkers,
            plannedRouteLines,
            hasIsolatedRoutes,
            isShowEquipmentMarkers,
            dispatchedEquipmentMarkers,
            isOpenAnyUnassignedTasksDrawer
        ]);

    const getDepotMarkers = () =>
        depotMarkers.length
            ? depotMarkers
            : [
                  ...(dispatchedDepotStopMarkers ?? []),
                  ...(completedDepotStopMarkers ?? [])
              ];

    return (
        <PlanMapDisplayContext.Provider
            value={{
                markers,
                equipmentMarkers,
                routeLines,
                mapSourceCoordinates,
                markerPopup,
                setMarkerPopup,
                routeLevelCoordinatesRef,
                stopLevelCoordinatesRef,
                completedRoutesCoordinatesRef,
                driverCoordinatesRef,
                allPolygonsRef,
                depots,
                depotMarkers: getDepotMarkers(),
                superClusters
            }}
        >
            {children}
        </PlanMapDisplayContext.Provider>
    );
};
