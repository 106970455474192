import React, { useMemo, useState } from 'react';
import { useCompletedStopPopup, useIsolatedRoutes, useMapUtils } from '~/hooks';
import { useSelector } from 'react-redux';

import {
    selectIsClusteringStops,
    selectShowRouteLabel,
    selectShowRoutePopup,
    selectShowStopLabel,
    selectShowStopNumber,
    selectShowStopPopup
} from '~/reducers/mapSettingsSlice';

import { useHereMapContext } from '~/components/HereMaps/HereMapProvider';
import { DriverMarkerPopup, RouteMarker } from '~/ui';
import { getExceptionIcons } from '~/ui/components/LiveRoutesMarker/LiveRoutesMarker';

import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { Coordinates } from '~/api/types';
import { LiveStop, PlanRoute, PlanStop } from '~/data-classes';

import constants from '~/utils/constants';

interface UseCompletedMarkersConfig {
    markers: JSX.Element[];
}

export const useCompletedMarkersConfig = ({
    markers
}: UseCompletedMarkersConfig) => {
    const [hoveredRouteMarker, setHoveredRouteMarker] = useState(null);

    const { isCompletedRouteMode, mapRouteMode } = useMapUtils();

    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );

    const showRouteLabel = useSelector(
        selectShowRouteLabel(mapRouteMode as ConfigurableMapRouteMode)
    );
    const showRoutePopup = useSelector(
        selectShowRoutePopup(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const { showPopup, removePopup } = useHereMapContext();
    const { hasIsolatedRoutes } = useIsolatedRoutes();
    const showStopSequenceNumber = useSelector(
        selectShowStopNumber(mapRouteMode as ConfigurableMapRouteMode)
    );
    const showStopLabel = useSelector(
        selectShowStopLabel(mapRouteMode as ConfigurableMapRouteMode)
    );

    const { makeCompletedStopPopup } = useCompletedStopPopup();

    const showStopPopup = useSelector(
        selectShowStopPopup(mapRouteMode as unknown as ConfigurableMapRouteMode)
    );

    const handlePopupEvents = ({ event = '' }: { event?: string }) => {
        const { mapChildEvents } = constants;
        switch (event) {
            case mapChildEvents.POPUP_MOUSELEAVE:
                removePopup();
                break;
            default:
                break;
        }
    };

    const handleLiveRouteMarkerMouseEnter = (payload: {
        eventCoordinates: Record<string, number>;
        routeLevelData: PlanRoute;
    }) => {
        if (!showRoutePopup) {
            return;
        }
        const { routeLevelData, eventCoordinates } = payload;
        const popup = (
            <DriverMarkerPopup
                driverData={routeLevelData}
                exceptionData={getExceptionIcons(routeLevelData)}
            />
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        showPopup(popup, eventCoordinates);
    };

    const handleStopMarkerMouseEnter = (payload: {
        eventCoordinates: Record<string, number>;
        location: Coordinates;
        markerData: PlanStop | LiveStop;
    }) => {
        if (!showStopPopup) {
            return;
        }
        const { markerData } = payload;
        const popup = makeCompletedStopPopup(
            markerData as LiveStop,
            handlePopupEvents
        );
        const { eventCoordinates } = payload;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        showPopup(popup, eventCoordinates);
    };
    const markersConfig = useMemo(() => {
        if (!isCompletedRouteMode) {
            return;
        }
        if (hasIsolatedRoutes) {
            return markers.map((marker) => {
                const { props } = marker;
                const { id, lat, lng, stopData } = props;
                const coordinates = { lat, lng };
                const { stopId, taskId } = stopData || {};
                const markerId = id || stopId || taskId;
                const events = [
                    {
                        event: 'pointerenter',
                        handler: (eventCoordinates: Record<string, number>) => {
                            handleStopMarkerMouseEnter({
                                eventCoordinates,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                location: coordinates,
                                markerData: stopData
                            });
                        }
                    }
                ];
                return {
                    id: markerId,
                    component: marker,
                    coordinates,
                    events
                };
            });
        }
        return markers
            .map((marker) => {
                if (marker.type !== RouteMarker) {
                    return null;
                }
                const { props } = marker;
                const { lat, lng, routeLevelData } = props;
                const coordinates = { lat, lng };
                const { clientDriverId } = routeLevelData;
                const events = [
                    {
                        event: 'pointerenter',
                        handler: (eventCoordinates: Record<string, number>) => {
                            setHoveredRouteMarker(clientDriverId);
                            handleLiveRouteMarkerMouseEnter({
                                eventCoordinates,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                location: coordinates,
                                routeLevelData
                            });
                        }
                    },
                    {
                        event: 'pointerleave',
                        handler: () => {
                            setHoveredRouteMarker(null);
                            removePopup();
                        }
                    }
                ];
                return {
                    id: clientDriverId,
                    component: marker,
                    coordinates,
                    events
                };
            })
            .filter(Boolean);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        markers,
        isCompletedRouteMode,
        isClusteringStops,
        hasIsolatedRoutes,
        showRouteLabel,
        showRoutePopup,
        showStopLabel,
        showStopPopup,
        showStopSequenceNumber
    ]);

    return { markersConfig, hoveredRouteMarker };
};
