import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, TooltipButton, useModal } from '~/ui';
import { useTaskAssignments } from '~/hooks';
import SignatureModalContent from '~/components/TaskManagementPage/TaskDetailDrawer/ProofOfDelivery/SignatureModalContent';
import ZoomPhotoModalContent from '~/components/TaskManagementPage/TaskDetailDrawer/ProofOfDelivery/ZoomPhotoModalContent';

import { usePhotoModal } from './PhotoModalContent';
import { useItemsWithPhotos } from './useItemsWithPhotos';

import './ProofOfDelivery.scss';

function ProofOfDelivery({ inventoryItems, taskId }) {
    const { t } = useTranslation('taskManagement');
    const [isSigned, setSigned] = useState(false);
    const { assignments } = useTaskAssignments({ taskId });
    const {
        items,
        numberOfPhotos,
        selectedItemName,
        selectedPhotoUrl,
        setSelectedItemName,
        setSelectedPhotoUrl
    } = useItemsWithPhotos({
        assignments,
        inventoryItems
    });

    const { showModal: showSignatureModal, modal: SignatureModal } = useModal();

    const {
        showModal: showZoomPhotoModal,
        hideModal: hideZoomPhotoModal,
        modal: ZoomPhotoModal
    } = useModal();

    useEffect(() => {
        if (!inventoryItems.length) {
            return;
        }
        setSigned(Boolean(inventoryItems[0].signature_url));
    }, [inventoryItems]);

    function onPhotoClick(name, photoUrl) {
        setSelectedItemName(name);
        setSelectedPhotoUrl(photoUrl);
        showZoomPhotoModal();
    }

    const { showModal: showPhotoModal, modal: photoModal } = usePhotoModal({
        items,
        onPhotoClick,
        numberOfPhotos
    });

    function onZoomPhotoModalHide() {
        setSelectedItemName('');
        setSelectedPhotoUrl('');
        hideZoomPhotoModal();
        showPhotoModal();
    }

    function ProofItem({ title, hasProof, icon, count, onClick }) {
        const classNames = `proofofdelivery-item _fd-column _w-100 _text-2 ${
            hasProof && 'proofofdelivery-item_received'
        }`;
        return (
            <TooltipButton
                className={classNames}
                onClick={onClick}
                disabled={!hasProof}
            >
                <span>{title}</span>
                {hasProof ? (
                    <div className="_d-flex _ai-center _mt-2">
                        <Icon icon={icon} color="meteor" />
                        <span className="_ml-4 _text-2-alt">{count}</span>
                    </div>
                ) : (
                    <span className="_text-3-alt _mt-2 proofofdelivery_notreceived">
                        {t('proof.notReceived')}
                    </span>
                )}
            </TooltipButton>
        );
    }

    return (
        <div
            className="_d-flex _jc-space-between _cg-2 _w-100"
            data-testid="proof-of-delivery"
        >
            <ProofItem
                title={t('proof.signature')}
                hasProof={isSigned}
                icon="signature"
                count={1}
                onClick={showSignatureModal}
            />
            {numberOfPhotos > 0 && (
                <ProofItem
                    hasProof
                    title={t('proof.image', { count: numberOfPhotos })}
                    icon="image"
                    count={numberOfPhotos}
                    onClick={showPhotoModal}
                />
            )}
            <SignatureModal title={t('proof.signature')}>
                <SignatureModalContent
                    signatureUrl={inventoryItems[0]?.signature_url}
                />
            </SignatureModal>
            {photoModal}
            <ZoomPhotoModal variant="large" hasAutoHide={false} hasCustomHeader>
                <ZoomPhotoModalContent
                    name={selectedItemName}
                    photoUrl={selectedPhotoUrl}
                    onHide={onZoomPhotoModalHide}
                />
            </ZoomPhotoModal>
        </div>
    );
}

export default ProofOfDelivery;
