import React from 'react';

import { Icon } from '~/ui';

import './PopupSubmitButton.scss';

interface PopupSubmitButtonProps {
    text: string;
    onSubmit: () => void;
    isDisabled: boolean;
}

const rootClassName = 'popup-submit-button';

export const PopupSubmitButton = ({
    text,
    onSubmit,
    isDisabled
}: PopupSubmitButtonProps) => {
    return (
        <button
            type="button"
            onClick={onSubmit}
            className={rootClassName}
            data-testid={rootClassName}
            disabled={isDisabled}
        >
            <div
                className={`${rootClassName}__wrapper`}
                data-testid={`${rootClassName}__wrapper`}
            >
                <Icon icon="route" size="m" color="comet" />
                <span
                    data-testid={`${rootClassName}__label`}
                    className={`${rootClassName}__label`}
                >
                    {text}
                </span>
            </div>
        </button>
    );
};
