import { useContext } from 'react';
import { LiveDispatchTableContextValues } from '../types';
import { LiveDispatchTableContext } from './LiveDispatchTableContextProvider';

export const useLiveDispatchTableContext =
    (): LiveDispatchTableContextValues => {
        const context = useContext(LiveDispatchTableContext);

        if (!context) {
            throw new Error(
                'useLiveDispatchTableContext must be within context provider'
            );
        }

        return context;
    };
