import { useDispatch, useSelector } from 'react-redux';
import { useLiveRoutesUtils } from '~/hooks';
import {
    addSelectedMapRoute,
    removeSelectedMapRoute,
    selectSelectedMapRoutes
} from '~/reducers/selectedMapRoutesSlice';
import {
    selectIsOpenUnassignedTasksDrawer,
    setIsOpenSelectedCardsDrawer,
    setIsOpenUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import {
    selectIsMultipleCardSelectEnabled,
    selectViewCardDetails,
    setMapMarkerMode,
    setShouldFitPlanMapToBounds
} from '~/reducers/mapSettingsSlice';
import { useCallback } from 'react';
import PlanRoute from '~/data-classes/plan/PlanRoute';
import { setSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { upsertSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import constants from '~/utils/constants';

export const useRouteMarkerSelect = () => {
    const dispatch = useDispatch();
    const { deselectDriver } = useLiveRoutesUtils();
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const viewCardDetails = useSelector(selectViewCardDetails);
    const isMultipleCardSelectEnabled = useSelector(
        selectIsMultipleCardSelectEnabled
    );

    const toggleIsSelected = useCallback(
        ({
            selectable,
            routeLevelData
        }: {
            selectable: boolean;
            routeLevelData: PlanRoute;
        }) => {
            if (!selectable) return;

            const { clientRouteId } = routeLevelData;
            const isSelected = selectedMapRoutes.includes(clientRouteId);
            const shouldRenderStops =
                !isMultipleCardSelectEnabled && !viewCardDetails;

            if (shouldRenderStops) {
                if (!routeLevelData.isPlanned) {
                    deselectDriver();
                    dispatch(setIsOpenUnassignedTasksDrawer(true));
                } else {
                    const routeData =
                        routeLevelData instanceof PlanRoute
                            ? routeLevelData.toJSON()
                            : routeLevelData;
                    dispatch(setSelectedDrawerCardId(clientRouteId));
                    dispatch(
                        upsertSelectedDrawerCardData({
                            id: clientRouteId,
                            data: routeData
                        })
                    );
                    dispatch(setIsOpenSelectedCardsDrawer(true));
                }
                dispatch(setShouldFitPlanMapToBounds(true));
                dispatch(
                    setMapMarkerMode(constants.mapMarkerModes.STOPS_CLUSTERS)
                );
            }
            dispatch(
                isSelected
                    ? removeSelectedMapRoute(clientRouteId)
                    : addSelectedMapRoute(clientRouteId)
            );

            if (isOpenUnassignedTasksDrawer)
                dispatch(setIsOpenUnassignedTasksDrawer(false));
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [
            selectedMapRoutes,
            deselectDriver,
            isOpenUnassignedTasksDrawer,
            isMultipleCardSelectEnabled,
            viewCardDetails
        ]
    );
    return {
        toggleIsSelected
    };
};
