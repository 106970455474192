import { ReactElement, useEffect, useRef } from 'react';
import { HereMapsConstants } from '~/components/HereMaps/HereMapProvider/HereMapsPlatform';

import { useMapUtils } from '~/hooks';
import { useSelector } from 'react-redux';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { hextoRGB } from '~/utils/map/drawing/utils';
import { useHereMapPolygons } from '~/components/HereMaps/ui/useHereMapPolygons';

interface UsePolygonConfig {
    markers: ReactElement[];
}

const { DEFAULT_POLYGON_FILL_OPACITY, DEFAULT_STROKE } =
    HereMapsConstants.polygons;

export const usePolygonConfig = ({ markers }: UsePolygonConfig) => {
    const { isStopsClustersMode, isPlanRouteMode } = useMapUtils();
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const polygonsRef = useRef<Record<string, Record<string, unknown>>>({});
    const { getPolygon } = useHereMapPolygons();

    const parseConfig = (marker: JSX.Element) => {
        if (!isPlanRouteMode) {
            return null;
        }
        const {
            props: {
                colorCSS,
                routeLevelData: { perimeterCoordinates, clientRouteId }
            }
        } = marker;
        return {
            backgroundColor: colorCSS.backgroundColor,
            perimeterCoordinates,
            id: clientRouteId
        };
    };

    useEffect(() => {
        if (isStopsClustersMode || isOpenUnassignedTasksDrawer) {
            return;
        }
        polygonsRef.current = {};
        markers.forEach((marker: ReactElement) => {
            const data = parseConfig(marker);
            if (!data) {
                return;
            }
            const rgbaColor = hextoRGB(
                data.backgroundColor,
                DEFAULT_POLYGON_FILL_OPACITY
            );
            const { id, perimeterCoordinates, backgroundColor } = data;
            const config = {
                id,
                perimeterCoordinates,
                color: rgbaColor,
                strokeColor: backgroundColor,
                strokeWeight: DEFAULT_STROKE
            };
            const polygon = getPolygon(config);
            polygonsRef.current[id] = {
                polygon,
                color: backgroundColor
            };
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        isStopsClustersMode,
        isOpenUnassignedTasksDrawer,
        markers,
        isPlanRouteMode
    ]);

    return {
        polygonsRef
    };
};
