import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
    selectIsLiveDispatchDrawerExpanded,
    selectIsOpenLiveDispatchDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import { NavigationTabsGroup } from '~/ui';
import { EquipmentTable } from './EquipmentTable';
import { EquipmentTableToolbar } from './EquipmentTableToolbar';
import { EquipmentTableFooter } from './EquipmentTableFooter';
import { LiveDispatchHeader } from './LiveDispatchHeader';
import { LiveDispatchTable } from './LiveDispatchTable';
import { LiveDispatchTableToolbar } from './LiveDispatchTableToolbar';
import { LiveDispatchTableFooter } from './LiveDispatchTableFooter';
import { useLiveDispatchTabsLogic } from './useLiveDispatchTabsLogic';

import './LiveDispatchDrawer.scss';

const ROOT_CLASS_NAME = 'live-dispatch-drawer';

function LiveDispatchDrawer() {
    const isDrawerDisplayed = useSelector(selectIsOpenLiveDispatchDrawer);
    const isDrawerExpanded = useSelector(selectIsLiveDispatchDrawerExpanded);
    const { tabs, isUnassignedTasksTabActive } = useLiveDispatchTabsLogic();

    const conditionalClasses = {
        [`${ROOT_CLASS_NAME}--expanded`]: isDrawerExpanded,
        [`${ROOT_CLASS_NAME}--collapsed`]: !isDrawerExpanded
    };
    const elementClassName = classNames(ROOT_CLASS_NAME, conditionalClasses);

    if (!isDrawerDisplayed) {
        return null;
    }

    return (
        <div className={elementClassName} data-testid="live-dispatch-drawer">
            <LiveDispatchHeader />
            <div className="live-dispatch-tabs-content">
                <NavigationTabsGroup tabs={tabs} />
                {isUnassignedTasksTabActive && (
                    <>
                        <LiveDispatchTableToolbar />
                        <LiveDispatchTable />
                        <LiveDispatchTableFooter />
                    </>
                )}
                {!isUnassignedTasksTabActive && (
                    <>
                        <EquipmentTableToolbar />
                        <EquipmentTable />
                        <EquipmentTableFooter />
                    </>
                )}
            </div>
        </div>
    );
}

export default LiveDispatchDrawer;
