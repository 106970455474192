import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useTaskManualDispatch } from '~/hooks';
import { useGetEligibleDriversToAssign } from './useGetEligibleDriversToAssign';

import RouteDriverSearch from './RouteDriverSearch';
import { Button } from '~/ui';

import SinglePlanEditApi from '~/api/SinglePlanEditApi';

import { LiveDriver } from '~/data-classes';

import { selectMainClient } from '~/reducers/mainClientSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { getAllTasks } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import { selectIsOpenDispatchManagementPopup } from '~/reducers/mapDrawerSettingsSlice';
import { setSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { setSelectedDriverId } from '~/reducers/liveDispatchSelectedDriverSlice';

import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';

import './AssignTasksModalContent.scss';

function AssignTasksModalContent({ hideModal, selectedTaskIds }) {
    const { t } = useTranslation(['taskManagement', 'common']);
    const dispatch = useDispatch();
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [isAssigning, setIsAssigning] = useState(false);
    const selectedDate = useSelector(selectDate);
    const mainClient = useSelector(selectMainClient);
    const { manualDispatchTask } = useTaskManualDispatch();
    const { eligibleDriversToAssign: routes } = useGetEligibleDriversToAssign();
    const isOpenDispatchManagementPopup = useSelector(
        selectIsOpenDispatchManagementPopup
    );

    function onRouteSelection(value) {
        setSelectedRoute(value);
    }

    async function onAssign() {
        if (isOpenDispatchManagementPopup) {
            const driver = new LiveDriver({
                ...selectedRoute,
                id: selectedRoute.driverId
            });
            const { clientDriverId } = driver;
            dispatch(setSelectedDrawerCardId(clientDriverId));
            dispatch(setSelectedDriverId(selectedRoute.driverId));
            hideModal();

            return;
        }

        try {
            setIsAssigning(true);
            if (selectedRoute.status === constants.taskStatus.DISPATCHED) {
                const tasksToDispatch = selectedTaskIds.map((taskId) => ({
                    id: taskId
                }));

                await manualDispatchTask(
                    {
                        driverId: selectedRoute.driverId,
                        tasksToDispatch,
                        position: constants.assignmentPosition
                    },
                    {
                        onSuccess: () => {
                            dispatch(
                                addToast({
                                    message: t(
                                        'assignTask.dispatchConfirmation',
                                        {
                                            count: selectedTaskIds.length
                                        }
                                    ),
                                    variant: 'info'
                                })
                            );
                        }
                    }
                );
            } else {
                const routeDate = dateUtils.convertToISODateOnly(selectedDate);
                await SinglePlanEditApi.augmentRoute(mainClient.id, {
                    date: routeDate,
                    driverId: selectedRoute.driverId,
                    taskIds: selectedTaskIds
                });

                dispatch(
                    addToast({
                        message: t('assignTask.assignConfirmation', {
                            count: selectedTaskIds.length
                        }),
                        variant: 'info'
                    })
                );
            }
            // update task table
            dispatch(getAllTasks({ routeDate: selectedDate }));
            dispatch(resetSelectedTaskIds());
        } catch (e) {
            console.error(e);
            dispatch(
                addToast({
                    message: t('error:assignTasksError'),
                    variant: 'error'
                })
            );
        }
        hideModal();
        setIsAssigning(false);
    }

    return (
        <div
            className="_d-flex _fd-column _ai-space-between assigntasksmodal-content"
            data-testid="assigntasksmodal-content"
        >
            <div className="_header-3 assigntasksmodal-search">
                <span>{t('assignTask.selectRouteDriver')}</span>
                <RouteDriverSearch
                    routes={routes}
                    onRouteSelection={onRouteSelection}
                />
            </div>
            <div className="assigntasksmodal-footer">
                <Button variant="secondary" onClick={hideModal}>
                    {t('common:cancel')}
                </Button>
                <Button
                    marginLeft="2.4rem"
                    onClick={onAssign}
                    disabled={!selectedRoute || isAssigning}
                    data-testid="assign-tasks-button"
                >
                    {t('assignTask.assign')}
                </Button>
            </div>
        </div>
    );
}

export default AssignTasksModalContent;
