import { KeyboardEvent, useEffect, useState } from 'react';
import { UpdateTaskProps } from '~/components/TaskManagementPage/TaskDetailDrawer/useUpdateTask';

interface UseTaskLabelsEdit {
    labels: string[];
    taskId: string;
    onClose: () => void;
    onTaskUpdate: (data: UpdateTaskProps) => Promise<boolean>;
}

enum KeyCode {
    ENTER = 'Enter',
    SPACE = ' '
}

export const useTaskLabelsEdit = ({
    labels,
    onClose,
    onTaskUpdate,
    taskId
}: UseTaskLabelsEdit) => {
    const [inputText, setInputText] = useState('');
    const [savedLabels, setSavedLabels] = useState<string[]>([]);
    const [isUpdating, setIsUpdating] = useState(false);

    const onDeleteLabel = (label: string) => {
        setSavedLabels((values) => values.filter((value) => value !== label));
    };

    const onKeyUp = ({ key, target }: KeyboardEvent<HTMLInputElement>) => {
        const { value } = target as HTMLInputElement;
        const newValue = value.trim();
        const isSaveLabel = [KeyCode.ENTER, KeyCode.SPACE].includes(
            key as KeyCode
        );

        if (!isSaveLabel || savedLabels.includes(newValue)) {
            setInputText(newValue);
            return;
        }

        setInputText('');

        if (newValue) setSavedLabels((values) => [...values, newValue]);
    };

    const onSave = () => {
        (async () => {
            setIsUpdating(true);

            const newLabels = inputText.length
                ? [...savedLabels, inputText]
                : savedLabels;
            const property = 'labels';

            const isSuccessful = await onTaskUpdate({
                taskId,
                property,
                value: newLabels.length ? newLabels : null
            });

            setIsUpdating(false);

            if (isSuccessful) onClose();
        })();
    };

    useEffect(() => {
        setSavedLabels(labels);
    }, [labels]);

    return {
        isUpdating,
        onDeleteLabel,
        onKeyUp,
        inputText,
        setInputText,
        savedLabels,
        onSave
    };
};
