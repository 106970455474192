import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flexRender } from '@tanstack/react-table';
import { LiveDispatchTaskTableRowData } from '~/data-classes';
import {
    selectSelectedTaskIds,
    setSelectedTaskIds
} from '~/reducers/selectedTaskIdsSlice';
import { HandleChangeProps, TableRowProps } from '../types';
import { Checkbox } from '~/ui';
import './LiveDispatchTableRow.scss';

export const LiveDispatchTableRow = ({
    row
}: TableRowProps<LiveDispatchTaskTableRowData>) => {
    const {
        original: { rowId }
    } = row;

    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const dispatch = useDispatch();

    const liveDispatchTableRowClickHandler = ({
        selectedValues
    }: HandleChangeProps) => {
        dispatch(setSelectedTaskIds(selectedValues));
    };

    return (
        <tr
            role="row"
            className="live-dispatch-table-row"
            data-testid="live-dispatch-table-row"
            data-task-id={rowId}
        >
            <td role="cell">
                <div
                    className="live-dispatch-table-data live-dispatch-table-row__data-checkbox"
                    data-testid="live-dispatch-table-row__data-checkbox"
                >
                    <Checkbox
                        id={rowId}
                        className="_m-0"
                        value={rowId}
                        selectedValues={selectedTaskIds}
                        handleChange={liveDispatchTableRowClickHandler}
                        data-testid="live-dispatch-table-checkbox"
                        disabled={false}
                    />
                </div>
            </td>
            {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    );
};
