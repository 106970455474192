import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Highlight, TooltipButton } from '~/ui';
import { ApiNote } from '~/api/types';
import { LiveDispatchTableDataProps } from '../../types';
import './LiveDispatchTableDataNotes.scss';

const ROOT_CLASS_NAME = 'live-dispatch-table-data';

export const LiveDispatchTableDataNotes = ({
    data,
    className,
    'data-testid': dataTestId,
    searchText = ''
}: LiveDispatchTableDataProps<ApiNote[]>) => {
    const { t } = useTranslation('liveDispatchTable');
    const notes = useMemo(() => {
        const notesData = data || [];
        return notesData.map((note, idx) => ({
            key: `note-${idx}`,
            note: note.text
        }));
    }, [data]);

    const [selectedNoteIndex, setSelectedNoteIndex] =
        useState<number | null>(null);

    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        {
            [`${ROOT_CLASS_NAME}--truncate`]: true
        },
        'live-dispatch-table-row__data-note'
    );

    const handleNoteClick = useCallback(
        (index: number) => {
            setSelectedNoteIndex((prevIndex) =>
                prevIndex === index ? null : index
            );
        },
        [setSelectedNoteIndex]
    );

    const shouldShowNote = (index: number, noteText: string | undefined) => {
        return (
            selectedNoteIndex === index ||
            (searchText &&
                noteText &&
                noteText.toLowerCase().includes(searchText.toLowerCase()))
        );
    };

    const filteredNotes = notes.filter(({ note }, index) =>
        shouldShowNote(index, note)
    );
    return (
        <>
            <div className={className} data-testid={dataTestId}>
                {notes.map(({ key }, index) => (
                    <div key={key}>
                        <TooltipButton
                            key={key}
                            className="live-dispatch-table-data__note-links"
                            onClick={() => handleNoteClick(index)}
                            style={{ cursor: 'pointer' }}
                        >
                            {t('note', { index: index + 1 })}
                        </TooltipButton>
                    </div>
                ))}
            </div>
            {filteredNotes.length > 0 && (
                <div
                    className={elementClassName}
                    data-testid={`${dataTestId}-data`}
                >
                    {filteredNotes.map(({ key, note }) => (
                        <div key={key} className="highlight">
                            <div className="live-dispatch-table-data__divider" />
                            <Highlight
                                inputText={note || ''}
                                searchText={searchText}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};
