import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '~/ui';

import './SelectDriverCard.scss';

interface SelectDriverCardProps {
    handleClick: () => void;
}

export const SelectDriverCard: React.FC<SelectDriverCardProps> = ({
    handleClick
}) => {
    const { t } = useTranslation(['liveDispatchTable']);

    return (
        <div className="live-dispatch-select-driver-card">
            <span
                className="live-dispatch-select-driver-card__title"
                data-testid="live-dispatch-select-driver-card__title"
            >
                {t('dispatchPopup.selectDriver.card.title')}
            </span>
            <span
                className="live-dispatch-select-driver-card__subtitle"
                data-testid="live-dispatch-select-driver-card__subtitle"
            >
                {t('dispatchPopup.selectDriver.card.subtitle')}
            </span>
            <button
                type="button"
                onClick={handleClick}
                className="live-dispatch-select-driver-card__button"
                data-testid="live-dispatch-select-driver-card__button"
            >
                <span>{t('dispatchPopup.dropdown')}</span>
                <Icon icon="chevronDown" size="xs" color="meteor" />
            </button>
        </div>
    );
};
