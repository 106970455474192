import React, { DragEvent, useState } from 'react';
import { ViewColumnsDropdownItem } from '../ViewColumnsDropdownItem';
import { ViewColumnsDropdownListProps } from './types';
import classNames from 'classnames';

import './ViewColumnsDropdownList.scss';

const ViewColumnsDropdownList: React.FC<ViewColumnsDropdownListProps> = ({
    dropdownColumns,
    handleToggleVisibility,
    handleReorderColumns
}) => {
    const [dragStartIndex, setDragStartIndex] = useState<number | null>(null);
    const [isItemDragging, setIsItemDragging] = useState(false);

    const handleDrop = (_: DragEvent<HTMLLIElement>, dragDropIndex: number) => {
        if (
            dragStartIndex !== null &&
            dragStartIndex >= 0 &&
            dragDropIndex >= 0
        ) {
            handleReorderColumns({
                dragStartIndex,
                dragDropIndex
            });
        }
    };

    function getClassName() {
        const defaultClassName = 'live-dispatch-view-columns-dropdown-list';
        const conditionalClasses = {
            'hide-scrollbar': isItemDragging
        };
        return classNames(defaultClassName, conditionalClasses);
    }

    return (
        <ul
            className={getClassName()}
            data-testid="live-dispatch-view-columns-dropdown-list"
        >
            {Object.entries(dropdownColumns).map(
                ([columnName, isVisible], index) => (
                    <ViewColumnsDropdownItem
                        key={columnName}
                        columnName={columnName}
                        isVisible={isVisible}
                        onToggleVisibility={handleToggleVisibility}
                        handleDragStart={() => {
                            setIsItemDragging(true);
                            setDragStartIndex(index);
                        }}
                        handleDragOver={(e: DragEvent<HTMLLIElement>) => {
                            e.preventDefault();
                        }}
                        handleDrop={(e: DragEvent<HTMLLIElement>) =>
                            handleDrop(e, index)
                        }
                        handleDragEnd={(e: DragEvent<HTMLLIElement>) => {
                            e.preventDefault();
                            setIsItemDragging(false);
                        }}
                    />
                )
            )}
        </ul>
    );
};

export default ViewColumnsDropdownList;
