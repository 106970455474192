import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, TooltipButton } from '~/ui';
import { Item } from '../useItemsWithPhotos/utils';

import './PhotoModalContent.scss';

interface PhotoModalContentProps {
    /**
     * The list of items
     */
    items: Item[];
    /**
     * The photo click handler
     */
    onPhotoClick: (name: string, photoUrl: string) => void;
}

export const PhotoModalContent = ({
    items,
    onPhotoClick
}: PhotoModalContentProps) => {
    const { t } = useTranslation('taskManagement');

    return (
        <div
            className="_d-flex _fw-wrap photomodal"
            data-testid="photo-modal-content"
        >
            {items.map(({ name, photoUrls }) => {
                return photoUrls.map((photoUrl) => {
                    return (
                        <div
                            key={photoUrl}
                            className="_p-relative _text-2 _m-2 photomodal-inventoryitem"
                        >
                            <TooltipButton
                                className="_mb-1 photomodal-inventoryphoto"
                                onClick={() => onPhotoClick(name, photoUrl)}
                            >
                                <img
                                    className="photomodal-image"
                                    src={photoUrl}
                                    alt={t('proof.image')}
                                />
                            </TooltipButton>
                            <span>{name}</span>
                            <IconButton
                                className="photomodal-button"
                                icon="download"
                                iconColor="meteor"
                                onClick={() => window.open(photoUrl)}
                            />
                        </div>
                    );
                });
            })}
        </div>
    );
};
