import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import './TimeInput.scss';
import Icon from '../Icon/Icon';

interface TimeInputProps {
    value?: string;
    placeholder?: string;
    showPlaceholder?: boolean;
    onChange?: (newValue: string) => void;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
    variant?: 'table' | 'form' | 'naked' | 'live_dispatch';
    isEmptyValid?: boolean;
    className?: string;
    'data-testid'?: string;
}

function TimeInput({
    value,
    placeholder,
    showPlaceholder = false,
    onChange,
    onFocus,
    disabled,
    variant = 'table',
    isEmptyValid = false,
    className,
    ...extra
}: TimeInputProps): JSX.Element {
    const [isValidTime, setValidTime] = useState(true);

    useEffect(() => {
        if (!value) {
            if (isEmptyValid) {
                setValidTime(true);
            }
            return;
        }
        const datetime = DateTime.fromFormat(value, 'T');
        if (!datetime.isValid) {
            setValidTime(false);
        } else {
            setValidTime(true);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [value]);

    function getClassNames() {
        const conditionalClasses = {
            timeinput_error: !isValidTime,
            with_placeholder: !value && showPlaceholder && placeholder,
            timeinput_form: variant === 'form',
            timeinput_naked: variant === 'naked',
            timeinput_table: variant === 'table',
            timeinput_live_dispatch: variant === 'live_dispatch'
        };
        return classNames('timeinput', conditionalClasses);
    }

    function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        return onChange && onChange(e.target.value);
    }

    return (
        <div className={`_d-flex _ai-center _w-100 ${className}`}>
            <input
                type="time"
                className={getClassNames()}
                placeholder={placeholder}
                data-placeholder={placeholder}
                value={value}
                onChange={onInputChange}
                onFocus={onFocus}
                disabled={disabled}
                data-testid={extra['data-testid'] || 'time-input'}
            />
            {variant === 'table' && (
                <Icon
                    className="timeinput-icon"
                    icon="clock"
                    color={isValidTime ? 'galaxy-600' : 'mars-500'}
                    disabled={disabled}
                />
            )}
        </div>
    );
}

export default TimeInput;
