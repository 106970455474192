import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, isEmpty, uniq } from 'lodash';
import { usePlanMapDisplayContext } from '~/components/MapPage/PlanMap/PlanMapDisplayContext';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { useMapUtils } from '~/hooks';
import { selectShowRoutePolygons } from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import {
    HereMapsConstants,
    useHereMapContext
} from '~/components/HereMaps/HereMapProvider';
import { useHereMapMarkers } from '../ui';
import { PlanMapOverlay } from '~/ui';
import { setLastPlanMapBounds } from '~/reducers/lastPlanMapBoundsSlice';
import { setLastPlanMapCenter } from '~/reducers/lastPlanMapCenterSlice';
import { setLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { useMarkersConfig } from '~/components/HereMaps/useMarkersConfig';
import { usePolygonConfig } from '~/components/HereMaps/HerePlanMap/usePolygonConfig';
import constants from '~/utils/constants';
import { pageUtils } from '~/utils/page-utils';
import {
    highlightPolygon,
    unhighlightPolygon
} from '~/components/HereMaps/ui/polygonUtils';
import {
    useFitMapToBounds,
    useMapBoundingEffects
} from '~/components/MapPage/useFitMapToBounds';
import { selectIsOpenAnyUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { getBoundsFromCoordinates } from '~/components/HereMaps/utils';
import {
    selectAreRoutesLoading,
    selectAreStopsLoading
} from '~/reducers/dataLoadingSlice';
import { HereMapsGroup, HereMapsObject } from '../types';

// @TODO typescript if/when possible

export const HerePlanMap = () => {
    const dispatch = useDispatch();

    const [displayedPolygonGroup, setDisplayedPolygonGroup] =
        useState<null | HereMapsGroup>(null);
    const [displayedMarkerGroup, setDisplayedMarkerGroup] =
        useState<null | HereMapsGroup>(null);

    const { handleLoadingData, editPermissions } = usePlanMapPropsContext();
    const { removePopup, mapInstance } = useHereMapContext();

    const { mapRouteMode, isPlanRouteMode, isStopsClustersMode } =
        useMapUtils();

    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenAnyUnassignedTasksDrawer
    );
    const areRoutesLoading = useSelector(selectAreRoutesLoading);
    const areStopsLoading = useSelector(selectAreStopsLoading);
    const selectedDate = useSelector(selectDateOnly);
    const showRoutePolygons = useSelector(
        selectShowRoutePolygons(mapRouteMode as ConfigurableMapRouteMode)
    );

    const { markers, mapSourceCoordinates, routeLines } =
        usePlanMapDisplayContext();
    const { markersConfig, hoveredRouteMarker } = useMarkersConfig({
        markers
    });
    const { outputMarkers } = useHereMapMarkers({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        markersConfig
    });

    const { polygonsRef } = usePolygonConfig({ markers });

    const { shouldFitToBounds, setShouldFitToBounds } = useFitMapToBounds();
    useMapBoundingEffects();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleMapChange = (map) => {
        const debouncedChange = debounce(() => {
            if (!map) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const viewModel = map.getViewModel();
            const bounds = viewModel.getLookAtData().bounds.getBoundingBox();
            const boundsPoints = [
                bounds.getLeft(),
                bounds.getBottom(),
                bounds.getRight(),
                bounds.getTop()
            ];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const center = map.getCenter();
            const centerCoords = {
                lat: center.lat,
                lng: center.lng
            };
            const { zoom } = viewModel.getLookAtData();
            dispatch(setLastPlanMapCenter(centerCoords));
            dispatch(setLastPlanMapZoom(zoom));
            dispatch(setLastPlanMapBounds(boundsPoints));
            setShouldFitToBounds(false);
        }, constants.timings.MAP_CHANGE);
        return debouncedChange;
    };

    useEffect(() => {
        return () => {
            if (!mapInstance) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mapInstance.removeEventListener(
                'mapviewchangeend',
                handleMapChange
            );
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if (!mapInstance) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mapInstance.addEventListener(
            'mapviewchangeend',
            handleMapChange(mapInstance)
        );
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [mapInstance]);

    useEffect(() => {
        const clearMap = () => {
            if (displayedPolygonGroup) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mapInstance.removeObject(displayedPolygonGroup);
            }
            if (displayedMarkerGroup) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mapInstance.removeObject(displayedMarkerGroup);
            }
            removePopup();
            setDisplayedPolygonGroup(null);
            setDisplayedMarkerGroup(null);
        };
        if (!mapInstance) {
            return;
        }
        clearMap();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [outputMarkers, mapInstance, mapRouteMode]);

    useEffect(() => {
        if (!mapInstance) {
            return;
        }
        const clearPolygons = () => {
            if (!displayedPolygonGroup || showRoutePolygons) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mapInstance.removeObject(displayedPolygonGroup);
        };
        try {
            clearPolygons();
        } catch (e) {
            console.error('Error clearing polygons', e);
        }
    }, [mapInstance, showRoutePolygons, displayedPolygonGroup]);

    useEffect(() => {
        handleLoadingData();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [markers, mapRouteMode]);

    useEffect(() => {
        if (!selectedDate) {
            return;
        }
        pageUtils.resetPlanPageReducers();
        removePopup();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedDate]);

    useEffect(() => {
        if (isEmpty(polygonsRef.current)) {
            return;
        }
        if (hoveredRouteMarker) {
            const polygonRef = polygonsRef.current[hoveredRouteMarker];
            if (!polygonRef) {
                return;
            }
            const { polygon, color } = polygonRef;
            highlightPolygon(polygon, color as string);
        } else {
            Object.keys(polygonsRef.current).forEach(
                (clientRouteId: string) => {
                    const polygonRef = polygonsRef.current[clientRouteId];
                    if (!polygonRef) {
                        return;
                    }
                    const { polygon, color } = polygonRef;
                    unhighlightPolygon(polygon, color as string);
                }
            );
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [displayedPolygonGroup, hoveredRouteMarker]);

    useEffect(() => {
        if (!mapInstance) {
            return;
        }
        try {
            if (!outputMarkers?.length) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const group = new H.map.Group();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            outputMarkers.forEach((marker) => {
                group.addObject(marker);
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mapInstance.addObject(group);
            setDisplayedMarkerGroup(group);
            if (!shouldFitToBounds) {
                return;
            }
            const bounds =
                group.getBoundingBox() ||
                // when marker group is not visible because map is moved
                getBoundsFromCoordinates(mapSourceCoordinates);
            if (!bounds) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mapInstance.getViewModel().setLookAtData({
                bounds,
                center: bounds.getCenter(),
                zoom: HereMapsConstants.stopMarkerZoom,
                padding: { top: 100, left: 100, bottom: 100, right: 100 }
            });
        } catch (e) {
            console.error('Error adding markers to Here Maps', e);
        }
    }, [
        mapInstance,
        mapSourceCoordinates,
        outputMarkers,
        areRoutesLoading,
        areStopsLoading,
        shouldFitToBounds
    ]);

    const getSelectedRoutePolygons = (stopMarkers: ReactElement[]) => {
        return uniq(
            stopMarkers
                .map((marker) => {
                    const {
                        props: { data = {} }
                    } = marker;
                    const { clientRouteId } = data;
                    return clientRouteId;
                })
                .filter(Boolean)
        );
    };

    useEffect(() => {
        const skipPolygons =
            !isPlanRouteMode ||
            !mapInstance ||
            !showRoutePolygons ||
            isOpenUnassignedTasksDrawer;

        if (skipPolygons) {
            setDisplayedPolygonGroup(null);
            return;
        }
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const group = new H.map.Group();
            if (isStopsClustersMode) {
                const stopRouteClientRouteIds =
                    getSelectedRoutePolygons(markers);
                stopRouteClientRouteIds.forEach((clientRouteId) => {
                    const { polygon } = polygonsRef.current[clientRouteId];
                    group.addObject(polygon as HereMapsObject);
                });
            } else {
                Object.values(polygonsRef.current).forEach(({ polygon }) => {
                    group.addObject(polygon as HereMapsObject);
                });
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mapInstance.addObject(group);
            setDisplayedPolygonGroup(group);
        } catch (e) {
            console.error('Error adding polygons to Here Maps', e);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        mapInstance,
        outputMarkers,
        isPlanRouteMode,
        showRoutePolygons,
        isStopsClustersMode,
        isOpenUnassignedTasksDrawer
    ]);

    return (
        <>
            <PlanMapOverlay
                editPermissions={editPermissions}
                map={mapInstance}
            />
            {routeLines}
        </>
    );
};
