import { ColumnDef } from '@tanstack/react-table';
import { Equipment } from '~/data-classes';
import {
    EquipmentTableData,
    EquipmentTableDataHighlight,
    EquipmentTableDataLabels,
    EquipmentTableDataLabelsHighlight,
    EquipmentTableDataCoordinates
} from '../EquipmentTableData';
import {
    columnFilterState,
    columnFilterStringValues,
    columnFilterUpdateAt
} from './filterFns';
import { EquipmentTableHeader } from '../EquipmentTableHeader';
import { EquipmentTableHeaderUpdatedAt } from '../EquipmentTableHeaderUpdatedAt';
import { EquipmentTableHeaderStringFilter } from '../EquipmentTableHeaderStringFilter';
import { EquipmentTableHeaderArrayFilter } from '../EquipmentTableHeaderArrayFilter';

import { EquipmentTableColumnId } from '../types';

type EquipmentTableCellValues = Equipment[EquipmentTableColumnId];

export const columnDefMap: Record<
    string,
    ColumnDef<Equipment, EquipmentTableCellValues>
> = {
    [EquipmentTableColumnId.ID]: {
        accessorKey: EquipmentTableColumnId.ID,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [EquipmentTableColumnId.NAME]: {
        accessorKey: EquipmentTableColumnId.NAME,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [EquipmentTableColumnId.TYPE]: {
        accessorKey: EquipmentTableColumnId.TYPE,
        cell: EquipmentTableData,
        header: EquipmentTableHeaderStringFilter,
        enableColumnFilter: true,
        enableHiding: true,
        filterFn: columnFilterStringValues,
        enableSorting: true
    },
    [EquipmentTableColumnId.COORDINATES]: {
        accessorKey: EquipmentTableColumnId.COORDINATES,
        cell: EquipmentTableDataCoordinates,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: false
    },
    [EquipmentTableColumnId.STATUS]: {
        accessorKey: EquipmentTableColumnId.STATUS,
        cell: EquipmentTableData,
        header: EquipmentTableHeaderStringFilter,
        enableColumnFilter: true,
        enableHiding: true,
        filterFn: columnFilterStringValues,
        enableSorting: true
    },
    [EquipmentTableColumnId.STATE]: {
        accessorKey: EquipmentTableColumnId.STATE,
        cell: EquipmentTableDataLabels,
        header: EquipmentTableHeaderArrayFilter,
        enableColumnFilter: true,
        enableHiding: true,
        filterFn: columnFilterState,
        enableSorting: false
    },
    [EquipmentTableColumnId.ATTACHED]: {
        accessorKey: EquipmentTableColumnId.ATTACHED,
        cell: EquipmentTableDataLabelsHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: false
    },
    [EquipmentTableColumnId.UPDATED_AT]: {
        accessorKey: EquipmentTableColumnId.UPDATED_AT,
        cell: EquipmentTableData,
        header: EquipmentTableHeaderUpdatedAt,
        enableColumnFilter: true,
        enableHiding: true,
        filterFn: columnFilterUpdateAt,
        enableSorting: false
    },
    [EquipmentTableColumnId.CUSTOMERID]: {
        accessorKey: EquipmentTableColumnId.CUSTOMERID,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [EquipmentTableColumnId.CUSTOMERADDRESSLINE1]: {
        accessorKey: EquipmentTableColumnId.CUSTOMERADDRESSLINE1,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [EquipmentTableColumnId.CUSTOMERCITY]: {
        accessorKey: EquipmentTableColumnId.CUSTOMERCITY,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [EquipmentTableColumnId.CUSTOMERSTATE]: {
        accessorKey: EquipmentTableColumnId.CUSTOMERSTATE,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [EquipmentTableColumnId.CUSTOMERPOSTALCODE]: {
        accessorKey: EquipmentTableColumnId.CUSTOMERPOSTALCODE,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [EquipmentTableColumnId.CUSTOMERNAME]: {
        accessorKey: EquipmentTableColumnId.CUSTOMERNAME,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [EquipmentTableColumnId.DISPATCHZONE]: {
        accessorKey: EquipmentTableColumnId.DISPATCHZONE,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: false
    },
    [EquipmentTableColumnId.AREA]: {
        accessorKey: EquipmentTableColumnId.AREA,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [EquipmentTableColumnId.CHASSISID]: {
        accessorKey: EquipmentTableColumnId.CHASSISID,
        cell: EquipmentTableDataHighlight,
        header: EquipmentTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: false
    }
};

export const EquipmentTableColumnIds = Object.keys(columnDefMap);
