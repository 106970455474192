/* eslint-disable react/destructuring-assignment */
import classNames from 'classnames';
import React from 'react';

import { FlexLayout, Radio } from '~/ui/components';

import './radio-group.scss';

export interface RadioInputData<V extends string = string> {
    id: string;
    label: string;
    description?: string;
    value: V;
    disabled?: boolean;
}

export interface RadioGroupProps<V extends string = string> {
    radioData: readonly RadioInputData<V>[];
    name: string;
    label?: string;
    value: V;
    required?: boolean;
    onChange?: (v: V) => void;
    radioLabelClassName?: string;
    radioLabelDescriptionClassName?: string;
    radioInputGroupClassName?: string;
    radioInputClassName?: string;
    radioGroupLabelClassName?: string;
    className?: string;
    'data-testid'?: string;
    'radio-testid'?: string;
    'radioLabel-testid'?: string;
    'radioLabelDescription-testid'?: string;
    'radioGroupLabel-testid'?: string;
    'radioInputGroup-testid'?: string;
    'asterisk-testid'?: string;
}

const className = 'radio-group';
const radioLabelClassName = `${className}__radio-label`;
const radioLabelDescriptionClassName = `${className}__radio-label-description`;
const radioGroupLabelClassName = `${className}__label`;
const radioInputClassName = `${className}__radio-input`;
const radioInputGroupClassName = `${className}__radio-input-group`;

function RadioGroup<V extends string>({
    radioData,
    name,
    label,
    onChange,
    required,
    ...props
}: RadioGroupProps<V>): JSX.Element {
    return (
        <FlexLayout
            className={classNames(className, props.className)}
            flexDirection="column"
            sx={{ rowGap: '1.6rem' }}
            data-testid={props['data-testid']}
        >
            <FlexLayout flexDirection="row" sx={{ columnGap: '0.4rem' }}>
                {label ? (
                    <>
                        <span
                            className={classNames(
                                radioGroupLabelClassName,
                                props.radioGroupLabelClassName,
                                'radio-group__label'
                            )}
                            data-testid={props['radioGroupLabel-testid']}
                        >
                            {label}
                        </span>
                        {required ? (
                            <span
                                data-testid={props['asterisk-testid']}
                                className={`${className}__asterisk`}
                            >
                                *
                            </span>
                        ) : null}
                    </>
                ) : null}
            </FlexLayout>
            <FlexLayout
                className={classNames(
                    radioInputGroupClassName,
                    props.radioInputGroupClassName
                )}
                data-testid={props['radioInputGroup-testid']}
            >
                {radioData.map(({ id, value, ...radioInputData }) => (
                    <label
                        className={classNames(
                            radioLabelClassName,
                            props.radioLabelClassName,
                            'radio-group__label'
                        )}
                        key={id}
                        data-testid={props['radioLabel-testid']}
                        htmlFor={id}
                    >
                        <Radio
                            id={id}
                            className={classNames(
                                radioInputClassName,
                                props.radioInputClassName
                            )}
                            name={name}
                            value={value}
                            onChange={(e) => onChange?.(e.target.value as V)}
                            defaultChecked={props.value === value}
                            disabled={radioInputData.disabled}
                            data-testid={props['radio-testid']}
                        />
                        <div className="_fd-column">
                            <span>{radioInputData.label}</span>
                            <span
                                className={classNames(
                                    radioLabelDescriptionClassName,
                                    props.radioLabelDescriptionClassName
                                )}
                                data-testid={
                                    props['radioLabelDescription-testid']
                                }
                            >
                                {radioInputData.description}
                            </span>
                        </div>
                    </label>
                ))}
            </FlexLayout>
        </FlexLayout>
    );
}

export default RadioGroup;
