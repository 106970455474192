import { hextoRGB } from '~/utils/map/drawing/utils';
import { HereMapsConstants } from '~/components/HereMaps/HereMapProvider/HereMapsPlatform';

export const highlightPolygon = (polygon: unknown, color: string) => {
    const rgba = hextoRGB(
        color as string,
        HereMapsConstants.polygons.DEFAULT_POLYGON_HIGHLIGHT_FILL_OPACITY
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    polygon.setStyle({ ...polygon.style, fillColor: rgba });
};

export const unhighlightPolygon = (polygon: unknown, color: string) => {
    const rgba = hextoRGB(
        color as string,
        HereMapsConstants.polygons.DEFAULT_POLYGON_FILL_OPACITY
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    polygon.setStyle({ ...polygon.style, fillColor: rgba });
};
