import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Equipment } from '~/data-classes';
import { TableHeaderProps } from '../types';
import { useEquipmentTableStates } from '../useEquipmentTableStates';
import { TableSortElement } from '../TableSortElement/TableSortElement';

const ROOT_CLASS_NAME = 'equipment-table-header';

export const EquipmentTableHeader = ({
    header,
    children,
    className,
    ...extra
}: TableHeaderProps<Equipment>) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { onApplySorting, onClearSorting } = useEquipmentTableStates();
    const { id } = header || {};
    const headerText = id && t(`columns.equipmentTable.${id}`);
    const defaultTestId = id ? `${ROOT_CLASS_NAME}__${id}` : ROOT_CLASS_NAME;
    const dataTestId = extra['data-testid'] || defaultTestId;
    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        className,
        {
            [`${ROOT_CLASS_NAME}__${id}`]: id
        },
        'sortable-header'
    );

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            {header?.column.columnDef.enableSorting && id && (
                <TableSortElement
                    columnId={id}
                    onClearSorting={onClearSorting}
                    onApplySorting={onApplySorting}
                />
            )}
            {children}
            <span data-testid={`${dataTestId}-text`}>{headerText}</span>
        </div>
    );
};
