import React, { createContext, useState, useMemo, useContext } from 'react';
import { mapDrawingUtils } from '~/utils/map';
import { hereMapsDrawingUtils } from '~/utils/map/hereMapsDrawingUtils';
import { HereMapsConstants } from '~/components/HereMaps/HereMapProvider/HereMapsPlatform';
import constants from '~/utils/constants';
import { Context, SupportedMaps } from './types';

const { mapsDrawingStyles: googleMapStyles, mapsDirectionIconPath } = constants;

const { mapsDrawingStyles: hereMapStyles } = HereMapsConstants;

export const MapEngineContext = createContext<Context | undefined>(undefined);

export const useMapEngineContext = (): Context => {
    const context = useContext(MapEngineContext);

    if (!context) {
        throw new Error('MapEngineContext must be within context provider');
    }

    return context;
};

type Props = {
    children: React.ReactChild;
};

// @TODO when Here Maps Wrapper is ready, MapEngineProvider will return the active Map component
// @TODO complete typing
// @TODO convert `map-drawing-utils` and `hereMapsDrawingUtils` into classes

export const MapEngineProvider = ({ children }: Props): JSX.Element => {
    const [mapInstance, setMapInstance] = useState<null | SupportedMaps>(null);

    const mapToolKit = useMemo(() => {
        if (mapInstance instanceof google.maps.Map) {
            return mapDrawingUtils;
        }
        if (mapInstance instanceof H.Map) {
            return hereMapsDrawingUtils;
        }
        return null;
    }, [mapInstance]);

    const mapDrawingStyles = useMemo(() => {
        if (mapInstance instanceof google.maps.Map) {
            return {
                mapsDrawingStyles: googleMapStyles,
                mapsDirectionIconPath
            };
        }
        if (mapInstance instanceof H.Map) {
            return {
                mapsDrawingStyles: hereMapStyles,
                mapsDirectionIconPath
            };
        }
        return null;
    }, [mapInstance]);

    return (
        <MapEngineContext.Provider
            value={{
                mapInstance,
                setMapInstance,
                mapToolKit,
                mapDrawingStyles
            }}
        >
            {children}
        </MapEngineContext.Provider>
    );
};
