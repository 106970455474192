import _ from 'lodash';
import { DateTime } from 'luxon';

import constants from '~/utils/constants';

import { updateState } from '../loadTasksSlice';

export const getRouteDate = ({
    routeDate,
    getState
}: {
    routeDate?: string;
    getState: () => {
        selectedDate?: string;
    };
}): string | undefined | null => {
    if (routeDate) return routeDate;

    const { selectedDate } = getState();
    return selectedDate;
};

export const dispatchLoadTasksState = ({
    dispatch,
    getState,
    newRequestId,
    newState,
    isRequestIdsMustMatch = true
}: {
    dispatch: (action: unknown) => void;
    getState: () => {
        loadTasks: { requestId: string };
    };
    newRequestId: string;
    newState: unknown;
    isRequestIdsMustMatch?: boolean;
}): void => {
    const {
        loadTasks: { requestId }
    } = getState();

    if (!isRequestIdsMustMatch || requestId === newRequestId)
        dispatch(updateState(newState));
};

export const isFetchAllTasksAllowed = (
    {
        limit,
        status,
        routeDate: routeDateProp
    }: {
        limit?: number;
        status?: unknown;
        routeDate: string;
    },
    {
        getState
    }: {
        getState: () => {
            selectedDate?: string;
            loadTasks: {
                lastFetchMilliseconds?: number;
                requestProps: unknown[];
                requestId: string;
            };
        };
    }
): boolean => {
    const routeDate = getRouteDate({
        getState,
        routeDate: routeDateProp
    });
    const {
        loadTasks: { lastFetchMilliseconds, requestProps, requestId }
    } = getState();

    const newLoadTaskProps = [routeDate, status, limit];
    const isNewPropsTheSameAsCurrent = _.isEqual(
        newLoadTaskProps,
        requestProps
    );
    const isLoading = Boolean(requestId);

    if (!isNewPropsTheSameAsCurrent) return true;

    if (isLoading) return false;

    const currentTimeMilliseconds = DateTime.local().toMillis();
    const expirationMilliseconds =
        (lastFetchMilliseconds || 0) + constants.timings.IGNORE_DUPLICATE_FETCH;

    return currentTimeMilliseconds > expirationMilliseconds;
};
