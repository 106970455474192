import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApiTask, AxiosApiResponse } from '~/api/types';
import { useTaskManualDispatch } from '~/hooks';
import {
    selectIsSaveAndAssignTaskMode,
    selectSelectedEquipment,
    setSelectedTabIndex
} from '~/reducers/addTaskSlice';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import {
    selectIsEquipmentDepotPairing,
    selectIsOpenPairEquipmentControlPopup,
    setIsOpenDispatchManagementPopup
} from '~/reducers/mapDrawerSettingsSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { resetSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { setSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { getAllTasks } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import { useAddTask } from '../useAddTask';
import { useClientTaskState } from '../useClientTaskState';
import { useHandleTaskPairing } from '../useHandleTaskPairing';
import {
    ClientTask,
    clientTaskToApiTask,
    emptyClientTask
} from '../utils/addTaskModalUtils';
import { SideEffects, TaskToDispatch } from './types';
import constants from '~/utils/constants';

export const useOnSaveAndCloseClick = ({
    clientTask,
    hideModal
}: {
    clientTask: Required<ClientTask>;
    hideModal: () => void | undefined;
}) => {
    const dispatch = useDispatch();

    const { t } = useTranslation(['addTask']);

    const { addTask } = useAddTask();

    const selectedDate = useSelector(selectDate);
    const isEquipmentPairing = useSelector(
        selectIsOpenPairEquipmentControlPopup
    );
    const isEquipmentDepotPairing = useSelector(selectIsEquipmentDepotPairing);
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const serialSelectedLiveDriver = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    );
    const selectedEquipment = useSelector(selectSelectedEquipment);
    const isSaveAndAssignTaskMode = useSelector(selectIsSaveAndAssignTaskMode);

    const { setClientTask } = useClientTaskState();
    const { manualDispatchTask } = useTaskManualDispatch();
    const { handleTaskPairing } = useHandleTaskPairing();

    const [sideEffects, setSideEffects] = useState<SideEffects>();
    const [tasksToDispatch, setTasksToDispatch] = useState<TaskToDispatch[]>(
        []
    );

    const updateTasksToDispatch = useCallback((newTask) => {
        const taskToDispatch = {
            id: newTask.id
        };
        setTasksToDispatch([taskToDispatch]);
        return [taskToDispatch];
    }, []);

    const onSuccessAddTask = useCallback(
        async (successData: unknown) => {
            const newTask = (successData as AxiosApiResponse<ApiTask>).data
                .data;

            dispatch(
                getAllTasks({
                    routeDate: selectedDate
                })
            );

            if (isEquipmentPairing || isEquipmentDepotPairing) {
                handleTaskPairing(newTask);
            }
            if (selectedEquipment) {
                dispatch(resetSelectedEquipmentIds());
            }

            dispatch(
                addToast({
                    message: t('addTaskSuccess'),
                    variant: 'success'
                })
            );
            if (isSaveAndAssignTaskMode) {
                dispatch(setSelectedTaskIds([newTask.id]));
                dispatch(setIsOpenDispatchManagementPopup(true));
            }

            const driverId = serialSelectedLiveDriver?.id;

            if (driverId) {
                const tasks = updateTasksToDispatch(newTask);
                await manualDispatchTask(
                    {
                        driverId,
                        tasksToDispatch: tasks,
                        position: constants.assignmentPosition
                    },
                    sideEffects
                );
            }

            setSelectedTabIndex(0);
            setClientTask(emptyClientTask);
            hideModal();
        },
        [
            hideModal,
            setClientTask,
            isEquipmentPairing,
            isEquipmentDepotPairing,
            selectedEquipment,
            dispatch,
            handleTaskPairing,
            manualDispatchTask,
            selectedDate,
            serialSelectedLiveDriver,
            isSaveAndAssignTaskMode,
            sideEffects,
            updateTasksToDispatch,
            t
        ]
    );

    const onErrorAddTask = useCallback(() => {
        dispatch(
            addToast({
                message: t('addTaskFailure'),
                variant: 'error'
            })
        );
    }, [dispatch, t]);

    const handleAddTask = useCallback(
        async (apiTask) => {
            await addTask(apiTask, {
                onSuccess: onSuccessAddTask,
                onError: onErrorAddTask
            });
        },
        [addTask, onErrorAddTask, onSuccessAddTask]
    );

    const onSaveAndCloseClick = useCallback(() => {
        const apiTask = clientTaskToApiTask(clientTask);
        handleAddTask(apiTask);
    }, [clientTask, handleAddTask]);

    return {
        onSaveAndCloseClick,
        tasksToDispatch,
        setSideEffects
    };
};
