import { useTranslation } from 'react-i18next';

import { NavigationTabElement } from '~/ui/components/NavigationTabsGroup/types';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsOpenPairEquipmentControlPopup,
    selectIsUnassignedTasksTabActive,
    setIsUnassignedTasksTabActive
} from '~/reducers/mapDrawerSettingsSlice';
import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { resetSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';

export const useLiveDispatchTabsLogic = () => {
    const { t } = useTranslation(['liveDispatchTable']);
    const dispatch = useDispatch();

    const isUnassignedTasksTabActive = useSelector(
        selectIsUnassignedTasksTabActive
    );
    const isOpenPairEquipmentControlPopup = useSelector(
        selectIsOpenPairEquipmentControlPopup
    );

    const handleAvailableEquipmentTabClick = () => {
        dispatch(setIsUnassignedTasksTabActive(false));
        if (!isOpenPairEquipmentControlPopup) {
            dispatch(resetSelectedTaskIds(null));
        }
    };

    const handleUnassignedTasksTabClick = () => {
        dispatch(setIsUnassignedTasksTabActive(true));
        if (!isOpenPairEquipmentControlPopup) {
            dispatch(resetSelectedEquipmentIds());
        }
    };

    const getButtonNames: () => NavigationTabElement[] = () => [
        {
            onClick: handleUnassignedTasksTabClick,
            selected: isUnassignedTasksTabActive,
            text: t('drawerHeader.unassignedTasks'),
            icon: 'iconTask'
        },
        {
            onClick: handleAvailableEquipmentTabClick,
            selected: !isUnassignedTasksTabActive,
            text: t('drawerHeader.availableEquipment'),
            icon: 'iconBox'
        }
    ];

    return {
        isUnassignedTasksTabActive,
        handleAvailableEquipmentTabClick,
        handleUnassignedTasksTabClick,
        tabs: getButtonNames()
    };
};
