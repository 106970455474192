import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TooltipButton } from '~/ui';

import './FooterWithDeleteSaveAndCancelButtons.scss';

type Props = {
    cancelButtonText?: string;
    className?: string;
    isSaveDisabled?: boolean;
    isDeleteDisabled?: boolean;
    saveButtonText?: string;
    onCancel: () => void;
    onSave: () => void;
    onDelete: () => void;
};

export const FooterWithDeleteSaveAndCancelButtons = ({
    cancelButtonText,
    className,
    onCancel,
    onSave,
    onDelete,
    saveButtonText,
    isSaveDisabled = false,
    isDeleteDisabled = false
}: Props): JSX.Element => {
    const { t } = useTranslation('common');

    return (
        <footer
            className={classnames(
                'footer-with-delete-save-and-cancel-buttons _ai-center _jc-flex-start _p-4',
                className
            )}
            data-testid="footer-with-delete-save-and-cancel-buttons"
        >
            <TooltipButton
                data-testid="footer-delete-button"
                variant="danger"
                size="l"
                disabled={isDeleteDisabled}
                onClick={onDelete}
            >
                {t('common:delete')}
            </TooltipButton>
            <div className="save-and-cancel_button-group _ml-auto _d-flex">
                <TooltipButton
                    variant="secondary"
                    size="l"
                    onClick={onCancel}
                    data-testid="footer-cancel-button"
                >
                    {cancelButtonText || t('cancel')}
                </TooltipButton>
                <TooltipButton
                    variant="primary"
                    size="l"
                    disabled={isSaveDisabled}
                    onClick={onSave}
                    data-testid="footer-save-button"
                >
                    {saveButtonText || t('save')}
                </TooltipButton>
            </div>
        </footer>
    );
};
