/**
 * Compartment type fields
 * @category API Types
 */
export type CompartmentType = {
    /**
     * Compartment label
     */
    label: string;
    /**
     * Compartment unit
     */
    unit: string;
    /**
     * Client ID
     */
    client: string;
    /**
     * Whether it's the default compartment
     */
    isDefault: boolean;
    /**
     * Compartment ID
     */
    id: string;
};

export type StopCompartmentDetail = {
    id: string;
    label: string;
    size: number;
    unit: string;
};

export type TaskCompartmentDetail = {
    compartmentType: CompartmentType | string;
    size: number;
};

/**
 * Type for API location (i.e. lat-lng object)
 * @category API Types
 */
export interface Coordinates {
    /**
     * the latitude
     *
     * latitude ranges between -90 and 90 degrees, inclusive
     */
    lat: number;

    /**
     * the longitude
     *
     * longitude ranges between -180 and 180 degrees, inclusive
     */
    lng: number;
}

/**
 * Type for a coordinate point
 *
 * This is a fixed-length array with 2 number items.
 * + The `first item` in the array represents the `longitude`.
 * + The `second item` in the array represents the `latitude`.
 */
export type CoordinatePoint = [Coordinates['lng'], Coordinates['lat']];

/**
 * location object
 * @category API Types
 */
export interface Location {
    /**
     * the location coordinates
     */
    location: Coordinates;
}

/**
 * Type for API contact information
 * @category API Types
 */
export interface Profile {
    /**
     * the profile name
     */
    name?: string;
    /**
     * the profile first name
     */
    firstname?: string;

    /**
     * the profile last name
     */
    lastname?: string;
    /**
     * the profile initials
     */
    initials: string;
}

/**
 * Type for API contact information
 * @category API Types
 */
export interface Contact {
    /**
     * the contact name
     */
    name: string;
    /**
     * the contact phone
     */
    phone?: string;
    /**
     * the contact email
     */
    email?: string;

    /**
     * First name of the contact
     */
    firstname?: string;

    /**
     * Last name of the contact
     */
    lastname?: string;
}

/**
 * Address structure
 * @category API Types
 */
export interface Address {
    /**
     * the street address
     */
    addressLine1: string;
    /**
     * the supplementary address line (suite, building, etc...)
     */
    addressLine2?: string;
    /**
     * the address city
     */
    city: string;
    /**
     * the address state / province
     */
    state: string;
    /**
     * the address zip / postal code
     */
    zipcode: string;
}

/**
 * Address with location structure
 * @category API Types
 */
export interface AddressWithLocation extends Address, Location {}

/**
 * Full Address structure
 * @category API Types
 */
export interface FullAddress extends Address {
    /**
     * the address name
     */
    name: string;
    /**
     * the address country
     */
    country: string;
}

/**
 * Full Address with location structure
 * @category API Types
 */
export interface FullAddressWithLocation extends FullAddress, Location {}

/**
 * Two-task address structure
 * @category API Types
 */
export interface TwoPartAddress {
    /**
     * The address for the pickup portion of the two-part task
     */
    pickupLocation: Address;
    /**
     * The address for the delivery portion of the two-part task
     */
    deliveryLocation: Address;
}

/**
 * Time window structure
 * @category API Types
 */
export interface TimeWindow {
    /**
     * Start of time window. ISO-8601 timestamp.
     */
    start: string;
    /**
     * End of time window. ISO-8601 timestamp.
     */
    end: string;
}

/**
 * Service window structure
 * @category API Types
 */
export type ServiceWindow = TimeWindow | TimeWindow[];

/**
 * Two-part Task service window structure
 * @category API Types
 */
export interface TwoPartServiceWindow {
    /**
     * The service window for the pickup portion of the two-part task
     */
    pickup: ServiceWindow;
    /**
     * The service window for the delivery portion of the two-part task
     */
    delivery: ServiceWindow;
}

/**
 * Web color structure
 * @category API Types
 */
export interface WebColor {
    /**
     * a hexadecimal string representing the background color
     */
    bgColor: string;
    /**
     * hexadecimal string representing the foreground color
     */
    fgColor: string;
}

/**
 * Assigned web color structure
 * @category API Types
 */
export interface AssignedWebColor {
    /**
     * a hexadecimal string for the `color` property in a style object
     */
    color: string;
    /**
     * a hexadecimal string for the `backgroundColor` property in a style object
     */
    backgroundColor: string;
}

/**
 * React query side effects
 * @category API Types
 */
export interface ReactQuerySideEffects<
    TData = unknown,
    TError = unknown,
    TVariables = unknown,
    TContext = unknown
> {
    /**
     * This function will fire when the query or mutation is successful and will be passed the result.
     *
     * If a promise is returned, it will be awaited and resolved before proceeding.
     */
    onSuccess?: (
        data?: TData,
        variables?: TVariables,
        context?: TContext
    ) => Promise<unknown> | void;
    /**
     * This function will fire if the query or mutation encounters an error and will be passed the error.
     *
     * If a promise is returned, it will be awaited and resolved before proceeding
     */
    onError?: (
        error?: TError,
        variables?: TVariables,
        context?: TContext | undefined
    ) => Promise<unknown> | void;
    /**
     * This function will fire when the query or mutation is either
     * successfully fetched or encounters an error and be passed either the data or error.
     *
     * If a promise is returned, it will be awaited and resolved before proceeding.
     */
    onSettled?: (
        data?: TData | undefined,
        error?: TError | null,
        variables?: TVariables,
        context?: TContext | undefined
    ) => Promise<unknown> | void;
}

/**
 * Private API fields that may or may not be included in an API response.
 * In general, these fields should not be used. They are included here
 * to satisfy type checking.
 * @category API Types
 */
export interface IPrivateApiFields {
    /**
     * private api field for the object id. Prefer to use the `id` field
     */
    _id: string;
    /**
     * private api field generated by Mongoose
     */
    __v: number;
    /**
     * the api resource owner
     */
    owner: string;
}

/**
 * Status values for `PromiseSettledResult` when using `Promise.allSettled()`
 */
export enum PromiseSettledResultStatus {
    /**
     * When using `Promise.allSettled()`, the status value when the promise is fulfilled
     *
     * @see {@link https://github.com/microsoft/TypeScript/blob/main/src/lib/es2020.promise.d.ts PromiseSettledResult}
     *
     */
    FULFILLED = 'fulfilled',
    /**
     * When using `Promise.allSettled()`, the status value when the promise is rejected
     *
     * @see {@link https://github.com/microsoft/TypeScript/blob/main/src/lib/es2020.promise.d.ts PromiseSettledResult}
     */
    REJECTED = 'rejected'
}
