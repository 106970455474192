import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { selectShowStopNumber } from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import { Icon } from '~/ui';
import { useHereMaps } from '~/hooks';
import { useIsStopSelected } from './useIsStopSelected';
import { useTranslation } from 'react-i18next';
import { useStopMarkerLabel } from './useStopMarkerLabel';

import './stopmarker.scss';

function StopMarker({
    className,
    number = '-1',
    label = '',
    colorCSS = {},
    data = {},
    isPlanned,
    isHighPriority,
    lat,
    lng,
    mapRouteMode = constants.mapRouteModes.PLAN,
    emittedEventHandler = () => {},
    customLabel = undefined
}) {
    const { t } = useTranslation('common');

    const [isDisabled, setIsDisabled] = useState(false);
    const { isHerePlanMapActive } = useHereMaps();
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const showStopNumber = useSelector(selectShowStopNumber(mapRouteMode));
    const isSelected = useIsStopSelected({ stopData: data });

    const { isAtRisk, isLate, taskType, isUnassigned, isDelivery } = data;

    const stopMarkerLabel = useStopMarkerLabel({
        customLabel,
        label,
        mapRouteMode
    });

    useEffect(() => {
        setIsDisabled(typeof emittedEventHandler !== 'function');
    }, [emittedEventHandler]);

    function _handleMouseEnter() {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEENTER,
            payload: {
                location: {
                    lat,
                    lng
                },
                stopMarkerData: data
            }
        });
    }

    function _handleDragStart() {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_DRAGSTART,
            payload: {
                isSelected,
                isPlanned,
                id: data.clientRouteTaskId,
                combinedIds: [data.clientRouteTaskId],
                clientRouteId: data.clientRouteId,
                type: constants.mapChildEvents.STOP_DRAGSTART,
                selectedMapStops
            }
        });
    }

    function _handleMouseUp() {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEUP,
            payload: {
                isSelected,
                isTwoPart: data.isTwoPart,
                isPlanned,
                id: data.clientRouteTaskId,
                routeDate: data.routeDate,
                clientRouteId: data.clientRouteId,
                selectedMapStops,
                taskId: data.taskId,
                stopLevelData: data.toJSON()
            }
        });
    }

    function _getClassName() {
        let boxClassName = 'stopmarker';
        boxClassName =
            (isSelected && `${boxClassName} stopmarker_selected`) ||
            boxClassName;
        boxClassName =
            (isHighPriority && `${boxClassName} stopmarker-star`) ||
            boxClassName;
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function getIndicatorIconClass() {
        const boxClassName = 'stopmarker__icon _p-absolute';

        const conditionalClasses = {
            'icon--priority': isHighPriority,
            'icon--default': !isHighPriority
        };
        return classNames(boxClassName, conditionalClasses);
    }

    function getIndicatorIcon() {
        if (!isAtRisk && !isLate) return null;
        return (
            <Icon
                className={getIndicatorIconClass()}
                icon="clockFill3"
                color={isAtRisk ? 'venus' : 'mars'}
                size="s"
                stroke="comet"
                data-testid="clock-fill-icon"
            />
        );
    }

    const getUnassignedTaskStopIcon = () => {
        const isDeliveryTask =
            taskType === constants.taskTypes.DELIVERY || isDelivery;

        return isDeliveryTask
            ? t('unassignedTaskIcons.delivery')
            : t('unassignedTaskIcons.pickup');
    };

    function _getMarkerBody() {
        if (isHighPriority && !isHerePlanMapActive) {
            return (
                <>
                    <Icon
                        color={colorCSS.backgroundColor}
                        fillOpacity={colorCSS.opacity || 1}
                        icon="stopPriority"
                        size="xl"
                        data-testid="stop-marker-proprity"
                    />
                    {showStopNumber && (
                        <span className="stopmarker-star_number">{number}</span>
                    )}
                    {getIndicatorIcon()}
                </>
            );
        }
        return (
            <div className="_p-relative" data-testid="default-stop-marker">
                <span
                    className="stopmarker-circle"
                    data-testid="stop-marker-circle"
                    style={colorCSS}
                >
                    {isUnassigned
                        ? getUnassignedTaskStopIcon()
                        : showStopNumber && number}
                </span>
                {getIndicatorIcon()}
            </div>
        );
    }

    return (
        <button
            type="button"
            className={_getClassName()}
            onMouseEnter={_handleMouseEnter}
            draggable="true"
            onDragStart={_handleDragStart}
            onMouseUp={_handleMouseUp}
            disabled={isDisabled}
            data-testid="stop-marker"
        >
            <div className="stopmarker-body">{_getMarkerBody()}</div>
            {stopMarkerLabel}
        </button>
    );
}

export default StopMarker;
