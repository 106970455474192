import React, { useState, useRef } from 'react';
import { Icon, TextInput } from '~/ui';
import classNames from 'classnames';
import { EditableTextActions } from './EditableTextActions';
import './EditableText.scss';

function EditableText({
    mainContent,
    handleSubmit,
    handleCancel = () => {},
    index = 0,
    isEditable = false,
    isEditMode = false,
    showEditIcon = false,
    formatter = (value) => value,
    ...extra
}) {
    const [isTextInput, setIsTextInput] = useState(isEditMode);
    const textInputRef = useRef(null);

    /**
     * Returns when mainContent props is empty
     * (value of 0 is not considered empty)
     * @returns {boolean}
     */
    function isMainContentEmpty() {
        return !mainContent && mainContent !== 0;
    }

    function _getClassName() {
        const defaultClassNames = 'editabletext _d-flex';
        const conditionalClasses = {
            'editable-text--enabled': isEditable,
            '_jc-space-between': !isMainContentEmpty(),
            '_jc-flex-end': isMainContentEmpty()
        };
        return classNames(defaultClassNames, conditionalClasses);
    }

    function onClickCheck() {
        if (textInputRef.current.value !== mainContent) {
            const { value } = textInputRef.current;
            handleSubmit({ index, value });
        } else {
            handleCancel();
        }
        setIsTextInput(false);
    }

    function onClickCancel() {
        handleCancel();
        setIsTextInput(false);
    }

    function onClickDetail() {
        if (isEditable) setIsTextInput(!isTextInput);
    }

    function getMainContent() {
        return (
            <div className="_fd-column edit-mode">
                <TextInput
                    ref={textInputRef}
                    value={mainContent}
                    width="fullWidth"
                    variant="edit"
                    data-testid="editabletext-input"
                />
                <EditableTextActions
                    onClickCancel={onClickCancel}
                    onClickCheck={onClickCheck}
                />
            </div>
        );
    }

    return (
        <>
            {isTextInput ? (
                getMainContent()
            ) : (
                <span
                    className={_getClassName()}
                    onClick={onClickDetail}
                    onKeyDown={onClickDetail}
                    role="button"
                    tabIndex={0}
                    data-testid={extra['data-testid'] || 'editabletext'}
                >
                    {formatter(mainContent)}
                    {showEditIcon && (
                        <Icon
                            data-testid={
                                `${extra['data-testid']}-icon` ||
                                'editabletext-icon'
                            }
                            iconSize="m"
                            className="editabletext-icon"
                            icon="edit"
                        />
                    )}
                </span>
            )}
        </>
    );
}

export default EditableText;
