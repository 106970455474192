import React from 'react';
import { LiveDispatchViewColumns } from '../LiveDispatchViewColumns';
import { EquipmentTableSearchBar } from '../EquipmentTableSearchBar';
import { useEquipmentTableStates } from '../useEquipmentTableStates';
import { EquipmentTableColumnIds } from '../useEquipmentTableConfig/columnDef';

import './EquipmentTableToolbar.scss';

export const EquipmentTableToolbar = () => {
    const {
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder
    } = useEquipmentTableStates();

    return (
        <div
            className="live-dispatch-table-toolbar"
            data-testid="live-dispatch-table-toolbar"
        >
            <div className="live-dispatch-table-toolbar__header-container">
                <EquipmentTableSearchBar />
                <LiveDispatchViewColumns
                    columnIds={EquipmentTableColumnIds}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                />
            </div>
        </div>
    );
};
