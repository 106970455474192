import {
    Address,
    ApiInventoryItem,
    ApiNote,
    ApiTask,
    Coordinates,
    DeliveryTask,
    PickupTask,
    ServiceWindow,
    StopCompartmentDetail,
    TaskCompartmentDetail,
    TaskPriority,
    TaskStatus,
    TaskStatusCondition,
    TaskTypes,
    TypeAgnosticStopData,
    TypeSpecificStopData
} from '~/api/types';
import constants from '~/utils/constants';
import { idUtils } from '~/utils/id-utils';
import { parseCompartmentDetails } from '~/utils/compartment-utils';
import taskUtils from '~/utils/task-utils';

/**
 * A constant identifying the task to be a two-part task.
 */
const TASK_TYPE_TWOPART = 'twoPart';

/**
 * A constant identifying the task route ID as unplanned
 */
const TASK_ROUTE_ID_UNPLANNED = 'unplanned';

/**
 * Task data class
 *
 * @category Data Classes
 *
 * @example
 * import Task from '~/data-classes/task/Task';
 *
 * const srcData = {};
 * const task = new Task(srcData);
 *
 */
export class Task {
    /**
     * The API source data
     */
    private readonly apiTask: ApiTask;

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(apiTask: ApiTask) {
        this.apiTask = apiTask;
    }

    /**
     * the task ID
     */
    get id(): string {
        return this.apiTask.id || this.apiTask._id;
    }

    /**
     * the route id provided by client
     */
    get euid(): string | null {
        return this.apiTask?.euid || null;
    }

    /**
     * the task route ID
     */
    get routeId(): string {
        return this.apiTask.routeId || TASK_ROUTE_ID_UNPLANNED;
    }

    /**
     * the task client ID
     */
    get clientId(): string {
        return this.apiTask.client;
    }

    /**
     * the combined client route task id
     */
    get clientRouteTaskId(): string {
        return idUtils.getCombinedId(this.clientId, this.routeId, this.id);
    }

    /**
     * the task name
     */
    get name(): string {
        return this.apiTask.name;
    }

    /**
     * the task labels array
     */
    get labels(): string[] {
        return this.apiTask.labels ?? [];
    }

    /**
     * the task status condition code
     */
    get taskStatusCondition(): TaskStatusCondition {
        const { taskStatus } = this.apiTask;
        if (taskStatus) return taskStatus;

        return taskUtils.getTaskStatus(this.apiTask) as TaskStatusCondition;
    }

    /**
     * the task status code
     */
    get status(): TaskStatus {
        return this.apiTask.status;
    }

    /**
     * determine whether this task is unassigned
     */
    get isUnassigned(): boolean {
        return this.status === TaskStatus.UNASSIGNED;
    }

    /**
     * determine whether this task is dispatched
     */
    get isDispatched(): boolean {
        return this.status === TaskStatus.ASSIGNED;
    }

    /**
     * determine whether this task is in progress
     */
    get isInProgress(): boolean {
        return this.status === TaskStatus.IN_PROGRESS;
    }

    /**
     * determine whether this task is completed
     */
    get isCompleted(): boolean {
        return this.status === TaskStatus.COMPLETED;
    }

    /**
     * determine whether this task is cancelled
     */
    get isCancelled(): boolean {
        return this.status === TaskStatus.FAILED;
    }

    /**
     * determine whether this task is planned
     */
    get isPlanned(): boolean {
        return this.isUnassigned && this.routeId !== TASK_ROUTE_ID_UNPLANNED;
    }

    /**
     * determine whether the task is for pickup
     */
    get isPickup(): boolean {
        const pickupTask = this.apiTask as PickupTask;
        return Boolean(pickupTask.pickupLocation);
    }

    /**
     * determine whether the task is for delivery
     */
    get isDelivery(): boolean {
        const deliveryTask = this.apiTask as DeliveryTask;
        return Boolean(deliveryTask.deliveryLocation);
    }

    /**
     * determine whether the task is for both pickup and delivery
     */
    get isTwoPart(): boolean {
        return this.isPickup && this.isDelivery;
    }

    /**
     * the task type
     */
    get taskType(): string {
        if (this.isTwoPart) return TASK_TYPE_TWOPART;

        return this.isPickup ? TaskTypes.PICKUP : TaskTypes.DELIVERY;
    }

    /**
     * the external task type, optionally set by a client on task creation
     */
    get externalTaskType(): string | null {
        return this.apiTask?.externalTaskType || null;
    }

    /**
     * the external links, optionally set by a client on task creation
     */
    get externalLinks(): string[] | null {
        return this.apiTask?.props?.externalLinks || null;
    }

    /**
     * the task notes
     */
    get notes(): ApiNote[] | null {
        return this.apiTask?.props?.notes || null;
    }

    /**
     * the task priority code
     */
    get taskPriority(): TaskPriority {
        return this.apiTask.props.priority;
    }

    /**
     * determine whether the task is high priority
     */
    get isHighPriority(): boolean {
        return this.apiTask.props.priority === TaskPriority.HIGH;
    }

    /**
     * the task pickup location name
     */
    get pickupLocationName(): string | null {
        return this.pickupTask?.pickupLocation.name || null;
    }

    /**
     * the task pickup address object
     */
    get pickupLocationAddress(): Address | null {
        if (!this.pickupTask?.pickupLocation) return null;

        const { addressLine1, addressLine2, city, state, zipcode } =
            this.pickupTask.pickupLocation;
        return {
            addressLine1,
            addressLine2,
            city,
            state,
            zipcode
        } as Address;
    }

    /**
     * the pickup location latitude and longitude
     */
    get pickupLocationLatLng(): Coordinates | null {
        const { location, geoLocation } = this.pickupTask?.pickupLocation || {};

        if (location) return location;

        if (geoLocation?.coordinates) {
            const [lng, lat] = geoLocation.coordinates;
            return { lat, lng };
        }

        return null;
    }

    /**
     * the task pickup time as ISO date-time string
     */
    get pickupTime(): string | null {
        return this.pickupTask?.props.pickupTime || null;
    }

    /**
     * the task pickup time as ISO date-time string
     */
    get pickupStartTime(): string | null {
        if (!this.pickupTask?.props?.pickupWindow) return null;

        const { pickupWindow: serviceWindows } = this.pickupTask?.props || {};
        return serviceWindows.length === 1 ? serviceWindows[0].start : null;
    }

    /**
     * the task pickup time as ISO date-time string
     */
    get pickupEndTime(): string | null {
        if (!this.pickupTask?.props?.pickupWindow) return null;

        const { pickupWindow: serviceWindows } = this.pickupTask?.props || {};
        return serviceWindows.length === 1 ? serviceWindows[0].end : null;
    }

    /**
     * the task pickup time as ISO date-time string
     */
    get deliveryStartTime(): string | null {
        if (!this.deliveryTask?.props?.deliveryWindow) return null;

        const { deliveryWindow: serviceWindows } =
            this.deliveryTask?.props || {};
        return serviceWindows.length === 1 ? serviceWindows[0].start : null;
    }

    /**
     * the task pickup time as ISO date-time string
     */
    get deliveryEndTime(): string | null {
        if (!this.deliveryTask?.props?.deliveryWindow) return null;

        const { deliveryWindow: serviceWindows } =
            this.deliveryTask?.props || {};
        return serviceWindows.length === 1 ? serviceWindows[0].end : null;
    }

    /**
     * the task pickup service time as ISO duration string
     */
    get pickupServiceTime(): string | null {
        return this.pickupTask?.props.pickupServiceTime || null;
    }

    /**
     * the task pickup window
     * the task's location may have more than 1 service windows.
     */
    get pickupWindow(): ServiceWindow | null {
        if (!this.pickupTask?.props?.pickupWindow) return null;

        const { pickupWindow: serviceWindows } = this.pickupTask.props;
        return serviceWindows.length === 1 ? serviceWindows[0] : serviceWindows;
    }

    /**
     * the task pickup inventory array
     */
    get pickupInventory(): ApiInventoryItem[] | null {
        return this.pickupTask?.props.pickupInventory || null;
    }

    /**
     * the task original pickup labels
     */
    get pickupLabels(): string[] {
        return this.pickupTask?.props?.originalPickup?.labels || this.labels;
    }

    /**
     * the task delivery location name
     */
    get deliveryLocationName(): string | null {
        return this.deliveryTask?.deliveryLocation.name || null;
    }

    /**
     * the task delivery address object
     */
    get deliveryLocationAddress(): Address | null {
        if (!this.deliveryTask?.deliveryLocation) return null;

        const { addressLine1, addressLine2, city, state, zipcode } =
            this.deliveryTask.deliveryLocation;
        return {
            addressLine1,
            addressLine2,
            city,
            state,
            zipcode
        } as Address;
    }

    /**
     * the delivery location latitude and longitude
     */
    get deliveryLocationLatLng(): Coordinates | null {
        const { location, geoLocation } =
            this.deliveryTask?.deliveryLocation || {};

        if (location) return location;

        if (geoLocation?.coordinates) {
            const [lng, lat] = geoLocation.coordinates;
            return { lat, lng };
        }

        return null;
    }

    /**
     * the task delivery time as ISO date-time string
     */
    get deliveryTime(): string | null {
        return this.deliveryTask?.props.deliveryTime || null;
    }

    /**
     * the task delivery service time as ISO duration string
     */
    get deliveryServiceTime(): string | null {
        return this.deliveryTask?.props.deliveryServiceTime || null;
    }

    /**
     * the task delivery window
     * the task's location may have more than 1 service windows.
     */
    get deliveryWindow(): ServiceWindow | null {
        if (!this.deliveryTask?.props?.deliveryWindow) return null;

        const { deliveryWindow: serviceWindows } = this.deliveryTask.props;
        return serviceWindows.length === 1 ? serviceWindows[0] : serviceWindows;
    }

    /**
     * the task delivery inventory array
     */
    get deliveryInventory(): ApiInventoryItem[] | null {
        return this.deliveryTask?.props.deliveryInventory || null;
    }

    /**
     * the task original delivery labels
     */
    get deliveryLabels(): string[] {
        return this.deliveryTask?.props.originaldelivery?.labels || this.labels;
    }

    /**
     * the route date for this task
     */
    get routeDate(): string {
        return this.apiTask.routeDate;
    }

    /**
     * the data that is common between the pickup and delivery parts required for use with StopMarker
     *
     * @todo verify that `toJSON` is required for this property,
     * calling it return a reference to the class' `toJSON` method
     */
    get typeAgnosticStopData(): TypeAgnosticStopData {
        return {
            clientRouteId: idUtils.getCombinedId(this.clientId, this.routeId),
            isTwoPart: this.isTwoPart,
            routeDate: this.routeDate,
            taskId: this.id,
            isPlanned: this.isPlanned,
            isHighPriority: this.isHighPriority,
            toJSON: () => this.toJSON
        };
    }

    /**
     * the data that is required for use with StopMarker for the pickup part
     */
    get pickupStopData(): TypeSpecificStopData {
        return {
            ...this.typeAgnosticStopData,
            clientRouteTaskId: idUtils.getCombinedId(
                this.clientId,
                this.routeId,
                constants.taskTypes.PICKUP,
                this.id
            ),
            lat: this.pickupLocationLatLng?.lat || null,
            lng: this.pickupLocationLatLng?.lng || null,
            label: this.pickupLocationName
        };
    }

    /**
     * the data that is required for use with StopMarker for the delivery part
     */
    get deliveryStopData(): TypeSpecificStopData {
        return {
            ...this.typeAgnosticStopData,
            clientRouteTaskId: idUtils.getCombinedId(
                this.clientId,
                this.routeId,
                constants.taskTypes.DELIVERY,
                this.id
            ),
            lat: this.deliveryLocationLatLng?.lat || null,
            lng: this.deliveryLocationLatLng?.lng || null,
            label: this.deliveryLocationName
        };
    }

    /**
     * Narrows the type of the underlying source {@link ApiTask}
     * to {@link PickupTask}. Returns `null` if the task has no pickup
     * part.
     */
    get pickupTask(): PickupTask | null {
        return this.isPickup ? (this.apiTask as PickupTask) : null;
    }

    /**
     * Narrows the type of the underlying source {@link ApiTask}
     * to {@link DeliveryTask}. Returns `null` if the task has no delivery
     * part.
     */
    get deliveryTask(): DeliveryTask | null {
        return this.isDelivery ? (this.apiTask as DeliveryTask) : null;
    }

    /**
     * the 2-part task id
     */
    get twoPartId(): string {
        return this.apiTask?.twoPartId || this.id;
    }

    /**
     * The task size by compartment
     * For multi-compartment account setup - array of 1+
     * For single-compartment account setup - array of 1
     * @type {array}
     */
    get sizeByCompartment(): StopCompartmentDetail[] {
        const { sizeByCompartment = [] } = this.apiTask;
        return sizeByCompartment.map((compartment) => {
            return parseCompartmentDetails(
                compartment as unknown as TaskCompartmentDetail
            );
        });
    }

    /**
     * the task delivery location customer ID
     */
    get deliveryCustomerId(): string | null {
        return this.deliveryTask?.deliveryLocation?.customer || null;
    }

    /**
     * the task delivery EUID
     */
    get deliveryEuid(): string | null {
        return this.deliveryTask?.deliveryEuid || null;
    }

    /**
     * the task pickup location customer ID
     */
    get pickupCustomerId(): string | null {
        return this.pickupTask?.pickupLocation?.customer || null;
    }

    /**
     * the task pickup EUID
     */
    get pickupEuid(): string | null {
        return this.pickupTask?.pickupEuid || null;
    }

    /**
     * the task equipment ID
     */
    get equipmentId(): string | null {
        return this.apiTask.equipmentId;
    }

    /**
     * the task equipment payer of freight
     */
    get payerOfFreight(): string | null {
        return this.apiTask.payerOfFreight;
    }

    /**
     * the task equipment reservation
     */
    get reservation(): string | null {
        return this.apiTask.reservation;
    }

    /**
     * the task equipment type
     */
    get equipmentType(): string | null {
        return this.apiTask.carKind;
    }

    /**
     * the task equipment drop/stay status
     */
    get dropStay(): string | null {
        return this.apiTask.dropStay;
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiTask}
     */
    toJSON(): ApiTask {
        return this.apiTask;
    }
}
