import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import EquipmentApi from '~/api/EquipmentApi';
import dateUtils from '~/utils/date-utils';
import { has } from 'lodash';
import { EquipmentItem, EquipmentItems } from './types';
import { useUpdateTask } from '~/components/TaskManagementPage/TaskDetailDrawer/useUpdateTask';

const TIMESTAMP = 'timestamp';

interface UseEquipmentDetailsProps {
    equipmentId: string;
    hasEditPermissions: boolean;
    taskId: string;
}

export const useEquipmentDetails = ({
    equipmentId,
    hasEditPermissions,
    taskId
}: UseEquipmentDetailsProps) => {
    const { t } = useTranslation(['taskManagement', 'common', 'error']);

    const { updateTask: apiRequestToUpdate } = useUpdateTask({
        isTaskReadOnly: !hasEditPermissions
    });

    const emptyEquipmentData: EquipmentItem[] = [
        {
            iconName: 'equipmentId',
            tooltipContent: t('drawer.equipmentDetails.equipmentId'),
            value: 'N/A',
            key: 'id'
        },
        {
            iconName: 'equipmentName',
            tooltipContent: t('drawer.equipmentDetails.name'),
            value: 'N/A',
            key: 'name'
        },
        {
            iconName: 'equipmentType',
            tooltipContent: t('drawer.equipmentDetails.type'),
            value: 'N/A',
            key: 'type'
        },
        {
            iconName: 'equipmentLatLng',
            tooltipContent: `${t('drawer.equipmentDetails.latitude')}, ${t(
                'drawer.equipmentDetails.longitude'
            )}`,
            value: 'N/A, N/A',
            key: 'lat-lng'
        },
        {
            iconName: 'equipmentStatus',
            tooltipContent: t('drawer.equipmentDetails.status'),
            value: 'N/A',
            key: 'status'
        },
        {
            iconName: 'equipmentLastUpdated',
            tooltipContent: t('drawer.equipmentDetails.lastUpdated'),
            value: 'N/A',
            key: 'timestamp'
        },
        {
            iconName: 'equipmentAttached',
            tooltipContent: t('drawer.equipmentDetails.attached'),
            value: ['N/A'],
            key: 'attached'
        },
        {
            iconName: 'equipmentState',
            tooltipContent: t('drawer.equipmentDetails.state'),
            value: ['N/A'],
            key: 'state'
        },
        {
            iconName: 'calendarClock',
            tooltipContent: t('drawer.equipmentDetails.reservation'),
            value: 'N/A',
            key: 'reservation'
        },
        {
            iconName: 'universalCurrency',
            tooltipContent: t('drawer.equipmentDetails.payerOfFreight'),
            value: 'N/A',
            key: 'payerOfFreight'
        },
        {
            iconName: 'bottomPanelClose',
            tooltipContent: t('drawer.equipmentDetails.dropStay'),
            value: 'N/A',
            key: 'dropStay'
        }
    ];

    const [equipmentData, setEquipmentData] =
        useState<EquipmentItem[]>(emptyEquipmentData);

    const equipmentItems: EquipmentItems = {
        id: {
            iconName: 'equipmentId',
            tooltipContent: t('drawer.equipmentDetails.equipmentId')
        },
        name: {
            iconName: 'equipmentName',
            tooltipContent: t('drawer.equipmentDetails.name')
        },
        type: {
            iconName: 'equipmentType',
            tooltipContent: t('drawer.equipmentDetails.type')
        },
        lat: {
            iconName: 'equipmentLatLng',
            tooltipContent: t('drawer.equipmentDetails.latitude')
        },
        lng: {
            iconName: 'equipmentLatLng',
            tooltipContent: t('drawer.equipmentDetails.longitude')
        },
        status: {
            iconName: 'equipmentStatus',
            tooltipContent: t('drawer.equipmentDetails.status')
        },
        timestamp: {
            iconName: 'equipmentLastUpdated',
            tooltipContent: t('drawer.equipmentDetails.lastUpdated')
        },
        attached: {
            iconName: 'equipmentAttached',
            tooltipContent: t('drawer.equipmentDetails.attached'),
            value: []
        },
        state: {
            iconName: 'equipmentState',
            tooltipContent: t('drawer.equipmentDetails.state'),
            value: []
        },
        reservation: {
            iconName: 'calendarClock',
            tooltipContent: t('drawer.equipmentDetails.reservation')
        },
        payerOfFreight: {
            iconName: 'universalCurrency',
            tooltipContent: t('drawer.equipmentDetails.payerOfFreight')
        },
        dropStay: {
            iconName: 'bottomPanelClose',
            tooltipContent: t('drawer.equipmentDetails.dropStay')
        }
    };

    const getEquipmentData = (data: Record<string, unknown>) => {
        const itemKeys = Object.keys(equipmentItems);

        const iterableEquipmentData = itemKeys.map((eachKey: string) => {
            let value = has(data, eachKey) && data[eachKey];
            if (!value || (Array.isArray(value) && !value.length)) {
                value = t('drawer.equipmentDetails.notApplicable');
            }
            return {
                ...equipmentItems[eachKey],
                value,
                key: eachKey
            };
        }) as EquipmentItem[];

        // club lat and lng and delete lng from data -- start
        const latIndex = iterableEquipmentData.findIndex(
            (eachItem) => eachItem.key === 'lat'
        );
        const lngIndex = iterableEquipmentData.findIndex(
            (eachItem) => eachItem.key === 'lng'
        );

        const latLng: EquipmentItem = {
            ...iterableEquipmentData[latIndex],
            tooltipContent: t('drawer.equipmentDetails.latLng'),
            value: `${iterableEquipmentData[latIndex].value}, ${iterableEquipmentData[lngIndex].value}`,
            key: 'lat-lng',
            iconName: 'equipmentLatLng'
        };

        iterableEquipmentData.splice(latIndex, 2, latLng);
        // club lat and lng and delete lng from data -- end

        // format lastUpdated value -- start
        const lastUpdatedIndex = iterableEquipmentData.findIndex(
            (eachItem) => eachItem.key === TIMESTAMP
        );
        const lastUpdated = iterableEquipmentData[lastUpdatedIndex]
            .value as unknown as Date;
        const formattedDate = dateUtils.formatEquipmentUpdatedAtDate(
            new Date(lastUpdated),
            'alternate'
        );
        iterableEquipmentData[lastUpdatedIndex].value = formattedDate;
        // format lastUpdated value -- end

        return iterableEquipmentData;
    };

    const fetchEquipmentData = async (id: string) => {
        if (!id || id === 'N/A') {
            setEquipmentData(emptyEquipmentData);
            return;
        }
        const response = await EquipmentApi.getEquipmentById(id);
        try {
            const data = getEquipmentData(
                response.data as unknown as Record<string, unknown>
            );
            setEquipmentData(data);
        } catch (error) {
            console.error(error);
        }
    };

    const validateNewEquipmentId = async (newEquipmentId: string) => {
        try {
            await EquipmentApi.getEquipmentById(newEquipmentId);
            return true;
        } catch (error) {
            console.info(error);
            return false;
        }
    };

    useEffect(() => {
        fetchEquipmentData(equipmentId);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [equipmentId]);

    const handleUpdate =
        (property: string) =>
        ({ value }: { value: string }) => {
            apiRequestToUpdate({
                taskId,
                property,
                value
            });
        };

    return {
        equipmentItems,
        equipmentData,
        getEquipmentData,
        fetchEquipmentData,
        validateNewEquipmentId,
        updateEquipmentId: handleUpdate('equipmentId'),
        handleUpdateReservation: handleUpdate('reservation'),
        handleUpdatePayerOfFreight: handleUpdate('payerOfFreight'),
        handleUpdateDropOrStay: handleUpdate('dropStay')
    };
};
