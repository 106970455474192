import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { IconButton } from '~/ui';
import OutOfService from '~/ui/components/OutOfService/OutOfService';

import constants from '~/utils/constants';

import './RouteDriverSearch.scss';
import ShiftStartTime from '~/ui/components/ShiftStartTime/ShiftStartTime';

function RouteDriverSearch({ routes, onRouteSelection }) {
    const { t } = useTranslation('taskManagement');
    const [isDropDownDisplayed, setDropDownDisplayed] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRoute, setSelectedRoute] = useState('');
    const [filteredRoutes, setFilteredRoutes] = useState([]);

    useEffect(() => {
        clearInput();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [routes]);
    function toggleDropDownDisplayed() {
        setDropDownDisplayed(!isDropDownDisplayed);
    }

    function clearInput() {
        setSearchQuery('');
        setSelectedRoute('');
        setFilteredRoutes(routes);
        onRouteSelection(null);
    }

    function filterResults(value) {
        return routes.filter((route) =>
            route.driverLabel.toLowerCase().includes(value)
        );
    }

    const debouncedFilter = _.debounce((value) => {
        const filterValue = value.toLowerCase();
        setFilteredRoutes(filterResults(filterValue));
    }, constants.timings.TEXT_DEBOUNCE);

    function handleSearchChange({ target: { value } }) {
        setSearchQuery(value);
        debouncedFilter(value);
        // show suggestions
        setDropDownDisplayed(true);
    }

    function handleRouteSelection(route) {
        setSelectedRoute(route.driverLabel);
        setSearchQuery(route.driverLabel);
        // hide suggestions
        setDropDownDisplayed(false);
        setFilteredRoutes([]);
        onRouteSelection(route);
    }

    function getStatusName(statusCode) {
        switch (statusCode) {
            case constants.taskStatus.PLANNED:
                return t('table.taskStatus.planned');
            case constants.taskStatus.DISPATCHED:
                return t('table.taskStatus.dispatched');
            default:
                return null;
        }
    }

    return (
        <div className="_mt-1 routedriversearch">
            <input
                className="_w-100 routedriversearch-input"
                placeholder={t('assignTask.search')}
                value={searchQuery}
                onChange={handleSearchChange}
                data-testid="routedriversearch-input"
            />
            <IconButton
                className={`icon-wrapper routedriversearch-button ${
                    isDropDownDisplayed ? '_t-rotate-180' : ''
                }`}
                icon={selectedRoute ? 'cancel' : 'chevronDown'}
                iconColor="galaxy-800"
                onClick={selectedRoute ? clearInput : toggleDropDownDisplayed}
                data-testid="routedriversearch-button-icon"
            />
            {isDropDownDisplayed && (
                <div
                    className="_d-flex _fd-column _w-100 routedriversearch-dropdown"
                    data-testid="routedriversearch-dropdown"
                >
                    {filteredRoutes.map((route) => {
                        const routeStatus = getStatusName(route.status);
                        const shiftStartText = t('shiftStartTime', {
                            time: route.shiftStartTime
                        });
                        return (
                            <button
                                type="button"
                                key={`${route.status}-${route.driverId}`}
                                className="routedriversearch-dropdown-item button_clear"
                                data-testid="routedriversearch-dropdown-item"
                                onClick={() => handleRouteSelection(route)}
                            >
                                <div className="_d-flex _ai-center _jc-space-between _w-100">
                                    <span className="route-driver-name">
                                        {route.driverLabel}
                                    </span>
                                    <div className="_d-flex _ai-center _jc-space-between driver-tags">
                                        {route.isDriverOutOfService && (
                                            <OutOfService
                                                textMsg={t(
                                                    'outOfServiceDriver'
                                                )}
                                            />
                                        )}
                                        {route.isVehicleOutOfService && (
                                            <OutOfService
                                                textMsg={t(
                                                    'outOfServiceVehicle'
                                                )}
                                            />
                                        )}
                                        <ShiftStartTime
                                            textMsg={shiftStartText}
                                        />
                                        {routeStatus && (
                                            <div className="_text-3 routedriversearch-item-label">
                                                {routeStatus}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </button>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default RouteDriverSearch;
