import {
    ApiDriver,
    TaskPriority,
    TaskTypes,
    AssignmentDelay
} from '~/api/types';
import { LiveDriver } from '../dispatched';
import { getLocalizedAssignmentDelayLabel } from '~/utils/assignment-utils';
import { DateTime, Duration } from 'luxon';
import driverUtils from '~/utils/driver-utils';

type InstantRoutesReportDriverSummary = {
    assignmentsCompleted: number;
    assignmentsRemaining: number;
    mostRecentStatus: string;
    percentageComplete: string;
};

type InstantRoutesReportDriverDetail = {
    assignmentOrder: string | number;
    assignmentName: string;
    delayStatus: string;
    estimatedServiceTime: string;
    serviceTime: string;
    timeWindowStart: string;
    timeWindowEnd: string;
    arrivalTime: string;
    city: string;
    type: string | TaskTypes;
    stopLatitude: string | number;
    stopLongitude: string | number;
    units: number;
    weight: number;
    labels: string;
    priority: string | TaskPriority;
};

type InstantRoutesReportDriverInventory = {
    id: string | undefined;
    routeId: string | undefined;
    driverName: string | undefined;
    assignmentOrder: number;
    assignmentName: string;
    itemId: string;
    itemName: string;
    expectedQuantity: number;
    actualQuantity: number;
    scannedAt: string;
    status: string;
    reasonCode: string;
    barcode: string;
    signatureUrl: string;
    photoUrl: string;
};

export class InstantRoutesReportDriver {
    /**
     * The API source data
     * @type {ApiDriver}
     */
    private readonly driver: ApiDriver;

    private liveDriver: LiveDriver | undefined;

    /**
     * the completion percentage is not-a-number
     */
    static readonly NOT_A_NUMBER = 'NaN%';

    /**
     * the default value when the cell value is dashes
     */
    static readonly DASHES = '------------';

    /**
     * the default value when the cell value is not applicable
     */
    static readonly NOT_APPLICABLE = 'N/A';

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(driver: ApiDriver) {
        this.driver = driver;
    }

    setLiveDriver(driver: LiveDriver): this {
        this.liveDriver = driver;
        return this;
    }

    get routeId(): string | undefined {
        const { vehicle } = this.driver;

        if (!vehicle || typeof vehicle === 'string') return undefined;

        return vehicle.eid;
    }

    get driverName(): string | undefined {
        return driverUtils.getDriverNameFromApiDriver(this.driver);
    }

    get driverId(): string | undefined {
        return this.driver.id;
    }

    get summary(): InstantRoutesReportDriverSummary {
        const initSummary = {
            assignmentsCompleted: 0,
            assignmentsRemaining: 0,
            mostRecentStatus: getLocalizedAssignmentDelayLabel(
                -2 as AssignmentDelay
            ),
            percentageComplete: InstantRoutesReportDriver.NOT_A_NUMBER
        };

        if (!this.liveDriver) return initSummary;

        const { schedule, numStops } = this.liveDriver;

        const { assignmentsCompleted, assignmentsRemaining } = schedule.reduce(
            (allAssignments, assignment) => {
                const { isDepot, isCompleted, delay } = assignment;
                if (!isDepot) {
                    if (isCompleted) {
                        allAssignments.assignmentsCompleted.push(delay);
                    } else {
                        allAssignments.assignmentsRemaining.push(delay);
                    }
                }
                return allAssignments;
            },
            {
                assignmentsCompleted: [] as number[],
                assignmentsRemaining: [] as number[]
            }
        );

        // use either the first of the assignments remaining or the last of the assignments completed;
        const mostRecentStatusCode =
            assignmentsRemaining.length > 0
                ? assignmentsRemaining[0]
                : assignmentsCompleted[assignmentsCompleted.length - 1];
        const mostRecentStatus =
            getLocalizedAssignmentDelayLabel(mostRecentStatusCode); // map completed to 'on time'

        const percentageComplete = assignmentsCompleted.length / numStops;

        return {
            assignmentsCompleted: assignmentsCompleted.length,
            assignmentsRemaining: assignmentsRemaining.length,
            mostRecentStatus,
            percentageComplete: percentageComplete.toLocaleString('en', {
                style: 'percent'
            })
        };
    }

    get details(): InstantRoutesReportDriverDetail[] {
        const initDetails = {
            assignmentOrder: InstantRoutesReportDriver.DASHES,
            assignmentName: InstantRoutesReportDriver.DASHES,
            delayStatus: InstantRoutesReportDriver.DASHES,
            estimatedServiceTime: InstantRoutesReportDriver.DASHES,
            serviceTime: InstantRoutesReportDriver.DASHES,
            timeWindowStart: InstantRoutesReportDriver.DASHES,
            timeWindowEnd: InstantRoutesReportDriver.DASHES,
            arrivalTime: InstantRoutesReportDriver.DASHES,
            city: InstantRoutesReportDriver.DASHES,
            type: InstantRoutesReportDriver.DASHES,
            stopLatitude: InstantRoutesReportDriver.DASHES,
            stopLongitude: InstantRoutesReportDriver.DASHES,
            units: 0,
            weight: 0,
            labels: InstantRoutesReportDriver.DASHES,
            priority: InstantRoutesReportDriver.DASHES
        };

        if (this.liveDriver) {
            const { schedule, volumeCapacityUsed, weightCapacityUsed } =
                this.liveDriver;

            initDetails.units = volumeCapacityUsed;
            initDetails.weight = weightCapacityUsed;

            let assignmentOrder = 0;
            const scheduleDetails = schedule.map((item) => {
                return {
                    assignmentOrder: !item.isDepot
                        ? ++assignmentOrder
                        : item.name,
                    assignmentName: item.stopName,
                    delayStatus: getLocalizedAssignmentDelayLabel(item.delay), // map 'on time' to 'complete'
                    estimatedServiceTime: !item.isDepot
                        ? Duration.fromISO(item.serviceTime)
                              .shiftTo('minutes')
                              .toHuman()
                        : InstantRoutesReportDriver.DASHES,
                    serviceTime: item.serviceDuration
                        ? Duration.fromMillis(item.serviceDuration)
                              .shiftTo('minutes')
                              .toHuman()
                        : InstantRoutesReportDriver.NOT_APPLICABLE,
                    timeWindowStart: item.timeWindow[0].start,
                    timeWindowEnd: item.timeWindow[0].end,
                    arrivalTime: DateTime.fromISO(
                        item.arrivalTime
                    ).toLocaleString(DateTime.TIME_SIMPLE),
                    city: item.address.city,
                    type: item.type,
                    stopLatitude: item.location.lat,
                    stopLongitude: item.location.lng,
                    units: item.size,
                    weight: item.weight,
                    labels: item.labels.join(','),
                    priority: item.priority
                };
            });

            return [initDetails, ...scheduleDetails];
        }

        return [initDetails];
    }

    get inventory(): InstantRoutesReportDriverInventory[] {
        if (!this.liveDriver) return [];

        const { schedule } = this.liveDriver;

        return schedule
            .filter((item) => !item.isDepot)
            .map((item, idx) => {
                const { stopName, inventory } = item;
                const inventoryRows = inventory.map((inventoryItem) => {
                    return {
                        id: inventoryItem.id,
                        routeId: this.routeId,
                        driverName: this.driverName,
                        assignmentOrder: idx + 1,
                        assignmentName: stopName,
                        itemId: inventoryItem.item_id,
                        itemName: inventoryItem.item_name,
                        expectedQuantity: inventoryItem.expected_quantity,
                        actualQuantity: inventoryItem.actual_quantity,
                        scannedAt: inventoryItem.scanned_at,
                        status: inventoryItem.status,
                        reasonCode: inventoryItem.reason_code,
                        barcode: inventoryItem.barcode_id,
                        signatureUrl: inventoryItem.signature_url,
                        photoUrl: inventoryItem.photo_url
                    } as InstantRoutesReportDriverInventory;
                });
                return [...inventoryRows];
            })
            .reduce((allItems, item) => {
                return [...allItems, ...item];
            }, []);
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiDriver}
     */
    toJSON(): ApiDriver {
        return this.driver;
    }
}
