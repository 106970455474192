import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEquipment } from '~/reducers/equipmentSlice';

export const useEquipmentDetailsPopup = () => {
    const equipment = useSelector(selectEquipment);

    const [equipmentId, setEquipmentId] = useState<string>();

    const hoveredEquipment = equipment?.[equipmentId ?? ''];

    const additionalData = useMemo(() => {
        if (hoveredEquipment) {
            const { additionalData: existingLabels } = hoveredEquipment;
            const labels = Object.values(existingLabels).map((value) => value);
            return labels;
        }
    }, [hoveredEquipment]);

    return {
        hoveredEquipment,
        setEquipmentId,
        additionalData
    };
};
