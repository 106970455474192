import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, IconButton, theme } from '~/ui';

import { useLiveDispatchAssignTasks } from '../useLiveDispatchAssignTasks';
import { getDriverInitials } from '../utils';

import './SelectedDriverCard.scss';

export const SelectedDriverCard: React.FC = () => {
    const { t } = useTranslation(['liveDispatchTable']);

    const { driverData, colorCSS, handleDriverCancelButtonClick } =
        useLiveDispatchAssignTasks();

    const markerText = getDriverInitials(driverData?.profile?.name ?? '');

    const driverName = driverData?.profile?.name ?? '';

    return (
        <div
            className="live-dispatch-selected-driver-card"
            data-testid="live-dispatch-selected-driver-card"
        >
            <IconButton
                className="live-dispatch-selected-driver-card__marker"
                data-testid="live-dispatch-selected-driver-card__marker"
                text={markerText}
                textColor={theme.colors.comet}
                textStyle={{
                    ...theme.text['11-medium'],
                    lineHeight: '1.6rem',
                    letterSpacing: '0.0.5rem'
                }}
                style={{
                    backgroundColor: colorCSS?.backgroundColor
                }}
                disabled
            />
            <span
                className="live-dispatch-selected-driver-card__text"
                data-testid="live-dispatch-selected-driver-card__text"
            >
                {driverData?.routeName &&
                    t('dispatchPopup.selectedDriverCard.textWithRouteName', {
                        routeName: driverData?.routeName ?? '',
                        driverName
                    })}
                {!driverData?.routeName &&
                    t('dispatchPopup.selectedDriverCard.textWithoutRouteName', {
                        driverName
                    })}
            </span>
            <Icon
                icon="cancel"
                size="xs"
                onClick={handleDriverCancelButtonClick}
                data-testid="live-dispatch-popup-cancel-icon"
            />
        </div>
    );
};
