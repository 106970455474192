import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AssignTasksModalContent from '~/components/TaskManagementPage/AssignTasksModalContent';
import { useModal } from '~/ui';
import {
    setSelectedDriverData,
    setSelectedDriverId
} from '~/reducers/liveDispatchSelectedDriverSlice';

import { DispatchButton } from '../DispatchButton';
import { SelectDriverCard } from '../SelectDriverCard';
import { SelectedDriverCard } from '../SelectedDriverCard';
import { useLiveDispatchAssignTasks } from '../useLiveDispatchAssignTasks';

import './PopupDriverContainer.scss';

export const PopupDriverContainer: React.FC = () => {
    const { t } = useTranslation(['liveDispatchTable', 'taskManagement']);
    const disptach = useDispatch();

    const { driverData, unassignedTasks } = useLiveDispatchAssignTasks();

    const { showModal, hideModal, modal: AssignTasksModal } = useModal();

    useEffect(() => {
        return () => {
            disptach(setSelectedDriverData(false));
            disptach(setSelectedDriverId(false));
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <div
            className="live-dispatch-popup-driver-container"
            data-testid="live-dispatch-popup-driver-container"
        >
            <span className="live-dispatch-popup-driver-container__title">
                {t('liveDispatchTable:dispatchPopup.selectDriver.title')}
            </span>
            {driverData?.profile && <SelectedDriverCard />}
            {!driverData?.profile && (
                <SelectDriverCard handleClick={showModal} />
            )}
            <AssignTasksModal
                title={t('taskManagement:assignTask.title', {
                    count: unassignedTasks.length
                })}
            >
                <AssignTasksModalContent
                    hideModal={hideModal}
                    selectedTaskIds={unassignedTasks.map((each) => each.id)}
                />
            </AssignTasksModal>
            <DispatchButton />
        </div>
    );
};
