import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '~/ui';

import { useLiveDispatchAssignTasks } from '../useLiveDispatchAssignTasks';

import './PopupHeader.scss';

export const PopupHeader: React.FC = () => {
    const { t } = useTranslation(['liveDispatchTable']);

    const { handleCancelButtonClick } = useLiveDispatchAssignTasks();

    return (
        <div
            className="live-dispatch-popup-header"
            data-testid="live-dispatch-popup-header"
        >
            <div className="live-dispatch-popup-header__wrapper">
                <Icon
                    icon="arrowLeft"
                    stroke="meteor"
                    size="m"
                    data-testid="live-dispatch-popup-header__left-arrow-icon"
                    onClick={handleCancelButtonClick}
                />
                <span
                    className="live-dispatch-popup-header__title"
                    data-testid="live-dispatch-popup-header__title"
                >
                    {t('dispatchPopup.header.title')}
                </span>
            </div>
            <Icon
                icon="cancel"
                size="m"
                onClick={handleCancelButtonClick}
                data-testid="live-dispatch-popup-cancel-icon"
            />
        </div>
    );
};
