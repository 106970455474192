import React, { useState, useRef, useEffect, ReactNode } from 'react';
import classNames from 'classnames';
import type * as CSS from 'csstype';
import { Icon, IconButton, UIElementWithChildren } from '~/ui';

import unitsUtils from '~/utils/unit-converter';

import './collapsible.scss';
import { IconName } from '../Icon/IconNames';
import { IconSizes } from '../Icon/IconSizes';

enum CollapsibleIconPosition {
    /**
     * Positions the toggle button's icon to the left
     */
    LEFT,

    /**
     * Positions the toggle button's icon to the right
     */
    RIGHT
}

enum CollapsibleDirection {
    /**
     * Places the collapsible panel above the toggle button, allowing the panel to close upwards
     */
    UP,

    /**
     * Places the collapsible panel below the toggle button, allowing the panel to close downwards
     */
    DOWN
}

interface CollapsibleProps extends UIElementWithChildren {
    /**
     * The collapse direction
     */
    direction?: CollapsibleDirection;

    /**
     * Whether the collapsible panel is initially open
     */
    initOpen?: boolean;

    /**
     * Whether the toggle button is disabled
     */
    disabled?: boolean;

    /**
     * Overrides the toggle button's click handler, preventing the default toggle open functionality
     */
    overrideClickHandler?: () => void;

    /**
     * The collapsible toggle button open text
     */
    textOpen?: string;

    /**
     * The collapsible toggle button close text
     */
    textClose?: string;

    /**
     * The collapsible toggle button open icon
     */
    iconOpen?: IconName;

    /**
     * The collapsible toggle button close icon
     */
    iconClose?: IconName;

    /**
     * The collapsible toggle button icon size
     */
    iconSize?: IconSizes;

    /**
     * The collapsible toggle button icon color
     */
    iconColor?: string;

    /**
     * The collapsible toggle button icon position
     */
    iconPosition?: CollapsibleIconPosition;

    /**
     * The click handler for the icon button
     */
    iconClickHandler?: () => void;

    /**
     * The header for the collapsible card
     */
    cardHeader?: JSX.Element;

    /**
     * Buttons to show between the header and the cancel button
     */
    headerButtons?: ReactNode;
}

interface CollapsiblePanelStyle {
    /**
     * The CSS max-height property of the collapsible panel
     */
    maxHeight?: CSS.Property.MaxHeight;
}

/**
 * The maximum height of the open collapsible panel, in pixels
 */
const MAX_PANEL_HEIGHT = 1200;

const Collapsible = ({
    className,
    direction = CollapsibleDirection.DOWN,
    textOpen,
    textClose,
    initOpen = false,
    overrideClickHandler,
    disabled = false,
    children,
    iconOpen,
    iconClose,
    iconSize,
    iconColor,
    iconPosition = CollapsibleIconPosition.RIGHT,
    iconClickHandler,
    cardHeader,
    headerButtons,
    ...extra
}: CollapsibleProps) => {
    const [isOpen, setIsOpen] = useState(initOpen);
    const panelRef = useRef<HTMLDivElement>(null);

    const dataTestId = extra['data-testid'] || 'collapsible';
    const rootClassName = 'collapsible';
    const conditionalClassNames = {
        'collapsible--open': isOpen,
        'collapsible--disabled': disabled
    };
    const blockClassName = classNames(
        rootClassName,
        className,
        conditionalClassNames
    );
    const toggleButtonElementClassName = classNames(
        'collapsible-toggle_button _d-flex',
        {
            '_fd-row-reverse': iconPosition === CollapsibleIconPosition.LEFT,
            '_fd-row': iconPosition === CollapsibleIconPosition.RIGHT
        },
        '_jc-space-between _ai-center'
    );

    const isCollapsingUp = direction === CollapsibleDirection.UP;
    const isCollapsingDown = direction === CollapsibleDirection.DOWN;
    const { scrollHeight } = panelRef?.current || {};
    const panelMaxHeight = scrollHeight || MAX_PANEL_HEIGHT;
    const maxHeight = `${unitsUtils.convertPixelstoRem(panelMaxHeight)}rem`;

    const icon = (isOpen && iconOpen) || (!isOpen && iconClose);
    const text = (isOpen && textOpen) || (!isOpen && textClose);
    const panelStyle: CollapsiblePanelStyle = isOpen ? { maxHeight } : {};

    const showButton = Boolean(iconClickHandler);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setIsOpen(initOpen);
    }, [initOpen]);

    const handleClick = () => {
        const clickHandler = overrideClickHandler || toggleCollapse;
        clickHandler();
    };

    return (
        <div
            className={blockClassName}
            data-direction={direction}
            aria-expanded={isOpen}
            data-testid={dataTestId}
        >
            {children && isCollapsingUp && (
                <div
                    className="collapsible-panel"
                    style={panelStyle}
                    ref={panelRef}
                    data-testid={`${dataTestId}-panel--up`}
                >
                    {children}
                </div>
            )}
            <div className="collapsible-toggle _d-flex">
                <button
                    data-testid={`${dataTestId}-button`}
                    className={toggleButtonElementClassName}
                    type="button"
                    disabled={disabled}
                    onClick={handleClick}
                >
                    {cardHeader}
                    {text && (
                        <span
                            className="collapsible-toggle_text"
                            data-testid={`${dataTestId}-toggle_text`}
                        >
                            {text}
                        </span>
                    )}
                    {icon && (
                        <Icon
                            className="collapsible-toggle_icon"
                            icon={icon}
                            size={iconSize}
                            color={iconColor}
                        />
                    )}
                </button>
                {headerButtons}
                {showButton && (
                    <IconButton
                        className="collapsible-toggle__cancel-button"
                        icon="cancel"
                        iconColor="galaxy-500"
                        onClick={iconClickHandler}
                        data-testid="cancel-button"
                    />
                )}
            </div>
            {children && isCollapsingDown && (
                <div
                    className="collapsible-panel"
                    style={panelStyle}
                    ref={panelRef}
                    data-testid={`${dataTestId}-panel--down`}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

Collapsible.direction = CollapsibleDirection;
Collapsible.iconPosition = CollapsibleIconPosition;

export default Collapsible;
