import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';

import { ApiAssignment } from '~/api/types';

import { Button, DatePickerHeader, TimeInput } from '~/ui';

import { useRevisedTimeModalContent } from './useRevisedTimeModalContent';

import './RevisedTimeModalContent.scss';

const ROOT_CLASS_NAME = 'revised-time-modal-content';

interface RevisedTimeModalProps {
    /**
     * The task assignment object
     */
    assignment: ApiAssignment;
    /**
     * Function to hide the modal
     */
    hideModal: () => void;
}

export const RevisedTimeModalContent = ({
    assignment,
    hideModal
}: RevisedTimeModalProps) => {
    const { t } = useTranslation('taskManagement');

    const {
        revisedDate,
        revisedTime,
        handleDateChange,
        handleTimeChange,
        isDisabled,
        onSave
    } = useRevisedTimeModalContent({ assignment, hideModal });

    const { arrivalTime, completedTime } = revisedTime;
    const { arrivalDate, completedDate } = revisedDate;

    return (
        <div className={ROOT_CLASS_NAME}>
            <div className={`${ROOT_CLASS_NAME}__arrival-time-main _mb-6`}>
                <span>{t('reviseTime.arrivalTime')}</span>
                <span className={`${ROOT_CLASS_NAME}__arrival-time _d-flex`}>
                    <ReactDatePicker
                        id="arrival-datepicker"
                        title="arrival-datepicker"
                        selected={new Date(arrivalDate)}
                        onChange={(value) =>
                            handleDateChange('arrivalDate', value)
                        }
                        showPopperArrow={false}
                        renderCustomHeader={DatePickerHeader}
                        wrapperClassName="_w-100"
                    />
                    <TimeInput
                        data-testid="arrival-time-input"
                        variant="form"
                        value={arrivalTime}
                        onChange={(value) =>
                            handleTimeChange('arrivalTime', value)
                        }
                    />
                </span>
            </div>
            <div className={`${ROOT_CLASS_NAME}__completed-time-main _mb-6`}>
                <span>{t('reviseTime.completedTime')}</span>
                <span className={`${ROOT_CLASS_NAME}__completed-time _d-flex`}>
                    <ReactDatePicker
                        id="completed-datepicker"
                        title="completed-datepicker"
                        selected={new Date(completedDate)}
                        onChange={(value) =>
                            handleDateChange('completedDate', value)
                        }
                        showPopperArrow={false}
                        renderCustomHeader={DatePickerHeader}
                        wrapperClassName="_w-100"
                    />
                    <TimeInput
                        data-testid="completed-time-input"
                        variant="form"
                        value={completedTime}
                        onChange={(value) =>
                            handleTimeChange('completedTime', value)
                        }
                    />
                </span>
            </div>
            <div className={`${ROOT_CLASS_NAME}__footer _d-flex _jc-flex-end`}>
                <Button
                    onClick={onSave}
                    disabled={isDisabled}
                    data-testid="save-button"
                >
                    {t('reviseTime.saveNewTime')}
                </Button>
            </div>
        </div>
    );
};
