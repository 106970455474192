import {
    TableOptions,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { Equipment } from '~/data-classes';
import { useEquipmentTableData } from '../useEquipmentTableData';
import { useEquipmentTableStates } from '../useEquipmentTableStates';
import { columnDefMap } from './columnDef';

export const useEquipmentTableConfig = () => {
    /**
     * Retrieves table source data
     */
    const { unselectedEquipmentData, selectedEquipmentData, isFiltered } =
        useEquipmentTableData();

    /**
     * Retrieves context values for table state
     */
    const { columnOrder, columnVisibility } = useEquipmentTableStates();

    /**
     * Define source data for unselected equipment table rows
     */
    const rowData = unselectedEquipmentData;

    /**
     * Define source data for sticky table rows
     */
    const selectedRowData = selectedEquipmentData;

    /**
     * Define column headers
     */
    const columnHeaders = Object.values(columnDefMap);

    /**
     * Define table options for the unselected equipment table
     */
    const tableOptions: TableOptions<Equipment> = {
        data: rowData,
        columns: columnHeaders,
        state: {
            columnOrder,
            columnVisibility
        },
        getCoreRowModel: getCoreRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        manualPagination: true
    };

    /**
     * Initializes the react-table model for the unselected equipment table
     */
    const { getHeaderGroups, getRowModel } = useReactTable(tableOptions);

    /**
     * Define table options for the sticky table
     */
    const selectedTableOptions: TableOptions<Equipment> = {
        data: selectedRowData,
        columns: columnHeaders,
        state: {
            columnOrder,
            columnVisibility
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel()
    };

    /**
     * Initializes the react-table model for the sticky table
     */
    const { getRowModel: getSelectedRowModel } =
        useReactTable(selectedTableOptions);

    /**
     * The table data to build the unselected equipment table with
     */
    const allRows = getRowModel().rows;

    /**
     * The table data for selected rows to build the sticky table with
     */
    const allSelectedRows = getSelectedRowModel().rows;

    /**
     * The table header groups data to build the table headers with
     */
    const headerGroups = getHeaderGroups();

    return {
        allRows,
        allSelectedRows,
        headerGroups,
        isFiltered
    };
};
