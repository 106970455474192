import React from 'react';

export enum StopDragAndDropTypes {
    STOP_REASSIGN = 'stop_reassign',
    STOP_RESEQUENCE = 'stop_resequence'
}

export interface CustomData {
    type?: StopDragAndDropTypes;
    renderOverlay?: () => React.ReactNode;
}
