import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import throttle from 'lodash/throttle';

import CustomerMapMarker from '~/components/CustomerManagementPage/CustomerMap/CustomerMapMarker';
import CustomerMapOverlay from '~/components/CustomerManagementPage/CustomerMap/CustomerMapOverlay';
import { Map } from '~/ui';
import ParkingPin from '~/ui/components/ParkingPin/ParkingPin';

import { useMapContext } from '~/components/ZoneManagementPage/MapContext';
import { useDrawingMapOptions } from '~/hooks/useDrawingMapOptions';

import { updateDraggedMarkerLocation } from '~/reducers/draggedMarkerLocation';

import constants from '~/utils/constants';

import './CustomerMap.scss';

const POLYGON_STROKE_WEIGHT = 3;

function CustomerMap({
    customerLocation,
    onUpdateCustomerLocation,
    parkingLocations = [],
    isGeocodeEditable = false,
    isGeofenceEditable = false,
    geofenceCircleRadius,
    geofencePolygon,
    isDisableCircularGeofences = false
}) {
    const dispatch = useDispatch();
    const draggedMarkerRef = useRef(null);
    const [markerLocation, setMarkerLocation] = useState(customerLocation);
    const [mapGestureHandling, setMapGestureHandling] = useState(
        constants.mapOptionSettings.GESTURE_HANDLING_AUTO
    );

    const { isDrawingMode, drawingManagerInstance, mapInstance, mapsApi } =
        useMapContext();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const _dragMarkerOnMap = useCallback(
        throttle((hoverKey, childProps, mouse) => {
            if (!draggedMarkerRef.current) return;
            const { lat, lng } = mouse;
            setMarkerLocation({ lat, lng });
            onUpdateCustomerLocation?.(lat, lng);
            dispatch(updateDraggedMarkerLocation({ lat, lng }));
        }, constants.timings.MARKER_MOVE_THROTTLE),
        []
    );

    function _handleMapChildMouseLeave() {
        setMapGestureHandling(
            constants.mapOptionSettings.GESTURE_HANDLING_AUTO
        );
    }

    function _handleMarkerDragStart(event) {
        setMapGestureHandling(constants.mapOptionSettings.GESTURE_HANDLING_OFF);
        draggedMarkerRef.current = event;
    }

    function _handleMarkerMouseEnter() {
        setMapGestureHandling(constants.mapOptionSettings.GESTURE_HANDLING_OFF);
    }

    function _handleMarkerMouseUp() {
        draggedMarkerRef.current = null;
    }

    function emittedEventHandler({ event }) {
        const { DRAG_START, MOUSE_ENTER, MOUSE_UP } =
            constants.customerMap.childEvents;
        const eventHandlers = {
            [DRAG_START]: () => _handleMarkerDragStart(event),
            [MOUSE_ENTER]: _handleMarkerMouseEnter,
            [MOUSE_UP]: _handleMarkerMouseUp
        };
        const handler = eventHandlers[event];
        if (handler) handler();
    }

    const mapOptions = useDrawingMapOptions({
        mapCenter: customerLocation,
        defaultZoom: constants.customerMap.DEFAULT_ZOOM,
        polygonStrokeWeight: POLYGON_STROKE_WEIGHT
    });

    if (isGeocodeEditable) {
        mapOptions.onChildMouseMove = _dragMarkerOnMap;
        mapOptions.options.gestureHandling = mapGestureHandling;
        mapOptions.onChildMouseLeave = _handleMapChildMouseLeave;
    }

    useEffect(() => {
        const newDrawingMode = isDrawingMode
            ? constants.mapDrawingModes.POLYGON
            : null;
        const currentDrawingMode = drawingManagerInstance?.getDrawingMode();

        if (newDrawingMode !== currentDrawingMode) {
            drawingManagerInstance?.setDrawingMode(newDrawingMode);
        }
    }, [isDrawingMode, drawingManagerInstance]);

    /**
     * Display parking map markers
     * @param {Object[]} locations
     */
    function displayParkingMarkers(locations) {
        const bounds = new mapsApi.LatLngBounds();
        locations.forEach((parkingLocation) => {
            bounds.extend({
                lat: parkingLocation.lat,
                lng: parkingLocation.lng
            });
        });
        mapInstance.fitBounds(bounds);
    }

    const handleSetMapCenter = (map, location) => {
        map.setCenter(location);
    };

    useEffect(() => {
        if (!mapInstance || !mapsApi || !customerLocation) return;

        handleSetMapCenter(mapInstance, customerLocation);

        if (parkingLocations?.length) {
            displayParkingMarkers(parkingLocations);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [mapInstance, mapsApi, customerLocation, parkingLocations]);

    return (
        <Map
            dataTestId="customer-map"
            className="customermap"
            googleMapOptions={mapOptions}
            overlay={
                <CustomerMapOverlay
                    mapInstance={mapInstance}
                    customerLocation={customerLocation}
                    parkingLocations={parkingLocations}
                    isGeocodeEditable={isGeocodeEditable}
                    isGeofenceEditable={isGeofenceEditable}
                    geofenceCircleRadius={geofenceCircleRadius}
                    geofencePolygon={geofencePolygon}
                    isDisableCircularGeofences={isDisableCircularGeofences}
                />
            }
        >
            <CustomerMapMarker
                lat={
                    isGeocodeEditable
                        ? markerLocation.lat
                        : customerLocation.lat
                }
                lng={
                    isGeocodeEditable
                        ? markerLocation.lng
                        : customerLocation.lng
                }
                isDraggable={isGeocodeEditable}
                emittedEventHandler={emittedEventHandler}
            />
            {parkingLocations.map((parkingLocation) => (
                <ParkingPin
                    key={parkingLocation.lng}
                    lat={parkingLocation.lat}
                    lng={parkingLocation.lng}
                />
            ))}
        </Map>
    );
}

export default CustomerMap;
