import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/ui';
import { useLiveDispatchTableContext } from '~/components/MapPageDrawers/LiveDispatchDrawer/LiveDispatchTableContext';
import { ColumnItemProps } from '../types';

const VisibleColumnItem: React.FC<ColumnItemProps> = ({
    columnName,
    onToggleVisibility
}) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { tableMode } = useLiveDispatchTableContext();

    const handleClick = () => {
        onToggleVisibility(columnName);
    };

    return (
        <>
            <span
                data-testid={`live-dispatch-visible-column__item-label-${columnName}`}
                className="live-dispatch-visible-column__item-label"
            >
                {t(`columns.${tableMode}.${columnName}`)}
            </span>
            <Button
                className="live-dispatch-visible-column__hide-button"
                onClick={handleClick}
                type="link"
                sx={{ textDecoration: 'underline' }}
                data-testid="live-dispatch-visible-column__hide-button"
            >
                <span className="live-dispatch-hidden-column__hide-button-label">
                    {t('viewColumns.hideColumnItem')}
                </span>
            </Button>
        </>
    );
};

export default VisibleColumnItem;
