import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/ui';

import constants from '~/utils/constants';

import './TaskStatus.scss';

function TaskStatus({ status, className }) {
    const { t } = useTranslation(['taskManagement']);
    let statusColor = '';
    let statusName = '';
    let statusIcon = '';
    switch (status) {
        case constants.taskStatus.UNASSIGNED:
            statusColor = 'galaxy-500';
            statusName = t('table.taskStatus.unassigned');
            statusIcon = 'questionMark';
            break;
        case constants.taskStatus.PLANNED:
            statusColor = 'ocean';
            statusName = t('table.taskStatus.planned');
            statusIcon = 'route';
            break;
        case constants.taskStatus.DISPATCHED:
        case constants.taskStatus.IN_PROGRESS:
            statusColor = 'meteor';
            statusName = t('table.taskStatus.dispatched');
            statusIcon = 'vehicle';
            break;
        case constants.taskStatus.COMPLETED:
            statusColor = 'earth';
            statusName = t('table.taskStatus.completed');
            statusIcon = 'checkmark';
            break;
        case constants.taskStatus.CANCELLED:
            statusColor = 'saturn-500';
            statusName = t('table.taskStatus.cancelled');
            statusIcon = 'line';
            break;
        default:
            statusIcon = 'questionMark';
    }

    return (
        <div
            className={`_d-flex _ai-center ${className}`}
            data-testid="task-status"
        >
            <div
                className={`taskstatus-circle taskstatus-circle--${statusColor}`}
            >
                <Icon icon={statusIcon} color="comet" size="xs" />
            </div>
            <span
                className={`taskstatus-text taskstatus-text--${statusColor}`}
                data-testid="task-status-text"
            >
                {statusName}
            </span>
        </div>
    );
}

export default TaskStatus;
