import React from 'react';
import { useSelector } from 'react-redux';

import { RouteTripStats } from '~/components/MapPageDrawers/SelectedCardsDrawer/types';

import { selectAccountSetupByClientId } from '~/reducers/accountSetupByClientSlice';

import { RouteCardSummary } from '~/ui';

import constants from '~/utils/constants';
import { getSummaryMetrics } from '~/components/MapPageDrawers/PlanRoutesDrawer/RouteItem/utils';

import './TripListItem.scss';

type TripListItemProps = {
    clientId: string;
    name: string;
    stats: RouteTripStats;
};

const TripListItem = ({
    clientId,
    name,
    stats
}: TripListItemProps): JSX.Element => {
    const { isMultiCompartmentClient = false } =
        useSelector(selectAccountSetupByClientId(clientId)) || {};
    const summaryMetrics = getSummaryMetrics({
        stats,
        isMultiCompartmentClient
    }).filter((stat) => stat.metric !== constants.routeSummaryMetrics.DISTANCE);
    return (
        <span className="_fd-column trip-list-item">
            <span>{name}</span>
            <span className="_d-flex trip-list-item__details">
                <RouteCardSummary
                    className="routeitem__summary _d-grid trip-list-item__summary"
                    metrics={summaryMetrics}
                />
            </span>
        </span>
    );
};

export default TripListItem;
