import { markerMaker } from '~/utils/map';
import { MakeUnassignedStopMarkerEffects } from './types';

export const makeUnassignedStopMarkerEffects = ({
    isClusteringStops,
    unassignedSuperClusters,
    getClusters,
    makeUnassignedClusterMarkers,
    emittedEventHandler,
    planTasks
}: MakeUnassignedStopMarkerEffects) => {
    if (isClusteringStops) {
        return unassignedSuperClusters.map((unassignedSuperCluster, index) => {
            const geoJSONFeatures = getClusters(unassignedSuperCluster);
            return makeUnassignedClusterMarkers({
                superCluster: unassignedSuperCluster,
                geoJSONFeatures,
                superClusterIndex: index,
                emittedEventHandler
            });
        });
    }

    return markerMaker.makeUnassignedStopMarkers(
        planTasks,
        emittedEventHandler
    );
};
