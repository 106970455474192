import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, TabList, Tabs } from 'react-tabs';
import { isEmpty } from 'lodash';
import { Duration } from 'luxon';
import classNames from 'classnames';

import {
    Button,
    CompartmentEditModal,
    CompartmentTag,
    Detail,
    DetailDrawerItem,
    Drawer,
    DrawerBody,
    DrawerButtonGroup,
    DrawerHeader,
    EditableText,
    IconButton,
    useModal
} from '~/ui';

import TaskStatus from '~/components/TaskManagementPage/TaskStatus';
import DeleteTasksModalContent from '~/components/TaskManagementPage/DeleteTasksModalContent/DeleteTasksModalContent';
import EditTimeWindowModal from '~/components/TaskManagementPage/EditTimeWindowModal';
import EditTaskAddressModal from '~/components/TaskManagementPage/EditTaskAddressModal';
import { selectTaskById, addNewTask } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

import tasksApi from '~/api/TasksApi';

import constants from '~/utils/constants';
import taskUtils from '~/utils/task-utils';
import {
    formatCompartmentList,
    mapSizeByCompartment
} from '~/utils/compartment-utils';
import { FeatureFlag } from '~/utils/feature-flags-utils';

import {
    nonNegativeFloatValidator,
    floatValidator,
    useFeatureFlag,
    useHereMaps
} from '~/hooks';
import { useGetCompartments } from '~/hooks/useGetCompartments';

import InventoryItem from './InventoryItem/InventoryItem';
import ProofOfDelivery from './ProofOfDelivery';
import TaskHistory from './TaskHistory/TaskHistory';
import TaskMap from './TaskMap/TaskMap';
import TaskMapMarker from './TaskMap/TaskMapMarker/TaskMapMarker';
import TaskPriorityDropdown from './TaskPriorityDropDown';
import { InvoiceAndPayment } from './InvoiceAndPayment';
import { InvoiceAndPaymentEditModal } from './InvoiceAndPaymentEditModal';
import { useGetTaskDetails } from './useGetTaskDetails';
import {
    getFilteredInvoiceItem,
    getUpdatedDeliveryContactEmail,
    getUpdatedDeliveryContactPhone,
    getUpdatedDeliveryLocation,
    getUpdatedDeliveryServiceTime,
    getUpdatedPickupContactEmail,
    getUpdatedPickupContactPhone,
    getUpdatedPickupLocation,
    getUpdatedPickupServiceTime,
    isTaskDelivery,
    isTaskPickup
} from './utils';
import { EquipmentDetails } from './EquipmentDetails';
import { getFilteredTask, getIsTaskEditable } from './utils/utils';
import { useVehicleTypeDetail } from './VehicleTypeDetail';
import { useUpdateTask } from './useUpdateTask';
import { useIsWithEditPermissions } from './useIsWithEditPermissions';
import { ExternalTaskTypeMainContent } from './ExternalTaskTypeMainContent';
import { useTaskLabelsDetail } from './TaskLabelsDetail';

import './TaskDetailDrawer.scss';

const MAX_CUSTOMER_NOTES_DISPLAY = 4;

function TaskDetailDrawer({
    id,
    handleTaskDetailDrawerClose,
    shouldRenderMapPageStyle
}) {
    const { t } = useTranslation(['taskManagement', 'common', 'error']);
    const { isHereMapsEnabled } = useHereMaps();
    const editTimeWindowModalRef = useRef();
    const editAddressModalRef = useRef();
    const [tabIndex, setTabIndex] = useState(0);
    const [isEditTimeWindowOpen, setEditTimeWindowOpen] = useState(false);
    const [isEditAddressModalOpen, setEditAddressModalOpen] = useState(false);
    const [isEditingCompartments, setIsEditingCompartments] = useState(false);
    const [isEditInvoicesAndPayments, setIsEditInvoicesAndPayments] =
        useState(false);
    const [selectedInvoiceItem, setSelectedInvoiceItem] = useState({});
    const [task, setTask] = useState(null);
    const [customerNotesDisplay, setCustomerNotesDisplay] = useState(false);
    const [assignedEquipmentId, setAssignedEquipmentId] = useState('');

    const dispatch = useDispatch();

    const selectedTask = useSelector(selectTaskById(id));

    const mainClient = useSelector(selectMainClient);
    const { preferences: { allowEditingAssignedTasks = false } = {} } =
        mainClient || {};

    const { isMultiCompartment, compartments } = useGetCompartments();

    const { taskCustomerNotes, taskInventoryItems } = useGetTaskDetails({
        taskId: id,
        selectedTask
    });

    const [isEditingExternalTaskType, setIsEditingExternalTaskType] =
        useState(false);

    const isFlaggedFeatureDisplayed = useFeatureFlag(
        FeatureFlag.LIVE_DISPATCH_UI
    );

    useEffect(() => {
        const getTaskDetails = async () => {
            if (!selectedTask && id) {
                try {
                    // address cases where the selected task is not yet cached on redux
                    // + newly added tasks
                    // + selected tasks have route dates in past/future
                    const taskResponse = await tasksApi.getTask(id);
                    const taskStatus = taskUtils.getTaskStatus(
                        taskResponse.data.data
                    );
                    const newSelectedTask = {
                        ...taskResponse.data.data,
                        taskStatus
                    };
                    dispatch(addNewTask(newSelectedTask));
                    setTask(newSelectedTask);
                } catch (e) {
                    console.error(e);
                    dispatch(
                        addToast({
                            message: t('error:taskDetailsError'),
                            variant: 'error'
                        })
                    );
                }
                return;
            }
            setTask(selectedTask);
        };
        getTaskDetails();

        return () => setTask(null);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [id, selectedTask]);

    const { deliveryCustomer, pickupCustomer } = taskCustomerNotes || {};
    const pickupCustomerNotes = pickupCustomer?.notes;
    const deliveryCustomerNotes = deliveryCustomer?.notes;

    const customerNotes = useMemo(() => {
        // identify current tab
        const isPickupTab = tabIndex === 0;
        const isDeliveryTab = tabIndex === 1;

        // identify notes for two-part task
        const isTwoPartNote =
            Boolean(pickupCustomerNotes) && Boolean(deliveryCustomerNotes);
        const pickupTabNote =
            isTwoPartNote && isPickupTab && pickupCustomerNotes;
        const deliveryTabNote =
            isTwoPartNote && isDeliveryTab && deliveryCustomerNotes;

        // identify notes for single task
        const singleTaskNote =
            pickupCustomerNotes || deliveryCustomerNotes || [];

        return pickupTabNote || deliveryTabNote || singleTaskNote;
    }, [pickupCustomerNotes, deliveryCustomerNotes, tabIndex]);

    const {
        showModal: showDeleteTasksModal,
        hideModal: hideDeleteTasksModal,
        modal: DeleteTasksModal
    } = useModal();

    const isTwoPartTask = task && taskUtils.checkIsTwoPartTask(task);
    const isDelivery = task && taskUtils.checkIsDeliveryTask(task);
    const isPickup = task && taskUtils.checkIsPickupTask(task);

    const filteredTask = useMemo(() => {
        return getFilteredTask({
            isTwoPartTask,
            isDelivery,
            isPickup,
            task,
            tabIndex,
            taskInventoryItems
        });
    }, [
        isTwoPartTask,
        isDelivery,
        isPickup,
        task,
        tabIndex,
        taskInventoryItems
    ]);

    const { equipmentId } = filteredTask || {};

    useEffect(() => {
        if (equipmentId !== assignedEquipmentId) {
            setAssignedEquipmentId(equipmentId);
        }
    }, [equipmentId, assignedEquipmentId]);

    const { taskStatus } = task ?? {};

    const { isWithEditPermissions: hasEditPermissions } =
        useIsWithEditPermissions({ allowEditingAssignedTasks, taskStatus });
    const isTaskUnassigned =
        task?.taskStatus === constants.taskStatus.UNASSIGNED;
    const isShowDeleteButton = hasEditPermissions && isTaskUnassigned;

    const compartmentsData = mapSizeByCompartment(
        filteredTask?.sizeByCompartment ?? [],
        compartments
    );

    function getTaskMapMarkers() {
        const taskMapMarkers = [];
        const isSelected = tabIndex === 0;
        if (isTwoPartTask) {
            taskMapMarkers.push(
                <TaskMapMarker
                    key={task.pickupLocation.location.lat}
                    lat={task.pickupLocation.location.lat}
                    lng={task.pickupLocation.location.lng}
                    isSelected={isSelected}
                />
            );
            taskMapMarkers.push(
                <TaskMapMarker
                    key={task.deliveryLocation.location.lat}
                    lat={task.deliveryLocation.location.lat}
                    lng={task.deliveryLocation.location.lng}
                    isSelected={!isSelected}
                />
            );
        } else {
            taskMapMarkers.push(
                <TaskMapMarker
                    key={filteredTask.locationLat}
                    lat={filteredTask.locationLat}
                    lng={filteredTask.locationLng}
                />
            );
        }
        return taskMapMarkers;
    }

    const isTaskEditable = getIsTaskEditable({
        taskStatus,
        isWithEditPermissions: hasEditPermissions,
        allowEditingAssignedTasks
    });
    const isTaskReadOnly = !isTaskEditable;

    const { updateTask: apiRequestToUpdate } = useUpdateTask({
        isTaskReadOnly
    });

    function updateCustomerName({ value }) {
        let updatedLocation;

        if (isTaskDelivery({ isTwoPartTask, isDelivery, tabIndex })) {
            updatedLocation = getUpdatedDeliveryLocation({ task, id, value });
        }

        if (isTaskPickup({ isTwoPartTask, isPickup, tabIndex })) {
            updatedLocation = getUpdatedPickupLocation({ task, id, value });
        }

        apiRequestToUpdate(updatedLocation);
    }

    function updateContactPhone({ value }) {
        let updatedContact;

        if (isTaskDelivery({ isTwoPartTask, isDelivery, tabIndex })) {
            updatedContact = getUpdatedDeliveryContactPhone({
                task,
                id,
                value
            });
        }

        if (isTaskPickup({ isTwoPartTask, isPickup, tabIndex })) {
            updatedContact = getUpdatedPickupContactPhone({ task, id, value });
        }
        apiRequestToUpdate(updatedContact);
    }

    function updateContactEmail({ value }) {
        let updatedContact;

        if (isTaskDelivery({ isTwoPartTask, isDelivery, tabIndex })) {
            updatedContact = getUpdatedDeliveryContactEmail({
                task,
                id,
                value
            });
        }

        if (isTaskPickup({ isTwoPartTask, isPickup, tabIndex })) {
            updatedContact = getUpdatedPickupContactEmail({ task, id, value });
        }
        apiRequestToUpdate(updatedContact);
    }

    function updateNotes({ index, value }) {
        const { props } = task;
        const newNote = { text: value };
        const notes = props.notes ? [...props.notes] : [];
        notes.splice(index, 1, newNote);
        const updatedNotes = {
            taskId: id,
            property: 'props',
            value: { notes }
        };
        apiRequestToUpdate(updatedNotes);
    }

    function updateServiceTime({ value }) {
        if (isEmpty(value) || !floatValidator(value)) return;

        const serviceTimeDurationISO = Duration.fromObject({
            minutes: value
        })
            .shiftTo('hours', 'minutes', 'seconds')
            .toISO();

        let updatedServiceTime;

        if (isTaskDelivery({ isTwoPartTask, isDelivery, tabIndex })) {
            updatedServiceTime = getUpdatedDeliveryServiceTime({
                id,
                serviceTimeDurationISO
            });
        }

        if (isTaskPickup({ isTwoPartTask, isPickup, tabIndex })) {
            updatedServiceTime = getUpdatedPickupServiceTime({
                id,
                serviceTimeDurationISO
            });
        }

        apiRequestToUpdate(updatedServiceTime);
    }

    function updateWeightOrVolume({ index: property, value }) {
        if (!nonNegativeFloatValidator(value)) return;

        apiRequestToUpdate({
            taskId: id,
            property: 'props',
            value: { [property]: Number(value) }
        });
    }

    function updateTaskPriority(value) {
        apiRequestToUpdate({
            taskId: id,
            property: 'props',
            value: { priority: value }
        });
    }

    function updateRouteId({ value }) {
        apiRequestToUpdate({
            taskId: id,
            property: 'euid',
            value
        });
    }

    function handleSaveCompartments(editedCompartments) {
        const formattedCompartments = formatCompartmentList(
            editedCompartments,
            'size'
        );
        apiRequestToUpdate({
            taskId: id,
            property: 'sizeByCompartment',
            value: formattedCompartments
        });
        setIsEditingCompartments(false);
    }

    function _closeDrawer() {
        handleTaskDetailDrawerClose();
    }

    function displayModalEditDetail(content, onClick) {
        let className = '';
        let isButtonDisabled = false;
        if (isTaskReadOnly) {
            className = 'detail-drawer-item__button--disabled';
            isButtonDisabled = true;
        }

        return (
            <button
                type="button"
                className={`detail-drawer-item__button ${className}`}
                onClick={isButtonDisabled ? null : onClick}
            >
                {content}
            </button>
        );
    }

    function displayTimeWindows(timeWindows) {
        const timeWindowsContent = timeWindows.map((tw, i) => {
            const key = `tw_${id}_${i}`;
            return <div key={key}>{tw}</div>;
        });

        return displayModalEditDetail(timeWindowsContent, () =>
            hasEditPermissions ? setEditTimeWindowOpen(true) : null
        );
    }

    function displayExternalLinks(links) {
        if (links.length === 0) return t('common:N/A');
        return links.map((item) => (
            <a
                key={item}
                href={item}
                className="task-detail__external-links"
                target="_blank"
                rel="noreferrer"
            >
                {item}
            </a>
        ));
    }

    function displayNotes(notes) {
        return notes.map((note, i) => {
            const key = `note_${id}_${i}`;
            return (
                <div key={key} id={key}>
                    <EditableText
                        mainContent={note.text}
                        handleSubmit={updateNotes}
                        index={i}
                        isEditable={hasEditPermissions}
                        data-testid="notes-editable-text"
                    />
                </div>
            );
        });
    }

    function displayCustomerNotes() {
        if (!customerNotes.length) return <span>{t('common:N/A')}</span>;

        return (
            <>
                {customerNotes.map((data, index) => (
                    <div
                        key={data.id}
                        className={classNames(
                            'task-detail__customer-note-entry',
                            {
                                '_d-none':
                                    index > MAX_CUSTOMER_NOTES_DISPLAY - 1 &&
                                    !customerNotesDisplay
                            }
                        )}
                    >
                        {data.text}
                    </div>
                ))}

                {customerNotes.length > MAX_CUSTOMER_NOTES_DISPLAY && (
                    <span
                        onClick={() =>
                            setCustomerNotesDisplay(!customerNotesDisplay)
                        }
                        onKeyDown={() =>
                            setCustomerNotesDisplay(!customerNotesDisplay)
                        }
                        className="task-detail__customer-notes-display"
                        role="button"
                        tabIndex={0}
                    >
                        {!customerNotesDisplay
                            ? t('drawer.showMore')
                            : t('drawer.showLess')}
                    </span>
                )}
            </>
        );
    }

    function displayInventoryItems() {
        if (!taskInventoryItems.length) return null;

        const isCompleted =
            filteredTask.status === constants.taskStatus.COMPLETED;

        return filteredTask.inventoryItems.map((item) => {
            const key = `item_${item.id}`;
            return (
                <InventoryItem
                    key={key}
                    item={item}
                    isCompleted={isCompleted}
                />
            );
        });
    }

    function _getClassName() {
        const defaultClassName = 'task-detail-drawer';
        const conditionalClasses = {
            'task-detail-drawer-mappage': shouldRenderMapPageStyle
        };
        return classNames(defaultClassName, conditionalClasses);
    }

    function toggleCompartmentEditModal() {
        setIsEditingCompartments((isEditing) => !isEditing);
    }
    function getCompartmentDetails() {
        return (
            <button
                className="task-detail__compartment-button _d-flex _jc-space-between"
                type="button"
                onClick={toggleCompartmentEditModal}
                disabled={isTaskReadOnly}
                data-testid="multicompartment-button-container"
            >
                {compartmentsData.map((compartment) => (
                    <CompartmentTag key={compartment.id} {...compartment} />
                ))}
            </button>
        );
    }

    const toggleInvoiceAndPaymentEditModal = () => {
        setIsEditInvoicesAndPayments((isEditing) => !isEditing);
    };

    const handleSelectedInvoice = (item) => {
        setSelectedInvoiceItem(item);
        toggleInvoiceAndPaymentEditModal();
    };

    const handleDeleteInvoicesAndPayments = () => {
        const { props } = task;

        const getUpdatedPickupInvoice = () => {
            const { pickupInvoices } = props;

            const result = getFilteredInvoiceItem(
                pickupInvoices,
                selectedInvoiceItem
            );

            return {
                taskId: id,
                property: 'props',
                value: { pickupInvoices: result }
            };
        };

        const getUpdatedDeliveryInvoice = () => {
            const { deliveryInvoices } = props;

            const result = getFilteredInvoiceItem(
                deliveryInvoices,
                selectedInvoiceItem
            );

            return {
                taskId: id,
                property: 'props',
                value: { deliveryInvoices: result }
            };
        };

        let updatedInvoices;

        if (isTaskDelivery({ isTwoPartTask, isDelivery, tabIndex })) {
            updatedInvoices = getUpdatedDeliveryInvoice();
        }

        if (isTaskPickup({ isTwoPartTask, isPickup, tabIndex })) {
            updatedInvoices = getUpdatedPickupInvoice();
        }

        apiRequestToUpdate(updatedInvoices);

        toggleInvoiceAndPaymentEditModal();
    };

    const handleSaveInvoicesAndPayments = (editedInvoiceItem) => {
        const { props } = task;

        const getUpdatedPickupInvoice = () => {
            const { pickupInvoices } = props;

            const { invoiceId } = editedInvoiceItem;

            const result = pickupInvoices.map((item) =>
                item.invoiceId === invoiceId ? editedInvoiceItem : item
            );

            return {
                taskId: id,
                property: 'props',
                value: { pickupInvoices: result }
            };
        };

        const getUpdatedDeliveryInvoice = () => {
            const { deliveryInvoices } = props;

            const { invoiceId } = editedInvoiceItem;

            const result = deliveryInvoices.map((item) =>
                item.invoiceId === invoiceId ? editedInvoiceItem : item
            );

            return {
                taskId: id,
                property: 'props',
                value: { deliveryInvoices: result }
            };
        };

        let updatedInvoices;

        if (isTaskDelivery({ isTwoPartTask, isDelivery, tabIndex })) {
            updatedInvoices = getUpdatedDeliveryInvoice();
        }

        if (isTaskPickup({ isTwoPartTask, isPickup, tabIndex })) {
            updatedInvoices = getUpdatedPickupInvoice();
        }

        apiRequestToUpdate(updatedInvoices);

        toggleInvoiceAndPaymentEditModal();
    };

    const getInvoicesAndPayments = () => {
        const { invoicesAndPayments } = filteredTask;

        if (!invoicesAndPayments.length) return null;

        return invoicesAndPayments.map((item) => {
            const key = `item_${item.invoiceId}`;
            return (
                <InvoiceAndPayment
                    key={key}
                    item={item}
                    handleSelectedInvoice={handleSelectedInvoice}
                />
            );
        });
    };

    const { vehicleTypeDetail } = useVehicleTypeDetail({
        task,
        isEditable: hasEditPermissions,
        onTaskUpdate: apiRequestToUpdate
    });

    const isPredefinedExternalTaskTypeEnabled = useFeatureFlag(
        FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES
    );

    const { taskLabelsDetail } = useTaskLabelsDetail({
        taskId: id,
        isEditable: isTaskEditable,
        labels: filteredTask?.labels,
        onTaskUpdate: apiRequestToUpdate
    });

    const getExternalTaskTypeClassName = () => {
        const conditionalClassNames = {
            'task-detail-drawer__external-task-type':
                isEditingExternalTaskType && isPredefinedExternalTaskTypeEnabled
        };
        return classNames('_d-flex _ai-flex-start', conditionalClassNames);
    };

    if (!id || !task) return null;

    return (
        <Drawer className={_getClassName()}>
            <DrawerHeader className="task-detail-drawer__header">
                <DrawerButtonGroup className="_jc-space-between task-detail-drawer__header--sticky">
                    <div className="task-detail-drawer-status">
                        <TaskStatus status={filteredTask.status} />
                    </div>
                    <IconButton
                        className="drawer-button"
                        icon="cancel"
                        iconColor="galaxy-800"
                        onClick={_closeDrawer}
                    />
                </DrawerButtonGroup>
            </DrawerHeader>
            <DrawerBody>
                <div className="detail-drawer-item detail-drawer-item--open">
                    <div className="heading-1 _mb-2">
                        {filteredTask.taskName}
                    </div>
                    <div className="sub-heading-1 _to-ellipsis _mb-3">
                        {filteredTask.taskAddress}
                    </div>
                    {!isHereMapsEnabled && (
                        <TaskMap taskMapMarkers={getTaskMapMarkers()} />
                    )}
                    <TaskHistory task={task} tabIndex={tabIndex} />
                </div>
                {isTwoPartTask && (
                    <Tabs
                        className="task-detail-drawer__tabs"
                        selectedIndex={tabIndex}
                        onSelect={(index) => setTabIndex(index)}
                    >
                        <TabList>
                            <Tab>{t('taskManagement:drawer.pickup')}</Tab>
                            <Tab>{t('taskManagement:drawer.delivery')}</Tab>
                        </TabList>
                    </Tabs>
                )}
                <DetailDrawerItem
                    title={t('drawer.customerDetails')}
                    isDividerDisabled
                >
                    <Detail
                        iconName="userLine"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.locationName'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <EditableText
                                mainContent={filteredTask.customerName}
                                handleSubmit={updateCustomerName}
                                isEditable={hasEditPermissions}
                                data-testid="customer-name-editable-text"
                            />
                        }
                    />
                    <Detail
                        iconName="call"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.phoneNumber'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <EditableText
                                mainContent={filteredTask.contactPhone}
                                handleSubmit={updateContactPhone}
                                isEditable={hasEditPermissions}
                                data-testid="contact-phone-editable-text"
                            />
                        }
                    />
                    <Detail
                        iconName="message"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.email'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <EditableText
                                mainContent={filteredTask.contactEmail}
                                handleSubmit={updateContactEmail}
                                isEditable={hasEditPermissions}
                                data-testid="contact-email-editable-text"
                            />
                        }
                    />
                    <Detail
                        iconName="pin"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.address'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={displayModalEditDetail(
                            filteredTask.customerAddress,
                            () => setEditAddressModalOpen(true)
                        )}
                    />
                    <Detail
                        iconName="note"
                        iconSize="s"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.note'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <div className="task-detail__customer-notes _fd-column">
                                {displayCustomerNotes()}
                            </div>
                        }
                    />
                </DetailDrawerItem>
                <DetailDrawerItem title={t('drawer.taskDetail')}>
                    <Detail
                        iconName="link"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.links'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <div
                                className="task-detail__external-links-drawer"
                                data-testid="external-links"
                            >
                                {displayExternalLinks(
                                    filteredTask.externalLinks
                                )}
                            </div>
                        }
                    />
                    <Detail
                        iconName="iconRouteFill"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.routeId'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <EditableText
                                mainContent={filteredTask.euId}
                                handleSubmit={updateRouteId}
                                isEditable={hasEditPermissions}
                                data-testid="route-editable-text"
                            />
                        }
                    />
                    <Detail
                        iconName="star"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.priority'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <TaskPriorityDropdown
                                index="priority"
                                selectedId={filteredTask.priority}
                                handleSubmit={updateTaskPriority}
                                isEditable={hasEditPermissions}
                                data-testid="task-priority-editable-text"
                            />
                        }
                    />
                    <Detail
                        iconName="clock"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.timeWindow'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={displayTimeWindows(
                            filteredTask.timeWindows
                        )}
                    />
                    <Detail
                        iconName="hourglass"
                        iconSize="s"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.serviceTime'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <EditableText
                                index="serviceTime"
                                mainContent={filteredTask.serviceTime}
                                handleSubmit={updateServiceTime}
                                isEditable={hasEditPermissions}
                                data-testid="service-time-editable-text"
                                formatter={(value) =>
                                    `${value} ${t('common:time.minutes')}`
                                }
                            />
                        }
                    />
                    {!isMultiCompartment ? (
                        <Detail
                            iconName="volume"
                            iconSize="s"
                            iconColor="galaxy-500"
                            iconClassName="detail-drawer-item__icon"
                            iconTooltipContent={t(
                                'taskManagement:iconTooltipContent.volume'
                            )}
                            showTooltipOnIcon
                            className="_d-flex _ai-flex-start"
                            mainContent={
                                <EditableText
                                    index="size"
                                    mainContent={filteredTask.size}
                                    handleSubmit={updateWeightOrVolume}
                                    isEditable={hasEditPermissions}
                                    data-testid="volume-editable-text"
                                />
                            }
                        />
                    ) : (
                        <Detail
                            iconName="volume"
                            iconSize="s"
                            iconColor="galaxy-500"
                            iconClassName="detail-drawer-item__icon"
                            iconTooltipContent={t(
                                'taskManagement:iconTooltipContent.volume'
                            )}
                            showTooltipOnIcon
                            className="_d-flex _ai-flex-start"
                            mainContent={getCompartmentDetails()}
                        />
                    )}
                    <Detail
                        iconName="weightEmpty"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.weight'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <EditableText
                                index="weight"
                                mainContent={filteredTask.weight}
                                handleSubmit={updateWeightOrVolume}
                                isEditable={hasEditPermissions}
                                data-testid="weight-editable-text"
                            />
                        }
                    />
                    {vehicleTypeDetail}
                    {taskLabelsDetail}
                    <Detail
                        iconName="note"
                        iconSize="s"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.note'
                        )}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={displayNotes(filteredTask.notes)}
                    />
                    <Detail
                        iconName="iconTask"
                        iconSize="s"
                        iconColor="galaxy-600"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={t(
                            'taskManagement:iconTooltipContent.externalTaskType'
                        )}
                        showTooltipOnIcon
                        className={getExternalTaskTypeClassName()}
                        mainContent={
                            <ExternalTaskTypeMainContent
                                clientTask={filteredTask}
                                id={id}
                                isEditing={isEditingExternalTaskType}
                                setIsEditing={setIsEditingExternalTaskType}
                                isDelivery={isDelivery}
                                isPickup={isPickup}
                                isTwoPartTask={isTwoPartTask}
                            />
                        }
                    />
                </DetailDrawerItem>
                {isFlaggedFeatureDisplayed && (
                    <DetailDrawerItem
                        title={t('drawer.equipmentDetails.title')}
                    >
                        <EquipmentDetails
                            equipmentId={assignedEquipmentId}
                            selectedTask={selectedTask || {}}
                            hasEditPermissions={hasEditPermissions}
                        />
                    </DetailDrawerItem>
                )}
                <DetailDrawerItem
                    title={t('drawer.inventoryItems', {
                        total: filteredTask.inventoryItems.length
                    })}
                >
                    {displayInventoryItems(taskInventoryItems)}
                </DetailDrawerItem>
                <DetailDrawerItem
                    title={t('drawer.invoicesAndPayments.title', {
                        total: filteredTask.invoicesAndPayments.length
                    })}
                >
                    {getInvoicesAndPayments()}
                </DetailDrawerItem>
                <DetailDrawerItem title={t('proof.title')}>
                    <ProofOfDelivery
                        taskId={id}
                        inventoryItems={filteredTask.inventoryItems}
                    />
                </DetailDrawerItem>
                {isShowDeleteButton && (
                    <div
                        className="detail-drawer-item detail-drawer-item--open"
                        data-testid="task-detail-drawer-delete"
                    >
                        <div className="detail-drawer-item__body">
                            <Button
                                variant="secondary-dismissive"
                                onClick={showDeleteTasksModal}
                                style={{ width: '100%' }}
                            >
                                {t('common:delete')}
                            </Button>
                        </div>
                    </div>
                )}
            </DrawerBody>
            <DeleteTasksModal variant="dialogue" hasCustomHeader>
                <DeleteTasksModalContent
                    hideModal={hideDeleteTasksModal}
                    selectedTaskIds={[id]}
                    hideOther={handleTaskDetailDrawerClose}
                />
            </DeleteTasksModal>
            {isEditTimeWindowOpen && (
                <EditTimeWindowModal
                    ref={editTimeWindowModalRef}
                    taskId={id}
                    isDelivery={isTwoPartTask ? Boolean(tabIndex) : isDelivery}
                    onHide={() => setEditTimeWindowOpen(false)}
                    onDateUpdate={handleTaskDetailDrawerClose}
                />
            )}
            {isEditAddressModalOpen && (
                <EditTaskAddressModal
                    ref={editAddressModalRef}
                    taskId={id}
                    isDelivery={isTwoPartTask ? Boolean(tabIndex) : isDelivery}
                    onHide={() => setEditAddressModalOpen(false)}
                />
            )}
            {isEditingCompartments && (
                <CompartmentEditModal
                    open={isEditingCompartments}
                    onCancel={toggleCompartmentEditModal}
                    compartmentsData={compartmentsData}
                    onSave={handleSaveCompartments}
                />
            )}
            {isEditInvoicesAndPayments && (
                <InvoiceAndPaymentEditModal
                    open={isEditInvoicesAndPayments}
                    isInvoiceEditable={isTaskReadOnly}
                    onCancel={toggleInvoiceAndPaymentEditModal}
                    item={selectedInvoiceItem}
                    onSave={handleSaveInvoicesAndPayments}
                    onDelete={handleDeleteInvoicesAndPayments}
                />
            )}
        </Drawer>
    );
}

export default TaskDetailDrawer;
