import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeSelectedMapStop } from '~/reducers/selectedMapStopsSlice';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { IconButton, ManageStopControlListItem } from '~/ui';
import { useGetMetricsIconsData } from './useGetMetricsIconsData';
import { useIconButtonStyles } from './useIconButtonStyles';
import { useGetTaskTypeText } from './useGetTaskTypeText';
import { getRouteSequenceNumber } from '~/utils/stopUtils';

function ResequenceModalStopList({
    selectedStop,
    handleDragStart,
    handleDragOver,
    handleDrop,
    stopIdPropertyName,
    tooltipPlacement = 'top'
}) {
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const dispatch = useDispatch();
    const { stopName, isUnassigned = false } = selectedStop;
    const metricsIconsData = useGetMetricsIconsData({ stop: selectedStop });
    const taskTypeText = useGetTaskTypeText({ stop: selectedStop });
    const stopId = selectedStop[stopIdPropertyName];
    const { iconButtonClassName, iconButtonTextColor, iconButtonStyle } =
        useIconButtonStyles({ isUnassigned });

    const stopNumber = getRouteSequenceNumber(selectedStop) || '?';

    function _removeStop() {
        if (!isOpenUnassignedTasksDrawer) {
            dispatch(removeSelectedMapStop(stopId));
            return;
        }

        const { pickupStopData, deliveryStopData, isPickup } = selectedStop;

        dispatch(
            removeSelectedMapStop(
                isPickup
                    ? pickupStopData.clientRouteTaskId
                    : deliveryStopData.clientRouteTaskId
            )
        );
    }

    return (
        <div
            className="resequencemodal-stopitem _p-relative"
            draggable="true"
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            id={stopId}
            data-testid="resequence-modal-stop-list"
        >
            <ManageStopControlListItem
                className={iconButtonClassName}
                text={stopName}
                iconButtonText={stopNumber}
                iconButtonTextColor={iconButtonTextColor}
                tooltipPlacement={tooltipPlacement}
                metricsIconsData={metricsIconsData}
                iconButtonStyle={iconButtonStyle}
                taskTypeText={taskTypeText}
            />
            <div className="_d-flex stopitem-buttongroup">
                <IconButton
                    className="icon-button_clear stopitem-button"
                    onClick={_removeStop}
                    icon="cancel"
                    iconColor="galaxy-800"
                />
                <IconButton
                    className="icon-button_clear stopitem-button"
                    icon="iconDragger"
                    iconColor="galaxy-800"
                />
            </div>
        </div>
    );
}

export default ResequenceModalStopList;
