import React from 'react';
import { useTranslation } from 'react-i18next';

import './InvoiceAndPayment.scss';

interface InvoiceAndPaymentData {
    invoiceId: string;
    invoiceNumber: string;
    amountDue: number;
}

type Props = {
    item: InvoiceAndPaymentData;
    handleSelectedInvoice: (data: InvoiceAndPaymentData) => void;
};

const invoiceAndPayment = 'invoice-and-payment';

export const InvoiceAndPayment = ({ item, handleSelectedInvoice }: Props) => {
    const { t } = useTranslation(['taskManagement']);
    const { invoiceNumber, amountDue } = item;

    return (
        <div
            className={`${invoiceAndPayment} _ai-center _jc-space-between _mb-3`}
            data-testid="invoice-and-payment-container"
            onClick={() => handleSelectedInvoice(item)}
            onKeyDown={() => handleSelectedInvoice(item)}
            role="button"
            tabIndex={0}
        >
            <div className={`${invoiceAndPayment}__detail`}>
                <div className="heading-3 _to-ellipsis">
                    {t('drawer.invoicesAndPayments.amountDue', {
                        amount: amountDue
                    })}
                </div>
                <div className="sub-heading-2 _to-ellipsis">
                    {t('drawer.invoicesAndPayments.invoiceNumber', {
                        number: invoiceNumber
                    })}
                </div>
            </div>
        </div>
    );
};
