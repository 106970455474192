import React from 'react';
import { Icon } from '~/ui';

import './TaskActionButton.scss';

function TaskActionButton({
    title,
    icon = '',
    onClick = () => {},
    isDisplayed,
    ...extra
}) {
    if (!isDisplayed) {
        return null;
    }
    return (
        <div
            className="_d-flex _ai-center taskactionbutton"
            role="button"
            onClick={onClick}
            onKeyDown={onClick}
            tabIndex={0}
        >
            {icon && (
                <Icon
                    icon={icon}
                    color="comet"
                    className="taskactionbutton-icon"
                />
            )}
            <span data-testid={extra['data-testid'] || title}>{title}</span>
        </div>
    );
}

export default TaskActionButton;
