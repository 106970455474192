import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import {
    RouteCardMarker,
    RouteCardSummary,
    RouteCardTitle,
    CompartmentUsageContainer
} from '~/ui';
import { getSummaryMetrics, Stats } from '../utils';
import { useHideRouteTrip } from '~/hooks';
import classnames from 'classnames';

import './TripListItem.scss';

export enum TripListItemVariants {
    DEFAULT = 'default',
    SMALL = 'small'
}

type TripListItemVariantValues = `${TripListItemVariants}`;

interface TripListItemProps {
    isMultiCompartment: boolean;
    variant?: TripListItemVariantValues;
    index: number;
    clientRouteId: string;
    markerColor?: CSSProperties;
    iconColor?: string;
    stats: Stats;
    isParentHovered?: boolean;
}

const TRIP_BLOCK_CLASSNAME = 'routeitem__trip';

export const TripListItem = ({
    isMultiCompartment,
    index,
    clientRouteId,
    markerColor,
    iconColor,
    stats,
    isParentHovered = false,
    variant = TripListItemVariants.DEFAULT
}: TripListItemProps): JSX.Element => {
    const { t } = useTranslation('translation');
    const { isHidden, hideButton } = useHideRouteTrip({
        clientRouteId,
        isParentHovered
    });

    const classNames = classnames(TRIP_BLOCK_CLASSNAME, '_d-flex', {
        [`${TRIP_BLOCK_CLASSNAME}--disabled`]: isHidden,
        [`${TRIP_BLOCK_CLASSNAME}--small`]:
            variant === TripListItemVariants.SMALL
    });

    const title = t('TripName', { number: index + 1 });
    const { compartmentStats = [] } = stats;
    const metrics = getSummaryMetrics({
        stats,
        isMultiCompartmentClient: isMultiCompartment
    });

    return (
        <div className={classNames} data-testid="routeitem-trip-section">
            <div className="routeitem__markers _fd-column _ai-center _jc-space-between">
                <RouteCardMarker
                    disabled
                    iconStyle={markerColor}
                    icon="route"
                    iconSize="xs"
                    iconColor={iconColor}
                />
            </div>
            <div className="routeitem-main">
                <div className="_d-flex _jc-space-between routeitem__trip-title">
                    <RouteCardTitle title={title} /> {hideButton}
                </div>
                <RouteCardSummary
                    className="routeitem__summary _d-grid"
                    metrics={metrics}
                />
                <CompartmentUsageContainer
                    isMultiCompartment={isMultiCompartment}
                    compartmentStats={compartmentStats}
                />
            </div>
        </div>
    );
};
