import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Supercluster from 'supercluster';

import { ApiLiveStop, Coordinates } from '~/api/types';

import {
    useHereMaps,
    useIsolatedRoutes,
    useLiveDispatchTasks,
    useMapUtils,
    useOnDemandDispatchMarkerEventHandler
} from '~/hooks';
import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';
import makeUnassignedClusterMarkers from '~/utils/map-modes/unassigned-tasks-cluster-mode';
import makeEquipmentClusterMarkers from '~/utils/map-modes/equipment-cluster-mode';

import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { usePlanMapEventsContext } from '~/components/MapPage/PlanMap/PlanMapEventsContext';

import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectDispatchedDrivers } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import {
    selectIsOpenSuggestDrawer,
    selectIsOpenAnyUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';

import makeDispatchedComponents from '~/utils/map-modes/dispatched-routes-mode';
import { makeLiveRoutesComponents } from '~/utils/map-modes/live-routes-stops-mode';
import { selectEquipment } from '~/reducers/equipmentSlice';
import {
    selectIsClusteringEquipment,
    selectIsClusteringStops,
    selectedIsClusteringUnassignedTasks
} from '~/reducers/mapSettingsSlice';
import { selectLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';
import { selectLastPlanMapBounds } from '~/reducers/lastPlanMapBoundsSlice';
import { makeUnassignedStopMarkerEffects } from '../makeUnassignedStopMarkerEffects';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { makeEquipmentMarkerEffects } from '../makeEquipmentMarkerEffects';
import { isEmpty } from 'lodash';
import { getFilteredEquipments } from '~/components/MapPage/PlanMap/utils';
import { useEquipmentTableStates } from '~/components/MapPageDrawers/LiveDispatchDrawer/useEquipmentTableStates';

interface UseDispatchedModeMapMarkersProps {
    stopLevelCoordinatesRef: MutableRefObject<Record<string, unknown>[]>;
    driverCoordinatesRef: MutableRefObject<Record<string, unknown>[]>;
    unassignedSuperClusters: Supercluster.AnyProps[];
    equipmentSuperClusters: Supercluster.AnyProps[];
    liveStopsSuperClusters: Supercluster.AnyProps[];
}

interface UseDispatchedModeMapMarkersReturnValues {
    routeMarkers: JSX.Element[];
    routeLines: JSX.Element[];
    selectedRouteLines: JSX.Element[];
    routeStopMarkers: JSX.Element[];
    depotStopMarkers: JSX.Element[];
    routeMarkerCoordinates: Coordinates[];
    equipmentMarkers: JSX.Element[];
}

const MAX_PAGE_SIZE = 200;

// @TODO break down further https://wisesys.atlassian.net/browse/RP-846
const useDispatchedModeMapMarkers = ({
    stopLevelCoordinatesRef,
    driverCoordinatesRef,
    unassignedSuperClusters,
    equipmentSuperClusters,
    liveStopsSuperClusters
}: UseDispatchedModeMapMarkersProps): UseDispatchedModeMapMarkersReturnValues => {
    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { mapInstance, routesLevelData } = usePlanMapPropsContext();
    const { mapRouteMode, isDispatchedRouteMode } = useMapUtils();
    const { emittedEventHandler } = usePlanMapEventsContext();
    const { selectedMapRoutes } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });
    const { viewCardDetails } = useIsolatedRoutes();
    const onDemandDispatchMarkerEventHandler =
        useOnDemandDispatchMarkerEventHandler();
    const isClusteringUnassignedTasks = useSelector(
        selectedIsClusteringUnassignedTasks(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const isClusteringEquipment = useSelector(
        selectIsClusteringEquipment(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const { isHerePlanMapActive } = useHereMaps();
    const lastPlanMapZoom = useSelector(selectLastPlanMapZoom);
    const lastPlanMapBounds = useSelector(selectLastPlanMapBounds);

    const isOpenAnyUnassignedTasksDrawer = useSelector(
        selectIsOpenAnyUnassignedTasksDrawer
    );
    const selectedDate = useSelector(selectDateOnly);
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const equipment = useSelector(selectEquipment);
    const { globalFilter, columnFilters } = useEquipmentTableStates();

    const { data: selectedDrawerCard } = (useSelector(
        selectSelectedDrawerCardData
    ) ?? {}) as {
        data?: {
            cepLocation: Coordinates;
            schedule: ApiLiveStop[];
        };
    };
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenAnyUnassignedTasksDrawer
    );
    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const isOpenSuggestDrawer = useSelector(selectIsOpenSuggestDrawer);

    const [routeMarkers, setRouteMarkers] = useState<JSX.Element[]>([]);
    const [routeLines, setRouteLines] = useState<JSX.Element[]>([]);
    const [routeMarkerCoordinates, setRouteMarkerCoordinates] = useState<
        Coordinates[]
    >([]);
    const [routeStopMarkers, setRouteStopMarkers] = useState<JSX.Element[]>([]);
    const [depotStopMarkers, setDepotStopMarkers] = useState<JSX.Element[]>([]);
    const [selectedRouteLines, setSelectedRouteLines] = useState<JSX.Element[]>(
        []
    );
    const [equipmentMarkers, setEquipmentMarkers] = useState<JSX.Element[]>([]);

    const { unassignedTasks: filteredUnassignedTasks } = useLiveDispatchTasks({
        selectedTaskIds,
        apiPaginationOptions: {
            limit: MAX_PAGE_SIZE,
            page: 0
        }
    });

    const getClusters = useCallback(
        (superCluster) => {
            if (isHerePlanMapActive) {
                return superCluster.points;
            }
            return superCluster.getClusters(lastPlanMapBounds, lastPlanMapZoom);
        },
        [isHerePlanMapActive, lastPlanMapBounds, lastPlanMapZoom]
    );

    useEffect(() => {
        if (!selectedDate) return;
        setRouteMarkers([]);
        setRouteLines([]);
        setRouteMarkerCoordinates([]);
        setRouteStopMarkers([]);
        setSelectedRouteLines([]);
        setEquipmentMarkers([]);
    }, [selectedDate]);

    useEffect(() => {
        if (!isDispatchedRouteMode) {
            return;
        }

        stopLevelCoordinatesRef.current = [];
        const {
            driverMarkers: newDriverMarkers,
            routeLines: newRouteLines,
            driverMarkerCoordinates
        } = makeDispatchedComponents(dispatchedDrivers, mapInstance);

        driverCoordinatesRef.current = driverMarkerCoordinates;
        setRouteMarkers(newDriverMarkers as unknown as JSX.Element[]);
        setRouteLines(newRouteLines as unknown as JSX.Element[]);
        setRouteMarkerCoordinates(driverMarkerCoordinates);

        if (
            !(
                selectedDrawerCard ||
                isOpenUnassignedTasksDrawer ||
                viewCardDetails
            )
        ) {
            return;
        }

        const selectedClientDriverIds = selectedDrawerCard
            ? [selectedDrawerRouteId]
            : selectedMapRoutes;

        if (isOpenUnassignedTasksDrawer) {
            const stopMarkerEffects = makeUnassignedStopMarkerEffects({
                isClusteringStops: isClusteringUnassignedTasks,
                unassignedSuperClusters,
                getClusters,
                makeUnassignedClusterMarkers,
                emittedEventHandler,
                planTasks: filteredUnassignedTasks
            });

            setRouteStopMarkers(stopMarkerEffects);
            return;
        }

        const {
            liveRoutesDepotMarkers,
            liveRoutesStopMarkers,
            liveRoutesIsolatedRouteLines
        } = makeLiveRoutesComponents({
            selectedClientDriverIds,
            dispatchedDrivers,
            showDrawerOnDemandDispatch: isOpenUnassignedTasksDrawer,
            onDemandDispatchTasks: filteredUnassignedTasks,
            onDemandDispatchMarkerEventHandler,
            mapInstance,
            mapRouteMode,
            isOpenSuggestDrawer,
            emittedEventHandler,
            liveStopsSuperClusters,
            getClusters,
            isClusteringStops
        });

        setDepotStopMarkers(liveRoutesDepotMarkers);
        setRouteStopMarkers(liveRoutesStopMarkers);
        setSelectedRouteLines(liveRoutesIsolatedRouteLines);

        if (!selectedDrawerCard?.schedule) {
            return;
        }

        const driverStopCoords = selectedDrawerCard.schedule.map(
            (item: ApiLiveStop) => {
                return item.location.location;
            }
        );
        driverStopCoords.push(selectedDrawerCard.cepLocation);
        stopLevelCoordinatesRef.current = driverStopCoords as unknown as Record<
            string,
            unknown
        >[];
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        emittedEventHandler,
        dispatchedDrivers,
        mapRouteMode,
        isDispatchedRouteMode,
        selectedDrawerCard,
        selectedDrawerRouteId,
        selectedMapRoutes,
        viewCardDetails,
        isOpenUnassignedTasksDrawer,
        isOpenAnyUnassignedTasksDrawer,
        filteredUnassignedTasks,
        mapInstance,
        unassignedSuperClusters,
        isClusteringUnassignedTasks,
        getClusters,
        isClusteringStops
    ]);

    useEffect(() => {
        if (!isDispatchedRouteMode || !mapInstance || isEmpty(equipment)) {
            return;
        }

        const filteredEquipments = getFilteredEquipments({
            equipment,
            globalFilter,
            columnFilters
        });

        const equipmentMarkerEffects = makeEquipmentMarkerEffects({
            isClusteringStops: isClusteringEquipment,
            equipmentSuperClusters,
            getClusters,
            makeEquipmentClusterMarkers,
            equipment: filteredEquipments
        });

        setEquipmentMarkers(equipmentMarkerEffects as unknown as JSX.Element[]);
    }, [
        isDispatchedRouteMode,
        mapInstance,
        equipment,
        emittedEventHandler,
        equipmentSuperClusters,
        getClusters,
        isClusteringEquipment,
        globalFilter,
        columnFilters
    ]);

    return {
        routeMarkers,
        depotStopMarkers,
        routeStopMarkers,
        routeLines,
        selectedRouteLines,
        routeMarkerCoordinates,
        equipmentMarkers
    };
};

export default useDispatchedModeMapMarkers;
