import React from 'react';
import classNames from 'classnames';
import { Tooltip, Icon } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSize } from '~/ui/components/Icon/IconSizes';

import './detail.scss';

interface DetailProps {
    /**
     * The icon name
     */
    iconName?: IconName;
    /**
     * The icon color
     */
    iconColor?: string;
    /**
     * The icon size
     */
    iconSize?: IconSize;
    /**
     * The main content
     */
    mainContent: React.ReactNode;
    /**
     * The secondary content
     */
    secondaryContent?: React.ReactNode;
    /**
     * The icon class name
     */
    iconClassName?: string;
    /**
     * The class name
     */
    className?: string;
    /**
     * Whether to show tooltip on the icon
     */
    showTooltipOnIcon?: boolean;
    /**
     * The content of the icon tooltip
     */
    iconTooltipContent?: string;
    /**
     * The test id
     */
    dataTestId?: string;
}

function Detail({
    iconName,
    iconColor,
    iconSize,
    mainContent,
    secondaryContent,
    iconClassName,
    className,
    showTooltipOnIcon = false,
    iconTooltipContent = '',
    dataTestId = 'detail'
}: DetailProps) {
    if (!mainContent) return null;

    function getClassName() {
        const defaultClassName = 'detail';
        return classNames(defaultClassName, className);
    }

    return (
        <div className={getClassName()} data-testid={`${dataTestId}`}>
            {iconName && (
                <Tooltip
                    content={iconTooltipContent}
                    placement="top"
                    disabled={!showTooltipOnIcon}
                >
                    <div className="detail-icon">
                        <Icon
                            icon={iconName}
                            color={iconColor}
                            size={iconSize}
                            className={`${iconClassName}`}
                            data-testid={`${iconName}-icon`}
                        />
                    </div>
                </Tooltip>
            )}
            <div className="detail-content">
                <div className="detail-main" data-testid={`${dataTestId}-main`}>
                    {mainContent}
                </div>
                {secondaryContent && (
                    <div
                        className="detail-secondary"
                        data-testid={`${dataTestId}-secondary`}
                    >
                        {secondaryContent}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Detail;
