import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDropDownMenuHandler } from '~/hooks';
import { DropDownMenu, DropDownMenuItem } from '~/ui/components/DropDownMenu';

import './TaskPriorityDropDown.scss';

type TaskPriorityDropdownProps = {
    selectedId: number;
    handleSubmit: (value: number) => void;
    isEditable: boolean;
};
function TaskPriorityDropdown({
    selectedId,
    handleSubmit,
    isEditable
}: TaskPriorityDropdownProps): JSX.Element {
    const { t } = useTranslation(['addTask', 'common']);
    const {
        ref: dropdownRef,
        isOpen,
        onToggle,
        onClose
    } = useDropDownMenuHandler();

    function toggleDropDown() {
        if (!isEditable) return;
        onToggle();
    }

    function handleClickItem(value: number) {
        handleSubmit(value);
        onClose();
    }

    const taskPriorityDropDownMenuItemsData = useMemo(
        () =>
            [
                {
                    translationKey: 'fields.priority.fields.high.label',
                    id: 1,
                    onClick: () => {
                        handleClickItem(1);
                    },
                    menuItemTestId: 'high-priority-menu-item'
                },
                {
                    translationKey: 'fields.priority.fields.medium.label',
                    id: 2,
                    onClick: () => {
                        handleClickItem(2);
                    },
                    menuItemTestId: 'medium-priority-menu-item'
                },
                {
                    translationKey: 'fields.priority.fields.low.label',
                    id: 3,
                    onClick: () => {
                        handleClickItem(3);
                    },
                    menuItemTestId: 'low-priority-menu-item'
                }
            ] as const,
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [onClose, handleClickItem]
    );

    const taskDropDownMenuTranslationKey =
        taskPriorityDropDownMenuItemsData[selectedId - 1]?.translationKey ||
        'common:unknown';

    return (
        <div className="task-priority-dropdown _p-relative">
            <DropDownMenu
                title={{ name: t(taskDropDownMenuTranslationKey) }}
                isOpen={isOpen}
                toggle={toggleDropDown}
                close={onClose}
                styles={{
                    innerContainer: 'dropdown-flex _p-0',
                    menuTitle: 'dropdown-flex-item _p-0',
                    menuTitleText: 'title-text'
                }}
                ref={dropdownRef}
            >
                {taskPriorityDropDownMenuItemsData.map(
                    ({ translationKey, onClick, menuItemTestId, id }) =>
                        id !== selectedId && (
                            <DropDownMenuItem
                                key={translationKey}
                                menuItem={{
                                    text: t(translationKey),
                                    onClick
                                }}
                                data-testid={menuItemTestId}
                            />
                        )
                )}
            </DropDownMenu>
        </div>
    );
}

export default TaskPriorityDropdown;
