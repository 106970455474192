import React from 'react';

import { Icon } from '~/ui';

import dateUtils from '~/utils/date-utils';
import { IconName } from '../Icon/IconNames';

import './DatePickerHeader.scss';

interface ArrowButtonProps {
    icon: IconName;
    rotation: string;
    onClick: () => void;
}

interface DatePickerHeaderProps {
    date: Date;
    decreaseYear: () => void;
    increaseYear: () => void;
    decreaseMonth: () => void;
    increaseMonth: () => void;
}

function ArrowButton({ icon, rotation = '90', onClick }: ArrowButtonProps) {
    const iconClassName = `arrowbutton-icon-${rotation}`;
    return (
        <button
            type="button"
            className="icon-wrapper icon-wrapper--dark"
            onClick={onClick}
        >
            <Icon
                icon={icon}
                color="galaxy-500"
                size="m"
                className={iconClassName}
            />
        </button>
    );
}

export const DatePickerHeader = ({
    date,
    decreaseYear,
    increaseYear,
    decreaseMonth,
    increaseMonth
}: DatePickerHeaderProps) => {
    return (
        <div className="_d-flex _ai-center _jc-space-between datepickerheader">
            <ArrowButton
                icon="doubleChevron"
                rotation="90"
                onClick={decreaseYear}
            />
            <ArrowButton
                icon="chevronDown"
                rotation="90"
                onClick={decreaseMonth}
            />
            <span className="datepickerheader-title">
                {dateUtils.formatMonthYear(date)}
            </span>
            <ArrowButton
                icon="chevronDown"
                rotation="270"
                onClick={increaseMonth}
            />
            <ArrowButton
                icon="doubleChevron"
                rotation="270"
                onClick={increaseYear}
            />
        </div>
    );
};
