import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiInventoryItem } from '~/api/types';
import { useMapUtils, useToastContext } from '~/hooks';

import { IconSizes } from '../Icon/IconSizes';
import InventoryItemsApi from '~/api/InventoryItemsApi';
import { Collapsible, Icon, Spinner } from '~/ui/components';

import { InventoryListItem } from './InventoryListItem';

import './InventoryItemsDetails.scss';

const ROOT_CLASS_NAME = 'inventory-items-details';

interface InventoryItemsDetailsProps {
    inventoryItems: ApiInventoryItem[];
    isCompleted?: boolean;
    inventoryItemsCount: number;
    taskId: string;
}

export const InventoryItemsDetails = ({
    inventoryItems,
    inventoryItemsCount,
    isCompleted,
    taskId
}: InventoryItemsDetailsProps) => {
    const { t } = useTranslation(['inventory', 'error']);
    const { addToast } = useToastContext();

    const { isPlanRouteMode } = useMapUtils();
    const [inventoryItemList, setInventoryItemList] = useState<
        ApiInventoryItem[]
    >([]);
    const [initOpen, setInitOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setInventoryItemList(inventoryItems);
    }, [inventoryItems]);

    const getCardHeader = () => {
        return (
            <div className="inventory-header" data-testid="inventory-header">
                <Icon icon="unitsOutline" size={IconSizes.M} />
                {t('numInventoryItems', {
                    count: inventoryItemsCount
                })}
            </div>
        );
    };

    const getInventoryItems = async () => {
        try {
            const {
                data: { data }
            } = await InventoryItemsApi.get({
                task_id: taskId,
                signed: true,
                limit: 1000
            });
            setInventoryItemList(data);
        } catch (error) {
            console.error(error);
            addToast({
                message: t('error:inventoryItemsFetchError'),
                variant: 'error'
            });
        }
    };

    const overrideToggle = async () => {
        if (!inventoryItemsCount) return;

        // This is a workaround to get inventory items
        // web/planSolution?detail=stops does not return inventory items
        if (isPlanRouteMode && !inventoryItemList.length) {
            setIsLoading(true);
            await getInventoryItems();
            setIsLoading(false);
        }

        setInitOpen(!initOpen);
    };

    return (
        <>
            <Collapsible
                className={`${ROOT_CLASS_NAME}__collapsible`}
                iconColor="meteor"
                iconPosition={Collapsible.iconPosition.RIGHT}
                cardHeader={getCardHeader()}
                overrideClickHandler={() => overrideToggle()}
                initOpen={initOpen}
                textOpen={t('collapsible.hideDetails')}
                textClose={t('collapsible.viewDetails')}
                data-testid={`${ROOT_CLASS_NAME}-collapsible`}
            >
                <div className="inventory-list" data-testid="inventory-list">
                    <div className="inventory-header-row">
                        <span className="inventory-item-name _text-3-alt">
                            {t('itemName')}
                        </span>
                        <div>
                            <span
                                className="_text-3-alt"
                                data-testid="inventory-header-planned"
                            >
                                {t('planned')}
                            </span>
                            {isCompleted && (
                                <span
                                    className="_text-3-alt"
                                    data-testid="inventory-header-actual"
                                >
                                    {t('actual')}
                                </span>
                            )}
                        </div>
                    </div>
                    <div
                        className={`${ROOT_CLASS_NAME}__item-group`}
                        data-testid={`${ROOT_CLASS_NAME}-item-group`}
                    >
                        {inventoryItemList.map((item) => {
                            const {
                                item_name: itemName = '',
                                actual_quantity: actualQuantity = 0,
                                expected_quantity: expectedQuantity = 0,
                                id
                            } = item;
                            return (
                                <InventoryListItem
                                    key={id}
                                    itemName={itemName}
                                    planned={expectedQuantity}
                                    actual={actualQuantity}
                                    isCompleted={isCompleted}
                                />
                            );
                        })}
                    </div>
                </div>
            </Collapsible>
            {isLoading && (
                <div className="_jc-center">
                    <Spinner
                        sx={{
                            width: '2rem',
                            height: '2rem',
                            marginTop: '-2rem'
                        }}
                        color="ocean"
                    />
                </div>
            )}
        </>
    );
};
