export const enum SortDir {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * Parameters used for pagination-related requests
 */
export interface PaginationParams {
    page?: number;
    limit?: number;
    sortdir?: SortDir;
}

/**
 * The typical response data associated when making a paginated api request
 */
export interface PaginationMetadata {
    limit?: number;
    page?: number;
    sortdir?: SortDir;
    startAt?: number;
    total?: number;
}

/**
 * The extended to include next property for pagination response
 */
export interface ExtendedPaginationParams extends PaginationParams {
    /**
     * this property will identify next number of screen
     */
    next?: number;
}
