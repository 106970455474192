import React from 'react';
import { Icon } from '~/ui';
import './OutOfService.scss';

interface OutOfServiceProps {
    textMsg: string;
}

function OutOfService({ textMsg }: OutOfServiceProps) {
    return (
        <span className="_d-flex _ai-center out-of-service">
            <Icon icon="warning" color="saturn-60" />
            {textMsg}
        </span>
    );
}

export default OutOfService;
