import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskActionButtonDropdown } from '~/components/TaskManagementPage/TaskActionBar/TaskActionButton';
import { DropDownMenuItem } from '~/ui';
import { usePairDropdownButtonLogicHandler } from './usePairDropdownButtonLogicHandler';
import { InitialTaskActions } from '~/hooks/useTaskAvailableActions/types';

interface SelectedToolbarPairDropdownButtonParams {
    isDisplayed?: InitialTaskActions;
}

export const SelectedToolbarPairDropdownButton = ({
    isDisplayed
}: SelectedToolbarPairDropdownButtonParams) => {
    const { t } = useTranslation(['liveDispatchTable']);

    const {
        pairButtonRef,
        isPairDropdownVisible,
        setIsPairDropdownVisible,
        taskPairItem,
        equipmentPairItem,
        depotPairItem
    } = usePairDropdownButtonLogicHandler({ isDisplayed });

    const {
        manualPair: isDisplayedManualPair,
        pairTaskToEquipment: isDisplayedPairTaskToEquipment,
        pairEquipmentToTask: isDisplayedPairEquipmentToTask,
        depot: isDisplayedDepot
    } = isDisplayed || {};

    const isDisplayedEquipmentPair =
        isDisplayedPairTaskToEquipment || isDisplayedPairEquipmentToTask;

    const props = {
        ref: pairButtonRef,
        title: t('selectedToolbar.pair'),
        icon: 'link',
        isDisplayed:
            isDisplayedManualPair ||
            isDisplayedPairTaskToEquipment ||
            isDisplayedPairEquipmentToTask ||
            isDisplayedDepot,
        isOpen: isPairDropdownVisible,
        toggle: () => setIsPairDropdownVisible(!isPairDropdownVisible),
        close: () => setIsPairDropdownVisible(false),
        children: (
            <>
                {isDisplayedManualPair && (
                    <DropDownMenuItem menuItem={taskPairItem} />
                )}
                {isDisplayedEquipmentPair && (
                    <DropDownMenuItem menuItem={equipmentPairItem} />
                )}
                {isDisplayedDepot && (
                    <DropDownMenuItem menuItem={depotPairItem} />
                )}
            </>
        )
    };

    return <TaskActionButtonDropdown {...props} />;
};
