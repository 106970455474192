import { createSlice, createSelector } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const planRoutesLevelData = createSlice({
    name: 'planRoutesLevelData',
    initialState: [],
    reducers: {
        setPlanRoutesLevelData: (state, action) => {
            if (!Array.isArray(action.payload)) {
                return state;
            }
            return action.payload;
        },
        resetPlanRoutesLevelData: () => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const { setPlanRoutesLevelData, resetPlanRoutesLevelData } =
    planRoutesLevelData.actions;

export const selectPlanRoutesLevelData = (state) => state.planRoutesLevelData;

export const selectPlannedLevelData = createSelector(
    (state) => state.planRoutesLevelData,
    (planRoutes) => {
        return Object.values(planRoutes).filter(
            (plannedData) => plannedData.route.isPlanned
        );
    }
);

export const selectUnplannedRouteData = (state) => {
    return Object.values(state.planRoutesLevelData).find(
        (item) => item.route.routeId === 'unplanned'
    );
};

export default planRoutesLevelData.reducer;
