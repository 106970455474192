import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLiveDispatchTasks } from '~/hooks';
import { Task } from '~/data-classes';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { selectSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { usePairEquipmentControl } from './usePairEquipmentControl';
import { EquipmentCard } from './EquipmentCard';
import { PopupHeader } from '../LiveDispatchPopup/PopupHeader/PopupHeader';
import { PopupContentHeader } from '../LiveDispatchPopup/PopupContentHeader';
import { PopupSubmitButton } from '../LiveDispatchPopup/PopupSubmitButton';
import { PopupTaskCard } from '../LiveDispatchPopup/PopupTaskCard';
import { PopupSelectionCard } from '../LiveDispatchPopup/PopupSelectionCard/PopupSelectionCard';
import { useEquipmentTableData } from '../useEquipmentTableData';
import './PairEquipmentControl.scss';

const MAX_PAGE_SIZE = 200;

export const PairEquipmentControl = () => {
    const { t } = useTranslation(['liveDispatchTable']);

    const { handleCancel, handleLeftArrow, handleSubmit, isSubmitDisabled } =
        usePairEquipmentControl();

    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);

    const { unassignedTasks } = useLiveDispatchTasks({
        selectedTaskIds,
        apiPaginationOptions: {
            limit: MAX_PAGE_SIZE,
            page: 0
        }
    });
    const { selectedEquipmentData } = useEquipmentTableData();

    const [unassignedTask] = unassignedTasks;
    const task = new Task(unassignedTask);

    const [equipmentData] = selectedEquipmentData;

    return (
        <div
            className="pair-equipment-control"
            data-testid="pair-equipment-control"
        >
            <PopupHeader
                title={t('pairEquipmentControl.header.title')}
                onCancel={handleCancel}
                onLeftArrowClick={handleLeftArrow}
            />
            <div className="live-dispatch-popup-body">
                <PopupContentHeader
                    title={t('pairEquipmentControl.bodyContent.header.title')}
                    subtitle={t(
                        'pairEquipmentControl.bodyContent.header.subtitle'
                    )}
                />
                <div
                    className="live-dispatch-tasks-container"
                    data-testid="live-dispatch-tasks-container"
                >
                    <div className="live-dispatch-popup-card">
                        {!selectedEquipmentIds.length && (
                            <PopupSelectionCard
                                title={t(
                                    'pairEquipmentControl.bodyContent.selectionCard.equipment.title'
                                )}
                                subtitle={t(
                                    'pairEquipmentControl.bodyContent.selectionCard.equipment.subtitle'
                                )}
                            />
                        )}
                        {Boolean((selectedEquipmentIds as string[]).length) &&
                            equipmentData && (
                                <EquipmentCard equipment={equipmentData} />
                            )}
                    </div>
                    <div className="live-dispatch-popup-card">
                        {Boolean(selectedTaskIds.length) && unassignedTask && (
                            <PopupTaskCard task={task} />
                        )}
                        {!selectedTaskIds.length && (
                            <PopupSelectionCard
                                title={t(
                                    'pairEquipmentControl.bodyContent.selectionCard.task.title'
                                )}
                                subtitle={t(
                                    'pairEquipmentControl.bodyContent.selectionCard.task.subtitle'
                                )}
                            />
                        )}
                    </div>
                </div>
                <PopupSubmitButton
                    text={t(
                        'pairEquipmentControl.bodyContent.submitButton.text'
                    )}
                    onSubmit={handleSubmit}
                    isDisabled={isSubmitDisabled}
                />
                <div
                    className="pair-equipment-control__note"
                    data-testid="pair-equipment-control__note"
                >
                    <p>
                        <b className="pair-equipment-control__note-highlight-text">
                            Wise will create a pickup task
                        </b>{' '}
                        out of the equipment when pairing to a delivery task.
                    </p>
                    <p>
                        Once paired, the task and equipment will be available
                        under the{' '}
                        <b className="pair-equipment-control__note-highlight-text">
                            unassigned task tab
                        </b>
                    </p>
                </div>
            </div>
        </div>
    );
};
