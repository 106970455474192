import React, { useEffect } from 'react';
import { IconButton } from '~/ui';
import { Equipment } from '~/data-classes';
import { useEquipmentCard } from './useEquipmentCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsUnassignedTasksTabActive,
    setIsUnassignedTasksTabActive
} from '~/reducers/mapDrawerSettingsSlice';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import './EquipmentCard.scss';

const rootClassName = 'equipment-card';

interface EquipmentCardProps {
    equipment: Equipment;
}

export const EquipmentCard = ({ equipment }: EquipmentCardProps) => {
    const { id, status, attached, timestamp } = useEquipmentCard(equipment);

    const dispatch = useDispatch();

    const isUnassignedTasksTabActive = useSelector(
        selectIsUnassignedTasksTabActive
    );
    const selectedTaskIds = useSelector(selectSelectedTaskIds);

    const isSelectedTaskIdsEmpty = !selectedTaskIds.length;

    useEffect(() => {
        if (!isUnassignedTasksTabActive && isSelectedTaskIdsEmpty) {
            dispatch(setIsUnassignedTasksTabActive(true));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isUnassignedTasksTabActive, isSelectedTaskIdsEmpty]);

    const remainingAttachments = attached.length - 2;

    return (
        <div className={rootClassName} data-testid={rootClassName}>
            <IconButton
                className={`${rootClassName}__marker`}
                icon="iconBox"
                iconColor="galaxy-800"
                iconSize="s"
                disabled
            />
            <div className={`${rootClassName}__summary`}>
                <div className={`${rootClassName}__summary-header`}>
                    <span
                        className={`${rootClassName}__text`}
                        data-testid={`${rootClassName}__text`}
                    >
                        {id}
                    </span>
                    <span
                        className={`${rootClassName}__status`}
                        data-testid={`${rootClassName}__status`}
                    >
                        {status}
                    </span>
                </div>
                <span
                    className={`${rootClassName}__text`}
                    data-testid={`${rootClassName}__timestamp`}
                >
                    {timestamp}
                </span>
                <div
                    className={`${rootClassName}__attachments`}
                    data-testid={`${rootClassName}__attachments`}
                >
                    {attached.map((attachment, index) => {
                        return (
                            <div
                                className={`${rootClassName}__attachment`}
                                key={attachment}
                            >
                                {index < 2 && attachment}
                                {index >= 2 && <>+{remainingAttachments}</>}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
