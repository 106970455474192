import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import tasksApi from '~/api/TasksApi';
import taskUtils from '~/utils/task-utils';
import { updateTaskById } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';

interface UseUpdateTaskProps {
    /**
     * Whether the task is read only
     */
    isTaskReadOnly: boolean;
}

export interface UpdateTaskProps {
    /**
     * The task id
     */
    taskId: string;
    /**
     * The task property to update
     */
    property: string;
    /**
     * The new value of the property
     */
    value: string | string[] | null | unknown;
}

export const useUpdateTask = ({ isTaskReadOnly }: UseUpdateTaskProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['taskManagement', 'common', 'error']);

    const updateTask = useCallback(
        async ({
            property,
            taskId,
            value
        }: UpdateTaskProps): Promise<boolean> => {
            if (isTaskReadOnly) {
                dispatch(
                    addToast({
                        message: t('error:taskNotEditableError'),
                        variant: 'error'
                    })
                );
                return false;
            }

            try {
                await tasksApi.update(taskId, {
                    [property]: value
                });
                const getResponse = await tasksApi.getTask(taskId);
                const updatedTask = getResponse.data.data;
                const taskStatus = taskUtils.getTaskStatus(updatedTask);

                dispatch(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    updateTaskById({
                        taskId,
                        value: { ...updatedTask, taskStatus }
                    })
                );
                dispatch(
                    addToast({
                        message: t('taskManagement:successUpdate'),
                        variant: 'success'
                    })
                );

                return true;
            } catch (error) {
                console.error(error);
                dispatch(
                    addToast({
                        message: t('error:taskEditError'),
                        variant: 'error'
                    })
                );
                return false;
            }
        },
        [dispatch, t, isTaskReadOnly]
    );

    return { updateTask };
};
