import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TextInput, Icon, TooltipButton } from '~/ui';
import { useEquipmentTableStates } from '~/components/MapPageDrawers/LiveDispatchDrawer/useEquipmentTableStates';

import { IconSizes } from '~/ui/components/Icon/IconSizes';
import './EquipmentTableSearchBar.scss';

const ROOT_CLASS_NAME = 'equipment-table-search-bar';

export const EquipmentTableSearchBar = () => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { globalFilter, setGlobalFilter } = useEquipmentTableStates();
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const isSearchFilled = !isSearchFocused && globalFilter?.length;

    const elementClassName = classNames(ROOT_CLASS_NAME, '_d-flex', {
        [`${ROOT_CLASS_NAME}--focused`]: isSearchFocused,
        [`${ROOT_CLASS_NAME}--filled`]: isSearchFilled
    });
    const iconClassName = `${ROOT_CLASS_NAME}__icon`;
    const inputClassName = `${ROOT_CLASS_NAME}__search`;
    const searchClassName = `${ROOT_CLASS_NAME}__button_search`;
    const clearClassName = `${ROOT_CLASS_NAME}__button_clear`;

    const searchPlaceholder = t('tableToolbar.search.placeholder');
    const setSearchInput = () => {
        setGlobalFilter(searchInputRef.current?.value || '');
    };
    const clearSearch = () => {
        setGlobalFilter('');
        if (searchInputRef.current) {
            searchInputRef.current.value = '';
        }
    };

    return (
        <div className={elementClassName} data-testid={ROOT_CLASS_NAME}>
            <div className={iconClassName} data-testid={iconClassName}>
                <Icon icon="search" size={IconSizes.S} />
            </div>
            <TextInput
                className={inputClassName}
                ref={searchInputRef}
                width="fullWidth"
                placeholder={searchPlaceholder}
                variant="basic"
                value={globalFilter}
                onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                        setSearchInput();
                    }
                }}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                data-testid={`${ROOT_CLASS_NAME}__search`}
            />
            {globalFilter && (
                <TooltipButton
                    className={clearClassName}
                    onClick={clearSearch}
                    data-testid={`${ROOT_CLASS_NAME}__button_clear`}
                >
                    {t('clearLabel')}
                </TooltipButton>
            )}
            <TooltipButton
                className={searchClassName}
                onClick={setSearchInput}
                data-testid={`${ROOT_CLASS_NAME}__button_search`}
            >
                <Icon icon="search" color="comet" />
            </TooltipButton>
        </div>
    );
};
