import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';
import {
    resetLiveDrivers,
    selectCompletedDrivers
} from '~/reducers/liveDriversSlice';
import { selectSelectedClients } from '~/reducers/selectedClientsSlice';

import { ApiLiveDriver } from '~/api/types';

export function useCompletedLiveDrivers() {
    const dispatch = useDispatch();
    const selectedClients = useSelector(selectSelectedClients);
    const completedDrivers = useSelector(
        selectCompletedDrivers
    ) as ApiLiveDriver[];

    useEffect(() => {
        dispatch(resetLiveDrivers());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedClients]);

    return useMemo(
        () => completedDrivers.map((driver) => new LiveDriver(driver)),
        [completedDrivers]
    );
}
