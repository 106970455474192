import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flexRender } from '@tanstack/react-table';
import { Equipment } from '~/data-classes';
import {
    selectSelectedEquipmentIds,
    setSelectedEquipmentIds
} from '~/reducers/selectedEquipmentIdsSlice';
import { TableRowProps, HandleChangeProps } from '../types';
import { Checkbox } from '~/ui';
import './EquipmentTableRow.scss';

export const EquipmentTableRow = ({ row }: TableRowProps<Equipment>) => {
    const dispatch = useDispatch();
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);

    const equipmentTableRowClickHandler = ({
        selectedValues
    }: HandleChangeProps) => {
        dispatch(setSelectedEquipmentIds(selectedValues as string[]));
    };

    return (
        <tr
            role="row"
            className="equipment-table-row"
            data-testid="equipment-table-row"
        >
            <td role="cell">
                <div
                    className="live-dispatch-table-data live-dispatch-table-row__data-checkbox"
                    data-testid="live-dispatch-table-row__data-checkbox"
                >
                    <Checkbox
                        id={row.original.id as string}
                        className="_m-0"
                        value={row.original.id as string}
                        selectedValues={selectedEquipmentIds}
                        handleChange={equipmentTableRowClickHandler}
                        data-testid="equipment-table-checkbox"
                        disabled={false}
                    />
                </div>
            </td>
            {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    );
};
