import React from 'react';

import { PopupBody } from './PopupBody';
import { PopupHeader } from './PopupHeader';
import { useLiveDispatchAssignTasks } from './useLiveDispatchAssignTasks';

import './LiveDispatchAssignDriverPopup.scss';

export const LiveDispatchAssignDriverPopup: React.FC = () => {
    const { isPopupDisplayed } = useLiveDispatchAssignTasks();

    if (!isPopupDisplayed) {
        return null;
    }

    return (
        <div
            className="live-dispatch-assign-driver-popup _d-flex _fd-column"
            data-testid="live-dispatch-assign-driver-popup"
        >
            <PopupHeader />
            <PopupBody />
        </div>
    );
};
