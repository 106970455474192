import React from 'react';

import { Icon, UIElement } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';

import './TaskTimeContent.scss';

interface TaskTimeContentProps extends UIElement {
    icon: IconName;
    text: string;
}

const ROOT_CLASS_NAME = 'live-dispatch-task-time-content';

export const TaskTimeContent: React.FC<TaskTimeContentProps> = ({
    icon,
    text,
    'data-testid': dataTestId
}) => {
    return (
        <div
            className={ROOT_CLASS_NAME}
            data-testid={dataTestId || ROOT_CLASS_NAME}
        >
            <Icon
                icon={icon}
                size="xs"
                color="galaxy-500"
                data-testid="live-dispatch-task-time-content__icon"
            />
            <span className="live-dispatch-task-time-content__text">
                {text}
            </span>
        </div>
    );
};
