import React from 'react';
import classNames from 'classnames';
import { TextOverflowTooltip, Tooltip, Highlight } from '~/ui';
import { LiveDispatchTableDataProps } from '../../types';
import './LiveDispatchTableData.scss';

const ROOT_CLASS_NAME = 'live-dispatch-table-data';

export const LiveDispatchTableData = ({
    data,
    tooltip,
    truncate = false,
    className,
    'data-testid': dataTestId,
    searchText = ''
}: LiveDispatchTableDataProps<string>) => {
    const TableDataTooltip = tooltip ? Tooltip : TextOverflowTooltip;
    const tooltipContent = tooltip ?? data;
    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        {
            [`${ROOT_CLASS_NAME}--truncate`]: truncate
        },
        className
    );

    return (
        <TableDataTooltip content={tooltipContent} placement="top">
            <div className={elementClassName} data-testid={dataTestId}>
                <Highlight inputText={data || ''} searchText={searchText} />
            </div>
        </TableDataTooltip>
    );
};
