import { useMemo } from 'react';
import Supercluster from 'supercluster';
import { useMapUtils } from '~/hooks';
import { useSelector } from 'react-redux';
import { markerUtils } from '~/utils/map';
import {
    selectDispatchedDrivers,
    selectCompletedDrivers
} from '~/reducers/liveDriversSlice';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

import constants from '~/utils/constants';
import { LiveStop } from '~/data-classes';

const clusterRadius = constants.mapOptionSettings.DEFAULT_CLUSTER_RADIUS;
const maxZoom = constants.mapOptionSettings.MAX_ZOOM;

export const useLiveStopsSuperClusters = () => {
    const { isStopsClustersMode, mapRouteMode } = useMapUtils();
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const completedDrivers = useSelector(selectCompletedDrivers);

    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);

    const liveDrivers =
        mapRouteMode === constants.mapRouteModes.COMPLETED
            ? completedDrivers
            : dispatchedDrivers;
    const filteredDrivers = useMemo(() => {
        return liveDrivers.filter((driver) => {
            const { clientId, id } = driver;
            const mapRouteId = `${clientId}_${id}`;
            return selectedMapRoutes.includes(mapRouteId);
        });
    }, [liveDrivers, selectedMapRoutes]);

    const liveSchedules = useMemo(() => {
        if (!isStopsClustersMode || !filteredDrivers.length) {
            return [];
        }

        return filteredDrivers.flatMap((liveDriver) => {
            return liveDriver.schedule
                .filter((liveStop: LiveStop) => !liveStop.isDepot)
                .map(
                    (liveStop: {
                        location: { location: { lat: number; lng: number } };
                    }) => {
                        const {
                            location: {
                                location: { lat, lng }
                            }
                        } = liveStop;
                        return markerUtils.getGeoJSONFeaturePoint(
                            lat,
                            lng,
                            liveStop
                        );
                    }
                );
        });
    }, [filteredDrivers, isStopsClustersMode]);

    const superClusters = useMemo(() => {
        const superClusterEffects = [];
        const superCluster = new Supercluster({
            radius: clusterRadius,
            maxZoom
        });
        superCluster.load(liveSchedules);
        superClusterEffects.push(superCluster);
        return superClusterEffects;
    }, [liveSchedules]);

    return {
        superClusters
    };
};
