import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { Icon, useClickOutside } from '~/ui';
import TaskAddressForm from '~/components/TaskManagementPage/EditTaskAddressModal/TaskAddressForm';

import tasksApi from '~/api/TasksApi';
import { useAddressFormik } from '~/hooks/useFormik/useAddressFormik';
import { selectTasks, updateTaskById } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import constants from '~/utils/constants';
import taskUtils from '~/utils/task-utils';

import './EditTaskAddressModal.scss';

const EditTaskAddressModal = React.forwardRef(function EditTaskAddressModal(
    { taskId, isDelivery, onHide },
    ref
) {
    const { t } = useTranslation(['taskManagement', 'common']);
    const domElement = document.getElementById('modal-root');
    const dispatch = useDispatch();

    const allTasks = useSelector(selectTasks);
    const taskAddress = isDelivery
        ? allTasks[taskId].deliveryLocation
        : allTasks[taskId].pickupLocation;
    const { initialValues, validationSchema } = useAddressFormik(taskAddress);

    useClickOutside(ref, onHide);

    async function handleSave(values) {
        const property = isDelivery
            ? constants.taskTypes.DELIVERY_LOCATION
            : constants.taskTypes.PICKUP_LOCATION;
        const previousValue = allTasks[taskId][property];
        const updatedValue = {
            name: previousValue.name,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
            country: values.country
        };

        // only update the coordinates if they have been altered, otherwise
        // we fetch new coordinates based on the input address
        if (
            String(previousValue.location.lng) !== values.longitude ||
            String(previousValue.location.lat) !== values.latitude
        ) {
            updatedValue.location = {
                lng: parseFloat(values.longitude),
                lat: parseFloat(values.latitude)
            };
        }

        try {
            await tasksApi.update(taskId, {
                [property]: updatedValue
            });
            const getResponse = await tasksApi.getTask(taskId);
            const updatedTask = getResponse.data.data;
            const taskStatus = taskUtils.getTaskStatus(updatedTask);
            dispatch(
                updateTaskById({
                    taskId,
                    value: { ...updatedTask, taskStatus }
                })
            );
            onHide();
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('error:taskEditError'),
                    variant: 'error'
                })
            );
        }
    }

    return ReactDOM.createPortal(
        <div className="overlay">
            <div className="modal" ref={ref}>
                <div className="modal-header">
                    <span>{t('editAddress.title')}</span>
                    <button
                        data-testid="cancel-button-icon"
                        type="button"
                        className="icon-wrapper"
                        onClick={onHide}
                    >
                        <Icon icon="cancel" color="galaxy-800" />
                    </button>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(formValues) => handleSave(formValues)}
                    validationSchema={validationSchema}
                >
                    <TaskAddressForm onHide={onHide} />
                </Formik>
            </div>
        </div>,
        domElement
    );
});

export default EditTaskAddressModal;
