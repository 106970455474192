import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theme-ui';
import { PersistGate } from 'redux-persist/integration/react';
import FlagProvider, { UnleashClient } from '@unleash/proxy-client-react';

// base
import 'normalize.css';
import '~/ui/assets/stylesheets/base/custom-defaults.scss';
import '~/ui/assets/stylesheets/base/color.scss';
import '~/ui/assets/stylesheets/base/sizes.scss';
import '~/ui/assets/stylesheets/base/dimensions.scss';
import '~/ui/assets/stylesheets/base/z-index.scss';
import '~/ui/assets/stylesheets/base/weights.scss';

// components
import '~/ui/assets/stylesheets/components/units.scss';
import '~/ui/assets/stylesheets/components/tray.scss';
import '~/ui/assets/stylesheets/components/field.scss';
import '~/ui/assets/stylesheets/components/tabs.scss';
import '~/ui/assets/stylesheets/components/label.scss';
import '~/ui/assets/stylesheets/components/heading.scss';
import '~/ui/assets/stylesheets/components/sub-heading.scss';
// controls
import '~/ui/assets/stylesheets/controls/button.scss';
import '~/ui/assets/stylesheets/controls/checkbox.scss';
import '~/ui/assets/stylesheets/controls/search.scss';
import '~/ui/assets/stylesheets/controls/radio-list-item.scss';
import '~/ui/assets/stylesheets/controls/icon-wrapper.scss';
// skins
import '~/ui/assets/stylesheets/skins/skins.scss';
// utilities
import '~/ui/assets/stylesheets/utilities/typography.scss';
import '~/ui/assets/stylesheets/utilities/width.scss';
import '~/ui/assets/stylesheets/utilities/position.scss';
import '~/ui/assets/stylesheets/utilities/transform.scss';
import '~/ui/assets/stylesheets/utilities/justify-self.scss';
import '~/ui/assets/stylesheets/utilities/margin.scss';
import '~/ui/assets/stylesheets/utilities/padding.scss';
import '~/ui/assets/stylesheets/utilities/font-size.scss';
import '~/ui/assets/stylesheets/utilities/pointer-events.scss';
import '~/ui/assets/stylesheets/utilities/display.scss';
import '~/ui/assets/stylesheets/utilities/align-items.scss';
import '~/ui/assets/stylesheets/utilities/text-transform.scss';
import '~/ui/assets/stylesheets/utilities/text-overflow.scss';
import '~/ui/assets/stylesheets/utilities/white-space.scss';
import '~/ui/assets/stylesheets/utilities/flex-direction.scss';
import '~/ui/assets/stylesheets/utilities/justify-content.scss';
import '~/ui/assets/stylesheets/utilities/flex-wrap.scss';
import '~/ui/assets/stylesheets/utilities/column-gap.scss';
import '~/ui/assets/stylesheets/utilities/row-gap.scss';

import '~/i18n';

import { store, persistor } from '~/store';
import App from '~/components/App';
import { theme } from '~/ui';
import { ToastContextProvider } from '~/contexts/ToastContext';
import generalUtils, { getAppVersion } from '~/utils/general-utils';
import constants from '~/utils/constants';
import { getFeatureFlagsConfig } from '~/utils/feature-flags-utils';

// initialize `react-query` client with updated default options
const defaultOptions = constants.reactQueryClientOptions.default;
const queryClient = new QueryClient({ defaultOptions });

// output current react version for dev and testing environments
if (generalUtils.isFlaggedFeatureDisplayed()) {
    console.info('Flagged features are visible');
    console.info(`React.version: ${React.version}`);
    console.info(`App version: ${getAppVersion()}`);
}

// global callback required by google maps api
/* eslint-disable-next-line func-names */
window.initMap = function () {
    if (generalUtils.isFlaggedFeatureDisplayed()) {
        console.info(`Google Maps API version: ${google.maps.version}`);
    }
};

// generates script tag to load Google Map Places API
// TODO https://wisesys.atlassian.net/browse/RP-765 should load places API when needed
const script = document.createElement('script');
script.type = 'application/javascript';
script.src = `https://maps.googleapis.com/maps/api/js?callback=initMap&libraries=${Object.values(
    constants.mapLibraries
).join(',')}&key=${constants.mapOptionSettings.DEFAULT_GOOGLE_MAP_KEY}`;
script.async = true;
script.defer = true;
document.head.appendChild(script);

const featureFlagConfig = getFeatureFlagsConfig(window.location.hostname);

const client = new UnleashClient(featureFlagConfig);

ReactDOM.render(
    <FlagProvider unleashClient={client}>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <ToastContextProvider>
                            {/* TODO: add a fallback component to replace null */}
                            <Suspense fallback={null}>
                                <App />
                            </Suspense>
                        </ToastContextProvider>
                    </ThemeProvider>
                </PersistGate>
            </QueryClientProvider>
        </Provider>
    </FlagProvider>,
    document.getElementById('root')
);
