import { FilterFn } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { LiveDispatchTaskTableRowData } from '~/data-classes';
import constants from '~/utils/constants';

function isDateTimeInRange(
    start: string,
    filterStart: DateTime,
    filterEnd: DateTime
): boolean {
    const systemStart = DateTime.fromISO(start);
    return systemStart >= filterStart && systemStart <= filterEnd;
}

/**
 * column filter function for the `rowExtTaskType` column of the Live Dispatch table
 */
export const columnFilterExtTaskType: FilterFn<LiveDispatchTaskTableRowData> = (
    row,
    columnId,
    columnFilterValue
) => {
    const rowCellValue: string = row.getValue(columnId);
    if (rowCellValue === null) {
        return columnFilterValue.includes('None');
    }
    const mergedExternalTaskTypeArray = Object.values(
        constants.externalTaskTypePredefinedData
    ).reduce((acc, currentValue) => [...acc, ...currentValue], []);

    const uniqueExternalTaskTypeArray = [
        ...new Set(mergedExternalTaskTypeArray)
    ];
    if (!uniqueExternalTaskTypeArray.includes(rowCellValue)) {
        return columnFilterValue.includes('Other');
    }
    return columnFilterValue.includes(rowCellValue);
};

/**
 * column filter function for the `rowTImeWindow` column of the Live Dispatch table
 */
export const columnFilterTime: FilterFn<LiveDispatchTaskTableRowData> = (
    row,
    columnId,
    columnFilterValue
) => {
    const rowCellValue: string = row.getValue(columnId);
    const timeStringValue = rowCellValue;

    const checkTimeWindows = (timeString: string) => {
        if (
            isDateTimeInRange(
                timeString,
                columnFilterValue[0],
                columnFilterValue[1]
            )
        ) {
            return true;
        }
        return false;
    };

    if (timeStringValue && checkTimeWindows(timeStringValue)) {
        return true;
    }

    return false;
};

/**
 * column filter function for the `rowTaskType` column of the Live Dispatch table
 */
export const columnFilterTaskType: FilterFn<LiveDispatchTaskTableRowData> = (
    row,
    columnId,
    columnFilterValue
) => {
    const rowCellValue: string = row.getValue(columnId);
    return columnFilterValue.includes(rowCellValue);
};

/**
 * column filter function for the `rowTaskPriority` column of the Live Dispatch table
 */
export const columnFilterTaskPriority: FilterFn<LiveDispatchTaskTableRowData> =
    (row, columnId, columnFilterValue) => {
        const priorityNameConvert = {
            Normal: 'LOW',
            High: 'MEDIUM',
            Highest: 'HIGH'
        };
        const priorityCodeFilterValue: number[] = columnFilterValue.map(
            (filterValue: 'Highest' | 'High' | 'Normal') =>
                constants.priorityCodes[
                    priorityNameConvert[
                        filterValue
                    ].toUpperCase() as keyof typeof constants.priorityCodes
                ]
        );
        const rowCellValue: number = row.getValue(columnId);
        return priorityCodeFilterValue.includes(rowCellValue);
    };

/**
 * column filter function for the `equipmentType` column of the Live Dispatch table
 */
export const columnFilterEquipmentType: FilterFn<LiveDispatchTaskTableRowData> =
    (row, columnId, columnFilterValue) => {
        const rowCellValue: string = row.getValue(columnId);
        if (rowCellValue === null) {
            return columnFilterValue.includes('N/A');
        }
        return columnFilterValue.includes(rowCellValue);
    };
