import React from 'react';
import { IconButton } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import './TaskLabelsEditLabel.scss';

interface TaskLabelsEditLabelProps {
    isDisabled?: boolean;
    label: string;
    onDelete: (label: string) => void;
}

export const TaskLabelsEditLabel = ({
    isDisabled,
    label,
    onDelete
}: TaskLabelsEditLabelProps) => {
    const rootClassName = 'task-labels-edit-label';
    return (
        <div className={`${rootClassName} _d-flex _ai-center`}>
            <span className={`${rootClassName}__text`}>{label}</span>
            <IconButton
                disabled={isDisabled}
                className={`${rootClassName}__delete`}
                icon="cancel"
                iconSize={IconSizes.XS}
                onClick={() => onDelete(label)}
            />
        </div>
    );
};
