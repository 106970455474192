import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LiveDispatchTaskTableRowData } from '~/data-classes';
import { useLiveDispatchTasks } from '~/hooks';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { useLiveDispatchTableContext } from '~/components/MapPageDrawers/LiveDispatchDrawer/LiveDispatchTableContext';

export const useLiveDispatchTableData = () => {
    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const {
        globalFilter: search,
        apiPaginationOptions: apiOptions,
        setTotalLiveDispatchCount
    } = useLiveDispatchTableContext();
    const apiPaginationOptions = { ...apiOptions, search };
    const { unassignedTasks, excludedUnassignedTasks, isFiltered } =
        useLiveDispatchTasks({
            selectedTaskIds,
            apiPaginationOptions,
            setTotalLiveDispatchCount
        });

    const unassignedData = useMemo<LiveDispatchTaskTableRowData[]>(() => {
        const data = unassignedTasks.map(
            (task) => new LiveDispatchTaskTableRowData(task)
        );
        return data;
    }, [unassignedTasks]);

    const excludedData = useMemo<LiveDispatchTaskTableRowData[]>(() => {
        const data = excludedUnassignedTasks.map(
            (task) => new LiveDispatchTaskTableRowData(task)
        );
        return data;
    }, [excludedUnassignedTasks]);

    return {
        isFiltered,
        unassignedTasksData: unassignedData,
        excludedUnassignedTasksData: excludedData
    };
};
