import React from 'react';
import { useTranslation } from 'react-i18next';
import { Detail } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import {
    VehicleType,
    vehicleTypeToTranslationKeyMap
} from '~/components/AddTaskModal/utils/addTaskModalUtils';
import { UpdateTaskProps } from '../useUpdateTask';
import { VehicleTypeDropDown } from './VehicleTypeDropDown';

interface UseVehicleTypeProps {
    /**
     * Whether the dropdown menu is editable
     */
    isEditable: boolean;
    /**
     * The task update handler
     */
    onTaskUpdate: (data: UpdateTaskProps) => Promise<void>;
    /**
     * The selected task
     */
    task?: {
        /**
         * The task id
         */
        id: string;
        /**
         * The list of vehicle types
         */
        vehicleTypes?: string[];
    } | null;
}

export const useVehicleTypeDetail = ({
    isEditable,
    onTaskUpdate,
    task
}: UseVehicleTypeProps) => {
    const { t } = useTranslation(['addTask', 'common', 'driverManagement']);
    const { vehicleTypes, id: taskId = '' } = task ?? {};
    const [vehicleType = ''] = vehicleTypes ?? [];

    const onItemSelected = (value: string) => {
        onTaskUpdate({
            taskId,
            value,
            property: 'vehicleTypes'
        });
    };

    const getDropDownTitle = () => {
        if (!vehicleType) return t('common:N/A');

        const dropDownTitleTranslationKey =
            vehicleTypeToTranslationKeyMap[vehicleType as VehicleType];

        if (dropDownTitleTranslationKey)
            return t(`addTask:${dropDownTitleTranslationKey}`);

        return vehicleType;
    };

    const vehicleTypeDetail = (
        <Detail
            showTooltipOnIcon
            iconName="vehicleType"
            iconSize={IconSizes.S}
            iconColor="galaxy-500"
            iconClassName="detail-drawer-item__icon"
            iconTooltipContent={t(
                'driverManagement:iconTooltipContent.vehicleType'
            )}
            className="_d-flex _ai-flex-start"
            mainContent={
                <VehicleTypeDropDown
                    isEditable={isEditable}
                    onItemSelected={onItemSelected}
                    title={getDropDownTitle()}
                />
            }
        />
    );

    return {
        vehicleTypeDetail
    };
};
