import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TaskStatusCondition } from '~/api/types';
import { Task } from '~/data-classes';

import { selectSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

import {
    UseTaskAvailableActionsParams,
    SelectedTasksTestResults,
    AvailableTaskActions
} from './types';

export const useTaskAvailableActions = ({
    allTasks,
    initialDisplayedButtons,
    hasRoutePlan
}: UseTaskAvailableActionsParams): AvailableTaskActions => {
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);
    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const mainClient = useSelector(selectMainClient);
    const { enableTwoPartTaskEditing } = mainClient?.preferences || {};

    /**
     * Performs a series of tests against the selected task IDs and the provided all tasks object
     *
     * Results can then be used to qualify certain task actions
     */
    const testResults = useMemo<SelectedTasksTestResults>(() => {
        const selectedTasks = Object.values(allTasks)
            .filter((apiTask) => selectedTaskIds.includes(apiTask.id))
            .map((apiTask) => new Task(apiTask));

        const counts = selectedTasks.reduce(
            (allCounts, task) => {
                const {
                    isTwoPart,
                    isUnassigned,
                    isDispatched,
                    taskStatusCondition
                } = task;

                if (isTwoPart) allCounts.twoPart++;
                if (!isTwoPart) allCounts.single++;
                if (
                    isUnassigned &&
                    taskStatusCondition !== TaskStatusCondition.PLANNED
                )
                    allCounts.unassigned++;
                if (isDispatched) allCounts.dispatched++;

                return allCounts;
            },
            {
                single: 0,
                twoPart: 0,
                unassigned: 0,
                dispatched: 0
            }
        );

        return {
            hasSelectedTasks: selectedTasks.length > 0,
            isOneTaskSelected: selectedTasks.length === 1,
            areTwoTasksSelected: selectedTasks.length === 2,
            areAllSingleTasks: selectedTasks.length === counts.single,
            areAllTwoPartTasks: selectedTasks.length === counts.twoPart,
            areAllUnassignedTasks: selectedTasks.length === counts.unassigned,
            areAllDispatchedTasks: selectedTasks.length === counts.dispatched,
            selectedTasks
        };
    }, [allTasks, selectedTaskIds]);

    /**
     * Spread test results
     */
    const {
        hasSelectedTasks,
        isOneTaskSelected,
        areTwoTasksSelected,
        areAllSingleTasks,
        areAllTwoPartTasks,
        areAllUnassignedTasks,
        areAllDispatchedTasks,
        selectedTasks
    } = testResults;
    const isRoutePlanAvailable = Boolean(hasRoutePlan);
    const isEnabledTwoPartTaskEditing = Boolean(enableTwoPartTaskEditing);

    /**
     * Whether the selected task can be used to display the `View Details` button
     */
    const isAvailableViewDetails = isOneTaskSelected;

    /**
     * Whether the selected task can be used to display the `Unassign Planned Task` button
     */
    const [{ isPlanned }] = isOneTaskSelected
        ? selectedTasks
        : [{ isPlanned: false }];
    const isAvailableUnassignPlannedTask = isOneTaskSelected && isPlanned;

    /**
     * Whether the selected task can be used to display the `Unassign Dispatched Task` button
     */
    const [{ isDispatched }] = isOneTaskSelected
        ? selectedTasks
        : [{ isDispatched: false }];
    const isAvailableUnassignDispatchedTask = isOneTaskSelected && isDispatched;

    /**
     * Whether the selected tasks can be used to display the `Split` button
     */
    const isAvailableSplitTasks =
        isEnabledTwoPartTaskEditing &&
        hasSelectedTasks &&
        areAllTwoPartTasks &&
        areAllUnassignedTasks;

    /**
     * Whether the selected tasks can be used to display the `Pair` button
     *
     * @todo Verify that `Pair` and `Optimal Pair` are two separate distinct actions.
     */
    const isAvailablePair =
        isEnabledTwoPartTaskEditing &&
        hasSelectedTasks &&
        areAllSingleTasks &&
        areAllUnassignedTasks;

    /**
     * Whether the selected tasks can be used to display the `Optimal Pair` button
     *
     * @todo Verify that `Pair` and `Optimal Pair` are two separate distinct actions.
     */
    const isAvailableOptimalPair =
        isEnabledTwoPartTaskEditing &&
        hasSelectedTasks &&
        areAllSingleTasks &&
        areAllUnassignedTasks;

    /**
     * Whether the selected tasks can be used to display the `Manual Pair` button
     */
    const canManualPair = () => {
        const isEligibleOption =
            areTwoTasksSelected && areAllSingleTasks && areAllUnassignedTasks;

        if (!isEligibleOption) return false;

        const [task1, task2] = selectedTasks;
        const { isDelivery: isDeliveryTask1, isPickup: isPickupTask1 } = task1;
        const { isDelivery: isDeliveryTask2, isPickup: isPickupTask2 } = task2;

        return (
            (isDeliveryTask1 && isPickupTask2) ||
            (isDeliveryTask2 && isPickupTask1)
        );
    };
    const isAvailableManualPair = canManualPair();

    /**
     * Whether the selected tasks can be used to display the `Assign` button
     */
    const isAvailableAssign =
        isRoutePlanAvailable && hasSelectedTasks && areAllUnassignedTasks;

    /**
     * Whether the selected tasks can be used to display the `Cancel` button
     */
    const isAvailableCancel = hasSelectedTasks && areAllDispatchedTasks;

    /**
     * Whether the selected tasks can be used to display the `Delete` button
     */
    const isAvailableDelete = hasSelectedTasks && areAllUnassignedTasks;

    /**
     * Whether the selected task can be used to pair with equipment
     */
    const canPairTaskToEquipment = () => {
        const isEligibleOption =
            isOneTaskSelected && areAllSingleTasks && areAllUnassignedTasks;
        if (!isEligibleOption) return false;

        const [task1] = selectedTasks;
        return task1.isDelivery;
    };
    const isAvailablePairTaskToEquipment = canPairTaskToEquipment();

    /**
     * Whether the selected equipment can be used to pair with a task
     */
    const isAvailablePairEquipmentToTask = selectedEquipmentIds.length === 1;

    /**
     * Whether the selected task can be used to pair with a depot
     */
    const isAvailableDepot =
        isOneTaskSelected && areAllSingleTasks && areAllUnassignedTasks;

    /**
     * Available task actions
     */
    const availableTaskActions: AvailableTaskActions = {
        viewDetails: isAvailableViewDetails,
        unassign:
            isAvailableUnassignPlannedTask || isAvailableUnassignDispatchedTask,
        unassignPlannedTask: isAvailableUnassignPlannedTask,
        unassignDispatchedTask: isAvailableUnassignDispatchedTask,
        split: isAvailableSplitTasks,
        pair: isAvailablePair,
        optimalPair: isAvailableOptimalPair,
        manualPair: isAvailableManualPair,
        assign: isAvailableAssign,
        cancel: isAvailableCancel,
        delete: isAvailableDelete,
        equipment:
            isAvailablePairTaskToEquipment && isAvailablePairEquipmentToTask,
        pairTaskToEquipment: isAvailablePairTaskToEquipment,
        pairEquipmentToTask: isAvailablePairEquipmentToTask,
        depot: isAvailableDepot
    };

    return { ...(initialDisplayedButtons || {}), ...availableTaskActions };
};
