import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import ReactDOM from 'react-dom';

import {
    Button,
    FormErrorMessage,
    Icon,
    TimeInput,
    useClickOutside,
    DatePickerHeader
} from '~/ui';

import tasksApi from '~/api/TasksApi';
import { selectDate } from '~/reducers/selectedDateSlice';
import { updateTaskMetrics } from '~/reducers/taskMetricsSlice';
import {
    removeTasksById,
    selectTasks,
    updateTaskById
} from '~/reducers/tasksSlice';
import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';
import taskUtils from '~/utils/task-utils';

import './EditTimeWindowModal.scss';

const EditTimeWindowModal = React.forwardRef(function EditTimeWindowModal(
    { taskId, isDelivery = false, onHide, onDateUpdate },
    ref
) {
    const { t } = useTranslation(['taskManagement', 'common']);
    const dispatch = useDispatch();
    const domElement = document.getElementById('modal-root');

    const initialTimeInputs = {
        firstStartTime: '',
        firstEndTime: '',
        secondStartTime: '',
        secondEndTime: ''
    };
    const { DELIVERY_WINDOW } = constants.taskTypes;
    const { PICKUP_WINDOW } = constants.taskTypes;

    const selectedDate = useSelector(selectDate);
    const allTasks = useSelector(selectTasks);

    const [dateInput, setDateInput] = useState(null);
    const [isNewDateSelected, setNewDateSelected] = useState(false);
    const [timeInputs, setTimeInputs] = useState({ ...initialTimeInputs });
    const [errorMessage, setErrorMessage] = useState('');
    const [isSaveDisabled, setSaveDisabled] = useState(false);

    useClickOutside(ref, onHide);

    /**
     * Get the initial time window
     * @param {string} taskType ('deliveryWindow' or 'pickupWindow')
     * @returns {{firstEndTime: string, firstStartTime: string, secondStartTime: string, secondEndTime: string}}
     */
    function getInitialTimeWindows(taskType = DELIVERY_WINDOW) {
        if (taskType !== DELIVERY_WINDOW && taskType !== PICKUP_WINDOW) {
            return { ...initialTimeInputs };
        }

        const taskWindow = allTasks[taskId].props[taskType];
        return {
            firstStartTime: dateUtils.get24HourTime(taskWindow[0].start),
            firstEndTime: dateUtils.get24HourTime(taskWindow[0].end),
            secondStartTime:
                taskWindow.length > 1
                    ? dateUtils.get24HourTime(taskWindow[1].start)
                    : '',
            secondEndTime:
                taskWindow.length > 1
                    ? dateUtils.get24HourTime(taskWindow[1].end)
                    : ''
        };
    }

    useEffect(() => {
        setDateInput(new Date(selectedDate));
        const taskTypeString = isDelivery ? DELIVERY_WINDOW : PICKUP_WINDOW;
        setTimeInputs(getInitialTimeWindows(taskTypeString));
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isDelivery]);

    function isValidTime(inputValue) {
        return DateTime.fromFormat(inputValue, 'T').isValid;
    }

    useEffect(() => {
        if (
            isValidTime(timeInputs.firstStartTime) &&
            isValidTime(timeInputs.firstEndTime)
        ) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }, [timeInputs]);

    function onDateChange(newDate) {
        setDateInput(newDate);
        if (
            dateUtils.isSameDay(
                DateTime.fromJSDate(newDate),
                DateTime.fromISO(selectedDate)
            )
        ) {
            setNewDateSelected(false);
        } else {
            setNewDateSelected(true);
        }
    }

    function handleTimeWindowChange(key, value) {
        setTimeInputs({
            ...timeInputs,
            [key]: value
        });
    }

    /**
     * Format edited time windows to ISO and into array
     * @returns {[{start: *, end: *}]}
     */
    function formatEditedTimeWindowArray() {
        const timeFormatOptions = { isConvertToUTC: true };

        const { firstStartTime, firstEndTime, secondStartTime, secondEndTime } =
            timeInputs;

        const firstStart = dateUtils.convert24HourDateTimeToISO(
            dateInput,
            firstStartTime,
            timeFormatOptions
        );

        const firstEnd = dateUtils.convert24HourDateTimeToISO(
            dateInput,
            firstEndTime,
            timeFormatOptions
        );

        const timeWindowArray = [
            {
                start: firstStart,
                end: firstEnd
            }
        ];

        if (secondStartTime && secondEndTime) {
            const secondStart = dateUtils.convert24HourDateTimeToISO(
                dateInput,
                secondStartTime,
                timeFormatOptions
            );

            const secondEnd = dateUtils.convert24HourDateTimeToISO(
                dateInput,
                secondEndTime,
                timeFormatOptions
            );

            timeWindowArray.push({
                start: secondStart,
                end: secondEnd
            });
        }
        return timeWindowArray;
    }

    async function onSave() {
        const date = dateUtils.convertJsDateToISODate(dateInput).slice(0, 10);
        const taskTypeString = isDelivery ? DELIVERY_WINDOW : PICKUP_WINDOW;
        const editedTimeWindow = formatEditedTimeWindowArray();
        const payload = {
            routeDate: date,
            props: {
                [taskTypeString]: editedTimeWindow
            }
        };

        try {
            await tasksApi.update(taskId, payload);

            const getResponse = await tasksApi.getTask(taskId);
            const updatedTask = getResponse.data.data;
            const taskStatus = taskUtils.getTaskStatus(updatedTask);

            dispatch(
                updateTaskById({
                    taskId,
                    value: { ...updatedTask, taskStatus }
                })
            );

            if (isNewDateSelected) {
                onDateUpdate();
                dispatch(removeTasksById([taskId]));
                dispatch(updateTaskMetrics(selectedDate));
            }
            onHide();
        } catch (e) {
            setErrorMessage(t('editTimeWindow.errorMessage'));
        }
    }

    return ReactDOM.createPortal(
        <div className="overlay">
            <div className="modal" ref={ref}>
                <div className="modal-header">
                    <span>{t('editTimeWindow.title')}</span>
                    <button
                        type="button"
                        className="icon-wrapper"
                        onClick={onHide}
                    >
                        <Icon icon="cancel" color="galaxy-800" />
                    </button>
                </div>
                {errorMessage && (
                    <FormErrorMessage
                        className="edittimewindow-modal-errormessage"
                        message={errorMessage}
                    />
                )}
                <div className="_d-flex _fd-column edittimewindow-modal-content">
                    <span className="_mb-3">{t('editTimeWindow.date')}</span>
                    <ReactDatePicker
                        id="datepicker"
                        placeholderText={t('editTimeWindow.placeholderDate')}
                        selected={dateInput}
                        minDate={new Date()}
                        onChange={onDateChange}
                        formatWeekDay={dateUtils.formatLetterDayOfWeek}
                        showPopperArrow={false}
                        renderCustomHeader={DatePickerHeader}
                        wrapperClassName="_w-100"
                    />
                    {isNewDateSelected && (
                        <div className="_d-flex _ai-center _mt-2 edittimewindow-modal-infomessage">
                            <Icon icon="infoLine" color="neptune-300" />
                            <span className="_ml-2">
                                {t('editTimeWindow.infoMessage')}
                            </span>
                        </div>
                    )}
                    <span className="_mt-6 _mb-3">
                        {t('editTimeWindow.firstTimeWindow')}
                    </span>
                    <div className="_d-flex">
                        <div className="_d-flex _fd-column _w-100 _mr-1">
                            <span className="_mb-3">
                                {t('editTimeWindow.startTime')}
                            </span>
                            <TimeInput
                                variant="form"
                                value={timeInputs.firstStartTime}
                                placeholder={t(
                                    'editTimeWindow.placeholderStart'
                                )}
                                onChange={(value) =>
                                    handleTimeWindowChange(
                                        'firstStartTime',
                                        value
                                    )
                                }
                            />
                        </div>
                        <div className="_d-flex _fd-column _w-100">
                            <span className="_mb-3">
                                {t('editTimeWindow.endTime')}
                            </span>
                            <TimeInput
                                variant="form"
                                value={timeInputs.firstEndTime}
                                placeholder={t('editTimeWindow.placeholderEnd')}
                                onChange={(value) =>
                                    handleTimeWindowChange(
                                        'firstEndTime',
                                        value
                                    )
                                }
                            />
                        </div>
                    </div>
                    <span className="_mt-6 _mb-3">
                        {t('editTimeWindow.secondTimeWindow')}
                    </span>
                    <div className="_d-flex">
                        <div className="_d-flex _fd-column _w-100 _mr-1">
                            <span className="_mb-3">
                                {t('editTimeWindow.startTime')}
                            </span>
                            <TimeInput
                                variant="form"
                                value={timeInputs.secondStartTime}
                                placeholder={t(
                                    'editTimeWindow.placeholderStart'
                                )}
                                onChange={(value) =>
                                    handleTimeWindowChange(
                                        'secondStartTime',
                                        value
                                    )
                                }
                                isEmptyValid
                            />
                        </div>
                        <div className="_d-flex _fd-column _w-100">
                            <span className="_mb-3">
                                {t('editTimeWindow.endTime')}
                            </span>
                            <TimeInput
                                variant="form"
                                value={timeInputs.secondEndTime}
                                placeholder={t('editTimeWindow.placeholderEnd')}
                                onChange={(value) =>
                                    handleTimeWindowChange(
                                        'secondEndTime',
                                        value
                                    )
                                }
                                isEmptyValid
                            />
                        </div>
                    </div>
                </div>
                <div className="_d-flex _jc-flex-end edittimewindow-modal-footer">
                    <Button
                        variant="secondary"
                        marginRight="1rem"
                        onClick={onHide}
                        data-testid="cancel-button"
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        onClick={onSave}
                        disabled={isSaveDisabled}
                        data-testid="save-button"
                    >
                        {t('common:save')}
                    </Button>
                </div>
            </div>
        </div>,
        domElement
    );
});

export default EditTimeWindowModal;
