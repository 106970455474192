import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import {
    AxiosApiResponse,
    ApiTaskScheduler,
    ReactQuerySideEffects
} from '~/api/types';
import TasksApi from '~/api/TasksApi';

import { PlanRoute } from '~/data-classes';

import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

import { usePlanRoutes } from '../useRoutes';

type HookDriverDispatch = {
    /** whether either dispatch function is busy */
    isDispatchBusy: boolean;
    /** accepts assigned tasks and dispatches the specified driver */
    dispatchDriver: (
        sideEffects: ReactQuerySideEffects,
        driverId?: string
    ) => void;
    /** accepts all driver assigned tasks and dispatches all scheduled driver routes */
    dispatchAllRoutes: (sideEffects: ReactQuerySideEffects) => void;
};

export const useDriverDispatch = (): HookDriverDispatch => {
    const selectedDate = useSelector(selectDateOnly);
    const selectedMapRouteIds = useSelector(
        selectSelectedMapRoutes
    ) as string[];
    const planRoutes = usePlanRoutes();

    const {
        isLoading: isLoadingDispatchDriver,
        mutateAsync: doDispatchDriver
    } = useMutation((driverId: string): Promise<
        AxiosApiResponse<ApiTaskScheduler>
    > => {
        return TasksApi.acceptAssignedTasks(driverId, selectedDate);
    });

    const {
        isLoading: isLoadingDispatchAll,
        mutateAsync: doDispatchAllRoutes
    } = useMutation((date: string): Promise<
        AxiosApiResponse<ApiTaskScheduler>
    > => {
        return TasksApi.dispatchAllRoutes(date);
    });

    const selectedDriverIds = useMemo(() => {
        if (!selectedMapRouteIds?.length) {
            return [];
        }
        const selectedDrivers = planRoutes
            .filter((planRoute: PlanRoute) => {
                return selectedMapRouteIds.includes(planRoute.clientRouteId);
            })
            .map((planRoute: PlanRoute) => {
                return planRoute.driverId;
            });
        return selectedDrivers;
    }, [selectedMapRouteIds, planRoutes]);

    const isDispatchBusy = isLoadingDispatchAll || isLoadingDispatchDriver;

    const dispatchDriver = async (
        sideEffects: ReactQuerySideEffects = {},
        selectedDriverId: string | undefined = undefined
    ): Promise<void> => {
        const {
            onSuccess: onSuccessDispatchDriver,
            onError: onErrorDispatchDriver
        } = sideEffects;
        const ids = selectedDriverId ? [selectedDriverId] : selectedDriverIds;

        try {
            const successData = await Promise.all(
                ids.map((driverId) => doDispatchDriver(driverId))
            );

            if (onSuccessDispatchDriver) onSuccessDispatchDriver(successData);
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            console.error(message);
            if (onErrorDispatchDriver) onErrorDispatchDriver(error);
        }
    };

    const dispatchAllRoutes = async (
        sideEffects: ReactQuerySideEffects = {}
    ): Promise<void> => {
        const {
            onSuccess: onSuccessDispatchAllRoutes,
            onError: onErrorDispatchAllRoutes
        } = sideEffects;

        if (!selectedDate) {
            return;
        }

        try {
            if (selectedDate) {
                const successData = await doDispatchAllRoutes(selectedDate);

                if (onSuccessDispatchAllRoutes)
                    onSuccessDispatchAllRoutes(successData);
            }
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            console.error(message);
            if (onErrorDispatchAllRoutes) onErrorDispatchAllRoutes(error);
        }
    };

    return {
        isDispatchBusy,
        dispatchDriver,
        dispatchAllRoutes
    };
};
