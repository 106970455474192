import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '~/store';

interface AddOperationalStopState {
    isOpenOperationalStopModal: boolean;
}

const initialState: AddOperationalStopState = {
    isOpenOperationalStopModal: false
};

export const addOperationalStopsSlice = createSlice({
    name: 'addOperationalStop',
    initialState,
    reducers: {
        setIsOpenOperationalStopModal: (
            state: AddOperationalStopState,
            action: PayloadAction<boolean>
        ) => {
            return {
                ...state,
                isOpenOperationalStopModal: action.payload
            };
        }
    }
});

export const { setIsOpenOperationalStopModal } =
    addOperationalStopsSlice.actions;

export const selectIsOpenOperationalStopModal = (state: RootState): boolean =>
    state.addOperationalStop.isOpenOperationalStopModal;

export default addOperationalStopsSlice.reducer;
