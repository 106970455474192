import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { UIElementWithChildren } from '~/ui';
import './EquipmentTableSelectedToolbar.scss';

const ROOT_CLASS_NAME = 'live-dispatch-table-selected-toolbar';

export const EquipmentTableSelectedToolbar = ({
    children
}: UIElementWithChildren) => {
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);
    const { t } = useTranslation(['liveDispatchTable']);

    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        '_jc-space-between _ai-center'
    );
    const statusTextClassName = classNames(
        `${ROOT_CLASS_NAME}__status-text`,
        '_text-2'
    );
    const selectedEquipmentsText = t('selectedToolbar.selectedEquipments', {
        count: selectedEquipmentIds.length
    });

    return (
        <div className={elementClassName} data-testid={ROOT_CLASS_NAME}>
            <div
                className={statusTextClassName}
                data-testid={`${ROOT_CLASS_NAME}__status`}
            >
                {selectedEquipmentsText}
            </div>

            <div
                className={`${ROOT_CLASS_NAME}__actions`}
                data-testid={`${ROOT_CLASS_NAME}__actions`}
            >
                {children}
            </div>
        </div>
    );
};
