import React, { useMemo } from 'react';
import { LiveDispatchTableData } from '../LiveDispatchTableData';
import { LiveDispatchTableDataProps } from '../../types';
import './LiveDispatchTableDataLabels.scss';

/**
 * The maximum number of labels to display before consolidating
 */
const MAX_LABELS = 3;

interface LiveDispatchTableLabelsData {
    key: string;
    label: string;
}

export const LiveDispatchTableDataLabels = ({
    data,
    className,
    'data-testid': dataTestId,
    searchText = ''
}: LiveDispatchTableDataProps<string[]>) => {
    const labels = useMemo<LiveDispatchTableLabelsData[]>(() => {
        const labelsData = data || [];
        return labelsData.map((label, idx) => ({
            key: `label-${idx}`,
            label
        }));
    }, [data]);

    const extraLabels = useMemo<string[]>(() => {
        return labels.splice(MAX_LABELS).map(({ label }) => label);
    }, [labels]);

    return (
        <div className={className} data-testid={dataTestId}>
            {labels.map(({ key, label }) => (
                <LiveDispatchTableData
                    key={key}
                    data={label}
                    className="live-dispatch-table-row__data-label"
                    data-testid="live-dispatch-table-row__data-label"
                    truncate
                    searchText={searchText}
                />
            ))}
            {extraLabels.length > 0 && (
                <LiveDispatchTableData
                    data={`${extraLabels.length}+`}
                    tooltip={extraLabels.join(', ')}
                    className="live-dispatch-table-row__data-label"
                    data-testid="live-dispatch-table-row__data-label"
                    searchText={searchText}
                />
            )}
        </div>
    );
};
