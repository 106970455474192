import React, { createContext, useState } from 'react';

import {
    EquipmentTableContextValues,
    EquipmentTableContextProviderProps
} from '../types';
import { GetEquipmentParams } from '~/api/EquipmentApi';

const MAX_PAGE_SIZE = 100;

export const EquipmentTableContext =
    createContext<EquipmentTableContextValues | undefined>(undefined);

export const EquipmentTableContextProvider = ({
    children
}: EquipmentTableContextProviderProps): JSX.Element => {
    const [totalEquipmentCount, setTotalEquipmentCount] = useState(0);
    const [evalualatedKeys, setEvalualatedKeys] = useState<string[]>([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(MAX_PAGE_SIZE);
    const [pageTotal, setPageTotal] = useState(1);
    const [apiPaginationOptions, setApiPaginationOptions] =
        useState<GetEquipmentParams>({
            limit: MAX_PAGE_SIZE,
            page: 1
        });

    return (
        <EquipmentTableContext.Provider
            value={{
                totalEquipmentCount,
                setTotalEquipmentCount,
                apiPaginationOptions,
                setApiPaginationOptions,
                pageIndex,
                setPageIndex,
                pageSize,
                setPageSize,
                pageTotal,
                setPageTotal,
                evalualatedKeys,
                setEvalualatedKeys
            }}
        >
            {children}
        </EquipmentTableContext.Provider>
    );
};
