import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useDropDownMenuHandler } from '~/hooks';
import { DropDownMenu, DropDownMenuItem, Icon, TooltipButton } from '~/ui';
import constants from '~/utils/constants';
import { useSelector } from 'react-redux';
import { selectSortConfig } from '~/reducers/liveDispatchTableSlice';
import { selectIsUnassignedTasksTabActive } from '~/reducers/mapDrawerSettingsSlice';
import {
    columnIdAndSortablePropertyKeysMapping,
    equipmentTableColumnIdAndSortableKeyMapping
} from '../types';
import { selectEquipmentSorting } from '~/reducers/equipmentTableSlice';

import './TableSortElement.scss';

const ROOT_CLASS_NAME = 'table-header';

interface TableSortElementProps {
    columnId: string;
    onClearSorting: () => void;
    onApplySorting: (id: string, sortingType: string) => void;
}

export const TableSortElement = ({
    columnId,
    onClearSorting,
    onApplySorting
}: TableSortElementProps) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const {
        ref,
        isOpen: isDropDownOpen,
        onToggle: toggleDropDown,
        onClose: closeDropDown
    } = useDropDownMenuHandler();

    const sortConfig = useSelector(selectSortConfig);
    const equipmentSorting = useSelector(selectEquipmentSorting);
    const isUnassignedTasksTabActive = useSelector(
        selectIsUnassignedTasksTabActive
    );
    const sortState = useMemo(() => {
        const sortItems = isUnassignedTasksTabActive
            ? sortConfig
            : equipmentSorting;

        const keyMap = isUnassignedTasksTabActive
            ? columnIdAndSortablePropertyKeysMapping
            : equipmentTableColumnIdAndSortableKeyMapping;

        const [sortItem] = sortItems || [{}];
        const { id: sortColumnId, desc: isColumnSortDescending } =
            sortItem || {};

        let sortDirection;
        let sortIconColor = 'galaxy-300';
        let isSortAscending;
        let isSortDescending;

        const mappedColumnId =
            Object.entries(keyMap).find(
                (entry) => entry[1] === sortColumnId
            )?.[0] || sortColumnId;

        if (mappedColumnId === columnId) {
            sortIconColor = 'meteor';
            isSortAscending = !isColumnSortDescending;
            isSortDescending = isColumnSortDescending;

            sortDirection = isColumnSortDescending
                ? constants.descending
                : constants.ascending;
        }

        if (isDropDownOpen) {
            sortIconColor = 'galaxy-800';
        }

        return {
            sortDirection,
            sortIconColor,
            isSortAscending,
            isSortDescending
        };
    }, [
        columnId,
        sortConfig,
        isDropDownOpen,
        isUnassignedTasksTabActive,
        equipmentSorting
    ]);

    const SortingIcon = () => (
        <Icon
            sx={{
                pointer: 'cursor'
            }}
            icon="sortIconNew"
            className={`${ROOT_CLASS_NAME}__sortingIcon`}
            color={sortState.sortIconColor}
            data-testid={`${ROOT_CLASS_NAME}__sortingIcon`}
            size="s"
        />
    );

    const ascendingItemClassName = classNames('_p-0 sortingMenuContainer', {
        applied: sortState.isSortAscending
    });
    const descendingItemClassName = classNames('_p-0 sortingMenuContainer', {
        applied: sortState.isSortDescending
    });
    const clearButtonClassName = classNames(
        'button_clear',
        `${ROOT_CLASS_NAME}-sorting-clear`,
        {
            active: sortState.sortDirection
        }
    );

    return (
        <DropDownMenu
            isOpen={isDropDownOpen}
            toggle={toggleDropDown}
            close={closeDropDown}
            customDropDownButton={SortingIcon}
            position="down-right"
            styles={{
                innerContainer: 'dropdown-flex _p-0',
                menuTitle: 'dropdown-flex-item',
                menuItems: 'sortingDropdown'
            }}
            ref={ref}
            data-testid={`${ROOT_CLASS_NAME}__sortingDropdown`}
        >
            <div className={`${ROOT_CLASS_NAME}__sortingHeader`}>
                <Icon
                    sx={{
                        pointer: 'cursor'
                    }}
                    icon="sortIconNew"
                    className={`${ROOT_CLASS_NAME}__sortingIcon`}
                    size="s"
                />
                <span>{t('sort')}</span>
                <Icon
                    color="galaxy-800"
                    sx={{
                        pointer: 'cursor'
                    }}
                    icon="cancel"
                    className={`${ROOT_CLASS_NAME}__sortingIcon`}
                    onClick={closeDropDown}
                    data-testid={`${ROOT_CLASS_NAME}__sortingClose`}
                    size="s"
                />
            </div>
            <hr className={`${ROOT_CLASS_NAME}__sortingPartition`} />
            <div className={`${ROOT_CLASS_NAME}__sortingBody`}>
                <DropDownMenuItem
                    className={ascendingItemClassName}
                    menuItem={{
                        onClick: () =>
                            onApplySorting(columnId, constants.ascending)
                    }}
                    data-testid={`${ROOT_CLASS_NAME}__sortingMenuOption--ascending`}
                >
                    <div className="sortingMenuTextBox">
                        <Icon
                            sx={{
                                pointer: 'cursor'
                            }}
                            icon="arrowUp"
                            color="galaxy-300"
                            className={`${ROOT_CLASS_NAME}__sortingIcon`}
                            size="m"
                        />
                        <TooltipButton
                            className={`button_clear sortingMenuText
                        `}
                        >
                            {t('ascending')}
                        </TooltipButton>
                    </div>
                    {sortState.isSortAscending && (
                        <Icon
                            sx={{
                                pointer: 'cursor'
                            }}
                            icon="checkmark"
                            color="galaxy-300"
                            className={`${ROOT_CLASS_NAME}__sortingIcon`}
                            size="m"
                        />
                    )}
                </DropDownMenuItem>
                <DropDownMenuItem
                    className={descendingItemClassName}
                    menuItem={{
                        onClick: () =>
                            onApplySorting(columnId, constants.descending)
                    }}
                    data-testid={`${ROOT_CLASS_NAME}__sortingMenuOption--descending`}
                >
                    <div className="sortingMenuTextBox">
                        <Icon
                            sx={{
                                pointer: 'cursor'
                            }}
                            icon="downArrow"
                            color="galaxy-300"
                            className={`${ROOT_CLASS_NAME}__sortingIcon`}
                            size="m"
                        />
                        <TooltipButton
                            className={`button_clear sortingMenuText 
                    `}
                        >
                            {t('descending')}
                        </TooltipButton>
                    </div>
                    {sortState.isSortDescending && (
                        <Icon
                            sx={{
                                pointer: 'cursor'
                            }}
                            icon="checkmark"
                            color="galaxy-300"
                            className={`${ROOT_CLASS_NAME}__sortingIcon`}
                            size="m"
                        />
                    )}
                </DropDownMenuItem>
            </div>

            <hr className={`${ROOT_CLASS_NAME}__sortingPartition`} />
            <div
                className={`_d-flex _ai-center _jc-space-between ${ROOT_CLASS_NAME}__sortingFooter`}
            >
                <TooltipButton
                    className={clearButtonClassName}
                    onClick={onClearSorting}
                    data-testid={`${ROOT_CLASS_NAME}__sortingClear`}
                >
                    {t('clearSorting')}
                </TooltipButton>
            </div>
        </DropDownMenu>
    );
};
