import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import dateUtils from '~/utils/date-utils';
import { ApiAssignment } from '~/api/types';
import { useTaskAssignments } from '~/hooks';
import {
    DateInputs,
    TimeInputs
} from '~/components/TaskManagementPage/TaskDetailDrawer/types';

interface UseRevisedTimeModalContentProps {
    /**
     * The task assignment object
     */
    assignment: ApiAssignment;
    /**
     * Function to hide the modal
     */
    hideModal: () => void;
}

export const useRevisedTimeModalContent = ({
    assignment,
    hideModal
}: UseRevisedTimeModalContentProps) => {
    const {
        id: assignmentId,
        revisedStartServiceAt,
        revisedCompletedAt
    } = assignment || {};
    const { updateRevisedTimes } = useTaskAssignments({ taskId: assignmentId });

    const getDateTimeCustomFormat = (dateTimeObj: {
        toFormat: (value: string) => string;
    }) => ({
        date: dateTimeObj.toFormat('yyyy-MM-dd'),
        time: dateTimeObj.toFormat('HH:mm')
    });

    const getDefaultDateInputs = (dateTimeObj: DateTime) =>
        dateTimeObj.isValid
            ? new Date(getDateTimeCustomFormat(dateTimeObj).date)
            : new Date();

    const getDefaultTimeInputs = (dateTimeObj: DateTime) =>
        dateTimeObj.isValid ? getDateTimeCustomFormat(dateTimeObj).time : '';

    const startDateTimeObj = DateTime.fromISO(revisedStartServiceAt || '');
    const completedDateTimeObj = DateTime.fromISO(revisedCompletedAt || '');

    const defaultDateInputs = {
        arrivalDate: getDefaultDateInputs(startDateTimeObj),
        completedDate: getDefaultDateInputs(completedDateTimeObj)
    };

    const defaultTimeInputs = {
        arrivalTime: getDefaultTimeInputs(startDateTimeObj),
        completedTime: getDefaultTimeInputs(completedDateTimeObj)
    };

    const [revisedDate, setRevisedDate] =
        useState<DateInputs>(defaultDateInputs);
    const [revisedTime, setRevisedTime] =
        useState<TimeInputs>(defaultTimeInputs);

    const handleDateChange = (key: keyof DateInputs, value: Date | null) => {
        setRevisedDate({
            ...revisedDate,
            [key]: value
        });
    };

    const handleTimeChange = (key: keyof TimeInputs, value: string) => {
        setRevisedTime({
            ...revisedTime,
            [key]: value
        });
    };

    const formatDateTime = (date: DateTime, time: string) => {
        return new Date(`${date.toISODate()} ${time}`);
    };

    const isDisabled = useMemo(() => {
        const { arrivalTime, completedTime } = revisedTime;
        const { arrivalDate, completedDate } = revisedDate;

        if (!arrivalTime && !completedTime) {
            return true;
        }

        if (!arrivalTime || !completedTime) return false;

        const arrivalDateTime = formatDateTime(
            DateTime.fromJSDate(arrivalDate),
            arrivalTime
        );
        const completedDateTime = formatDateTime(
            DateTime.fromJSDate(completedDate),
            completedTime
        );

        return arrivalDateTime > completedDateTime;
    }, [revisedTime, revisedDate]);

    const onSave = () => {
        const { arrivalDate, completedDate } = revisedDate;
        const { arrivalTime, completedTime } = revisedTime;

        const convertToISO = (date: Date, time: string) =>
            dateUtils.convert24HourDateTimeToISO(date, time, {
                isConvertToUTC: true
            });

        const revisedStart =
            arrivalTime && convertToISO(arrivalDate, arrivalTime);

        const revisedCompleted =
            completedTime && convertToISO(completedDate, completedTime);

        updateRevisedTimes({
            assignmentId,
            revisedStartServiceAt: revisedStart,
            revisedCompletedAt: revisedCompleted
        });
        hideModal();
    };

    return {
        revisedDate,
        revisedTime,
        handleDateChange,
        handleTimeChange,
        isDisabled,
        onSave
    };
};
