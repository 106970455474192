import React from 'react';

import { useHereMapContext } from '~/components/HereMaps/HereMapProvider';
import { MapZoomControl } from '~/ui/components/MapZoomControl';
import constants from '~/utils/constants';

const {
    mapOptionSettings: { MAX_ZOOM, MIN_ZOOM }
} = constants;

export const ZoomControl = ({ showControls }) => {
    const smallZoomStep = 1;
    const { mapInstance } = useHereMapContext();

    if (!mapInstance) {
        return null;
    }

    const zoomIn = () => {
        const viewModel = mapInstance.getViewModel();
        const { zoom } = viewModel.getLookAtData();
        if (zoom < MAX_ZOOM) {
            mapInstance.setZoom(zoom + smallZoomStep, true);
        }
    };

    const zoomOut = () => {
        const viewModel = mapInstance.getViewModel();
        const { zoom } = viewModel.getLookAtData();
        if (zoom > MIN_ZOOM) {
            mapInstance.setZoom(zoom - smallZoomStep, true);
        }
    };

    if (!showControls) {
        return null;
    }

    return <MapZoomControl zoomIn={zoomIn} zoomOut={zoomOut} />;
};
