import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '~/ui';
import { useLiveDispatchTableData } from '../useLiveDispatchTableData';
import { useEquipmentTableData } from '../useEquipmentTableData';
import {
    selectIsLiveDispatchDrawerExpanded,
    setIsLiveDispatchDrawerExpanded
} from '~/reducers/mapDrawerSettingsSlice';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import './LiveDispatchFooter.scss';
import { useLiveDispatchTabsLogic } from '../useLiveDispatchTabsLogic';
import { useEquipmentTablePagination } from '../useEquipmentTablePagination';
import { useLiveDispatchTablePagination } from '../useLiveDispatchTablePagination';
import { useEquipmentTableContext } from '../EquipmentTableContext';
import { useLiveDispatchTableContext } from '../LiveDispatchTableContext';

export const LiveDispatchTableFooter = () => {
    const isDrawerExpanded = useSelector(selectIsLiveDispatchDrawerExpanded);
    const dispatch = useDispatch();
    const {
        pageIndex: liveDispatchPageIndex,
        pageSize: liveDispatchPageSize,
        totalLiveDispatchCount
    } = useLiveDispatchTableContext();

    const totalLiveDispatchPageCount = Math.floor(
        totalLiveDispatchCount / liveDispatchPageSize
    );

    const calculateRecordRangeForLiveDispatchPage = () => {
        const startRecordLiveDispatch =
            liveDispatchPageIndex * liveDispatchPageSize + 1;
        const endRecordLiveDispatch = Math.min(
            (liveDispatchPageIndex + 1) * liveDispatchPageSize,
            totalLiveDispatchCount
        );
        return [startRecordLiveDispatch, endRecordLiveDispatch];
    };

    const [startRecordLiveDispatch, endRecordLiveDispatch] =
        calculateRecordRangeForLiveDispatchPage();

    const {
        goToFirstPage: goToFirstPageLiveDispatch,
        goToNextPage: goToNextPageLiveDispatch,
        goToPrevPage: goToPrevPageLiveDispatch
    } = useLiveDispatchTablePagination();

    function handleExpandClick() {
        dispatch(setIsLiveDispatchDrawerExpanded(!isDrawerExpanded));
    }
    const {
        goToFirstPage: goToFirstEquipmentPage,
        goToNextPage: goToNextEquipmentPage,
        goToPrevPage: goToPrevEquipmentPage
    } = useEquipmentTablePagination();
    const { t } = useTranslation('liveDispatchTable');
    const { unassignedTasksData, excludedUnassignedTasksData, isFiltered } =
        useLiveDispatchTableData();
    const {
        unselectedEquipmentData,
        selectedEquipmentData,
        isFiltered: equipmentIsFiltered,
        totalPageNumber
    } = useEquipmentTableData();

    const {
        pageIndex: equipmentPageIndex,
        pageSize: equipmentPageSize,
        totalEquipmentCount
    } = useEquipmentTableContext();

    const calculateRecordRangeForPage = () => {
        const startRecord = (equipmentPageIndex - 1) * equipmentPageSize + 1;
        const endRecord = Math.min(
            equipmentPageIndex * equipmentPageSize,
            totalEquipmentCount
        );
        return [startRecord, endRecord];
    };

    const [startRecord, endRecord] = calculateRecordRangeForPage();

    const { isUnassignedTasksTabActive } = useLiveDispatchTabsLogic();

    const EnabledFilter = isUnassignedTasksTabActive
        ? isFiltered
        : equipmentIsFiltered;

    const excludedTasksEquipmentCount = isUnassignedTasksTabActive
        ? excludedUnassignedTasksData.length
        : unselectedEquipmentData.length;

    const totalEquipment =
        unselectedEquipmentData.length + selectedEquipmentData.length;
    const selectedEquipment = selectedEquipmentData.length;

    const tasksEquipmentCount = isUnassignedTasksTabActive
        ? unassignedTasksData.length
        : selectedEquipmentData.length + unselectedEquipmentData.length;

    const tasksTotal = EnabledFilter
        ? tasksEquipmentCount + excludedTasksEquipmentCount
        : tasksEquipmentCount;

    const displayTextCategory = isUnassignedTasksTabActive
        ? 'tasksDisplayStatusText'
        : 'equipmentDisplayStatusText';

    const statusText = isUnassignedTasksTabActive
        ? t(
              `${displayTextCategory}.${
                  EnabledFilter ? 'filtered' : 'unfiltered'
              }`,
              {
                  count: EnabledFilter ? tasksEquipmentCount : undefined,
                  total: tasksTotal
              }
          )
        : t(
              `${displayTextCategory}.${
                  EnabledFilter ? 'filtered' : 'unfiltered'
              }`,
              {
                  count: EnabledFilter ? selectedEquipment : undefined,
                  total: totalEquipment
              }
          );

    return (
        <div
            className="live-dispatch-drawer__footer"
            data-testid="live-dispatch-drawer-footer"
        >
            <div
                className="live-dispatch-drawer__tasks-display-status-text"
                data-testid="tasks-display-status-text"
            >
                {statusText}
            </div>
            {isUnassignedTasksTabActive ? (
                <div
                    className="live-dispatch-drawer__pagination"
                    data-testid="tasks-display-unassigned-tasks-table-footer"
                >
                    <button
                        className="live-dispatch-drawer__pagination-button"
                        type="button"
                        data-testid="live-dispatch-pagination-start"
                        onClick={goToFirstPageLiveDispatch}
                        disabled={liveDispatchPageIndex === 0 ?? true}
                    >
                        <div className="_ai-center _jc-center">
                            <div className="expandicon">
                                <Icon
                                    icon="paginationStart"
                                    color={
                                        liveDispatchPageIndex === 0
                                            ? 'galaxy-400'
                                            : 'galaxy-800'
                                    }
                                    size={IconSizes.L}
                                />
                            </div>
                        </div>
                    </button>
                    <button
                        className="live-dispatch-drawer__pagination-button-center"
                        type="button"
                        data-testid="live-dispatch-pagination-left"
                        onClick={goToPrevPageLiveDispatch}
                        disabled={liveDispatchPageIndex === 0 ?? true}
                    >
                        <div className="_ai-center _jc-center">
                            <div className="expandicon">
                                <Icon
                                    icon="paginationLeft"
                                    color={
                                        liveDispatchPageIndex === 0
                                            ? 'galaxy-400'
                                            : 'galaxy-800'
                                    }
                                    size={IconSizes.L}
                                />
                            </div>
                        </div>
                    </button>
                    <button
                        className="live-dispatch-drawer__pagination-button-center"
                        type="button"
                        data-testid="live-dispatch-pagination-right"
                        onClick={goToNextPageLiveDispatch}
                        disabled={
                            totalLiveDispatchPageCount <=
                                liveDispatchPageIndex ?? true
                        }
                    >
                        <div className="_ai-center _jc-center">
                            <div className="expandicon">
                                <Icon
                                    icon="paginationRight"
                                    color={
                                        totalLiveDispatchPageCount <=
                                        liveDispatchPageIndex
                                            ? 'galaxy-400'
                                            : 'galaxy-800'
                                    }
                                    size={IconSizes.L}
                                />
                            </div>
                        </div>
                    </button>
                    <span
                        className="live-dispatch-drawer__pagination-text"
                        data-testid="pagination-display-status-text"
                    >
                        {t('pagination.statusText', {
                            start: startRecordLiveDispatch,
                            end: endRecordLiveDispatch,
                            total: totalLiveDispatchCount
                        })}
                    </span>
                </div>
            ) : (
                <div
                    className="live-dispatch-drawer__pagination"
                    data-testid="tasks-display-equipment-table-footer"
                >
                    <button
                        className="live-dispatch-drawer__pagination-button"
                        type="button"
                        data-testid="live-dispatch-pagination-start"
                        onClick={goToFirstEquipmentPage}
                        disabled={equipmentPageIndex === 1}
                    >
                        <div className="_ai-center _jc-center">
                            <div className="expandicon">
                                <Icon
                                    icon="paginationStart"
                                    color={
                                        equipmentPageIndex === 1
                                            ? 'galaxy-400'
                                            : 'galaxy-800'
                                    }
                                    size={IconSizes.L}
                                />
                            </div>
                        </div>
                    </button>
                    <button
                        className="live-dispatch-drawer__pagination-button-center"
                        type="button"
                        data-testid="live-dispatch-pagination-left"
                        onClick={goToPrevEquipmentPage}
                        disabled={equipmentPageIndex === 1}
                    >
                        <div className="_ai-center _jc-center">
                            <div className="expandicon">
                                <Icon
                                    icon="paginationLeft"
                                    color={
                                        equipmentPageIndex === 1
                                            ? 'galaxy-400'
                                            : 'galaxy-800'
                                    }
                                    size={IconSizes.L}
                                />
                            </div>
                        </div>
                    </button>
                    <button
                        className="live-dispatch-drawer__pagination-button-center"
                        type="button"
                        data-testid="live-dispatch-pagination-right"
                        onClick={goToNextEquipmentPage}
                        disabled={equipmentPageIndex === totalPageNumber}
                    >
                        <div className="_ai-center _jc-center">
                            <div className="expandicon">
                                <Icon
                                    icon="paginationRight"
                                    color={
                                        equipmentPageIndex === totalPageNumber
                                            ? 'galaxy-400'
                                            : 'galaxy-800'
                                    }
                                    size={IconSizes.L}
                                />
                            </div>
                        </div>
                    </button>
                    <span
                        className="live-dispatch-drawer__pagination-text"
                        data-testid="pagination-display-status-text"
                    >
                        {t('pagination.statusText', {
                            start: startRecord,
                            end: endRecord,
                            total: totalEquipmentCount
                        })}
                    </span>
                </div>
            )}

            <button
                className="live-dispatch-drawer__expand-button"
                type="button"
                onClick={handleExpandClick}
                onKeyDown={handleExpandClick}
                data-testid="expand-live-dispatch-button"
            >
                <div className="_ai-center _jc-center">
                    <div className="expandicon">
                        <Icon
                            icon={
                                !isDrawerExpanded ? 'chevronDown' : 'chevronUp'
                            }
                            color="galaxy-500"
                            size={IconSizes.M}
                        />
                    </div>
                    <span className="live-dispatch-drawer__expand-button-text">
                        {!isDrawerExpanded
                            ? t('button.fullScreen')
                            : t('button.halfScreen')}
                    </span>
                </div>
            </button>
        </div>
    );
};
