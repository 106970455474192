import { useCallback } from 'react';
import { usePlanStopClickHandler } from '../../MapPageDrawers/SelectedCardsDrawer/SelectedCardStop/usePlanStopClickHandler';
import { useIsPlanStopSelected } from '~/ui/components/StopMarker/useIsPlanStopSelected';
import { PlanStop } from '~/data-classes';

export const useStopMarkerSelect = () => {
    const { planStopClickHandler: handleStopMarkerClick } =
        usePlanStopClickHandler();
    const { isPlanStopSelected } = useIsPlanStopSelected();

    const toggleIsSelected = useCallback(
        (stopData: PlanStop) => {
            const { clientRouteTaskId } = stopData;
            const isStopSelected = isPlanStopSelected(clientRouteTaskId);
            handleStopMarkerClick({
                clientRouteTaskId,
                isSelected: isStopSelected
            });
        },
        [isPlanStopSelected, handleStopMarkerClick]
    );
    return {
        toggleIsSelected
    };
};
