import React from 'react';

import { Icon } from '~/ui';
import DropDownMenu from '~/ui/components/DropDownMenu/DropDownMenu';

import './TaskActionButton.scss';

const TaskActionButtonDropdown = React.forwardRef(
    function TaskActionButtonDropdown(
        { title, icon = '', isDisplayed, isOpen, toggle, close, children },
        ref
    ) {
        if (!isDisplayed) {
            return null;
        }

        const CustomDropdownButton = () => {
            return (
                <div className="_d-flex _ai-center taskactionbutton">
                    {icon && (
                        <Icon
                            icon={icon}
                            color="comet"
                            className="taskactionbutton-icon"
                        />
                    )}
                    <span>{title}</span>
                    <Icon
                        icon="chevronDown"
                        color="comet"
                        className="taskactionbutton-chevron"
                    />
                </div>
            );
        };

        return (
            <DropDownMenu
                ref={ref}
                customDropDownButton={CustomDropdownButton}
                isOpen={isOpen}
                toggle={toggle}
                close={close}
                styles={{
                    innerContainer: '_m-0',
                    menuItems: 'taskactionbutton-menuitems'
                }}
            >
                {children}
            </DropDownMenu>
        );
    }
);

export default TaskActionButtonDropdown;
