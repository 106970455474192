import { ApiEquipmentAttributes } from '~/api/types';
import {
    useGetEquipmentAttributes,
    UseGetRoutePlansReactQueryOptions
} from '~/hooks/useGetEquipmentAttributes';
import { useEquipmentTableStates } from '../useEquipmentTableStates';

/**
 * The time in minutes after data is considered stale
 *
 * When stale, fresh data can be retrieved from the API on next render
 */
const STALE_TIME_MINUTES = 1;

export const useEquipmentTableColumnFilterValues = () => {
    const { setAttributes } = useEquipmentTableStates();

    const onSuccess = (data: ApiEquipmentAttributes) => {
        setAttributes(data);
    };

    const reactQueryOptions: UseGetRoutePlansReactQueryOptions = {
        staleTime: STALE_TIME_MINUTES * (60 * 1000),
        onSuccess
    };

    return useGetEquipmentAttributes({
        reactQueryOptions
    });
};
