import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { resetSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';
import { selectTasks } from '~/reducers/tasksSlice';
import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';

import DispatchTasksControl from './DispatchTasksControl';
import MultiTasksControl from './MultiTasksControl';
import PairTasksControl from './PairTasksControl';
import SingleTasksControl from './SingleTasksControl';
import { ManageStopControl } from '~/ui';

import './unassigned-tasks-control.scss';
import { mapPlanStopUtils } from '~/utils/map';

function UnassignedTasksControl() {
    const [shouldRender, setShouldRender] = useState(false);
    const [showSelectTasksControl, setShowSelectTasksControl] = useState(true);
    const [showDispatchTasksControl, setShowDispatchTasksControl] =
        useState(false);
    const [showPairTasksControl, setShowPairTasksControl] = useState(false);
    const [isMultiTasksSelected, setIsMultiTasksSelected] = useState(false);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const tasksData = useSelector(selectTasks);
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    useEffect(() => {
        // reset back to main control when task selections change
        _resetControlState();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedMapStops]);

    useEffect(() => {
        const selectedTasksData = mapPlanStopUtils.mapIdtoTasksData(
            selectedMapStops,
            tasksData
        );
        setIsMultiTasksSelected(selectedTasksData.length > 1);
        setSelectedTasks(selectedTasksData);
        setShouldRender(
            selectedTasksData.length > 0 &&
                Boolean(selectedTasksData[0]?.apiTask)
        );
    }, [selectedMapStops, tasksData]);

    useEffect(() => {
        // when task selections changed while open,
        // reset `RouteDrawer` selections
        if (shouldRender) {
            dispatch(resetSelectedMapRoutes());
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [shouldRender, selectedTasks]);

    function _handleClickDeselect() {
        dispatch(resetSelectedMapStops());
    }

    function _handleClickDispatch() {
        setShowSelectTasksControl(false);
        setShowDispatchTasksControl(true);
        setShowPairTasksControl(false);
    }

    function _handleClickPair() {
        setShowSelectTasksControl(false);
        setShowDispatchTasksControl(false);
        setShowPairTasksControl(true);
    }

    function _resetControlState() {
        setShowSelectTasksControl(true);
        setShowDispatchTasksControl(false);
        setShowPairTasksControl(false);
        dispatch(resetSelectedTaskIds());
    }

    return (
        shouldRender && (
            <ManageStopControl
                className="unassigned-tasks-control"
                data-testid="unassigned-tasks-control"
            >
                {showSelectTasksControl && (
                    <>
                        {isMultiTasksSelected ? (
                            <MultiTasksControl
                                selectedTasks={selectedTasks}
                                onClickDispatchButton={_handleClickDispatch}
                                onClickPairButton={_handleClickPair}
                                footerButtonTitle={t(
                                    'UnassignedTasksControl.button.deselectAll'
                                )}
                                footerButtonIcon="iconClose"
                                onClickFooterButton={_handleClickDeselect}
                            />
                        ) : (
                            <SingleTasksControl
                                taskData={selectedTasks[0]}
                                onClickDispatchButton={_handleClickDispatch}
                                onClickPairButton={_handleClickPair}
                                footerButtonTitle={t(
                                    'UnassignedTasksControl.button.deselect'
                                )}
                                footerButtonIcon="iconClose"
                                onClickFooterButton={_handleClickDeselect}
                            />
                        )}
                    </>
                )}
                {showDispatchTasksControl && (
                    <DispatchTasksControl
                        selectedTaskIds={selectedTasks.map((task) => task.id)}
                        onClickTitle={_resetControlState}
                        modalTitle={t(
                            'UnassignedTasksControl.title.dispatch_stops',
                            { count: selectedTasks.length }
                        )}
                    />
                )}
                {showPairTasksControl && (
                    <PairTasksControl
                        selectedTaskIds={selectedTasks.map((task) => task.id)}
                        onClickTitle={_resetControlState}
                        modalTitle={t(
                            'UnassignedTasksControl.title.pair_tasks',
                            { count: selectedTasks.length }
                        )}
                    />
                )}
            </ManageStopControl>
        )
    );
}

export default UnassignedTasksControl;
