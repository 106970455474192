import React from 'react';
import { LiveDispatchTableData } from '../LiveDispatchTableData';
import { LiveDispatchTableDataProps } from '../../types';

export const LiveDispatchTableDataDate = ({
    data,
    className,
    'data-testid': dataTestId
}: LiveDispatchTableDataProps<string>) => {
    return (
        <LiveDispatchTableData
            data={data}
            className={className}
            data-testid={dataTestId}
        />
    );
};
