import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMapSettings,
    setMapRouteMode
} from '~/reducers/mapSettingsSlice';
import { MapRouteModes, useMapUtils } from '~/hooks/useMapUtils';
import { selectIsRoutePlannerDisabled } from '~/reducers/mainClientSlice';

export const useMapSettings = () => {
    const dispatch = useDispatch();
    const mapSettings = useSelector(selectMapSettings);
    const hidePlannedTab = useSelector(selectIsRoutePlannerDisabled);
    const { isPreRoutePlanningMode } = useMapUtils();

    useEffect(() => {
        if (hidePlannedTab && isPreRoutePlanningMode) {
            dispatch(setMapRouteMode(MapRouteModes.DISPATCHED));
        }
    }, [hidePlannedTab, isPreRoutePlanningMode, dispatch]);

    return {
        mapSettings
    };
};
