import { useCallback } from 'react';
import { useLiveDispatchTableContext } from '../LiveDispatchTableContext';

export const useLiveDispatchTablePagination = () => {
    const { setApiPaginationOptions, setPageIndex, pageIndex, pageSize } =
        useLiveDispatchTableContext();

    const goToFirstPage = useCallback(() => {
        setPageIndex(0);
        setApiPaginationOptions({
            limit: pageSize,
            page: 0
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [setApiPaginationOptions, setPageIndex]);

    const goToNextPage = useCallback(() => {
        setPageIndex(pageIndex + 1);
        setApiPaginationOptions({
            limit: pageSize,
            page: pageIndex + 1
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [pageIndex, setApiPaginationOptions, setPageIndex]);

    const goToPrevPage = useCallback(() => {
        setPageIndex(pageIndex - 1);
        setApiPaginationOptions({
            limit: pageSize,
            page: pageIndex - 1
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [setApiPaginationOptions, pageIndex, setPageIndex]);

    return {
        goToFirstPage,
        goToNextPage,
        goToPrevPage
    };
};
