import { CognitoUserPool } from 'amazon-cognito-identity-js';
import constants from '~/utils/constants';
import authService from '~/auth/aws-cognito-auth-service';
import clientsAPI from '~/api/ClientsApi';
import socketInstance from '~/utils/socket/socket-instance';

const getMcwAppInitFromLocalStorage = () => {
    const item = localStorage.getItem(constants.localStorageKeys.MCW_APP_INIT);
    return item ? JSON.parse(item) : null;
};

export const refreshSessionToken = async (poolData: CognitoUserPool) => {
    await authService.isSessionExpiring(poolData);
    const mcwAppInit = getMcwAppInitFromLocalStorage();

    if (!mcwAppInit) return;

    const clientsResponse = await clientsAPI.get();
    const clientIds = clientsResponse.data.data.map(({ id }) => id);

    socketInstance.reconnectToSocketServer({
        clientIds,
        accessToken: mcwAppInit.requestHeaders.authorization,
        socketUrl: mcwAppInit.data.socketUrl
    });
};
