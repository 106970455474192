import React from 'react';
import { IconButton } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

interface EditableTextActionsProps {
    isDisableButtons?: boolean;
    onClickCancel: () => void;
    onClickCheck: () => void;
}

export const EditableTextActions = ({
    isDisableButtons,
    onClickCancel,
    onClickCheck
}: EditableTextActionsProps) => {
    const rootClassName = 'editable-text-actions';
    const iconButtonClassName = 'editabletext-button';

    return (
        <div className={`${rootClassName} _d-flex _jc-flex-end _mb-1`}>
            <div className="_d-inline _ml-2">
                <IconButton
                    className={iconButtonClassName}
                    icon="cancel"
                    iconSize={IconSizes.S}
                    iconColor="meteor"
                    onClick={onClickCancel}
                    disabled={isDisableButtons}
                />
            </div>
            <div className="_d-inline _ml-2">
                <IconButton
                    className={iconButtonClassName}
                    icon="checkLine"
                    iconSize={IconSizes.S}
                    onClick={onClickCheck}
                    disabled={isDisableButtons}
                />
            </div>
        </div>
    );
};
