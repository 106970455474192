import React from 'react';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import { LiveDispatchTaskTableRowData } from '~/data-classes';
import { TableHeaderRowProps } from '../types';
import './LiveDispatchTableHeaderRow.scss';

const ROOT_CLASS_NAME = 'live-dispatch-table__table-header-cell';

export const LiveDispatchTableHeaderRow = ({
    headerGroup,
    hasSelectedRows
}: TableHeaderRowProps<LiveDispatchTaskTableRowData>) => {
    const elementClassName = classNames(ROOT_CLASS_NAME, {
        [`${ROOT_CLASS_NAME}--has-selected`]: hasSelectedRows
    });

    return (
        <tr role="row">
            <th role="columnheader" className={elementClassName}>
                &nbsp;
            </th>
            {headerGroup.headers.map((header) => (
                <th
                    key={header.id}
                    role="columnheader"
                    data-testid={header.id}
                    className={elementClassName}
                >
                    {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                    )}
                </th>
            ))}
        </tr>
    );
};
