import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    setIsOpenDispatchManagementPopup,
    setIsOpenLiveDispatchDrawer,
    setIsOpenPairEquipmentControlPopup,
    setIsOpenUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import {
    resetSelectedTaskIds,
    selectSelectedTaskIds
} from '~/reducers/selectedTaskIdsSlice';
import { selectSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';

import { TaskActionButton } from '~/components/TaskManagementPage/TaskActionBar/TaskActionButton';

import { LiveDispatchTableHeaderRow } from '../LiveDispatchTableHeaderRow';
import { LiveDispatchTableRow } from '../LiveDispatchTableRow';
import { LiveDispatchTableSelectedToolbar } from '../LiveDispatchTableSelectedToolbar';
import { useLiveDispatchTableConfig } from '../useLiveDispatchTableConfig';
import { SelectedToolbarPairDropdownButton } from '../SelectedToolbarPairDropdownButton';
import { useLiveDispatchTableContext } from '~/components/MapPageDrawers/LiveDispatchDrawer/LiveDispatchTableContext';
import { ApiTask, TaskStatus } from '~/api/types';
import { useGetTasks, useTaskAvailableActions, useTaskSplit } from '~/hooks';
import { selectSortConfig } from '~/reducers/liveDispatchTableSlice';

import './LiveDispatchTable.scss';

export const LiveDispatchTable = () => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { apiPaginationOptions } = useLiveDispatchTableContext();

    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);
    const sortConfig = useSelector(selectSortConfig);

    const dispatch = useDispatch();

    const { isFiltered, headerGroups, allRows, allSelectedRows } =
        useLiveDispatchTableConfig();

    const { data: apiTasksData, refetch } = useGetTasks({
        apiOptions: {
            status: [TaskStatus.UNASSIGNED],
            ...apiPaginationOptions,
            sort: sortConfig
        }
    });

    const {
        splitTasks,
        onErrorSplitTasks,
        onSettledSplitTasks,
        onSuccessSplitTasks
    } = useTaskSplit();

    const allTasks = useMemo<Record<string, ApiTask>>(() => {
        const taskEntries = apiTasksData?.data.map((task: ApiTask) => {
            return [task.id, task];
        });
        return Object.fromEntries(taskEntries || []);
    }, [apiTasksData]);

    const isDisplayed = useTaskAvailableActions({ allTasks });

    const isSelectedTaskIdsEmpty = !selectedTaskIds.length;
    const isSelectedEquipmentIdsEmpty = !selectedEquipmentIds.length;

    useEffect(() => {
        if (isSelectedTaskIdsEmpty && isSelectedEquipmentIdsEmpty) {
            dispatch(setIsOpenPairEquipmentControlPopup(false));
        }
    }, [dispatch, isSelectedTaskIdsEmpty, isSelectedEquipmentIdsEmpty]);

    const deselectAllTasks = () => {
        dispatch(resetSelectedTaskIds(null));
    };

    const onSplitTaskClick = async () => {
        await splitTasks(selectedTaskIds, {
            onSuccess: onSuccessSplitTasks,
            onError: onErrorSplitTasks,
            onSettled: () => {
                onSettledSplitTasks();
                refetch();
            }
        });
    };

    const handleManualAssignButtonClick = () => {
        dispatch(setIsOpenUnassignedTasksDrawer(false));
        dispatch(setIsOpenLiveDispatchDrawer(false));
        dispatch(setIsOpenDispatchManagementPopup(true));
    };

    const onViewDetailsClick = () => {
        dispatch(setSelectedTaskRowId(selectedTaskIds[0]));
    };

    return (
        <div
            className="live-dispatch-table _fd-column"
            data-testid="live-dispatch-table"
        >
            {isFiltered && (
                <LiveDispatchTableSelectedToolbar>
                    <div className="_d-flex _ai-center taskactionbar-buttons">
                        <TaskActionButton
                            title={t('selectedToolbar.assign')}
                            icon="iconRouteFill"
                            onClick={handleManualAssignButtonClick}
                            isDisplayed
                        />
                        <SelectedToolbarPairDropdownButton
                            isDisplayed={isDisplayed}
                        />
                        <TaskActionButton
                            title={t(
                                'taskManagement:taskActionBar.viewDetails'
                            )}
                            icon="stopDetails"
                            isDisplayed={isDisplayed.viewDetails}
                            onClick={onViewDetailsClick}
                        />
                        <TaskActionButton
                            title={t('selectedToolbar.split')}
                            icon="delink"
                            isDisplayed={isDisplayed.split}
                            onClick={onSplitTaskClick}
                        />
                        <div className="vertical-line" />
                        <TaskActionButton
                            title={t('selectedToolbar.deselectAll')}
                            onClick={deselectAllTasks}
                            isDisplayed
                        />
                    </div>
                </LiveDispatchTableSelectedToolbar>
            )}
            <div className="live-dispatch-table__wrapper _fd-column">
                <table
                    role="table"
                    className="live-dispatch-table__table live-dispatch-table__table--stickied"
                    data-testid="live-dispatch-table__sticky-table"
                >
                    <thead
                        className="live-dispatch-table__table-header _header-4-alt"
                        data-testid="live-dispatch-table__thead"
                    >
                        {headerGroups.map((headerGroup) => (
                            <LiveDispatchTableHeaderRow
                                key={headerGroup.id}
                                headerGroup={headerGroup}
                                hasSelectedRows={isFiltered}
                            />
                        ))}
                    </thead>
                    <tbody>
                        {allSelectedRows.map((row) => (
                            <LiveDispatchTableRow key={row.id} row={row} />
                        ))}
                    </tbody>
                </table>
                <table
                    role="table"
                    className="live-dispatch-table__table table"
                    data-testid="live-dispatch-table__table"
                >
                    <tbody>
                        {allRows.map((row) => (
                            <LiveDispatchTableRow key={row.id} row={row} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
