import { AssignmentDelay, AssignmentStatus } from './ApiAssignment';
import { ApiLiveStop } from './ApiStop';
import { TaskPriority, TaskTypes } from './ApiTask';
import { ApiVehicle, VehicleType } from './ApiVehicle';
import { Coordinates, Contact, TimeWindow, Profile } from './CommonTypes';
import { PaginationMetadata } from './Pagination';

/* eslint-disable camelcase */

/**
 * Enum of supported driver device types
 * @category API Types
 */
export enum DriverDeviceType {
    IOS = 'ios',
    ANDROID = 'android'
}

/**
 * Expected driver assignment props time window structure
 * @category API
 */
export interface DriverAssignmentTimeWindow {
    /**
     * Start of time window, in milliseconds.
     */
    start: number;
    /**
     * End of time window, in milliseconds.
     */
    end: number;
}

/**
 * Expected driver assignment props structure
 * @category API
 */
export interface DriverAssignmentProps {
    /**
     * the driver assignment task priority
     */
    priority: TaskPriority;
    /**
     * the driver assignment service time
     */
    serviceTime: number;
    /**
     * the driver assignment unit-less task size
     */
    size: number;
    /**
     * the driver assignment unit-less task weight
     */
    weight: number;
    /**
     * the driver assignment time window
     */
    window: DriverAssignmentTimeWindow[];
}

/**
 * Expected driver assignment structure
 * @category API
 */
export interface DriverAssignment {
    /**
     * the driver ID
     */
    driver: string;
    /**
     * the driver assignment ID
     */
    id: string;
    /**
     * the driver assignment location
     */
    location: Coordinates;
    /**
     * the driver assignment properties
     */
    props: DriverAssignmentProps;
    /**
     * the driver assignment task ID
     */
    task: string;
    /**
     * the driver assignment task type
     */
    type: TaskTypes;
}

/**
 * Expected driver schedule structure
 * @category API
 */
export interface DriverSchedule {
    /**
     * the driver schedule assignment ID
     */
    assignment: string;
    /**
     * the driver schedule assignment ETA
     */
    eta: string;
    /**
     * the driver schedule assignment delay code
     */
    delay: AssignmentDelay;
    /**
     * the driver schedule assignment status code
     */
    status: AssignmentStatus;
}

/**
 * Expected driver schedule structure
 * @category API
 */
export interface DriverShiftTimes {
    /**
     * the driver start shift time as ISO-8601 time (ex. 08:00, 15:00)
     */
    start: string;
    /**
     * the driver shift duration as ISO-8601 duration (ex. PT8H, PT6H30M)
     */
    duration: string;
}

/**
 * Expected driver props structure
 * @category API
 */
export interface DriverProps {
    /**
     * the driver shift time
     */
    shiftTime: TimeWindow[];
    /**
     * the driver shift times
     */
    shiftTimes: Record<string, DriverShiftTimes[]>;
    /**
     * the driver shift exceptions
     */
    shiftExceptions: Record<string, unknown>;
}

/**
 * Expected driver location structure
 * @category API
 */
export interface DriverLocationUpdate extends Coordinates {
    /**
     * the lastest time driver coordinates were updated
     */
    serverTime: string;
    /**
     * the driver direction in angular degrees
     */
    direction?: number | null;
}

/**
 * Type driver fields
 * @category API Types
 */
export interface ApiDriver {
    /**
     * the driver ID
     */
    id?: string;
    /**
     * the driver password
     */
    password?: string;
    /**
     * the driver contact information
     */
    profile?: Contact;
    /**
     * the driver phone number
     */
    telephone?: string;
    /**
     * the driver first name
     */
    firstname?: string;
    /**
     * the driver last name
     */
    lastname?: string;
    /**
     * the driver vehicle ID or vehicle object
     */
    vehicle?: string | ApiVehicle;
    /**
     * the driver properties
     */
    props?: DriverProps;
    /**
     * the driver EID
     */
    eid?: string;
    /**
     * indicates whether the driver is activated
     */
    activated?: boolean;
    /**
     * the driver device type
     */
    deviceType?: DriverDeviceType;
    /**
     * the driver client ID
     */
    client?: string;
    /**
     * the driver default vehicle ID
     */
    defaultVehicle?: string;
    /**
     * the driver ID, alias of `id`
     */
    driver?: string;
    /**
     * the driver current location
     */
    location?: Coordinates;
    /**
     * the driver assignments
     *
     * the values are not sorted in any particular order. Refer to `schedule` for the actual order of assignment stops.
     */
    assignments?: DriverAssignment[];
    /**
     * the driver schedule
     *
     * the values are sorted according to scheduled assignment stops. Refer to `assignments` for additional assignment detail.
     */
    schedule?: DriverSchedule[];
    /**
     * the driver last location update date/time
     */
    lastLocationUpdate?: string;
}

/**
 * Type web driver fields
 * @category API Types
 */
export interface ApiWebDriver extends ApiDriver {
    display?: {
        color?: string;
        text?: string;
        css?: string;
        zoom?: string;
        cssbdr?: string;
    };
    routeId?: string;
}

/**
 * Type driver location update
 * @category API Types
 */
export type ApiDriverLocationUpdate = Record<string, DriverLocationUpdate[]>;

/**
 * Type live driver fields
 * @category API Types
 */
export interface ApiLiveDriver {
    /**
     * the driver ID
     */
    id: string;
    /**
     * the client ID
     */
    clientId: string;
    /**
     * the driver profile
     */
    profile: Profile;
    /**
     * the driver's route name
     */
    routeName: string;
    /**
     * the driver stats
     *
     * this object contains various metric about the driver's route and activity
     */
    stats: {
        /**
         * the current stop being serviced in the driver schedule
         */
        currentStop: number;
        /**
         * the total number of stops in the driver route
         */
        numStops: number;
        /**
         * The estimated time remaining to complete the stop
         */
        timeRemaining?: number;
        /**
         * whether the driver is currently active and online
         */
        active: boolean;
        /**
         * the total number of inventory items in the driver route
         */
        numInventoryItems: number;
        /**
         * the total number of confirmed inventory items in the driver route
         */
        numConfirmedInventoryItems: number;
        /**
         * the total number of inventory exceptions in the driver route
         */
        numInventoryExceptions: number;
        /**
         * the total number of at risk exceptions in the driver route
         */
        numAtRiskExceptions: number;
        /**
         * the total number of late exceptions in the driver route
         */
        numLateExceptions: number;
        /**
         * whether the driver route is complete
         *
         * this boolean, however, does not account for `canceled` stops in the schedule
         */
        isDriverComplete: boolean;
        /**
         * the total distance
         */
        distance?: number;
        /**
         * whether the driver is ending their shift
         *
         * the driver schedule can have a assignment that is flagged with `lastStopInShift`.
         * this prop indicates that the driver schedule has a stop flagged with it.
         */
        isEndingShift?: boolean;
    };
    /**
     * the driver's currently assigned vehicle
     */
    vehicle: {
        /**
         * the vehicle ID
         */
        id: string;
        /**
         * the vehicle type
         */
        type: VehicleType;
        /**
         * the vehicle max weight capacity
         */
        maxWeight: number;
        /**
         * the vehicle max volume capacity
         */
        maxVolume: number;
        /**
         * the vehicle stats
         *
         * this object contains additional metric about the assigned vehicle
         */
        stats: {
            /**
             * the vehicle volume capacity used
             */
            volumeCapacityUsed: number;
            /**
             * the vehicle weight capacity used
             */
            weightCapacityUsed: number;
        };
    };
    /**
     * the driver's latest location update
     */
    latestLocationUpdate: DriverLocationUpdate | null;
    /**
     * the driver's last location update, as ISO 8601 date-time string
     */
    lastLocationUpdate: string;
    /**
     * the current driver location coordinates, according to WISE CEP
     */
    cepLocation: Coordinates;
    /**
     * the route center coordinates
     */
    routeCenter: Coordinates | null;
    /**
     * the driver schedule
     */
    schedule: ApiLiveStop[];
}

interface Stop {
    /**
     * The delay at the stop
     */
    delay?: number;
    /**
     * The stop delay flag
     */
    delayFlag?: number;
    /**
     * The stop departure time
     */
    departureTime?: number;
    /**
     * The distance from the previous stop
     */
    distanceFromPrevious?: number;
    /**
     * The distance to the next stop
     */
    distanceToNext?: number;
    /**
     * Indicates if there is hard time window violation
     */
    hardTWViolated?: boolean;
    /**
     * The ID of the stop
     */
    id: string;
    /**
     * The coordinates of the stop
     */
    location?: Coordinates;
    /**
     * The start service time
     */
    startServiceTime?: number;
    /**
     * The task associated with the stop
     */
    task?: string;
    /**
     * The travel time from the previous stop
     */
    travelTimeFromPrevious?: number;
    /**
     * The travel time to the next stop
     */
    travelTimeToNext?: number;
    /**
     * Indicates if there is time window violation
     */
    violatedTW?: boolean;
    /**
     * The waiting time at the stop
     */
    waitingTime?: number;
    /**
     * The delivery time at the stop
     */
    deliveryTime?: number;
    /**
     * The ID of the depot associated with the stop
     */
    depotId?: string;
    /**
     * The pickup time at the stop
     */
    pickupTime?: number;
    /**
     * The type of the stop
     */
    stopType?: string;
}

export interface DriverReportResponse {
    /**
     * Response metadata
     */
    meta: PaginationMetadata;
    /**
     * Response data
     */
    data: DriverReportData;
}

export interface DriverReportData {
    /**
     * The planned driver report
     */
    planned: DriverReport;
    /**
     * The projected driver report
     */
    projected: DriverReport;
    /**
     * The current driver report
     */
    current: DriverReport;
    /**
     * The route date
     */
    route_date: string;
}

/**
 * Represents a driver report.
 */
export interface DriverReport {
    /**
     * The travel time of the driver, expressed in seconds
     */
    travel_time: number;
    /**
     * The service time of the driver, expressed in seconds
     */
    service_time: number;
    /**
     * The waiting time of the driver, expressed in seconds
     */
    waiting_time: number;
    /**
     * The distance covered by the driver
     */
    distance: number;
    /**
     * The number of delays experienced by the driver
     */
    delays: number;
    /**
     * The total number of stops
     */
    num_stops: number;
    /**
     * The list of stops
     */
    stops: Stop[];
}
