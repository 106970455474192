import { useTranslation } from 'react-i18next';
import { TimeWindow } from '~/api/types';
import { Task } from '~/data-classes';
import dateUtils from '~/utils/date-utils';
import localizationUtils from '~/utils/localization-utils';

export const usePopupTaskCard = (task: Task) => {
    const { t } = useTranslation(['liveDispatchTable']);

    const {
        name,
        pickupTask,
        deliveryTask,
        pickupLocationAddress,
        deliveryLocationAddress
    } = task;

    const locationAddress =
        pickupLocationAddress || deliveryLocationAddress || '';
    const locationAddressText =
        localizationUtils.formatAddress(locationAddress);

    const {
        props: { pickupServiceTime, pickupWindow }
    } = pickupTask || { props: {} };

    const {
        props: { deliveryServiceTime, deliveryWindow }
    } = deliveryTask || { props: {} };

    const serviceTime = pickupServiceTime || deliveryServiceTime || '';
    const { hours, minutes, seconds } =
        dateUtils.convertISOStringToDurationObjectUnits(serviceTime);
    const serviceTimeHours = hours
        ? t('dispatchPopup.taskCard.serviceTimeHours', { hours })
        : '';
    const serviceTimeMinutes = minutes
        ? t('dispatchPopup.taskCard.serviceTimeMinutes', { minutes })
        : '';
    const serviceTimeSeconds = seconds
        ? t('dispatchPopup.taskCard.serviceTimeSeconds', { seconds })
        : '';

    const serviceTimeText = [
        serviceTimeHours,
        serviceTimeMinutes,
        serviceTimeSeconds
    ];

    const [firstTimeWindow] = pickupWindow || deliveryWindow || [];

    const timeWindowToString = (timeWindow: TimeWindow) => {
        const { startTime, endTime } =
            dateUtils.convertTimeWindowToStringFormat(timeWindow);
        return t('timeWindow.range', { startTime, endTime });
    };

    const timeWindowText = timeWindowToString(firstTimeWindow);

    return {
        name,
        locationAddressText,
        serviceTimeText,
        timeWindowText
    };
};
