import { useMemo } from 'react';
import * as Yup from 'yup';
import { FullAddressWithLocation } from '~/api/types';

type ReturnProps = {
    initialValues: Record<string, unknown>;
    validationSchema: unknown;
};

export const useAddressFormik = (
    address?: FullAddressWithLocation
): ReturnProps => {
    const initialValues = useMemo(() => {
        return {
            name: address?.name || '',
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            city: address?.city || '',
            state: address?.state || '',
            zipcode: address?.zipcode || '',
            country: address?.country || '',
            longitude: address?.location?.lng.toString() || '',
            latitude: address?.location?.lat.toString() || ''
        };
    }, [address]);

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            name: Yup.string().required(),
            addressLine1: Yup.string().required(),
            city: Yup.string().required(),
            state: Yup.string().required(),
            zipcode: Yup.string().required()
        });
    }, []);

    return { initialValues, validationSchema };
};
