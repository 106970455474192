import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '~/ui';
import { LiveDispatchTableDataProps } from '../../types';
import './LiveDispatchTableDataLinks.scss';

export const LiveDispatchTableDataLinks = ({
    data,
    className,
    'data-testid': dataTestId
}: LiveDispatchTableDataProps<string[]>) => {
    const { t } = useTranslation('liveDispatchTable');
    const links = useMemo(() => {
        const linksData = data || [];
        return linksData.map((link, idx) => ({
            key: `link-${idx}`,
            link
        }));
    }, [data]);

    return (
        <div className={className} data-testid={dataTestId}>
            {links.map(({ key, link }, index) => (
                <Tooltip content={link} placement="top" key={key}>
                    <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        className="live-dispatch-table-row__data-link"
                        data-testid="live-dispatch-table-row__data-link"
                    >
                        {t('link', { index: index + 1 })}
                    </a>
                </Tooltip>
            ))}
        </div>
    );
};
