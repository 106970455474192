import { Coordinates } from '~/api/types/CommonTypes';

const MIN_POINTS = 2;

export const getBoundsFromCoordinates = (coordinates: Coordinates[]) => {
    if (coordinates?.length < MIN_POINTS) {
        return null;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const linestring = new H.geo.LineString();
    coordinates.forEach((point) => {
        linestring.pushPoint(point);
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const polyline = new H.map.Polyline(linestring, {
        style: { lineWidth: 0 }
    });
    return polyline.getBoundingBox();
};
