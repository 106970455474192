import { combineReducers } from '@reduxjs/toolkit';

import { accountSetupByClientSlice } from '~/reducers/accountSetupByClientSlice';
import { activeClientsSlice } from '~/reducers/activeClientsSlice';
import { addTaskSlice } from '~/reducers/addTaskSlice';
import { appGlobalsSlice } from '~/reducers/appGlobalsSlice';
import { availableDriversIdSlice } from '~/reducers/availableDriversIdSlice';
import { bookingMetricsSlice } from '~/reducers/bookingMetricsSlice';
import { bookingsByClientSlice } from '~/reducers/bookingsByClientSlice';
import { clientsSlice } from '~/reducers/clientsSlice';
import { clientTemplateDataSlice } from '~/reducers/clientTemplateDataSlice';
import { configurableWorkflowSlice } from '~/reducers/configurableWorkflowSlice';
import { currentPageSlice } from '~/reducers/currentPageSlice';
import { currentUserSlice } from '~/reducers/currentUserSlice';
import { customerSearchResultsSlice } from '~/reducers/customerSearchResultsSlice';
import { customersSlice } from '~/reducers/customersSlice';
import { dataLoadingSlice } from '~/reducers/dataLoadingSlice';
import { detectWebSolutionSlice } from '~/reducers/detectWebSolutionSlice';
import { draggedMarkerLocationSlice } from '~/reducers/draggedMarkerLocation';
import { driverBreaksSlice } from '~/reducers/driverBreaksModalSlice';
import { driversByClientSlice } from '~/reducers/driversByClientSlice';
import { driversLocationsSlice } from '~/reducers/driversLocationsSlice';
import { editHistoryByClientSlice } from '~/reducers/editHistoryByClientSlice';
import { editHistoryMetricsSlice } from '~/reducers/editHistoryMetricsSlice';
import { equipmentSlice } from '~/reducers/equipmentSlice';
import { equipmentTableSlice } from '~/reducers/equipmentTableSlice';
import { filteredDataCountSlice } from '~/reducers/filteredDataCountSlice';
import { filteredOutRoutesSlice } from '~/reducers/filteredOutRoutesSlice';
import { forecastSlice } from '~/reducers/forecastSlice';
import { geofenceSlice } from '~/reducers/geofenceSlice';
import { hiddenRoutesSlice } from '~/reducers/hiddenRoutesSlice';
import { lastPlanMapCenterSlice } from '~/reducers/lastPlanMapCenterSlice';
import { lastPlanMapZoomSlice } from '~/reducers/lastPlanMapZoomSlice';
import { lastPlanMapBoundsSlice } from '~/reducers/lastPlanMapBoundsSlice';
import { liveDispatchColumnVisibilitySlice } from '~/reducers/liveDispatchColumnVisibilitySlice';
import { liveDispatchSelectedDriverSlice } from '~/reducers/liveDispatchSelectedDriverSlice';
import { liveDispatchTableSlice } from '~/reducers/liveDispatchTableSlice';
import { liveDriversSlice } from '~/reducers/liveDriversSlice';
import { loadTasksSlice } from '~/reducers/loadTasksSlice';
import { mainClientSlice } from '~/reducers/mainClientSlice';
import { mapDrawerSettingsSlice } from '~/reducers/mapDrawerSettingsSlice';
import { mapSettingsSlice } from '~/reducers/mapSettingsSlice';
import { mapSideBarSlice } from '~/reducers/mapSideBarSlice';
import { onDemandDispatchTaskCountSlice } from '~/reducers/onDemandDispatchTaskCountSlice';
import { pendingJobsSlice } from '~/reducers/pendingJobsSlice';
import { planClientsLevelData } from '~/reducers/planClientsLevelDataSlice';
import { planRoutesLevelData } from '~/reducers/planRoutesLevelDataSlice';
import { planStopsLevelData } from '~/reducers/planStopsLevelDataSlice';
import { processIndicatorSlice } from '~/reducers/processIndicatorSlice';
import { routePlansByDateSlice } from '~/reducers/routePlansByDateSlice';
import { routePlanSummaryMetricsSlice } from '~/reducers/routePlanSummaryMetricsSlice';
import { routesDrawerFilterSlice } from '~/reducers/routesDrawerFilterSlice';
import { routesDrawerSortSlice } from '~/reducers/routesDrawerSortSlice';
import { schedulerProgressSlice } from '~/reducers/schedulerProgressSlice';
import { selectedBreakDetailsSlice } from '~/reducers/selectedBreakDetailsSlice';
import { selectedCardDriverSlice } from '~/reducers/selectedCardDriverSlice';
import { selectedClientsSlice } from '~/reducers/selectedClientsSlice';
import { selectedDateSlice } from '~/reducers/selectedDateSlice';
import { selectedDepotIdSlice } from '~/reducers/selectedDepotIdSlice';
import { selectedDrawerCardDataSlice } from '~/reducers/selectedDrawerCardDataSlice';
import { selectedDrawerCardIdSlice } from '~/reducers/selectedDrawerCardIdSlice';
import { selectedDriverVehicleRowId } from '~/reducers/selectedDriverVehicleRowIdSlice';
import { selectedEquipmentIdsSlice } from '~/reducers/selectedEquipmentIdsSlice';
import { selectedMapRoutesSlice } from '~/reducers/selectedMapRoutesSlice';
import { selectedMapStopsSlice } from '~/reducers/selectedMapStopsSlice';
import { selectedTaskFiltersSlice } from '~/reducers/selectedTaskFiltersSlice';
import { selectedTaskIdsSlice } from '~/reducers/selectedTaskIdsSlice';
import { selectedTaskRowIdSlice } from '~/reducers/selectedTaskRowIdSlice';
import { selectedUserGroupIdSlice } from '~/reducers/selectedUserGroupIdSlice';
import { taskMetricsSlice } from '~/reducers/taskMetricsSlice';
import { tasksSlice } from '~/reducers/tasksSlice';
import { taskUploadSlice } from '~/reducers/taskUploadSlice';
import { toastsSlice } from '~/reducers/toastsSlice';
import { uploadResultsSlice } from '~/reducers/uploadResultsSlice';
import { userGroupsByIdSlice } from '~/reducers/userGroupsByIdSlice';
import { userGroupsForCurrentUserSlice } from '~/reducers/userGroupsForCurrentUserSlice';
import { usersByClientSlice } from '~/reducers/usersByClientSlice';
import { usersByIdSlice } from '~/reducers/usersByIdSlice';
import { vehiclesSlice } from '~/reducers/vehiclesSlice';
import { webColorsSlice } from '~/reducers/webColorsSlice';
import { webSolutionSlice } from '~/reducers/webSolutionSlice';
import { addOperationalStopsSlice } from '~/reducers/addOperationalStopsSlice';

// keep `reducers` sorted alphabetically
// to help find them from `redux-devTools` list
export const reducers = combineReducers({
    accountSetupByClient: accountSetupByClientSlice.reducer,
    activeClients: activeClientsSlice.reducer,
    appGlobals: appGlobalsSlice.reducer,
    addOperationalStop: addOperationalStopsSlice.reducer,
    addTask: addTaskSlice.reducer,
    availableDriversId: availableDriversIdSlice.reducer,
    bookingMetrics: bookingMetricsSlice.reducer,
    bookingsByClient: bookingsByClientSlice.reducer,
    clients: clientsSlice.reducer,
    clientTemplateData: clientTemplateDataSlice.reducer,
    configurableWorkflow: configurableWorkflowSlice.reducer,
    currentPage: currentPageSlice.reducer,
    currentUser: currentUserSlice.reducer,
    customers: customersSlice.reducer,
    customerSearchResults: customerSearchResultsSlice.reducer,
    dataLoading: dataLoadingSlice.reducer,
    detectWebSolution: detectWebSolutionSlice.reducer,
    draggedMarkerLocation: draggedMarkerLocationSlice.reducer,
    driverBreaksModal: driverBreaksSlice.reducer,
    driversByClient: driversByClientSlice.reducer,
    driversLocations: driversLocationsSlice.reducer,
    editHistoryByClient: editHistoryByClientSlice.reducer,
    editHistoryMetrics: editHistoryMetricsSlice.reducer,
    equipment: equipmentSlice.reducer,
    equipmentTable: equipmentTableSlice.reducer,
    filteredDataCount: filteredDataCountSlice.reducer,
    filteredOutRoutes: filteredOutRoutesSlice.reducer,
    forecast: forecastSlice.reducer,
    geofence: geofenceSlice.reducer,
    hiddenRoutes: hiddenRoutesSlice.reducer,
    lastPlanMapBounds: lastPlanMapBoundsSlice.reducer,
    lastPlanMapCenter: lastPlanMapCenterSlice.reducer,
    lastPlanMapZoom: lastPlanMapZoomSlice.reducer,
    liveDispatchColumnVisibility: liveDispatchColumnVisibilitySlice.reducer,
    liveDispatchSelectedDriver: liveDispatchSelectedDriverSlice.reducer,
    liveDispatchTable: liveDispatchTableSlice.reducer,
    liveDrivers: liveDriversSlice.reducer,
    loadTasks: loadTasksSlice.reducer,
    mainClient: mainClientSlice.reducer,
    mapDrawerSettings: mapDrawerSettingsSlice.reducer,
    mapSettings: mapSettingsSlice.reducer,
    mapSideBar: mapSideBarSlice.reducer,
    onDemandDispatchTaskCount: onDemandDispatchTaskCountSlice.reducer,
    pendingJobs: pendingJobsSlice.reducer,
    planClientsLevelData: planClientsLevelData.reducer,
    planRoutesLevelData: planRoutesLevelData.reducer,
    planStopsLevelData: planStopsLevelData.reducer,
    processIndicator: processIndicatorSlice.reducer,
    routePlansByDate: routePlansByDateSlice.reducer,
    routePlanSummaryMetrics: routePlanSummaryMetricsSlice.reducer,
    routesDrawerFilter: routesDrawerFilterSlice.reducer,
    routesDrawerSort: routesDrawerSortSlice.reducer,
    schedulerProgress: schedulerProgressSlice.reducer,
    selectedBreakDetails: selectedBreakDetailsSlice.reducer,
    selectedCardDriver: selectedCardDriverSlice.reducer,
    selectedClients: selectedClientsSlice.reducer,
    selectedDate: selectedDateSlice.reducer,
    selectedDepotId: selectedDepotIdSlice.reducer,
    selectedDrawerCardData: selectedDrawerCardDataSlice.reducer,
    selectedDrawerCardId: selectedDrawerCardIdSlice.reducer,
    selectedDriverVehicleRowId: selectedDriverVehicleRowId.reducer,
    selectedEquipmentIds: selectedEquipmentIdsSlice.reducer,
    selectedMapRoutes: selectedMapRoutesSlice.reducer,
    selectedMapStops: selectedMapStopsSlice.reducer,
    selectedTaskFilters: selectedTaskFiltersSlice.reducer,
    selectedTaskIds: selectedTaskIdsSlice.reducer,
    selectedTaskRowId: selectedTaskRowIdSlice.reducer,
    selectedUserGroupId: selectedUserGroupIdSlice.reducer,
    taskMetrics: taskMetricsSlice.reducer,
    tasks: tasksSlice.reducer,
    taskUpload: taskUploadSlice.reducer,
    toasts: toastsSlice.reducer,
    uploadResults: uploadResultsSlice.reducer,
    userGroupsById: userGroupsByIdSlice.reducer,
    userGroupsForCurrentUser: userGroupsForCurrentUserSlice.reducer,
    usersByClient: usersByClientSlice.reducer,
    usersById: usersByIdSlice.reducer,
    vehicles: vehiclesSlice.reducer,
    webColors: webColorsSlice.reducer,
    webSolution: webSolutionSlice.reducer
});
