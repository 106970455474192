import { useContext } from 'react';
import { EquipmentTableContextValues } from '../types';
import { EquipmentTableContext } from './EquipmentTableContextProvider';

export const useEquipmentTableContext = (): EquipmentTableContextValues => {
    const context = useContext(EquipmentTableContext);

    if (!context) {
        throw new Error(
            'useEquipmentTableContext must be within context provider'
        );
    }

    return context;
};
