import { ColumnDef } from '@tanstack/react-table';
import { LiveDispatchTaskTableRowData } from '~/data-classes';

import {
    columnFilterExtTaskType,
    columnFilterTime,
    columnFilterTaskType,
    columnFilterTaskPriority,
    columnFilterEquipmentType
} from './filterFns';

import {
    UnassignedTasksTableData,
    UnassignedTasksTableDataDate,
    UnassignedTasksTableDataLabels,
    UnassignedTasksTableDataLinks,
    UnassignedTasksTableDataNotes,
    UnassignedTasksTableDataTaskPriority,
    UnassignedTasksTableDataTaskType
} from '../UnassignedTasksTableData';

import { LiveDispatchTableHeader } from '../LiveDispatchTableHeaderRow/LiveDispatchTableHeader';
import { LiveDispatchTableHeaderTaskType } from '../LiveDispatchTableHeaderRow/LiveDispatchTableHeaderTaskType';
import { LiveDispatchTableHeaderExtTaskType } from '../LiveDispatchTableHeaderRow/LiveDispatchTableHeaderExtTaskType';

import { LiveDispatchTableColumnId } from '../types';
import { LiveDispatchTableHeaderTimes } from '../LiveDispatchTableHeaderRow/LiveDispatchTableHeaderTimes';
import { LiveDispatchTableHeaderTaskPriority } from '../LiveDispatchTableHeaderRow/LiveDispatchTableHeaderTaskPriority';

type LiveDispatchTaskTableCellValues =
    LiveDispatchTaskTableRowData[LiveDispatchTableColumnId];

export const columnDefMap: Record<
    string,
    ColumnDef<LiveDispatchTaskTableRowData, LiveDispatchTaskTableCellValues>
> = {
    [LiveDispatchTableColumnId.TASK_TYPE]: {
        accessorKey: LiveDispatchTableColumnId.TASK_TYPE,
        cell: UnassignedTasksTableDataTaskType,
        header: LiveDispatchTableHeaderTaskType,
        filterFn: columnFilterTaskType,
        enableColumnFilter: true,
        enableHiding: true,
        enableSorting: false
    },
    [LiveDispatchTableColumnId.EXT_TASK_TYPE]: {
        accessorKey: LiveDispatchTableColumnId.EXT_TASK_TYPE,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeaderExtTaskType,
        filterFn: columnFilterExtTaskType,
        enableColumnFilter: true,
        enableHiding: true,
        enableSorting: false
    },
    [LiveDispatchTableColumnId.NAME]: {
        accessorKey: LiveDispatchTableColumnId.NAME,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.LABELS]: {
        accessorKey: LiveDispatchTableColumnId.LABELS,
        cell: UnassignedTasksTableDataLabels,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true
    },
    [LiveDispatchTableColumnId.EQUIPMENT_ID]: {
        accessorKey: LiveDispatchTableColumnId.EQUIPMENT_ID,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.PRIORITY]: {
        accessorKey: LiveDispatchTableColumnId.PRIORITY,
        cell: UnassignedTasksTableDataTaskPriority,
        header: LiveDispatchTableHeaderTaskPriority,
        filterFn: columnFilterTaskPriority,
        enableColumnFilter: true,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.PICKUP_START]: {
        accessorKey: LiveDispatchTableColumnId.PICKUP_START,
        cell: UnassignedTasksTableDataDate,
        header: LiveDispatchTableHeaderTimes,
        filterFn: columnFilterTime,
        enableColumnFilter: true,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.PICKUP_END]: {
        accessorKey: LiveDispatchTableColumnId.PICKUP_END,
        cell: UnassignedTasksTableDataDate,
        header: LiveDispatchTableHeaderTimes,
        filterFn: columnFilterTime,
        enableColumnFilter: true,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.DELIVERY_START]: {
        accessorKey: LiveDispatchTableColumnId.DELIVERY_START,
        cell: UnassignedTasksTableDataDate,
        header: LiveDispatchTableHeaderTimes,
        filterFn: columnFilterTime,
        enableColumnFilter: true,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.DELIVERY_END]: {
        accessorKey: LiveDispatchTableColumnId.DELIVERY_END,
        cell: UnassignedTasksTableDataDate,
        header: LiveDispatchTableHeaderTimes,
        filterFn: columnFilterTime,
        enableColumnFilter: true,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.PICKUP_CUSTOMER_NAME]: {
        accessorKey: LiveDispatchTableColumnId.PICKUP_CUSTOMER_NAME,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.PICKUP_CUSTOMER_ADDRESS]: {
        accessorKey: LiveDispatchTableColumnId.PICKUP_CUSTOMER_ADDRESS,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: false
    },
    [LiveDispatchTableColumnId.PICKUP_CUSTOMER_CITY]: {
        accessorKey: LiveDispatchTableColumnId.PICKUP_CUSTOMER_CITY,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: false
    },
    [LiveDispatchTableColumnId.DELIVERY_CUSTOMER_NAME]: {
        accessorKey: LiveDispatchTableColumnId.DELIVERY_CUSTOMER_NAME,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.DELIVERY_CUSTOMER_ADDRESS]: {
        accessorKey: LiveDispatchTableColumnId.DELIVERY_CUSTOMER_ADDRESS,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: false
    },
    [LiveDispatchTableColumnId.DELIVERY_CUSTOMER_CITY]: {
        accessorKey: LiveDispatchTableColumnId.DELIVERY_CUSTOMER_CITY,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: false
    },
    [LiveDispatchTableColumnId.EXT_LINKS]: {
        accessorKey: LiveDispatchTableColumnId.EXT_LINKS,
        cell: UnassignedTasksTableDataLinks,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true
    },
    [LiveDispatchTableColumnId.NOTES]: {
        accessorKey: LiveDispatchTableColumnId.NOTES,
        cell: UnassignedTasksTableDataNotes,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true
    },
    [LiveDispatchTableColumnId.EQUIPMENT_TYPE]: {
        accessorKey: LiveDispatchTableColumnId.EQUIPMENT_TYPE,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        filterFn: columnFilterEquipmentType,
        enableColumnFilter: true,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.PAYOR_OF_FREIGHT]: {
        accessorKey: LiveDispatchTableColumnId.PAYOR_OF_FREIGHT,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.RESERVATION]: {
        accessorKey: LiveDispatchTableColumnId.RESERVATION,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: true
    },
    [LiveDispatchTableColumnId.DROP_STAY]: {
        accessorKey: LiveDispatchTableColumnId.DROP_STAY,
        cell: UnassignedTasksTableData,
        header: LiveDispatchTableHeader,
        enableColumnFilter: false,
        enableHiding: true,
        enableSorting: false
    }
};

export const LiveDispatchTaskTableColumnIds = Object.keys(columnDefMap);
