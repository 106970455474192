import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import { useModal } from '~/ui';
import { InvoiceAndPaymentFormikInput } from './InvoiceAndPaymentFormikInput';
import { InvoiceAndPaymentData } from '../types';

import './InvoiceAndPaymentEditModal.scss';
import { FooterWithDeleteSaveAndCancelButtons } from '~/ui/components/FooterWithDeleteSaveAndCancelButtons';

const invoiceAndPaymentEditModal = 'invoice-and-payment-edit-modal';

export const InvoiceAndPaymentEditModal = ({
    item,
    open,
    isInvoiceEditable,
    onCancel,
    onSave,
    onDelete
}: InvoiceAndPaymentData): JSX.Element | null => {
    const { t } = useTranslation('taskManagement');

    const {
        showModal,
        hideModal,
        modal: Modal
    } = useModal({
        onHide: () => {
            onCancel();
        },
        onShow: () => {}
    });

    useEffect(() => {
        if (open) return showModal();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [open]);

    if (!item) return null;

    const { invoiceNumber, amountDue } = item;

    return (
        <Modal
            hasCloseButton
            variant="small"
            title={invoiceNumber}
            className={invoiceAndPaymentEditModal}
        >
            <Formik
                initialValues={item}
                onSubmit={(values) => {
                    onSave(values);
                }}
            >
                {({ dirty, isSubmitting, submitForm }) => (
                    <div
                        className={`${invoiceAndPaymentEditModal}__wrapper _fd-column _jc-space-between`}
                        data-testid={invoiceAndPaymentEditModal}
                    >
                        <Form>
                            <div
                                className={`${invoiceAndPaymentEditModal}__body`}
                                data-testid={`${invoiceAndPaymentEditModal}-body`}
                            >
                                <div
                                    className={`${invoiceAndPaymentEditModal}__payment-details _header-4 _mb-3`}
                                >
                                    {t(
                                        'drawer.invoicesAndPayments.paymentDetails'
                                    )}
                                </div>
                                <InvoiceAndPaymentFormikInput
                                    amountDue={amountDue}
                                    invoiceNumber={invoiceNumber}
                                    isInvoiceEditable={isInvoiceEditable}
                                />
                            </div>
                            <FooterWithDeleteSaveAndCancelButtons
                                isSaveDisabled={
                                    !dirty || isSubmitting || isInvoiceEditable
                                }
                                isDeleteDisabled={
                                    isSubmitting || isInvoiceEditable
                                }
                                onCancel={hideModal}
                                onSave={submitForm}
                                onDelete={onDelete}
                            />
                        </Form>
                    </div>
                )}
            </Formik>
        </Modal>
    );
};
