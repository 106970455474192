import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    setIsOpenAddTaskModal,
    setIsSaveAndAssignTaskMode
} from '~/reducers/addTaskSlice';
import { Icon, TooltipButton } from '~/ui';

import { LiveDispatchTableSearchBar } from '../LiveDispatchTableSearchBar';
import { LiveDispatchViewColumns } from '../LiveDispatchViewColumns';
import { useLiveDispatchTableStates } from '../useLiveDispatchTableStates';
import { LiveDispatchTaskTableColumnIds } from '../useLiveDispatchTableConfig/columnDef';

import './LiveDispatchTableToolbar.scss';

export const LiveDispatchTableToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['liveDispatchTable']);

    const {
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder
    } = useLiveDispatchTableStates();

    const openAddTaskModal = () => {
        dispatch(setIsSaveAndAssignTaskMode(true));
        dispatch(setIsOpenAddTaskModal(true));
    };

    return (
        <div
            className="live-dispatch-table-toolbar"
            data-testid="live-dispatch-table-toolbar"
        >
            <div className="live-dispatch-table-toolbar__header-container">
                <LiveDispatchTableSearchBar />
                <LiveDispatchViewColumns
                    columnIds={LiveDispatchTaskTableColumnIds}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                />
            </div>
            <div className="live-dispatch-table-toolbar__buttons-container">
                <TooltipButton
                    className="live-dispatch-table-toolbar__add-task-button _d-flex _ai-center _jc-center"
                    data-testid="live-dispatch-table-toolbar__add-task-button"
                    variant="primary"
                    size="m"
                    onClick={openAddTaskModal}
                >
                    <Icon
                        icon="plus"
                        color="comet"
                        size="xs"
                        data-testid="live-dispatch-table-toolbar__plus-icon"
                    />
                    {t('tableToolbar.addTaskAndAssign')}
                </TooltipButton>
            </div>
        </div>
    );
};
