import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';

import { useHideParentRoute } from '~/hooks';
import { usePlanRouteItemCardClick } from '../usePlanRouteItemCardClick';
import { useProcessedRouteData } from '~/components/MapPageDrawers/PlanRoutesDrawer/RouteItem/useProcessedRouteData';

import {
    CompartmentUsageContainer,
    DroppableContainer,
    RouteCard,
    RouteCardLateTaskPrompt,
    RouteCardMarker,
    RouteCardSummary,
    RouteCardTitle
} from '~/ui';
import { TripListItem } from './TripListItem';
import { idUtils } from '~/utils/id-utils';
import { StopDragAndDropTypes } from '~/components/MapPageDrawers/types';
import { TripListItemVariants } from '~/components/MapPageDrawers/PlanRoutesDrawer/RouteItem/TripListItem/TripListItem';
import { useUpdateSelectedDrawerCardData } from './useUpdateSelectedDrawerCardData';

import './route-item.scss';

function RouteItem({ routeData, isInteractive = false }) {
    const [isHovered, setIsHovered] = useState(false);
    const [isRouteSelected, setIsRouteSelected] = useState(false);
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);

    const {
        isMultiTrips,
        singleTripCompartmentStats,
        summaryMetrics,
        markerColor,
        iconColor,
        isMultiCompartmentClient,
        titleLine,
        isLocked,
        trips,
        clientId,
        clientRouteId,
        routeId,
        vehicleId,
        numTasks,
        numLateStops,
        numAtRiskStops,
        driverId
    } = useProcessedRouteData(routeData);

    const { isHidden: isRouteHidden, hideButton } = useHideParentRoute({
        clientRouteId,
        isHovered,
        buttonClassName: 'routeitem__visibility'
    });

    const { onPlanRouteItemCardClick } = usePlanRouteItemCardClick();

    const handleItemClick = useCallback(() => {
        onPlanRouteItemCardClick({
            isInteractive,
            planRoute: routeData
        });
    }, [isInteractive, onPlanRouteItemCardClick, routeData]);

    useEffect(() => {
        setIsRouteSelected(selectedMapRoutes.includes(clientRouteId));
    }, [selectedMapRoutes, clientRouteId, selectedDrawerCardId]);

    useUpdateSelectedDrawerCardData({
        driverId,
        numTasks,
        routeId,
        vehicleId,
        handleUpdate: handleItemClick
    });

    if (!routeData) {
        return null;
    }

    const rootClassName = 'route-item';

    return (
        <div
            data-testid={rootClassName}
            className={rootClassName}
            aria-hidden="true"
            type="button"
            onClick={handleItemClick}
        >
            <RouteCard
                isRouteSelected={isRouteSelected}
                isRouteHidden={isRouteHidden}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className="routeitem__markers _fd-column _ai-center _jc-space-between">
                    <RouteCardMarker
                        disabled={isRouteHidden}
                        isRouteLocked={isLocked}
                        iconStyle={markerColor}
                        icon={isRouteSelected ? 'checkmark' : 'route'}
                        iconSize="s"
                        iconColor={iconColor}
                    />
                    {hideButton}
                </div>
                <div className="routeitem-main">
                    <RouteCardTitle
                        title={titleLine}
                        tooltip={titleLine}
                        tooltipPlacement="top"
                    >
                        <RouteCardLateTaskPrompt
                            numLateStops={numLateStops}
                            numAtRiskStops={numAtRiskStops}
                        />
                    </RouteCardTitle>
                    <RouteCardSummary
                        className="routeitem__summary _d-grid"
                        metrics={summaryMetrics}
                        data-testid="route-item-summary"
                    />
                    {!isMultiTrips && (
                        <CompartmentUsageContainer
                            isIndicateOverCapacity
                            isMultiCompartment={isMultiCompartmentClient}
                            compartmentStats={singleTripCompartmentStats}
                        />
                    )}
                </div>
                {isMultiTrips && (
                    <div className="routeitem__trips">
                        <>
                            {trips.map(
                                (
                                    { stats: tripStats, routeId: tripRouteId },
                                    index
                                ) => {
                                    const tripClientRouteId =
                                        idUtils.getCombinedId(
                                            clientId,
                                            tripRouteId
                                        );
                                    return (
                                        <DroppableContainer
                                            id={tripClientRouteId}
                                            key={tripClientRouteId}
                                            data={{
                                                type: StopDragAndDropTypes.STOP_REASSIGN
                                            }}
                                        >
                                            <TripListItem
                                                key={tripClientRouteId}
                                                isMultiCompartment={
                                                    isMultiCompartmentClient
                                                }
                                                index={index}
                                                clientRouteId={
                                                    tripClientRouteId
                                                }
                                                markerColor={markerColor}
                                                iconColor={iconColor}
                                                stats={tripStats}
                                                isParentHovered={isHovered}
                                                variant={
                                                    TripListItemVariants.SMALL
                                                }
                                            />
                                        </DroppableContainer>
                                    );
                                }
                            )}
                        </>
                    </div>
                )}
            </RouteCard>
        </div>
    );
}

export default RouteItem;
