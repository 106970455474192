import type * as CSS from 'csstype';
import { IconName } from '../Icon/IconNames';
import { UIElement } from '~/ui';
import { InputProps as ThemeInputProps, ThemeUIStyleObject } from 'theme-ui';

export enum TextInputSupportedType {
    TEXT = 'text',
    NUMBER = 'number',
    PASSWORD = 'password'
}

export type TextInputSupportedTypes = `${TextInputSupportedType}`;

export enum TextInputVariant {
    BASIC = 'basic',
    EDIT = 'edit',
    SEARCH = 'search'
}

export type TextInputVariants = `${TextInputVariant}`;

export enum TextInputWidth {
    XS = 'xs',
    S = 's',
    M = 'm',
    FULLWIDTH = 'fullWidth'
}

export type TextInputWidths = `${TextInputWidth}`;

interface TextInputVariantStyle {
    /**
     * The WISE theme color name to attach to the CSS `color` property
     */
    color: string;

    /**
     * The CSS `font-size` property
     */
    fontSize: CSS.Property.FontSize;

    /**
     * CSS properties to attach to the `placeholder` pseudoelement
     */
    placeholder: {
        /**
         * The WISE theme color name to attach to the CSS `color` property
         */
        color: string;
    };

    /**
     * CSS properties to attach to the `disabled` state
     */
    disabled: {
        /**
         * The WISE theme color name to attach to the CSS `color` property
         */
        color: string;

        /**
         * The CSS `background-color` property
         */
        backgroundColor: CSS.Property.BackgroundColor;
    };

    /**
     * The CSS `background-color` property
     */
    backgroundColor?: CSS.Property.BackgroundColor;

    /**
     * The CSS `border` property
     */
    border?: CSS.Property.Border;

    /**
     * The CSS `border-radius` property
     */
    borderRadius?: CSS.Property.BorderRadius;

    /**
     * The CSS `bottom` property
     */
    bottom?: CSS.Property.Bottom;

    /**
     * CSS properties to attach to the `focus` state
     */
    focus?: {
        /**
         * The CSS `outline` property
         */
        outline?: CSS.Property.Outline;
    };

    /**
     * The icon name
     */
    icon?: IconName;

    /**
     * The CSS `padding` property
     */
    padding?: CSS.Property.Padding;

    /**
     * The CSS `position` property
     */
    position?: CSS.Property.Position;

    /**
     * The CSS `width` property
     */
    width?: CSS.Property.Width;
}

const commonVariantStyles: TextInputVariantStyle = {
    color: 'galaxy-800',
    fontSize: '1.6rem',
    placeholder: {
        color: 'galaxy-400'
    },
    disabled: {
        color: 'galaxy-400',
        backgroundColor: 'transparent'
    }
};

export const textInputVariantStyles: Record<
    TextInputVariant,
    TextInputVariantStyle
> = {
    [TextInputVariant.BASIC]: {
        ...commonVariantStyles,
        border: 'solid 0.1rem var(--color-galaxy-300)',
        borderRadius: '0.8rem',
        padding: '0.6rem 1.6rem'
    },
    [TextInputVariant.EDIT]: {
        ...commonVariantStyles,
        fontSize: '1.4rem',
        border: 'solid 0.1rem var(--color-ocean)',
        backgroundColor: 'var(--color-neptune-100)',
        borderRadius: '0.8rem',
        position: 'relative',
        bottom: '0.7rem',
        padding: '0.6rem 0.8rem',
        focus: {
            outline: 'none'
        }
    },
    [TextInputVariant.SEARCH]: {
        ...commonVariantStyles,
        border: 'solid 0.1rem var(--color-galaxy-200)',
        borderRadius: '0.8rem',
        padding: '1rem 1rem 1rem 4rem',
        icon: 'search'
    }
};

export const textInputPresetWidths: Record<TextInputWidth, CSS.Property.Width> =
    {
        [TextInputWidth.XS]: '10.9rem',
        [TextInputWidth.S]: '16rem',
        [TextInputWidth.M]: '32rem',
        [TextInputWidth.FULLWIDTH]: '100%'
    };

export interface InputProps
    extends Omit<ThemeInputProps, 'onChange'>,
        UIElement {
    /**
     * Whether the text input field is disabled
     */
    disabled?: boolean;

    /**
     * The text input field placeholder value
     */
    placeholder?: string;

    /**
     * The text input field value
     */
    value?: string | number;

    /**
     * The supported input types
     */
    type?: TextInputSupportedTypes;

    /**
     * The `theme-ui` style object to attach to the text input
     *
     * There is a preset style object as defined by the `variant` property.
     * Using the `sx` property will extend the desired preset.
     */
    sx?: ThemeUIStyleObject;

    /**
     * The handler for the `change` event on the text input
     */
    onChange?: (value: string) => void;
}

export interface TextInputProps extends InputProps {
    /**
     * The preset width to use
     */
    width?: TextInputWidth | TextInputWidths;

    /**
     * The preset style variant to use
     */
    variant?: TextInputVariant | TextInputVariants;

    /**
     * The `theme-ui` style object to attach to the wrapper
     */
    wrapperSx?: ThemeUIStyleObject;
}
