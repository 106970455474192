import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '~/ui';
import NotificationStatus from '~/ui/components/NotificationStatus/NotificationStatus';
import classNames from 'classnames';
import constants from '~/utils/constants';
import { usePageNavigation } from '~/hooks';
import { RevisedTimeStatus } from '../RevisedTimeStatus';
import { IconName } from '~/ui/components/Icon/IconNames';

import './TaskHistoryStatus.scss';

interface StateProperties {
    lineColor: string;
    icon: IconName;
    isDefault: boolean;
    iconColor?: string;
}

interface TaskHistoryStatusProps {
    /**
     * The task history status title
     */
    title: string;

    /**
     * The task history status date
     */
    date: string;

    /**
     * The status of the task history
     */
    status: string;

    /**
     * Indicates whether to hide the status progress line
     */
    hideLine: boolean;

    /**
     * An array of notification objects
     */
    notifications: { title: string; status: string }[];

    /**
     * Indicates whether notifications are disabled
     */
    isNotificationsDisabled: boolean;

    /**
     * The revised date and time
     */
    revisedDateTime?: string;

    /**
     * Indicates whether the task is in progress
     */
    isTaskInProgress: boolean;

    /**
     * Indicates whether the task is completed
     */
    isTaskCompleted: boolean;
}

export const TaskHistoryStatus = ({
    title,
    date,
    status,
    hideLine = false,
    notifications = [],
    isNotificationsDisabled,
    revisedDateTime = '',
    isTaskInProgress = false,
    isTaskCompleted = false
}: TaskHistoryStatusProps) => {
    const { t } = useTranslation('taskManagement');
    const { goToPage } = usePageNavigation();

    function computeState() {
        switch (status) {
            case 'focus':
                return {
                    iconColor: 'galaxy-500',
                    lineColor: 'galaxy-400',
                    icon: 'circle',
                    isDefault: false
                };
            case 'completed':
                return {
                    iconColor: 'galaxy-500',
                    lineColor: 'galaxy-500',
                    icon: 'circle',
                    isDefault: false
                };
            default:
                return {
                    lineColor: 'galaxy-400',
                    icon: 'circleEmpty',
                    isDefault: true
                };
        }
    }

    const properties = computeState() as StateProperties;

    const lineClassNames = classNames(
        `task-history-status__line task-history-status__line--${properties.lineColor}`,
        {
            'task-history-status__line--hidden': hideLine
        }
    );

    return (
        <div
            className="task-history-status"
            data-testid="task-history-status-container"
        >
            <Icon
                data-testid="task-history-status-icon"
                className="task-history-status__circle"
                icon={properties.icon}
                size="xs"
                color={properties.iconColor}
            />
            <div className="_d-flex _ai-center _jc-space-between">
                <div
                    data-testid="task-history-status-title"
                    className={classNames('_header-4', {
                        'task-history-status__name--default':
                            properties.isDefault
                    })}
                >
                    {title}
                </div>
                <div
                    className="sub-heading-2"
                    data-testid="task-history-status-date"
                >
                    {date}
                </div>
            </div>
            <div className={lineClassNames} />
            <div className="task-history-status__notification">
                {notifications.length > 0 && (
                    <div
                        data-testid="task-history-status-notifications-text"
                        className="_text-3 _mb-3 task-history-status__notification-text"
                    >
                        {t('notificationStatus.notifications')}
                    </div>
                )}
                {notifications.map((notification) => (
                    <NotificationStatus
                        data-testid="notification-status"
                        key={notification.title}
                        title={notification.title}
                        status={notification.status}
                        onClick={() => {
                            goToPage(constants.url.NOTIFICATION_MANAGEMENT);
                        }}
                        disabled={isNotificationsDisabled}
                    />
                ))}
                {(isTaskInProgress || isTaskCompleted) && (
                    <RevisedTimeStatus revisedDateTime={revisedDateTime} />
                )}
            </div>
        </div>
    );
};
