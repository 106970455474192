import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Supercluster from 'supercluster';
import { has } from 'lodash';
import constants from '~/utils/constants';
import { selectedIsClusteringUnassignedTasks } from '~/reducers/mapSettingsSlice';
import { selectUnassignedPlanTasks } from '~/reducers/tasksSlice';
import { markerUtils } from '~/utils/map';
import { Coordinates, DeliveryTask, PickupTask } from '~/api/types';
import { useMapUtils } from '~/hooks';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

const clusterRadius = constants.mapOptionSettings.DEFAULT_CLUSTER_RADIUS;
const maxZoom = constants.mapOptionSettings.MAX_ZOOM;

export const useUnassignedSuperClusters = () => {
    const unassignedPlanTasks = useSelector(selectUnassignedPlanTasks);

    const { mapRouteMode } = useMapUtils();

    const isClusteringUnassignedTasks = useSelector(
        selectedIsClusteringUnassignedTasks(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );

    const schedules = useMemo<
        Supercluster.PointFeature<Supercluster.AnyProps>[]
    >(() => {
        if (!isClusteringUnassignedTasks) {
            return [];
        }

        return unassignedPlanTasks.map((unassignedPlanTask) => {
            let markerCoordinates = { lat: 0, lng: 0 };
            if (has(unassignedPlanTask, 'deliveryLocation')) {
                const { deliveryLocation } = unassignedPlanTask as DeliveryTask;
                markerCoordinates = deliveryLocation.location as Coordinates;
            } else if (has(unassignedPlanTask, 'pickupLocation')) {
                const { pickupLocation } = unassignedPlanTask as PickupTask;
                markerCoordinates = pickupLocation.location as Coordinates;
            }

            return markerUtils.getGeoJSONFeaturePoint(
                markerCoordinates.lat,
                markerCoordinates.lng,
                unassignedPlanTask
            ) as unknown as Supercluster.PointFeature<Supercluster.AnyProps>;
        });
    }, [isClusteringUnassignedTasks, unassignedPlanTasks]);

    const superClusters = useMemo(() => {
        const superClusterEffects: Supercluster.AnyProps[] = [];

        const superCluster = new Supercluster({
            radius: clusterRadius,
            maxZoom
        });
        superCluster.load(schedules);
        superClusterEffects.push(superCluster);

        return superClusterEffects;
    }, [schedules]);

    return {
        schedules,
        superClusters
    };
};
