import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsUnassignedTasksTabActive } from '~/reducers/mapDrawerSettingsSlice';
import {
    LiveDispatchTableContextValues,
    LiveDispatchTableContextProviderProps,
    LiveDispatchDrawerTableMode
} from '../types';
import { ExtendedPaginationParams } from '~/api/types/Pagination';

export const LiveDispatchTableContext =
    createContext<LiveDispatchTableContextValues | undefined>(undefined);

const MAX_PAGE_SIZE = 100;

export const LiveDispatchTableContextProvider = ({
    children
}: LiveDispatchTableContextProviderProps): JSX.Element => {
    const isUnassignedTasksTabActive = useSelector(
        selectIsUnassignedTasksTabActive
    );

    const [globalFilter, setGlobalFilter] = useState('');

    const [tableMode, setTableMode] = useState<LiveDispatchDrawerTableMode>(
        LiveDispatchDrawerTableMode.TASK_TABLE
    );
    const [totalLiveDispatchCount, setTotalLiveDispatchCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(MAX_PAGE_SIZE);
    const [apiPaginationOptions, setApiPaginationOptions] =
        useState<ExtendedPaginationParams>({
            limit: MAX_PAGE_SIZE,
            page: 0
        });

    useEffect(() => {
        setTableMode(
            isUnassignedTasksTabActive
                ? LiveDispatchDrawerTableMode.TASK_TABLE
                : LiveDispatchDrawerTableMode.EQUIPMENT_TABLE
        );
    }, [isUnassignedTasksTabActive]);

    return (
        <LiveDispatchTableContext.Provider
            value={{
                globalFilter,
                setGlobalFilter,
                tableMode,
                setTableMode,
                totalLiveDispatchCount,
                setTotalLiveDispatchCount,
                apiPaginationOptions,
                setApiPaginationOptions,
                pageIndex,
                setPageIndex,
                pageSize,
                setPageSize
            }}
        >
            {children}
        </LiveDispatchTableContext.Provider>
    );
};
