import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectDriverLocationById } from '~/reducers/driversLocationsSlice';
import {
    selectShowDriverActualLines,
    selectShowDriverLines
} from '~/reducers/mapSettingsSlice';
import { RouteLineComponentProps } from './types';
import { ApiInitialVsActualDriverRoutePaths } from '~/api/types';
import {
    SupportedPolylines,
    SupportedRouteLinePaths
} from '~/components/MapEngineProvider/types';
import { useMapUtils } from '~/hooks';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useDriverRoutePaths } from '~/hooks/useDriverRoutePaths';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { useDriverScheduleRouteLine } from './useDriverScheduleRouteLine';
import { useMapEngineContext } from '~/components/MapEngineProvider';
import { getStyleSetup } from '~/ui/components/RouteLine/utils';

export default function RouteLine({
    driver,
    showHistory = false,
    colorCSS,
    isShowLineArrow = false
}: RouteLineComponentProps) {
    const {
        id: driverId,
        schedule: driverSchedule,
        location: driverLocation,
        currentStopIndex: driverCurrentStopIndex
    } = driver;

    const { mapInstance, mapToolKit, mapDrawingStyles } = useMapEngineContext();

    const styleSetup = getStyleSetup(mapDrawingStyles);

    const { mapRouteMode, isCompletedRouteMode } = useMapUtils();
    const locationHistory = useSelector(selectDriverLocationById(driverId));

    const showDriverLines = useSelector(
        selectShowDriverLines(mapRouteMode as ConfigurableMapRouteMode)
    );

    const showDriverActualLines = useSelector(
        selectShowDriverActualLines(mapRouteMode as ConfigurableMapRouteMode)
    );

    const projectionLine = useRef<SupportedPolylines | null>(null);
    const historyLine = useRef<SupportedPolylines | null>(null);

    const routeDate = useSelector(selectDateOnly) as string;
    const { data: initialVsActualResponse } = useDriverRoutePaths(
        {
            driverId,
            date: routeDate
        },
        {
            // auto fetch only for completed map route mode
            enabled: isCompletedRouteMode
        }
    );

    const initialPath = useMemo(() => {
        if (!mapToolKit) {
            return null;
        }
        const routePaths =
            initialVsActualResponse as ApiInitialVsActualDriverRoutePaths;
        const hasRoutePaths = routePaths?.length && routePaths[0]?.polyline;

        if (!hasRoutePaths || !isCompletedRouteMode) return;

        const [{ polyline: encodedPath }] = routePaths;

        const decodedPath = mapToolKit.decodePolylinePath(encodedPath);
        return decodedPath;
    }, [initialVsActualResponse, isCompletedRouteMode, mapToolKit]);

    const { driverSchedulePath } = useDriverScheduleRouteLine({
        driverSchedule,
        driverLocation,
        driverCurrentStopIndex
    });

    const locationHistoryPath = useMemo(() => {
        if (
            !showHistory ||
            !locationHistory ||
            !Array.isArray(locationHistory)
        ) {
            return;
        }

        const historyPath = locationHistory.map((lh) => {
            const { lat, lng } = lh;
            return { lat, lng };
        });

        return historyPath;
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [showHistory, locationHistory, driverLocation]);

    const updateProjectionLine = (
        path: SupportedRouteLinePaths,
        lineRef: MutableRefObject<SupportedPolylines | null>,
        strokeColor: string
    ) => {
        if (!styleSetup || !mapToolKit) {
            return;
        }
        const { SOLID, routeLineDirectionSymbol } = styleSetup;
        const icons = isShowLineArrow ? [routeLineDirectionSymbol] : [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const polylineStyle = mapToolKit.getPolylineStyle({
            icons,
            ...SOLID,
            strokeColor
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mapToolKit.updatePolyline(path, lineRef, polylineStyle);
    };

    const updateHistoryLine = (
        path: SupportedRouteLinePaths,
        lineRef: MutableRefObject<SupportedPolylines | null>,
        strokeColor: string
    ) => {
        if (!styleSetup) {
            return;
        }
        const { DOTTED, routeLineDirectionSymbol } = styleSetup;
        if (lineRef.current) {
            const {
                icons: [dottedLineSymbol],
                ...lineProps
            } = DOTTED;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const polylineOptions = mapToolKit.getPolylineStyle({
                ...lineProps,
                icons: isShowLineArrow
                    ? [dottedLineSymbol, routeLineDirectionSymbol]
                    : [dottedLineSymbol],
                strokeColor
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mapToolKit.updatePolyline(path, lineRef, polylineOptions);
        } else {
            const {
                icons: [dottedLineSymbol],
                ...lineProps
            } = DOTTED;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const polylineOptions = mapToolKit.getPolylineStyle({
                ...lineProps,
                icons: isShowLineArrow
                    ? [dottedLineSymbol, routeLineDirectionSymbol]
                    : [dottedLineSymbol],
                strokeColor
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const historyPolyline = mapToolKit.createPolyline(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                path,
                polylineOptions
            );
            lineRef.current = historyPolyline;
        }
    };

    // Update projection (initial) line
    useEffect(() => {
        if (!mapToolKit) {
            return;
        }
        const projectionPath = initialPath || driverSchedulePath;

        try {
            updateProjectionLine(
                projectionPath,
                projectionLine,
                colorCSS.backgroundColor
            );

            if (showDriverLines) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mapToolKit.drawMapObject(projectionLine.current, mapInstance);
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mapToolKit.removeMapObject(projectionLine.current, mapInstance);
            }
        } catch (e) {
            console.error(e);
        }

        const currentPolyline = projectionLine.current;
        return () => {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mapToolKit.removeMapObject(currentPolyline, mapInstance);
            } catch (e) {
                console.error(e);
            }
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        initialPath,
        driverSchedulePath,
        projectionLine,
        showDriverLines,
        colorCSS,
        mapInstance,
        mapToolKit
    ]);

    // Update history (actual) line
    useEffect(() => {
        if (!locationHistoryPath || !mapToolKit || !mapInstance) {
            return;
        }

        try {
            updateHistoryLine(
                locationHistoryPath,
                historyLine,
                colorCSS.backgroundColor
            );

            if (showDriverActualLines) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mapToolKit.drawMapObject(historyLine.current, mapInstance);
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mapToolKit.removeMapObject(historyLine.current, mapInstance);
            }
        } catch (e) {
            console.error(e);
        }

        const currentPolyline = historyLine.current;
        return () => {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mapToolKit.removeMapObject(currentPolyline, mapInstance);
            } catch (e) {
                console.error(e);
            }
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        locationHistoryPath,
        historyLine,
        showDriverActualLines,
        colorCSS,
        mapToolKit
    ]);

    return null;
}
