import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Equipment } from '~/data-classes';
import { TableHeaderProps } from '../types';

import { useEquipmentTableStates } from '../useEquipmentTableStates';
import { TableFilterElement } from '../TableFilterElement';
import { ApiEquipmentAttributes } from '~/api/types';

const ROOT_CLASS_NAME = 'equipment-table-header';

interface CheckboxHandler {
    isChecked: boolean;
    value?: string | number | unknown;
    selectedValues?: Array<string | number | unknown>;
}

interface FilterValueDetails {
    name: string;
    numberOfEntries: number;
}

export const EquipmentTableHeaderArrayFilter = ({
    header,
    children,
    className,
    ...extra
}: TableHeaderProps<Equipment>) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { id } = header || {};
    const headerText = id && t(`columns.equipmentTable.${id}`);
    const defaultTestId = id ? `${ROOT_CLASS_NAME}__${id}` : ROOT_CLASS_NAME;
    const dataTestId = extra['data-testid'] || defaultTestId;
    const elementClassName = classNames(ROOT_CLASS_NAME, className, {
        [`${ROOT_CLASS_NAME}__${id}`]: id
    });
    const [selectedTypeFilter, setSelectedTypeFilter] = useState<string[]>([]);
    const { attributes, columnFilters, setColumnFilters } =
        useEquipmentTableStates();

    const facetedUniqueValues = header?.column.getFacetedUniqueValues().keys();
    const isEnabledColumnFilter =
        id && header?.column.columnDef.enableColumnFilter;

    useEffect(() => {
        if (columnFilters.length) {
            const currentColumnData = columnFilters.filter(
                (data) => data.id === id
            );
            if (currentColumnData.length) {
                setSelectedTypeFilter(currentColumnData[0].value as string[]);
            }
        }
    }, [columnFilters, id]);

    const typeColumnFilterArray = useMemo(() => {
        if (!id || !attributes) return [];

        const selectedColumnFilterValues =
            attributes[id as keyof ApiEquipmentAttributes] || [];

        const mapColumnFilterValues = selectedColumnFilterValues.reduce<
            Record<string, FilterValueDetails>
        >((acc, filterValue) => {
            acc[filterValue] = { name: filterValue, numberOfEntries: 0 };
            return acc;
        }, {});

        if (facetedUniqueValues) {
            for (const key of facetedUniqueValues) {
                if (!key.length) continue;
                key.forEach((state: string) => {
                    if (!mapColumnFilterValues[state]) {
                        mapColumnFilterValues[state] = {
                            name: state,
                            numberOfEntries: 0
                        };
                    }

                    mapColumnFilterValues[state].numberOfEntries++;
                });
            }
        }

        return Object.values(mapColumnFilterValues).sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    }, [attributes, id, facetedUniqueValues]);

    const handleTypeFilterCheckboxClick = ({
        isChecked,
        value
    }: CheckboxHandler) => {
        const updatedFilter = isChecked
            ? [...selectedTypeFilter, value as string]
            : selectedTypeFilter.filter((data) => data !== value);
        setSelectedTypeFilter(updatedFilter);
    };

    const onClearStatusFilter = () => {
        setSelectedTypeFilter([]);
        const updatedColumnFilter = columnFilters.filter(
            (data) => data.id !== id
        );
        setColumnFilters([...updatedColumnFilter]);
    };

    const onApplyStatusFilter = () => {
        const hasFilter = selectedTypeFilter.length > 0;
        if (hasFilter) {
            const filteredColumnFilters = columnFilters.filter(
                (data) => data.id !== id
            );
            const updatedColumnFilters = [
                ...filteredColumnFilters,
                {
                    id: id as string,
                    value: selectedTypeFilter
                }
            ];
            setColumnFilters(updatedColumnFilters);
        } else {
            onClearStatusFilter();
        }
    };

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            {isEnabledColumnFilter && (
                <TableFilterElement
                    headerText={headerText}
                    headerId={id}
                    columnFilters={columnFilters}
                    filterList={typeColumnFilterArray}
                    selectedFilterList={selectedTypeFilter}
                    handleFilterCheckboxClick={handleTypeFilterCheckboxClick}
                    onClearStatusFilter={onClearStatusFilter}
                    onApplyStatusFilter={onApplyStatusFilter}
                />
            )}
            {children}
            <span data-testid={`${dataTestId}-text`}>{headerText}</span>
        </div>
    );
};
