import React, { useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { LiveDispatchTaskTableRowData } from '~/data-classes';
import { TableHeaderProps } from '../../types';
import { useLiveDispatchTableStates } from '../../useLiveDispatchTableStates';
import { TableSortElement } from '../../TableSortElement/TableSortElement';
import { TableFilterElement } from '../../TableFilterElement';

const ROOT_CLASS_NAME = 'live-dispatch-table-header';

export const LiveDispatchTableHeaderTimes = ({
    header,
    children,
    className,
    ...extra
}: TableHeaderProps<LiveDispatchTaskTableRowData>) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { id } = header || {};
    const headerText = id && t(`columns.taskTable.${id}`);
    const defaultTestId = id ? `${ROOT_CLASS_NAME}__${id}` : ROOT_CLASS_NAME;
    const dataTestId = extra['data-testid'] || defaultTestId;
    const elementClassName = classNames(ROOT_CLASS_NAME, className, {
        [`${ROOT_CLASS_NAME}__${id}`]: id
    });

    const [selectedDateFilter, setSelectedDateFilter] =
        useState<string | null>(null);
    const { columnFilters, setColumnFilters, onApplySorting, onClearSorting } =
        useLiveDispatchTableStates();

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [startTime, setStartTime] = useState<string>('00:01');
    const [endTime, setEndTime] = useState<string>('23:59');
    const headerId = header?.id ?? '';

    const onChange = (value: Date) => {
        setSelectedDate(value);
        setSelectedDateFilter(value.toISOString().split('T')[0]);
    };

    const onStartTimeChange = (value: string) => {
        setStartTime(value);
    };

    const onEndTimeChange = (value: string) => {
        setEndTime(value);
    };

    const modifyDateAndTime = (date: string, time: string) => {
        const [hours, minutes]: number[] = time.split(':').map(Number);
        const parsedDate = DateTime.fromISO(date);
        const dateTimeObject = parsedDate.set({
            hour: hours,
            minute: minutes
        });
        return dateTimeObject;
    };

    const onClearStatusFilter = () => {
        setSelectedDateFilter(null);
        setSelectedDate(null);
        setStartTime('00:00');
        setEndTime('23:59');
        const updatedColumnFilter = columnFilters.filter(
            (data) => data.id !== headerId
        );
        setColumnFilters([...updatedColumnFilter]);
    };

    const onApplyStatusFilter = () => {
        if (selectedDateFilter && startTime && endTime) {
            const modifiedStartTime = modifyDateAndTime(
                selectedDateFilter,
                startTime
            );
            const modifiedEndTime = modifyDateAndTime(
                selectedDateFilter,
                endTime
            );
            const filterFromDateType = columnFilters.filter(
                (data) => data.id !== headerId
            );

            setColumnFilters([
                ...filterFromDateType,
                {
                    id: headerId,
                    value: [modifiedStartTime, modifiedEndTime]
                }
            ]);
        }
    };

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            {header?.column.columnDef.enableSorting && id && (
                <TableSortElement
                    columnId={id}
                    onClearSorting={onClearSorting}
                    onApplySorting={onApplySorting}
                />
            )}

            {header?.column.columnDef.enableColumnFilter && (
                <TableFilterElement
                    headerText={headerText}
                    startTime={startTime}
                    onStartTimeChange={onStartTimeChange}
                    endTime={endTime}
                    onEndTimeChange={onEndTimeChange}
                    headerId={headerId}
                    selectedDate={selectedDate}
                    onChangeDate={onChange}
                    columnFilters={columnFilters}
                    onClearStatusFilter={onClearStatusFilter}
                    onApplyStatusFilter={onApplyStatusFilter}
                />
            )}
            {children}
            <span data-testid={`${dataTestId}-text`}>{headerText}</span>
        </div>
    );
};
