import React, { useEffect, useRef } from 'react';
import { mapUtils } from '~/utils/map-utils';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useDepotCoordinates } from './useDepotCoordinates';

const GooglePlacesAutocomplete = ({ placeholder, onSelect, taskType }) => {
    const {
        southwestLatitude,
        southwestLongitude,
        northeastLatitude,
        northeastLongitude
    } = useDepotCoordinates();

    const options = {
        bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(southwestLatitude, southwestLongitude),
            new google.maps.LatLng(northeastLatitude, northeastLongitude)
        ),
        fields: ['address_components', 'geometry', 'name'],
        types: ['establishment'],
        strictBounds: true
    };

    const taskTypeRef = useRef(taskType);

    useEffect(() => {
        taskTypeRef.current = taskType;
    }, [taskType]);

    const { ref } = usePlacesWidget({
        onPlaceSelected: (place) => {
            const currentTaskType = taskTypeRef.current;

            const addressInfo = {
                ...mapUtils.googlePlaceToAddress(place),
                currentTaskType
            };

            onSelect(addressInfo);
        },
        options
    });

    return (
        <div
            className="google-places-autocomplete"
            data-testid="google-places-autocomplete"
        >
            <input
                className="label-input _w-100"
                ref={ref}
                placeholder={placeholder}
                data-testid="google-places-autocomplete_input"
            />
        </div>
    );
};
export default GooglePlacesAutocomplete;
