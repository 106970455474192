export interface Item {
    /**
     * The item name
     */
    name: string;
    /**
     * The photo urls
     */
    photoUrls: string[];
}

/* eslint-disable camelcase */
export const getItemsWithPhotos = (
    items: {
        /**
         * The item name - this is the format from task assignments
         */
        name?: string;
        /**
         * The item name - this is the format from inventory items
         */
        item_name?: string;
        /**
         * The list of photo urls - this is the format from inventory items
         */
        photo_urls?: string[];
        /**
         * The list of photo urls - this is the format from task assignments
         */
        photoUrls?: string[];
    }[]
) => {
    return items
        .map(({ item_name, name, photo_urls, photoUrls }) => ({
            name: item_name || name,
            photoUrls: photo_urls || photoUrls
        }))
        .filter(
            ({ name, photoUrls }) => Boolean(name) && photoUrls?.length
        ) as Item[];
};
/* eslint-enable camelcase */
