import React from 'react';

import { useTranslation } from 'react-i18next';

import { ApiTask } from '~/api/types';

import { PopupDriverContainer } from '../PopupDriverContainer';
import { TaskCard } from '../TaskCard';
import { useLiveDispatchAssignTasks } from '../useLiveDispatchAssignTasks';

import './PopupBody.scss';

export const PopupBody: React.FC = () => {
    const { t } = useTranslation(['liveDispatchTable']);

    const { isFiltered, unassignedTasks } = useLiveDispatchAssignTasks();

    return (
        <div
            className="live-dispatch-popup-body"
            data-testid="live-dispatch-popup-body"
        >
            <span
                className="live-dispatch-popup-body__title"
                data-testid="live-dispatch-popup-body__title"
            >
                {t('dispatchPopup.body.title')}
            </span>
            <span
                className="live-dispatch-popup-body__subtitle"
                data-testid="live-dispatch-popup-body__subtitle"
            >
                {t('dispatchPopup.body.subtitle')}
            </span>
            {isFiltered && Boolean(unassignedTasks.length) && (
                <div
                    className="live-dispatch-tasks-container"
                    data-testid="live-dispatch-tasks-container"
                >
                    {unassignedTasks.map((eachTask: ApiTask) => {
                        const { id } = eachTask;
                        return <TaskCard task={eachTask} key={id} />;
                    })}
                </div>
            )}
            <PopupDriverContainer />
        </div>
    );
};
