import classNames from 'classnames';
import {
    GetElementClassNamesProps,
    GetPaginationStatsProps,
    GetPaginationStatsReturn
} from './types';

const getBlockClassNames = ({
    rootClassName,
    customClassName,
    blockName
}: GetElementClassNamesProps) => {
    const conditionalClassNames = {
        [`${rootClassName}__${blockName}`]: blockName,
        [`${customClassName}__${blockName}`]: blockName && customClassName
    };
    return classNames(conditionalClassNames);
};

const getButtonClassNames = ({
    rootClassName,
    customClassName,
    blockName
}: GetElementClassNamesProps) => {
    const conditionalClassNames = {
        [`${rootClassName}__action--${blockName}`]: blockName,
        [`${customClassName}__${blockName}`]: blockName && customClassName
    };
    return classNames(`${rootClassName}__action`, conditionalClassNames);
};

export const getElementClassNames = (props: GetElementClassNamesProps) => {
    const { rootClassName, customClassName } = props;
    const elementClassName = classNames(rootClassName, customClassName);
    const childClassName = getBlockClassNames({
        ...props,
        blockName: 'content'
    });
    const actionsClassName = getBlockClassNames({
        ...props,
        blockName: 'actions'
    });
    const statusClassName = getBlockClassNames({
        ...props,
        blockName: 'status'
    });

    const buttonFirstClassName = getButtonClassNames({
        ...props,
        blockName: 'button-first'
    });

    const buttonPreviousClassName = getButtonClassNames({
        ...props,
        blockName: 'button-previous'
    });

    const buttonNextClassName = getButtonClassNames({
        ...props,
        blockName: 'button-next'
    });

    const buttonLastClassName = getButtonClassNames({
        ...props,
        blockName: 'button-last'
    });

    return {
        elementClassName,
        childClassName,
        actionsClassName,
        statusClassName,
        buttonFirstClassName,
        buttonPreviousClassName,
        buttonNextClassName,
        buttonLastClassName
    };
};

export const getPaginationStats = ({
    totalRecords,
    currentPage,
    totalPages,
    pageSize
}: GetPaginationStatsProps): GetPaginationStatsReturn => {
    const canPaginate =
        totalRecords > 0 && currentPage > 0 && totalPages > 0 && pageSize > 0;

    const defaultStats: GetPaginationStatsReturn = {
        canPaginate,
        isDisabledPrevious: true,
        isDisabledNext: true,
        prevPage: 0,
        nextPage: 0,
        startRecord: 0,
        endRecord: 0,
        totalRecordCount: 0
    };

    if (!canPaginate) return defaultStats;

    const prevPage = currentPage - 1;
    const nextPage = currentPage + 1;

    const isDisabledPrevious = prevPage < 1;
    const isDisabledNext = nextPage > totalPages;

    const prevEndRecord = prevPage * pageSize;
    const startRecord = prevEndRecord + 1;
    const endRecord = Math.min(currentPage * pageSize, totalRecords);

    const stats = {
        canPaginate,
        isDisabledPrevious,
        isDisabledNext,
        prevPage,
        nextPage,
        startRecord,
        endRecord,
        totalRecordCount: totalRecords
    };

    return stats;
};
