import { DateTime, Duration } from 'luxon';
import i18n from '~/i18n';
import dateUtilsHelpers from './date-utils-helpers';
import dateUtilsConverters from './date-utils-converters';
import dateUtilsLocalized from './date-utils-localized';

/**
 * Date Utilities
 *
 * @category Utils
 * @module utils/dateUtils
 *
 * @example
 * import dateUtils from '~/utils/date-utils';
 *
 * @requires module:utils/dateUtilsConverters
 * @borrows module:utils/dateUtilsConverters~convertISODateToJsDate as convertISODateToJsDate
 * @borrows module:utils/dateUtilsConverters~convertISODateToLocaleString as convertISODateToLocaleString
 * @borrows module:utils/dateUtilsConverters~convertISODateToNumericDate as convertISODateToNumericDate
 * @borrows module:utils/dateUtilsConverters~convertJsDateToISODate as convertJsDateToISODate
 * @borrows module:utils/dateUtilsConverters~convertToISODateOnly as convertToISODateOnly
 * @borrows module:utils/dateUtilsConverters~convert24HourDateTimeToISO as convert24HourDateTimeToISO
 * @borrows module:utils/dateUtilsConverters~get24HourTime as get24HourTime
 * @borrows module:utils/dateUtilsConverters~convertMillisecondsToHoursAndMinutesAndSeconds as
 * convertMillisecondsToHoursAndMinutesAndSeconds
 *
 * @requires module:utils/dateUtilsLocalized
 * @borrows module:utils/dateUtilsLocalized~formatMonthYear as formatMonthYear
 * @borrows module:utils/dateUtilsLocalized~formatMonthDay as formatMonthDay
 * @borrows module:utils/dateUtilsLocalized~formatLetterDayOfWeek as formatLetterDayOfWeek
 * @borrows module:utils/dateUtilsLocalized~formatAbbreviatedDate as formatAbbreviatedDate
 * @borrows module:utils/dateUtilsLocalized~getIntWeekDay as getIntWeekDay
 * @borrows module:utils/dateUtilsLocalized~getDurationString as getDurationString
 * @borrows module:utils/dateUtilsLocalized~addDurationToTime as addDurationToTime
 * @borrows module:utils/dateUtilsLocalized~getShiftTime as getShiftTime
 * @borrows module:utils/dateUtilsLocalized~getLocalizedTime as getLocalizedTime
 * @borrows module:utils/dateUtilsLocalized~getTimeWindow as getTimeWindow
 */

/**
 * Check two DateTimes are the same day
 *
 * @param {DateTime} firstDate the first date
 * @param {DateTime} secondDate the second date to compare against
 * @returns {Boolean}
 */
function isSameDay(firstDate: DateTime, secondDate: DateTime): boolean {
    return firstDate.hasSame(secondDate, 'day');
}

/**
 * Checks if the provided date is today.
 *
 * @param {String} date should be in ISO 8601 format
 * @returns {Boolean} the result of the test
 * @see [isSameDay]{@link module:utils/dateUtils~isSameDay}
 */
function isToday(date: string): boolean {
    return isSameDay(DateTime.fromJSDate(new Date()), DateTime.fromISO(date));
}

/**
 * Returns the dates of the first and last date of the month containing the provided date
 *
 * @param date - The date whose month should be used for returning the start and end dates
 * @returns - The start and end date of the month of the provided date
 */
function getMonthRange(date: Date | DateTime): [DateTime, DateTime] {
    const datetime = (() => {
        if (DateTime.isDateTime(date)) {
            return date;
        }
        return DateTime.fromJSDate(date);
    })();

    return [datetime.startOf('month'), datetime.endOf('month')];
}

/**
 * Returns the dates of the first and last date of the week containing the provided date
 *
 * @param date - The date whose week should be used for returning the start and end dates
 * @returns - The start and end date of the month of the provided date
 */
function getWeekRange(date: Date | DateTime): [DateTime, DateTime] {
    const datetime = (() => {
        if (DateTime.isDateTime(date)) {
            return date;
        }
        return DateTime.fromJSDate(date);
    })().plus({ days: 1 });

    return [
        datetime.startOf('week').minus({ days: 1 }),
        datetime.endOf('week').minus({ days: 1 })
    ];
}

/**
 * Generates an array of dates in ISO 8601 format
 *
 * @param {String} startDate - start date in ISO 8601 format
 * @param {Number} numDays - the number of days to back track from start date
 * @param {Boolean} sortAsc - whether to sort results ascending
 * @returns {String[]} array of dates in ISO 8601 format
 */
function getDateRange(startDate: string, numDays: number): string[] {
    if (!startDate) {
        throw new TypeError('start date not provided');
    }

    const startDateTime = DateTime.fromISO(startDate);

    if (!startDateTime.isValid) {
        throw new TypeError('start date is not in ISO format');
    }

    return Array.from(Array(numDays).keys()).map((days) =>
        startDateTime.minus({ days }).toISODate()
    );
}

/**
 * Formats time in ms to XX hr XX min
 *
 * @param {Number} timeValues - time in ms
 * @param {Boolean} options
 * @param {Boolean} options.isSecondsRequired - will include seconds if set to true
 * @param {Boolean} options.isWithSecondsOnlyIfLessThanMinute - will include seconds if time is less than one min
 * @returns {String} formatted time string in form XX hr XX min by default
 */
function getFormattedTimeString(
    timeValues: number,
    {
        isSecondsRequired = false,
        isWithSecondsOnlyIfLessThanMinute = false
    } = {}
): string {
    const hoursMinutesSeconds =
        dateUtilsConverters.convertMillisecondsToHoursAndMinutesAndSeconds(
            timeValues
        );

    const formattedDuration = [];
    const { hours, minutes, seconds } = hoursMinutesSeconds;
    const isLessThanOneMinute = !hours && !minutes;

    if (hours) {
        formattedDuration.push(
            i18n.t('driverBreak:duration_hours', {
                hours
            })
        );
    }
    if (minutes) {
        formattedDuration.push(
            i18n.t('driverBreak:duration_min', {
                minutes
            })
        );
    }

    if (
        isSecondsRequired ||
        (isWithSecondsOnlyIfLessThanMinute && isLessThanOneMinute)
    ) {
        formattedDuration.push(
            i18n.t('driverBreak:duration_sec', {
                seconds
            })
        );
    }

    return formattedDuration.join(' ');
}

/**
 * Converts an ISO string representing a duration to an object containing duration units.
 * @param {string} serviceTime - The ISO string representing the duration.
 * @returns {Object} - An object containing duration units (hours, minutes, seconds).
 */
const convertISOStringToDurationObjectUnits = (serviceTime: string) => {
    return Duration.fromISO(serviceTime)
        .shiftTo('hours', 'minutes', 'seconds')
        .normalize()
        .toObject();
};

const formatEquipmentUpdatedAtDate = (
    updatedAt: Date,
    format: 'default' | 'alternate' = 'default'
) => {
    const month = (updatedAt.getMonth() + 1).toString().padStart(2, '0');
    const day = updatedAt.getDate().toString().padStart(2, '0');
    const year = updatedAt.getFullYear();
    const hours = updatedAt.getHours().toString().padStart(2, '0');
    const minutes = updatedAt.getMinutes().toString().padStart(2, '0');

    if (format === 'alternate') {
        return `${hours}:${minutes} ${year}/${month}/${day}`;
    }

    return `${month}/${day}/${year} ${hours}:${minutes}`;
};

export const DAYS_OF_WEEK = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
};

export type WeekdayNumber = keyof typeof DAYS_OF_WEEK;

/**
 * Gets day of the week from an array index
 * @param {Number} index
 * @returns {DAYS_OF_WEEK}
 */
const getWeekdayFromIndex = (index: number) => {
    const dayIndex = index as WeekdayNumber;
    return DAYS_OF_WEEK[dayIndex];
};

export default {
    ...dateUtilsHelpers,
    ...dateUtilsConverters,
    ...dateUtilsLocalized,
    isSameDay,
    isToday,
    getMonthRange,
    getWeekRange,
    getDateRange,
    getFormattedTimeString,
    convertISOStringToDurationObjectUnits,
    formatEquipmentUpdatedAtDate,
    getWeekdayFromIndex
};
