import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/ui';

import { ViewColumnsDropdownHeaderProps } from './types';

import './ViewColumnsDropdownHeader.scss';

const ViewColumnsDropdownHeader: React.FC<ViewColumnsDropdownHeaderProps> = ({
    setShowDropdown
}) => {
    const { t } = useTranslation(['liveDispatchTable']);
    return (
        <div className="live-dispatch-view-columns-dropdown-header">
            <Icon icon="visibleOn" size="s" color="galaxy-300" />
            <span
                data-testid="live-dispatch-view-columns-dropdown-header__label"
                className="live-dispatch-view-columns-dropdown-header__label"
            >
                {t('viewColumns.buttonLabel')}
            </span>
            <Icon
                icon="cancel"
                size="s"
                color="galaxy-800"
                onClick={() => setShowDropdown((prevState) => !prevState)}
                data-testid="live-dispatch-view-columns-dropdown-header__cancel-icon"
            />
        </div>
    );
};

export default ViewColumnsDropdownHeader;
