import React from 'react';
import { UnassignedTasksTableDataProps } from '../types';
import { LiveDispatchTableData } from '../LiveDispatchTableRow/LiveDispatchTableData';
import { LiveDispatchTableDataLabels } from '../LiveDispatchTableRow/LiveDispatchTableDataLabels';
import { LiveDispatchTableDataLinks } from '../LiveDispatchTableRow/LiveDispatchTableDataLinks';
import { LiveDispatchTableDataNotes } from '../LiveDispatchTableRow/LiveDispatchTableDataNotes';
import { LiveDispatchTableDataTaskType } from '../LiveDispatchTableRow/LiveDispatchTableDataTaskType';
import { useLiveDispatchTableContext } from '../LiveDispatchTableContext';

import { ApiNote } from '~/api/types';
import { LiveDispatchTableDataDate } from '../LiveDispatchTableRow/LiveDispatchTableDataDate';
import { LiveDispatchTableDataTaskPriority } from '../LiveDispatchTableRow/LiveDispatchTableDataTaskPriority';
import { isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

const ROOT_CLASS_NAME = 'unassigned-tasks-table-data';

export const UnassignedTasksTableData = ({
    cell
}: UnassignedTasksTableDataProps) => {
    const { globalFilter } = useLiveDispatchTableContext();
    const { t } = useTranslation(['liveDispatchTable']);
    const isCellValueNull = isNull(cell.getValue());
    const cellValue = isCellValueNull
        ? t('common:N/A')
        : (cell.getValue() as string);

    return (
        <LiveDispatchTableData
            data={cellValue}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
            searchText={globalFilter}
            truncate
        />
    );
};

export const UnassignedTasksTableDataLabels = ({
    cell
}: UnassignedTasksTableDataProps) => {
    const { globalFilter } = useLiveDispatchTableContext();

    return (
        <LiveDispatchTableDataLabels
            data={cell.getValue() as string[]}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
            searchText={globalFilter}
            truncate
        />
    );
};

export const UnassignedTasksTableDataLinks = ({
    cell
}: UnassignedTasksTableDataProps) => {
    return (
        <LiveDispatchTableDataLinks
            data={cell.getValue() as string[]}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
            truncate
        />
    );
};

export const UnassignedTasksTableDataNotes = ({
    cell
}: UnassignedTasksTableDataProps) => {
    const { globalFilter } = useLiveDispatchTableContext();

    return (
        <LiveDispatchTableDataNotes
            data={cell.getValue() as ApiNote[]}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
            searchText={globalFilter}
            truncate
        />
    );
};

export const UnassignedTasksTableDataDate = ({
    cell
}: UnassignedTasksTableDataProps) => {
    const cellValue = cell.getValue() as string;
    const formattedTime = cellValue
        ? DateTime.fromISO(cellValue).toFormat('yyyy-MM-dd, HH:mm')
        : null;

    return (
        <LiveDispatchTableDataDate
            data={formattedTime}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
        />
    );
};

export const UnassignedTasksTableDataTaskType = ({
    cell
}: UnassignedTasksTableDataProps) => {
    return (
        <LiveDispatchTableDataTaskType
            data={cell.getValue() as string}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
        />
    );
};

export const UnassignedTasksTableDataTaskPriority = ({
    cell
}: UnassignedTasksTableDataProps) => {
    return (
        <LiveDispatchTableDataTaskPriority
            data={cell.getValue() as string}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
        />
    );
};
