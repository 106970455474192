import { markerMaker } from '~/utils/map';

function makeMarkers(
    superCluster,
    geoJSONFeatures,
    superClusterIndex,
    emittedEventHandler,
    coordinatesOffset
) {
    const markers = [];
    for (const geoJSONFeature of geoJSONFeatures) {
        const isCluster = geoJSONFeature.properties.cluster;
        let marker = null;
        if (isCluster) {
            marker = markerMaker.makeClusterMarker({
                superCluster,
                geoJSONFeature,
                superClusterIndex,
                emittedEventHandler
            });
        } else {
            marker = markerMaker.makeStopMarker(
                geoJSONFeature,
                coordinatesOffset,
                emittedEventHandler
            );
        }
        markers.push(marker);
    }
    return markers;
}

export default makeMarkers;
