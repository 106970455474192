import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/ui';
import { useLiveDispatchAssignTasks } from '../useLiveDispatchAssignTasks';

import './DispatchButton.scss';

export const DispatchButton: React.FC = () => {
    const { t } = useTranslation(['liveDispatchTable']);

    const { handleDispatchButtonClick, driverData } =
        useLiveDispatchAssignTasks();

    return (
        <button
            type="button"
            onClick={handleDispatchButtonClick}
            className="live-dispatch-button"
            data-testid="live-dispatch-button"
            disabled={isEmpty(driverData)}
        >
            <div
                className="live-dispatch-button__wrapper"
                data-testid="live-dispatch-button__wrapper"
            >
                <Icon icon="route" size="m" color="comet" />
                <span
                    data-testid="live-dispatch-button__label"
                    className="live-dispatch-button__label"
                >
                    {t('dispatchPopup.buttonLabel')}
                </span>
            </div>
        </button>
    );
};
