function findRoomsToJoin(activeClientsData, roomsToJoinByClientId) {
    const roomsToJoin = [];
    for (const roomToJoin in roomsToJoinByClientId) {
        if (!activeClientsData[roomToJoin]) {
            roomsToJoin.push(roomToJoin);
        }
    }
    return roomsToJoin;
}

function findRoomsToLeave(activeClientsData, roomsToJoinByClientId) {
    const roomsToLeave = [];
    for (const activeClient in activeClientsData) {
        if (!roomsToJoinByClientId[activeClient]) {
            roomsToLeave.push(activeClient);
        }
    }
    return roomsToLeave;
}

export const socketUtils = {
    findRoomsToJoin,
    findRoomsToLeave
};
