import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { LiveDispatchTaskTableRowData } from '~/data-classes';
import { TableHeaderProps, LiveDispatchTableColumnId } from '../../types';
import { useLiveDispatchTableStates } from '../../useLiveDispatchTableStates';
import { getExtTaskTypeFilterList, ExtTypeFilterList } from './utils';
import { TableSortElement } from '../../TableSortElement/TableSortElement';
import { TableFilterElement } from '../../TableFilterElement';

const ROOT_CLASS_NAME = 'live-dispatch-table-header';

interface CheckboxHandler {
    isChecked: boolean;
    value?: string | number | unknown;
    selectedValues?: Array<string | number | unknown>;
}

export const LiveDispatchTableHeaderExtTaskType = ({
    header,
    children,
    className,
    ...extra
}: TableHeaderProps<LiveDispatchTaskTableRowData>) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { id } = header || {};
    const headerText = id && t(`columns.taskTable.${id}`);
    const defaultTestId = id ? `${ROOT_CLASS_NAME}__${id}` : ROOT_CLASS_NAME;
    const dataTestId = extra['data-testid'] || defaultTestId;
    const elementClassName = classNames(ROOT_CLASS_NAME, className, {
        [`${ROOT_CLASS_NAME}__${id}`]: id
    });

    const [selectedExtTypeFilter, setSelectedExtTypeFilter] = useState<
        string[]
    >([]);
    const { columnFilters, setColumnFilters, onApplySorting, onClearSorting } =
        useLiveDispatchTableStates();

    const isPredefinedExternalTaskTypeEnabled = useFeatureFlag(
        FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES
    );

    const unassignedTasks = useMemo<LiveDispatchTaskTableRowData[]>(() => {
        const headerContext = header?.getContext();
        if (!headerContext) return [];

        const { table } = headerContext;
        const { rows: coreRowModel } = table.getCoreRowModel();
        return coreRowModel.map((row) => row.original);
    }, [header]);

    const extTaskTypeFilterList = useMemo<ExtTypeFilterList[]>(() => {
        return getExtTaskTypeFilterList({
            unassignedTasks,
            isPredefinedExternalTaskTypeEnabled
        });
    }, [unassignedTasks, isPredefinedExternalTaskTypeEnabled]);

    const handleExtTypeFilterCheckboxClick = ({
        isChecked,
        value
    }: CheckboxHandler) => {
        if (isChecked) {
            setSelectedExtTypeFilter([
                ...selectedExtTypeFilter,
                value as string
            ]);
        } else {
            const removedEntryList = selectedExtTypeFilter.filter(
                (data) => data !== value
            );
            setSelectedExtTypeFilter([...removedEntryList]);
        }
    };

    const onClearStatusFilter = () => {
        setSelectedExtTypeFilter([]);
        const updatedColumnFilter = columnFilters.filter(
            (data) => data.id !== LiveDispatchTableColumnId.EXT_TASK_TYPE
        );
        setColumnFilters([...updatedColumnFilter]);
    };

    const onApplyStatusFilter = () => {
        if (selectedExtTypeFilter.length) {
            const filterFromExtTaskType = columnFilters.filter(
                (data) => data.id !== LiveDispatchTableColumnId.EXT_TASK_TYPE
            );

            setColumnFilters([
                ...filterFromExtTaskType,
                {
                    id: LiveDispatchTableColumnId.EXT_TASK_TYPE,
                    value: selectedExtTypeFilter
                }
            ]);
        } else {
            onClearStatusFilter();
        }
    };

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            {header?.column.columnDef.enableSorting && id && (
                <TableSortElement
                    columnId={id}
                    onClearSorting={onClearSorting}
                    onApplySorting={onApplySorting}
                />
            )}
            {header?.column.columnDef.enableColumnFilter && (
                <TableFilterElement
                    headerText={headerText}
                    headerId={id || ''}
                    columnFilters={columnFilters}
                    filterList={extTaskTypeFilterList}
                    selectedFilterList={selectedExtTypeFilter}
                    handleFilterCheckboxClick={handleExtTypeFilterCheckboxClick}
                    onClearStatusFilter={onClearStatusFilter}
                    onApplyStatusFilter={onApplyStatusFilter}
                />
            )}
            {children}
            <span data-testid={`${dataTestId}-text`}>{headerText}</span>
        </div>
    );
};
