import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMapUtils } from '~/hooks';
import { selectIsClusteringEquipment } from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { RouteCardText, RouteCardTitle } from '../RouteCard';
import { EquipmentLabels } from './EquipmentLabels';
import { getLastUpdatedFromTimestamp } from './utils';
import { EquipmentDetailsPopupProps } from './types';
import { useEquipmentDetailsPopup } from './useEquipmentDetailsPopup';
import { IconSizes } from '../Icon/IconSizes';

import './EquipmentDetailsPopup.scss';

export const testId = 'equipment-details-popup';

export const EquipmentDetailsPopup = ({
    equipmentId
}: EquipmentDetailsPopupProps) => {
    const { mapRouteMode } = useMapUtils();
    const isCluster = useSelector(
        selectIsClusteringEquipment(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );

    const { hoveredEquipment, setEquipmentId, additionalData } =
        useEquipmentDetailsPopup();

    useEffect(() => {
        setEquipmentId(equipmentId);
    }, [equipmentId, setEquipmentId]);

    if (!hoveredEquipment) {
        return null;
    }

    const { name, id, status, type, timestamp, attached, state } =
        hoveredEquipment;

    const getIdClassName = () => {
        const defaultClassName = 'route-card-text';
        const conditionalClassNames = {
            'route-card-text__id-with-status': status
        };
        return classNames(defaultClassName, conditionalClassNames);
    };

    const getWrapperClassName = () => {
        const defaultClassName = 'equipment-details-popup marker-popup';
        const conditionalClassNames = {
            'cluster-mode': isCluster
        };
        return classNames(defaultClassName, conditionalClassNames);
    };

    const lastUpdated = getLastUpdatedFromTimestamp(timestamp);

    return (
        <div
            className={getWrapperClassName()}
            data-testid="equipment-details-popup"
        >
            {name && (
                <div className="selected-card-item">
                    <RouteCardTitle
                        title={name}
                        tooltip={name}
                        data-testid={`${testId}-title`}
                    />
                    {Boolean(state?.length) && (
                        <span
                            className="task-type-chip"
                            data-testid={`${testId}-state`}
                        >
                            {state}
                        </span>
                    )}
                </div>
            )}
            {id && (
                <div className="selected-card-item">
                    <RouteCardText
                        className={getIdClassName()}
                        icon="list"
                        iconColor="galaxy-800"
                        iconSize={IconSizes.S}
                        data-testid={`${testId}-id`}
                    >
                        {id}
                    </RouteCardText>
                    {status && (
                        <span
                            className="task-type-chip"
                            data-testid={`${testId}-task-status`}
                        >
                            {status}
                        </span>
                    )}
                </div>
            )}

            {type && (
                <div className="selected-card-item">
                    <RouteCardText
                        className="route-card-text"
                        icon="equipmentType"
                        iconColor="galaxy-800"
                        iconSize={IconSizes.S}
                        data-testid={`${testId}-type`}
                    >
                        {type}
                    </RouteCardText>
                </div>
            )}

            {name && (
                <div className="selected-card-item">
                    <RouteCardText
                        className="route-card-text"
                        icon="pin"
                        iconColor="galaxy-800"
                        iconSize={IconSizes.S}
                        data-testid={`${testId}-name`}
                    >
                        {name}
                    </RouteCardText>
                </div>
            )}

            {Boolean(attached?.length) && (
                <div className="selected-card-item">
                    <RouteCardText
                        className={classNames('route-card-text', 'attached')}
                        icon="attached"
                        iconColor="galaxy-800"
                        iconSize={IconSizes.S}
                        data-testid={`${testId}-attached`}
                    >
                        {attached?.join(', ')}
                    </RouteCardText>
                </div>
            )}
            {lastUpdated && (
                <div className="selected-card-item">
                    <RouteCardText
                        className="route-card-text"
                        icon="calendarClock"
                        iconColor="galaxy-800"
                        iconSize={IconSizes.S}
                        data-testid={`${testId}-last-updated`}
                    >
                        Last Updated: {lastUpdated}
                    </RouteCardText>
                </div>
            )}
            {Boolean(additionalData?.length) && (
                <div className="selected-card-item">
                    <EquipmentLabels
                        className={classNames(
                            'route-card-text',
                            'additional-data'
                        )}
                        data-testid={`${testId}-additional-data`}
                        labels={additionalData as string[]}
                    />
                </div>
            )}
        </div>
    );
};
