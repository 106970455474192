import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';

type ReturnProps = {
    isSaveDisabled: boolean;
    onSave: () => void;
};

export const useSaveButtonProps = (
    isSaveInitiallyDisabled = true
): ReturnProps => {
    const { isSubmitting, isValid, submitForm, touched } = useFormikContext();

    const isSaveDisabled = isSaveInitiallyDisabled
        ? !isValid || isSubmitting || _.isEmpty(touched)
        : !isValid || isSubmitting;

    const onSave = useCallback(() => {
        submitForm().catch((e) => console.error(e));
    }, [submitForm]);

    return {
        isSaveDisabled,
        onSave
    };
};
