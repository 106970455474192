import React from 'react';

import { Icon } from '~/ui';

import './PopupHeader.scss';

interface PopupHeaderProps {
    title: string;
    onCancel: () => void;
    onLeftArrowClick: () => void;
}

const rootClassName = 'live-dispatch-popup-header';

export const PopupHeader = ({
    title,
    onCancel,
    onLeftArrowClick
}: PopupHeaderProps) => {
    return (
        <div className={rootClassName} data-testid={rootClassName}>
            <button
                className={`${rootClassName}__wrapper`}
                onClick={onLeftArrowClick}
                type="button"
            >
                <Icon
                    icon="arrowLeft"
                    stroke="meteor"
                    size="m"
                    data-testid={`${rootClassName}__left-arrow-icon`}
                />
                <span
                    className={`${rootClassName}__title`}
                    data-testid={`${rootClassName}__title`}
                >
                    {title}
                </span>
            </button>
            <Icon
                icon="cancel"
                size="m"
                onClick={onCancel}
                data-testid={`${rootClassName}__cancel-icon`}
            />
        </div>
    );
};
