import React, { useState } from 'react';
import { ViewColumnsButton } from './ViewColumnsButton';
import { ViewColumnsDropdownHeader } from './ViewColumnsDropdownHeader';
import { ViewColumnsDropdownList } from './ViewColumnsDropdownList';
import { useLiveDispatchColumnVisibility } from './useLiveDispatchColumnVisibility';
import './LiveDispatchViewColumns.scss';
import { useLiveDispatchColumnReorder } from './useLiveDispatchColumnReorder';
import { LiveDispatchViewColumnsProps } from './types';

const LiveDispatchViewColumns = ({
    columnIds,
    columnVisibility,
    setColumnVisibility,
    columnOrder,
    setColumnOrder
}: LiveDispatchViewColumnsProps) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const { dropdownColumns, handleToggleVisibility } =
        useLiveDispatchColumnVisibility({
            columnIds,
            columnVisibility,
            setColumnVisibility,
            columnOrder
        });

    const { reorderColumns } = useLiveDispatchColumnReorder({
        columnOrder,
        setColumnOrder
    });

    const toggleDropdown = () => {
        setShowDropdown((prevState) => !prevState);
    };

    return (
        <div
            className="live-dispatch-view-columns"
            data-testid="live-dispatch-view-columns"
        >
            <ViewColumnsButton
                showDropdown={showDropdown}
                toggleDropdown={toggleDropdown}
            />
            {showDropdown && (
                <div
                    className="live-dispatch-view-columns__dropdown-content"
                    data-testid="live-dispatch-view-columns__dropdown-content"
                >
                    <ViewColumnsDropdownHeader
                        setShowDropdown={setShowDropdown}
                    />
                    <ViewColumnsDropdownList
                        dropdownColumns={dropdownColumns}
                        handleToggleVisibility={handleToggleVisibility}
                        handleReorderColumns={reorderColumns}
                    />
                </div>
            )}
        </div>
    );
};

export default LiveDispatchViewColumns;
