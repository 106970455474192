import React from 'react';
import { useTranslation } from 'react-i18next';
import { LiveDispatchTableData } from '../LiveDispatchTableData';
import { LiveDispatchTableDataProps } from '../../types';

export const LiveDispatchTableDataTaskPriority = ({
    data,
    className,
    'data-testid': dataTestId
}: LiveDispatchTableDataProps<string>) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const taskPriority = data && t(`taskPriority.${data}`);

    return (
        <LiveDispatchTableData
            data={taskPriority}
            className={className}
            data-testid={dataTestId}
        />
    );
};
