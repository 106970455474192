import React from 'react';

import './PopupContentHeader.scss';

interface PopupBodyContentHeaderProps {
    title: string;
    subtitle: string;
}

const rootClassName = 'live-dispatch-popup-content-header';

export const PopupContentHeader = ({
    title,
    subtitle
}: PopupBodyContentHeaderProps) => {
    return (
        <div
            className={rootClassName}
            data-testid="live-dispatch-popup-content-header"
        >
            <span
                className={`${rootClassName}__title`}
                data-testid={`${rootClassName}__title`}
            >
                {title}
            </span>
            <span
                className={`${rootClassName}__subtitle`}
                data-testid={`${rootClassName}__subtitle`}
            >
                {subtitle}
            </span>
        </div>
    );
};
