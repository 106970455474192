import React, { Fragment } from 'react';
import { ValueWithUnit } from './types';

const rootTestId = 'route-card-metric-values-with-units';
const valueTestIdValue = `${rootTestId}_value`;
const unitTestIdValue = `${rootTestId}_units`;

interface RouteCardMetricValuesWithUnitsProps {
    /**
     * The class name of each unit
     */
    unitsClassName: string;
    /**
     * The class name of each value
     */
    valuesClassName: string;
    /**
     * The list of values and their units
     */
    valuesWithUnits?: ValueWithUnit[];
}
export const RouteCardMetricValuesWithUnits = ({
    unitsClassName,
    valuesClassName,
    valuesWithUnits
}: RouteCardMetricValuesWithUnitsProps) => {
    return (
        <>
            {valuesWithUnits?.map(({ value, unit }) => {
                return (
                    <Fragment key={`${value}_${unit}`}>
                        <span
                            data-testid={valueTestIdValue}
                            className={valuesClassName}
                        >
                            {value}
                        </span>
                        <span
                            data-testid={unitTestIdValue}
                            className={unitsClassName}
                        >
                            {unit}
                        </span>
                    </Fragment>
                );
            })}
        </>
    );
};
