import React from 'react';
import { TextInput } from '~/ui';
import { EditableTextActions } from '~/ui/components/EditableText';
import { UpdateTaskProps } from '~/components/TaskManagementPage/TaskDetailDrawer/useUpdateTask';
import {
    TextInputVariant,
    TextInputWidth
} from '~/ui/components/TextInput/types';
import { useTaskLabelsEdit } from './useTaskLabelsEdit';
import { TaskLabelsEditLabel } from './TaskLabelsEditLabel';
import './TaskLabelsEdit.scss';

interface TaskLabelsEditProps {
    labels: string[];
    taskId: string;
    onClose: () => void;
    onTaskUpdate: (data: UpdateTaskProps) => Promise<boolean>;
}

export const TaskLabelsEdit = ({
    labels,
    onClose,
    onTaskUpdate,
    taskId
}: TaskLabelsEditProps) => {
    const rootClassName = 'task-labels-edit';
    const {
        onDeleteLabel,
        onKeyUp,
        inputText,
        isUpdating,
        savedLabels,
        onSave
    } = useTaskLabelsEdit({ labels, onClose, onTaskUpdate, taskId });

    return (
        <div className={`${rootClassName} _fd-column _p-relative _w-100`}>
            <div
                className={`${rootClassName}__content _d-flex _ai-center _fw-wrap _w-100`}
            >
                {savedLabels.map((label) => (
                    <TaskLabelsEditLabel
                        key={label}
                        isDisabled={isUpdating}
                        label={label}
                        onDelete={onDeleteLabel}
                    />
                ))}
                <TextInput
                    disabled={isUpdating}
                    className={`${rootClassName}__label-input`}
                    value={inputText}
                    width={TextInputWidth.FULLWIDTH}
                    variant={TextInputVariant.EDIT}
                    onKeyUp={onKeyUp}
                />
            </div>
            <EditableTextActions
                isDisableButtons={isUpdating}
                onClickCancel={onClose}
                onClickCheck={onSave}
            />
        </div>
    );
};
