import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';

import { useFeatureFlag } from '../useFeatureFlag';
import { FeatureFlag } from '~/utils/feature-flags-utils';

export const useFormatTimeToClientTimeZone = () => {
    const mainClient = useSelector(selectMainClient);
    const isClientTimezoneFlagEnabled = useFeatureFlag(
        FeatureFlag.CLIENT_TIMEZONE
    );
    const { timezone: selectedClientTimeZone } = mainClient || {};

    const formatTimeToClientTimeZone = (
        time: string,
        format = 't'
    ): string | null => {
        const dateTime = DateTime.fromISO(time);

        if (!dateTime.isValid) return null;

        if (isClientTimezoneFlagEnabled) return dateTime.toFormat(format);

        return dateTime.setZone(selectedClientTimeZone).toFormat(format);
    };

    return { formatTimeToClientTimeZone };
};
