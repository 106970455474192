import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Icon, LabelInput } from '~/ui';
import inventoryItemsApi from '~/api/InventoryItemsApi';
import { addToast } from '~/reducers/toastsSlice';
import { useInventoryItem } from '~/hooks';

import './InventoryItemEditModalContent.css';

function InventoryItemEditModalContent({
    id,
    itemName,
    barcodeId,
    quantity,
    weight,
    hideModal,
    onUpdate
}) {
    const { t } = useTranslation(['common', 'taskManagement', 'error']);
    const [newItemName, setNewItemName] = useState(itemName || '');
    const [newBarcodeId, setNewBarcodeId] = useState(barcodeId || '');
    const [newQuantity, setNewQuantity] = useState(quantity);
    const [newWeight, setNewWeight] = useState(weight);
    const dispatch = useDispatch();

    const { deleteInventoryItem } = useInventoryItem();

    const onClickDelete = () => {
        deleteInventoryItem(id);
        hideModal();
    };

    function onClickCancel() {
        hideModal();
    }

    async function onClickSave() {
        const payload = {
            item_name: newItemName,
            barcode_id: newBarcodeId,
            actual_quantity: Number(newQuantity),
            unit_weight: Number(newWeight)
        };

        try {
            await inventoryItemsApi.updateInventoryItem(id, payload);
            onUpdate(payload);
            hideModal();
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('error:inventoryItemsFetchError'),
                    variant: 'error'
                })
            );
        }
    }
    return (
        <div className="inventoryitemeditmodal">
            <div className="modal-header">
                <div className="_d-flex">
                    <span>{t('taskManagement:inventoryItem.edit')}</span>
                </div>
                <button type="button" className="icon-wrapper">
                    <Icon
                        icon="cancel"
                        color="galaxy-800"
                        onClick={hideModal}
                    />
                </button>
            </div>
            <div className="inventoryitemeditmodal-content _d-flex _fd-column">
                <div className="_d-flex _cg-8">
                    <div className="_fd-column _w-50">
                        <LabelInput
                            label={t('taskManagement:inventoryItem.itemName')}
                            type="text"
                            placeholder={t(
                                'taskManagement:inventoryItem.itemName'
                            )}
                            value={newItemName}
                            onChange={(e) => setNewItemName(e.target.value)}
                        />
                    </div>
                    <div className="_fd-column _w-50">
                        <LabelInput
                            label={t('taskManagement:inventoryItem.barcodeId')}
                            type="text"
                            placeholder={t(
                                'taskManagement:inventoryItem.barcodeId'
                            )}
                            value={newBarcodeId}
                            onChange={(e) => setNewBarcodeId(e.target.value)}
                        />
                    </div>
                </div>
                <div className="_d-flex _cg-8">
                    <div className="_fd-column _w-50">
                        <LabelInput
                            label={t('taskManagement:inventoryItem.quantity')}
                            type="text"
                            placeholder={t(
                                'taskManagement:inventoryItem.quantity'
                            )}
                            value={String(newQuantity)}
                            onChange={(e) => setNewQuantity(e.target.value)}
                        />
                    </div>
                    <div className="_fd-column _w-50">
                        <LabelInput
                            label={t('taskManagement:inventoryItem.weight')}
                            type="text"
                            placeholder={t(
                                'taskManagement:inventoryItem.weight'
                            )}
                            value={String(newWeight)}
                            onChange={(e) => setNewWeight(e.target.value)}
                        />
                    </div>
                </div>
                <div className="_d-flex _jc-space-between _mt-20">
                    <Button
                        variant="primary-dismissive"
                        onClick={onClickDelete}
                    >
                        {t('delete')}
                    </Button>
                    <div className="_d-flex _jc-space-evenly _cg-6">
                        <Button variant="secondary" onClick={onClickCancel}>
                            {t('cancel')}
                        </Button>
                        <Button onClick={onClickSave}>{t('save')}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InventoryItemEditModalContent;
