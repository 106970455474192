import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { selectEquipmentById, setEquipment } from '~/reducers/equipmentSlice';
import { Equipment } from '~/data-classes';
import { useGetEquipmentList } from '~/hooks';
import { ApiEquipmentBulk } from '~/api/EquipmentApi/types';
import { useEquipmentTableContext } from '../EquipmentTableContext';
import { useEquipmentTableStates } from '../useEquipmentTableStates';
import { equipmentColumnIdToColumnFilterKeyMap } from '../types';
import constants from '~/utils/constants';

export const useEquipmentTableData = () => {
    const dispatch = useDispatch();

    const {
        setTotalEquipmentCount,
        setPageIndex,
        setPageTotal,
        apiPaginationOptions
    } = useEquipmentTableContext();

    const {
        columnFilters,
        sorting: equipmentSorting,
        globalFilter: term
    } = useEquipmentTableStates();

    const onSuccess = (data: ApiEquipmentBulk) => {
        const {
            data: apiEquipmentData,
            totalCount,
            currentPageNumber,
            totalPageNumber
        } = data;

        setTotalEquipmentCount(totalCount);
        setPageIndex(currentPageNumber);
        setPageTotal(totalPageNumber);

        dispatch(setEquipment(apiEquipmentData || []));
    };

    const apiOptions = useMemo(() => {
        const searchOptions = columnFilters.reduce<Record<string, string>>(
            (acc, { id, value }) => {
                const filterKey = equipmentColumnIdToColumnFilterKeyMap[id];
                if (filterKey) acc[filterKey] = (value as string[]).join(',');
                return acc;
            },
            {}
        );

        if (term?.length) searchOptions.term = term;

        let sorting;
        if (equipmentSorting && equipmentSorting.length) {
            const [{ id, desc }] = equipmentSorting;
            sorting = {
                sort: id,
                order: desc ? constants.sortOrder.DESC : constants.sortOrder.ASC
            };
        }

        return { ...apiPaginationOptions, ...searchOptions, ...sorting };
    }, [term, apiPaginationOptions, columnFilters, equipmentSorting]);

    const { data: equipmentData } = useGetEquipmentList({
        apiOptions,
        reactQueryOptions: {
            onSuccess
        }
    });

    const { data: equipmentList } = equipmentData || { data: [] };

    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);
    const selectedEquipment = useSelector(
        selectEquipmentById(selectedEquipmentIds)
    );

    /**
     * whether the equipment list array will be filtered
     */
    const isFiltered = Boolean(
        equipmentList?.length && selectedEquipmentIds?.length
    );

    let currentPageNumber;
    let totalPageNumber;

    if (equipmentData) {
        currentPageNumber = equipmentData.currentPageNumber;
        totalPageNumber = equipmentData.totalPageNumber;
    }

    const selectedEquipmentData = useMemo<Equipment[]>(() => {
        return selectedEquipment.map(
            (apiEquipment) => new Equipment(apiEquipment)
        );
    }, [selectedEquipment]);

    const unselectedEquipmentData = useMemo<Equipment[]>(() => {
        const data = (equipmentList || []).map(
            (apiEquipment) => new Equipment(apiEquipment)
        );

        return data.filter((item) => {
            return !selectedEquipmentIds.includes(item.id);
        });
    }, [equipmentList, selectedEquipmentIds]);

    return {
        isFiltered,
        unselectedEquipmentData,
        selectedEquipmentData,
        currentPageNumber,
        totalPageNumber
    };
};
