import React from 'react';
import './NotificationStatus.scss';
import { Icon } from '~/ui';
import classNames from 'classnames';
import constants from '~/utils/constants';
import { useTranslation } from 'react-i18next';

function NotificationStatus({
    title,
    status,
    onClick = () => {},
    disabled = false
}) {
    const { t } = useTranslation('taskManagement');

    return (
        <button
            type="button"
            className="notification-status _ai-center button_clear"
            data-testid="notification-status"
            onClick={onClick}
            disabled={disabled}
        >
            <div
                className={classNames('_d-flex _ai-center _cg-2', {
                    'notification-status--inactive':
                        status !==
                        constants.notifications.notificationStatusLifecycle.sent
                })}
            >
                <Icon icon="sendOutline" size="s" />
                <div className="notification-status__title">{title}</div>
            </div>
            <div
                data-testid="notification-status-statusText"
                className={classNames('notification-status--active', {
                    'notification-status--inactive notification-status--background-inactive':
                        status !==
                        constants.notifications.notificationStatusLifecycle.sent
                })}
            >
                {t(`notificationStatus.status.${status}`)}
            </div>
        </button>
    );
}

export default NotificationStatus;
