import React from 'react';
import { EquipmentTableDataProps } from '../types';
import { LiveDispatchTableData } from '../LiveDispatchTableRow/LiveDispatchTableData';
import { LiveDispatchTableDataLabels } from '../LiveDispatchTableRow/LiveDispatchTableDataLabels';
import { useEquipmentTableStates } from '~/components/MapPageDrawers/LiveDispatchDrawer/useEquipmentTableStates';
import { Coordinates } from '~/api/types';

const ROOT_CLASS_NAME = 'equipment-table-data';

export const EquipmentTableData = ({ cell }: EquipmentTableDataProps) => {
    return (
        <LiveDispatchTableData
            data={cell.getValue() as string}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
            truncate
        />
    );
};

export const EquipmentTableDataHighlight = ({
    cell
}: EquipmentTableDataProps) => {
    const { globalFilter } = useEquipmentTableStates();
    return (
        <LiveDispatchTableData
            data={cell.getValue() as string}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
            truncate
            searchText={globalFilter}
        />
    );
};

export const EquipmentTableDataLabels = ({ cell }: EquipmentTableDataProps) => {
    return (
        <LiveDispatchTableDataLabels
            data={cell.getValue() as string[]}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
            truncate
        />
    );
};

export const EquipmentTableDataLabelsHighlight = ({
    cell
}: EquipmentTableDataProps) => {
    const { globalFilter } = useEquipmentTableStates();
    return (
        <LiveDispatchTableDataLabels
            data={cell.getValue() as string[]}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
            truncate
            searchText={globalFilter}
        />
    );
};

export const EquipmentTableDataCoordinates = ({
    cell
}: EquipmentTableDataProps) => {
    const { lng, lat } = cell.getValue() as Coordinates;
    return (
        <LiveDispatchTableData
            data={`${lng}, ${lat}`}
            className={`${ROOT_CLASS_NAME}__${cell.column.id}`}
            truncate
        />
    );
};
