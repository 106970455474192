import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '~/ui';

import { Item } from '../useItemsWithPhotos/utils';
import { PhotoModalContent } from './PhotoModalContent';

interface UsePhotoModalProps {
    /**
     * The list of items
     */
    items: Item[];
    /**
     * The number of photos
     */
    numberOfPhotos: number;
    /**
     * The photo click handler
     */
    onPhotoClick: (name: string, photoUrl: string) => void;
}

export const usePhotoModal = ({
    items,
    numberOfPhotos,
    onPhotoClick
}: UsePhotoModalProps) => {
    const { t } = useTranslation('taskManagement');
    const { showModal, hideModal, modal: PhotoModal } = useModal();

    const handlePhotoClick = (name: string, photoUrl: string) => {
        hideModal();
        onPhotoClick(name, photoUrl);
    };

    const modal = (
        <PhotoModal title={t('proof.imageCount', { count: numberOfPhotos })}>
            <PhotoModalContent items={items} onPhotoClick={handlePhotoClick} />
        </PhotoModal>
    );

    return {
        showModal,
        hideModal,
        modal
    };
};
