/* global H */
import React, { useEffect, useState, useMemo } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import { useHereMapContext } from '~/components/HereMaps/HereMapProvider';

import { store } from '~/store';

export const useHereMapMarkers = ({ markersConfig }) => {
    const { mapInstance } = useHereMapContext();
    const [markups, setMarkups] = useState(false);

    useEffect(() => {
        const renderedMarkups = {};
        markersConfig.forEach(({ id, component }, index) => {
            const isLastOne = index === markersConfig.length - 1;
            const wrapper = document.createElement('div');
            renderedMarkups[id] = wrapper;
            ReactDOM.render(
                <Provider store={store}>{component}</Provider>,
                wrapper,
                // since ReactDOM.render is async, need to persist rendered node
                // so that it can be used to render Here Maps marker
                () => {
                    if (isLastOne) {
                        setMarkups(renderedMarkups);
                    }
                }
            );
        });
        return () => {
            setMarkups(false);
        };
    }, [markersConfig]);

    const outputMarkers = useMemo(() => {
        if (!mapInstance) {
            return null;
        }
        try {
            const finalMarkers = markersConfig
                .map(({ id, coordinates, events }) => {
                    const html = markups[id];
                    if (!html) {
                        return false;
                    }
                    const hereMarker = new H.map.DomIcon(html);
                    const finalMarker = new H.map.DomMarker(coordinates, {
                        icon: hereMarker
                    });
                    events.forEach((event) => {
                        const { event: ev, handler } = event;
                        finalMarker.addEventListener(ev, (evt) => {
                            const { currentPointer } = evt;
                            const { viewportX, viewportY } =
                                currentPointer || {};
                            handler({ viewportX, viewportY });
                        });
                    });
                    return finalMarker;
                })
                .filter(Boolean);
            return finalMarkers;
        } catch (e) {
            console.error('Error rendering Here Maps markers', e);
        }
    }, [markersConfig, mapInstance, markups]);

    return {
        outputMarkers
    };
};
