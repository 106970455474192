import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { syncOnLogin } from '~/reducers/common-actions';
import {
    ColumnFiltersState,
    VisibilityState,
    SortingState,
    ColumnOrderState
} from '@tanstack/react-table';
import { ApiEquipmentAttributes } from '~/api/types';
import type { RootState } from '~/store';

interface EquipmentTableState {
    equipmentAttributes: ApiEquipmentAttributes;
    equipmentColumnFilters: ColumnFiltersState;
    equipmentColumnOrder: ColumnOrderState;
    equipmentColumnVisibility: VisibilityState;
    equipmentSorting: SortingState;
    equipmentGlobalFilter: string;
}

export const initialState: EquipmentTableState = {
    equipmentAttributes: {},
    equipmentColumnFilters: [],
    equipmentColumnOrder: [],
    equipmentColumnVisibility: {},
    equipmentSorting: [],
    equipmentGlobalFilter: ''
};

export const equipmentTableSlice = createSlice({
    name: 'equipmentTable',
    initialState,
    reducers: {
        setEquipmentAttributes: (
            state: EquipmentTableState,
            action: PayloadAction<ApiEquipmentAttributes>
        ) => {
            return {
                ...state,
                equipmentAttributes: action.payload
            };
        },

        setEquipmentColumnFilters: (
            state: EquipmentTableState,
            action: PayloadAction<ColumnFiltersState>
        ) => {
            return {
                ...state,
                equipmentColumnFilters: action.payload
            };
        },

        setEquipmentColumnOrder: (
            state: EquipmentTableState,
            action: PayloadAction<ColumnOrderState>
        ) => {
            return {
                ...state,
                equipmentColumnOrder: action.payload
            };
        },

        setEquipmentColumnVisibility: (
            state: EquipmentTableState,
            action: PayloadAction<VisibilityState>
        ) => {
            return {
                ...state,
                equipmentColumnVisibility: action.payload
            };
        },

        setEquipmentSorting: (
            state: EquipmentTableState,
            action: PayloadAction<SortingState>
        ) => {
            return {
                ...state,
                equipmentSorting: action.payload
            };
        },
        setEquipmentGlobalFilter: (
            state: EquipmentTableState,
            action: PayloadAction<string>
        ) => {
            return {
                ...state,
                equipmentGlobalFilter: action.payload
            };
        },
        replaceEquipmentTable: (
            state: EquipmentTableState,
            action: PayloadAction<EquipmentTableState>
        ) => {
            return action.payload;
        },

        /**
         * Resets to a blank equipment state
         */
        resetEquipmentTable: (): EquipmentTableState => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(syncOnLogin, (state) => {
            // merge to initial state to ensure new keys for this slice
            // are synced with the current state
            return {
                ...initialState,
                ...state
            };
        });
    }
});

export const {
    setEquipmentAttributes,
    setEquipmentColumnFilters,
    setEquipmentColumnOrder,
    setEquipmentColumnVisibility,
    setEquipmentSorting,
    setEquipmentGlobalFilter,
    replaceEquipmentTable,
    resetEquipmentTable
} = equipmentTableSlice.actions;

export const selectEquipmentAttributes = (
    state: RootState
): ApiEquipmentAttributes => state.equipmentTable.equipmentAttributes;

export const selectEquipmentColumnFilters = (
    state: RootState
): ColumnFiltersState => state.equipmentTable.equipmentColumnFilters;

export const selectEquipmentColumnOrder = (
    state: RootState
): ColumnOrderState => state.equipmentTable.equipmentColumnOrder;

export const selectEquipmentColumnVisibility = (
    state: RootState
): VisibilityState => state.equipmentTable.equipmentColumnVisibility;

export const selectEquipmentSorting = (state: RootState): SortingState =>
    state.equipmentTable.equipmentSorting;

export const selectEquipmentGlobalFilter = (state: RootState): string =>
    state.equipmentTable.equipmentGlobalFilter;

export default equipmentTableSlice.reducer;
