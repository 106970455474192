import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    ApiInventoryItem,
    UserPermissionFeatures,
    UserPermissionRoles
} from '~/api/types';
import InventoryItemStatus from './InventoryItemStatus/InventoryItemStatus';
import { IconButton, useModal } from '~/ui';
import InventoryItemEditModalContent from './InventoryItemEditModalContent/InventoryItemEditModalContent';
import { validatePermissions } from '~/utils/validatePermissions';
import constants from '~/utils/constants';
import { selectUserGroupsForCurrentUser } from '~/reducers/userGroupsForCurrentUserSlice';

import './InventoryItem.scss';

interface InventoryItemProps {
    item: ApiInventoryItem;
    isCompleted?: boolean;
}

function InventoryItem({ item, isCompleted }: InventoryItemProps) {
    const { t } = useTranslation(['taskManagement', 'inventory']);
    const [isHover, setIsHover] = useState(false);
    const [inventoryItem, setInventoryItem] = useState(item);

    const {
        id,
        barcode_id: barcodeId,
        item_name: itemName,
        status,
        actual_quantity: actualQuantity,
        expected_quantity: expectedQuantity,
        unit_weight: unitWeight
    } = inventoryItem;

    const associatedUserGroups = useSelector(selectUserGroupsForCurrentUser);
    const hasEditPermissions = validatePermissions(
        associatedUserGroups,
        constants.userPermissionFeatures
            .TASK_MANAGEMENT as UserPermissionFeatures,
        constants.userPermissionRoles.EDITOR as UserPermissionRoles
    );
    const editableInventoryItemClassName = hasEditPermissions
        ? 'inventoryitem-edit_enabled'
        : '';
    const {
        showModal: showInventoryItemEditModal,
        hideModal: hideInventoryItemEditModal,
        modal: InventoryItemEditModal
    } = useModal();

    function onMouseEnter() {
        setIsHover(true);
    }

    function onMouseLeave() {
        setIsHover(false);
    }

    function openInventoryItemEditModal() {
        showInventoryItemEditModal();
    }

    function updateInventoryItem(payload: ApiInventoryItem) {
        setInventoryItem({ ...inventoryItem, ...payload });
    }

    return (
        <div
            className={`inventoryitem ${editableInventoryItemClassName} _d-flex _ai-center _jc-space-between _mb-3`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className="inventoryitem-detail">
                <div className="heading-3 _to-ellipsis">{itemName}</div>
                <div className="sub-heading-2 _to-ellipsis">
                    {t('inventory:plannedQuantity', {
                        count: expectedQuantity
                    })}
                    {isCompleted && (
                        <span>
                            {t('inventory:actualQuantity', {
                                count: actualQuantity
                            })}
                        </span>
                    )}
                </div>
            </div>
            {isHover && hasEditPermissions ? (
                <IconButton icon="edit" onClick={openInventoryItemEditModal} />
            ) : (
                <InventoryItemStatus status={status} />
            )}
            <InventoryItemEditModal variant="small" hasCustomHeader>
                <InventoryItemEditModalContent
                    id={id}
                    itemName={itemName}
                    barcodeId={barcodeId}
                    quantity={actualQuantity}
                    weight={unitWeight}
                    hideModal={hideInventoryItemEditModal}
                    onUpdate={updateInventoryItem}
                />
            </InventoryItemEditModal>
        </div>
    );
}

export default InventoryItem;
