import { useCallback, useMemo } from 'react';
import { isEmpty, pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { removeTasksById, getAllTasks } from '~/reducers/tasksSlice';
import {
    resetSelectedTaskIds,
    selectSelectedTaskIds
} from '~/reducers/selectedTaskIdsSlice';
import { updateTaskMetrics } from '~/reducers/taskMetricsSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';
import {
    setIsEquipmentDepotPairing,
    setIsOpenPairEquipmentControlPopup
} from '~/reducers/mapDrawerSettingsSlice';
import { setIsOpenAddTaskModal } from '~/reducers/addTaskSlice';

import { ApiTask, TaskStatusCondition } from '~/api/types';

import { IconName } from '~/ui/components/Icon/IconNames';
import taskActionBarLogic from '~/components/TaskManagementPage/TaskActionBar/taskActionBarLogic';

import { useTaskPairing } from '~/hooks';
import { InitialTaskActions } from '~/hooks/useTaskAvailableActions/types';

interface PairItemRecord {
    text: string;
    iconName: IconName;
    iconColor: string;
}

interface UsePairItemHooksParams {
    allTasks?: Record<string, ApiTask>;
    initialDisplayedButtons?: InitialTaskActions;
    hasRoutePlan?: boolean;
}

export const usePairItemHooks = ({
    allTasks,
    initialDisplayedButtons,
    hasRoutePlan
}: UsePairItemHooksParams = {}) => {
    const { t } = useTranslation(['taskManagement', 'error']);
    const { depotPairing, manualPairing, optimalPairing } = useTaskPairing();
    const dispatch = useDispatch();
    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const selectedDate = useSelector(selectDate);
    const mainClient = useSelector(selectMainClient);

    const manualPair = useCallback(() => {
        const updateTaskStates = () => {
            dispatch(removeTasksById(selectedTaskIds));
            dispatch(resetSelectedTaskIds(null));
            // eslint-disable-next-line
            // @ts-ignore
            dispatch(updateTaskMetrics(selectedDate));
        };

        manualPairing({ selectedTaskIds, updateTaskStates });
    }, [dispatch, manualPairing, selectedTaskIds, selectedDate]);

    const handleEquipmentPair = useCallback(() => {
        dispatch(setIsOpenPairEquipmentControlPopup(true));
        dispatch(setIsEquipmentDepotPairing(false));
    }, [dispatch]);

    const optimalPairDetails = useMemo(() => {
        // filter selected task ids to get just the unassigned task ids
        const selectedUnassignedTaskIds = new Set(
            selectedTaskIds.filter(
                (id) =>
                    allTasks?.[id]?.taskStatus ===
                    TaskStatusCondition.UNASSIGNED
            )
        );

        // get all unassigned tasks from redux for selected date
        const unassignedTasks = pickBy(allTasks, (value) => {
            return value.taskStatus === TaskStatusCondition.UNASSIGNED;
        });

        // filter unassigned task ids to remove selected unassigned taskIds
        const unassignedTaskIds = Object.keys(unassignedTasks).filter(
            (id) => !selectedUnassignedTaskIds.has(id)
        );

        return {
            taskIds: [...selectedUnassignedTaskIds],
            unassignedTaskIds
        };
    }, [allTasks, selectedTaskIds]);

    const optimalPair = useCallback(() => {
        optimalPairing(optimalPairDetails);
    }, [optimalPairing, optimalPairDetails]);

    const depotPair = useCallback(() => {
        const updateTaskStatesForDepotPairing = () => {
            dispatch(getAllTasks({ routeDate: selectedDate }));
            dispatch(resetSelectedTaskIds(null));
        };

        depotPairing({ selectedTaskIds, updateTaskStatesForDepotPairing });
    }, [dispatch, depotPairing, selectedTaskIds, selectedDate]);

    const handleEquipmentDepotPair = useCallback(() => {
        dispatch(setIsEquipmentDepotPairing(true));
        dispatch(setIsOpenAddTaskModal(true));
    }, [dispatch]);

    const handleActionButtonDisplayState = useCallback(
        (displayedButtons?: Record<string, boolean>) => {
            const enableTwoPartTaskEditing = Boolean(
                mainClient?.preferences?.enableTwoPartTaskEditing
            );

            if (!isEmpty(allTasks)) {
                return taskActionBarLogic.updateButtonsDisplayed(
                    selectedTaskIds,
                    {
                        ...initialDisplayedButtons,
                        ...displayedButtons
                    },
                    allTasks,
                    hasRoutePlan,
                    enableTwoPartTaskEditing
                );
            }

            return {
                ...initialDisplayedButtons,
                ...displayedButtons
            };
        },
        [
            allTasks,
            hasRoutePlan,
            initialDisplayedButtons,
            mainClient?.preferences?.enableTwoPartTaskEditing,
            selectedTaskIds
        ]
    );

    const pairItemRecords: Record<string, PairItemRecord> = {
        pair: {
            text: t('taskActionBar.taskPair'),
            iconName: 'link' as IconName,
            iconColor: 'galaxy-500'
        },
        optimal: {
            text: t('optimalPair'),
            iconName: 'machineLearning' as IconName,
            iconColor: 'galaxy-500'
        },
        equipment: {
            text: t('taskActionBar.equipmentPair'),
            iconName: 'equipmentPair' as IconName,
            iconColor: 'galaxy-500'
        },
        depot: {
            text: t('depotPair'),
            iconName: 'depotPair' as IconName,
            iconColor: 'galaxy-500'
        }
    };

    return {
        pairItemRecords,
        handleActionButtonDisplayState,
        handleManualPair: manualPair,
        handleOptimalPair: optimalPair,
        handleDepotPair: depotPair,
        handleEquipmentPair,
        handleEquipmentDepotPair
    };
};
