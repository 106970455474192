import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import driversApi from '~/api/DriversApi';
import {
    setAvailableDriversId,
    resetAvailableDriversId,
    selectAvailableDriversId
} from '~/reducers/availableDriversIdSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { selectSelectedClientIds } from '~/reducers/selectedClientsSlice';
import {
    selectDriversByClient,
    setDriversByClient
} from '~/reducers/driversByClientSlice';
import { idUtils } from '~/utils/id-utils';
import { colorUtils } from '~/utils/color-utils';
import { ApiDriver } from '~/api/types';
import driverUtils from '~/utils/driver-utils';

type HookGetDriverList = {
    /**
     * the drivers list
     *
     * This will contain all drivers, from all clients, that the user has access to
     */
    driversList: ApiDriver[] | undefined;

    /**
     * the available drivers count based on selected date
     *
     * Availability is based whether the driver has a shift time that matches the selected date
     */
    availableDriversCount: number;

    /**
     * the total drivers count
     */
    totalDrivers: number;

    /**
     * the selected client's drivers list
     *
     * In a multi-client scenario, this will contain only `driver data` belonging to the selected client ID.
     * The user will be selecting a client prior to any other planning and dispatch operations.
     */
    selectedClientDriverList: ApiDriver[] | undefined;

    /**
     * the selected client's available drivers count based on selected date
     *
     * Availability is based whether the driver has a shift time that matches the selected date
     *
     * In a multi-client scenario, this will contain only `count available drivers` belonging to the selected client ID.
     * The user will be selecting a client prior to any other planning and dispatch operations
     */
    selectedClientAvailableDriversCount: number;

    /**
     * the selected client's total drivers count
     */
    selectedClientTotalDrivers: number;
};

export const useGetDriverList = (): HookGetDriverList => {
    const dispatch = useDispatch();
    const availableDriversId: Record<string, boolean> | null = useSelector(
        selectAvailableDriversId
    );
    const selectedDate = useSelector(selectDate);
    const selectedClientIds: string[] = useSelector(selectSelectedClientIds);
    const driversByClient: Record<string, ApiDriver[]> = useSelector(
        selectDriversByClient
    );

    /**
     * Group drivers by client ID
     */
    const reduceDriversByClientIds = (
        result: Record<string, ApiDriver[]>,
        driver: ApiDriver
    ) => {
        const { client } = driver;
        if (client) {
            if (!result[client]) {
                result[client] = [];
            }
            result[client].push(driver);
        }
        return result;
    };

    /**
     * Updates the fetched data as part of the `onSuccess` effect of react query
     *
     * This will:
     * + set the available driver IDs into redux (see `availableDriversIdSlice`)
     * + set the driver marker colors into redux (see `webColorsSlice`)
     */
    const updateFetchedDrivers = (drivers: ApiDriver[]) => {
        dispatch(resetAvailableDriversId());

        const availableDrivers: Record<string, boolean> = {};

        for (const driver of drivers) {
            // auto-assign web colors by driver
            // TODO:
            // + in a future improvement, user-defined driver color will be set to the driver response
            // + for now, we will auto-assign web colors for each driver
            const { client, id } = driver;
            if (client && id) {
                const clientDriverId = idUtils.getCombinedId(client, id);
                const isWebColorAssignedForId =
                    colorUtils.isWebColorAssignedForId(clientDriverId);

                if (!isWebColorAssignedForId)
                    colorUtils.assignWebColorsToDriver(clientDriverId);

                // determine if driver is avaiable on selected date
                const isAvailable = driverUtils.isDriverAvailableOnSelectedDate(
                    driver,
                    selectedDate
                );

                availableDrivers[id] = isAvailable;
            }
        }

        dispatch(setAvailableDriversId(availableDrivers));
    };

    /**
     * the `onSuccess` effect handler
     */
    const handleOnSuccess = (driversResponse: ApiDriver[]) => {
        updateFetchedDrivers(driversResponse);
        const driversByClientIds = driversResponse.reduce(
            reduceDriversByClientIds,
            {} as Record<string, ApiDriver[]>
        );
        dispatch(setDriversByClient(driversByClientIds));
    };

    /**
     * the `onError` effect handler
     */
    const handleOnError = (e: Error) => {
        dispatch(resetAvailableDriversId());
        dispatch(setDriversByClient({}));
        console.error(e);
    };

    /**
     * Retrieve all drivers
     */
    const { data: driversList } = useQuery<ApiDriver[], Error>(
        [driversApi.REACT_QUERY_KEY, selectedClientIds],
        async () => {
            const {
                data: { data: drivers }
            } = await driversApi.get();
            return drivers;
        },
        {
            onSuccess: handleOnSuccess,
            onError: handleOnError
        }
    );

    /**
     * Count available drivers
     */
    const availableDriversCount = useMemo(() => {
        const driverIds = Object.entries(availableDriversId || {});
        const availableDrivers = driverIds.filter((driverEntry) => {
            const [isAvailable] = driverEntry.reverse();
            return isAvailable;
        });

        return availableDrivers.length;
    }, [availableDriversId]);

    /**
     * Count total drivers
     */
    const totalDrivers = driversList?.length || 0;

    /**
     * Retrieve only drivers belonging to selected client IDs
     */
    const selectedClientDriverList = useMemo<ApiDriver[]>(() => {
        return selectedClientIds.reduce<ApiDriver[]>((allDrivers, clientId) => {
            const { [`${clientId}`]: clientDriversList = [] } = driversByClient;
            return [...allDrivers, ...clientDriversList];
        }, []);
    }, [selectedClientIds, driversByClient]);

    /**
     * Count only available drivers belonging to selected client IDs
     */
    const selectedClientAvailableDriversCount = useMemo(() => {
        const availableSelectedDrivers = selectedClientDriverList.filter(
            (driver) => {
                const { id: driverId } = driver;
                return driverId && availableDriversId?.[driverId];
            }
        );

        return availableSelectedDrivers.length;
    }, [availableDriversId, selectedClientDriverList]);

    /**
     * Count only total drivers belonging to selected client IDs
     */
    const selectedClientTotalDrivers = selectedClientDriverList.length;

    return {
        driversList,
        availableDriversCount,
        totalDrivers,
        selectedClientDriverList,
        selectedClientAvailableDriversCount,
        selectedClientTotalDrivers
    };
};
