import { useCallback } from 'react';
import { useEquipmentTableContext } from '../EquipmentTableContext';
import { useEquipmentTableData } from '../useEquipmentTableData';

export const useEquipmentTablePagination = () => {
    const { setApiPaginationOptions, setPageIndex, pageIndex, pageSize } =
        useEquipmentTableContext();

    const { totalPageNumber } = useEquipmentTableData();

    const goToFirstPage = useCallback(() => {
        setPageIndex(1);
        setApiPaginationOptions({
            limit: pageSize,
            page: 1
        });
    }, [setApiPaginationOptions, setPageIndex, pageSize]);

    const goToLastPage = useCallback(() => {
        setPageIndex(totalPageNumber ?? 1);
        setApiPaginationOptions({
            limit: pageSize,
            page: totalPageNumber ?? 1
        });
    }, [setApiPaginationOptions, setPageIndex, pageSize, totalPageNumber]);

    const goToNextPage = useCallback(() => {
        setPageIndex(pageIndex + 1);
        setApiPaginationOptions({
            limit: pageSize,
            page: pageIndex + 1
        });
    }, [pageIndex, setApiPaginationOptions, setPageIndex, pageSize]);

    const goToPrevPage = useCallback(() => {
        setPageIndex(pageIndex - 1);
        setApiPaginationOptions({
            limit: pageSize,
            page: pageIndex - 1
        });
    }, [setApiPaginationOptions, pageIndex, setPageIndex, pageSize]);

    return {
        goToFirstPage,
        goToLastPage,
        goToNextPage,
        goToPrevPage
    };
};
