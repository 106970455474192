import { useMemo, useCallback } from 'react';
import { has, intersection } from 'lodash';

import { Column } from './types';
import { LiveDispatchViewColumnsProps } from '../types';

type UseLiveDispatchColumnVisibilityProps = Pick<
    LiveDispatchViewColumnsProps,
    'columnIds' | 'columnVisibility' | 'setColumnVisibility' | 'columnOrder'
>;

const useLiveDispatchColumnVisibility = ({
    columnIds,
    columnVisibility,
    setColumnVisibility,
    columnOrder
}: UseLiveDispatchColumnVisibilityProps) => {
    const availableColumns = useMemo<string[]>(() => {
        // test if array have items in common with `columnIds`
        const columnOrderIds = intersection(columnOrder, columnIds);
        const columnVisibilityIds = intersection(
            Object.keys(columnVisibility),
            columnOrder
        );

        // both arrays should have items in common
        const isColumnVisibilityCurrent =
            columnVisibilityIds.length === columnIds.length;
        const isColumnOrderCurrent = columnOrderIds.length === columnIds.length;

        const currentColumnVisibilityIds = isColumnVisibilityCurrent
            ? columnVisibilityIds
            : columnIds;

        return isColumnOrderCurrent
            ? columnOrderIds
            : currentColumnVisibilityIds;
    }, [columnOrder, columnIds, columnVisibility]);

    const dropdownColumns = useMemo<Column>(() => {
        const updatedDropdownColumns = availableColumns.reduce<Column>(
            (acc, columnId) => {
                acc[columnId] =
                    !has(columnVisibility, columnId) ||
                    columnVisibility[columnId];
                return acc;
            },
            {}
        );

        return updatedDropdownColumns;
    }, [availableColumns, columnVisibility]);

    const handleToggleVisibility = useCallback(
        (columnName: string) => {
            const updatedColumnVisibility = {
                ...dropdownColumns,
                [columnName]: !dropdownColumns[columnName]
            };
            setColumnVisibility(updatedColumnVisibility);
        },
        [dropdownColumns, setColumnVisibility]
    );

    return {
        dropdownColumns,
        handleToggleVisibility
    };
};

export default useLiveDispatchColumnVisibility;
