import React from 'react';
import { useTranslation } from 'react-i18next';
import { Detail } from '~/ui';
import { UpdateTaskProps } from '~/components/TaskManagementPage/TaskDetailDrawer/useUpdateTask';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { TaskLabelsDetailContent } from './TaskLabelsDetailContent';

interface UseTaskLabelsDetailProps {
    isEditable?: boolean;
    labels?: string[];
    taskId?: string;
    onTaskUpdate: (data: UpdateTaskProps) => Promise<boolean>;
}

export const useTaskLabelsDetail = ({
    isEditable,
    onTaskUpdate,
    taskId,
    labels
}: UseTaskLabelsDetailProps) => {
    const { t } = useTranslation('taskManagement');

    const taskLabelsDetail = taskId ? (
        <Detail
            showTooltipOnIcon
            iconName="taskLine"
            iconSize={IconSizes.S}
            iconColor="galaxy-500"
            iconClassName="detail-drawer-item__icon"
            iconTooltipContent={t('iconTooltipContent.labels')}
            className="_d-flex _ai-flex-start"
            mainContent={
                <TaskLabelsDetailContent
                    isEditable={isEditable}
                    labels={labels}
                    onTaskUpdate={onTaskUpdate}
                    taskId={taskId}
                />
            }
        />
    ) : null;

    return {
        taskLabelsDetail
    };
};
