/* global H */
import { isNumber } from 'lodash';
import Polyline from '~/utils/map/decodeUtils/polyline';
import {
    HereMapsMapInstance,
    HereMapsObject,
    HereMapsPolyline
} from '~/components/HereMaps/types';
import { Coordinates } from '~/api/types';
import { MutableRefObject } from 'react';

const MIN_POINTS = 2;

const decodePolylinePath = (encodedPath: string, precision = 5) => {
    const decodedPath = Polyline.decode(encodedPath, precision);
    return decodedPath.map((pair) => {
        const [lat, lng] = pair;
        return { lat, lng };
    });
};

const drawMapObject = (
    object: HereMapsObject,
    mapInstance: HereMapsMapInstance
) => {
    if (!object || !mapInstance) {
        return;
    }
    mapInstance.addObject(object);
};

const removeMapObject = (
    object: HereMapsObject,
    mapInstance: HereMapsMapInstance
) => {
    if (!object || !mapInstance) {
        return;
    }
    mapInstance.removeObject(object);
};

const getPolylineStyle = ({
    strokeColor,
    strokeWeight,
    lineDash
}: {
    strokeColor?: string;
    strokeWeight?: number;
    lineDash?: number[];
}) => {
    const options = {
        ...(strokeColor && { strokeColor }),
        ...(isNumber(strokeWeight) && { lineWidth: strokeWeight }),
        ...(lineDash && { lineDash })
    };
    return options;
};

const createPolyline = (
    path: Coordinates[],
    options: {
        strokeColor: string;
        lineWidth: number;
        lineDash?: number[];
    }
) => {
    const { strokeColor, lineWidth, lineDash = [0] } = options;
    if ((path?.length || 0) < MIN_POINTS) {
        return null;
    }
    const linestring = new H.geo.LineString();
    path.forEach((point) => {
        linestring.pushPoint(point);
    });
    const polyline = new H.map.Polyline(linestring, {
        style: {
            lineWidth,
            strokeColor,
            lineDash
        }
    });
    return polyline;
};

const updatePolyline = (
    path: Coordinates[],
    lineRef: MutableRefObject<HereMapsPolyline | null>,
    style: {
        strokeColor: string;
        lineWidth: number;
        lineDash?: number[];
    }
) => {
    if ((path?.length || 0) < MIN_POINTS) {
        return lineRef.current ? lineRef : null;
    }
    if (lineRef.current) {
        const linestring = new H.geo.LineString();
        path.forEach((point: Coordinates) => {
            linestring.pushPoint(point);
        });
        lineRef.current.setGeometry(linestring);
        lineRef.current.setStyle(style);
    } else {
        const linestring = new H.geo.LineString();
        path.forEach((point) => {
            linestring.pushPoint(point);
        });
        const projectionPolyline = new H.map.Polyline(linestring, {
            style
        });
        lineRef.current = projectionPolyline;
    }
    return lineRef;
};

export const hereMapsDrawingUtils = {
    updatePolyline,
    createPolyline,
    drawMapObject,
    removeMapObject,
    getPolylineStyle,
    decodePolylinePath
};
