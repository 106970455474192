import { useMemo } from 'react';
import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ApiVehicle, TaskStatusCondition } from '~/api/types';

import { selectDate } from '~/reducers/selectedDateSlice';

import { useGetDriverList, useGetRoutePlans, useIsOutOfService } from '~/hooks';

import dateUtils from '~/utils/date-utils';
import utils from '~/utils/general-utils';
import driverUtils from '~/utils/driver-utils';

interface EligibleDriverToAssign {
    driverId?: string;
    clientId?: string;
    hasAssignments: boolean;
    driverFullName: string;
    driverLabel: string;
    vehicleEid: string;
    isDispatched?: boolean;
    isPlanned?: boolean;
    isUnassigned?: boolean;
    isDriverOutOfService: boolean;
    isVehicleOutOfService: boolean;
    status?: TaskStatusCondition;
    shiftStartTime?: string;
}

export const useGetEligibleDriversToAssign = () => {
    const { t } = useTranslation(['common']);
    const selectedDate: string | null = useSelector(selectDate);
    const selectedRouteDate =
        (selectedDate && dateUtils.convertToISODateOnly(selectedDate)) ||
        undefined;

    const { selectedClientDriverList: fetchedDriversList } = useGetDriverList();
    const { data: fetchedRoutePlans } = useGetRoutePlans({
        routeDate: selectedRouteDate
    });
    const { isOutOfService } = useIsOutOfService();

    const driversList = useMemo(() => {
        return (fetchedDriversList || []).map((driver) => {
            const {
                firstname = '',
                lastname = '',
                vehicle,
                id: driverId,
                client: clientId,
                props,
                assignments
            } = driver;

            const { shiftTimes } = props || {};

            const { eid } = vehicle as ApiVehicle;

            const fullName = utils.getUserName(firstname, lastname);

            const vehicleEid: string = eid || t('common:N/A');

            const hasAssignments = Boolean(assignments?.length);
            const shiftTimesByDayOfWeek =
                shiftTimes && driverUtils.populateShiftTimes(shiftTimes);
            const weekday = dateUtils.getIntWeekDay(selectedDate ?? '');
            const shiftStart = shiftTimes?.[weekday]?.[0]?.start;
            const { isDriverOutOfService, isVehicleOutOfService } =
                isOutOfService({ driverId, shiftTimesByDayOfWeek });

            const driverLabel = eid ? `${vehicleEid} - ${fullName}` : fullName;

            const eligibleDriver: EligibleDriverToAssign = {
                driverId,
                clientId,
                hasAssignments,
                driverFullName: fullName,
                driverLabel,
                vehicleEid,
                isDriverOutOfService,
                isVehicleOutOfService,
                shiftStartTime: shiftStart
            };

            return eligibleDriver;
        });
    }, [fetchedDriversList, isOutOfService, t, selectedDate]);

    const selectedDateRoutePlan = useMemo(() => {
        if (!fetchedRoutePlans || !selectedRouteDate) return;
        return fetchedRoutePlans.find(
            (routePlan) => routePlan.route_date === selectedRouteDate
        );
    }, [fetchedRoutePlans, selectedRouteDate]);

    const eligibleDriversToAssign = useMemo<EligibleDriverToAssign[]>(() => {
        const isSelectedDateToday = dateUtils.isToday(selectedDate || '');
        const driversWithPlannedRoute = keyBy(
            selectedDateRoutePlan?.routes,
            'driverId'
        );

        const routesList = driversList.flatMap((driver) => {
            const { hasAssignments, driverId } = driver;
            const isDispatched = isSelectedDateToday && hasAssignments;
            const isPlanned = Boolean(
                driverId && driversWithPlannedRoute[driverId]
            );
            const isUnassigned = !isPlanned;
            const driversRoutes: EligibleDriverToAssign[] = [];

            if (isDispatched) {
                driversRoutes.push({
                    ...driver,
                    isDispatched,
                    status: TaskStatusCondition.DISPATCHED
                });
            }

            driversRoutes.push({
                ...driver,
                isPlanned,
                isUnassigned,
                status: isPlanned
                    ? TaskStatusCondition.PLANNED
                    : TaskStatusCondition.UNASSIGNED
            });

            return driversRoutes;
        });

        return routesList;
    }, [selectedDate, selectedDateRoutePlan, driversList]);

    return { eligibleDriversToAssign };
};
