import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { uniq } from 'lodash';
import { UpdateTaskProps } from '~/components/TaskManagementPage/TaskDetailDrawer/useUpdateTask';
import { TaskLabelsEdit } from '../TaskLabelsEdit';
import './TaskLabelsDetailContent.scss';

interface TaskLabelsDetailContentProps {
    isEditable?: boolean;
    labels?: string[] | null;
    taskId: string;
    onTaskUpdate: (data: UpdateTaskProps) => Promise<boolean>;
}

export const TaskLabelsDetailContent = ({
    isEditable,
    onTaskUpdate,
    taskId,
    labels
}: TaskLabelsDetailContentProps) => {
    const { t } = useTranslation('common');
    const rootClassName = 'task-labels-detail-content';
    const [isEditMode, setIsEditMode] = useState(false);

    const onClose = () => {
        setIsEditMode(false);
    };

    const enableEditMode = () => {
        if (isEditable) setIsEditMode(true);
    };

    const defaultViewRootClassName = `${rootClassName}__default-view`;
    const defaultViewClassName = classNames(
        defaultViewRootClassName,
        '_d-flex _fw-wrap _p-relative _w-100',
        {
            [`${defaultViewRootClassName}--editable`]: isEditable
        }
    );

    const uniqueLabels = useMemo(() => uniq(labels), [labels]);

    return (
        <div className={`${rootClassName} _p-relative _w-100`}>
            {isEditMode && (
                <TaskLabelsEdit
                    labels={uniqueLabels}
                    onClose={onClose}
                    onTaskUpdate={onTaskUpdate}
                    taskId={taskId}
                />
            )}
            {!isEditMode && (
                <div
                    className={defaultViewClassName}
                    onClick={enableEditMode}
                    onKeyDown={enableEditMode}
                    role="button"
                    tabIndex={0}
                    data-testid={defaultViewRootClassName}
                >
                    {!uniqueLabels.length && t('N/A')}
                    {uniqueLabels.map((label) => {
                        return (
                            <div key={label} className="label">
                                {label}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
