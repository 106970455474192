import React from 'react';

import './NavigationTabsGroup.scss';
import { NavigationTabElement, NavigationTabsGroupElement } from './types';
import { Icon } from '~/ui';

export const navigationTabsGroupContainerTestId =
    'navigationTabsGroup-containerTestId';
export const navigationTabsGroupButtonTestId =
    'navigationTabsGroup-buttonTestId';

function NavigationTabsGroup({ tabs }: NavigationTabsGroupElement) {
    return (
        <div
            className="navigationtabsgroup _d-flex"
            data-testid={navigationTabsGroupContainerTestId}
        >
            {tabs.map((item: NavigationTabElement) => (
                <button
                    key={`navigationtabsgroup__button-${item.text}`}
                    data-testid={`${navigationTabsGroupButtonTestId}-${item.text}`}
                    type="button"
                    className="navigationtabsgroup__button"
                    disabled={item.disabled}
                    // prevent `data-selected` from rendering when false or falsy
                    // for correct css rule application
                    data-selected={item.selected || null}
                    onClick={() => {
                        if (!item.selected) item.onClick();
                    }}
                >
                    {item.icon && <Icon icon={item.icon} size="s" />}
                    {item.text}
                </button>
            ))}
        </div>
    );
}

export default NavigationTabsGroup;
