import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LiveDispatchTaskTableRowData } from '~/data-classes';
import constants from '~/utils/constants';
import { TableHeaderProps, LiveDispatchTableColumnId } from '../../types';
import { useLiveDispatchTableStates } from '../../useLiveDispatchTableStates';
import { TableFilterElement } from '../../TableFilterElement';

const ROOT_CLASS_NAME = 'live-dispatch-table-header';

interface CheckboxHandler {
    isChecked: boolean;
    value?: string | number | unknown;
    selectedValues?: Array<string | number | unknown>;
}

export const LiveDispatchTableHeaderTaskPriority = ({
    header,
    children,
    className,
    ...extra
}: TableHeaderProps<LiveDispatchTaskTableRowData>) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { id } = header || {};
    const headerText = id && t(`columns.taskTable.${id}`);
    const defaultTestId = id ? `${ROOT_CLASS_NAME}__${id}` : ROOT_CLASS_NAME;
    const dataTestId = extra['data-testid'] || defaultTestId;
    const elementClassName = classNames(ROOT_CLASS_NAME, className, {
        [`${ROOT_CLASS_NAME}__${id}`]: id
    });

    const [selectedTaskPriorityFilter, setSelectedTaskPriorityFilter] =
        useState<string[]>([]);
    const { columnFilters, setColumnFilters } = useLiveDispatchTableStates();

    useEffect(() => {
        if (columnFilters.length) {
            const currentColumnData = columnFilters.filter(
                (data) => data.id === id
            );
            if (currentColumnData.length) {
                setSelectedTaskPriorityFilter(
                    currentColumnData[0].value as string[]
                );
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [columnFilters]);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const facetedUniqueValues = header?.column.getFacetedUniqueValues() || [];
    const facetedUniqueValuesSortedArray = [
        ...facetedUniqueValues.entries()
    ].sort(([a], [b]) => {
        return a - b;
    });

    const TaskPriorityFilterList = useMemo(() => {
        const typeCounts: { name: string; numberOfEntries: number }[] = [];
        const facetedUniqueValuesSortedMap = new Map(
            facetedUniqueValuesSortedArray
        );

        for (const entry of facetedUniqueValuesSortedMap.entries()) {
            typeCounts.push({
                name: constants.taskPriorityNames[entry[0] as '1' | '2' | '3'],
                numberOfEntries: entry[1]
            });
        }

        return typeCounts;
    }, [facetedUniqueValuesSortedArray]);

    const handleTaskPriorityFilterCheckboxClick = ({
        isChecked,
        value
    }: CheckboxHandler) => {
        if (isChecked) {
            setSelectedTaskPriorityFilter([
                ...selectedTaskPriorityFilter,
                value as string
            ]);
        } else {
            const removedEntryList = selectedTaskPriorityFilter.filter(
                (data) => data !== value
            );
            setSelectedTaskPriorityFilter([...removedEntryList]);
        }
    };

    const onClearStatusFilter = () => {
        setSelectedTaskPriorityFilter([]);
        const updatedColumnFilter = columnFilters.filter(
            (data) => data.id !== LiveDispatchTableColumnId.PRIORITY
        );
        setColumnFilters([...updatedColumnFilter]);
    };

    const onApplyStatusFilter = () => {
        if (selectedTaskPriorityFilter.length) {
            const filterFromTaskPriority = columnFilters.filter(
                (data) => data.id !== LiveDispatchTableColumnId.PRIORITY
            );

            setColumnFilters([
                ...filterFromTaskPriority,
                {
                    id: LiveDispatchTableColumnId.PRIORITY,
                    value: selectedTaskPriorityFilter
                }
            ]);
        } else {
            onClearStatusFilter();
        }
    };
    return (
        <div className={elementClassName} data-testid={dataTestId}>
            {header?.column.columnDef.enableColumnFilter && (
                <TableFilterElement
                    headerText={headerText}
                    headerId={id || ''}
                    columnFilters={columnFilters}
                    filterList={TaskPriorityFilterList}
                    selectedFilterList={selectedTaskPriorityFilter}
                    handleFilterCheckboxClick={
                        handleTaskPriorityFilterCheckboxClick
                    }
                    onClearStatusFilter={onClearStatusFilter}
                    onApplyStatusFilter={onApplyStatusFilter}
                />
            )}
            {children}
            <span data-testid={`${dataTestId}-text`}>{headerText}</span>
        </div>
    );
};
