import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '~/ui';

import './PopupSelectionCard.scss';

interface PopupSelectionCardProps {
    showDropdown?: boolean;
    onDropdownClick?: () => void;
    title: string;
    subtitle: string;
}

const rootClassName = 'live-dispatch-popup-selection-card';

export const PopupSelectionCard: React.FC<PopupSelectionCardProps> = ({
    showDropdown = false,
    onDropdownClick,
    title,
    subtitle
}) => {
    const { t } = useTranslation(['liveDispatchTable']);

    return (
        <div className={rootClassName}>
            <span
                className={`${rootClassName}__title`}
                data-testid={`${rootClassName}__title`}
            >
                {title}
            </span>
            <span
                className={`${rootClassName}__subtitle`}
                data-testid={`${rootClassName}__subtitle`}
            >
                {subtitle}
            </span>
            {showDropdown && (
                <button
                    type="button"
                    onClick={onDropdownClick}
                    className={`${rootClassName}__button`}
                    data-testid={`${rootClassName}__button`}
                >
                    <span>{t('dispatchPopup.dropdown')}</span>
                    <Icon icon="chevronDown" size="xs" color="meteor" />
                </button>
            )}
        </div>
    );
};
