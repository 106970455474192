import {
    EnvironmentFeatureFlags,
    EnvironmentFeatureFlagEntry,
    FeatureFlagEnvironmentSuffix,
    UnleashConfig
} from './types';
import {
    ServerCIEnvironment,
    endPointToServerMapping
} from '~/servers/servers';
import { FeatureFlag, envFeatureFlagMapping } from './featureFlags';

const stageConfig: UnleashConfig = {
    appName: ServerCIEnvironment.STAGE,
    clientKey: 'wNbzYLsf3fMqrU',
    refreshInterval: 60,
    url: 'https://interstellar-stage.unleash.wisesys.info/proxy'
};

const uatConfig: UnleashConfig = {
    appName: ServerCIEnvironment.UAT,
    clientKey: 'ed2RQR9njoj2XA',
    refreshInterval: 60,
    url: 'https://interstellar-uat.unleash.wisesys.info/proxy'
};

const cnUatConfig: UnleashConfig = {
    appName: ServerCIEnvironment.CN_UAT,
    clientKey: 'nrhGag2P4dExzd',
    refreshInterval: 30,
    url: 'https://interstellar.unleash.cn-uat.wisesys.info/proxy'
};

const productionConfig: UnleashConfig = {
    appName: ServerCIEnvironment.PRODUCTION,
    clientKey: 'client-secret',
    refreshInterval: 30,
    url: 'https://interstellar.unleash.wisesys.info/proxy'
};

/**
 * A list of CI environments with Unleash feature flagging support
 */
export const unleashConfigMap: Record<string, UnleashConfig> = {
    [ServerCIEnvironment.STAGE]: stageConfig,
    [ServerCIEnvironment.UAT]: uatConfig,
    [ServerCIEnvironment.CN_UAT]: cnUatConfig,
    [ServerCIEnvironment.PRODUCTION]: productionConfig
};

/**
 * Retrieve the Uleash client configuration for the target `hostname`
 *
 * When a client configuration does not exist for a hostname,
 * the returned configuration will default to the `stage` configuration
 */
export const getFeatureFlagsConfig = (
    hostName: keyof typeof endPointToServerMapping
): UnleashConfig => {
    const currentCIEnv = endPointToServerMapping[hostName]?.CIEnv;

    return (
        unleashConfigMap[currentCIEnv] ||
        unleashConfigMap[ServerCIEnvironment.STAGE]
    );
};

/**
 * Generates a mapping of CI environments to their corresponding expected environment feature flag
 *
 * Environment feature flags will follow the following format: `{featureFlag}-{environment}`
 *
 * Environment suffixes are defined by the `FeatureFlagEnvironmentSuffix` constant
 *
 * Environments without  defined suffix will not be included in this map
 */
export const getEnvFeatureFlagsMap = (
    flag: string,
    environment: ServerCIEnvironment[]
): EnvironmentFeatureFlags => {
    const supportedSuffixes: EnvironmentFeatureFlagEntry[] = environment.map(
        (ciEnv) => [ciEnv, FeatureFlagEnvironmentSuffix[ciEnv]]
    );

    const featureFlagEntries: EnvironmentFeatureFlagEntry[] = supportedSuffixes
        .filter((item) => item[1])
        .map(([ciEnv, suffix]) => [ciEnv, `${flag}-${suffix}`]);

    const hasAnyEnvironment = environment.includes(ServerCIEnvironment.ANY);
    if (hasAnyEnvironment)
        featureFlagEntries.push([ServerCIEnvironment.ANY, flag]);

    return Object.fromEntries(featureFlagEntries);
};

/**
 * Retrieve the feature flag configured for an environment
 *
 * Environment feature flags should follow the following format: `{featureFlag}-{environment}`
 *
 * Refer to the `envFeatureFlagMapping` constant in {@link src/utils/feature-flags-utils/feature-flags-utils.ts}
 */
export const getEnvFeatureFlag = (flag: FeatureFlag) => {
    const { appName: CIEnv } = getFeatureFlagsConfig(window.location.hostname);
    const supportedEnvironments = envFeatureFlagMapping?.[flag] || [];

    const envFlagMap = getEnvFeatureFlagsMap(flag, supportedEnvironments);
    const envFlag = envFlagMap?.[CIEnv];
    const devFlag = envFlagMap?.[ServerCIEnvironment.STAGE];
    const anyFlag = envFlagMap?.[ServerCIEnvironment.ANY];

    return envFlag || devFlag || anyFlag || '';
};
