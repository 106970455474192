import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '~/ui';

import { useLiveDispatchTableContext } from '~/components/MapPageDrawers/LiveDispatchDrawer/LiveDispatchTableContext';
import { ColumnItemProps } from '../types';

const HiddenColumnItem: React.FC<ColumnItemProps> = ({
    columnName,
    onToggleVisibility
}) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { tableMode } = useLiveDispatchTableContext();

    const handleClick = () => {
        onToggleVisibility(columnName);
    };

    return (
        <>
            <span
                className="live-dispatch-hidden-column__column-name"
                data-testid="live-dispatch-hidden-column__column-name"
            >
                <Icon
                    icon="visibleOff"
                    size="s"
                    color="meteor-70"
                    className="live-dispatch-hidden-column__visibility-off-icon"
                />
                <span
                    data-testid={`live-dispatch-visible-column__item-label-${columnName}`}
                    className="live-dispatch-hidden-column__item-label"
                >
                    {t(`columns.${tableMode}.${columnName}`)}
                </span>
            </span>
            <Button
                className="live-dispatch-hidden-column__view-button"
                onClick={handleClick}
                type="link"
                sx={{ textDecoration: 'underline' }}
                data-testid="live-dispatch-hidden-column__view-button"
            >
                <span className="live-dispatch-hidden-column__view-button-label">
                    {t('viewColumns.viewColumnItem')}
                </span>
            </Button>
        </>
    );
};

export default HiddenColumnItem;
