import { has, isEmpty } from 'lodash';
import constants from '~/utils/constants';
import taskUtils from '~/utils/task-utils';

function updateButtonsDisplayed(
    selectedTaskIds,
    initialDisplayedButtons,
    allTasks,
    hasRoutePlan,
    enableTwoPartTaskEditing
) {
    const availableSelectedTaskIds = selectedTaskIds.filter((selectedTaskId) =>
        has(allTasks, selectedTaskId)
    );

    if (isEmpty(allTasks) || !availableSelectedTaskIds.length) {
        return initialDisplayedButtons;
    }

    const numTaskSelected = availableSelectedTaskIds.length;
    const buttonsDisplayed = { ...initialDisplayedButtons };

    // count number of task for each criteria (status, type)
    const counts = {
        unassigned: 0,
        planned: 0,
        dispatched: 0,
        isTwoPart: 0,
        isDelivery: 0,
        isPickup: 0
    };
    for (const taskId of availableSelectedTaskIds) {
        const task = allTasks[taskId];

        // possible to not be available when selecting past or future tasks
        if (!task) continue;

        switch (task.taskStatus) {
            case constants.taskStatus.UNASSIGNED:
                counts.unassigned++;
                break;
            case constants.taskStatus.PLANNED:
                counts.planned++;
                break;
            case constants.taskStatus.DISPATCHED:
                counts.dispatched++;
                break;
            default:
        }

        if (taskUtils.checkIsTwoPartTask(task)) {
            counts.isTwoPart++;
        } else if (taskUtils.checkIsDeliveryTask(task)) {
            counts.isDelivery++;
        } else {
            counts.isPickup++;
        }
    }

    // count task type totals
    const allTwoPartTasks = counts.isTwoPart === numTaskSelected;
    const allOnePartTasks =
        counts.isDelivery + counts.isPickup === numTaskSelected;

    // a single task is selected
    if (numTaskSelected === 1) {
        buttonsDisplayed.viewDetails = true;
        const [selectedTaskId] = availableSelectedTaskIds;
        const status = allTasks[selectedTaskId].taskStatus;
        buttonsDisplayed.unassign =
            status === constants.taskStatus.PLANNED ||
            status === constants.taskStatus.DISPATCHED;
    }

    function setPairSplitButtons(isUnassigned = false) {
        if (enableTwoPartTaskEditing) {
            if (allTwoPartTasks) {
                buttonsDisplayed.split = true;
            }

            if (allOnePartTasks) {
                buttonsDisplayed.pair = true;

                if (isUnassigned) {
                    buttonsDisplayed.optimalPair = true;
                }

                // TODO:https://wisesys.atlassian.net/browse/MCW-585
                if (counts.isDelivery === 1 && counts.isPickup === 1) {
                    buttonsDisplayed.manualPair = true;
                }
            }
        }
    }

    // all selected tasks are unassigned
    if (counts.unassigned === numTaskSelected) {
        if (hasRoutePlan) {
            buttonsDisplayed.assign = true;
        }
        setPairSplitButtons(true);
    }

    // all selected tasks are dispatched
    if (counts.dispatched === numTaskSelected) {
        buttonsDisplayed.cancel = true;
    }

    buttonsDisplayed.delete = counts.unassigned === numTaskSelected;

    return buttonsDisplayed;
}

export default { updateButtonsDisplayed };
