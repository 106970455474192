import {
    Address,
    ServiceWindow,
    TwoPartAddress,
    TwoPartServiceWindow
} from '~/api/types';
import { Task } from './Task';

/**
 * On-Demand Dispatch Stop data class
 *
 * @category Data Classes
 *
 * @example
 * import { OnDemandDispatchStop } from '~/data-classes';
 *
 * const srcData = {};
 * const task = new OnDemandDispatchStop(srcData);
 *
 */
export class OnDemandDispatchStop extends Task {
    /**
     * the task id
     */
    get taskId(): string {
        return this.id;
    }

    /**
     * the task name
     */
    get stopName(): string {
        return this.name;
    }

    /**
     * the task size (volume) capacity used
     */
    get size(): number | null {
        const stop = this.isPickup ? this.pickupTask : this.deliveryTask;
        return stop?.props.size || null;
    }

    /**
     * the task client route task id
     * in the format `clientId_routeId_taskType_taskId`
     */
    get clientRouteTaskId(): string {
        return this.isPickup
            ? (this.pickupStopData.clientRouteTaskId as string)
            : (this.deliveryStopData.clientRouteTaskId as string);
    }

    /**
     * the task coordinates
     */
    get markerCoordinates() {
        return this.isPickup
            ? this.pickupLocationLatLng
            : this.deliveryLocationLatLng;
    }

    /**
     * the task location
     */
    get location(): Address | TwoPartAddress {
        if (this.isTwoPart) {
            return {
                pickupLocation: this.pickupLocationAddress as Address,
                deliveryLocation: this.deliveryLocationAddress as Address
            };
        }

        return this.isPickup
            ? (this.pickupLocationAddress as Address)
            : (this.deliveryLocationAddress as Address);
    }

    /**
     * the stop number of inventory items
     */
    get numInventoryItems() {
        const inventoryItem = this.isPickup
            ? this.pickupInventory
            : this.deliveryInventory;

        return inventoryItem?.length || 0;
    }

    /**
     * the task service window
     */
    get serviceWindow(): ServiceWindow | TwoPartServiceWindow {
        if (this.isTwoPart) {
            return {
                pickup: this.pickupWindow as ServiceWindow,
                delivery: this.deliveryWindow as ServiceWindow
            };
        }

        return this.isPickup
            ? (this.pickupWindow as ServiceWindow)
            : (this.deliveryWindow as ServiceWindow);
    }
}
