import React from 'react';

import { VisibleColumnItem } from './VisibleColumnItem';
import { HiddenColumnItem } from './HiddenColumnItem';

import { ViewColumnsDropdownItemProps } from './types';

import './ViewColumnsDropdownItem.scss';

const ViewColumnsDropdownItem: React.FC<ViewColumnsDropdownItemProps> = ({
    columnName,
    isVisible,
    onToggleVisibility,
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleDragEnd
}) => {
    const isColumnVisible = isVisible ? 'visible' : 'hidden';

    const handleClick = () => {
        onToggleVisibility(columnName);
    };

    return (
        <li
            className={`live-dispatch-view-columns-dropdown-item ${isColumnVisible}`}
            data-testid="live-dispatch-view-columns-dropdown-item"
            draggable="true"
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragEnd={handleDragEnd}
        >
            {isVisible && (
                <VisibleColumnItem
                    columnName={columnName}
                    onToggleVisibility={handleClick}
                />
            )}
            {!isVisible && (
                <HiddenColumnItem
                    columnName={columnName}
                    onToggleVisibility={handleClick}
                />
            )}
        </li>
    );
};

export default ViewColumnsDropdownItem;
