import React from 'react';
import './routecard-text.scss';

import { IconButton } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSize, IconSizes } from '~/ui/components/Icon/IconSizes';

interface RouteCardTextProps {
    className: string;
    text?: string;
    children: React.ReactNode;
    icon?: IconName;
    iconColor?: string;
    iconSize?: IconSize;
    'data-testid'?: string;
}

function RouteCardText({
    className,
    text,
    children,
    icon,
    iconColor = 'galaxy-500',
    iconSize = IconSizes.M,
    ...extra
}: RouteCardTextProps) {
    function getClassName() {
        let boxClassName = 'routecard-text';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function getIcon() {
        if (!icon || !icon.length) return null;
        return (
            <IconButton
                icon={icon}
                iconColor={iconColor}
                iconSize={iconSize}
                disabled
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}-icon`
                        : 'routecard-text-icon'
                }
            />
        );
    }

    return (
        <div
            className={getClassName()}
            data-testid={extra['data-testid'] || 'routecard-text'}
        >
            {(children || icon) && (
                <div className="routecard-text_group _d-flex _ai-center">
                    {getIcon()}
                    <span
                        className="route-card-text__content"
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-children`
                                : 'routecard-text-children'
                        }
                    >
                        {children}
                    </span>
                </div>
            )}
            {text}
        </div>
    );
}

export default RouteCardText;
