import { useDispatch, useSelector } from 'react-redux';

import { useMapUtils } from '~/hooks';
import {
    selectShowDriverActualLines,
    selectShowDriverLines,
    setShowDriverActualLines,
    setShowDriverLines
} from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

export const useRouteLinesControl = () => {
    const dispatch = useDispatch();
    const { isLiveRouteMode, mapRouteMode } = useMapUtils();

    const showDriverLinesState = useSelector(
        selectShowDriverLines(mapRouteMode as ConfigurableMapRouteMode)
    );

    const showDriverActualLinesState = useSelector(
        selectShowDriverActualLines(mapRouteMode as ConfigurableMapRouteMode)
    );

    const handlePlannedLinesState = () => {
        dispatch(
            setShowDriverLines({
                mode: mapRouteMode as ConfigurableMapRouteMode,
                value: !showDriverLinesState
            })
        );
    };

    const handleActualLinesState = () => {
        dispatch(
            setShowDriverActualLines({
                mode: mapRouteMode as ConfigurableMapRouteMode,
                value: !showDriverActualLinesState
            })
        );
    };

    return {
        handleActualLinesState,
        handlePlannedLinesState,
        isShowRouteLinesControl: isLiveRouteMode,
        showDriverLinesState,
        showDriverActualLinesState
    };
};
