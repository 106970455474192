import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import {
    getUserAccessToClients,
    updateWiseClientIdHeader,
    updateWiseClientIdsHeader
} from '~/utils/app-utils';
import { setMainClient } from '~/reducers/mainClientSlice';
import { selectClientIds, selectClients } from '~/reducers/clientsSlice';
import { useSelectedClients } from '~/hooks';
import {
    selectSelectedClientIds,
    selectSelectedClients,
    setSelectedClients
} from '~/reducers/selectedClientsSlice';

const ClientProvider = () => {
    const dispatch = useDispatch();
    const { areAllMultiClientsSelected } = useSelectedClients();
    const clients = useSelector(selectClients);
    const clientIds = useSelector(selectClientIds);
    const selectedClients = useSelector(selectSelectedClients);
    const selectedClientIds = useSelector(selectSelectedClientIds);

    const userAccessToClients = useMemo(() => {
        const access = getUserAccessToClients();
        if (!access) {
            return;
        }
        return access.map((item: { client: string }) => item.client);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [clients]);

    const mainClientInfo = useMemo(() => {
        let mainClientId;
        if (userAccessToClients) {
            const filteredAccess = userAccessToClients.filter((id: string) =>
                Boolean(selectedClients[id])
            );
            [mainClientId] = filteredAccess;
        } else {
            [mainClientId] = selectedClientIds;
        }
        return clients[mainClientId];
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [clients, selectedClientIds, userAccessToClients]);

    const selectFirstClient = () => {
        const [firstClientId] = clientIds;
        if (firstClientId) {
            dispatch(setSelectedClients([firstClientId]));
        }
    };

    useEffect(() => {
        if (!selectedClientIds.length || !clientIds.length) {
            return;
        }
        const validSelectedClientIds = selectedClientIds.filter((id) => {
            return clients[id];
        });
        if (!isEqual(validSelectedClientIds, selectedClientIds)) {
            dispatch(setSelectedClients(validSelectedClientIds));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [clients, clientIds, selectedClientIds]);

    useEffect(() => {
        if (mainClientInfo) {
            const { id: mainClientId } = mainClientInfo;
            const clientIdRequestHeader = areAllMultiClientsSelected
                ? ''
                : mainClientId;
            updateWiseClientIdHeader(clientIdRequestHeader);
            updateWiseClientIdsHeader(selectedClientIds);
            dispatch(setMainClient(mainClientInfo));
        } else {
            selectFirstClient();
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        selectedClients,
        clients,
        mainClientInfo,
        areAllMultiClientsSelected,
        selectedClientIds
    ]);

    return null;
};

export { ClientProvider };
