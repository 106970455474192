import React from 'react';
import { LabelInput } from '~/ui';
import { useTranslation } from 'react-i18next';
import { ValidatedInput } from '~/components/ValidatedInput';
import { useFormikContext } from 'formik';
import { twoDecimalPlacesNonNegativeFloatValidator } from '~/hooks';
import { FormikInput } from '../../types';

import './InvoiceAndPaymentFormikInput.scss';

const invoiceAndPaymentEditModal = 'invoice-and-payment-edit-modal';

export const InvoiceAndPaymentFormikInput = ({
    amountDue,
    invoiceNumber,
    isInvoiceEditable
}: FormikInput): JSX.Element => {
    const { t } = useTranslation('taskManagement');
    const { setFieldValue } = useFormikContext();

    const handleAmountDueChange = (value: string) => {
        setFieldValue('amountDue', Number(value));
    };

    return (
        <div
            className="_d-flex _ai-center"
            data-testid="invoice-and-payment-inputs"
        >
            <ValidatedInput
                className="invoice-number"
                disabled
                inputComponent={LabelInput}
                value={invoiceNumber}
                data-testid={`${invoiceAndPaymentEditModal}-invoice-number`}
            />
            <ValidatedInput
                inputComponent={LabelInput}
                disabled={isInvoiceEditable}
                className="amount-due"
                validator={twoDecimalPlacesNonNegativeFloatValidator}
                value={amountDue}
                onValueChange={handleAmountDueChange}
                data-testid={`${invoiceAndPaymentEditModal}-amount-due`}
                placeholder={t('drawer.invoicesAndPayments.amount')}
            />
            <span
                className="currency _d-grid"
                data-testid="invoice-and-payment-edit-modal-currency"
            >
                {t('drawer.invoicesAndPayments.currency')}
            </span>
        </div>
    );
};
