import { groupBy, isArray, isEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { Shift } from './types';

const getListOfTwentyFourHourShifts = (
    values: { days: number[]; shift: Shift }[][]
) => {
    return values.map((items) => {
        const [{ days }] = items;
        const uniqueShifts = items.map(({ shift }) => {
            const { start, end } = shift;

            return { start, end };
        });

        return { days, id: uuidV4(), shift: uniqueShifts };
    });
};

const getListOfLongShifts = (values: { days: number[]; shift: Shift }[]) => {
    return values.map(({ days, shift }) => {
        const { start, isLongShift, hours, minutes } = shift;

        return {
            days,
            id: uuidV4(),
            shift: [
                {
                    isLongShift,
                    start,
                    hours: hours.toString(),
                    minutes: minutes.toString()
                }
            ]
        };
    });
};

export const createShiftListByDayOfWeek = (shifts: Record<string, Shift[]>) => {
    const twentyFourHourShiftsByStartAndDuration: Record<
        string,
        { days: number[]; shift: Shift }
    > = {};
    const longShiftsByStartAndDuration: Record<
        string,
        { days: number[]; shift: Shift }
    > = {};

    Object.keys(shifts).forEach((current) => {
        const day = parseInt(current, 10);
        const currentDayShiftOrShifts = shifts[current];

        if (isEmpty(currentDayShiftOrShifts)) {
            return;
        }

        const shiftsForCurrentDay = isArray(currentDayShiftOrShifts)
            ? currentDayShiftOrShifts
            : [currentDayShiftOrShifts];

        shiftsForCurrentDay.forEach((shift) => {
            const { start, hours, minutes, isLongShift } = shift;
            const key = `${start}_${hours}_${minutes}`;
            const shiftsByKey = isLongShift
                ? longShiftsByStartAndDuration
                : twentyFourHourShiftsByStartAndDuration;
            const item = shiftsByKey[key];

            if (item) {
                item.days.push(day);
                return;
            }

            shiftsByKey[key] = {
                shift,
                days: [day]
            };
        });
    });

    const twentyFourHourShiftsByDays = groupBy(
        Object.values(twentyFourHourShiftsByStartAndDuration),
        ({ days }) => days.join('_')
    );

    const listOfTwentyFourHourShifts = getListOfTwentyFourHourShifts(
        Object.values(twentyFourHourShiftsByDays)
    );

    const listOfLongShifts = getListOfLongShifts(
        Object.values(longShiftsByStartAndDuration)
    );

    return [...listOfTwentyFourHourShifts, ...listOfLongShifts];
};
