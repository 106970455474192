import taskUtils from '~/utils/task-utils';
import constants from '~/utils/constants';
import {
    TaskObject,
    TaskUpdateObject,
    InvoiceAndPaymentDetailsData
} from '../types';
import { ApiInventoryItem, ApiTask } from '~/api/types';

export const isTaskDelivery = ({
    isTwoPartTask,
    isDelivery,
    tabIndex
}: TaskObject) => {
    return (!isTwoPartTask && isDelivery) || (isTwoPartTask && tabIndex === 1);
};

export const isTaskPickup = ({
    isTwoPartTask,
    isPickup,
    tabIndex
}: TaskObject) => {
    return (!isTwoPartTask && isPickup) || (isTwoPartTask && tabIndex === 0);
};

export const getUpdatedDeliveryLocation = ({
    task,
    id,
    value
}: TaskUpdateObject) => {
    const { deliveryLocation } = task;
    const updatedLocation = {
        taskId: id,
        property: 'deliveryLocation',
        value: { ...deliveryLocation, name: value }
    };
    return updatedLocation;
};

export const getUpdatedPickupLocation = ({
    task,
    id,
    value
}: TaskUpdateObject) => {
    const { pickupLocation } = task;
    const updatedLocation = {
        taskId: id,
        property: 'pickupLocation',
        value: { ...pickupLocation, name: value }
    };
    return updatedLocation;
};

export const getUpdatedDeliveryContactPhone = ({
    task,
    id,
    value
}: TaskUpdateObject) => {
    const { deliveryContact } = task;
    const updatedContact = {
        taskId: id,
        property: 'deliveryContact',
        value: { ...deliveryContact, phone: value }
    };
    return updatedContact;
};

export const getUpdatedPickupContactPhone = ({
    task,
    id,
    value
}: TaskUpdateObject) => {
    const { pickupContact } = task;
    const updatedContact = {
        taskId: id,
        property: 'pickupContact',
        value: { ...pickupContact, phone: value }
    };
    return updatedContact;
};

export const getUpdatedPickupContactEmail = ({
    task,
    id,
    value
}: TaskUpdateObject) => {
    const { pickupContact } = task;
    const updatedContact = {
        taskId: id,
        property: 'pickupContact',
        value: { ...pickupContact, email: value }
    };
    return updatedContact;
};

export const getUpdatedDeliveryContactEmail = ({
    task,
    id,
    value
}: TaskUpdateObject) => {
    const { deliveryContact } = task;
    const updatedContact = {
        taskId: id,
        property: 'deliveryContact',
        value: { ...deliveryContact, email: value }
    };
    return updatedContact;
};

export const getUpdatedPickupServiceTime = ({
    id,
    serviceTimeDurationISO
}: TaskUpdateObject) => {
    return {
        taskId: id,
        property: 'props',
        value: { pickupServiceTime: serviceTimeDurationISO }
    };
};

export const getUpdatedDeliveryServiceTime = ({
    id,
    serviceTimeDurationISO
}: TaskUpdateObject) => {
    return {
        taskId: id,
        property: 'props',
        value: { deliveryServiceTime: serviceTimeDurationISO }
    };
};

export const getFilteredInvoiceItem = (
    invoices: Array<InvoiceAndPaymentDetailsData>,
    selectedItem: InvoiceAndPaymentDetailsData
) => {
    return invoices.filter(
        ({ invoiceId }) => selectedItem.invoiceId !== invoiceId
    );
};

export const getFilteredTask = ({
    isTwoPartTask,
    isDelivery,
    isPickup,
    task,
    tabIndex,
    taskInventoryItems
}: {
    isTwoPartTask: boolean;
    isDelivery: boolean;
    isPickup: boolean;
    task: ApiTask;
    tabIndex: number;
    taskInventoryItems: ApiInventoryItem[] | undefined;
}) => {
    if (!isTwoPartTask) {
        if (isDelivery) {
            return taskUtils.filterDeliveryTask(task, taskInventoryItems);
        }

        if (isPickup) {
            return taskUtils.filterPickupTask(task, taskInventoryItems);
        }

        return undefined;
    }

    if (tabIndex === 0) {
        return taskUtils.filterPickupTask(task, taskInventoryItems);
    }

    if (tabIndex === 1) {
        return taskUtils.filterDeliveryTask(task, taskInventoryItems);
    }

    return undefined;
};

export const getIsTaskEditable = ({
    isWithEditPermissions,
    taskStatus,
    allowEditingAssignedTasks
}: {
    taskStatus?: number;
    isWithEditPermissions: boolean;
    allowEditingAssignedTasks?: boolean;
}): boolean => {
    if (!isWithEditPermissions) return false;

    if (
        allowEditingAssignedTasks &&
        (taskStatus === constants.taskStatus.DISPATCHED ||
            taskStatus === constants.taskStatus.IN_PROGRESS)
    )
        return true;

    return (
        taskStatus === constants.taskStatus.PLANNED ||
        taskStatus === constants.taskStatus.UNASSIGNED
    );
};
