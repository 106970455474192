import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LiveDispatchTaskTableRowData } from '~/data-classes';
import constants from '~/utils/constants';
import { LiveDispatchTableColumnId, TableHeaderProps } from '../../types';
import { TableSortElement } from '../../TableSortElement/TableSortElement';
import { useLiveDispatchTableStates } from '../../useLiveDispatchTableStates';
import { TableFilterElement } from '../../TableFilterElement/TableFilterElement';

const ROOT_CLASS_NAME = 'live-dispatch-table-header';

interface CheckboxHandler {
    isChecked: boolean;
    value?: string | number | unknown;
    selectedValues?: Array<string | number | unknown>;
}

export const LiveDispatchTableHeader = ({
    header,
    children,
    className,
    ...extra
}: TableHeaderProps<LiveDispatchTaskTableRowData>) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { id } = header || {};
    const headerText = id && t(`columns.taskTable.${id}`);
    const defaultTestId = id ? `${ROOT_CLASS_NAME}__${id}` : ROOT_CLASS_NAME;
    const dataTestId = extra['data-testid'] || defaultTestId;
    const isColumnFilterActive = header?.column.columnDef.enableColumnFilter;
    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        className,
        {
            [`${ROOT_CLASS_NAME}__${id}`]: id
        },
        'sortable-header'
    );
    const { columnFilters, setColumnFilters, onApplySorting, onClearSorting } =
        useLiveDispatchTableStates();

    const [selectedStatusFilter, setSelectedStatusFilter] = useState<string[]>(
        []
    );

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const facetedUniqueValues = header?.column.getFacetedUniqueValues() || [];
    const filterList = useMemo(() => {
        if (isColumnFilterActive) {
            const typeCounts: { name: string; numberOfEntries: number }[] = [];

            for (const entry of facetedUniqueValues.entries()) {
                typeCounts.push({
                    name: entry[0] ? entry[0] : constants.common.notApplicable,
                    numberOfEntries: entry[1]
                });
            }

            typeCounts.sort((a, b) => a.name.localeCompare(b.name)); // Sort by "name" in alphabetical order

            return typeCounts;
        }
        return [];
    }, [facetedUniqueValues, isColumnFilterActive]);

    const handleFilterStatusCheckboxClick = ({
        isChecked,
        value
    }: CheckboxHandler) => {
        if (isChecked) {
            setSelectedStatusFilter([...selectedStatusFilter, value as string]);
        } else {
            const removedEntryList = selectedStatusFilter.filter(
                (filter) => filter !== value
            );
            setSelectedStatusFilter([...removedEntryList]);
        }
    };

    const onClearStatusFilter = () => {
        setSelectedStatusFilter([]);
        const updatedColumnFilter = columnFilters.filter(
            (columnFilter) =>
                columnFilter.id !== LiveDispatchTableColumnId.EQUIPMENT_TYPE
        );
        setColumnFilters([...updatedColumnFilter]);
    };

    const onApplyStatusFilter = () => {
        if (selectedStatusFilter.length) {
            const filteredFromTaskType = columnFilters.filter(
                (columnFilter) =>
                    columnFilter.id !== LiveDispatchTableColumnId.EQUIPMENT_TYPE
            );

            setColumnFilters([
                ...filteredFromTaskType,
                {
                    id: LiveDispatchTableColumnId.EQUIPMENT_TYPE,
                    value: selectedStatusFilter
                }
            ]);
        } else {
            onClearStatusFilter();
        }
    };

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            {header?.column.columnDef.enableSorting && id && (
                <TableSortElement
                    columnId={id}
                    onClearSorting={onClearSorting}
                    onApplySorting={onApplySorting}
                />
            )}
            {isColumnFilterActive && (
                <TableFilterElement
                    headerText={headerText}
                    headerId={id || ''}
                    columnFilters={columnFilters}
                    filterList={filterList}
                    selectedFilterList={selectedStatusFilter}
                    handleFilterCheckboxClick={handleFilterStatusCheckboxClick}
                    onClearStatusFilter={onClearStatusFilter}
                    onApplyStatusFilter={onApplyStatusFilter}
                />
            )}
            {children}
            <span data-testid={`${dataTestId}-text`}>{headerText}</span>
        </div>
    );
};
