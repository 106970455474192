import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectHasIsolatedRoutes,
    selectIsMultipleCardSelectEnabled,
    selectViewCardDetails,
    setHasIsolatedRoutes as updateHasIsolatedRoutes,
    setIsMultipleCardSelectEnabled as updateIsMultipleCardSelectEnabled,
    setViewCardDetails as updateViewCardDetails
} from '~/reducers/mapSettingsSlice';

export const useIsolatedRoutes = () => {
    const dispatch = useDispatch();

    const hasIsolatedRoutes = useSelector(selectHasIsolatedRoutes);

    const isMultipleCardSelectEnabled = useSelector(
        selectIsMultipleCardSelectEnabled
    );

    const viewCardDetails = useSelector(selectViewCardDetails);

    const setHasIsolatedRoutes = (value: boolean) => {
        return dispatch(updateHasIsolatedRoutes(value));
    };

    const setIsMultipleCardSelectEnabled = useCallback(
        (value: boolean) => {
            return dispatch(updateIsMultipleCardSelectEnabled(value));
        },
        [dispatch]
    );

    const setViewCardDetails = (value: boolean) => {
        return dispatch(updateViewCardDetails(value));
    };

    const isolateSingleRoute = (isolate = true) => {
        setHasIsolatedRoutes(isolate);
        setIsMultipleCardSelectEnabled(false);
        setViewCardDetails(false);
    };

    const isolateMultipleRoutes = (isolate = true) => {
        setHasIsolatedRoutes(isolate);
        setIsMultipleCardSelectEnabled(isolate);
        setViewCardDetails(isolate);
    };

    return {
        hasIsolatedRoutes,
        isMultipleCardSelectEnabled,
        viewCardDetails,
        setHasIsolatedRoutes,
        setIsMultipleCardSelectEnabled,
        setViewCardDetails,
        isolateSingleRoute,
        isolateMultipleRoutes
    };
};
