import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Equipment } from '~/data-classes';
import { TableHeaderProps } from '../types';
import { useEquipmentTableStates } from '../useEquipmentTableStates';
import classNames from 'classnames';
import { TableSortElement } from '../TableSortElement/TableSortElement';
import { TableFilterElement } from '../TableFilterElement';
import { DateTime } from 'luxon';

const ROOT_CLASS_NAME = 'equipment-table-header';

export const EquipmentTableHeaderUpdatedAt = ({
    header,
    children,
    className,
    ...extra
}: TableHeaderProps<Equipment>) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { id } = header || {};
    const headerText = id && t(`columns.equipmentTable.${id}`);
    const [selectedDateFilter, setSelectedDateFilter] = useState<string[]>([]);
    const defaultTestId = id ? `${ROOT_CLASS_NAME}__${id}` : ROOT_CLASS_NAME;
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const dataTestId = extra['data-testid'] || defaultTestId;
    const elementClassName = classNames(ROOT_CLASS_NAME, className, {
        [`${ROOT_CLASS_NAME}__${id}`]: id
    });

    const { columnFilters, setColumnFilters, onApplySorting, onClearSorting } =
        useEquipmentTableStates();

    const isEnabledSorting = id && header?.column.columnDef.enableSorting;
    const isEnabledColumnFilter =
        id && header?.column.columnDef.enableColumnFilter;

    const selectedColumnFilterData = useMemo(() => {
        if (!columnFilters.length) return;

        return columnFilters.filter((data) => data.id === id);
    }, [columnFilters, id]);

    useEffect(() => {
        if (!selectedColumnFilterData?.length) return;

        const [{ value }] = selectedColumnFilterData;
        const [start, end] = value as string[];
        const startDateTime = DateTime.fromISO(start).toJSDate();
        const endDateTime = DateTime.fromISO(end).minus({ day: 1 }).toJSDate();
        const isSameDay = startDateTime.getTime() === endDateTime.getTime();

        setStartDate(startDateTime);
        setEndDate(isSameDay ? null : endDateTime);
    }, [selectedColumnFilterData]);

    const onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        const startDateTime = DateTime.fromJSDate(start).toUTC();
        const endDateTime = end && DateTime.fromJSDate(end).toUTC();
        const dateRange = [startDateTime.toISO()];

        if (endDateTime) {
            // when an end date is provided, we will include records within 24 hours of the selected end date
            dateRange.push(endDateTime.plus({ days: 1 }).toISO());
        } else {
            // when only a start date is provided, we will include records within 24 hours of the selected start date
            dateRange.push(startDateTime.plus({ days: 1 }).toISO());
        }

        setStartDate(start);
        setEndDate(end);
        setSelectedDateFilter(dateRange);
    };

    const onClearUpdateAtFilter = () => {
        setSelectedDateFilter([]);
        const updatedColumnFilter = columnFilters.filter(
            (data) => data.id !== id
        );
        setStartDate(null);
        setEndDate(null);
        setColumnFilters([...updatedColumnFilter]);
    };

    const onApplyUpdateAtFilter = () => {
        if (!selectedDateFilter.length) return;

        const filterFromUpdateAt = columnFilters.filter(
            (data) => data.id !== id
        );

        const addColumnFilter = {
            id: id as string,
            value: selectedDateFilter
        };

        setColumnFilters([...filterFromUpdateAt, addColumnFilter]);
    };

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            {isEnabledSorting && (
                <TableSortElement
                    columnId={id}
                    onClearSorting={onClearSorting}
                    onApplySorting={onApplySorting}
                />
            )}

            {isEnabledColumnFilter && (
                <TableFilterElement
                    headerText={headerText}
                    headerId={id}
                    selectedStartDate={startDate}
                    selectedEndDate={endDate}
                    onChangeDateMultiple={onChange}
                    columnFilters={columnFilters}
                    onClearStatusFilter={onClearUpdateAtFilter}
                    onApplyStatusFilter={onApplyUpdateAtFilter}
                />
            )}

            {children}
            <span data-testid={`${dataTestId}-text`}>{headerText}</span>
        </div>
    );
};
