import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    ManageStopControlMenu,
    ManageStopControlSingle,
    StopModalMenuButton
} from '~/ui';

import {
    useUnassignStops,
    useOnDemandDispatchUnassignTasks,
    useToastContext
} from '~/hooks';
import { selectMainClient } from '~/reducers/mainClientSlice';
import constants from '~/utils/constants';
import { MarkEndOfShiftControl } from '~/components/MapPage/MarkEndOfShiftControl';
import { useEndOfShiftCallbacks } from '../../MarkEndOfShiftControl/useEndOfShiftCallbacks';
import { selectMapRouteMode } from '~/reducers/mapSettingsSlice';

function SingleStopControl({
    stopData,
    onSwitchView,
    disableResequencing = false,
    footerButtonTitle,
    footerButtonIcon,
    isLiveStopControl,
    onClickFooterButton
}) {
    const { hasLastStopInShift, handleRemoveShiftEnd } =
        useEndOfShiftCallbacks(stopData);
    const { t } = useTranslation('translation');
    const { unassignStops, isUnassignStopsAllowed } = useUnassignStops();
    const { cancelTasks, unassignTasks } = useOnDemandDispatchUnassignTasks();
    const { addToast } = useToastContext();
    const mainClient = useSelector(selectMainClient);
    const mapRouteMode = useSelector(selectMapRouteMode);
    const showDispatchCancelButton =
        mainClient?.preferences?.showDispatchCancelButton;
    const showReassignButton = [
        constants.mapRouteModes.PLAN,
        constants.mapRouteModes.EMPTY
    ].includes(mapRouteMode);

    function handleClickLiveStopUnassign() {
        unassignTasks({
            onSuccess: (data) => {
                const count = JSON.parse(data.config.data).taskIds.length;
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessUnassign', {
                        count
                    }),
                    variant: 'success'
                });
                if (hasLastStopInShift) {
                    handleRemoveShiftEnd(stopData);
                }
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorUnassign', {
                        count: 1
                    }),
                    variant: 'error'
                });
            }
        });
    }

    function handleClickLiveStopCancel() {
        cancelTasks({
            onSuccess: (data) => {
                const count = JSON.parse(data.config.data).taskIds.length;
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessCancel', {
                        count
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorCancel', {
                        count: 1
                    }),
                    variant: 'error'
                });
            }
        });
    }

    return (
        <ManageStopControlSingle
            className="singlestopcontrol"
            stopData={stopData}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    buttonIcon="iconReorder"
                    buttonText={t('PlanStopsControl.button.resequence')}
                    onClick={() =>
                        onSwitchView(constants.stopsControl.views.RESEQUENCE)
                    }
                    buttonStopText={t('PlanStopsControl.button.stopText', {
                        stopIndex: stopData.stopNumber
                    })}
                    disabled={disableResequencing}
                    showButtonArrow
                    data-testid="button-resequence"
                />
                {showReassignButton && (
                    <StopModalMenuButton
                        buttonIcon="iconRouteFill"
                        buttonText={t('PlanStopsControl.button.reassign')}
                        showButtonArrow
                        onClick={() =>
                            onSwitchView(constants.stopsControl.views.REASSIGN)
                        }
                        data-testid="button-reassign"
                    />
                )}
                {isLiveStopControl && (
                    <StopModalMenuButton
                        onClick={handleClickLiveStopUnassign}
                        buttonIcon="unassign"
                        buttonText={t('PlanStopsControl.button.unassign')}
                        data-testid="button-live-stop-unassign"
                    />
                )}
                {isLiveStopControl && showDispatchCancelButton && (
                    <StopModalMenuButton
                        onClick={handleClickLiveStopCancel}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.cancel')}
                        data-testid="button-cancel"
                    />
                )}
                {isLiveStopControl && (
                    <MarkEndOfShiftControl stopData={stopData} />
                )}
                {!isLiveStopControl && (
                    <StopModalMenuButton
                        disabled={!isUnassignStopsAllowed}
                        onClick={unassignStops}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.unassign')}
                        data-testid="button-plan-stop-unassign"
                    />
                )}
            </ManageStopControlMenu>
        </ManageStopControlSingle>
    );
}

export default SingleStopControl;
