import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { selectSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { setIsOpenPairEquipmentControlPopup } from '~/reducers/mapDrawerSettingsSlice';
import { selectTasks } from '~/reducers/tasksSlice';

import { usePairItemHooks } from '~/components/TaskManagementPage/TaskActionBar/usePairItemHooks';
import { useLiveDispatchTabsLogic } from '../../useLiveDispatchTabsLogic';

import { ApiTask } from '~/api/types';
import { InitialTaskActions } from '~/hooks/useTaskAvailableActions/types';

interface UsePairDropdownButtonLogicHandlerParams {
    isDisplayed?: InitialTaskActions;
}

export const usePairDropdownButtonLogicHandler = ({
    isDisplayed
}: UsePairDropdownButtonLogicHandlerParams) => {
    const dispatch = useDispatch();
    const pairButtonRef = useRef();
    const [isPairDropdownVisible, setIsPairDropdownVisible] = useState(false);

    const { isUnassignedTasksTabActive } = useLiveDispatchTabsLogic();

    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);

    const allTasks = useSelector(selectTasks) as unknown as Record<
        string,
        ApiTask
    >;

    const isSelectedTaskIdsEmpty = !selectedTaskIds.length;
    const isSelectedEquipmentIdsEmpty = !selectedEquipmentIds.length;
    const isDisplayedEquipmentFound = Boolean(isDisplayed?.equipment);

    useEffect(() => {
        if (isDisplayedEquipmentFound) return;
        if (
            (isUnassignedTasksTabActive && isSelectedTaskIdsEmpty) ||
            (!isUnassignedTasksTabActive && isSelectedEquipmentIdsEmpty)
        ) {
            dispatch(setIsOpenPairEquipmentControlPopup(false));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        isDisplayedEquipmentFound,
        isSelectedEquipmentIdsEmpty,
        isSelectedTaskIdsEmpty,
        isUnassignedTasksTabActive
    ]);

    const {
        pairItemRecords: {
            pair: taskPairItem,
            equipment: equipmentPairItem,
            depot: depotPairItem
        },
        handleManualPair,
        handleDepotPair,
        handleEquipmentPair,
        handleEquipmentDepotPair
    } = usePairItemHooks({
        allTasks
    });

    return {
        pairButtonRef,
        isPairDropdownVisible,
        setIsPairDropdownVisible,
        taskPairItem: { ...taskPairItem, onClick: handleManualPair },
        equipmentPairItem: {
            ...equipmentPairItem,
            onClick: handleEquipmentPair
        },
        depotPairItem: {
            ...depotPairItem,
            onClick: isUnassignedTasksTabActive
                ? handleDepotPair
                : handleEquipmentDepotPair
        }
    };
};
