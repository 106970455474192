import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Alert, Collapsible, FormikTextInputField } from '~/ui';
import localizationUtils from '~/utils/localization-utils';

import './AddressForm.scss';

function AddressForm(props) {
    const { ...extra } = props;
    const { t } = useTranslation(['taskManagement', 'common']);

    const englishFormatAddressInputs = [
        { name: 'addressLine1', label: t('editAddress.address') },
        { name: 'addressLine2', placeholder: t('editAddress.address2') },
        { name: 'city', label: t('editAddress.city') },
        { name: 'state', label: t('editAddress.province') },
        { name: 'zipcode', label: t('editAddress.postalCode') },
        { name: 'country', label: t('editAddress.country'), isMandatory: false }
    ];

    const addressInputs = localizationUtils.formatAddressInputsByLocale(
        englishFormatAddressInputs
    );

    const coordinateInputs = [
        {
            name: 'latitude',
            label: t('editAddress.latitude'),
            isMandatory: false
        },
        {
            name: 'longitude',
            label: t('editAddress.longitude'),
            isMandatory: false
        }
    ];

    /**
     * Renders the input with FormikTextInputField
     * @param {Object} input
     * @param {String} input.name - Input name for the value
     * @param {String} input.label - Label content
     * @param {Boolean} input.isMandatory - Display asterisk for mandatory field
     * @param {String} input.placeholder - Placeholder content (same as label by default)
     * @returns {JSX.Element}
     */
    function renderLabelInput({
        name,
        label,
        isMandatory = true,
        placeholder
    }) {
        return (
            <FormikTextInputField
                key={name}
                className={`addressform-input_${name}`}
                inputFieldId={name}
                label={label}
                placeholder={placeholder || label}
                data-testid={`labelinput-${name}`}
                isOptional={!isMandatory}
            />
        );
    }

    return (
        <div
            className={classNames('_d-grid addressform', extra.className)}
            data-testid={extra['data-testid'] || 'address-form'}
        >
            {addressInputs.map((addressInput) =>
                renderLabelInput(addressInput)
            )}
            <Collapsible
                className="addressform-collapsible"
                textClose={t('editAddress.textClose')}
                textOpen={t('editAddress.textOpen')}
                iconClose="chevronDown"
                iconOpen="chevronUp"
                iconColor="meteor"
                iconPosition={Collapsible.iconPosition.LEFT}
                data-testid="addressform-collapsible"
            >
                <div className="_d-grid addressform-coords">
                    <Alert
                        variant="info"
                        icon="infoLine"
                        iconSize="m"
                        iconColor="meteor"
                        className="addressform-coords_alert"
                        data-testid="addressform-info"
                    >
                        {t('editAddress.coordinateAlert')}
                    </Alert>
                    {coordinateInputs.map((coordinateInput) =>
                        renderLabelInput(coordinateInput)
                    )}
                </div>
            </Collapsible>
        </div>
    );
}

export default AddressForm;
