import { LiveStop, PlanStop } from '~/data-classes';
import React, { useMemo, useState } from 'react';
import {
    useFeatureFlag,
    useIsolatedRoutes,
    useLiveRoutesUtils,
    useMapUtils
} from '~/hooks';
import { Coordinates } from '~/api/types';
import { popupMaker } from '~/utils/map';
import constants from '~/utils/constants';
import { useHereMapContext } from '~/components/HereMaps/HereMapProvider';
import { useSelector } from 'react-redux';
import {
    selectIsClusteringStops,
    selectShowDriverName,
    selectShowLiveRouteLabel,
    selectShowLiveRoutePopup,
    selectShowStopLabel,
    selectShowStopNumber,
    selectShowStopPopup,
    selectShowVehicleEid
} from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useStopMarkerSelect } from '~/components/MapPage/useStopMarkerSelect';
import { DriverMarkerPopup } from '~/ui';
import LiveRoutesMarker, {
    getExceptionIcons
} from '~/ui/components/LiveRoutesMarker/LiveRoutesMarker';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { RoutesWithTasks } from '~/hooks/useRoutes/useRoutesWithTasks';
import { selectIsOpenAnyUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';

interface UseDispatchedMarkersConfig {
    markers: JSX.Element[];
}

export const useDispatchedMarkersConfig = ({
    markers
}: UseDispatchedMarkersConfig) => {
    const [hoveredRouteMarker, setHoveredRouteMarker] = useState(null);
    const { handleDriverSelect, isDriverSelected } = useLiveRoutesUtils();

    const isClientTimezoneFlagEnabled = useFeatureFlag(
        FeatureFlag.CLIENT_TIMEZONE
    );
    const { isDispatchedRouteMode, mapRouteMode } = useMapUtils();

    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const isOpenAnyUnassignedTasksDrawer = useSelector(
        selectIsOpenAnyUnassignedTasksDrawer
    );

    const showLiveRoutesLabel = useSelector(
        selectShowLiveRouteLabel(mapRouteMode as ConfigurableMapRouteMode)
    );
    const showDriverPopup = useSelector(
        selectShowLiveRoutePopup(constants.mapRouteModes.DISPATCHED)
    );
    const { showPopup, removePopup } = useHereMapContext();
    const { hasIsolatedRoutes } = useIsolatedRoutes();
    const showStopSequenceNumber = useSelector(
        selectShowStopNumber(mapRouteMode as ConfigurableMapRouteMode)
    );
    const showDriverName = useSelector(
        selectShowDriverName(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const showVehicleEid = useSelector(
        selectShowVehicleEid(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const showStopLabel = useSelector(
        selectShowStopLabel(mapRouteMode as ConfigurableMapRouteMode)
    );

    const { toggleIsSelected: toggleIsStopSelected } = useStopMarkerSelect();

    const showStopPopup = useSelector(
        selectShowStopPopup(mapRouteMode as unknown as ConfigurableMapRouteMode)
    );

    const handlePopupEvents = ({ event }: { event: string }) => {
        const { mapChildEvents } = constants;
        switch (event) {
            case mapChildEvents.POPUP_MOUSELEAVE:
                removePopup();
                break;
            default:
                break;
        }
    };

    const handleDriverRouteMarkerClick = (
        driverData: Record<string, unknown>
    ) => {
        const { clientDriverId } = driverData;
        const isSelected = isDriverSelected(clientDriverId as string);
        handleDriverSelect(
            !isSelected,
            driverData as unknown as RoutesWithTasks
        );
    };

    const handleLiveRouteMarkerMouseEnter = (payload: {
        driverData: Record<string, unknown>;
        eventCoordinates: Record<string, number>;
    }) => {
        if (!showDriverPopup) {
            return;
        }
        const { driverData, eventCoordinates } = payload;
        const popup = (
            <DriverMarkerPopup
                driverData={driverData}
                exceptionData={getExceptionIcons(driverData)}
            />
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        showPopup(popup, eventCoordinates);
    };

    const handleStopMarkerClick = (marker: JSX.Element) => {
        const {
            props: { data }
        } = marker;
        toggleIsStopSelected(data);
    };

    const handleStopMarkerMouseEnter = (payload: {
        eventCoordinates: Record<string, number>;
        location: Coordinates;
        markerData: PlanStop | LiveStop;
    }) => {
        if (!showStopPopup) {
            return;
        }
        const { markerData } = payload;
        const popupPayload = {
            ...payload,
            stopMarkerData: markerData
        };
        const popup = popupMaker.makeDispatchedStopPopup(
            popupPayload,
            handlePopupEvents,
            isClientTimezoneFlagEnabled
        );
        const { eventCoordinates } = payload;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        showPopup(popup, eventCoordinates);
    };
    const markersConfig = useMemo(() => {
        if (!isDispatchedRouteMode) {
            return;
        }
        if (isOpenAnyUnassignedTasksDrawer) {
            const markerConfigs = markers.map((marker) => {
                const { props } = marker;
                const { lat, lng, data = {} } = props;
                const coordinates = { lat, lng };
                const { id } = data;
                const events = [
                    {
                        event: 'pointerenter',
                        handler: (eventCoordinates: Record<string, number>) => {
                            handleStopMarkerMouseEnter({
                                eventCoordinates,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                location: coordinates,
                                markerData: data
                            });
                        }
                    },
                    {
                        event: 'pointerup',
                        handler: () => {
                            handleStopMarkerClick(marker);
                        }
                    }
                ];
                return {
                    id,
                    component: marker,
                    coordinates,
                    events
                };
            });
            return markerConfigs;
        }

        if (hasIsolatedRoutes) {
            const markerConfigs = markers.map((marker) => {
                const { props } = marker;
                const { id, lat, lng, stopData } = props;
                const coordinates = { lat, lng };
                const { stopId, taskId } = stopData || {};
                const markerId = id || stopId || taskId;
                const events = [
                    {
                        event: 'pointerenter',
                        handler: (eventCoordinates: Record<string, number>) => {
                            handleStopMarkerMouseEnter({
                                eventCoordinates,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                location: coordinates,
                                markerData: stopData
                            });
                        }
                    },
                    {
                        event: 'pointerup',
                        handler: () => {
                            handleStopMarkerClick(marker);
                        }
                    }
                ];
                return {
                    id: markerId,
                    component: marker,
                    coordinates,
                    events
                };
            });
            return markerConfigs;
        }
        return markers
            .map((marker) => {
                if (marker.type !== LiveRoutesMarker) {
                    return null;
                }
                const { props } = marker;
                const { lat, lng, driverData } = props;
                const coordinates = { lat, lng };
                const { clientDriverId } = driverData;
                const events = [
                    {
                        event: 'pointerenter',
                        handler: (eventCoordinates: Record<string, number>) => {
                            setHoveredRouteMarker(clientDriverId);
                            handleLiveRouteMarkerMouseEnter({
                                eventCoordinates,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                location: coordinates,
                                driverData
                            });
                        }
                    },
                    {
                        event: 'pointerleave',
                        handler: () => {
                            setHoveredRouteMarker(null);
                            removePopup();
                        }
                    },
                    {
                        event: 'pointerup',
                        handler: () => {
                            handleDriverRouteMarkerClick(driverData);
                        }
                    }
                ];
                return {
                    id: clientDriverId,
                    component: marker,
                    coordinates,
                    events
                };
            })
            .filter(Boolean);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        markers,
        isClusteringStops,
        isDispatchedRouteMode,
        hasIsolatedRoutes,
        showDriverPopup,
        showDriverName,
        showVehicleEid,
        showLiveRoutesLabel,
        showStopLabel,
        showStopPopup,
        isOpenAnyUnassignedTasksDrawer,
        showStopSequenceNumber
    ]);

    return { markersConfig, hoveredRouteMarker };
};
