import React from 'react';
import { Button } from '~/ui';

import { useTranslation } from 'react-i18next';

import './AddReviseTimeButton.scss';

const ROOT_CLASS_NAME = 'add-revise-time-button';

interface AddReviseTimeButtonProps {
    onClick: () => void;
}

export const AddReviseTimeButton = ({ onClick }: AddReviseTimeButtonProps) => {
    const { t } = useTranslation('taskManagement');

    return (
        <div className={ROOT_CLASS_NAME}>
            <Button
                className={`${ROOT_CLASS_NAME}__button`}
                data-testid={`${ROOT_CLASS_NAME}`}
                onClick={onClick}
            >
                <span
                    className={`${ROOT_CLASS_NAME}__button-text`}
                    data-testid="add-revise-time-button-text"
                >
                    {t('reviseTime.buttonTitle')}
                </span>
            </Button>
        </div>
    );
};
