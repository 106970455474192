import { useQuery, UseQueryOptions } from 'react-query';
import EquipmentApi from '~/api/EquipmentApi';
import { ApiEquipmentAttributes } from '~/api/types';

export type UseGetRoutePlansReactQueryOptions = Omit<
    UseQueryOptions<ApiEquipmentAttributes, Error>,
    'queryKey' | 'queryFn'
>;

interface UseGetEquipmentAttributesParams {
    /**
     * Additional react query options
     */
    reactQueryOptions?: UseGetRoutePlansReactQueryOptions;
}

const REACT_QUERY_KEY = 'equipment_attributes';

export const useGetEquipmentAttributes = ({
    reactQueryOptions = {}
}: UseGetEquipmentAttributesParams = {}) => {
    const fetchEquipmentAttributes =
        async (): Promise<ApiEquipmentAttributes> => {
            const {
                data: { data }
            } = await EquipmentApi.getEquipmentAttributes();
            return data;
        };

    // return the full useQuery Object
    return useQuery<ApiEquipmentAttributes, Error>(
        [REACT_QUERY_KEY],
        () => fetchEquipmentAttributes(),
        reactQueryOptions
    );
};
