/* eslint-disable no-restricted-globals */
import io from 'socket.io-client';
import constants from '~/utils/constants';

import { store } from '~/store';
import {
    addActiveClients,
    removeActiveClients
} from '~/reducers/activeClientsSlice';
import { updateLiveDrivers } from '~/reducers/liveDriversSlice';
import { setOnDemandDispatchTaskCount } from '~/reducers/onDemandDispatchTaskCountSlice';

import socketMessageHandler from '~/utils/socket/socket-message-handler';
import filterSocketMessages from '~/utils/socket/filter-socket-message';
import { debounce } from 'lodash';

let currentSocketInstance;
const joinedRooms = new Set();
// todo: join with socket-instance.js

const debouncedGetWeb = debounce(
    (clientId) => socketMessageHandler.handleEndAdjustment(clientId),
    constants.timings.GET_WEB_INTERVAL
);

async function handleSocketMessage(message) {
    const { message: socketEvent, clientId, schedulerTaskId, data } = message;
    if (schedulerTaskId && !data.schedulerTaskId) {
        data.schedulerTaskId = schedulerTaskId;
    }
    switch (socketEvent) {
        case constants.socketCustom.INTERSTELLAR_SOLUTION:
            socketMessageHandler.handleWebSolution({ data, clientId });
            break;
        case constants.socketCustom.CEP_RESULTS:
            handleCEPMessage(data, clientId);
            break;
        case constants.socketCustom.CUSTOMER_SEARCH:
            socketMessageHandler.handleCustomerSearch({ data });
            break;
        case constants.socketCustom.CUSTOMER_SEARCH_ERROR:
            socketMessageHandler.handleCustomerSearchError();
            break;
        case constants.socketCustom.DIRECT_ROUTE_IMPACT:
            await socketMessageHandler.handleDirectRouteImpact({
                data,
                clientId
            });
            break;
        case constants.socketCustom.DRIVERS:
            store.dispatch(updateLiveDrivers({ drivers: data, clientId }));
            break;
        case constants.socketCustom.METRICS:
            store.dispatch(
                setOnDemandDispatchTaskCount(data.tasks.activeTasks.unassigned)
            );
            break;
        case constants.socketCustom.JOIN_ROOMS:
            store.dispatch(addActiveClients([clientId]));
            break;
        case constants.socketCustom.LEAVE_ROOMS:
            store.dispatch(removeActiveClients([clientId]));
            break;
        case constants.socketCustom.PAIRING_EXECUTED:
            socketMessageHandler.handlePairingExecuted({ data });
            break;
        case constants.socketCustom.ON_DEMAND_TASK_ADDED:
            await socketMessageHandler.handleOnDemandTaskAdded(data);
            break;
        case constants.socketCustom.ON_DEMAND_TASK_ADDED_BULK:
            socketMessageHandler.handleOnDemandTaskAddedBulk();
            break;
        case constants.socketCustom.ON_DEMAND_TASK_REMOVED:
            socketMessageHandler.handleOnDemandTaskRemoved(data);
            break;
        case constants.socketCustom.UPLOAD:
            socketMessageHandler.handleUpload(message);
            break;
        case constants.socketCustom.END_ADJUSTMENT:
            await debouncedGetWeb(clientId);
            break;
        default:
            // socketEvent ignored
            break;
    }
}

function handleCEPMessage(data, clientId) {
    data = filterSocketMessages.filterCepResults({
        data,
        clientId
    });
    socketMessageHandler.handleCepResults({ data, clientId });
}

function connectToServer(payload) {
    const newSocketInstance = io(payload.socketUrl, {
        transports: ['websocket'],
        auth: { token: payload.accessToken }
    });
    currentSocketInstance = newSocketInstance;
    joinedRooms.clear();

    newSocketInstance.on(constants.socketReserved.ERROR, (error) => {
        console.warn('Socket Error', error);
        newSocketInstance.close();
    });

    newSocketInstance.on(constants.socketReserved.CONNECT_ERROR, (error) => {
        console.warn('Socket Connect Error', error);
        newSocketInstance.close();
    });

    newSocketInstance.on(constants.socketReserved.CONNECT, () => {});
    newSocketInstance.on(constants.socketCustom.MESSAGE, handleSocketMessage);
    joinClientRooms(payload);
}

function handleLeaveRooms(oldSocketInstance, clientIds) {
    if (!oldSocketInstance) return;

    clientIds.forEach((clientId) => {
        oldSocketInstance.emit(constants.socketCustom.LEAVE_ROOMS, clientId);
    });
}

function reconnectToServer(payload) {
    const oldSocketInstance = currentSocketInstance;
    const clientIds = Array.from(joinedRooms);

    connectToServer(payload);
    handleLeaveRooms(oldSocketInstance, clientIds);
    oldSocketInstance?.close();
}

function joinClientRooms(payload) {
    const { clientIds = [] } = payload;

    for (const clientId of clientIds) {
        if (!joinedRooms.has(clientId)) {
            currentSocketInstance.emit(
                constants.socketCustom.JOIN_ROOMS,
                clientId
            );
            joinedRooms.add(clientId);
        }
    }
}

function leaveRooms(payload) {
    const { clientIds = [] } = payload;
    handleLeaveRooms(currentSocketInstance, clientIds);
    clientIds.forEach((clientId) => joinedRooms.delete(clientId));
}

function leaveAllRooms() {
    if (currentSocketInstance) {
        // notify the server about leaving the room so that server leaves too
        // in order to re-establish connection on logout/login
        const payload = {
            clientIds: Array.from(joinedRooms)
        };
        leaveRooms(payload);
    }
}

function disconnectFromServer() {
    if (!currentSocketInstance) return;

    leaveAllRooms();
    currentSocketInstance.close();
    currentSocketInstance = null;
}

export default {
    connectToServer,
    disconnectFromServer,
    leaveAllRooms,
    joinClientRooms,
    leaveRooms,
    reconnectToServer,
    handleSocketMessage
};
