import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableFooterElement } from '../TableFooterElement';
import { useEquipmentTablePagination } from '../useEquipmentTablePagination';
import { useEquipmentTableContext } from '../EquipmentTableContext';
import './EquipmentTableFooter.scss';

const ROOT_CLASS_NAME = 'equipment-table-footer';

export const EquipmentTableFooter = () => {
    const { t } = useTranslation('liveDispatchTable');

    const { goToFirstPage, goToLastPage, goToNextPage, goToPrevPage } =
        useEquipmentTablePagination();

    const {
        pageIndex: currentPage,
        pageSize,
        pageTotal: totalPages,
        totalEquipmentCount: totalRecords
    } = useEquipmentTableContext();

    const displayPerPage = t('equipmentDisplayStatusText.perPage', {
        count: pageSize
    });
    const displayTotalAll = t('equipmentDisplayStatusText.allPageTotal', {
        count: totalRecords
    });
    const displayTotal = t('equipmentDisplayStatusText.onePageTotal', {
        count: totalRecords
    });

    const isPaginated = totalPages > 1;
    const hasRecords = totalRecords > 0;

    return (
        <TableFooterElement
            className={ROOT_CLASS_NAME}
            data-testid={ROOT_CLASS_NAME}
            totalRecords={totalRecords}
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onClickFirstPage={goToFirstPage}
            onClickPreviousPage={goToPrevPage}
            onClickNextPage={goToNextPage}
            onClickLastPage={goToLastPage}
        >
            {hasRecords && isPaginated && (
                <span data-testid={`${ROOT_CLASS_NAME}__content-per-page`}>
                    {displayPerPage}
                </span>
            )}
            {hasRecords && isPaginated && (
                <span data-testid={`${ROOT_CLASS_NAME}__content-total-multi`}>
                    {displayTotalAll}
                </span>
            )}
            {hasRecords && !isPaginated && (
                <span data-testid={`${ROOT_CLASS_NAME}__content-total-single`}>
                    {displayTotal}
                </span>
            )}
        </TableFooterElement>
    );
};
