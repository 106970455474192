import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableFooterElementProps } from './types';
import { IconButton } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { getElementClassNames, getPaginationStats } from './utils';
import './TableFooterElement.scss';

const ROOT_CLASS_NAME = 'table-footer-element';

export const TableFooterElement = ({
    totalRecords,
    currentPage,
    totalPages,
    pageSize,
    onClickFirstPage,
    onClickPreviousPage,
    onClickNextPage,
    onClickLastPage,
    children,
    className,
    'data-testid': dataTestId
}: TableFooterElementProps) => {
    const { t } = useTranslation('liveDispatchTable');
    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;

    const {
        elementClassName,
        childClassName,
        actionsClassName,
        statusClassName,
        buttonFirstClassName,
        buttonPreviousClassName,
        buttonNextClassName,
        buttonLastClassName
    } = getElementClassNames({
        rootClassName: ROOT_CLASS_NAME,
        customClassName: className
    });

    const {
        isDisabledPrevious,
        isDisabledNext,
        startRecord,
        endRecord,
        totalRecordCount
    } = getPaginationStats({ totalRecords, currentPage, totalPages, pageSize });

    const isAvailableFirst = typeof onClickFirstPage === 'function';
    const isAvailablePrevious = typeof onClickPreviousPage === 'function';
    const isAvailableNext = typeof onClickNextPage === 'function';
    const isAvailableLast = typeof onClickLastPage === 'function';

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            <div
                className={childClassName}
                data-testid={`${elementDataTestId}__content`}
            >
                {children}
            </div>
            <div
                className={actionsClassName}
                data-testid={`${elementDataTestId}__actions`}
            >
                {isAvailableFirst && (
                    <IconButton
                        className={buttonFirstClassName}
                        data-testid={`${elementDataTestId}__button-first`}
                        icon="paginationStart"
                        iconSize={IconSizes.M}
                        iconColor={
                            isDisabledPrevious ? 'galaxy-400' : 'galaxy-800'
                        }
                        onClick={onClickFirstPage}
                        disabled={isDisabledPrevious}
                        tooltipMessage={t('pagination.goToFirst')}
                    />
                )}
                {isAvailablePrevious && (
                    <IconButton
                        className={buttonPreviousClassName}
                        data-testid={`${elementDataTestId}__button-previous`}
                        icon="paginationLeft"
                        iconSize={IconSizes.M}
                        iconColor={
                            isDisabledPrevious ? 'galaxy-400' : 'galaxy-800'
                        }
                        onClick={onClickPreviousPage}
                        disabled={isDisabledPrevious}
                        tooltipMessage={t('pagination.goToPrevious')}
                    />
                )}
                {isAvailableNext && (
                    <IconButton
                        className={buttonNextClassName}
                        data-testid={`${elementDataTestId}__button-next`}
                        icon="paginationRight"
                        iconSize={IconSizes.M}
                        iconColor={isDisabledNext ? 'galaxy-400' : 'galaxy-800'}
                        onClick={onClickNextPage}
                        disabled={isDisabledNext}
                        tooltipMessage={t('pagination.goToNext')}
                    />
                )}
                {isAvailableLast && (
                    <IconButton
                        className={buttonLastClassName}
                        data-testid={`${elementDataTestId}__button-last`}
                        icon="paginationEnd"
                        iconSize={IconSizes.M}
                        iconColor={isDisabledNext ? 'galaxy-400' : 'galaxy-800'}
                        onClick={onClickLastPage}
                        disabled={isDisabledNext}
                        tooltipMessage={t('pagination.goToLast')}
                    />
                )}
            </div>
            <div
                className={statusClassName}
                data-testid={`${elementDataTestId}__status`}
            >
                {t('pagination.statusText', {
                    start: startRecord,
                    end: endRecord,
                    total: totalRecordCount
                })}
            </div>
        </div>
    );
};
