import React from 'react';
import { useTranslation } from 'react-i18next';

import dateUtils from '~/utils/date-utils';

import './RevisedTimeStatus.scss';

const ROOT_CLASS_NAME = 'revised-time-status';

interface RevisedTimeStatusProps {
    /**
     * The revised date and time
     */
    revisedDateTime: string;
}

export const RevisedTimeStatus = ({
    revisedDateTime
}: RevisedTimeStatusProps) => {
    const { t } = useTranslation('taskManagement');

    if (!revisedDateTime) return null;

    const formattedRevisedDateTimestamp = dateUtils.convertISODateToNumericDate(
        revisedDateTime,
        t('reviseTime.dateTimeFormat')
    );

    return (
        <div className={ROOT_CLASS_NAME} data-testid={ROOT_CLASS_NAME}>
            <div className={`${ROOT_CLASS_NAME}__time-info`}>
                <span className={`${ROOT_CLASS_NAME}__time-info-label`}>
                    {t('reviseTime.labelTitle')}:
                </span>
                <span
                    className={`${ROOT_CLASS_NAME}__time-info-label info-value`}
                    data-testid={`${ROOT_CLASS_NAME}-info-value`}
                >
                    {formattedRevisedDateTimestamp}
                </span>
            </div>
        </div>
    );
};
