import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PT from 'prop-types';
import { DateTime, Interval } from 'luxon';
import ReactDatePicker from 'react-datepicker';
import { Box, Text } from 'theme-ui';
import i18n from '~/i18n';

import { setSelectedDate, selectDateOnly } from '~/reducers/selectedDateSlice';
import dateUtils from '~/utils/date-utils';
import constants from '~/utils/constants';
import { useClientSelector, useSelectedClients } from '~/hooks';

import { Button, FlexLayout, Icon, DatePickerHeader } from '~/ui';
import CircleDayButton from './CircleDayButton';
import tasksApi from '~/api/TasksApi';

import '~/ui/components/DatePicker/DatePicker.css';
import './date-select-modal.scss';

const { reactQueryKeys } = constants;

const taskIndicatorStyles = {
    default: {
        backgroundColor: 'ocean'
    },
    selected: {
        backgroundColor: 'white'
    },
    disabled: {
        backgroundColor: 'galaxy-400'
    }
};

function DateSelectModalContent({
    dates,
    hideModal,
    onClickDone,
    isDateAgnosticPage,
    addressName
}) {
    // luxon startOf('week') always starts with monday
    // decrement by a day to start on sunday
    const currentStartOfWeek = DateTime.local()
        .setLocale(i18n.language)
        .startOf('week')
        .minus({ days: 1 });
    const [startOfWeek, setStartOfWeek] = useState(currentStartOfWeek);
    const [weekDateList, setWeekDateList] = useState([]);

    // set initial selected route date from redux routeDate
    const [chosenRouteDate, setChosenRouteDate] = useState(null);
    const [datePickerSelectedDate, setDatePickerSelectedDate] =
        useState(chosenRouteDate);

    const { areClientsReady } = useSelectedClients();
    const { clientSelector, updateClientSelection, userHasMultiAccess } =
        useClientSelector();

    /**
     * The time range that will be used to inform `react-query` when to fetch new task metrics
     * information
     *
     * This should update in response to the `startOfWeek` changing or in response to the
     * datepicker changing months (tracked with `datePickerSelectedDate`)
     *
     * We use two separate useEffects to handle this
     */
    const [metricsDateTimeRange, setMetricsDateTimeRange] = useState(
        getMetricsDateTimeRange(startOfWeek)
    );

    const { data: { data: { data: tasksMetricsData = {} } = {} } = {} } =
        useQuery(
            [
                reactQueryKeys.TASKS_METRICS,
                metricsDateTimeRange[0].toFormat('yyyy-LL-dd'),
                metricsDateTimeRange[1].toFormat('yyyy-LL-dd')
            ].join('-'),
            () => {
                const [startDateISO, endDateISO] = metricsDateTimeRange.map(
                    (dateTime) => dateTime.toISO()
                );
                return tasksApi.getMetrics({
                    start_date: startDateISO,
                    end_date: endDateISO
                });
            },
            {
                // Consider date stale after 1 minute, after which we don't return from cache
                staleTime: 1000 * 60
            }
        );

    const [dateMetricsMap, setDateMetricsMap] = useState(new Map());

    const enrichedDates = weekDateList.map((date) => {
        return {
            date,
            hasTasks: !!dateMetricsMap.get(date.toFormat('yyyy-LL-dd'))?.total
        };
    });

    const selectedDate = useSelector(selectDateOnly);
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const showClientSelector = areClientsReady && userHasMultiAccess;
    const showSingleClient = areClientsReady && !userHasMultiAccess;

    useEffect(() => {
        setMetricsDateTimeRange(getMetricsDateTimeRange(startOfWeek));
    }, [startOfWeek]);

    useEffect(() => {
        if (!datePickerSelectedDate) {
            return;
        }
        setMetricsDateTimeRange(
            getMetricsDateTimeRange(datePickerSelectedDate)
        );
    }, [datePickerSelectedDate]);

    useEffect(() => {
        Object.entries(tasksMetricsData).forEach(
            ([formattedDate, dateMetrics]) => {
                dateMetricsMap.set(formattedDate, dateMetrics);
                setDateMetricsMap(new Map(dateMetricsMap));
            }
        );
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [tasksMetricsData]);

    useEffect(() => {
        const endOfWeek = startOfWeek.plus({ days: 7 });
        setWeekDateList(
            Interval.fromDateTimes(startOfWeek, endOfWeek)
                .splitBy({
                    days: 1
                })
                .map((i) => i.start)
        );
    }, [startOfWeek]);

    useEffect(() => {
        const dateTime = (
            selectedDate
                ? DateTime.fromISO(selectedDate)
                : DateTime.local().startOf('day')
        ).setLocale(i18n.language);

        setChosenRouteDate(dateTime.toJSDate());

        setStartOfWeek(dateTime.startOf('week').minus({ days: 1 }));
    }, [selectedDate]);

    function handleClick(date) {
        setChosenRouteDate(date);
    }

    function onDoneClicked() {
        if (userHasMultiAccess) {
            updateClientSelection();
        }

        // set the selected date
        const date = new Date(chosenRouteDate);
        const dateISO = date.toISOString();
        dispatch(setSelectedDate(dateISO));

        // fire a provided handler
        if (onClickDone) {
            // send back some reasonable properties to be used downstream
            onClickDone({
                selectedDateISO: dateISO,
                selectedDate: DateTime.fromISO(dateISO).toISODate(),
                datesWithRoutes: dates
            });
        }

        // finally hide the modal
        hideModal();
    }

    function isSelectedDate(date) {
        const dateTime = date.toJSDate();
        return +dateTime === +chosenRouteDate;
    }

    function isDateDisabled(date) {
        // disable if date is in the past or
        // viewing from date agnostic page
        return (
            date.startOf('day') < DateTime.local().startOf('day') ||
            isDateAgnosticPage
        );
    }

    function previousWeekClick() {
        const prevWeekStart = startOfWeek.plus({ days: -7 });
        // prevent going further than the current week
        if (prevWeekStart >= currentStartOfWeek) {
            setStartOfWeek(prevWeekStart);
        }
    }

    function nextWeekClick() {
        setStartOfWeek(startOfWeek.plus({ days: 7 }));
    }

    function onDateChange(date) {
        setChosenRouteDate(date);
        const dateTime = DateTime.fromJSDate(date).setLocale(i18n.language);

        setStartOfWeek(dateTime.startOf('week'));
    }

    function renderDayContents(day, date) {
        const dateTime = DateTime.fromJSDate(date);
        const hasTasks = dateMetricsMap.get(
            dateTime.toFormat('yyyy-LL-dd')
        )?.total;
        return (
            <CustomDatepickerDayContents
                {...{ day, date, chosenRouteDate, hasTasks }}
            />
        );
    }

    function onMonthChange(currentSelectedDate) {
        setDatePickerSelectedDate(currentSelectedDate);
    }

    return (
        <FlexLayout className="dateselectmodal-container">
            <FlexLayout
                className="dateselectmodal-header"
                data-testid="date-select-modal-header"
            >
                <FlexLayout className="week-list">
                    <Icon
                        icon="chevronDown"
                        size="s"
                        onClick={previousWeekClick}
                        className="previousweek"
                    />
                    <FlexLayout data-testid="days-container">
                        {enrichedDates.map(({ date, hasTasks }) => (
                            <CircleDayButton
                                key={date}
                                date={date}
                                isSelected={isSelectedDate(date)}
                                isDisabled={isDateDisabled(date)}
                                onButtonClick={handleClick}
                                hasTasks={hasTasks}
                            />
                        ))}
                    </FlexLayout>
                    <Icon
                        icon="chevronDown"
                        size="s"
                        onClick={nextWeekClick}
                        className="nextweek"
                        data-testid="next-week-icon"
                    />
                </FlexLayout>
                <ReactDatePicker
                    id="datepicker"
                    customInput={<CustomDatepickerInput />}
                    selected={chosenRouteDate}
                    minDate={new Date()}
                    onChange={onDateChange}
                    formatWeekDay={dateUtils.formatLetterDayOfWeek}
                    showPopperArrow={false}
                    renderCustomHeader={DatePickerHeader}
                    popperPlacement="bottom-end"
                    renderDayContents={renderDayContents}
                    onMonthChange={onMonthChange}
                    disabled={isDateAgnosticPage}
                />
            </FlexLayout>
            <FlexLayout className="dateselectmodal-content">
                {showSingleClient && (
                    <FlexLayout
                        className="station-wrapper"
                        data-testid="single-client-item"
                    >
                        <Text variant="16-medium" className="name">
                            {addressName}
                        </Text>
                        <Icon
                            icon="checked"
                            size="l"
                            color="neptune-400"
                            className="selection-icon"
                        />
                    </FlexLayout>
                )}
                {showClientSelector && clientSelector}
            </FlexLayout>
            <FlexLayout className="dateselectmodal-footer">
                <Button
                    data-testid="done-button"
                    className="action-btn"
                    onClick={onDoneClicked}
                    disabled={!chosenRouteDate}
                >
                    {t('done')}
                </Button>
            </FlexLayout>
        </FlexLayout>
    );
}

const CustomDatepickerInput = React.forwardRef(({ onClick }, ref) => {
    return (
        <Icon
            ref={ref}
            icon="calendar"
            color="galaxy-800"
            size="m"
            sx={{ alignSelf: 'center' }}
            onClick={onClick}
            data-testid="date-picker-icon"
        />
    );
});

const CustomDatepickerDayContents = ({
    day,
    date,
    chosenRouteDate,
    hasTasks
}) => {
    const luxonDate = DateTime.fromJSDate(date);
    const chosenRouteLuxonDate = DateTime.fromJSDate(chosenRouteDate);
    const dateString = luxonDate.toFormat('yyyy-LL-dd');

    const isSelected =
        dateString === chosenRouteLuxonDate.toFormat('yyyy-LL-dd');
    const taskIndicatorStyle =
        (isSelected && taskIndicatorStyles.selected) ||
        taskIndicatorStyles.default;

    return (
        <Box sx={{ position: 'relative' }}>
            <span>{day}</span>
            {hasTasks ? (
                <Box
                    sx={{
                        ...taskIndicatorStyle,
                        width: '4px',
                        height: '4px',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: '50%',
                        top: '75%',
                        transform: 'translate(-50%)'
                    }}
                />
            ) : null}
        </Box>
    );
};

DateSelectModalContent.propTypes = {
    /** Array of dates with routes */
    dates: PT.arrayOf(PT.string),
    /** Function that triggers hiding modal */
    hideModal: PT.func,
    /** The address name */
    addressName: PT.string,
    /** Function attached to the Done button's click handler */
    onClickDone: PT.func,
    /** Used to determine date agnostic page */
    isDateAgnosticPage: PT.bool
};

export default DateSelectModalContent;

/**
 * Given a base date, returns the date range where:
 * - The start date is the first day of the week of the first day of the month of the base date
 * - The end date is the last day of the week of the last day of the month of the base date
 *
 * This date range is used to fetch a date range that minimally encompasses dates that might be
 * requested by the datepicker week or month select, to minimize requests for new task metrics data
 * @param {DateTime} baseDate - The date to base the range upon
 * @returns {DateTime}
 */
function getMetricsDateTimeRange(baseDate) {
    const monthDateTimeRange = dateUtils.getMonthRange(baseDate);
    const monthStartWeekRange = dateUtils.getWeekRange(monthDateTimeRange[0]);
    const monthEndWeekRange = dateUtils.getWeekRange(monthDateTimeRange[1]);
    return [monthStartWeekRange[0], monthEndWeekRange[1]];
}
