import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AddTaskTypes, ApiTask } from '~/api/types/ApiTask';
import { useFeatureFlag } from '~/hooks';
import { EditableText, FlexLayout, useClickOutside } from '~/ui';
import DropDownMenu from '~/ui/components/DropDownMenu/DropDownMenu';
import DropDownMenuItem from '~/ui/components/DropDownMenu/DropDownMenuItem';
import constants from '~/utils/constants';
import { FeatureFlag } from '~/utils/feature-flags-utils';

import { useUpdateTask } from '../useUpdateTask';

import './ExternalTaskTypeMainContent.scss';

export const externalTaskTypeWrapperTestId =
    'addressTab_externalTaskTypeWrapperTestId';

const rootClassName = 'external-task-type-main-content';
const buttonTextClassName = `${rootClassName}__button-text`;

interface ExterTaskTypeMainContentProps {
    clientTask: ApiTask;
    id: string;
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    isDelivery?: boolean;
    isTwoPartTask?: boolean;
}

export const ExternalTaskTypeMainContent = ({
    clientTask,
    id,
    isEditing,
    setIsEditing,
    isDelivery = false,
    isTwoPartTask = false
}: ExterTaskTypeMainContentProps) => {
    const { t } = useTranslation(['taskManagement', 'common']);

    const isPredefinedExternalTaskTypeEnabled = useFeatureFlag(
        FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES
    );

    const hasEditPermissions =
        clientTask.status === constants.taskStatus.UNASSIGNED;

    const externalTaskTypeDropDownMenuRef = useRef<HTMLDivElement>(null);

    const predefinedExternalTaskTypes =
        constants.externalTaskTypePredefinedData[
            // eslint-disable-next-line
            isTwoPartTask
                ? AddTaskTypes.TWOPART
                : isDelivery
                ? AddTaskTypes.DELIVERY
                : AddTaskTypes.PICKUP
        ];

    const [
        isExternalTaskTypeDropDownMenuOpen,
        setIsExternalTaskTypeDropDownMenuOpen
    ] = useState(false);

    const toggleExternalTaskTypeDropDown = () => {
        setIsExternalTaskTypeDropDownMenuOpen((prevValue) => !prevValue);
    };

    const closeExternalTaskTypeDropDown = () => {
        setIsExternalTaskTypeDropDownMenuOpen(false);
    };

    const { updateTask: apiRequestToUpdate } = useUpdateTask({
        isTaskReadOnly: false
    });

    const updateTask = async (value: string) => {
        await apiRequestToUpdate({
            taskId: id,
            property: 'externalTaskType',
            value
        }).then(() => {
            setIsEditing(false);
        });
    };

    const onExternalTaskTypeChangeSelect = (externalTaskType: string) => {
        updateTask(externalTaskType);
    };

    const onExternalTaskTypeChangeText = ({ value }: { value: string }) => {
        updateTask(value);
    };

    const toggleEditMode = () => {
        if (hasEditPermissions) {
            setIsEditing(!isEditing);
        }
    };

    const EditableTextField = () => (
        <EditableText
            index={'externalTaskType' as unknown as number}
            mainContent={
                clientTask.externalTaskType &&
                clientTask.externalTaskType.length
                    ? clientTask.externalTaskType
                    : t('common:N/A')
            }
            handleSubmit={onExternalTaskTypeChangeText}
            isEditable={hasEditPermissions}
            data-testid="external-task-type-text"
        />
    );

    const DropdownField = () => (
        <FlexLayout
            className="address-tab-content__external-task-type"
            flexDirection="column"
            sx={{ rowGap: '1.6rem' }}
        >
            <DropDownMenu
                title={{
                    name: clientTask.externalTaskType
                }}
                placeholder={t(
                    'taskManagement:iconTooltipContent.externalTaskType'
                )}
                styles={{
                    innerContainer:
                        'dropdown-flex-space-between dropdown_outlined',
                    menuTitle: 'dropdown-selection',
                    menuTitleText: 'dropdown-title-text',
                    dropdownToggleColor: 'var(--color-galaxy-800)'
                }}
                ref={externalTaskTypeDropDownMenuRef}
                isOpen={isExternalTaskTypeDropDownMenuOpen}
                toggle={toggleExternalTaskTypeDropDown}
                close={closeExternalTaskTypeDropDown}
                icon="chevronDown"
                position="down"
                data-testid={`${externalTaskTypeWrapperTestId}-Dropdown`}
            >
                {predefinedExternalTaskTypes.map((text) => (
                    <DropDownMenuItem
                        key={text}
                        menuItem={{
                            text,
                            onClick: () => onExternalTaskTypeChangeSelect(text)
                        }}
                        data-testid={text}
                    />
                ))}
            </DropDownMenu>
        </FlexLayout>
    );

    const ButtonText = () => (
        <button
            type="button"
            className={buttonTextClassName}
            data-testid="external-task-type"
            onClick={toggleEditMode}
        >
            {clientTask.externalTaskType && clientTask.externalTaskType.length
                ? clientTask.externalTaskType
                : t('common:N/A')}
        </button>
    );

    const ref = useRef<HTMLDivElement | null>(null);

    const onHide = () => {
        setIsEditing(false);
    };

    useClickOutside(ref, onHide);

    return isEditing ? (
        <span data-testid="external-task-type-main-content-wrapper" ref={ref}>
            {isPredefinedExternalTaskTypeEnabled && <DropdownField />}
            {!isPredefinedExternalTaskTypeEnabled && <EditableTextField />}
        </span>
    ) : (
        <ButtonText />
    );
};
