import socketModule from './socket-module';

function connectToSocketServer(socketUrl, accessToken, logout) {
    socketModule.connectToServer({
        socketUrl,
        accessToken,
        logout
    });
}

function reconnectToSocketServer({ accessToken, clientIds, socketUrl }) {
    socketModule.reconnectToServer({
        accessToken,
        clientIds,
        socketUrl
    });
}

function disconnectFromServer() {
    socketModule.disconnectFromServer();
}

function leaveAllRooms() {
    socketModule.leaveAllRooms();
}

function joinRooms(clientIds) {
    socketModule.joinClientRooms({
        clientIds
    });
}

function leaveRooms(clientIds) {
    socketModule.leaveRooms({
        clientIds
    });
}

export default {
    connectToSocketServer,
    disconnectFromServer,
    reconnectToSocketServer,
    joinRooms,
    leaveRooms,
    leaveAllRooms
};
