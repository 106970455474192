import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const usersByIdSlice = createSlice({
    name: 'usersById',
    initialState: {},
    reducers: {
        setUsersById: (state, action) => {
            return action.payload;
        },
        updateUserById: (state, action) => {
            const newState = { ...state };
            newState[action.payload.id] = action.payload;
            return newState;
        },
        deleteUserById: (state, action) => {
            const newState = { ...state };
            const id = action.payload;
            if (newState[id]) {
                delete newState[action.payload];
            }
            return newState;
        },
        resetUsersById: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setUsersById, updateUserById, resetUsersById, deleteUserById } =
    usersByIdSlice.actions;

export const selectUsersById = (state) => state.usersById;

export default usersByIdSlice.reducer;
