import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { useDepots, useGetDriverList, useGetVehicles } from '~/hooks';

import { VehicleType, DriverProps } from '~/api/types';
import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';
import { idUtils } from '~/utils/id-utils';
import { XLSXUtil } from '~/data-classes/utils';

/**
 * Expected hook returns from `useDownloadDriverVehicle`
 */
type HookDownloadDriverVehicle = {
    downloadSheet: (
        downloadFilename: string,
        sheetData: DriverSheetRow[]
    ) => void;
    downloadDriverVehicles: () => void;
    downloadDriverVehiclesTemplate: () => void;
};

/**
 * Enum of predefined values for the drivers spreadsheet
 */
enum DriverSheetConstants {
    SHEETNAME = 'Drivers',
    HQ = 'hq',
    EMPTY = '',
    WORKDAY = 'x',
    DEFAULT_PASSWORD = 'default_password',
    DEFAULT_STARTTIME = '9:00',
    DEFAULT_ENDTIME = '17:00',
    DEFAULT_FIRSTNAME = 'John',
    DEFAULT_LASTNAME = 'Thompson',
    DEFAULT_PHONE = '4445556677',
    DEFAULT_ROUTEID = '1',
    DEFAULT_LICENSEPLATE = 'H2X1292',
    DEFAULT_WEIGHT = 20000,
    DEFAULT_VOLUME = 200
}

/**
 * Driver spreadsheet row structure
 */
interface DriverSheetRow {
    firstName?: string;
    lastName?: string;
    phone?: string;
    password?: string;
    startTime?: string;
    endTime?: string;
    workdays?: string;
    sun?: string;
    mon?: string;
    tues?: string;
    wed?: string;
    thur?: string;
    fri?: string;
    sat?: string;
    routeId?: string;
    licensePlate?: string;
    vehicleType?: string;
    weight?: string | number;
    volume?: string | number;
    startDepot?: string;
    endDepot?: string;
    telematicsType?: string;
    telematicsId?: string;
}

/**
 * Type subset of driver spreadsheet row containing only workday fields
 */
type DriverSheetRowWorkdays = Pick<
    DriverSheetRow,
    'sun' | 'mon' | 'tues' | 'wed' | 'thur' | 'fri' | 'sat'
>;

/**
 * Mapping of `shiftTimes` keys to marked weekdays
 */
const driverWorkdayMapping = {
    '0': { sun: DriverSheetConstants.WORKDAY },
    '1': { mon: DriverSheetConstants.WORKDAY },
    '2': { tues: DriverSheetConstants.WORKDAY },
    '3': { wed: DriverSheetConstants.WORKDAY },
    '4': { thur: DriverSheetConstants.WORKDAY },
    '5': { fri: DriverSheetConstants.WORKDAY },
    '6': { sat: DriverSheetConstants.WORKDAY }
} as Record<string, DriverSheetRowWorkdays>;

/**
 * Pre-defined drivers spreadsheet headers.
 * Do not localize as `wise-pipeline` checks specifically for these headers to process the driver/vehicle upload.
 */
const driverSheetHeader = {
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    password: 'Password',
    startTime: 'Start Time',
    endTime: 'End Time',
    workdays: 'Workdays',
    sun: 'Sun',
    mon: 'Mon',
    tues: 'Tues',
    wed: 'Wed',
    thur: 'Thur',
    fri: 'Fri',
    sat: 'Sat',
    routeId: 'Route ID',
    licensePlate: 'License Plate',
    vehicleType: 'Vehicle Type',
    weight: 'Weight',
    volume: 'Volume',
    startDepot: 'Start Depot',
    endDepot: 'End Depot',
    telematicsType: 'Telematics_Type',
    telematicsId: 'Telematics_ID'
} as DriverSheetRow;

/**
 * initial drivers spreadsheet row data
 */
const initDriverSheetRowData = {
    firstName: DriverSheetConstants.EMPTY,
    lastName: DriverSheetConstants.EMPTY,
    phone: DriverSheetConstants.EMPTY,
    password: DriverSheetConstants.EMPTY,
    startTime: DriverSheetConstants.EMPTY,
    endTime: DriverSheetConstants.EMPTY,
    workdays: DriverSheetConstants.EMPTY,
    sun: DriverSheetConstants.EMPTY,
    mon: DriverSheetConstants.EMPTY,
    tues: DriverSheetConstants.EMPTY,
    wed: DriverSheetConstants.EMPTY,
    thur: DriverSheetConstants.EMPTY,
    fri: DriverSheetConstants.EMPTY,
    sat: DriverSheetConstants.EMPTY,
    routeId: DriverSheetConstants.EMPTY,
    licensePlate: DriverSheetConstants.EMPTY,
    vehicleType: DriverSheetConstants.EMPTY,
    weight: DriverSheetConstants.EMPTY,
    volume: DriverSheetConstants.EMPTY,
    startDepot: DriverSheetConstants.EMPTY,
    endDepot: DriverSheetConstants.EMPTY,
    telematicsType: DriverSheetConstants.EMPTY,
    telematicsId: DriverSheetConstants.EMPTY
} as DriverSheetRow;

/**
 * Pre-defined drivers spreadsheet template data
 */
const driverSheetTemplateData = {
    ...initDriverSheetRowData,
    firstName: DriverSheetConstants.DEFAULT_FIRSTNAME,
    lastName: DriverSheetConstants.DEFAULT_LASTNAME,
    phone: DriverSheetConstants.DEFAULT_PHONE,
    password: DriverSheetConstants.DEFAULT_PASSWORD,
    startTime: DriverSheetConstants.DEFAULT_STARTTIME,
    endTime: DriverSheetConstants.DEFAULT_ENDTIME,
    mon: DriverSheetConstants.WORKDAY,
    tues: DriverSheetConstants.WORKDAY,
    wed: DriverSheetConstants.WORKDAY,
    thur: DriverSheetConstants.WORKDAY,
    fri: DriverSheetConstants.WORKDAY,
    routeId: DriverSheetConstants.DEFAULT_ROUTEID,
    licensePlate: DriverSheetConstants.DEFAULT_LICENSEPLATE,
    vehicleType: VehicleType.ENDLOAD,
    weight: DriverSheetConstants.DEFAULT_WEIGHT,
    volume: DriverSheetConstants.DEFAULT_VOLUME,
    startDepot: DriverSheetConstants.HQ,
    endDepot: DriverSheetConstants.HQ
} as DriverSheetRow;

export const useDownloadDriverVehicle = (): HookDownloadDriverVehicle => {
    const { t } = useTranslation('driverManagement');
    const mainClient = useSelector(selectMainClient);
    const mainClientName = mainClient?.name || constants.defaultMainClientName;
    const { driversList } = useGetDriverList();
    const { getDepotById } = useDepots();
    const { getVehicleById } = useGetVehicles();

    const driverSheetData = useMemo<DriverSheetRow[]>(() => {
        if (!driversList?.length) return [];

        return driversList
            .map((driver) => {
                const {
                    firstname,
                    lastname,
                    telephone,
                    defaultVehicle,
                    eid,
                    props
                } = driver;

                const { shiftTimes } = props as DriverProps;

                const vehicleData =
                    (defaultVehicle && getVehicleById(defaultVehicle)) || {};

                const {
                    licensePlate,
                    type,
                    volumeCapacity,
                    weightCapacity,
                    startDepot,
                    endDepot
                } = vehicleData;

                const startDepotData =
                    (startDepot && getDepotById(startDepot)) || {};
                const endDepotData = (endDepot && getDepotById(endDepot)) || {};

                const shiftsGroup = Object.keys(shiftTimes)
                    .map((key) => {
                        return {
                            shifts: shiftTimes[key],
                            workday: driverWorkdayMapping[key]
                        };
                    })
                    .reduce((group, item) => {
                        const { shifts, workday } = item;
                        const hasShift = shifts.length > 0;

                        if (hasShift) {
                            const [firstShift] = shifts;
                            const shiftTime = dateUtils.getShiftTime(
                                firstShift.start,
                                firstShift.duration
                            ) as { start: string; end: string };
                            const category = idUtils.getCombinedId(
                                shiftTime.start,
                                shiftTime.end
                            );

                            if (!group[category]) {
                                group[category] = {};
                            }

                            group[category] = {
                                ...group[category],
                                ...workday
                            };
                        }
                        return group;
                    }, {} as Record<string, DriverSheetRowWorkdays>);

                const initDriverDetails = {
                    ...initDriverSheetRowData,
                    firstName: firstname,
                    lastName: lastname,
                    phone: telephone,
                    routeId: eid,
                    licensePlate,
                    vehicleType: type,
                    weight: weightCapacity,
                    volume: volumeCapacity,
                    startDepot:
                        (!startDepotData?.hq &&
                            startDepotData?.address?.name) ||
                        DriverSheetConstants.HQ,
                    endDepot:
                        (!endDepotData?.hq && endDepotData?.address?.name) ||
                        DriverSheetConstants.HQ
                } as DriverSheetRow;

                const shiftsGroupKeys = Object.keys(shiftsGroup);

                const driverDetails =
                    shiftsGroupKeys.length > 0
                        ? shiftsGroupKeys.map((key) => {
                              const [startTime, endTime] =
                                  idUtils.splitCombinedId(key);
                              return {
                                  ...initDriverDetails,
                                  startTime,
                                  endTime,
                                  ...shiftsGroup[key]
                              };
                          })
                        : [initDriverDetails];

                return driverDetails;
            })
            .reduce((compiled, driverDetails) => {
                return [...compiled, ...driverDetails];
            }, []);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [driversList]);

    const downloadSheet = (
        downloadFilename: string,
        sheetData: DriverSheetRow[]
    ) => {
        const completeDriverSheetData = [
            { ...driverSheetHeader },
            ...sheetData
        ];

        const wb = new XLSXUtil();
        wb.appendSheet(
            completeDriverSheetData,
            DriverSheetConstants.SHEETNAME,
            {
                skipHeader: true
            }
        );
        wb.save(downloadFilename);
    };

    const downloadDriverVehicles = () => {
        const clientName = mainClientName.toUpperCase().replaceAll(' ', '_');
        const currentDate = DateTime.now();
        const downloadFilename = t('driverVehicleExport.filename', {
            clientName,
            date: currentDate.toISODate()
        });
        downloadSheet(downloadFilename, driverSheetData);
    };

    const downloadDriverVehiclesTemplate = () => {
        const clientName = mainClientName.toUpperCase().replaceAll(' ', '_');
        const downloadFilename = t('driverVehicleExport.template', {
            clientName
        });
        downloadSheet(downloadFilename, [driverSheetTemplateData]);
    };

    return {
        downloadSheet,
        downloadDriverVehicles,
        downloadDriverVehiclesTemplate
    };
};
