import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LiveDispatchTaskTableRowData } from '~/data-classes';
import { TableHeaderProps, LiveDispatchTableColumnId } from '../../types';
import { useLiveDispatchTableStates } from '../../useLiveDispatchTableStates';
import { TableSortElement } from '../../TableSortElement/TableSortElement';
import { TableFilterElement } from '../../TableFilterElement';

const ROOT_CLASS_NAME = 'live-dispatch-table-header';

interface CheckboxHandler {
    isChecked: boolean;
    value?: string | number | unknown;
    selectedValues?: Array<string | number | unknown>;
}

export const LiveDispatchTableHeaderTaskType = ({
    header,
    children,
    className,
    ...extra
}: TableHeaderProps<LiveDispatchTaskTableRowData>) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const { id } = header || {};
    const headerText = id && t(`columns.taskTable.${id}`);
    const defaultTestId = id ? `${ROOT_CLASS_NAME}__${id}` : ROOT_CLASS_NAME;
    const dataTestId = extra['data-testid'] || defaultTestId;
    const elementClassName = classNames(ROOT_CLASS_NAME, className, {
        [`${ROOT_CLASS_NAME}__${id}`]: id
    });

    const [selectedTaskTypeFilter, setSelectedTaskTypeFilter] = useState<
        string[]
    >([]);
    const { columnFilters, setColumnFilters, onApplySorting, onClearSorting } =
        useLiveDispatchTableStates();

    useEffect(() => {
        if (columnFilters.length) {
            const currentColumnData = columnFilters.filter(
                (data) => data.id === id
            );
            if (currentColumnData.length) {
                setSelectedTaskTypeFilter(
                    currentColumnData[0].value as string[]
                );
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [columnFilters]);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const facetedUniqueValues = header?.column.getFacetedUniqueValues() || [];
    const taskTypeFilterList = useMemo(() => {
        const typeCounts: { name: string; numberOfEntries: number }[] = [];

        for (const entry of facetedUniqueValues.entries()) {
            typeCounts.push({ name: entry[0], numberOfEntries: entry[1] });
        }

        typeCounts.sort((a, b) => a.name.localeCompare(b.name)); // Sort by "name" in alphabetical order

        return typeCounts;
    }, [facetedUniqueValues]);

    const handleTaskTypeFilterCheckboxClick = ({
        isChecked,
        value
    }: CheckboxHandler) => {
        if (isChecked) {
            setSelectedTaskTypeFilter([
                ...selectedTaskTypeFilter,
                value as string
            ]);
        } else {
            const removedEntryList = selectedTaskTypeFilter.filter(
                (data) => data !== value
            );
            setSelectedTaskTypeFilter([...removedEntryList]);
        }
    };

    const onClearStatusFilter = () => {
        setSelectedTaskTypeFilter([]);
        const updatedColumnFilter = columnFilters.filter(
            (data) => data.id !== LiveDispatchTableColumnId.TASK_TYPE
        );
        setColumnFilters([...updatedColumnFilter]);
    };

    const onApplyStatusFilter = () => {
        if (selectedTaskTypeFilter.length) {
            const filteredFromTaskType = columnFilters.filter(
                (data) => data.id !== LiveDispatchTableColumnId.TASK_TYPE
            );
            setColumnFilters([
                ...filteredFromTaskType,
                {
                    id: LiveDispatchTableColumnId.TASK_TYPE,
                    value: selectedTaskTypeFilter
                }
            ]);
        } else {
            onClearStatusFilter();
        }
    };

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            {header?.column.columnDef.enableSorting && id && (
                <TableSortElement
                    columnId={id}
                    onClearSorting={onClearSorting}
                    onApplySorting={onApplySorting}
                />
            )}
            {header?.column.columnDef.enableColumnFilter && (
                <TableFilterElement
                    headerText={headerText}
                    headerId={id || ''}
                    columnFilters={columnFilters}
                    filterList={taskTypeFilterList}
                    selectedFilterList={selectedTaskTypeFilter}
                    handleFilterCheckboxClick={
                        handleTaskTypeFilterCheckboxClick
                    }
                    onClearStatusFilter={onClearStatusFilter}
                    onApplyStatusFilter={onApplyStatusFilter}
                />
            )}
            {children}
            <span data-testid={`${dataTestId}-text`}>{headerText}</span>
        </div>
    );
};
