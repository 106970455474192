import React, { useCallback, useState } from 'react';
import { ValidatedInput } from '~/components/ValidatedInput';
import { floatValidator } from '~/hooks/useControlledInput';
import { Alert, Button, Collapsible, LabelInput, RadioGroup } from '~/ui';
import GooglePlacesAutocomplete from '../GooglePlacesAutocomplete';
import CustomerDatabaseAutocomplete from '../CustomerDatabaseAutocomplete';
import CustomerMap from '~/components/CustomerManagementPage/CustomerMap';
import { MapContextProvider } from '~/components/ZoneManagementPage/MapContext';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import {
    AddressTaskTypes,
    TaskTypes,
    InputLocationMethods
} from '~/api/types/ApiTask';
import { RadioInputData } from '~/ui/components/RadioGroup/RadioGroup';
import { useAddTaskModalContext } from '../AddTaskModalContext';
import { useTranslation } from 'react-i18next';
import {
    AddressInputFields,
    clientTaskRequiredFieldMap
} from '../utils/addTaskModalUtils';
import localizationUtils from '~/utils/localization-utils';
import { useHereMaps } from '~/hooks';
import {
    getTaskSpecificLocationFromClientTask,
    getTaskSpecificLocationFromPlace
} from '../utils/locationUtils';

import '../AddressTabContent/AddressTabContent.scss';

export const inputLocationRadioGroupTestId =
    'addressTab_inputLocationRadioGroupTestId';
export const inputLocationRadioTestId = 'addressTab_inputLocationRadioTestId';
export const viewMapButtonTestId = 'address-tab-content__map_button';
export const viewMapTestId = 'address-tab-content__map';
export const addressAptWrapperTestId = 'addressTab_addressAptWrapperTestId';
export const addressCityWrapperTestId = 'addressTab_addressCityWrapperTestId';
export const addressStateWrapperTestId = 'addressTab_addressStateWrapperTestId';
export const addressStreetWrapperTestId =
    'addressTab_addressStreetWrapperTestId';
export const addressZipCodeWrapperTestId =
    'addressTab_addressZipCodeWrapperTestId';
export const customerIdWrapperTestId = 'addressTab_customerIdWrapperTestId';
export const customerNameWrapperTestId = 'addressTab_customerNameWrapperTestId';
export const latitudeWrapperTestId = 'addressTab_latitudeWrapperTestId';
export const longitudeWrapperTestId = 'addressTab_longitudeWrapperTestId';

interface AddressInputProps {
    onLongitudeChange: (value: string) => void;
    onLatitudeChange: (value: string) => void;
    taskType: AddressTaskTypes;
}

export const AddressInput = ({
    onLongitudeChange,
    onLatitudeChange,
    taskType
}: AddressInputProps) => {
    const { t } = useTranslation(['addTask']);
    const { isHereMapsEnabled } = useHereMaps();

    const {
        clientTask,
        setClientTask,
        fillInAddress,
        clearAddress,
        onInputChange
    } = useAddTaskModalContext();

    const [viewMap, setViewMap] = useState(false);
    const [inputLocationMethod, setInputLocationMethod] = useState(
        InputLocationMethods.CUSTOMER_DB
    );
    const [initialTaskLocation, setInitialTaskLocation] = useState<{
        lng: undefined | number;
        lat: undefined | number;
    }>({ lng: undefined, lat: undefined });
    const [parkingLocations] = useState([]);

    const inputLocationRadioData: RadioInputData<InputLocationMethods>[] = [
        {
            id: `${taskType}_${InputLocationMethods.CUSTOMER_DB}`,
            label: t('inputLocation.customerDatabase'),
            value: InputLocationMethods.CUSTOMER_DB
        },
        ...(!isHereMapsEnabled
            ? [
                  {
                      id: `${taskType}_${InputLocationMethods.GOOGLE}`,
                      label: t('inputLocation.google'),
                      value: InputLocationMethods.GOOGLE
                  }
              ]
            : []),
        {
            id: `${taskType}_${InputLocationMethods.MANUAL}`,
            label: t('inputLocation.manual'),
            value: InputLocationMethods.MANUAL
        }
    ];

    const isTwoPartTask = clientTask.taskType === TaskTypes.TWOPART;
    const inputLocationLabel = isTwoPartTask
        ? t(`fields.taskType.fields.${taskType}.label`)
        : '';

    const viewMapButtonEnabled =
        Boolean(clientTask[`${taskType}Longitude`]) &&
        Boolean(clientTask[`${taskType}Latitude`]);

    const onAddressAptChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange(`${taskType}AddressApt`, e);
        },
        [onInputChange, taskType]
    );

    const onAddressCityChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange(`${taskType}AddressCity`, e);
        },
        [onInputChange, taskType]
    );

    const onAddressStateChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange(`${taskType}AddressState`, e);
        },
        [onInputChange, taskType]
    );

    const onAddressZipCodeChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange(`${taskType}AddressZipCode`, e);
        },
        [onInputChange, taskType]
    );

    const onAddressStreetChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange(`${taskType}AddressStreet`, e);
        },
        [onInputChange, taskType]
    );

    const onCustomerNameChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange(`${taskType}CustomerName`, e);
        },
        [onInputChange, taskType]
    );

    const onCustomerIdChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange(`${taskType}Euid`, e);
        },
        [onInputChange, taskType]
    );

    const englishFormatAddressInputs = [
        <LabelInput
            key="address-line-1"
            className="address-tab-content__address-line-1 _mt-4"
            label={t('fields.addressStreet.label')}
            placeholder={t('fields.addressStreet.placeholder')}
            value={clientTask[`${taskType}AddressStreet`] || ''}
            onChange={onAddressStreetChange}
            required={
                clientTaskRequiredFieldMap(taskType)[`${taskType}AddressStreet`]
            }
            data-testid={addressStreetWrapperTestId}
        />,
        <LabelInput
            key="address-line-2"
            className="address-tab-content__address-line-2"
            placeholder={t('fields.addressApt.placeholder')}
            value={clientTask[`${taskType}AddressApt`] || ''}
            onChange={onAddressAptChange}
            required={
                clientTaskRequiredFieldMap(taskType)[`${taskType}AddressApt`]
            }
            data-testid={addressAptWrapperTestId}
        />,
        <LabelInput
            key="city"
            className="address-tab-content__city _mt-4"
            label={t('fields.city.label')}
            value={clientTask[`${taskType}AddressCity`] || ''}
            onChange={onAddressCityChange}
            required={
                clientTaskRequiredFieldMap(taskType)[`${taskType}AddressCity`]
            }
            data-testid={addressCityWrapperTestId}
        />,
        <LabelInput
            key="state"
            className="address-tab-content__state _mt-4"
            label={t('fields.state.label')}
            value={clientTask[`${taskType}AddressState`] || ''}
            onChange={onAddressStateChange}
            required={
                clientTaskRequiredFieldMap(taskType)[`${taskType}AddressState`]
            }
            data-testid={addressStateWrapperTestId}
        />,
        <LabelInput
            key="zipcode"
            className="address-tab-content__zipcode _mt-4"
            label={t('fields.zipCode.label')}
            value={clientTask[`${taskType}AddressZipCode`] || ''}
            onChange={onAddressZipCodeChange}
            required={
                clientTaskRequiredFieldMap(taskType)[
                    `${taskType}AddressZipCode`
                ]
            }
            data-testid={addressZipCodeWrapperTestId}
        />
    ];

    function onRadioInputChange(value: InputLocationMethods) {
        clearAddress(taskType);
        setInputLocationMethod(value);
    }

    function handleViewMap() {
        setViewMap(!viewMap);
    }

    function handleUpdateCustomerLocation(latitude: string, longitude: string) {
        setClientTask({
            ...clientTask,
            [`${taskType}Latitude`]: latitude,
            [`${taskType}Longitude`]: longitude
        });
    }

    const updateAddress = useCallback(
        (place: AddressInputFields) => {
            const { latitude, longitude, currentTaskType } = place;

            const lng = Number(longitude);
            const lat = Number(latitude);
            setInitialTaskLocation({
                lng,
                lat
            });
            setViewMap(false);

            const currentSelectedTaskType = (currentTaskType ||
                taskType) as AddressTaskTypes;
            const otherTaskType =
                currentSelectedTaskType === AddressTaskTypes.DELIVERY
                    ? AddressTaskTypes.PICKUP
                    : AddressTaskTypes.DELIVERY;

            let location = getTaskSpecificLocationFromPlace(
                currentSelectedTaskType,
                place
            );

            if (isTwoPartTask) {
                location = {
                    ...location,
                    ...getTaskSpecificLocationFromClientTask(
                        otherTaskType,
                        clientTask
                    )
                };
            }

            fillInAddress(location, currentSelectedTaskType);
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [clientTask]
    );

    const getAddressForm = localizationUtils.formatAddressInputsByLocale(
        englishFormatAddressInputs
    );

    return (
        <>
            <div className="address-tab-content__divider" />
            <RadioGroup
                radioData={inputLocationRadioData}
                onChange={onRadioInputChange}
                value={inputLocationMethod}
                name={`${taskType}InputLocation`}
                label={t('inputLocation.label', {
                    taskType: inputLocationLabel
                })}
                required
                className="address-tab-content__location-radio-group-wrapper _w-100"
                radioInputGroupClassName="address-tab-content__radio-group"
                radioInputClassName="address-tab-content__radio-input"
                data-testid={inputLocationRadioGroupTestId}
                radio-testid={inputLocationRadioTestId}
            />
            {inputLocationMethod === InputLocationMethods.GOOGLE && (
                <GooglePlacesAutocomplete
                    placeholder={t('inputLocation.searchPlaceholder')}
                    onSelect={updateAddress}
                    taskType={taskType}
                />
            )}
            {inputLocationMethod === InputLocationMethods.CUSTOMER_DB && (
                <CustomerDatabaseAutocomplete
                    placeholder={t(
                        'customerManagement:customerSearch.searchTitle'
                    )}
                    onSelect={updateAddress}
                    taskType={taskType}
                />
            )}
            <LabelInput
                id={`${taskType}-CustomerName`}
                className="address-tab-content__customer-name _mt-4"
                label={t('fields.customerName.label')}
                placeholder={t('fields.customerName.placeholder')}
                value={clientTask[`${taskType}CustomerName`] || ''}
                onChange={onCustomerNameChange}
                required={
                    clientTaskRequiredFieldMap(taskType)[
                        `${taskType}CustomerName`
                    ]
                }
                data-testid={customerNameWrapperTestId}
            />
            <LabelInput
                className="address-tab-content__customer-id _mt-4"
                label={t('fields.customerId.label')}
                placeholder={t('fields.customerId.placeholder')}
                value={clientTask[`${taskType}Euid`] || ''}
                onChange={onCustomerIdChange}
                data-testid={customerIdWrapperTestId}
            />
            {getAddressForm}
            <Collapsible
                className="address-tab-content__collapsible"
                textClose={t('coordinateCollapsible.textClose')}
                textOpen={t('coordinateCollapsible.textOpen')}
                iconClose="chevronDown"
                iconOpen="chevronUp"
                iconColor="meteor"
                iconSize={IconSizes.M}
                iconPosition={Collapsible.iconPosition.LEFT}
                initOpen={Boolean(
                    clientTask[`${taskType}Latitude`] &&
                        clientTask[`${taskType}Longitude`]
                )}
            >
                <Alert
                    variant="info"
                    icon="infoLine"
                    iconSize="m"
                    iconColor="meteor"
                    className="_ai-center"
                >
                    {t('coordinateCollapsible.alertText')}
                </Alert>
                <div className="address-tab-content__coordinates _jc-space-between">
                    <ValidatedInput
                        inputComponent={LabelInput}
                        validator={floatValidator}
                        label={t('fields.latitude.label')}
                        value={clientTask[`${taskType}Latitude`] || ''}
                        onValueChange={onLatitudeChange}
                        required={
                            clientTaskRequiredFieldMap(taskType)[
                                `${taskType}Latitude`
                            ]
                        }
                        data-testid={latitudeWrapperTestId}
                    />
                    <ValidatedInput
                        inputComponent={LabelInput}
                        validator={floatValidator}
                        label={t('fields.longitude.label')}
                        value={clientTask[`${taskType}Longitude`] || ''}
                        onValueChange={onLongitudeChange}
                        required={
                            clientTaskRequiredFieldMap(taskType)[
                                `${taskType}Longitude`
                            ]
                        }
                        data-testid={longitudeWrapperTestId}
                    />
                </div>
            </Collapsible>
            {!isHereMapsEnabled && (
                <Button
                    className="address-tab-content__map-button"
                    data-testid={viewMapButtonTestId}
                    variant="secondary"
                    disabled={!viewMapButtonEnabled}
                    onClick={handleViewMap}
                >
                    {t('viewMap.visualizeMap')}
                </Button>
            )}
            {!isHereMapsEnabled && viewMap && (
                <div
                    className="address-tab-content__map"
                    data-testid={viewMapTestId}
                >
                    <MapContextProvider>
                        <CustomerMap
                            onUpdateCustomerLocation={(
                                lat: string,
                                lng: string
                            ) => handleUpdateCustomerLocation(lat, lng)}
                            customerLocation={initialTaskLocation}
                            parkingLocations={parkingLocations}
                            isGeocodeEditable
                            geofenceCircleRadius={null}
                            geofencePolygon={null}
                            isDisableCircularGeofences
                        />
                    </MapContextProvider>
                </div>
            )}
        </>
    );
};
