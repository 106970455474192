import { store } from '~/store';

import {
    resetMapState,
    setMapMarkerMode,
    setShouldFitPlanMapToBounds
} from '~/reducers/mapSettingsSlice';

import { resetDataLoading } from '~/reducers/dataLoadingSlice';
import { resetDetectWebSolution } from '~/reducers/detectWebSolutionSlice';
import { resetDriverBreaksModal } from '~/reducers/driverBreaksModalSlice';
import { resetDriversLocations } from '~/reducers/driversLocationsSlice';
import { resetEditHistoryByClient } from '~/reducers/editHistoryByClientSlice';
import { resetEditHistoryMetrics } from '~/reducers/editHistoryMetricsSlice';
import { resetFilteredOutRoutes } from '~/reducers/filteredOutRoutesSlice';
import { resetHiddenRoutes } from '~/reducers/hiddenRoutesSlice';
import { resetLiveDrivers } from '~/reducers/liveDriversSlice';
import { resetMapDrawerSettings } from '~/reducers/mapDrawerSettingsSlice';
import { resetOnDemandDispatchTaskCount } from '~/reducers/onDemandDispatchTaskCountSlice';
import { resetPlanClientsLevelData } from '~/reducers/planClientsLevelDataSlice';
import { resetPlanRoutesLevelData } from '~/reducers/planRoutesLevelDataSlice';
import { resetPlanStopsLevelData } from '~/reducers/planStopsLevelDataSlice';
import { resetRoutesDrawerFilter } from '~/reducers/routesDrawerFilterSlice';
import { resetRoutesDrawerSort } from '~/reducers/routesDrawerSortSlice';
import { resetRouteWebColors } from '~/reducers/webColorsSlice';
import { resetSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { resetSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { resetSelectedDriverVehicleRowId } from '~/reducers/selectedDriverVehicleRowIdSlice';
import { resetSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { resetSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';

import constants from '~/utils/constants';

function resetSelectedDrawerCard() {
    store.dispatch(resetSelectedDrawerCardData());
    store.dispatch(resetSelectedDrawerCardId());
}

function resetRouteAndStopSelections() {
    resetSelectedDrawerCard();
    store.dispatch(resetFilteredOutRoutes());
    store.dispatch(resetHiddenRoutes());
    store.dispatch(resetSelectedMapRoutes());
    store.dispatch(resetSelectedMapStops());
}

function resetMap() {
    store.dispatch(resetMapDrawerSettings());
    store.dispatch(resetMapState());
}

function resetDriverBreaks() {
    store.dispatch(resetDriverBreaksModal());
}

function resetPlanData() {
    store.dispatch(resetPlanClientsLevelData());
    store.dispatch(resetPlanRoutesLevelData());
    store.dispatch(resetPlanStopsLevelData());
    store.dispatch(resetRouteWebColors());
    store.dispatch(resetDetectWebSolution());
    store.dispatch(resetDataLoading());
}

function resetEditHistory() {
    store.dispatch(resetEditHistoryByClient());
    store.dispatch(resetEditHistoryMetrics());
}

function resetPlanPageReducers() {
    resetRouteAndStopSelections();
    resetPlanData();
    resetMap();
    resetDriverBreaks();
    resetEditHistory();
    store.dispatch(resetRoutesDrawerFilter());
    store.dispatch(resetRoutesDrawerSort());
    store.dispatch(resetLiveDrivers());
    store.dispatch(resetDriversLocations());
    store.dispatch(resetOnDemandDispatchTaskCount());
    store.dispatch(resetSelectedEquipmentIds());
    store.dispatch(resetSelectedTaskIds());
    store.dispatch(resetSelectedTaskRowId());
    store.dispatch(resetSelectedDriverVehicleRowId());
}

function resetRouteSelection() {
    resetSelectedDrawerCard();
    store.dispatch(resetPlanStopsLevelData());
    store.dispatch(resetMapDrawerSettings());
    store.dispatch(setShouldFitPlanMapToBounds(true));
    store.dispatch(setMapMarkerMode(constants.mapMarkerModes.ROUTES));
}

function resetStateBeforeSchedulerRun() {
    resetRouteAndStopSelections();
    resetMap();
    resetDriverBreaks();
    store.dispatch(setMapMarkerMode(constants.mapMarkerModes.ROUTES));
    store.dispatch(resetDetectWebSolution());
}

export const pageUtils = {
    resetPlanPageReducers,
    resetRouteSelection,
    resetSelectedDrawerCard,
    resetStateBeforeSchedulerRun
};
