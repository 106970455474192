import _ from 'lodash';
import { Duration } from 'luxon';

import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';
import localizationUtils from '~/utils/localization-utils';
import generalUtils from './general-utils';
import i18n from '~/i18n';

/**
 * Task Utilities
 *
 * @category Utils
 * @module utils/taskUtils
 *
 * @example
 * import taskUtils from '~/utils/task-utils';
 */

const noContent = i18n.t('N/A', { ns: 'common' });
const dynamicTaskEuId = i18n.t('dynamic', { ns: 'common' });

function formatAddress(address) {
    return localizationUtils.formatAddress(address);
}

function formatServiceTime(serviceTime) {
    return generalUtils.roundToMaxDigitsAfterDecimal(
        Duration.fromISO(serviceTime).shiftTo('minutes').minutes,
        2
    );
}

function formatTimeWindow(timeWindow) {
    return (timeWindow || []).map((tw) => {
        const formattedTimeWindow = dateUtils.getTimeWindow(tw.start, tw.end);
        return formattedTimeWindow;
    });
}

function formatTimeWindowWithDate(timeWindow) {
    return (timeWindow || []).map((tw) => {
        const formattedTimeWindow = dateUtils.getTimeWindow(tw.start, tw.end);
        const date = dateUtils.convertISODateToJsDate(tw.start);
        return `${formattedTimeWindow}, ${dateUtils.formatMonthDay(date)}`;
    });
}

function formatTimestamp(timestamp) {
    if (!timestamp) return null;
    const date = dateUtils.convertISODateToJsDate(timestamp);
    return `${dateUtils.formatMonthDay(date)} ${dateUtils.getLocalizedTime(
        timestamp
    )}`;
}

function formatTaskName(task) {
    if (checkIsTwoPartTask(task))
        return `${task.pickupLocation.name} - ${task.deliveryLocation.name}`;
    if (checkIsDeliveryTask(task)) return task.deliveryLocation.name;
    if (checkIsPickupTask(task)) return task.pickupLocation.name;
}
function formatTaskAddress(task) {
    if (checkIsTwoPartTask(task)) {
        const { pickupLocation, deliveryLocation } = task;
        return `${formatAddress(pickupLocation)} - ${formatAddress(
            deliveryLocation
        )}`;
    }
    if (checkIsDeliveryTask(task)) return formatAddress(task.deliveryLocation);
    if (checkIsPickupTask(task)) return formatAddress(task.pickupLocation);
}

function getEuid(euid) {
    return euid || dynamicTaskEuId;
}

function getTaskLocationKey(type) {
    return type === constants.taskTypes.PICKUP
        ? constants.taskTypes.PICKUP_LOCATION
        : constants.taskTypes.DELIVERY_LOCATION;
}
function getTaskInventoryKey(type) {
    return type?.toLowerCase() === constants.taskTypes.PICKUP
        ? constants.taskTypes.PICKUP_INVENTORY
        : constants.taskTypes.DELIVERY_INVENTORY;
}

/**
 * Check if task is depot
 *
 * @param task {Object}
 * @returns {boolean}
 */
function checkIsDepot(task) {
    return task.props.type === constants.taskTypes.DEPOT;
}

function checkIsTwoPartTask(task) {
    return Boolean(
        task[constants.taskTypes.PICKUP_LOCATION] &&
            task[constants.taskTypes.DELIVERY_LOCATION]
    );
}

function checkIsDeliveryTask(task) {
    return Boolean(task[constants.taskTypes.DELIVERY_LOCATION]);
}

function checkIsPickupTask(task) {
    return Boolean(task[constants.taskTypes.PICKUP_LOCATION]);
}

function filterDeliveryTask(task, inventoryItems = []) {
    const { props } = task;
    return {
        taskName: formatTaskName(task),
        taskAddress: formatTaskAddress(task),
        customerName: task.deliveryLocation.name,
        contactPhone: task.deliveryContact?.phone
            ? task.deliveryContact.phone
            : noContent,
        contactEmail: task.deliveryContact?.email
            ? task.deliveryContact.email
            : noContent,
        customerAddress: formatAddress(task.deliveryLocation),
        externalTaskType: task.externalTaskType,
        locationLat: task.deliveryLocation.location.lat,
        locationLng: task.deliveryLocation.location.lng,
        timeWindows: formatTimeWindowWithDate(props.deliveryWindow),
        timeWindowsWithoutDate: formatTimeWindow(props.deliveryWindow),
        labels: task.labels ?? [],
        notes: props.notes?.length ? props.notes : [{ text: noContent }],
        externalLinks: props.externalLinks ? props.externalLinks : [],
        status: task.taskStatus,
        inventoryItems: inventoryItems.filter(
            (item) => item.type === constants.taskTypes.DELIVERY
        ),
        createdAt: task.createdAt,
        updatedAt: task.updatedAt,
        routeId: task.routeId ? task.routeId : '',
        serviceTime: formatServiceTime(props.deliveryServiceTime),
        priority: props.priority,
        weight: props?.weight || noContent,
        size: props?.size || noContent,
        sizeByCompartment: task.sizeByCompartment,
        euId: getEuid(task?.euid),
        invoicesAndPayments: props.deliveryInvoices ?? [],
        equipmentId: task?.equipmentId || noContent
    };
}

function filterPickupTask(task, inventoryItems = []) {
    const { props } = task;

    return {
        taskName: formatTaskName(task),
        taskAddress: formatTaskAddress(task),
        customerName: task.pickupLocation.name,
        contactPhone: task.pickupContact?.phone
            ? task.pickupContact.phone
            : noContent,
        contactEmail: task.pickupContact?.email
            ? task.pickupContact.email
            : noContent,
        customerAddress: formatAddress(task.pickupLocation),
        externalTaskType: task.externalTaskType,
        locationLat: task.pickupLocation.location.lat,
        locationLng: task.pickupLocation.location.lng,
        externalLinks: props.externalLinks ? props.externalLinks : [],
        timeWindows: formatTimeWindowWithDate(props.pickupWindow),
        timeWindowsWithoutDate: formatTimeWindow(props.pickupWindow),
        labels: task.labels ?? [],
        notes: props.notes?.length ? props.notes : [{ text: noContent }],
        status: task.taskStatus,
        inventoryItems: inventoryItems.filter(
            (item) => item.type === constants.taskTypes.PICKUP
        ),
        createdAt: task.createdAt,
        updatedAt: task.updatedAt,
        routeId: task.routeId ? task.routeId : '',
        serviceTime: formatServiceTime(props.pickupServiceTime),
        priority: props.priority,
        weight: props?.weight || noContent,
        size: props?.size || noContent,
        sizeByCompartment: task.sizeByCompartment,
        euId: getEuid(task?.euid),
        invoicesAndPayments: props.pickupInvoices ?? [],
        equipmentId: task?.equipmentId || noContent
    };
}
/**
 * Task metrics without depots included
 *
 * @param {Array} depotTasks
 * @param {Object} taskMetrics
 * @returns {Object} task metrics
 */
function removeDepotsFromTaskMetrics(depotTasks, taskMetrics) {
    for (const depot of depotTasks) {
        taskMetrics.total--;
        switch (depot.status) {
            case constants.taskStatus.UNASSIGNED:
                if (depot.routeId) {
                    taskMetrics.planned--;
                } else {
                    taskMetrics.unassigned--;
                }
                break;
            case constants.taskStatus.DISPATCHED:
            case constants.taskStatus.IN_PROGRESS:
                taskMetrics.dispatched--;
                break;
            case constants.taskStatus.COMPLETED:
                taskMetrics.completed--;
                break;
            case constants.taskStatus.CANCELLED:
                taskMetrics.canceled--;
                break;
            default:
        }
    }
    return taskMetrics;
}

/**
 * Checks task is planned status
 *
 * @param {Object} task
 * @returns {boolean}
 */
function isPlannedTask(task) {
    return (
        task.status === constants.taskStatus.UNASSIGNED && Boolean(task.routeId)
    );
}

/**
 * Checks unassigned status is in array
 *
 * @param {Array} statusArray
 * @returns {boolean}
 */
function includesUnassignedStatus(statusArray) {
    return statusArray.includes(constants.taskStatus.UNASSIGNED);
}

/**
 * Checks planned status is in array
 *
 * @param {Array} statusArray
 * @returns {boolean}
 */
function includesPlannedStatus(statusArray) {
    return statusArray.includes(constants.taskStatus.PLANNED);
}

/**
 * Converts web status to API status
 *
 * @param {Array} selectedStatusFilters
 * @returns {any[]|number[]}
 */
function getApiStatusFromWebStatus(selectedStatusFilters) {
    const { taskStatus } = constants;
    if (_.isEmpty(selectedStatusFilters)) {
        return [
            taskStatus.UNASSIGNED,
            taskStatus.DISPATCHED,
            taskStatus.IN_PROGRESS,
            taskStatus.COMPLETED,
            taskStatus.CANCELLED
        ];
    }

    const webStatusToApiStatus = {
        [taskStatus.UNASSIGNED]: taskStatus.UNASSIGNED,
        [taskStatus.PLANNED]: taskStatus.UNASSIGNED,
        [taskStatus.DISPATCHED]: taskStatus.DISPATCHED,
        [taskStatus.COMPLETED]: taskStatus.COMPLETED,
        [taskStatus.CANCELLED]: taskStatus.CANCELLED
    };
    const updatedTaskStatus = new Set(
        selectedStatusFilters.map((status) => webStatusToApiStatus[status])
    );
    return Array.from(updatedTaskStatus);
}

/**
 * Get task status, including planned status
 *
 * @param {Object} task
 * @returns {number}
 */
function getTaskStatus(task) {
    if (isPlannedTask(task)) {
        return constants.taskStatus.PLANNED;
    }
    return task.status;
}

/**
 * Format API tasks to web tasks by adding taskStatus property
 *
 * @param {Object[]} tasks
 * @returns {Object[]}
 */
function formatApiTasksToWebTasks(tasks) {
    return tasks.map((task) => {
        const taskStatus = getTaskStatus(task);
        return { ...task, taskStatus };
    });
}

/**
 * Filter tasks by taskStatus
 *
 * @param {Object[]} tasks
 * @param {Array} selectedStatusFilters
 * @returns {Object[]}
 */
function filterTasksByTaskStatus(tasks, selectedStatusFilters) {
    if (_.isEmpty(selectedStatusFilters)) {
        return tasks;
    }

    return tasks.filter((task) => {
        return selectedStatusFilters.includes(task.taskStatus);
    });
}

export default {
    getEuid,
    getTaskLocationKey,
    getTaskInventoryKey,
    checkIsDepot,
    checkIsTwoPartTask,
    checkIsPickupTask,
    checkIsDeliveryTask,
    formatTaskName,
    filterDeliveryTask,
    filterPickupTask,
    getTaskStatus,
    removeDepotsFromTaskMetrics,
    isPlannedTask,
    includesUnassignedStatus,
    includesPlannedStatus,
    getApiStatusFromWebStatus,
    formatApiTasksToWebTasks,
    filterTasksByTaskStatus,
    formatServiceTime,
    formatTimestamp,
    formatTimeWindow,
    formatTimeWindowWithDate
};
