import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RouteCardText } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import dateUtils from '~/utils/date-utils';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

import { useFeatureFlag } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';

interface RouteCardEtaProps {
    stopData: {
        eta?: string;
        arrivalTime?: string;
        completedAt?: string;
        serviceWindow: {
            start: string;
            end: string;
        };
        isUnassigned?: boolean;
        isCompleted?: boolean;
        isInProgress?: boolean;
        isCanceled?: boolean;
    };
    icon?: IconName;
    iconColor?: string;
    className?: string;
    'data-testid'?: string;
}

function RouteCardEta({
    stopData,
    icon,
    iconColor,
    className,
    ...extra
}: RouteCardEtaProps) {
    const { t } = useTranslation('common');

    const isClientTimezoneFlagEnabled = useFeatureFlag(
        FeatureFlag.CLIENT_TIMEZONE
    );
    const timeWindow = useMemo(() => {
        if (!stopData || stopData.isCanceled) {
            return '';
        }

        const {
            eta,
            arrivalTime,
            completedAt,
            serviceWindow,
            isUnassigned,
            isCompleted,
            isInProgress
        } = stopData;

        const parsedServiceWindow = {
            start: dateUtils.getLocalizedTime(serviceWindow?.start),
            end: dateUtils.getLocalizedTime(serviceWindow?.end)
        };

        if (isCompleted && completedAt) {
            return dateUtils.getLocalizedTime(completedAt);
        }

        if (isUnassigned && serviceWindow) {
            return t('time.timeWindow', parsedServiceWindow);
        }

        if (isInProgress && serviceWindow) {
            return t('time.estimatedTimeWindow', parsedServiceWindow);
        }

        const parsedArrival = dateUtils.getLocalizedTime(
            (isClientTimezoneFlagEnabled ? arrivalTime : eta) as string
        );

        return t('time.estimatedTime', { time: parsedArrival });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [stopData]);

    function getClassName() {
        const defaultClassName = 'routecard-eta';
        return classNames(defaultClassName, className);
    }

    return (
        <RouteCardText
            className={getClassName()}
            icon={icon}
            iconColor={iconColor}
            iconSize={IconSizes.S}
            data-testid={extra['data-testid'] || 'routecard-eta'}
        >
            {timeWindow}
        </RouteCardText>
    );
}

export default RouteCardEta;
