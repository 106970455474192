import i18n from '~/i18n';
import {
    TaskTypes,
    TaskPriority,
    TaskStatus,
    TimeWindow,
    TaskStatusCondition
} from '~/api/types';
import { Task } from './Task';
import dateUtils from '~/utils/date-utils';

export interface TaskTableRowName {
    taskName: string;
    typeName: TaskTypes;
    isHighPriority: boolean;
    priority: TaskPriority;
}

export interface TaskTableRowStatus {
    status: TaskStatus;
    taskStatus: TaskStatusCondition;
}

/**
 * TaskTableRowData data class
 *
 * @category Data Classes
 *
 * @example
 * import { TaskTableRowData } from '~/data-classes';
 *
 * const srcData = {};
 * const task = new TaskTableRowData(srcData);
 *
 */
export class TaskTableRowData extends Task {
    get rowId(): string {
        return this.id;
    }

    get rowName(): TaskTableRowName[] {
        const rowNames = [] as TaskTableRowName[];

        if (this.isPickup) {
            rowNames.push({
                taskName: this.pickupLocationName as string,
                typeName: TaskTypes.PICKUP,
                isHighPriority: this.isHighPriority,
                priority: this.taskPriority
            });
        }

        if (this.isDelivery) {
            rowNames.push({
                taskName: this.deliveryLocationName as string,
                typeName: TaskTypes.DELIVERY,
                isHighPriority: this.isHighPriority,
                priority: this.taskPriority
            });
        }

        return rowNames;
    }

    get rowAddress(): string[] {
        const rowAddress = [];

        if (this.isPickup) {
            rowAddress.push(this.pickupLocationAddress?.addressLine1 as string);
        }

        if (this.isDelivery) {
            rowAddress.push(
                this.deliveryLocationAddress?.addressLine1 as string
            );
        }

        return rowAddress;
    }

    get rowCityState(): string[] {
        const rowCityState = [];

        if (this.isPickup) {
            rowCityState.push(
                i18n.t('taskManagement:rowCityState', {
                    city: this.pickupLocationAddress?.city,
                    state: this.pickupLocationAddress?.state
                })
            );
        }

        if (this.isDelivery) {
            rowCityState.push(
                i18n.t('taskManagement:rowCityState', {
                    city: this.deliveryLocationAddress?.city,
                    state: this.deliveryLocationAddress?.state
                })
            );
        }

        return rowCityState;
    }

    get rowZipcode(): string[] {
        const rowZipcode = [];

        if (this.isPickup) {
            rowZipcode.push(this.pickupLocationAddress?.zipcode as string);
        }

        if (this.isDelivery) {
            rowZipcode.push(this.deliveryLocationAddress?.zipcode as string);
        }

        return rowZipcode;
    }

    get rowTimeWindow(): string[] {
        const timeWindow = [] as string[];

        if (this.isPickup) {
            const pickupServiceWindow = this.pickupWindow;
            const [pickupWindow] =
                pickupServiceWindow &&
                Array.isArray(pickupServiceWindow) &&
                pickupServiceWindow.length > 0
                    ? pickupServiceWindow
                    : [pickupServiceWindow as TimeWindow];

            timeWindow.push(
                dateUtils.getTimeWindow(pickupWindow.start, pickupWindow.end)
            );
        }

        if (this.isDelivery) {
            const deliveryServiceWindow = this.deliveryWindow;
            const [deliveryWindow] =
                deliveryServiceWindow &&
                Array.isArray(deliveryServiceWindow) &&
                deliveryServiceWindow.length > 0
                    ? deliveryServiceWindow
                    : [deliveryServiceWindow as TimeWindow];

            timeWindow.push(
                dateUtils.getTimeWindow(
                    deliveryWindow.start,
                    deliveryWindow.end
                )
            );
        }

        return timeWindow;
    }

    get rowLabels(): string[] {
        return this.labels;
    }

    get rowStatus(): TaskTableRowStatus {
        return { status: this.status, taskStatus: this.taskStatusCondition };
    }

    get rowExtTaskType(): string {
        if (this.externalTaskType) return this.externalTaskType;

        if (this.isTwoPart) {
            return i18n.t('taskManagement:rowExtTaskType.twoPart');
        }

        return this.isPickup
            ? i18n.t('taskManagement:rowExtTaskType.pickup')
            : i18n.t('taskManagement:rowExtTaskType.delivery');
    }

    get rowEuid(): string {
        return this.euid ?? '';
    }
}
