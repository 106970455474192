import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';

import AddressForm from '~/ui/components/AddressForm';
import { Button } from '~/ui';

import { useSaveButtonProps } from '~/hooks/useFormik/useSaveButtonProps';

function TaskAddressForm({ onHide }) {
    const { t } = useTranslation(['common']);
    const { isSaveDisabled, onSave } = useSaveButtonProps(false);

    return (
        <Form className="editaddress-content">
            <AddressForm className="editaddress-inputs" />
            <div className="_d-flex _jc-flex-end">
                <Button variant="secondary" marginRight="1rem" onClick={onHide}>
                    {t('cancel')}
                </Button>
                <Button
                    onClick={onSave}
                    disabled={isSaveDisabled}
                    data-testid="save-button"
                >
                    {t('save')}
                </Button>
            </div>
        </Form>
    );
}

export default TaskAddressForm;
