import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from '~/ui';
import { resetToasts, selectToasts } from '~/reducers/toastsSlice';
import '~/ui/components/Toast/Toast.scss';

const ToastContext = createContext();

export default ToastContext;

function ToastContextProvider({ children }) {
    const [toasts, setToasts] = useState([]);
    const storeToasts = useSelector(selectToasts);
    const dispatch = useDispatch();

    const removeToast = useCallback(
        (toast) => {
            setToasts((items) => items.filter((i) => i !== toast));
        },
        [setToasts]
    );

    const addToast = useCallback(
        (toast) => {
            setToasts((items) => [
                { ...toast, id: new Date().getTime() },
                ...items
            ]);
        },
        [setToasts]
    );

    useEffect(() => {
        if (storeToasts.length > 0) {
            storeToasts.forEach(addToast);
            dispatch(resetToasts());
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [storeToasts, addToast]);

    const value = useMemo(
        () => ({
            addToast,
            clearToasts: () => {
                setToasts([]);
            }
        }),
        [addToast, setToasts]
    );

    return (
        <ToastContext.Provider value={value}>
            {children}
            <div className="toasts-wrapper _fd-column _ai-center">
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        duration={toast.duration}
                        message={toast.message}
                        onRemove={() => removeToast(toast)}
                        variant={toast.variant}
                        isPersisted={toast.isPersisted}
                    />
                ))}
            </div>
        </ToastContext.Provider>
    );
}

export { ToastContextProvider };
