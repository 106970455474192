import React, { useMemo, useState } from 'react';
import { isEmpty, isNil, filter, maxBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from '~/ui';

import constants from '~/utils/constants';
import taskUtils from '~/utils/task-utils';

import { useTaskAssignments } from '~/hooks';

import tasksApi from '~/api/TasksApi';
import AssignmentApi from '~/api/AssignmentApi';

import { addToast } from '~/reducers/toastsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

import { TaskHistoryStatus } from './TaskHistoryStatus';
import { AddReviseTimeButton } from './AddReviseTimeButton';
import { RevisedTimeModalContent } from './RevisedTimeModalContent';

const taskHistoryStatus = {
    default: 'default',
    focus: 'focus',
    completed: 'completed'
};

const taskHistoryByStatus = {
    [constants.taskStatus.PLANNED]: {
        created: taskHistoryStatus.completed,
        assigned: taskHistoryStatus.focus,
        dispatched: taskHistoryStatus.default,
        inProgress: taskHistoryStatus.default,
        completed: taskHistoryStatus.default
    },
    [constants.taskStatus.DISPATCHED]: {
        created: taskHistoryStatus.completed,
        assigned: taskHistoryStatus.completed,
        dispatched: taskHistoryStatus.focus,
        inProgress: taskHistoryStatus.default,
        completed: taskHistoryStatus.default
    },
    [constants.taskStatus.IN_PROGRESS]: {
        created: taskHistoryStatus.completed,
        assigned: taskHistoryStatus.completed,
        dispatched: taskHistoryStatus.completed,
        inProgress: taskHistoryStatus.focus,
        completed: taskHistoryStatus.default
    },
    [constants.taskStatus.COMPLETED]: {
        created: taskHistoryStatus.completed,
        assigned: taskHistoryStatus.completed,
        dispatched: taskHistoryStatus.completed,
        inProgress: taskHistoryStatus.completed,
        completed: taskHistoryStatus.focus
    },
    [constants.taskStatus.CANCELLED]: {
        created: taskHistoryStatus.completed,
        assigned: taskHistoryStatus.completed,
        dispatched: taskHistoryStatus.completed,
        inProgress: taskHistoryStatus.completed,
        cancelled: taskHistoryStatus.focus
    }
};

function mapTaskHistoryStatus(taskStatus) {
    return (
        taskHistoryByStatus[taskStatus] || {
            created: taskHistoryStatus.focus,
            assigned: taskHistoryStatus.default,
            dispatched: taskHistoryStatus.default,
            inProgress: taskHistoryStatus.default,
            completed: taskHistoryStatus.default
        }
    );
}

function TaskHistory({ task, tabIndex }) {
    const { id: taskId } = task;

    const { t } = useTranslation('taskManagement');

    const msToMins = (timeInMins) => timeInMins / 60000;

    const dispatch = useDispatch();
    const mainClient = useSelector(selectMainClient);
    const { enableCustomerPortal } = mainClient?.notifications || false;

    const taskHistoryStatuses = mapTaskHistoryStatus(task.taskStatus);

    const [dispatchTimeStamp, setDispatchTimestamp] = useState(null);
    const [inProgressTimeStamp, setInProgressTimestamp] = useState(null);
    const [completeTimeStamp, setCompleteTimestamp] = useState(null);

    const [dispatchedNotifications, setDispatchedNotifications] = useState([]);
    const [inProgressNotifications, setInProgressNotifications] = useState([]);
    const [completedNotifications, setCompletedNotifications] = useState([]);

    const isCancelled = task.taskStatus === constants.taskStatus.CANCELLED;
    const isCompleted = task.taskStatus === constants.taskStatus.COMPLETED;
    const taskAssignedAt = task.assignedToRouteAt;

    const { assignments } = useTaskAssignments({ taskId });

    const assignment = useMemo(() => {
        if (!taskUtils.checkIsTwoPartTask(task)) return assignments[0];

        return assignments.find(({ type }) =>
            tabIndex === 0
                ? type === constants.taskTypes.PICKUP
                : type === constants.taskTypes.DELIVERY
        );
    }, [tabIndex, assignments, task]);

    const { revisedStartServiceAt = '', revisedCompletedAt = '' } =
        assignment || {};

    const {
        hideModal,
        showModal: showRevisedTimeModal,
        modal: RevisedTimeModal
    } = useModal();

    /**
     * Gets a notification's status based on client settings and sent status
     * @param isEnabled
     * @param isSent
     * @param notificationName
     * @returns {string} status
     */
    function getNotificationStatus(isEnabled, isSent, notificationName) {
        const { notifications } = constants;
        if (!isEnabled) {
            return notifications.notificationStatusLifecycle.disabled;
        }

        if (isSent) {
            return notifications.notificationStatusLifecycle.sent;
        }

        if (notificationName === notifications.notificationStatus.atRisk) {
            return notifications.notificationStatusLifecycle.enabled;
        }

        return notifications.notificationStatusLifecycle.scheduled;
    }

    /**
     * Get status of all notifications for a task status
     * @param notificationMapping - task status's notification mapping
     * @param portalSettings - portal settings containing sent status
     * @returns {{title: String, status: String}[]} - name and status to be displayed
     */
    function getNotificationStatusesFromMapping(
        notificationMapping,
        portalSettings
    ) {
        return notificationMapping.map((notification) => {
            const isEnabled =
                mainClient.notifications[notification.clientSettingName];

            const isSent = portalSettings
                ? portalSettings[notification.notificationSentName]
                : false;

            const currentStatus = getNotificationStatus(
                isEnabled,
                isSent,
                notification.title
            );

            const notificationTitle = /away/i.test(notification.title)
                ? t('notificationStatus.title.away', {
                      minutesAway: msToMins(
                          mainClient.notifications.notificationTime
                      )
                  })
                : t(`notificationStatus.title.${notification.title}`);

            return {
                title: notificationTitle,
                status: currentStatus
            };
        });
    }

    /**
     * Set all notification statuses
     * @param settings - portal settings
     */
    function mapNotifications(settings) {
        const dispatchedNotificationsWithStatus =
            getNotificationStatusesFromMapping(
                constants.notifications.mapping.dispatched,
                settings ?? undefined
            );

        setDispatchedNotifications(dispatchedNotificationsWithStatus);

        const inProgressNotificationsWithStatus =
            getNotificationStatusesFromMapping(
                constants.notifications.mapping.inProgress,
                settings ?? undefined
            );

        setInProgressNotifications(inProgressNotificationsWithStatus);

        const completedNotificationsWithStatus =
            getNotificationStatusesFromMapping(
                constants.notifications.mapping.completed,
                settings ?? undefined
            );

        setCompletedNotifications(completedNotificationsWithStatus);
    }

    function setAllNotificationStatusesToDisabled() {
        const { disabled } =
            constants.notifications.notificationStatusLifecycle;
        setDispatchedNotifications([
            {
                title: t('notificationStatus.title.dispatched'),
                status: disabled
            },
            {
                title: t('notificationStatus.title.away', {
                    minutesAway: msToMins(
                        mainClient.notifications.notificationTime
                    )
                }),
                status: disabled
            },
            {
                title: t('notificationStatus.title.risk'),
                status: disabled
            }
        ]);
        setInProgressNotifications([
            {
                title: t('notificationStatus.title.arrived'),
                status: disabled
            }
        ]);
        setCompletedNotifications([
            {
                title: t('notificationStatus.title.completed'),
                status: disabled
            }
        ]);
    }

    /**
     * Set timestamp for each task status
     * @param taskAssignments
     */
    function setAllTimestamps(taskAssignments) {
        const taskDispatchedAtTimestamp =
            !isNil(taskAssignments) && !isEmpty(taskAssignments)
                ? taskAssignments[0].createdAt
                : null;

        const taskInProgressTimestamp =
            !isNil(taskAssignments) && !isEmpty(taskAssignments)
                ? taskAssignments[0].startServiceAt
                : null;

        setDispatchTimestamp(taskDispatchedAtTimestamp);
        setInProgressTimestamp(taskInProgressTimestamp);

        const latestCompletedTask = maxBy(
            filter(taskAssignments, 'completedAt'),
            'completedAt'
        );

        const taskCompletedAtTimestamp =
            isCompleted && !isNil(latestCompletedTask)
                ? latestCompletedTask.completedAt
                : null;

        setCompleteTimestamp(taskCompletedAtTimestamp);
    }

    async function getTaskHistory() {
        try {
            // TODO: RP-1243 - make use of useTaskAssignments hook
            const result = await tasksApi.getTaskAssignments(task.id);

            const taskAssignments = result.data.data;
            setAllTimestamps(taskAssignments);

            /**
             * Client does not use customer portal so all notifications are disabled
             */
            if (!enableCustomerPortal) {
                setAllNotificationStatusesToDisabled();
                return;
            }

            const isTaskDispatched =
                !isNil(taskAssignments) && !isEmpty(taskAssignments);

            /**
             * Task is not dispatched, so assignment is not created yet
             * None of the notifications have been sent
             */
            if (!isTaskDispatched) {
                mapNotifications();
                return;
            }

            /**
             * Task has been dispatched, assignment exists
             * Need to check if notifications have been sent
             */
            const assignmentPortalInfo = await AssignmentApi.getPortalInfo(
                taskAssignments[0].id
            );
            const { settings } = assignmentPortalInfo.data.data;
            mapNotifications(settings);
        } catch (error) {
            dispatch(
                addToast({
                    message: t('error:taskHistoryError'),
                    variant: 'error'
                })
            );
            setDispatchTimestamp(null);
        }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useMemo(() => getTaskHistory(), [task.id]);

    return (
        <div className="_mt-6">
            <TaskHistoryStatus
                title={t('taskHistory.taskCreated')}
                date={taskUtils.formatTimestamp(task.createdAt)}
                status={taskHistoryStatuses.created}
                isNotificationsDisabled={!enableCustomerPortal}
            />
            <TaskHistoryStatus
                title={t('taskHistory.taskAssigned')}
                date={taskUtils.formatTimestamp(taskAssignedAt)}
                status={taskHistoryStatuses.assigned}
                isNotificationsDisabled={!enableCustomerPortal}
            />
            <TaskHistoryStatus
                title={t('taskHistory.taskDispatched')}
                date={taskUtils.formatTimestamp(dispatchTimeStamp)}
                status={taskHistoryStatuses.dispatched}
                notifications={dispatchedNotifications}
                isNotificationsDisabled={!enableCustomerPortal}
            />
            <TaskHistoryStatus
                title={t('taskHistory.taskInProgress')}
                date={taskUtils.formatTimestamp(inProgressTimeStamp)}
                status={taskHistoryStatuses.inProgress}
                notifications={inProgressNotifications}
                isNotificationsDisabled={!enableCustomerPortal}
                revisedDateTime={revisedStartServiceAt}
                isTaskInProgress
            />
            {!isCancelled ? (
                <TaskHistoryStatus
                    title={t('taskHistory.taskCompleted')}
                    date={
                        isCompleted
                            ? taskUtils.formatTimestamp(completeTimeStamp)
                            : ''
                    }
                    status={taskHistoryStatuses.completed}
                    notifications={completedNotifications}
                    isNotificationsDisabled={!enableCustomerPortal}
                    hideLine
                    revisedDateTime={revisedCompletedAt}
                    isTaskCompleted
                />
            ) : (
                <TaskHistoryStatus
                    title={t('taskHistory.taskCancelled')}
                    date={
                        isCancelled
                            ? taskUtils.formatTimestamp(task.updatedAt)
                            : ''
                    }
                    status={taskHistoryStatuses.cancelled}
                    isNotificationsDisabled={!enableCustomerPortal}
                    hideLine
                />
            )}
            {isCompleted && (
                <AddReviseTimeButton onClick={showRevisedTimeModal} />
            )}
            <RevisedTimeModal title={t('reviseTime.title')}>
                <RevisedTimeModalContent
                    assignment={assignment}
                    hideModal={hideModal}
                />
            </RevisedTimeModal>
        </div>
    );
}

export default TaskHistory;
