export default {
    default: {
        poolId: 'us-east-1_atzB1Ascg',
        clientId: '4mu55ljs7uuf9g685kacg4btum',
        advancedSecurity: false,
        domain: 'wisesystems-development.auth.us-east-1'
    },
    'admin.sandbox.wisesys.info': {
        poolId: 'us-east-1_atzB1Ascg',
        clientId: '5alj1219fqatgcuheh60uqbrhv',
        advancedSecurity: false,
        domain: 'wisesystems-development.auth.us-east-1'
    },
    'admin.stage.wisesys.info': {
        poolId: 'us-east-1_XDFxEnNc2',
        clientId: '60sbaitvokf0m5vg4f7034kqje',
        advancedSecurity: false,
        domain: 'wisesystems-stage.auth.us-east-1'
    },
    'admin.uat.wisesys.info': {
        poolId: 'us-east-1_n3Vecun0d',
        clientId: '7acbhe8h70v6under0akvf578j',
        advancedSecurity: false,
        domain: 'wisesystems-uat.auth.us-east-1'
    },
    'admin.cn-uat.wisesys.info': {
        poolId: 'ca-central-1_oz22FhxHq',
        clientId: '4vvnggn5cj3espf9fcc43biuv4',
        advancedSecurity: false,
        domain: 'wisesystems-ca-central-1.auth.ca-central-1'
    },
    'admin.dhl-uat.wisesys.info': {
        poolId: 'us-east-2_TZzbmgNXV',
        clientId: 'o2migqv2011l4katqdblrb7nc',
        advancedSecurity: false,
        domain: 'wisesystems-us-east-2.auth.us-east-2'
    },
    'admin.ses.wisesys.info': {
        poolId: 'eu-west-1_9jNoJQZS7',
        clientId: '1u2bi5nn4o1cu3i3e10n3jcb0',
        advancedSecurity: false,
        domain: 'wisesystems-eu-west-1.auth.eu-west-1'
    },
    'admin.demo.wisesys.info': {
        poolId: 'us-east-1_1pfVDk5L4',
        clientId: '7t1i56a71mgn8sf2ql2kab7tpp',
        advancedSecurity: false,
        domain: 'wisesystems-demo.auth.us-east-1'
    },
    'enterprise.wisesystems.io': {
        poolId: 'us-east-1_wVPIdHERF',
        clientId: '40dtgnki0sj126tgs8rgf5nspa',
        advancedSecurity: false,
        domain: 'wisesystems-production.auth.us-east-1'
    },
    'dev.wisesystems.io': {
        poolId: 'us-east-1_wVPIdHERF',
        clientId: '40dtgnki0sj126tgs8rgf5nspa',
        advancedSecurity: false,
        domain: 'wisesystems-production.auth.us-east-1'
    },
    'enterprise.jp.wisesystems.io': {
        poolId: 'ap-northeast-1_79CdY2CpI',
        clientId: '7aij71ne9pmv6o39rid3n5u59r',
        advancedSecurity: false,
        domain: 'wisesystems-japan.auth.ap-northeast-1'
    },
    'enterprise.sg.wisesystems.io': {
        poolId: 'ap-northeast-1_79CdY2CpI',
        clientId: '7aij71ne9pmv6o39rid3n5u59r',
        advancedSecurity: false,
        domain: 'wisesystems-japan.auth.ap-northeast-1'
    },
    'admin-uat.sg.wisesystems.io': {
        poolId: 'ap-southeast-1_hkdN8eUqb',
        clientId: '3nu0mmr05qqbhldrjmckkc25ra',
        advancedSecurity: false,
        domain: 'wisesystems-singaporeuat.auth.ap-southeast-1'
    },
    'enterprise.me.wisesystems.io': {
        poolId: 'ap-southeast-1_lnV6s3Kx6',
        clientId: '779f43nedlep492v84ce5hnid7',
        advancedSecurity: false,
        domain: 'wisesystems-bahrain.auth.ap-southeast-1'
    },
    'enterprise.au.wisesystems.io': {
        poolId: 'ap-southeast-2_5BiuVkXoc',
        clientId: '574bbjjue725gt55usokm33b63',
        advancedSecurity: false,
        domain: 'wisesystems-sydney.auth.ap-southeast-2'
    },
    'enterprise.ce.wisesystems.io': {
        poolId: 'eu-central-1_o1yg252xX',
        clientId: '1sa0pancubpi6n14slv9kf0eqq',
        advancedSecurity: false,
        domain: 'wisesystems-frankfurt.auth.eu-central-1'
    },
    'enterprise.ca.wisesystems.io': {
        poolId: 'us-east-1_wVPIdHERF',
        clientId: '40dtgnki0sj126tgs8rgf5nspa',
        advancedSecurity: false,
        domain: 'wisesystems-production.auth.us-east-1'
    },
    'enterprise.uk.wisesystems.io': {
        poolId: 'eu-west-2_SgC7F2LPv',
        clientId: '7gcgdcmbvl6896cjecnj0ae4kv',
        advancedSecurity: false,
        domain: 'wisesystems-london.auth.eu-west-2'
    },
    'enterprise.uk2.wisesystems.io': {
        poolId: 'eu-west-2_y32pxltBS',
        clientId: '6p2fa4pt4gj7dluvo79bgus9rg',
        advancedSecurity: false,
        domain: 'wisesystems-london2.auth.eu-west-2'
    },
    'enterprise.ie.wisesystems.io': {
        poolId: 'eu-west-2_SgC7F2LPv',
        clientId: '4ani5gbrcbprssqpvocb2lmou5',
        advancedSecurity: false,
        domain: 'wisesystems-london.auth.eu-west-2'
    },
    'enterprise.sa.wisesystems.io': {
        poolId: 'us-east-1_wVPIdHERF',
        clientId: '40dtgnki0sj126tgs8rgf5nspa',
        advancedSecurity: false,
        domain: 'wisesystems-production.auth.us-east-1'
    },
    'admin.dev.wisesys.info': {
        poolId: 'us-east-1_hc232xDac',
        clientId: '3l39biv4isq3ikk6e9bvcpri21',
        advancedSecurity: false,
        domain: 'wisesystems-us-east-1.auth.us-east-1'
    }
};
