import React from 'react';
import constants from '~/utils/constants';
import './ParkingPin.scss';

function ParkingPin() {
    return (
        <div
            data-testid="parkingpin"
            className="parkingpin _jc-center _ai-center"
        >
            {constants.mapMarkerLabels.PARKING}
        </div>
    );
}

export default ParkingPin;
