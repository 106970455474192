import { LiveStop, PlanRoute, PlanStop } from '~/data-classes';
import { useMemo, useState } from 'react';
import { useMapUtils } from '~/hooks';
import { Coordinates } from '~/api/types';
import { popupMaker } from '~/utils/map';
import constants from '~/utils/constants';
import { useHereMapContext } from '~/components/HereMaps/HereMapProvider';
import { useSelector } from 'react-redux';
import {
    selectIsClusteringStops,
    selectShowRouteLabel,
    selectShowRoutePopup,
    selectShowStopLabel,
    selectShowStopNumber,
    selectShowStopPopup
} from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useRouteMarkerSelect } from '~/components/MapPage/useRouteMarkerSelect';
import { useStopMarkerSelect } from '~/components/MapPage/useStopMarkerSelect';

interface UsePlannedMarkersConfig {
    markers: JSX.Element[];
}

export const usePlannedMarkersConfig = ({
    markers
}: UsePlannedMarkersConfig) => {
    const [hoveredRouteMarker, setHoveredRouteMarker] = useState(null);

    const { showPopup, removePopup } = useHereMapContext();
    const { toggleIsSelected: toggleIsRouteSelected } = useRouteMarkerSelect();
    const { toggleIsSelected: toggleIsStopSelected } = useStopMarkerSelect();

    const { isPlanRouteMode, isStopsClustersMode, mapRouteMode } =
        useMapUtils();
    const showStopSequenceNumber = useSelector(
        selectShowStopNumber(mapRouteMode as ConfigurableMapRouteMode)
    );
    const showStopLabel = useSelector(
        selectShowStopLabel(mapRouteMode as ConfigurableMapRouteMode)
    );
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const showRouteLabel = useSelector(
        selectShowRouteLabel(mapRouteMode as ConfigurableMapRouteMode)
    );

    const showRoutePopup = useSelector(
        selectShowRoutePopup(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );

    const showStopPopup = useSelector(
        selectShowStopPopup(mapRouteMode as unknown as ConfigurableMapRouteMode)
    );

    const handlePopupEvents = ({ event }: { event: string }) => {
        const { mapChildEvents } = constants;
        switch (event) {
            case mapChildEvents.POPUP_MOUSELEAVE:
                removePopup();
                break;
            default:
                break;
        }
    };

    const handleRouteMarkerClick = (marker: JSX.Element) => {
        const {
            props: { selectable, routeLevelData }
        } = marker;
        toggleIsRouteSelected({
            selectable,
            routeLevelData
        });
    };

    const handleRouteMarkerMouseEnter = (payload: {
        eventCoordinates: Record<string, number>;
        location: Coordinates;
        markerData: PlanRoute;
    }) => {
        const { markerData, location, eventCoordinates } = payload;
        if (!showRoutePopup) {
            return;
        }
        const popup = popupMaker.makeRoutePopup(
            location,
            markerData,
            handlePopupEvents
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        showPopup(popup, eventCoordinates);
    };

    const handleStopMarkerClick = (marker: JSX.Element) => {
        const {
            props: { data }
        } = marker;
        toggleIsStopSelected(data);
    };

    const handleStopMarkerMouseEnter = (payload: {
        eventCoordinates: Record<string, number>;
        location: Coordinates;
        markerData: PlanStop | LiveStop;
    }) => {
        if (!showStopPopup) {
            return;
        }
        const { markerData } = payload;
        const popupPayload = {
            ...payload,
            stopMarkerData: markerData
        };
        const popup = popupMaker.makeStopPopup(popupPayload, handlePopupEvents);

        const { eventCoordinates } = payload;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        showPopup(popup, eventCoordinates);
    };
    const markersConfig = useMemo(() => {
        if (!isPlanRouteMode) {
            return;
        }
        if (isStopsClustersMode) {
            const markerConfigs = markers.map((marker) => {
                const { props } = marker;
                const { id, lat, lng, data } = props;
                const coordinates = { lat, lng };
                const { stopId, taskId } = data || {};
                const markerId = id || stopId || taskId;
                const events = [
                    {
                        event: 'pointerenter',
                        handler: (eventCoordinates: Record<string, number>) => {
                            handleStopMarkerMouseEnter({
                                eventCoordinates,
                                location: coordinates,
                                markerData: data
                            });
                        }
                    },
                    {
                        event: 'pointerup',
                        handler: () => {
                            handleStopMarkerClick(marker);
                        }
                    }
                ];
                return {
                    id: markerId,
                    component: marker,
                    coordinates,
                    events
                };
            });
            return markerConfigs;
        }
        return markers.map((marker) => {
            const { props } = marker;
            const { lat, lng, routeLevelData } = props;
            const coordinates = { lat, lng };
            const { clientRouteId } = routeLevelData;
            const events = [
                {
                    event: 'pointerenter',
                    handler: (eventCoordinates: Record<string, number>) => {
                        setHoveredRouteMarker(clientRouteId);
                        handleRouteMarkerMouseEnter({
                            eventCoordinates,
                            location: coordinates,
                            markerData: routeLevelData
                        });
                    }
                },
                {
                    event: 'pointerleave',
                    handler: () => {
                        setHoveredRouteMarker(null);
                    }
                },
                {
                    event: 'pointerup',
                    handler: () => {
                        handleRouteMarkerClick(marker);
                    }
                }
            ];
            return {
                id: clientRouteId,
                component: marker,
                coordinates,
                events
            };
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        markers,
        isPlanRouteMode,
        isStopsClustersMode,
        isClusteringStops,
        showRoutePopup,
        showStopPopup,
        showRouteLabel,
        showStopLabel,
        showStopSequenceNumber
    ]);

    return { markersConfig, hoveredRouteMarker };
};
