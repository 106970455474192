import { useDropDownMenuHandler } from '~/hooks';
import { VehicleType } from '~/components/AddTaskModal/utils/addTaskModalUtils';

interface UseVehicleTypeDropDownProps {
    /**
     * Whether the dropdown menu is editable
     */
    isEditable: boolean;
    /**
     *
     * The handler for item selection
     */
    onItemSelected: (value: string) => void;
}

export const useVehicleTypeDropDown = ({
    isEditable,
    onItemSelected
}: UseVehicleTypeDropDownProps) => {
    const {
        isOpen,
        onToggle,
        onClose,
        ref: dropdownRef
    } = useDropDownMenuHandler();

    const toggleDropDown = () => {
        if (isEditable) onToggle();
    };

    const handleClickItem = (value: string) => {
        onClose();
        onItemSelected(value);
    };

    const menuItems = [
        {
            translationKey: 'fields.vehicleType.fields.sideLoader.label',
            onClick: () => {
                handleClickItem(VehicleType.SIDE_LOADER);
            }
        },
        {
            translationKey: 'fields.vehicleType.fields.endLoader.label',
            onClick: () => {
                handleClickItem(VehicleType.END_LOADER);
            }
        }
    ];

    return {
        isOpen,
        dropdownRef,
        menuItems,
        onClose,
        toggleDropDown
    };
};
