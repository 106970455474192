import {
    TableOptions,
    useReactTable,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel
} from '@tanstack/react-table';
import { LiveDispatchTaskTableRowData } from '~/data-classes';
import { useLiveDispatchTableData } from '../useLiveDispatchTableData';
import { useLiveDispatchTableStates } from '../useLiveDispatchTableStates';
import { columnDefMap } from './columnDef';
import { TaskTypes } from '~/api/types';
import { useSelector } from 'react-redux';
import { selectIsOpenPairEquipmentControlPopup } from '~/reducers/mapDrawerSettingsSlice';

export const useLiveDispatchTableConfig = () => {
    /**
     * Retrieves table source data
     */
    const { unassignedTasksData, excludedUnassignedTasksData, isFiltered } =
        useLiveDispatchTableData();

    /**
     * Retrieves context values for table state
     */
    const { columnFilters, columnOrder, columnVisibility } =
        useLiveDispatchTableStates();

    /**
     * Define source data for unselected tasks table rows
     */
    const rowData = isFiltered
        ? excludedUnassignedTasksData
        : unassignedTasksData;

    /**
     * Define source data for sticky table rows
     */
    const selectedRowData = isFiltered ? unassignedTasksData : [];

    /**
     * Define column headers
     */
    const columnHeaders = Object.values(columnDefMap);

    /**
     * Define table options for the unselected tasks table
     */
    const tableOptions: TableOptions<LiveDispatchTaskTableRowData> = {
        data: rowData,
        columns: columnHeaders,
        state: {
            columnFilters,
            columnOrder,
            columnVisibility
        },
        getCoreRowModel: getCoreRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel()
    };

    /**
     * Initializes the react-table model for the unselected tasks table
     */
    const { getHeaderGroups, getRowModel } = useReactTable(tableOptions);

    /**
     * Define table options for the sticky table
     */
    const selectedTableOptions: TableOptions<LiveDispatchTaskTableRowData> = {
        data: selectedRowData,
        columns: columnHeaders,
        state: {
            columnOrder,
            columnVisibility
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel()
    };

    /**
     * Initializes the react-table model for the sticky table
     */
    const { getRowModel: getSelectedRowModel } =
        useReactTable(selectedTableOptions);

    /**
     * Whether the equipment pair popup is open
     */
    const isOpenPairEquipmentControlPopupop = useSelector(
        selectIsOpenPairEquipmentControlPopup
    );

    /**
     * The table data to build the unselected tasks table with
     */
    const allRows = getRowModel().rows;

    /**
     * All unassigned tasks with delivery task type for Equipment Pairing
     */
    const deliveryRows = getRowModel().rows.filter((row) => {
        const { original: displayedData } = row;

        const { rowTaskType } = displayedData;

        return rowTaskType === TaskTypes.DELIVERY;
    });

    /**
     * The table data for selected rows to build the sticky table with
     */
    const allSelectedRows = getSelectedRowModel().rows;

    /**
     * The table header groups data to build the table headers with
     */
    const headerGroups = getHeaderGroups();

    return {
        allRows: isOpenPairEquipmentControlPopupop ? deliveryRows : allRows,
        allSelectedRows,
        headerGroups,
        isFiltered
    };
};
