import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Icon } from '~/ui';

import { ViewColumnsButtonProps } from './types';

import './ViewColumnsButton.scss';

const ViewColumnsButton: React.FC<ViewColumnsButtonProps> = ({
    toggleDropdown,
    showDropdown
}) => {
    const { t } = useTranslation(['liveDispatchTable']);

    return (
        <button
            type="button"
            className={`live-dispatch-view-columns-button ${
                showDropdown ? 'active' : ''
            }`}
            onClick={toggleDropdown}
            data-testid="live-dispatch-view-columns-button"
        >
            <div className="live-dispatch-view-columns-button__wrapper">
                <Icon
                    icon="visibleOn"
                    size="s"
                    color={showDropdown ? 'meteor' : 'galaxy-300'}
                />
                <span
                    className={classNames(
                        'live-dispatch-view-columns-button',
                        'button-label',
                        {
                            'button-label--selected': showDropdown
                        }
                    )}
                    data-testid="live-dispatch-view-columns-button__label"
                >
                    {t('viewColumns.buttonLabel')}
                </span>
            </div>
        </button>
    );
};

export default ViewColumnsButton;
