import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    setIsOpenLiveDispatchDrawer,
    setIsOpenUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { resetSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { Icon } from '~/ui';

import './LiveDispatchHeader.scss';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

export const LiveDispatchHeader: React.FC = () => {
    const { t } = useTranslation(['liveDispatchTable']);
    const dispatch = useDispatch();

    const handleCancelClick = () => {
        dispatch(setIsOpenUnassignedTasksDrawer(false));
        dispatch(setIsOpenLiveDispatchDrawer(false));
        dispatch(resetSelectedTaskIds([]));
        dispatch(resetSelectedEquipmentIds());
    };

    return (
        <div
            className="live-dispatch-drawer-header"
            data-testid="live-dispatch-drawer-header"
        >
            <div
                className="live-dispatch-drawer-header__title"
                data-testid="live-dispatch-drawer-header__title"
            >
                {t('tableToolbar.header.title')}
            </div>
            <Icon
                icon="cancel"
                size={IconSizes.M}
                color="galaxy-800"
                onClick={handleCancelClick}
                data-testid="live-dispatch-drawer-header__cancel-icon"
            />
        </div>
    );
};
