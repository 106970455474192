import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import classnames from 'classnames';

import { Checkbox, Icon, Tooltip, useClickOutside } from '~/ui';

import './DropDownSelection.css';

const DropDownSelection = React.forwardRef(function DropDownSelection(
    {
        title = '',
        placeholder = '',
        items = [],
        isSingleSelection = false,
        isSelectionDisabled = false,
        isErrorState = false,
        hasSelectAll = true,
        selectAllText = '',
        handleChange,
        initialValues,
        disabledItems = [],
        styles,
        isDropDownDisplayed,
        onToggle,
        onHide,
        className,
        customTitle,
        inputFieldId = 'dropDownInput',
        displayTooltip = true,
        ...extra
    },
    ref
) {
    const [selectedItems, setSelectedItems] = useState(initialValues || []);
    const [selectedAll, setSelectedAll] = useState([]);
    const [displayedSelectedItems, setDisplayedSelectedItems] = useState([]);
    useClickOutside(ref, onHide);

    useEffect(() => {
        if (initialValues) {
            setSelectedItems(initialValues);
        }
    }, [initialValues]);

    useEffect(() => {
        // checkmark 'select all' when all items are selected
        if (selectedItems.length === items.length && items.length !== 0) {
            setSelectedAll([true]);
        } else {
            setSelectedAll([false]);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedItems]);

    useEffect(() => {
        const selectedItemsWithoutDisabledItems = selectedItems.flatMap(
            (item) => (disabledItems.includes(item) ? [] : item.name)
        );
        setDisplayedSelectedItems(selectedItemsWithoutDisabledItems);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedItems]);

    function handleItemSelection(payload) {
        let { selectedValues } = payload;

        if (isSingleSelection) {
            selectedValues = payload.isChecked ? [payload.value] : [];
        }

        setSelectedAll([]);
        setSelectedItems(selectedValues);
        handleChange(selectedValues);

        if (payload.isChecked) onHide?.();
    }

    function handleSelectAll(payload) {
        setSelectedAll(payload.selectedValues);
        if (payload.selectedValues.length) {
            setSelectedItems(items);
            handleChange(items);
        } else {
            setSelectedItems([]);
            handleChange([]);
        }
    }

    const handleClick = () => {
        if (!isSelectionDisabled) onToggle?.();
    };

    const isTooltipDisabled =
        !displayedSelectedItems.length || isSelectionDisabled;

    const getDataTestId = (testIdLabel) => {
        const defaultPrefix = 'dropdownselection';
        if (extra['data-testid']) {
            return `${extra['data-testid']}-${testIdLabel}`;
        }
        return `${defaultPrefix}-${testIdLabel}`;
    };

    return (
        <div
            ref={ref}
            className={classnames('dropdown', className)}
            data-testid={extra['data-testid'] || 'dropdownselection'}
        >
            <label
                htmlFor={inputFieldId}
                className="dropdown-input-wrapper _mt-6"
            >
                {customTitle}
                {!customTitle && (
                    <span
                        className="dropdown-input-title"
                        data-testid={getDataTestId('title')}
                    >
                        {title}
                    </span>
                )}
                <Tooltip
                    placement="top"
                    content={displayedSelectedItems.join(', ')}
                    disabled={isTooltipDisabled}
                    isVisible={displayTooltip}
                >
                    <div
                        onClick={handleClick}
                        onKeyDown={handleClick}
                        role="button"
                        tabIndex={0}
                        data-testid={getDataTestId('button')}
                    >
                        <input
                            id={inputFieldId}
                            type="text"
                            className={classnames('dropdown-input', {
                                'label-input--error': isErrorState
                            })}
                            defaultValue={displayedSelectedItems.join(', ')}
                            placeholder={placeholder}
                            readOnly
                            disabled={isSelectionDisabled}
                            data-testid={getDataTestId('selected')}
                        />
                    </div>
                </Tooltip>
            </label>
            <button
                type="button"
                className={`icon-wrapper dropdown-arrow ${
                    isDropDownDisplayed && 'dropdown-arrow--rotate'
                }`}
                onClick={onToggle}
                disabled={isSelectionDisabled}
            >
                <Icon icon="chevronDown" color="galaxy-800" />
            </button>
            {isDropDownDisplayed && (
                <div className={`${styles?.selections} dropdown-selections`}>
                    {hasSelectAll && !isSingleSelection && (
                        <Checkbox
                            id="select-all"
                            name="select-all"
                            className="dropdown-selections-items"
                            value
                            selectedValues={selectedAll}
                            handleChange={handleSelectAll}
                        >
                            {`${selectAllText} (${items.length})`}
                        </Checkbox>
                    )}
                    {items.map((item) => {
                        const isDisabled = disabledItems.some(
                            (disabledItem) => {
                                return disabledItem.id === item.id;
                            }
                        );
                        return (
                            <Checkbox
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                className="dropdown-selections-items"
                                value={item}
                                selectedValues={selectedItems}
                                handleChange={handleItemSelection}
                                disabled={isDisabled}
                            >
                                {item.name}
                            </Checkbox>
                        );
                    })}
                </div>
            )}
        </div>
    );
});

DropDownSelection.propTypes = {
    /** Input label title */
    title: PT.string,
    /** Input placeholder */
    placeholder: PT.string,
    /** Array of selectable items (checkbox) */
    items: PT.arrayOf(PT.object),
    /** Select all checkbox is displayed */
    hasSelectAll: PT.bool,
    /** Text for select all checkbox */
    selectAllText: PT.string,
    /** Handle checkbox change function */
    handleChange: PT.func,
    /** Initial value for selected items */
    initialValues: PT.arrayOf(PT.object),
    /** Array of items that are disabled */
    disabledItems: PT.arrayOf(PT.object),
    /** Boolean to display the dropdown down */
    isDropDownDisplayed: PT.bool,
    /** Function called on outside click */
    onHide: PT.func,
    /** Function called to toggle drop down */
    onToggle: PT.func,
    /** Custom component to render instead of the default title component */
    customTitle: PT.node,
    /** Input field id attribute value */
    inputFieldId: PT.string,
    /** Boolean to add error state class to the input field */
    isErrorState: PT.bool,
    /** Boolean to disable the input field */
    isSelectionDisabled: PT.bool,
    className: PT.string
};

export default DropDownSelection;
