import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenAddTaskModal } from '~/reducers/addTaskSlice';
import { setIsOpenPairEquipmentControlPopup } from '~/reducers/mapDrawerSettingsSlice';
import {
    resetSelectedEquipmentIds,
    selectSelectedEquipmentIds
} from '~/reducers/selectedEquipmentIdsSlice';
import {
    resetSelectedTaskIds,
    selectSelectedTaskIds
} from '~/reducers/selectedTaskIdsSlice';

export const usePairEquipmentControl = () => {
    const dispatch = useDispatch();

    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);

    const handleCancel = () => {
        dispatch(setIsOpenPairEquipmentControlPopup(false));
        dispatch(resetSelectedEquipmentIds());
        dispatch(resetSelectedTaskIds(null));
    };

    const handleLeftArrow = () => {
        dispatch(setIsOpenPairEquipmentControlPopup(false));
        dispatch(resetSelectedEquipmentIds());
        dispatch(resetSelectedTaskIds(null));
    };

    const handleSubmit = () => {
        dispatch(setIsOpenAddTaskModal(true));
    };

    return {
        handleCancel,
        handleLeftArrow,
        handleSubmit,
        isSubmitDisabled: !(
            selectedTaskIds.length && selectedEquipmentIds.length
        )
    };
};
