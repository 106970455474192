import React from 'react';
import { Detail, EditableText } from '~/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addToast } from '~/reducers/toastsSlice';

import { useEquipmentDetails } from './useEquipmentDetails';
import { EquipmentItem } from './useEquipmentDetails/types';
import { ApiTask } from '~/api/types';

import './EquipmentDetails.scss';

interface FieldValueProps {
    value: string;
}
interface EquipmentDetailsProps {
    equipmentId: string;
    selectedTask: ApiTask;
    hasEditPermissions: boolean;
}

const EQUIPMENT_ID = 'id';
const RESERVATION = 'reservation';
const PAYEROFFREIGHT = 'payerOfFreight';
const DROPSTAY = 'dropStay';

export const EquipmentDetails = ({
    selectedTask,
    equipmentId,
    hasEditPermissions
}: EquipmentDetailsProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['taskManagement', 'common', 'error']);

    const { reservation, payerOfFreight, dropStay, id } = selectedTask;

    const {
        equipmentData,
        validateNewEquipmentId,
        updateEquipmentId,
        handleUpdateReservation,
        handleUpdatePayerOfFreight,
        handleUpdateDropOrStay
    } = useEquipmentDetails({ equipmentId, hasEditPermissions, taskId: id });

    const handleUpdateEquipmentId = async ({ value }: { value: string }) => {
        const result = await validateNewEquipmentId(value);
        if (result) {
            updateEquipmentId({ value });
        } else {
            dispatch(
                addToast({
                    message: t('error:invalidEquipmentIdError'),
                    variant: 'error'
                })
            );
        }
    };

    const getItemMainContent = (item: EquipmentItem, index: number) => {
        const { value, key } = item;
        const defaultText = t('N/A');

        const getEditableTextProps = (
            mainContent: string,
            handleSubmit: ({ value }: FieldValueProps) => void,
            testId: string
        ) => ({
            index,
            mainContent,
            handleSubmit,
            isEditable: hasEditPermissions,
            'data-testid': testId
        });

        if (Array.isArray(value)) {
            let iterableItems: Array<string> = value as string[];
            let extraItemsCount = 0;

            if (iterableItems.length > 2) {
                extraItemsCount = iterableItems.length - 2;
                iterableItems = iterableItems.slice(0, 2);
            }

            return (
                <div className="iterable-items">
                    {iterableItems.map((eachValue: string) => (
                        <span key={eachValue} className="iterable-items__item">
                            {eachValue}
                        </span>
                    ))}
                    {extraItemsCount > 0 && (
                        <span
                            key={extraItemsCount}
                            className="iterable-items__item"
                        >
                            {t('drawer.equipmentDetails.extraItemsCount', {
                                count: extraItemsCount
                            })}
                        </span>
                    )}
                </div>
            );
        }

        switch (key) {
            case EQUIPMENT_ID:
                return (
                    <EditableText
                        {...getEditableTextProps(
                            value as string,
                            handleUpdateEquipmentId,
                            'equipmentid-editable-text'
                        )}
                    />
                );
            case RESERVATION:
                return (
                    <EditableText
                        {...getEditableTextProps(
                            reservation || defaultText,
                            handleUpdateReservation,
                            'reservation-editable-text'
                        )}
                    />
                );
            case PAYEROFFREIGHT:
                return (
                    <EditableText
                        {...getEditableTextProps(
                            payerOfFreight || defaultText,
                            handleUpdatePayerOfFreight,
                            'payeroffreight-editable-text'
                        )}
                    />
                );
            case DROPSTAY:
                return (
                    <EditableText
                        {...getEditableTextProps(
                            dropStay || defaultText,
                            handleUpdateDropOrStay,
                            'dropstay-editable-text'
                        )}
                    />
                );
            default:
                return (
                    <EditableText
                        index={index}
                        mainContent={value}
                        handleSubmit={() => {}}
                        data-testid="equipment-noneditable-text"
                    />
                );
        }
    };

    return (
        <>
            {equipmentData.map((eachItem, index) => {
                const { key, iconName, tooltipContent } = eachItem;

                return (
                    <Detail
                        key={key as string}
                        iconName={iconName}
                        iconSize="s"
                        iconClassName="detail-drawer-item__icon"
                        iconTooltipContent={tooltipContent as string}
                        showTooltipOnIcon
                        className="_d-flex _ai-flex-start"
                        mainContent={getItemMainContent(eachItem, index)}
                        iconColor="galaxy-500"
                        secondaryContent={undefined}
                    />
                );
            })}
        </>
    );
};
