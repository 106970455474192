import { ColumnFiltersState } from '@tanstack/react-table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';

import {
    DropDownMenu,
    DropDownMenuItem,
    TooltipButton,
    Button,
    Icon,
    TimeInput,
    DatePickerHeader,
    Checkbox
} from '~/ui';
import { useDropDownMenuHandler } from '~/hooks';

const ROOT_CLASS_NAME = 'filter-table-header';

interface TableFilterElementProps {
    headerText: string | undefined;
    startTime?: string;
    onStartTimeChange?: (e: string) => void;
    endTime?: string;
    onEndTimeChange?: (e: string) => void;
    headerId: string;
    selectedDate?: null | Date;
    selectedStartDate?: null | Date;
    selectedEndDate?: null | Date;
    onChangeDate?: (e: Date) => void;
    onChangeDateMultiple?: (e: [Date, Date]) => void;
    columnFilters: ColumnFiltersState;
    filterList?: {
        name: string;
        numberOfEntries: number;
    }[];
    selectedFilterList?: string[];
    handleFilterCheckboxClick?: ({
        isChecked,
        value
    }: {
        isChecked: boolean;
        value?: string | number | unknown;
    }) => void;
    onClearStatusFilter: () => void;
    onApplyStatusFilter: () => void;
}

export const TableFilterElement = ({
    headerText,
    startTime,
    onStartTimeChange,
    endTime,
    onEndTimeChange,
    headerId,
    selectedDate,
    selectedStartDate,
    selectedEndDate,
    onChangeDate,
    onChangeDateMultiple,
    columnFilters,
    filterList,
    selectedFilterList,
    handleFilterCheckboxClick,
    onClearStatusFilter,
    onApplyStatusFilter
}: TableFilterElementProps) => {
    const { t } = useTranslation(['liveDispatchTable']);
    const {
        ref,
        isOpen: isDropDownOpen,
        onToggle: toggleDropDown,
        onClose: closeDropDown
    } = useDropDownMenuHandler();

    const getFilterIconColor = () => {
        if (columnFilters.some((data) => data.id === headerId)) {
            return 'meteor';
        }
        if (isDropDownOpen) {
            return 'galaxy-800';
        }
        return 'galaxy-300';
    };

    const FilterIcon = () => (
        <Icon
            color={getFilterIconColor()}
            className="filter"
            sx={{
                pointer: 'cursor'
            }}
            icon="filterMenu"
            data-testid={`${ROOT_CLASS_NAME}__filterMenuIcon`}
        />
    );

    const handleDateSelection = (value: Date) => {
        if (onChangeDate) {
            onChangeDate(value);
        }
    };

    const handleDateSelectionMultiple = (value: [Date, Date]) => {
        if (onChangeDateMultiple) {
            onChangeDateMultiple(value);
        }
    };

    return (
        <div className="live_dispatch-date-picker">
            <DropDownMenu
                isOpen={isDropDownOpen}
                toggle={toggleDropDown}
                close={closeDropDown}
                customDropDownButton={FilterIcon}
                position={filterList ? 'down-right' : 'down'}
                styles={{
                    innerContainer: 'dropdown-flex _p-0',
                    menuTitle: 'dropdown-flex-item',
                    menuItems: 'filterDropdown'
                }}
                ref={ref}
                data-testid={`${ROOT_CLASS_NAME}__filterDropdown`}
            >
                <div className={`${ROOT_CLASS_NAME}__filter-header`}>
                    <span>{headerText}</span>
                </div>

                {filterList ? (
                    <div
                        className={`${ROOT_CLASS_NAME}__filter-list-container`}
                    >
                        {filterList.map((data) => (
                            <DropDownMenuItem
                                key={data.name}
                                className="_p-0 _jc-space-between"
                            >
                                <Checkbox
                                    id={data.name}
                                    name={data.name}
                                    value={data.name}
                                    selectedValues={selectedFilterList}
                                    handleChange={handleFilterCheckboxClick}
                                    data-testid="filter-value-text"
                                >
                                    {data.name}
                                </Checkbox>
                                <span className="taskTypeCount">
                                    {data.numberOfEntries}
                                </span>
                            </DropDownMenuItem>
                        ))}
                    </div>
                ) : (
                    <>
                        {startTime && endTime && (
                            <div
                                className={`${ROOT_CLASS_NAME}__time-select _jc-space-between`}
                            >
                                <span>Start:</span>
                                <TimeInput
                                    variant="live_dispatch"
                                    value={startTime || '00:00'}
                                    onChange={onStartTimeChange}
                                    data-testid="live-dispatch-start-time"
                                />
                                <span>End:</span>
                                <TimeInput
                                    variant="live_dispatch"
                                    value={endTime || '23:59'}
                                    onChange={onEndTimeChange}
                                    data-testid="live-dispatch-end-time"
                                />
                            </div>
                        )}

                        <DropDownMenuItem
                            key={headerId}
                            className="live_dispatch-date-picker _p-0 _jc-space-between"
                        >
                            {onChangeDateMultiple ? (
                                <ReactDatePicker
                                    id="livedispatch-datepicker"
                                    title="livedispatch-datepicker"
                                    selected={selectedStartDate}
                                    onChange={handleDateSelectionMultiple}
                                    startDate={selectedStartDate}
                                    endDate={selectedEndDate}
                                    selectsRange
                                    tabIndex={-1}
                                    inline
                                    showPopperArrow={false}
                                    renderCustomHeader={DatePickerHeader}
                                />
                            ) : (
                                <ReactDatePicker
                                    id="livedispatch-datepicker"
                                    title="livedispatch-datepicker"
                                    selected={selectedDate}
                                    onChange={handleDateSelection}
                                    inline
                                    renderCustomHeader={DatePickerHeader}
                                />
                            )}
                        </DropDownMenuItem>
                    </>
                )}
                <div
                    className={`_d-flex _ai-center _jc-space-between ${ROOT_CLASS_NAME}__filter-footer`}
                >
                    <TooltipButton
                        className={`button_clear ${ROOT_CLASS_NAME}__filter-clear`}
                        onClick={onClearStatusFilter}
                        data-testid={`${ROOT_CLASS_NAME}__filter-clear`}
                    >
                        {t('clearSelection')}
                    </TooltipButton>
                    <Button
                        type="compact"
                        onClick={onApplyStatusFilter}
                        className={`${ROOT_CLASS_NAME}__filter-apply`}
                        data-testid={`${ROOT_CLASS_NAME}__filter-apply`}
                    >
                        {t('apply')}
                    </Button>
                </div>
            </DropDownMenu>
        </div>
    );
};
