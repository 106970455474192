import React from 'react';
import { useTranslation } from 'react-i18next';

import { MapViewSelector } from '../MapViewControl/MapViewSelector';

import { useRouteLinesControl } from './useRouteLinesControl';
import './RouteLinesControl.scss';

export const RouteLinesControl = () => {
    const ROOT_CLASS_NAME = 'route-lines-control';
    const { t } = useTranslation('translation');

    const {
        isShowRouteLinesControl,
        showDriverLinesState,
        showDriverActualLinesState,
        handleActualLinesState,
        handlePlannedLinesState
    } = useRouteLinesControl();

    if (!isShowRouteLinesControl) return null;

    return (
        <div
            className={`${ROOT_CLASS_NAME} _p-absolute _d-flex`}
            data-testid={ROOT_CLASS_NAME}
        >
            <MapViewSelector
                isSelected={showDriverLinesState}
                icon="plannedPathSelector"
                onClick={handlePlannedLinesState}
                text={t('plannedPath')}
            />
            <div className={`${ROOT_CLASS_NAME}__divider`} />
            <MapViewSelector
                isSelected={showDriverActualLinesState}
                icon="stopViewSelector"
                onClick={handleActualLinesState}
                text={t('actualPath')}
            />
        </div>
    );
};
