import { useMemo, useState } from 'react';
import { sumBy } from 'lodash';
import { getItemsWithPhotos } from './utils';

/* eslint-disable camelcase */
interface UseItemsWithPhotosProps {
    /**
     * The list of task assignments
     */
    assignments?: {
        /**
         * The assignment name
         */
        name: string;
        /**
         * The list of photo urls
         */
        photoUrls: string[];
    }[];
    /**
     * The list of inventory items
     */
    inventoryItems?: {
        /**
         * The inventory item name
         */
        item_name: string;
        /**
         * The list of photo urls
         */
        photo_urls: string[];
    }[];
}
/* eslint-enable camelcase */

export const useItemsWithPhotos = ({
    assignments = [],
    inventoryItems = []
}: UseItemsWithPhotosProps) => {
    const [selectedItemName, setSelectedItemName] = useState('');
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState('');
    const items = useMemo(
        () => getItemsWithPhotos([...assignments, ...inventoryItems]),
        [assignments, inventoryItems]
    );
    const numberOfPhotos = useMemo(
        () => sumBy(items, ({ photoUrls: { length } }) => length),
        [items]
    );

    return {
        items,
        numberOfPhotos,
        selectedItemName,
        selectedPhotoUrl,
        setSelectedItemName,
        setSelectedPhotoUrl
    };
};
