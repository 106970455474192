import axios from 'axios';
import constants from '~/utils/constants';
import {
    ApiTaskScheduler,
    AxiosApiResponse,
    DriverBreakRulesByStartTime,
    IApiResponse,
    IPostDriverAssignmentsResponse,
    ApiDriver,
    DriverReportResponse
} from './types';

/**
 * POST /driver/<driverId>/assignments payload type
 * @category API
 */
export interface TaskToDispatch {
    /**
     * Id of the task to dispatch
     */
    id: string;
    /**
     * Pickup time of the task to dispatch
     */
    pickupTime?: string | number;
    /**
     * Delivery time of the task to dispatch
     */
    deliveryTime?: string | number;
}

type ResequenceTasksResponseData = {
    id?: string;
};

type DeleteDriverResponseData = {
    id?: string;
};

type AddLiveBreakResponseData = {
    id?: string;
};

type DeleteLiveBreakResponseData = {
    id?: string;
};

type EditLiveBreakResponseData = {
    id?: string;
};
/**
 * Implementations of API methods under the /driver path
 *
 * @category API
 */
export default class DriverApi {
    /**
     * Path of the API endpoint
     */
    private static readonly PATH = '/driver';

    /**
     * Sends a POST /driver request with the given data for the given client to create a new driver.
     *
     * @param {ApiDriver} params data to create a new driver
     * @returns {Promise<AxiosApiResponse<ApiDriver>>}
     */
    static post(params: ApiDriver): Promise<AxiosApiResponse<ApiDriver>> {
        return axios.post<IApiResponse>(this.PATH, params);
    }

    /**
     * Sends a PATCH /driver/<id> request with the given data for the given client.
     *
     * @param {string} driverId the driver to patch
     * @param {ApiDriver} data the patch data
     * @param {string} clientId the target client
     * @returns {Promise<AxiosApiResponse<ApiDriver>>}
     */
    static patch(
        driverId: string,
        data: ApiDriver,
        clientId = ''
    ): Promise<AxiosApiResponse<ApiDriver>> {
        if (!driverId) {
            return Promise.reject('driver id not provided');
        }
        return axios.patch<IApiResponse>(`${this.PATH}/${driverId}`, data, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
        });
    }

    static unassignVehicleFromDriver(
        driverId: string,
        clientId = ''
    ): Promise<AxiosApiResponse> {
        if (!driverId) {
            return Promise.reject('Missing driver Id');
        }
        return axios.patch<IApiResponse>(
            `${this.PATH}/${driverId}/update_default_vehicle`,
            { defaultVehicle: '' },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
            }
        );
    }

    static assignDefaultVehicle(
        driverId: string,
        vehicleId: string,
        clientId = ''
    ): Promise<AxiosApiResponse> {
        if (!driverId || !vehicleId) {
            return Promise.reject('Both driver id and vehicle id are required');
        }
        return axios.patch<IApiResponse>(
            `${this.PATH}/${driverId}/update_default_vehicle`,
            { defaultVehicle: vehicleId },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
            }
        );
    }

    /**
     * Sends a POST /driver/<driverId>/assignments requests to assign the given
     * tasks to the given driver.
     *
     * @param {string} driverId driver to assign tasks to
     * @param {TaskToDispatch[]} tasks ids of tasks to assign to driver
     * @returns {IPostDriverAssignmentsResponse}
     */
    static dispatchTasks(
        driverId: string,
        tasks: TaskToDispatch[],
        position?: number
    ): Promise<AxiosApiResponse<IPostDriverAssignmentsResponse>> {
        if (!driverId) {
            return Promise.reject('driver id not provided');
        }
        let queryString = `${this.PATH}/${driverId}/assignments`;
        if (position) {
            queryString += `?position=${position}`;
        }

        return axios.post<IApiResponse<IPostDriverAssignmentsResponse>>(
            encodeURI(queryString),
            { tasks }
        );
    }

    /**
     * Sends a PATCH /driver/<driverId>/manual_sequence request to resequence the given
     * taskIds for the given driver.
     *
     * @param {string} driverId driver whose tasks should be resequenced
     * @param {Array<string>} taskIds ids of driver tasks to be resequenced
     * @returns {Promise<AxiosApiResponse<ResequenceTasksResponseData>>}
     */
    static resequenceTasks(
        driverId: string,
        taskIds: string[]
    ): Promise<AxiosApiResponse<ResequenceTasksResponseData>> {
        if (!driverId) {
            return Promise.reject('driver id not provided');
        }

        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('taskIds not provided');
        }

        return axios.patch(`${this.PATH}/${driverId}/manual_sequence`, {
            order: taskIds
        });
    }

    /**
     * Sends a PUT request to adjust the driver's schedule (run Manual RTA).
     * @note this request is async. API responds with a success before CEP is done running RTA.
     * @param {string} driverId the driver for which to run RTA
     * @returns {Promise<AxiosApiResponse<ApiTaskScheduler>>}
     */
    static adjustSchedule(
        driverId: string
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!driverId) {
            return Promise.reject('Missing driver Id');
        }
        return axios.put<IApiResponse>(
            `${this.PATH}/${driverId}/assignments?action=adjust_schedule`,
            {}
        );
    }

    /**
     * Sends a DELETE /driver/<driverId> request to delete a driver
     *
     * @param {string} driverId driver to be deleted
     * @returns {Promise<AxiosApiResponse<DeleteDriverResponseData>>}
     */
    static delete(
        driverId: string
    ): Promise<AxiosApiResponse<DeleteDriverResponseData>> {
        if (!driverId) {
            return Promise.reject('Missing driver Id');
        }
        return axios.delete<IApiResponse>(`${this.PATH}/${driverId}`);
    }

    /**
     * Sends a POST /driver/<driverId>/breaks?ruleType=startsAfter request to add a break
     *
     * @param {string} driverId selected driver to add break to
     * @param {object} breakRules object containing the driver break rules
     * @param {number} breakRules.startsAfter start time (in milliseconds since epoch) of the break
     * @param {number} breakRules.duration duration (in seconds) of the break
     * @returns {Promise<AxiosApiResponse<AddLiveBreakResponseData>>}
     */

    static addLiveBreak(
        driverId: string,
        breakRules: DriverBreakRulesByStartTime
    ): Promise<AxiosApiResponse<AddLiveBreakResponseData>> {
        if (!driverId) return Promise.reject('Missing driver Id');
        if (!breakRules) return Promise.reject('Missing break Rules');

        return axios.post(
            `${this.PATH}/${driverId}/breaks?ruleType=startsAfter`,
            { breakRulesByStartTime: [breakRules] }
        );
    }

    /**
     * Sends a DELETE /driver/<driverId>/breaks request to delete a live break
     *
     * @param {string} driverId selected driver to delete break from
     * @param {<string>}  assignmentId id of the assignment after the break to be deleted.
     * @returns {Promise<AxiosApiResponse<DeleteLiveBreakResponseData>>}
     */
    static deleteLiveBreak(
        driverId: string,
        assignmentId: string
    ): Promise<AxiosApiResponse<DeleteLiveBreakResponseData>> {
        if (!driverId) {
            return Promise.reject('Missing driver Id');
        }
        if (!assignmentId) {
            return Promise.reject('Missing assignment Id');
        }
        return axios.delete<IApiResponse>(`${this.PATH}/${driverId}/breaks`, {
            data: { assignmentIds: [assignmentId] }
        });
    }

    /**
     * Sends a GET /driver/{driverId}/report/{date} to get driver report
     * @param {string} driverId selected driver Id
     * @param {string} date selected date
     */
    static getDriverReport(
        date: string,
        driverId: string
    ): Promise<AxiosApiResponse<DriverReportResponse>> {
        if (!driverId)
            return Promise.reject('Required missing field: Driver Id');
        if (!date) return Promise.reject('Required missing field: Date');

        return axios.get(`${this.PATH}/${driverId}/report/${date}`);
    }

    /**
     * Sends a GET /driver/{driverId}/route to get the driver route
     * @param {string} driverId selected driver Id
     */
    static getDriverRoute(driverId: string): Promise<AxiosApiResponse> {
        if (!driverId)
            return Promise.reject('Required missing field: Driver Id');

        return axios.get(`${this.PATH}/${driverId}/route`);
    }

    /**
     * Sends a PATCH /driver/<driverId>/breaks request to edit a live break
     *
     * @param {string} driverId selected driver to edit break from
     * @param {<string>}  assignmentId id of the assignment after the break to be edited.
     * @param {number} startsAfter start time (in milliseconds since epoch) of the break
     * @param {number} duration duration (in seconds) of the break
     * @returns {Promise<AxiosApiResponse<EditLiveBreakResponseData>>}
     */
    static editLiveBreak(
        driverId: string,
        assignmentId: string,
        startsAfter: number,
        duration: number
    ): Promise<AxiosApiResponse<EditLiveBreakResponseData>> {
        if (!driverId) {
            return Promise.reject('Missing driver Id');
        }
        if (!assignmentId) {
            return Promise.reject('Missing assignment Id');
        }
        if (!startsAfter) {
            return Promise.reject('Missing break start time');
        }
        if (!duration) {
            return Promise.reject('Missing break duration');
        }
        return axios.patch(
            `${this.PATH}/${driverId}/breaks?ruleType=startsAfter`,
            {
                assignmentId,
                startsAfter,
                duration
            }
        );
    }
}
