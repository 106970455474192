import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { useDispatch } from 'react-redux';
import { updateEquipmentById } from '~/reducers/equipmentSlice';
import { ServerBaseUrlKey, getURL } from '~/servers/servers';
import { useFeatureFlag, useMapUtils } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { SocketEquipmentUpdate } from '~/api/types';

export const EquipmentSocketProvider = () => {
    const { isDispatchedRouteMode } = useMapUtils();

    const equipmentSocketUrl =
        getURL(ServerBaseUrlKey.EQUIPMENT_SOCKET) || null;

    const isEnabledLiveDispatchUi = useFeatureFlag(
        FeatureFlag.LIVE_DISPATCH_UI
    );

    const shouldConnect =
        isDispatchedRouteMode &&
        isEnabledLiveDispatchUi &&
        Boolean(equipmentSocketUrl);

    const equipmentSocketOptions = {
        share: false,
        shouldReconnect: () => true
    };

    const { lastJsonMessage: equipmentSocketMessage } = useWebSocket(
        equipmentSocketUrl,
        equipmentSocketOptions,
        shouldConnect
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (equipmentSocketMessage && Array.isArray(equipmentSocketMessage)) {
            (equipmentSocketMessage as SocketEquipmentUpdate[]).forEach(
                (equipmentUpdate) => {
                    const { id } = equipmentUpdate || {};
                    if (!id) return;
                    const payload = { id, value: equipmentUpdate };
                    dispatch(updateEquipmentById(payload));
                }
            );
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [equipmentSocketMessage]);

    return null;
};
