import React from 'react';
import classNames from 'classnames';
import { Icon } from '~/ui';

export interface HandleChangeProps {
    isChecked: boolean;
    value?: string | number | unknown;
    selectedValues?: Array<string | number | unknown>;
}

interface CheckboxProps {
    id?: string;
    name?: string;
    value?: string | number;
    selectedValues?: Array<string | number>;
    disabled?: boolean;
    className?: string;
    handleChange?: ({
        isChecked,
        value,
        selectedValues
    }: HandleChangeProps) => void;
    children?: React.ReactNode;
    'data-testid'?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    function Checkbox(
        {
            id = '',
            name = '',
            value = '',
            selectedValues = [],
            disabled = false,
            children,
            className = '',
            handleChange,
            ...extra
        },
        ref
    ) {
        const isChecked = selectedValues.includes(value);

        function handleCheckboxChange() {
            if (disabled) {
                return;
            }
            const updatedValues = [...selectedValues];
            const index = updatedValues.indexOf(value);

            if (index !== -1) {
                updatedValues.splice(index, 1);
            } else {
                updatedValues.push(value);
            }

            if (handleChange) {
                handleChange({
                    isChecked: updatedValues.includes(value),
                    value,
                    selectedValues: updatedValues
                });
            }
        }

        const getClassName = () => {
            const defaultClassName = 'checkbox';
            const conditionalClasses = {
                checkbox_checked: isChecked,
                checkbox_disabled: disabled
            };
            return classNames(defaultClassName, conditionalClasses, className);
        };

        return (
            <label htmlFor={id} className={getClassName()}>
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    value={value}
                    ref={ref}
                    checked={isChecked}
                    disabled={disabled}
                    onChange={handleCheckboxChange}
                    hidden
                    data-testid={extra['data-testid'] || 'checkbox-input'}
                />
                <div className="checkbox-box">
                    <Icon icon="checkmark" size="xs" color="white" />
                </div>
                {children}
            </label>
        );
    }
);

export default Checkbox;
