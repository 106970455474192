import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    resetSelectedTaskRowId,
    selectSelectedTaskRowId
} from '~/reducers/selectedTaskRowIdSlice';

import { useHereMaps, useMapUtils } from '~/hooks';

import { selectIsAddBreakModalOpen } from '~/reducers/driverBreaksModalSlice';

import { AddTaskModal } from '~/components/AddTaskModal/AddTaskModal';
import { LiveDispatchAssignDriverPopup } from '~/components/MapPageDrawers/LiveDispatchDrawer/LiveDispatchAssignDriverPopup';
import { LiveDispatchTableContextProvider } from '~/components/MapPageDrawers/LiveDispatchDrawer/LiveDispatchTableContext';
import { EquipmentTableContextProvider } from '~/components/MapPageDrawers/LiveDispatchDrawer/EquipmentTableContext';
import { PlanStopsControl } from '~/components/MapPage/PlanStopsControl';
import LiveDispatchDrawer from '~/components/MapPageDrawers/LiveDispatchDrawer/LiveDispatchDrawer';
import LiveStopsControl from '~/components/MapPage/LiveStopsControl/LiveStopsControl';
import TaskDetailDrawer from '~/components/TaskManagementPage/TaskDetailDrawer/TaskDetailDrawer';
import UnassignedTasksControl from '~/components/MapPage/UnassignedTasksControl';

import { AddBreakModal } from './AddBreakModal/AddPlanBreakModal';
import { AddLiveBreakModal } from './AddBreakModal/AddLiveBreakModal';
import { DriverBreakModal } from './DriverBreakModal/DriverPlanBreakModal';
import { DriverLiveBreakModal } from './DriverBreakModal/DriverLiveBreakModal';
import { MapSettingsControl } from './MapSettingsControl';
import { MapViewControl } from './MapViewControl';
import OnDemandDispatchMapControl from './OnDemandDispatchMapControl/OnDemandDispatchMapControl';
import ZoomControl from './ZoomControl';
import { ZoomControl as HereMapsZoomControl } from '~/components/HereMaps/ui/ZoomControl';
import { LiveDispatchPopup } from '~/components/MapPageDrawers/LiveDispatchDrawer/LiveDispatchPopup';
import { RouteLinesControl } from './RouteLinesControl';

import './PlanMapOverlay.scss';
import { AddOperationalStopModal } from '~/components/AddOperationalStopModal/AddOperationalStopModal';

function PlanMapOverlay({ editPermissions, map }) {
    const [showControls, setShowControls] = useState(false);
    const [showPlanStopsControl, setShowPlanStopsControl] = useState(false);
    const [showOnDemandDispatchControl, setShowOnDemandDispatchControl] =
        useState(false);
    const selectedTaskRowId = useSelector(selectSelectedTaskRowId);
    const showAddBreaksModal = useSelector(selectIsAddBreakModalOpen);
    const dispatch = useDispatch();
    const { isDispatchedRouteMode, isPlanRouteMode } = useMapUtils();
    const { isHerePlanMapActive } = useHereMaps();

    useEffect(() => {
        return map !== null ? setShowControls(true) : setShowControls(false);
    }, [map]);

    useEffect(() => {
        setShowPlanStopsControl(editPermissions && isPlanRouteMode);
        setShowOnDemandDispatchControl(
            editPermissions && isDispatchedRouteMode
        );
    }, [editPermissions, isPlanRouteMode, isDispatchedRouteMode]);

    function handleTaskDetailDrawerClose() {
        dispatch(resetSelectedTaskRowId());
    }

    return (
        <>
            <div className="planmapoverlay planmapoverlay_mapcontrols _d-flex _ai-center _fd-column _p-absolute">
                <OnDemandDispatchMapControl />
                {!isHerePlanMapActive && (
                    <ZoomControl map={map} showControls={showControls} />
                )}
                {isHerePlanMapActive && (
                    <HereMapsZoomControl showControls={showControls} />
                )}
                <MapSettingsControl />
            </div>

            <MapViewControl />
            <RouteLinesControl />

            <div className="planmapoverlay planmapoverlay_stopcontrols _d-flex _p-absolute">
                {showPlanStopsControl && <PlanStopsControl />}
                {showOnDemandDispatchControl && (
                    <>
                        <LiveStopsControl />
                        <UnassignedTasksControl />
                    </>
                )}
                {showAddBreaksModal && isPlanRouteMode && <AddBreakModal />}
                {showAddBreaksModal && isDispatchedRouteMode && (
                    <AddLiveBreakModal />
                )}
                <DriverBreakModal />
                <DriverLiveBreakModal />
            </div>

            <div className="planmapoverlay planmapoverlay_livedispatch _d-flex _p-absolute">
                <LiveDispatchTableContextProvider>
                    <EquipmentTableContextProvider>
                        <LiveDispatchDrawer />
                    </EquipmentTableContextProvider>
                </LiveDispatchTableContextProvider>
            </div>

            <div className="planmapoverlay planmapoverlay_dispatch-management-popup _d-flex _p-absolute">
                <LiveDispatchAssignDriverPopup />
            </div>

            <div className="planmapoverlay planmapoverlay__live-dispatch-popup _d-flex _p-absolute">
                <LiveDispatchTableContextProvider>
                    <EquipmentTableContextProvider>
                        <LiveDispatchPopup />
                    </EquipmentTableContextProvider>
                </LiveDispatchTableContextProvider>
            </div>

            {selectedTaskRowId && (
                <TaskDetailDrawer
                    shouldRenderMapPageStyle
                    id={selectedTaskRowId}
                    handleTaskDetailDrawerClose={handleTaskDetailDrawerClose}
                />
            )}

            <AddTaskModal />
            <AddOperationalStopModal />
        </>
    );
}

export default PlanMapOverlay;
