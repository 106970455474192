import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { IconName } from '~/ui/components/Icon/IconNames';
import {
    getAssignmentCodeLabel,
    getDelayCodeLabel
} from '~/utils/assignment-utils';

import { selectMainClient } from '~/reducers/mainClientSlice';

import { RouteCardSummary, Tooltip, UIElement } from '~/ui';

import { LiveStop } from '~/data-classes';
import { TaskTypes } from '~/api/types';
import utils from './utils';

import { useFeatureFlag } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import './RouteCardStatusBar.scss';

const arrowPropStyles = {
    position: 'absolute',
    bottom: '-0.7rem',
    left: '50%',
    width: '0',
    height: '10',
    borderLeft: '0.8rem solid transparent',
    borderRight: '0.8rem solid transparent',
    borderTop: '1rem solid var(--color-galaxy-700)'
};

interface RouteCardStatusBarProps extends UIElement {
    numStops: number;
    currentStopIndex: number;
    schedule?: LiveStop[];
    timeRemaining?: number;
    driverOffline?: boolean;
    isScheduleCompleted?: boolean;
    lastLocationUpdate: string;
}

interface RouteCardStatusBarGradientBand {
    color: string;
    start: number;
    end: number;
}

interface RouteCardStatusBarGradientProps {
    gradient: RouteCardStatusBarGradientBand[];
    position: number | null;
    statusColor: string | null;
}

interface RouteCardStatusBarGradientStyle {
    background: string;
}

interface RouteCardStatusBarPositionStyle {
    left: string;
    backgroundColor: string;
}

interface RouteCardStatusBarMetric {
    metric: string;
    value: string | number;
    maxValue?: number;
    icon?: IconName;
    iconColor?: string;
}

function RouteCardStatusBar({
    className,
    numStops,
    currentStopIndex,
    schedule = [],
    timeRemaining,
    driverOffline = true,
    isScheduleCompleted = false,
    lastLocationUpdate
}: RouteCardStatusBarProps) {
    const [currentStopIsDepot, setCurrentStopIsDepot] = useState(false);
    const [currentStopNumber, setCurrentStopNumber] = useState(0);
    const [currentStopType, setCurrentStopType] =
        useState<TaskTypes | null>(null);
    const [currentStopFinish, setCurrentStopFinish] =
        useState<string | null>(null);
    const [estimatedFinish, setEstimatedFinish] = useState<string | null>(null);
    const [routeStatusGradient, setRouteStatusGradient] =
        useState<RouteCardStatusBarGradientStyle | null>(null);
    const [routeStatusPosition, setRouteStatusPosition] =
        useState<RouteCardStatusBarPositionStyle | null>(null);
    const [statusMetrics, setStatusMetrics] = useState<
        RouteCardStatusBarMetric[]
    >([]);
    const { t } = useTranslation('translation');

    const mainClient = useSelector(selectMainClient);
    const { timezone } = mainClient || {};

    const isClientTimezoneFlagEnabled = useFeatureFlag(
        FeatureFlag.CLIENT_TIMEZONE
    );

    const completedTasksCount = utils.getCompletedTasksCount(schedule);

    const driverLastLocationUpdate =
        utils.getLastLocationUpdate(lastLocationUpdate);

    useEffect(() => {
        setCurrentStopType(
            schedule &&
                schedule[currentStopIndex] &&
                schedule[currentStopIndex].type
        );
        setCurrentStopIsDepot(
            schedule &&
                schedule[currentStopIndex] &&
                schedule[currentStopIndex].isDepot
        );
    }, [schedule, currentStopIndex]);

    useEffect(() => {
        const current =
            (isScheduleCompleted && numStops) ||
            Math.min(currentStopIndex, numStops);
        setCurrentStopNumber(current);
    }, [isScheduleCompleted, numStops, currentStopIndex]);

    useEffect(() => {
        const currentSchedule = schedule[currentStopNumber];
        if (!currentSchedule) return;

        const { completedAt } = currentSchedule;
        setCurrentStopFinish(DateTime.fromISO(completedAt).toFormat('t'));
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [currentStopNumber]);

    function reduceSchedule(
        compiled: RouteCardStatusBarGradientProps,
        item: LiveStop,
        idx: number,
        stops: LiveStop[]
    ) {
        const stopsLength = stops.length;
        const currentStop = stops.findIndex(
            (stop) => !stop.isCompleted && !stop.isCanceled
        );
        const { gradient, position, statusColor } = compiled;
        const {
            status: assignmentCode,
            delay: delayCode,
            isCanceled,
            isCompleted
        } = item;
        const currentGradient = [...gradient];
        const start = (idx / stopsLength) * 100;
        const end = ((idx + 1) / stopsLength) * 100;
        const isStopCompleted = isCompleted || isCanceled;

        const delayStatus = getDelayCodeLabel(delayCode);
        const assignmentStatus = getAssignmentCodeLabel(assignmentCode);

        const color =
            (isCanceled && `--color-status-${assignmentStatus}`) ||
            (isStopCompleted && `--color-status-${delayStatus}-completed`) ||
            `--color-status-${delayStatus}`;
        const currentBand = {
            color,
            start,
            end
        };
        const previousBand = currentGradient.pop();

        // consolidate consecutive same gradient color bands
        if (previousBand && previousBand.color === currentBand.color) {
            currentBand.start = previousBand.start;
        } else if (previousBand) currentGradient.push(previousBand);

        // update gradient
        currentGradient.push(currentBand);

        return {
            gradient: currentGradient,
            position:
                (currentStop < 0 && 100) ||
                (idx === currentStop && start) ||
                position,
            statusColor:
                (driverOffline && '--color-driver-offline') ||
                (currentStop < 0 && '--color-assignment-COMPLETED') ||
                (idx === currentStop &&
                    `--color-assignment-${assignmentStatus}`) ||
                statusColor
        };
    }

    useEffect(() => {
        if (
            !schedule ||
            schedule.length === 0 ||
            (currentStopIsDepot && !isScheduleCompleted)
        ) {
            setRouteStatusGradient(null);
            setRouteStatusPosition(null);
            return;
        }

        // set status bar gradient and marker
        const statusBar = schedule
            .filter((item) => {
                return !item.isDepot;
            })
            .reduce(reduceSchedule, {
                gradient: [],
                position: null,
                statusColor: null
            }) as RouteCardStatusBarGradientProps;

        // flatten gradient
        const gradientBands = statusBar.gradient
            .map((band) => {
                const { color, start, end } = band;
                return `var(${color}) ${start}%, var(${color}) ${end}%`;
            })
            .join(', ');

        setRouteStatusGradient({
            background: `linear-gradient(90deg, ${gradientBands})`
        });
        setRouteStatusPosition({
            left: `${statusBar.position}%`,
            backgroundColor: `var(${statusBar.statusColor})`
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [schedule, currentStopIsDepot, isScheduleCompleted]);

    useEffect(() => {
        const metrics = [
            {
                metric: 'stops',
                value: `${t('valueOfMaxValueForStops', {
                    value: completedTasksCount,
                    maxValue: numStops
                })}`
            }
        ] as RouteCardStatusBarMetric[];

        if (currentStopIsDepot && !isScheduleCompleted) {
            const depotStatus =
                currentStopNumber === numStops
                    ? t('returningToDepot')
                    : t('atDepot');
            metrics.push({
                metric: 'depot',
                value: depotStatus
            });
        } else {
            const scheduleStatus = isScheduleCompleted
                ? t('completedTime', { time: currentStopFinish })
                : t('finishTime', {
                      time: estimatedFinish
                  });
            metrics.push({
                metric: 'time-remaining',
                value: scheduleStatus
            });
        }

        setStatusMetrics(metrics);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        currentStopIsDepot,
        currentStopNumber,
        isScheduleCompleted,
        estimatedFinish,
        currentStopFinish,
        numStops
    ]);

    useEffect(() => {
        const lastScheduledStop = schedule[schedule.length - 1];

        if (!timeRemaining || !lastScheduledStop) {
            setEstimatedFinish(null);
            return;
        }

        const { isLastStopAEndDepot, formattedTime } = utils.getEndDepotETA({
            lastScheduledStop,
            isClientTimezoneFlagEnabled
        });

        const formattedEstimatedFinishTime = utils.calculateFinishTime({
            timeRemaining,
            isClientTimezoneFlagEnabled,
            timezone
        });

        setEstimatedFinish(
            isLastStopAEndDepot ? formattedTime : formattedEstimatedFinishTime
        );
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [timeRemaining, schedule, isClientTimezoneFlagEnabled]);

    function getClassName() {
        const defaultClassName = 'routecard-statusbar';
        return classNames(defaultClassName, className);
    }

    return (
        <div className={getClassName()} data-testid="routeCardStatusBar">
            <RouteCardSummary
                className="_jc-space-between"
                metrics={statusMetrics}
            />
            <div
                className="gradient _p-relative"
                style={routeStatusGradient as RouteCardStatusBarGradientStyle}
            >
                <Tooltip
                    placement="top"
                    arrowProps={{ style: arrowPropStyles }}
                    content={t('lastLocationUpdate', {
                        count: driverLastLocationUpdate
                    })}
                >
                    <div
                        className={`gradient_pointer _d-flex _ai-center _jc-center _p-absolute pointer_${currentStopType}`}
                        style={
                            routeStatusPosition as RouteCardStatusBarPositionStyle
                        }
                        data-offline={driverOffline || null}
                    />
                </Tooltip>
            </div>
        </div>
    );
}

export default RouteCardStatusBar;
