import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUploadZoneFile } from '~/hooks';

import { Icon, useModal, ButtonLink } from '~/ui';
import { usePageContext } from '../PageContext';

import './UploadZoneModal.scss';

const className = 'upload-zone-modal';
const uploadFileInputFieldId = `${className}__input`;

const UploadZoneModal = (): JSX.Element => {
    const { isOpenUploadZoneModal, setIsOpenUploadZoneModal } =
        usePageContext();

    const { t } = useTranslation(['zoneManagement']);
    const { handleDrop, handleDragOver, handleFilesSelected } =
        useUploadZoneFile();

    const {
        showModal,
        hideModal,
        modal: Modal
    } = useModal({
        onShow: () => undefined,
        onHide: () => {
            setIsOpenUploadZoneModal(false);
        }
    });

    useEffect(() => {
        if (isOpenUploadZoneModal) {
            showModal();
        } else {
            hideModal();
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isOpenUploadZoneModal]);

    return (
        <Modal
            variant="small"
            title={t('uploadZoneModal.title')}
            hasAutoHide={false}
            hasCloseButton
            className={className}
            overlayClassName={`${className}__overlay`}
        >
            <div
                className={`${className}__wrapper _jc-center _ai-center _fd-column`}
            >
                <div
                    className="uploader-section _fd-column _ai-center _jc-center"
                    data-testid="uploader-section"
                    onDrop={(e) => {
                        handleDrop(e);
                        hideModal();
                    }}
                    onDragOver={handleDragOver}
                >
                    <span className="supported-text _fd-column _ai-center _jc-center">
                        <Icon
                            data-testid="upload-icon"
                            size="xxl"
                            icon="importIcon"
                            color="galaxy-500"
                            className={`${className}__icon`}
                        />
                        {t('uploadZoneModal.supportedFileTypes')}
                    </span>
                    <span className="uploader-text">
                        {t('uploadZoneModal.dragFiles')}
                        <label
                            htmlFor={uploadFileInputFieldId}
                            data-testid="file-input-label"
                            className="buttonlink"
                        >
                            {t('uploadZoneModal.clickToBrowse')}
                            <input
                                id={uploadFileInputFieldId}
                                type="file"
                                accept=".json, .geojson, .txt"
                                onChange={(e) => {
                                    handleFilesSelected(e);
                                    hideModal();
                                }}
                                data-testid="file-input"
                                hidden
                            />
                        </label>
                    </span>
                </div>
                <span className={`${className}__downloadlink _d-flex`}>
                    <Icon icon="downloadLine" size="m" color="ocean" />
                    <ButtonLink className="button-link">
                        {t('uploadZoneModal.downloadTemplateHint')}
                    </ButtonLink>
                </span>
            </div>
        </Modal>
    );
};

export default UploadZoneModal;
