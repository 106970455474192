import { DateTime } from 'luxon';
import { LiveStop } from '~/data-classes';
import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';
import dateUtilsConverters from '~/utils/date-utils-converters';

const {
    depots: { END_DEPOT }
} = constants;

function getLastLocationUpdate(driverLastLocationUpdate: string) {
    const lastLocationDate = DateTime.fromISO(driverLastLocationUpdate).diffNow(
        ['minutes']
    ).minutes;

    if (Number.isNaN(lastLocationDate)) {
        return 0;
    }

    return Math.round(Math.abs(lastLocationDate));
}

function calculateFinishTime({
    timeRemaining,
    isClientTimezoneFlagEnabled,
    timezone
}: {
    timeRemaining: number;
    isClientTimezoneFlagEnabled?: boolean;
    timezone?: string;
}) {
    const timeRemainingDuration =
        dateUtilsConverters.convertMillisecondsToHoursAndMinutesAndSeconds(
            timeRemaining
        );

    const dateTime = DateTime.local().plus(timeRemainingDuration);
    const formattedEstimatedFinishTime = isClientTimezoneFlagEnabled
        ? dateTime.toFormat('t')
        : dateTime.setZone(timezone).toFormat('h:mm a');
    return formattedEstimatedFinishTime;
}

function getEndDepotETA({
    lastScheduledStop,
    isClientTimezoneFlagEnabled
}: {
    lastScheduledStop: LiveStop;
    isClientTimezoneFlagEnabled?: boolean;
}) {
    const { arrivalTime, eta, stopName } = lastScheduledStop;
    const isLastStopAEndDepot = stopName === END_DEPOT;
    const formattedTime = dateUtils.getLocalizedTime(
        isClientTimezoneFlagEnabled ? arrivalTime : eta
    );
    return { isLastStopAEndDepot, formattedTime };
}

function getCompletedTasksCount(schedule: LiveStop[]): number {
    return schedule.reduce(
        (count, stop) =>
            stop.isCompleted && !stop.isDepot ? count + 1 : count,
        0
    );
}

export default {
    getLastLocationUpdate,
    calculateFinishTime,
    getEndDepotETA,
    getCompletedTasksCount
};
