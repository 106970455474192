/* global H */
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import { HereMapsConstants } from './HereMapsPlatform';
import { useMapEngineContext } from '~/components/MapEngineProvider';

import './HereMapProvider.scss';

const HereMapContext = createContext({
    mapInstance: null,
    showPopup: () => {},
    removePopup: () => {}
});

export const useHereMapContext = () => useContext(HereMapContext);

export const HereMapProvider = ({ children, mapId, mapOptions = {} }) => {
    const HereMapsPlatform = new H.service.Platform({
        apikey: HereMapsConstants.apiKey
    });
    const defaultLayers = HereMapsPlatform.createDefaultLayers();
    const { setMapInstance: setHereMapsInstance } = useMapEngineContext();

    const [mapInstance, setMapInstance] = useState(null);
    const [resizeObserver, setResizeObserver] = useState(null);
    const [popup, setPopup] = useState(null);
    const [popupCoordinates, setPopupCoordinates] = useState(null);

    const showPopup = useCallback((popupComponent, coordinates) => {
        setPopup(popupComponent);
        setPopupCoordinates({
            left: coordinates.viewportX,
            top: coordinates.viewportY
        });
    }, []);

    const removePopup = useCallback(() => {
        setPopup(null);
        setPopupCoordinates(null);
    }, []);

    const resizeMap = (map) => {
        return function triggerResize() {
            if (!map) {
                return;
            }
            try {
                map.getViewPort().resize();
            } catch (e) {
                console.error('Error resizing map', e);
            }
        };
    };

    useEffect(() => {
        const map = new H.Map(
            document.getElementById(mapId),
            defaultLayers.vector.normal.map,
            {
                zoom: HereMapsConstants.defaultZoom,
                center: HereMapsConstants.defaultCenter,
                ...mapOptions
            }
        );
        const mapEvents = new H.mapevents.MapEvents(map);
        (() => {
            return new H.mapevents.Behavior(mapEvents);
        })();
        map.getViewPort().resize();
        const container = document.getElementById(mapId);
        window.addEventListener('resize', resizeMap(map));
        const containerResizeObserver = new ResizeObserver(
            resizeMap(map)
        ).observe(container);
        setMapInstance(map);
        setHereMapsInstance(map);
        setResizeObserver(containerResizeObserver);
        return () => {
            if (!map) {
                return;
            }
            map.dispose();
            window.removeEventListener('resize', resizeMap);
            if (resizeObserver) {
                resizeObserver.unobserve(container);
            }
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <HereMapContext.Provider
            value={{
                mapInstance,
                showPopup,
                removePopup
            }}
        >
            <div className="here-map-wrapper">
                <div
                    id={mapId}
                    className="here-map-container"
                    data-testid="here-map-wrapper"
                />
                {children}
                {popup && popupCoordinates && (
                    <div
                        id={`${mapId}-overlay`}
                        className="here-map-popup-overlay"
                        style={{
                            left: popupCoordinates.left,
                            top: popupCoordinates.top
                        }}
                    >
                        {popup}
                    </div>
                )}
            </div>
        </HereMapContext.Provider>
    );
};
