import { useSelector } from 'react-redux';
import {
    selectMapMarkerMode,
    selectMapRouteMode
} from '~/reducers/mapSettingsSlice';

/**
 * Expected hook returns from `useDownloadDriverVehicle`
 */
type HookMapUtils = {
    isStopsClustersMode: boolean;
    isPlanRouteMode: boolean;
    isPreRoutePlanningMode: boolean;
    isOnDemandDispatchMode: boolean;
    isLiveRouteMode: boolean;
    isDispatchedRouteMode: boolean;
    isCompletedRouteMode: boolean;
    mapMarkerMode: MapMarkerModes;
    mapRouteMode: MapRouteModes;
    isRoutesMode: boolean;
    isEmptyRouteMode: boolean;
};

/**
 * Enum of map route modes
 */
export enum MapRouteModes {
    EMPTY = 'empty',
    PLAN = 'plan',
    DISPATCHED = 'dispatched',
    COMPLETED = 'completed'
}

/**
 * Enum of map marker modes
 */
export enum MapMarkerModes {
    STOPS_CLUSTERS = 'stops-clusters',
    ROUTES = 'routes'
}

export const useMapUtils = (): HookMapUtils => {
    const mapMarkerMode = useSelector(selectMapMarkerMode) as MapMarkerModes;
    const mapRouteMode = useSelector(selectMapRouteMode) as MapRouteModes;

    const isStopsClustersMode = mapMarkerMode === MapMarkerModes.STOPS_CLUSTERS;

    const isRoutesMode = mapMarkerMode === MapMarkerModes.ROUTES;

    const isPreRoutePlanningMode = [
        MapRouteModes.PLAN,
        MapRouteModes.EMPTY
    ].includes(mapRouteMode);

    const isOnDemandDispatchMode = [
        MapRouteModes.PLAN,
        MapRouteModes.DISPATCHED
    ].includes(mapRouteMode);

    const isLiveRouteMode = [
        MapRouteModes.COMPLETED,
        MapRouteModes.DISPATCHED
    ].includes(mapRouteMode);

    const isEmptyRouteMode = mapRouteMode === MapRouteModes.EMPTY;
    const isPlanRouteMode = mapRouteMode === MapRouteModes.PLAN;
    const isDispatchedRouteMode = mapRouteMode === MapRouteModes.DISPATCHED;
    const isCompletedRouteMode = mapRouteMode === MapRouteModes.COMPLETED;

    return {
        isStopsClustersMode,
        isPlanRouteMode,
        isPreRoutePlanningMode,
        isOnDemandDispatchMode,
        isLiveRouteMode,
        isDispatchedRouteMode,
        isCompletedRouteMode,
        mapMarkerMode,
        mapRouteMode,
        isRoutesMode,
        isEmptyRouteMode
    };
};
