import { UIElementWithChildren } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSize, IconSizes } from '~/ui/components/Icon/IconSizes';

/**
 * An enum of available pre-defined modal sizes
 */
export enum ModalSize {
    SMALL = 'small',
    LARGE = 'large',
    DIALOGUE = 'dialogue',
    SMALL_DIALOGUE = 'small-dialogue',
    DATE = 'date'
}

/**
 * A union type of `ModalSizes`
 *
 * provided for backward-compatibility with existing implementations
 */
export type ModalSizes = `${ModalSize}`;

export interface ModalProps extends UIElementWithChildren {
    /**
     * The modal title
     */
    title?: string;

    /**
     * The modal size variant
     */
    variant?: ModalSize | ModalSizes;

    /**
     * The custom class name to attach to the overlay element
     */
    overlayClassName?: string;

    /**
     * The icon to prefix the modal header title
     */
    icon?: IconName;

    /**
     * The icon color
     */
    iconColor?: string;

    /**
     * The icon size
     */
    iconSize?: IconSize | IconSizes;

    /**
     * Whether the modal uses a custom header
     *
     * When this property is `true`, the component will just render the `children` prop.
     * The `children` prop will be responsible for implementing the modal header, body, and footer.
     */
    hasCustomHeader?: boolean;

    /**
     * Whether the modal will auto-hide when the user clicks outside the modal boundaries
     */
    hasAutoHide?: boolean;

    /**
     * Whether the modal will include a close button in the modal header
     *
     * When this property is `false`, the only ways the component can `close` is either when the user clicks
     * outside the component and/or when the modal content offers an alternative `close` component
     */
    hasCloseButton?: boolean;

    /**
     *
     */
    onHide?: () => void;
}
