import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsOpenPairEquipmentControlPopup } from '~/reducers/mapDrawerSettingsSlice';
import { PairEquipmentControl } from '../PairEquipmentControl';
import './LiveDispatchPopup.scss';

export const LiveDispatchPopup = () => {
    const isPopupDisplayed = useSelector(selectIsOpenPairEquipmentControlPopup);

    if (!isPopupDisplayed) {
        return null;
    }
    return (
        <div
            className="live-dispatch-popup _d-flex _fd-column"
            data-testid="live-dispatch-popup"
        >
            <PairEquipmentControl />
        </div>
    );
};
