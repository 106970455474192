import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import tasksApi from '~/api/TasksApi';
import taskAssignmentsApi from '~/api/CourierTaskAssignmentsApi';
import courierSchedulesApi from '~/api/CourierSchedulesApi';
import { selectDate } from '~/reducers/selectedDateSlice';
import { selectActiveClients } from '~/reducers/activeClientsSlice';
import constants from '~/utils/constants';
import { setIsSchedulerRunning } from '~/reducers/schedulerProgressSlice';
import useToastContext from './toastContext';

/**
 * Maintains state and effects related to re running the scheduler
 *
 * This includes (un)locking/(un)closing tasks when the optimization choice changes
 */
export function useRunScheduler() {
    const dispatch = useDispatch();
    const selectedDate = useSelector(selectDate);
    const driverGroups = useSelector(selectActiveClients);

    const { t } = useTranslation('routePlanner');
    const { addToast } = useToastContext();

    function dispatchRoutesScheduling(routesScheduling) {
        dispatch(setIsSchedulerRunning(routesScheduling));
    }

    const catchAction = (e) => {
        const { data: { code, message } = {} } = e.response || {};
        const errorMessage = message ?? e;

        console.error(e);

        let toastMessage;
        if (code === constants.cepErrorCodes.CLIENT_PREFERENCES_CONSTRAINT) {
            toastMessage = t('cep.clientPreferencesConstraint');
        } else {
            toastMessage = errorMessage;
        }

        addToast({
            message: t('error:schedulerRunningError', {
                message: toastMessage
            }),
            variant: 'error',
            isPersisted: true
        });

        dispatchRoutesScheduling(false);
    };

    const runScheduler = useCallback(
        async (selectedOptimizationChoice = null) => {
            const schedulerPromises = [];
            try {
                const isClosedRoutes =
                    selectedOptimizationChoice ===
                    constants.schedulerKeys.closeAllRoutes;
                const rerun =
                    selectedOptimizationChoice ===
                        constants.schedulerKeys.closeAllRoutes ||
                    selectedOptimizationChoice ===
                        constants.schedulerKeys.lockRoutes;
                const schedulerConfig = {
                    date: selectedDate,
                    rerun,
                    createNewRoutes: isClosedRoutes
                };

                dispatchRoutesScheduling(true);

                // driverGroups includes the main client
                if (Object.keys(driverGroups).length === 1) {
                    await tasksApi.startScheduler(schedulerConfig);
                    return;
                }
                const taskAssignerConfig = {
                    date: selectedDate,
                    close_routes: isClosedRoutes
                };

                const courierSchedulerConfig = { close_routes: isClosedRoutes };
                const taskAssignmentResponse = await taskAssignmentsApi.post(
                    taskAssignerConfig
                );
                const taskAssignerId = taskAssignmentResponse.data.data.runId;
                courierSchedulerConfig.taskAssignerId = taskAssignerId;

                setTimeout(async () => {
                    for (const driverGroupId of Object.keys(driverGroups)) {
                        courierSchedulerConfig.driverGroupId = driverGroupId;
                        schedulerPromises.push(
                            courierSchedulesApi.post(courierSchedulerConfig)
                        );
                    }
                    try {
                        await Promise.all(schedulerPromises);
                    } catch (e) {
                        catchAction(e);
                    }
                }, constants.timings.COURIER_SCHEDULER_INTERVAL);
            } catch (e) {
                catchAction(e);
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [driverGroups, selectedDate]
    );

    return { runScheduler };
}
