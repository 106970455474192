import React, { useState } from 'react';
import PT from 'prop-types';
import { Icon, Text } from '~/ui';
import Checkbox from '../Checkbox/Checkbox';
import './multi-select.scss';

function MultiSelect({ items, placeholder = '', selected = [], onItemChange }) {
    const [isListOpen, setIsListOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState(selected);

    function showSelectedItems() {
        if (selectedItems.length === 0) {
            return (
                <Text
                    variant="14-normal"
                    className="multiselect_placeholder"
                    onClick={_toggleList}
                >
                    {placeholder}
                </Text>
            );
        }
        return (
            <div className="_d-flex _ai-center _fw-wrap multiselect_selected">
                {items.map((item) => {
                    if (selectedItems.includes(item.key)) {
                        return (
                            <div
                                key={item.key}
                                className="_d-flex _ai-center multiselect_selected-item"
                            >
                                <Text variant="14-normal">{item.value}</Text>
                                <Icon
                                    icon="cancel"
                                    size="s"
                                    color="meteor"
                                    onClick={() => _handleRemoveItem(item.key)}
                                />
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    }

    function _handleItemCheckbox(payload) {
        setSelectedItems(payload.selectedValues);
        onItemChange(payload.selectedValues);
    }

    function _handleRemoveItem(key) {
        const updatedItems = selectedItems.filter((item) => item !== key);
        setSelectedItems(updatedItems);
        onItemChange(updatedItems);
    }

    function _toggleList() {
        setIsListOpen(!isListOpen);
    }

    return (
        <div className="_p-relative multiselect" data-testid="multi-select">
            <div className="_d-flex _ai-center _jc-space-between multiselect_selector">
                {showSelectedItems()}
                <Icon icon="chevronDown" onClick={_toggleList} />
            </div>
            {isListOpen && (
                <div className="_d-flex _fd-column _p-absolute multiselect_option-wrapper">
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className={`multiselect-option-item ${
                                item.isSubItem
                                    ? 'multiselect-option-subitem'
                                    : ''
                            }`}
                        >
                            <Checkbox
                                id={item.key}
                                name={item.key}
                                value={item.key}
                                selectedValues={selectedItems}
                                handleChange={_handleItemCheckbox}
                            >
                                <Text
                                    variant="14-normal"
                                    className="multiselect-option-item_text"
                                >
                                    {item.value}
                                </Text>
                            </Checkbox>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

MultiSelect.propTypes = {
    /**
     * List of items in MultiSelect component
     */
    items: PT.arrayOf(PT.shape({ key: PT.string, value: PT.string })),
    /**
     * Preselected items
     */
    selected: PT.arrayOf(PT.string),
    /**
     * Function that triggers on item change
     */
    onItemChange: PT.func
};

MultiSelect.defaultProps = {
    items: {},
    selected: []
};

export default MultiSelect;
