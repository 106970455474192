import { useTranslation } from 'react-i18next';
import React from 'react';
import { DropDownMenu, DropDownMenuItem } from '~/ui';
import { useVehicleTypeDropDown } from './useVehicleTypeDropDown';

import './VehicleTypeDropDown.scss';

interface VehicleTypeProps {
    /**
     * Whether the dropdown menu is editable
     */
    isEditable: boolean;
    /**
     *
     * The handler for item selection
     */
    onItemSelected: (value: string) => void;
    /**
     * The title of the dropdown menu
     */
    title: string;
}

export const VehicleTypeDropDown = ({
    isEditable,
    onItemSelected,
    title
}: VehicleTypeProps) => {
    const rootClassName = 'vehicle-type-dropdown';
    const { t } = useTranslation('addTask');
    const { isOpen, dropdownRef, toggleDropDown, menuItems, onClose } =
        useVehicleTypeDropDown({ isEditable, onItemSelected });

    return (
        <div className={rootClassName} data-testid={rootClassName}>
            <DropDownMenu
                isOpen={isOpen}
                toggle={toggleDropDown}
                close={onClose}
                styles={{
                    innerContainer: 'dropdown-flex _p-0',
                    menuTitle: 'dropdown-flex-item _p-0',
                    menuTitleText: 'title-text'
                }}
                ref={dropdownRef}
                title={{
                    name: title
                }}
            >
                {menuItems.map(({ onClick, translationKey }) => (
                    <DropDownMenuItem
                        key={translationKey}
                        menuItem={{
                            onClick,
                            text: t(translationKey)
                        }}
                    />
                ))}
            </DropDownMenu>
        </div>
    );
};
