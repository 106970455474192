import { ServerCIEnvironment } from '~/servers/servers';

/**
 * A list of feature flags available
 *
 * These will be used as keys to locate the actual Unleash feature flag from the `envFeatureFlagMapping` constant
 *
 * When adding new feature flags, this `enum` must be updated
 */
export enum FeatureFlag {
    GLOBAL = 'global',
    LIVE_DISPATCH_UI = 'live-dispatch-ui',
    RESET_TASKS = 'reset-tasks',
    CLIENT_TIMEZONE = 'client-timezone',
    OPERATIONAL_STOP = 'operational-stops',
    INITIAL_VS_ACTUAL = 'initial-vs-actual',
    PREDEFINED_EXTERNAL_TASK_TYPES = 'predefined-external-task-types',
    SNAPPED_TO_ROAD_PLANNED_LINES = 'snapped-to-road-planned-lines',
    REQUEST_EXTERNAL_ID_FOR_ROUTE_EXPORT = 'request-external-id-for-route-export'
}

/**
 * A mapping of available Unleash feature flags to support CI environments
 *
 * This map is essential for the `getEnvFeatureFlag()` utility and `useFeatureFlag()` hook
 *
 * When adding new feature flags, this `object` must be updated
 *
 * @example
 * To use feature flags for specific environments, define as follows:
 * ```
 * [featureFlag]: [
 *     ServerCIEnvironment.STAGE,
 *     ServerCIEnvironment.CN_UAT,
 *     ServerCIEnvironment.PRODUCTION
 * ]
 * ```
 *
 * Using this strategy, you will need to define separate Unleash feature flags
 * for each environment you want to support.
 *
 * Environment feature flags must conform to the following convention:
 * ```
 * {featureFlag}-{environmentSuffix}
 * ```
 *
 * Supported suffixes are defined under `FeatureFlagEnvironmentSuffix`
 *
 * @example
 * To just use 1 feature flag for any environment, define as follows:
 * ```
 * [featureFlag]: [ServerCIEnvironment.ANY]
 * ```
 *
 * Using this strategy, you will need to define only one Unleash feature flag,
 * and configure for any environment as a single strategy.
 *
 * @see {@link https://gitlab.com/wisesystems/wise-web-multi-client/-/feature_flags | Available Interstellar Feature Flags}
 * @see {@link https://docs.getunleash.io/reference/sdks/react | Unleash React Documentation}
 */
export const envFeatureFlagMapping: Partial<
    Record<FeatureFlag, ServerCIEnvironment[]>
> = {
    [FeatureFlag.GLOBAL]: [ServerCIEnvironment.ANY],
    [FeatureFlag.LIVE_DISPATCH_UI]: [
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.CN_UAT,
        ServerCIEnvironment.PRODUCTION
    ],
    [FeatureFlag.RESET_TASKS]: [ServerCIEnvironment.PRODUCTION],
    [FeatureFlag.CLIENT_TIMEZONE]: [
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.CN_UAT,
        ServerCIEnvironment.PRODUCTION
    ],
    [FeatureFlag.OPERATIONAL_STOP]: [
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.CN_UAT,
        ServerCIEnvironment.PRODUCTION
    ],
    [FeatureFlag.INITIAL_VS_ACTUAL]: [ServerCIEnvironment.STAGE],
    [FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES]: [ServerCIEnvironment.ANY],
    [FeatureFlag.SNAPPED_TO_ROAD_PLANNED_LINES]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.REQUEST_EXTERNAL_ID_FOR_ROUTE_EXPORT]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO
    ]
};
