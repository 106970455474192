import { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { LiveDispatchViewColumnsProps } from '../types';

type UseLiveDispatchColumnReorderProps = Pick<
    LiveDispatchViewColumnsProps,
    'columnOrder' | 'setColumnOrder'
>;

export const useLiveDispatchColumnReorder = ({
    columnOrder,
    setColumnOrder
}: UseLiveDispatchColumnReorderProps) => {
    const reorderColumns = useCallback(
        ({
            dragStartIndex,
            dragDropIndex
        }: {
            dragStartIndex: number;
            dragDropIndex: number;
        }) => {
            const items = cloneDeep(columnOrder);
            const [removedEntry] = items.splice(dragStartIndex, 1);
            items.splice(dragDropIndex, 0, removedEntry);

            setColumnOrder(items);
        },
        [columnOrder, setColumnOrder]
    );

    return {
        reorderColumns
    };
};
