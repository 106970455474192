import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useClickOutside } from '~/ui/hooks';
import { StackPinMarkerProps } from './types';
import { SocketScheduleItem } from '~/api/types';
import { StackPinMarkerPopup } from '../StackPinMarkerPopup';
import { selectViewCardDetails } from '~/reducers/mapSettingsSlice';
import { StopMarkerList } from '~/ui/components/StackPinMarkerPopup/StopMarkerList';

import './StackPinMarker.scss';

export const StackPinMarker = ({
    numClustered,
    tasks,
    colorCSS,
    emittedEventHandler,
    onDemandDispatchMarkerEventHandler,
    isShowClickAllButton
}: StackPinMarkerProps) => {
    const { t } = useTranslation(['common']);
    const ref = useRef<HTMLDivElement | null>(null);
    const [showStackPinPopup, setShowStackPinPopup] = useState(false);

    const viewCardDetails = useSelector(selectViewCardDetails);

    const foregroundMarkerCSS = { color: 'white', backgroundColor: 'black' };

    const scheduleItems = tasks as unknown as SocketScheduleItem[];
    const stopNamesSet = new Set(
        scheduleItems.map(({ euid, driver }) => euid || driver)
    );

    const hasMultipleAssignedStops = stopNamesSet.size >= 2;
    const areMultipleRoutesSelected =
        viewCardDetails && hasMultipleAssignedStops;

    const handleOnClick = () => {
        setShowStackPinPopup(!showStackPinPopup);
    };

    useClickOutside(ref, handleOnClick);

    const getClassName = () => {
        const boxClassName = 'stopmarker-body';

        const conditionalClasses = {
            'stopmarker-body__selected': showStackPinPopup
        };
        return classNames(boxClassName, conditionalClasses);
    };

    const getGradientBackgroundClassName = () => {
        return classNames('stopmarker-circle', {
            'gradient-background': areMultipleRoutesSelected
        });
    };

    return (
        <span className="stack-pin-marker">
            {showStackPinPopup && (
                <div ref={ref}>
                    <StackPinMarkerPopup tasksCount={tasks.length}>
                        <StopMarkerList
                            tasks={tasks}
                            emittedEventHandler={emittedEventHandler}
                            onDemandDispatchMarkerEventHandler={
                                onDemandDispatchMarkerEventHandler
                            }
                            isShowClickAllButton={isShowClickAllButton}
                        />
                    </StackPinMarkerPopup>
                </div>
            )}
            <button
                type="button"
                className="stopmarker unassigned-stack-pin"
                data-testid="stop-marker"
                onClick={handleOnClick}
            >
                <div
                    className={getClassName()}
                    data-testid="default-stop-marker"
                >
                    <span
                        className={getGradientBackgroundClassName()}
                        data-testid="stop-marker-circle"
                        style={colorCSS}
                    />
                    <span
                        className="stopmarker-circle"
                        data-testid="stop-marker-circle"
                        style={
                            areMultipleRoutesSelected
                                ? foregroundMarkerCSS
                                : colorCSS
                        }
                    />
                    <span
                        className="stack-cluster-count"
                        data-testid="stack-cluster-count"
                    >
                        {t(
                            numClustered > 100
                                ? 'stackStopsPopup.moreClusteredStopsCount'
                                : 'stackStopsPopup.clusteredStopsCount',
                            {
                                count: numClustered
                            }
                        )}
                    </span>
                </div>
            </button>
        </span>
    );
};
