import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetSelectedEquipmentIds,
    selectSelectedEquipmentIds
} from '~/reducers/selectedEquipmentIdsSlice';
import {
    setIsOpenAddTaskModal,
    setIsSaveAndAssignTaskMode
} from '~/reducers/addTaskSlice';
import {
    selectIsOpenPairEquipmentControlPopup,
    setIsOpenPairEquipmentControlPopup
} from '~/reducers/mapDrawerSettingsSlice';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';

import { TaskActionButton } from '~/components/TaskManagementPage/TaskActionBar/TaskActionButton';
import { EquipmentTableHeaderRow } from '../EquipmentTableHeaderRow';
import { EquipmentTableRow } from '../EquipmentTableRow';
import { EquipmentTableSelectedToolbar } from '../EquipmentTableSelectedToolbar';
import { SelectedToolbarPairDropdownButton } from '../SelectedToolbarPairDropdownButton';
import { useEquipmentTableConfig } from '../useEquipmentTableConfig';
import { useEquipmentTableColumnFilterValues } from '../useEquipmentTableColumnFilterValues';

import './EquipmentTable.scss';

const ROOT_CLASS_NAME = 'equipment-table';

export const EquipmentTable = () => {
    const { t } = useTranslation(['liveDispatchTable']);
    const dispatch = useDispatch();
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);
    const isOpenPairEquipmentControlPopup = useSelector(
        selectIsOpenPairEquipmentControlPopup
    );
    const selectedTaskIds = useSelector(selectSelectedTaskIds);

    const { headerGroups, allRows, allSelectedRows, isFiltered } =
        useEquipmentTableConfig();

    useEquipmentTableColumnFilterValues();

    function deselectAllTasks() {
        dispatch(resetSelectedEquipmentIds());
    }

    const openAddTaskModal = () => {
        dispatch(setIsOpenAddTaskModal(true));
        dispatch(setIsSaveAndAssignTaskMode(true));
    };

    const isSelectedTaskIdsEmpty = !selectedTaskIds.length;
    const isSelectedEquipmentIdsEmpty = !selectedEquipmentIds.length;

    useEffect(() => {
        if (
            isOpenPairEquipmentControlPopup &&
            isSelectedTaskIdsEmpty &&
            isSelectedEquipmentIdsEmpty
        ) {
            dispatch(setIsOpenPairEquipmentControlPopup(false));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        isOpenPairEquipmentControlPopup,
        isSelectedTaskIdsEmpty,
        isSelectedEquipmentIdsEmpty
    ]);

    const isDisplayed = {
        pairEquipmentToTask: selectedEquipmentIds.length === 1,
        depot: selectedEquipmentIds.length === 1
    };

    return (
        <div
            className={`${ROOT_CLASS_NAME} _fd-column`}
            data-testid={`${ROOT_CLASS_NAME}`}
        >
            {isFiltered && (
                <EquipmentTableSelectedToolbar>
                    <div className="live-dispatch-table-toolbar__buttons-container">
                        {selectedEquipmentIds.length === 1 && (
                            <TaskActionButton
                                title={t(
                                    'selectedToolbar.createTaskFromEquipment'
                                )}
                                icon="add"
                                onClick={openAddTaskModal}
                                isDisplayed
                                data-testid="selected-toolbar__create-task-from-equipment"
                            />
                        )}
                        <SelectedToolbarPairDropdownButton
                            isDisplayed={isDisplayed}
                        />
                    </div>
                    <div className="_d-flex _ai-center taskactionbar-buttons">
                        <TaskActionButton
                            title={t('selectedToolbar.deselectAll')}
                            onClick={deselectAllTasks}
                            isDisplayed
                            data-testid="selected-toolbar__deselect-all"
                        />
                    </div>
                </EquipmentTableSelectedToolbar>
            )}
            <div className={`${ROOT_CLASS_NAME}__wrapper _fd-column`}>
                <table
                    role="table"
                    className={`${ROOT_CLASS_NAME}__table ${ROOT_CLASS_NAME}__table--stickied`}
                    data-testid={`${ROOT_CLASS_NAME}__sticky-table`}
                >
                    <thead
                        className={`${ROOT_CLASS_NAME}__table-header _header-4-alt`}
                        data-testid={`${ROOT_CLASS_NAME}__thead`}
                    >
                        {headerGroups.map((headerGroup) => (
                            <EquipmentTableHeaderRow
                                key={headerGroup.id}
                                headerGroup={headerGroup}
                            />
                        ))}
                    </thead>
                    <tbody>
                        {allSelectedRows.map((row) => (
                            <EquipmentTableRow key={row.id} row={row} />
                        ))}
                    </tbody>
                </table>
                <table
                    role="table"
                    className={`${ROOT_CLASS_NAME}__table`}
                    data-testid={`${ROOT_CLASS_NAME}__table`}
                >
                    <tbody>
                        {allRows.map((row) => (
                            <EquipmentTableRow key={row.id} row={row} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
