import React from 'react';
import { Icon } from '~/ui';
import './ShiftStartTime.scss';

interface ShiftStartTimeProps {
    /**
     * The text message
     */
    textMsg: string;
}

function ShiftStartTime({ textMsg }: ShiftStartTimeProps) {
    return (
        <span className="_d-flex _ai-center shift-start-time">
            <Icon icon="clock" color="meteor" />
            {textMsg}
        </span>
    );
}

export default ShiftStartTime;
