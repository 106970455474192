import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMapContext } from '~/components/ZoneManagementPage/MapContext';
import {
    clearGeofenceState,
    selectCircleRadius,
    setCircleRadius,
    setGeofencePolygon
} from '~/reducers/geofenceSlice';
import { mapDrawingUtils } from '~/utils/map';
import { useClientPreferences } from '~/components/OptimizationConfigurationPage/hooks';
import { selectMainClient } from '~/reducers/mainClientSlice';
import _ from 'lodash';

const HIGHLIGHTED_POLYGON_OPACITY = 0.4;
const UNHIGHLIGHTED_POLYGON_OPACITY = 0.3;

export const useGeofence = (
    customerLocation,
    parkingLocations,
    geofencePolygon,
    geofenceCircleRadius,
    isDisableCircularGeofences = false
) => {
    const hasPolygonGeofence = Boolean(geofencePolygon?.coordinates?.length);
    const dispatch = useDispatch();
    const [isCircularMode, setCircularMode] = useState(false);
    const [isFreehandMode, setFreehandMode] = useState(false);
    const [mapCircles, setMapCircles] = useState([]);
    const [mapPolygon, setMapPolygon] = useState(null);
    const circleRadius = useSelector(selectCircleRadius);
    const { defaultGeofenceRadius } = useClientPreferences(
        useSelector(selectMainClient)
    ).clientData;

    const {
        activePolygon,
        isDrawingMode,
        mapInstance,
        mapsApi,
        setActivePolygon,
        setIsDrawingMode
    } = useMapContext();

    const isUpdateGeofenceCircleRadius =
        _.isNumber(geofenceCircleRadius) && geofenceCircleRadius >= 0;
    /**
     * Display polygon geofence
     */
    function displayPolygonGeofence() {
        const polygonCoordinates = geofencePolygon.coordinates.map(
            (coordinates) => {
                return coordinates.map((coordinate) => ({
                    lng: coordinate[0],
                    lat: coordinate[1]
                }));
            }
        );
        const polygon = mapDrawingUtils.makePolygon(
            mapsApi,
            polygonCoordinates,
            { strokeWeight: 3 }
        );

        polygon.addListener('mouseover', () => {
            mapDrawingUtils.highlightPolygon(
                polygon,
                HIGHLIGHTED_POLYGON_OPACITY
            );
        });

        polygon.addListener('mouseout', () => {
            mapDrawingUtils.unHighlightPolygon(
                polygon,
                UNHIGHLIGHTED_POLYGON_OPACITY
            );
        });

        mapDrawingUtils.drawMapObject(polygon, mapInstance);
        setMapPolygon(polygon);
        dispatch(setGeofencePolygon(geofencePolygon));
    }

    /**
     * Remove polygon geofence from map
     */
    function removeMapPolygon() {
        mapPolygon.setMap(null);
        setMapPolygon(null);
        dispatch(setGeofencePolygon(null));
    }

    /**
     * Display circular geofence around each location
     * @param {Object[]} locations
     * @param {Number} radius
     */
    function displayCircularGeofences(locations, radius) {
        const circles = locations.map((location) => {
            return mapDrawingUtils.drawCircleOnMap(
                mapInstance,
                mapsApi,
                location,
                radius
            );
        });
        setMapCircles((currentCircles) => [...currentCircles, ...circles]);
    }

    /**
     * Draw circular geofences with provided radius
     * @param {Number} radius
     */
    function addMapCircles(radius) {
        dispatch(setCircleRadius(radius));
        displayCircularGeofences(
            [customerLocation, ...parkingLocations],
            radius
        );
    }

    /**
     * Remove all circle geofences on map
     */
    function removeMapCircles() {
        mapCircles.forEach((circle) => mapDrawingUtils.removeMapObject(circle));
        setMapCircles([]);
        dispatch(setCircleRadius(null));
    }

    /**
     * Reset component internal states to default values
     */
    function resetStateToDefault() {
        if (mapPolygon) removeMapPolygon();
        if (mapCircles.length) removeMapCircles();
        dispatch(clearGeofenceState());
    }

    useEffect(() => {
        if (!mapInstance || !mapsApi || !customerLocation) return;

        resetStateToDefault();
        if (isUpdateGeofenceCircleRadius) {
            dispatch(setCircleRadius(geofenceCircleRadius));
            displayCircularGeofences(
                [customerLocation, ...parkingLocations],
                geofenceCircleRadius
            );
        } else if (hasPolygonGeofence) {
            displayPolygonGeofence();
        } else if (!isDisableCircularGeofences) {
            displayCircularGeofences(
                [customerLocation, ...parkingLocations],
                defaultGeofenceRadius
            );
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        mapInstance,
        mapsApi,
        customerLocation,
        parkingLocations,
        geofencePolygon
    ]);

    useEffect(() => {
        // update radius with slider value
        if (circleRadius) {
            mapCircles.forEach((circle) => circle.setRadius(circleRadius));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [circleRadius]);

    useEffect(() => {
        // removes auto-completed polygon when user clicks reset with unfinished polygon drawing
        if (activePolygon && !isFreehandMode && !isDrawingMode) {
            mapDrawingUtils.removeMapObject(activePolygon);
            setActivePolygon(null);
            dispatch(setGeofencePolygon(null));
        } else if (activePolygon) {
            const vertices = activePolygon.getPath();
            const newPolygonCoordinates = [];
            vertices.forEach((vertex, i) => {
                newPolygonCoordinates.push([
                    vertices.getAt(i).lng(),
                    vertices.getAt(i).lat()
                ]);
            });
            // first and last coordinates need to be the same for geoJSON format
            newPolygonCoordinates.push([
                vertices.getAt(0).lng(),
                vertices.getAt(0).lat()
            ]);
            dispatch(
                setGeofencePolygon({
                    type: 'Polygon',
                    coordinates: [newPolygonCoordinates]
                })
            );
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [activePolygon]);

    function handleSliderChange(e) {
        const { value } = e.target;
        dispatch(setCircleRadius(Number(value)));
    }

    function handleEditCircularGeofence() {
        setCircularMode(true);
        if (hasPolygonGeofence && !geofenceCircleRadius) {
            removeMapPolygon();
            removeMapCircles();
            addMapCircles(Number(defaultGeofenceRadius));
        } else if (!hasPolygonGeofence && !geofenceCircleRadius) {
            removeMapCircles();
            addMapCircles(Number(defaultGeofenceRadius));
        }
    }

    function handleEditPolygonGeofence() {
        setFreehandMode(true);
        if (geofenceCircleRadius) {
            removeMapCircles();
        } else if (hasPolygonGeofence) {
            removeMapPolygon();
        } else {
            removeMapCircles();
        }
        setIsDrawingMode(true);
    }

    function handleCircularCancel() {
        setCircularMode(false);
        if (geofenceCircleRadius) {
            // reset to original radius
            dispatch(setCircleRadius(geofenceCircleRadius));
        } else if (hasPolygonGeofence) {
            // remove circular geofence
            removeMapCircles();
            // reset to original polygon geofence
            displayPolygonGeofence();
        } else {
            removeMapCircles();
            displayCircularGeofences(
                [customerLocation, ...parkingLocations],
                defaultGeofenceRadius
            );
        }
    }

    function handleFreehandCancel() {
        setFreehandMode(false);
        setIsDrawingMode(false);
        if (activePolygon) {
            mapDrawingUtils.removeMapObject(activePolygon);
            setActivePolygon(null);
            dispatch(setGeofencePolygon(null));
        }
        if (geofenceCircleRadius) {
            // reset to original circular geofence
            addMapCircles(geofenceCircleRadius);
        } else if (hasPolygonGeofence) {
            // reset to original polygon geofence
            displayPolygonGeofence();
        } else {
            displayCircularGeofences(
                [customerLocation, ...parkingLocations],
                defaultGeofenceRadius
            );
        }
    }

    return {
        isCircularMode,
        isFreehandMode,
        handleSliderChange,
        handleEditCircularGeofence,
        handleEditPolygonGeofence,
        handleCircularCancel,
        handleFreehandCancel
    };
};
